import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { ErrorsTracker } from '@api/errorsTracker';
import { DividendsAdjustmentExecuteRequest } from '@api/fbe/manager/DividendsAdjustmentExecuteRequest';
import { Message, MessageType } from '@components/Message/Message';
import { DividendsAdjustmentExecuteRequestModel } from '@models/stocks/Dividends';
import { DividendsAdjustmentCommonParams } from '@api/fbe/manager/DividendsAdjustmentCommonParams';
import { DividendsAdjustmentExecuteResponse } from '@api/fbe/manager/DividendsAdjustmentExecuteResponse';

export class DividendsStore {
    errorTracker = new ErrorsTracker({ title: 'Dividends' });

    constructor() {
        makeAutoObservable(this);
    }

    isLoading: boolean = false;

    requestParams: DividendsAdjustmentExecuteRequestModel | null = null;

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setRequestParams(value: DividendsAdjustmentExecuteRequestModel | null) {
        this.requestParams = value;
    }

    @ErrorsTracker.wrapApi()
    async execute(): Promise<DividendsAdjustmentExecuteResponse> {
        const request = new DividendsAdjustmentExecuteRequest();
        if (this.requestParams === null) {
            Message(MessageType.error, 'Request params are required');
            return Promise.reject();
        }

        request.Params = new DividendsAdjustmentCommonParams();
        request.Params.SymbolName = this.requestParams.SymbolName;
        request.Params.GroupNamePatterns = this.requestParams.GroupNamePatterns;
        request.Params.LongPoints = this.requestParams.LongPoints;
        request.Params.ShortPoints = this.requestParams.ShortPoints;

        this.setIsLoading(true);

        try {
            const result = await SocketClient.instance.request(request, this.errorTracker);
            this.setIsLoading(false);
            return result;
        } catch (e) {
            this.setIsLoading(false);
            return Promise.reject(e);
        }
    }

    reset() {
        this.isLoading = false;
        this.requestParams = null;
    }
}

const dividendsStore = new DividendsStore();

export { dividendsStore };
