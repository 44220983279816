
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * OrderEvent struct
 */
class OrderEvent {
  /**
   * Initialize struct
   * @param {UUID=} symbolId
   * @param {string=} symbolName
   * @param {UUID=} currencyId
   * @param {string=} currencyName
   * @param {number=} price
   * @param {number=} amount
   * @param {UUID=} gatewayId
   * @param {string=} gatewayExecutionId
   * @param {string=} gatewayOrderId
   * @param {string=} gatewayClientOrderId
   * @param {number=} gatewayMsgSeqNum
   * @param {number=} markup
   * @constructor
   */
  constructor (symbolId = null, symbolName = null, currencyId = null, currencyName = null, price = null, amount = null, gatewayId = null, gatewayExecutionId = null, gatewayOrderId = null, gatewayClientOrderId = null, gatewayMsgSeqNum = null, markup = null) {
    this.SymbolId = symbolId
    this.SymbolName = symbolName
    this.CurrencyId = currencyId
    this.CurrencyName = currencyName
    this.Price = price
    this.Amount = amount
    this.GatewayId = gatewayId
    this.GatewayExecutionId = gatewayExecutionId
    this.GatewayOrderId = gatewayOrderId
    this.GatewayClientOrderId = gatewayClientOrderId
    this.GatewayMsgSeqNum = gatewayMsgSeqNum
    this.Markup = markup
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderEvent}
   * @param {!OrderEvent} other Other struct
   * @returns {!OrderEvent} This struct
   */
  copy (other) {
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.SymbolName != null) {
      this.SymbolName = other.SymbolName
    } else {
      this.SymbolName = null
    }
    if (other.CurrencyId != null) {
      this.CurrencyId = new UUID(other.CurrencyId)
    } else {
      this.CurrencyId = null
    }
    if (other.CurrencyName != null) {
      this.CurrencyName = other.CurrencyName
    } else {
      this.CurrencyName = null
    }
    if (other.Price != null) {
      this.Price = other.Price
    } else {
      this.Price = null
    }
    if (other.Amount != null) {
      this.Amount = other.Amount
    } else {
      this.Amount = null
    }
    if (other.GatewayId != null) {
      this.GatewayId = new UUID(other.GatewayId)
    } else {
      this.GatewayId = null
    }
    if (other.GatewayExecutionId != null) {
      this.GatewayExecutionId = other.GatewayExecutionId
    } else {
      this.GatewayExecutionId = null
    }
    if (other.GatewayOrderId != null) {
      this.GatewayOrderId = other.GatewayOrderId
    } else {
      this.GatewayOrderId = null
    }
    if (other.GatewayClientOrderId != null) {
      this.GatewayClientOrderId = other.GatewayClientOrderId
    } else {
      this.GatewayClientOrderId = null
    }
    if (other.GatewayMsgSeqNum != null) {
      this.GatewayMsgSeqNum = UInt64.fromNumber(other.GatewayMsgSeqNum)
    } else {
      this.GatewayMsgSeqNum = null
    }
    if (other.Markup != null) {
      this.Markup = UInt64.fromNumber(other.Markup)
    } else {
      this.Markup = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderEvent}
   * @returns {!OrderEvent} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderEventModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderEventModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderEvent}
   * @param {!OrderEvent} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderEvent)) {
      throw new TypeError('Instance of OrderEvent is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderEvent}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      SymbolName: ((this.SymbolName != null) ? this.SymbolName : null),
      CurrencyId: ((this.CurrencyId != null) ? this.CurrencyId.toString() : null),
      CurrencyName: ((this.CurrencyName != null) ? this.CurrencyName : null),
      Price: ((this.Price != null) ? this.Price : null),
      Amount: ((this.Amount != null) ? this.Amount : null),
      GatewayId: ((this.GatewayId != null) ? this.GatewayId.toString() : null),
      GatewayExecutionId: ((this.GatewayExecutionId != null) ? this.GatewayExecutionId : null),
      GatewayOrderId: ((this.GatewayOrderId != null) ? this.GatewayOrderId : null),
      GatewayClientOrderId: ((this.GatewayClientOrderId != null) ? this.GatewayClientOrderId : null),
      GatewayMsgSeqNum: ((this.GatewayMsgSeqNum != null) ? this.GatewayMsgSeqNum.toNumber() : null),
      Markup: ((this.Markup != null) ? this.Markup.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderEvent.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderEvent} other Object value
   * @returns {!OrderEvent} Created struct
   */
  static fromObject (other) {
    return new OrderEvent().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderEvent}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderEvent.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderEvent}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6515
  }
}

export { OrderEvent };

/**
 * Fast Binary Encoding OrderEvent field model
 */
class FieldModelOrderEvent extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.SymbolId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, 4 + 4), buffer, 4 + 4)
    this.SymbolName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize), buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.CurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.SymbolName.fbeOffset + this.SymbolName.fbeSize), buffer, this.SymbolName.fbeOffset + this.SymbolName.fbeSize)
    this.CurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CurrencyId.fbeOffset + this.CurrencyId.fbeSize), buffer, this.CurrencyId.fbeOffset + this.CurrencyId.fbeSize)
    this.Price = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.CurrencyName.fbeOffset + this.CurrencyName.fbeSize), buffer, this.CurrencyName.fbeOffset + this.CurrencyName.fbeSize)
    this.Amount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Price.fbeOffset + this.Price.fbeSize), buffer, this.Price.fbeOffset + this.Price.fbeSize)
    this.GatewayId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.Amount.fbeOffset + this.Amount.fbeSize), buffer, this.Amount.fbeOffset + this.Amount.fbeSize)
    this.GatewayExecutionId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.GatewayId.fbeOffset + this.GatewayId.fbeSize), buffer, this.GatewayId.fbeOffset + this.GatewayId.fbeSize)
    this.GatewayOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.GatewayExecutionId.fbeOffset + this.GatewayExecutionId.fbeSize), buffer, this.GatewayExecutionId.fbeOffset + this.GatewayExecutionId.fbeSize)
    this.GatewayClientOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.GatewayOrderId.fbeOffset + this.GatewayOrderId.fbeSize), buffer, this.GatewayOrderId.fbeOffset + this.GatewayOrderId.fbeSize)
    this.GatewayMsgSeqNum = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.GatewayClientOrderId.fbeOffset + this.GatewayClientOrderId.fbeSize), buffer, this.GatewayClientOrderId.fbeOffset + this.GatewayClientOrderId.fbeSize)
    this.Markup = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.GatewayMsgSeqNum.fbeOffset + this.GatewayMsgSeqNum.fbeSize), buffer, this.GatewayMsgSeqNum.fbeOffset + this.GatewayMsgSeqNum.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderEvent}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderEvent}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.SymbolId.fbeSize + this.SymbolName.fbeSize + this.CurrencyId.fbeSize + this.CurrencyName.fbeSize + this.Price.fbeSize + this.Amount.fbeSize + this.GatewayId.fbeSize + this.GatewayExecutionId.fbeSize + this.GatewayOrderId.fbeSize + this.GatewayClientOrderId.fbeSize + this.GatewayMsgSeqNum.fbeSize + this.Markup.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderEvent}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.SymbolId.fbeExtra + this.SymbolName.fbeExtra + this.CurrencyId.fbeExtra + this.CurrencyName.fbeExtra + this.Price.fbeExtra + this.Amount.fbeExtra + this.GatewayId.fbeExtra + this.GatewayExecutionId.fbeExtra + this.GatewayOrderId.fbeExtra + this.GatewayClientOrderId.fbeExtra + this.GatewayMsgSeqNum.fbeExtra + this.Markup.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderEvent}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderEvent.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderEvent}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6515
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderEvent}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderEvent}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.CurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyId.fbeSize

    if ((fbeCurrentSize + this.CurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyName.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Price.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Amount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.GatewayId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayId.fbeSize

    if ((fbeCurrentSize + this.GatewayExecutionId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayExecutionId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayExecutionId.fbeSize

    if ((fbeCurrentSize + this.GatewayOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayOrderId.fbeSize

    if ((fbeCurrentSize + this.GatewayClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayClientOrderId.fbeSize

    if ((fbeCurrentSize + this.GatewayMsgSeqNum.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayMsgSeqNum.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayMsgSeqNum.fbeSize

    if ((fbeCurrentSize + this.Markup.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Markup.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Markup.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderEvent}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderEvent}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderEvent}
   * @param {!OrderEvent} fbeValue Default value, defaults is new OrderEvent()
   * @returns {!OrderEvent} OrderEvent value
   */
  get (fbeValue = new OrderEvent()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderEvent}
   * @param {!OrderEvent} fbeValue OrderEvent value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolName = this.SymbolName.get()
    } else {
      fbeValue.SymbolName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.CurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.CurrencyId = this.CurrencyId.get()
    } else {
      fbeValue.CurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyId.fbeSize

    if ((fbeCurrentSize + this.CurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.CurrencyName = this.CurrencyName.get()
    } else {
      fbeValue.CurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyName.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) <= fbeStructSize) {
      fbeValue.Price = this.Price.get()
    } else {
      fbeValue.Price = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) <= fbeStructSize) {
      fbeValue.Amount = this.Amount.get()
    } else {
      fbeValue.Amount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.GatewayId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayId = this.GatewayId.get()
    } else {
      fbeValue.GatewayId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayId.fbeSize

    if ((fbeCurrentSize + this.GatewayExecutionId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayExecutionId = this.GatewayExecutionId.get()
    } else {
      fbeValue.GatewayExecutionId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayExecutionId.fbeSize

    if ((fbeCurrentSize + this.GatewayOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayOrderId = this.GatewayOrderId.get()
    } else {
      fbeValue.GatewayOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayOrderId.fbeSize

    if ((fbeCurrentSize + this.GatewayClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayClientOrderId = this.GatewayClientOrderId.get()
    } else {
      fbeValue.GatewayClientOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayClientOrderId.fbeSize

    if ((fbeCurrentSize + this.GatewayMsgSeqNum.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayMsgSeqNum = this.GatewayMsgSeqNum.get()
    } else {
      fbeValue.GatewayMsgSeqNum = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayMsgSeqNum.fbeSize

    if ((fbeCurrentSize + this.Markup.fbeSize) <= fbeStructSize) {
      fbeValue.Markup = this.Markup.get()
    } else {
      fbeValue.Markup = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Markup.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderEvent}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderEvent}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderEvent}
   * @param {!OrderEvent} fbeValue OrderEvent value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderEvent}
   * @param {OrderEvent} fbeValue OrderEvent value
   */
  setFields (fbeValue) {
    this.SymbolId.set(fbeValue.SymbolId)
    this.SymbolName.set(fbeValue.SymbolName)
    this.CurrencyId.set(fbeValue.CurrencyId)
    this.CurrencyName.set(fbeValue.CurrencyName)
    this.Price.set(fbeValue.Price)
    this.Amount.set(fbeValue.Amount)
    this.GatewayId.set(fbeValue.GatewayId)
    this.GatewayExecutionId.set(fbeValue.GatewayExecutionId)
    this.GatewayOrderId.set(fbeValue.GatewayOrderId)
    this.GatewayClientOrderId.set(fbeValue.GatewayClientOrderId)
    this.GatewayMsgSeqNum.set(fbeValue.GatewayMsgSeqNum)
    this.Markup.set(fbeValue.Markup)
  }
}

export { FieldModelOrderEvent };

/**
 * Fast Binary Encoding OrderEvent model
 */
class OrderEventModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderEvent(this.buffer, 4)
  }

  /**
   * Get the OrderEvent model
   * @this {!OrderEventModel}
   * @returns {!FieldModelOrderEvent} model OrderEvent model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderEventModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderEventModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderEventModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderEventModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderEvent.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderEventModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderEventModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderEventModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderEventModel}
   * @param {!OrderEvent} value OrderEvent value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderEventModel}
   * @param {!OrderEvent} value OrderEvent value, defaults is new OrderEvent()
   * @return {!object} Deserialized OrderEvent value and its size
   */
  deserialize (value = new OrderEvent()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderEvent(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderEvent(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderEventModel}
   * @param {!number} prev Previous OrderEvent model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderEventModel };
