
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {TradingCalendarOffTimeRuleCreateResponse} from './TradingCalendarOffTimeRuleCreateResponse';
import {DaysOfWeek} from '../core/DaysOfWeek';
import {FieldModelDaysOfWeek} from '../core/DaysOfWeek';
import {OffTimeType} from '../core/OffTimeType';
import {FieldModelOffTimeType} from '../core/OffTimeType';

/**
 * TradingCalendarOffTimeRuleCreateRequest struct
 */
class TradingCalendarOffTimeRuleCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {!string=} name
   * @param {Date=} day
   * @param {DaysOfWeek=} weekDays
   * @param {number=} monthDay
   * @param {number=} yearMonth
   * @param {!OffTimeType=} offTimeType
   * @param {!boolean=} isMarketEnabled
   * @param {!boolean=} isTradingEnabled
   * @param {!number=} startTime
   * @param {!number=} endTime
   * @param {!string=} comment
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), name = '', day = null, weekDays = null, monthDay = null, yearMonth = null, offTimeType = new OffTimeType(), isMarketEnabled = true, isTradingEnabled = true, startTime = new UInt64(0, 0), endTime = new UInt64(0, 0), comment = '') {
    super()
    super.copy(parent)
    this.Name = name
    this.Day = day
    this.WeekDays = weekDays
    this.MonthDay = monthDay
    this.YearMonth = yearMonth
    this.OffTimeType = offTimeType
    this.IsMarketEnabled = isMarketEnabled
    this.IsTradingEnabled = isTradingEnabled
    this.StartTime = startTime
    this.EndTime = endTime
    this.Comment = comment
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradingCalendarOffTimeRuleCreateRequest}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} other Other struct
   * @returns {!TradingCalendarOffTimeRuleCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Day != null) {
      if (other.Day instanceof Date) {
        this.Day = new Date(other.Day.getTime())
      } else {
        this.Day = new Date(Math.round(other.Day / 1000000))
      }
    } else {
      this.Day = null
    }
    if (other.WeekDays != null) {
      this.WeekDays = DaysOfWeek.fromObject(other.WeekDays)
    } else {
      this.WeekDays = null
    }
    if (other.MonthDay != null) {
      this.MonthDay = other.MonthDay
    } else {
      this.MonthDay = null
    }
    if (other.YearMonth != null) {
      this.YearMonth = other.YearMonth
    } else {
      this.YearMonth = null
    }
    if (other.OffTimeType != null) {
      this.OffTimeType = OffTimeType.fromObject(other.OffTimeType)
    } else {
      this.OffTimeType = null
    }
    if (other.IsMarketEnabled != null) {
      this.IsMarketEnabled = other.IsMarketEnabled
    } else {
      this.IsMarketEnabled = null
    }
    if (other.IsTradingEnabled != null) {
      this.IsTradingEnabled = other.IsTradingEnabled
    } else {
      this.IsTradingEnabled = null
    }
    if (other.StartTime != null) {
      this.StartTime = UInt64.fromNumber(other.StartTime)
    } else {
      this.StartTime = null
    }
    if (other.EndTime != null) {
      this.EndTime = UInt64.fromNumber(other.EndTime)
    } else {
      this.EndTime = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradingCalendarOffTimeRuleCreateRequest}
   * @returns {!TradingCalendarOffTimeRuleCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradingCalendarOffTimeRuleCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradingCalendarOffTimeRuleCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradingCalendarOffTimeRuleCreateRequest}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingCalendarOffTimeRuleCreateRequest)) {
      throw new TypeError('Instance of TradingCalendarOffTimeRuleCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradingCalendarOffTimeRuleCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Name: ((this.Name != null) ? this.Name : null),
      Day: ((this.Day != null) ? (this.Day.getTime() * 1000000) : null),
      WeekDays: ((this.WeekDays != null) ? this.WeekDays : null),
      MonthDay: ((this.MonthDay != null) ? this.MonthDay : null),
      YearMonth: ((this.YearMonth != null) ? this.YearMonth : null),
      OffTimeType: ((this.OffTimeType != null) ? this.OffTimeType : null),
      IsMarketEnabled: ((this.IsMarketEnabled != null) ? this.IsMarketEnabled : null),
      IsTradingEnabled: ((this.IsTradingEnabled != null) ? this.IsTradingEnabled : null),
      StartTime: ((this.StartTime != null) ? this.StartTime.toNumber() : null),
      EndTime: ((this.EndTime != null) ? this.EndTime.toNumber() : null),
      Comment: ((this.Comment != null) ? this.Comment : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradingCalendarOffTimeRuleCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradingCalendarOffTimeRuleCreateRequest} other Object value
   * @returns {!TradingCalendarOffTimeRuleCreateRequest} Created struct
   */
  static fromObject (other) {
    return new TradingCalendarOffTimeRuleCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradingCalendarOffTimeRuleCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6297
  }
}

export { TradingCalendarOffTimeRuleCreateRequest };

/**
 * Fast Binary Encoding TradingCalendarOffTimeRuleCreateRequest field model
 */
class FieldModelTradingCalendarOffTimeRuleCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.Name = new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Day = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Name.fbeOffset + this.Name.fbeSize), buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.WeekDays = new fbe.FieldModelOptional(new FieldModelDaysOfWeek(buffer, this.Day.fbeOffset + this.Day.fbeSize), buffer, this.Day.fbeOffset + this.Day.fbeSize)
    this.MonthDay = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.WeekDays.fbeOffset + this.WeekDays.fbeSize), buffer, this.WeekDays.fbeOffset + this.WeekDays.fbeSize)
    this.YearMonth = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.MonthDay.fbeOffset + this.MonthDay.fbeSize), buffer, this.MonthDay.fbeOffset + this.MonthDay.fbeSize)
    this.OffTimeType = new FieldModelOffTimeType(buffer, this.YearMonth.fbeOffset + this.YearMonth.fbeSize)
    this.IsMarketEnabled = new fbe.FieldModelBool(buffer, this.OffTimeType.fbeOffset + this.OffTimeType.fbeSize)
    this.IsTradingEnabled = new fbe.FieldModelBool(buffer, this.IsMarketEnabled.fbeOffset + this.IsMarketEnabled.fbeSize)
    this.StartTime = new fbe.FieldModelUInt64(buffer, this.IsTradingEnabled.fbeOffset + this.IsTradingEnabled.fbeSize)
    this.EndTime = new fbe.FieldModelUInt64(buffer, this.StartTime.fbeOffset + this.StartTime.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.EndTime.fbeOffset + this.EndTime.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Name.fbeSize + this.Day.fbeSize + this.WeekDays.fbeSize + this.MonthDay.fbeSize + this.YearMonth.fbeSize + this.OffTimeType.fbeSize + this.IsMarketEnabled.fbeSize + this.IsTradingEnabled.fbeSize + this.StartTime.fbeSize + this.EndTime.fbeSize + this.Comment.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Name.fbeExtra + this.Day.fbeExtra + this.WeekDays.fbeExtra + this.MonthDay.fbeExtra + this.YearMonth.fbeExtra + this.OffTimeType.fbeExtra + this.IsMarketEnabled.fbeExtra + this.IsTradingEnabled.fbeExtra + this.StartTime.fbeExtra + this.EndTime.fbeExtra + this.Comment.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradingCalendarOffTimeRuleCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6297
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Day.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Day.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Day.fbeSize

    if ((fbeCurrentSize + this.WeekDays.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.WeekDays.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WeekDays.fbeSize

    if ((fbeCurrentSize + this.MonthDay.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MonthDay.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MonthDay.fbeSize

    if ((fbeCurrentSize + this.YearMonth.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.YearMonth.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.YearMonth.fbeSize

    if ((fbeCurrentSize + this.OffTimeType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OffTimeType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OffTimeType.fbeSize

    if ((fbeCurrentSize + this.IsMarketEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsMarketEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsMarketEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradingEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradingEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradingEnabled.fbeSize

    if ((fbeCurrentSize + this.StartTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StartTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StartTime.fbeSize

    if ((fbeCurrentSize + this.EndTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.EndTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EndTime.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} fbeValue Default value, defaults is new TradingCalendarOffTimeRuleCreateRequest()
   * @returns {!TradingCalendarOffTimeRuleCreateRequest} TradingCalendarOffTimeRuleCreateRequest value
   */
  get (fbeValue = new TradingCalendarOffTimeRuleCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} fbeValue TradingCalendarOffTimeRuleCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Day.fbeSize) <= fbeStructSize) {
      fbeValue.Day = this.Day.get()
    } else {
      fbeValue.Day = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Day.fbeSize

    if ((fbeCurrentSize + this.WeekDays.fbeSize) <= fbeStructSize) {
      fbeValue.WeekDays = this.WeekDays.get()
    } else {
      fbeValue.WeekDays = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.WeekDays.fbeSize

    if ((fbeCurrentSize + this.MonthDay.fbeSize) <= fbeStructSize) {
      fbeValue.MonthDay = this.MonthDay.get()
    } else {
      fbeValue.MonthDay = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MonthDay.fbeSize

    if ((fbeCurrentSize + this.YearMonth.fbeSize) <= fbeStructSize) {
      fbeValue.YearMonth = this.YearMonth.get()
    } else {
      fbeValue.YearMonth = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.YearMonth.fbeSize

    if ((fbeCurrentSize + this.OffTimeType.fbeSize) <= fbeStructSize) {
      fbeValue.OffTimeType = this.OffTimeType.get()
    } else {
      fbeValue.OffTimeType = new OffTimeType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OffTimeType.fbeSize

    if ((fbeCurrentSize + this.IsMarketEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsMarketEnabled = this.IsMarketEnabled.get(true)
    } else {
      fbeValue.IsMarketEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsMarketEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradingEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradingEnabled = this.IsTradingEnabled.get(true)
    } else {
      fbeValue.IsTradingEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradingEnabled.fbeSize

    if ((fbeCurrentSize + this.StartTime.fbeSize) <= fbeStructSize) {
      fbeValue.StartTime = this.StartTime.get()
    } else {
      fbeValue.StartTime = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StartTime.fbeSize

    if ((fbeCurrentSize + this.EndTime.fbeSize) <= fbeStructSize) {
      fbeValue.EndTime = this.EndTime.get()
    } else {
      fbeValue.EndTime = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EndTime.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} fbeValue TradingCalendarOffTimeRuleCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradingCalendarOffTimeRuleCreateRequest}
   * @param {TradingCalendarOffTimeRuleCreateRequest} fbeValue TradingCalendarOffTimeRuleCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Name.set(fbeValue.Name)
    this.Day.set(fbeValue.Day)
    this.WeekDays.set(fbeValue.WeekDays)
    this.MonthDay.set(fbeValue.MonthDay)
    this.YearMonth.set(fbeValue.YearMonth)
    this.OffTimeType.set(fbeValue.OffTimeType)
    this.IsMarketEnabled.set(fbeValue.IsMarketEnabled)
    this.IsTradingEnabled.set(fbeValue.IsTradingEnabled)
    this.StartTime.set(fbeValue.StartTime)
    this.EndTime.set(fbeValue.EndTime)
    this.Comment.set(fbeValue.Comment)
  }
}

export { FieldModelTradingCalendarOffTimeRuleCreateRequest };

/**
 * Fast Binary Encoding TradingCalendarOffTimeRuleCreateRequest model
 */
class TradingCalendarOffTimeRuleCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradingCalendarOffTimeRuleCreateRequest(this.buffer, 4)
  }

  /**
   * Get the TradingCalendarOffTimeRuleCreateRequest model
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @returns {!FieldModelTradingCalendarOffTimeRuleCreateRequest} model TradingCalendarOffTimeRuleCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradingCalendarOffTimeRuleCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradingCalendarOffTimeRuleCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} value TradingCalendarOffTimeRuleCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @param {!TradingCalendarOffTimeRuleCreateRequest} value TradingCalendarOffTimeRuleCreateRequest value, defaults is new TradingCalendarOffTimeRuleCreateRequest()
   * @return {!object} Deserialized TradingCalendarOffTimeRuleCreateRequest value and its size
   */
  deserialize (value = new TradingCalendarOffTimeRuleCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradingCalendarOffTimeRuleCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradingCalendarOffTimeRuleCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradingCalendarOffTimeRuleCreateRequestModel}
   * @param {!number} prev Previous TradingCalendarOffTimeRuleCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradingCalendarOffTimeRuleCreateRequestModel };
TradingCalendarOffTimeRuleCreateRequest.__has_response = true;
TradingCalendarOffTimeRuleCreateRequest.__response_class = TradingCalendarOffTimeRuleCreateResponse;
