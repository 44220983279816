
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * Person struct
 */
class Person {
  /**
   * Initialize struct
   * @param {!number=} personId
   * @param {!number=} actualVersion
   * @param {!string=} name
   * @param {!string=} country
   * @param {!string=} city
   * @param {!string=} state
   * @param {!string=} zipCode
   * @param {!string=} address
   * @param {!string=} phone
   * @param {!string=} email
   * @param {!string=} identifier
   * @param {!string=} status
   * @param {!Date=} created
   * @param {!Date=} modified
   * @param {string=} externalClientId
   * @param {!string=} localLanguageName
   * @constructor
   */
  constructor (personId = new UInt64(0, 0), actualVersion = new UInt64(0, 0), name = '', country = '', city = '', state = '', zipCode = '', address = '', phone = '', email = '', identifier = '', status = '', created = new Date(0), modified = new Date(0), externalClientId = null, localLanguageName = '') {
    this.PersonId = personId
    this.ActualVersion = actualVersion
    this.Name = name
    this.Country = country
    this.City = city
    this.State = state
    this.ZipCode = zipCode
    this.Address = address
    this.Phone = phone
    this.Email = email
    this.Identifier = identifier
    this.Status = status
    this.Created = created
    this.Modified = modified
    this.ExternalClientId = externalClientId
    this.LocalLanguageName = localLanguageName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Person}
   * @param {!Person} other Other struct
   * @returns {!Person} This struct
   */
  copy (other) {
    if (other.PersonId != null) {
      this.PersonId = UInt64.fromNumber(other.PersonId)
    } else {
      this.PersonId = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Country != null) {
      this.Country = other.Country
    } else {
      this.Country = null
    }
    if (other.City != null) {
      this.City = other.City
    } else {
      this.City = null
    }
    if (other.State != null) {
      this.State = other.State
    } else {
      this.State = null
    }
    if (other.ZipCode != null) {
      this.ZipCode = other.ZipCode
    } else {
      this.ZipCode = null
    }
    if (other.Address != null) {
      this.Address = other.Address
    } else {
      this.Address = null
    }
    if (other.Phone != null) {
      this.Phone = other.Phone
    } else {
      this.Phone = null
    }
    if (other.Email != null) {
      this.Email = other.Email
    } else {
      this.Email = null
    }
    if (other.Identifier != null) {
      this.Identifier = other.Identifier
    } else {
      this.Identifier = null
    }
    if (other.Status != null) {
      this.Status = other.Status
    } else {
      this.Status = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    if (other.Modified != null) {
      if (other.Modified instanceof Date) {
        this.Modified = new Date(other.Modified.getTime())
      } else {
        this.Modified = new Date(Math.round(other.Modified / 1000000))
      }
    } else {
      this.Modified = null
    }
    if (other.ExternalClientId != null) {
      this.ExternalClientId = other.ExternalClientId
    } else {
      this.ExternalClientId = null
    }
    if (other.LocalLanguageName != null) {
      this.LocalLanguageName = other.LocalLanguageName
    } else {
      this.LocalLanguageName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Person}
   * @returns {!Person} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PersonModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PersonModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Person}
   * @param {!Person} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Person)) {
      throw new TypeError('Instance of Person is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.PersonId !== other.PersonId) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Person}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      PersonId: ((this.PersonId != null) ? this.PersonId.toNumber() : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      Name: ((this.Name != null) ? this.Name : null),
      Country: ((this.Country != null) ? this.Country : null),
      City: ((this.City != null) ? this.City : null),
      State: ((this.State != null) ? this.State : null),
      ZipCode: ((this.ZipCode != null) ? this.ZipCode : null),
      Address: ((this.Address != null) ? this.Address : null),
      Phone: ((this.Phone != null) ? this.Phone : null),
      Email: ((this.Email != null) ? this.Email : null),
      Identifier: ((this.Identifier != null) ? this.Identifier : null),
      Status: ((this.Status != null) ? this.Status : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null),
      Modified: ((this.Modified != null) ? (this.Modified.getTime() * 1000000) : null),
      ExternalClientId: ((this.ExternalClientId != null) ? this.ExternalClientId : null),
      LocalLanguageName: ((this.LocalLanguageName != null) ? this.LocalLanguageName : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Person.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Person} other Object value
   * @returns {!Person} Created struct
   */
  static fromObject (other) {
    return new Person().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Person}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Person.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Person}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6522
  }
}

export { Person };

/**
 * Fast Binary Encoding Person field model
 */
class FieldModelPerson extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.PersonId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.ActualVersion = new fbe.FieldModelUInt64(buffer, this.PersonId.fbeOffset + this.PersonId.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.Country = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.City = new fbe.FieldModelString(buffer, this.Country.fbeOffset + this.Country.fbeSize)
    this.State = new fbe.FieldModelString(buffer, this.City.fbeOffset + this.City.fbeSize)
    this.ZipCode = new fbe.FieldModelString(buffer, this.State.fbeOffset + this.State.fbeSize)
    this.Address = new fbe.FieldModelString(buffer, this.ZipCode.fbeOffset + this.ZipCode.fbeSize)
    this.Phone = new fbe.FieldModelString(buffer, this.Address.fbeOffset + this.Address.fbeSize)
    this.Email = new fbe.FieldModelString(buffer, this.Phone.fbeOffset + this.Phone.fbeSize)
    this.Identifier = new fbe.FieldModelString(buffer, this.Email.fbeOffset + this.Email.fbeSize)
    this.Status = new fbe.FieldModelString(buffer, this.Identifier.fbeOffset + this.Identifier.fbeSize)
    this.Created = new fbe.FieldModelTimestamp(buffer, this.Status.fbeOffset + this.Status.fbeSize)
    this.Modified = new fbe.FieldModelTimestamp(buffer, this.Created.fbeOffset + this.Created.fbeSize)
    this.ExternalClientId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Modified.fbeOffset + this.Modified.fbeSize), buffer, this.Modified.fbeOffset + this.Modified.fbeSize)
    this.LocalLanguageName = new fbe.FieldModelString(buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelPerson}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPerson}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.PersonId.fbeSize + this.ActualVersion.fbeSize + this.Name.fbeSize + this.Country.fbeSize + this.City.fbeSize + this.State.fbeSize + this.ZipCode.fbeSize + this.Address.fbeSize + this.Phone.fbeSize + this.Email.fbeSize + this.Identifier.fbeSize + this.Status.fbeSize + this.Created.fbeSize + this.Modified.fbeSize + this.ExternalClientId.fbeSize + this.LocalLanguageName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPerson}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.PersonId.fbeExtra + this.ActualVersion.fbeExtra + this.Name.fbeExtra + this.Country.fbeExtra + this.City.fbeExtra + this.State.fbeExtra + this.ZipCode.fbeExtra + this.Address.fbeExtra + this.Phone.fbeExtra + this.Email.fbeExtra + this.Identifier.fbeExtra + this.Status.fbeExtra + this.Created.fbeExtra + this.Modified.fbeExtra + this.ExternalClientId.fbeExtra + this.LocalLanguageName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPerson}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPerson.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPerson}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6522
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPerson}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPerson}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.PersonId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PersonId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PersonId.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Country.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.City.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.State.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ZipCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Address.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Phone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Email.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Identifier.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Identifier.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Identifier.fbeSize

    if ((fbeCurrentSize + this.Status.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Status.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Status.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modified.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LocalLanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPerson}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPerson}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPerson}
   * @param {!Person} fbeValue Default value, defaults is new Person()
   * @returns {!Person} Person value
   */
  get (fbeValue = new Person()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPerson}
   * @param {!Person} fbeValue Person value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.PersonId.fbeSize) <= fbeStructSize) {
      fbeValue.PersonId = this.PersonId.get()
    } else {
      fbeValue.PersonId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PersonId.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) <= fbeStructSize) {
      fbeValue.Country = this.Country.get()
    } else {
      fbeValue.Country = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) <= fbeStructSize) {
      fbeValue.City = this.City.get()
    } else {
      fbeValue.City = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) <= fbeStructSize) {
      fbeValue.State = this.State.get()
    } else {
      fbeValue.State = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) <= fbeStructSize) {
      fbeValue.ZipCode = this.ZipCode.get()
    } else {
      fbeValue.ZipCode = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) <= fbeStructSize) {
      fbeValue.Address = this.Address.get()
    } else {
      fbeValue.Address = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) <= fbeStructSize) {
      fbeValue.Phone = this.Phone.get()
    } else {
      fbeValue.Phone = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) <= fbeStructSize) {
      fbeValue.Email = this.Email.get()
    } else {
      fbeValue.Email = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Identifier.fbeSize) <= fbeStructSize) {
      fbeValue.Identifier = this.Identifier.get()
    } else {
      fbeValue.Identifier = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Identifier.fbeSize

    if ((fbeCurrentSize + this.Status.fbeSize) <= fbeStructSize) {
      fbeValue.Status = this.Status.get()
    } else {
      fbeValue.Status = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Status.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) <= fbeStructSize) {
      fbeValue.Modified = this.Modified.get()
    } else {
      fbeValue.Modified = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalClientId = this.ExternalClientId.get()
    } else {
      fbeValue.ExternalClientId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.LocalLanguageName = this.LocalLanguageName.get()
    } else {
      fbeValue.LocalLanguageName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPerson}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPerson}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPerson}
   * @param {!Person} fbeValue Person value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPerson}
   * @param {Person} fbeValue Person value
   */
  setFields (fbeValue) {
    this.PersonId.set(fbeValue.PersonId)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.Name.set(fbeValue.Name)
    this.Country.set(fbeValue.Country)
    this.City.set(fbeValue.City)
    this.State.set(fbeValue.State)
    this.ZipCode.set(fbeValue.ZipCode)
    this.Address.set(fbeValue.Address)
    this.Phone.set(fbeValue.Phone)
    this.Email.set(fbeValue.Email)
    this.Identifier.set(fbeValue.Identifier)
    this.Status.set(fbeValue.Status)
    this.Created.set(fbeValue.Created)
    this.Modified.set(fbeValue.Modified)
    this.ExternalClientId.set(fbeValue.ExternalClientId)
    this.LocalLanguageName.set(fbeValue.LocalLanguageName)
  }
}

export { FieldModelPerson };

/**
 * Fast Binary Encoding Person model
 */
class PersonModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPerson(this.buffer, 4)
  }

  /**
   * Get the Person model
   * @this {!PersonModel}
   * @returns {!FieldModelPerson} model Person model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PersonModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PersonModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PersonModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PersonModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPerson.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PersonModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PersonModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PersonModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PersonModel}
   * @param {!Person} value Person value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PersonModel}
   * @param {!Person} value Person value, defaults is new Person()
   * @return {!object} Deserialized Person value and its size
   */
  deserialize (value = new Person()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Person(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Person(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PersonModel}
   * @param {!number} prev Previous Person model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PersonModel };
