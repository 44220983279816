
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Order} from '../core/Order';
import {FieldModelOrder} from '../core/Order';
import {TradeHistoryRecord} from '../core/TradeHistoryRecord';
import {FieldModelTradeHistoryRecord} from '../core/TradeHistoryRecord';
import {AccountMarginStable} from '../entity/AccountMarginStable';
import {FieldModelAccountMarginStable} from '../entity/AccountMarginStable';

/**
 * OrderSplit2ResponseParams struct
 */
class OrderSplit2ResponseParams {
  /**
   * Initialize struct
   * @param {!Order=} orderPrevious
   * @param {!Order=} orderCurrent
   * @param {!TradeHistoryRecord=} orderTradeHistoryRecord
   * @param {AccountMarginStable=} marginPrevious
   * @param {AccountMarginStable=} marginCurrent
   * @param {TradeHistoryRecord=} marginTradeHistoryRecord
   * @constructor
   */
  constructor (orderPrevious = new Order(), orderCurrent = new Order(), orderTradeHistoryRecord = new TradeHistoryRecord(), marginPrevious = null, marginCurrent = null, marginTradeHistoryRecord = null) {
    this.OrderPrevious = orderPrevious
    this.OrderCurrent = orderCurrent
    this.OrderTradeHistoryRecord = orderTradeHistoryRecord
    this.MarginPrevious = marginPrevious
    this.MarginCurrent = marginCurrent
    this.MarginTradeHistoryRecord = marginTradeHistoryRecord
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderSplit2ResponseParams}
   * @param {!OrderSplit2ResponseParams} other Other struct
   * @returns {!OrderSplit2ResponseParams} This struct
   */
  copy (other) {
    if (other.OrderPrevious != null) {
      this.OrderPrevious = Order.fromObject(other.OrderPrevious)
    } else {
      this.OrderPrevious = null
    }
    if (other.OrderCurrent != null) {
      this.OrderCurrent = Order.fromObject(other.OrderCurrent)
    } else {
      this.OrderCurrent = null
    }
    if (other.OrderTradeHistoryRecord != null) {
      this.OrderTradeHistoryRecord = TradeHistoryRecord.fromObject(other.OrderTradeHistoryRecord)
    } else {
      this.OrderTradeHistoryRecord = null
    }
    if (other.MarginPrevious != null) {
      this.MarginPrevious = AccountMarginStable.fromObject(other.MarginPrevious)
    } else {
      this.MarginPrevious = null
    }
    if (other.MarginCurrent != null) {
      this.MarginCurrent = AccountMarginStable.fromObject(other.MarginCurrent)
    } else {
      this.MarginCurrent = null
    }
    if (other.MarginTradeHistoryRecord != null) {
      this.MarginTradeHistoryRecord = TradeHistoryRecord.fromObject(other.MarginTradeHistoryRecord)
    } else {
      this.MarginTradeHistoryRecord = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderSplit2ResponseParams}
   * @returns {!OrderSplit2ResponseParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderSplit2ResponseParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderSplit2ResponseParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderSplit2ResponseParams}
   * @param {!OrderSplit2ResponseParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderSplit2ResponseParams)) {
      throw new TypeError('Instance of OrderSplit2ResponseParams is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderSplit2ResponseParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      OrderPrevious: ((this.OrderPrevious != null) ? this.OrderPrevious : null),
      OrderCurrent: ((this.OrderCurrent != null) ? this.OrderCurrent : null),
      OrderTradeHistoryRecord: ((this.OrderTradeHistoryRecord != null) ? this.OrderTradeHistoryRecord : null),
      MarginPrevious: ((this.MarginPrevious != null) ? this.MarginPrevious : null),
      MarginCurrent: ((this.MarginCurrent != null) ? this.MarginCurrent : null),
      MarginTradeHistoryRecord: ((this.MarginTradeHistoryRecord != null) ? this.MarginTradeHistoryRecord : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderSplit2ResponseParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderSplit2ResponseParams} other Object value
   * @returns {!OrderSplit2ResponseParams} Created struct
   */
  static fromObject (other) {
    return new OrderSplit2ResponseParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderSplit2ResponseParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderSplit2ResponseParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderSplit2ResponseParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4195
  }
}

export { OrderSplit2ResponseParams };

/**
 * Fast Binary Encoding OrderSplit2ResponseParams field model
 */
class FieldModelOrderSplit2ResponseParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.OrderPrevious = new FieldModelOrder(buffer, 4 + 4)
    this.OrderCurrent = new FieldModelOrder(buffer, this.OrderPrevious.fbeOffset + this.OrderPrevious.fbeSize)
    this.OrderTradeHistoryRecord = new FieldModelTradeHistoryRecord(buffer, this.OrderCurrent.fbeOffset + this.OrderCurrent.fbeSize)
    this.MarginPrevious = new fbe.FieldModelOptional(new FieldModelAccountMarginStable(buffer, this.OrderTradeHistoryRecord.fbeOffset + this.OrderTradeHistoryRecord.fbeSize), buffer, this.OrderTradeHistoryRecord.fbeOffset + this.OrderTradeHistoryRecord.fbeSize)
    this.MarginCurrent = new fbe.FieldModelOptional(new FieldModelAccountMarginStable(buffer, this.MarginPrevious.fbeOffset + this.MarginPrevious.fbeSize), buffer, this.MarginPrevious.fbeOffset + this.MarginPrevious.fbeSize)
    this.MarginTradeHistoryRecord = new fbe.FieldModelOptional(new FieldModelTradeHistoryRecord(buffer, this.MarginCurrent.fbeOffset + this.MarginCurrent.fbeSize), buffer, this.MarginCurrent.fbeOffset + this.MarginCurrent.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.OrderPrevious.fbeSize + this.OrderCurrent.fbeSize + this.OrderTradeHistoryRecord.fbeSize + this.MarginPrevious.fbeSize + this.MarginCurrent.fbeSize + this.MarginTradeHistoryRecord.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.OrderPrevious.fbeExtra + this.OrderCurrent.fbeExtra + this.OrderTradeHistoryRecord.fbeExtra + this.MarginPrevious.fbeExtra + this.MarginCurrent.fbeExtra + this.MarginTradeHistoryRecord.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderSplit2ResponseParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4195
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderPrevious.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderPrevious.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderPrevious.fbeSize

    if ((fbeCurrentSize + this.OrderCurrent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderCurrent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderCurrent.fbeSize

    if ((fbeCurrentSize + this.OrderTradeHistoryRecord.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderTradeHistoryRecord.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.MarginPrevious.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginPrevious.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginPrevious.fbeSize

    if ((fbeCurrentSize + this.MarginCurrent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrent.fbeSize

    if ((fbeCurrentSize + this.MarginTradeHistoryRecord.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginTradeHistoryRecord.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginTradeHistoryRecord.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @param {!OrderSplit2ResponseParams} fbeValue Default value, defaults is new OrderSplit2ResponseParams()
   * @returns {!OrderSplit2ResponseParams} OrderSplit2ResponseParams value
   */
  get (fbeValue = new OrderSplit2ResponseParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @param {!OrderSplit2ResponseParams} fbeValue OrderSplit2ResponseParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderPrevious.fbeSize) <= fbeStructSize) {
      fbeValue.OrderPrevious = this.OrderPrevious.get()
    } else {
      fbeValue.OrderPrevious = new Order()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderPrevious.fbeSize

    if ((fbeCurrentSize + this.OrderCurrent.fbeSize) <= fbeStructSize) {
      fbeValue.OrderCurrent = this.OrderCurrent.get()
    } else {
      fbeValue.OrderCurrent = new Order()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderCurrent.fbeSize

    if ((fbeCurrentSize + this.OrderTradeHistoryRecord.fbeSize) <= fbeStructSize) {
      fbeValue.OrderTradeHistoryRecord = this.OrderTradeHistoryRecord.get()
    } else {
      fbeValue.OrderTradeHistoryRecord = new TradeHistoryRecord()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.MarginPrevious.fbeSize) <= fbeStructSize) {
      fbeValue.MarginPrevious = this.MarginPrevious.get()
    } else {
      fbeValue.MarginPrevious = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginPrevious.fbeSize

    if ((fbeCurrentSize + this.MarginCurrent.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrent = this.MarginCurrent.get()
    } else {
      fbeValue.MarginCurrent = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrent.fbeSize

    if ((fbeCurrentSize + this.MarginTradeHistoryRecord.fbeSize) <= fbeStructSize) {
      fbeValue.MarginTradeHistoryRecord = this.MarginTradeHistoryRecord.get()
    } else {
      fbeValue.MarginTradeHistoryRecord = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginTradeHistoryRecord.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @param {!OrderSplit2ResponseParams} fbeValue OrderSplit2ResponseParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderSplit2ResponseParams}
   * @param {OrderSplit2ResponseParams} fbeValue OrderSplit2ResponseParams value
   */
  setFields (fbeValue) {
    this.OrderPrevious.set(fbeValue.OrderPrevious)
    this.OrderCurrent.set(fbeValue.OrderCurrent)
    this.OrderTradeHistoryRecord.set(fbeValue.OrderTradeHistoryRecord)
    this.MarginPrevious.set(fbeValue.MarginPrevious)
    this.MarginCurrent.set(fbeValue.MarginCurrent)
    this.MarginTradeHistoryRecord.set(fbeValue.MarginTradeHistoryRecord)
  }
}

export { FieldModelOrderSplit2ResponseParams };

/**
 * Fast Binary Encoding OrderSplit2ResponseParams model
 */
class OrderSplit2ResponseParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderSplit2ResponseParams(this.buffer, 4)
  }

  /**
   * Get the OrderSplit2ResponseParams model
   * @this {!OrderSplit2ResponseParamsModel}
   * @returns {!FieldModelOrderSplit2ResponseParams} model OrderSplit2ResponseParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderSplit2ResponseParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderSplit2ResponseParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderSplit2ResponseParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderSplit2ResponseParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderSplit2ResponseParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderSplit2ResponseParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderSplit2ResponseParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderSplit2ResponseParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderSplit2ResponseParamsModel}
   * @param {!OrderSplit2ResponseParams} value OrderSplit2ResponseParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderSplit2ResponseParamsModel}
   * @param {!OrderSplit2ResponseParams} value OrderSplit2ResponseParams value, defaults is new OrderSplit2ResponseParams()
   * @return {!object} Deserialized OrderSplit2ResponseParams value and its size
   */
  deserialize (value = new OrderSplit2ResponseParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderSplit2ResponseParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderSplit2ResponseParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderSplit2ResponseParamsModel}
   * @param {!number} prev Previous OrderSplit2ResponseParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderSplit2ResponseParamsModel };
