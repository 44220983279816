// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: document.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {LegalDocumentType} from './document/LegalDocumentType'
import {LegalDocumentMetadata} from './document/LegalDocumentMetadata'
import {LegalDocumentMetadataModel} from './document/LegalDocumentMetadata'
import {LegalDocumentDescription} from './document/LegalDocumentDescription'
import {LegalDocumentDescriptionModel} from './document/LegalDocumentDescription'
import {LegalDocumentGroup} from './document/LegalDocumentGroup'
import {LegalDocumentGroupModel} from './document/LegalDocumentGroup'
import {LegalDocumentGroupWithDocumentsDescriptions} from './document/LegalDocumentGroupWithDocumentsDescriptions'
import {LegalDocumentGroupWithDocumentsDescriptionsModel} from './document/LegalDocumentGroupWithDocumentsDescriptions'
import {LegalDocumentGroupGetFilterParams} from './document/LegalDocumentGroupGetFilterParams'
import {LegalDocumentGroupGetFilterParamsModel} from './document/LegalDocumentGroupGetFilterParams'
import {LegalDocumentGroupGetFilterRequest} from './document/LegalDocumentGroupGetFilterRequest'
import {LegalDocumentGroupGetFilterRequestModel} from './document/LegalDocumentGroupGetFilterRequest'
import {LegalDocumentGroupGetFilterResult} from './document/LegalDocumentGroupGetFilterResult'
import {LegalDocumentGroupGetFilterResultModel} from './document/LegalDocumentGroupGetFilterResult'
import {LegalDocumentGroupGetFilterResponse} from './document/LegalDocumentGroupGetFilterResponse'
import {LegalDocumentGroupGetFilterResponseModel} from './document/LegalDocumentGroupGetFilterResponse'
import {LegalDocumentGroupCreateParams} from './document/LegalDocumentGroupCreateParams'
import {LegalDocumentGroupCreateParamsModel} from './document/LegalDocumentGroupCreateParams'
import {LegalDocumentGroupCreateRequest} from './document/LegalDocumentGroupCreateRequest'
import {LegalDocumentGroupCreateRequestModel} from './document/LegalDocumentGroupCreateRequest'
import {LegalDocumentGroupCreateResult} from './document/LegalDocumentGroupCreateResult'
import {LegalDocumentGroupCreateResultModel} from './document/LegalDocumentGroupCreateResult'
import {LegalDocumentGroupCreateResponse} from './document/LegalDocumentGroupCreateResponse'
import {LegalDocumentGroupCreateResponseModel} from './document/LegalDocumentGroupCreateResponse'
import {LegalDocumentGroupModifyParams} from './document/LegalDocumentGroupModifyParams'
import {LegalDocumentGroupModifyParamsModel} from './document/LegalDocumentGroupModifyParams'
import {LegalDocumentGroupModifyRequest} from './document/LegalDocumentGroupModifyRequest'
import {LegalDocumentGroupModifyRequestModel} from './document/LegalDocumentGroupModifyRequest'
import {LegalDocumentGroupModifyResult} from './document/LegalDocumentGroupModifyResult'
import {LegalDocumentGroupModifyResultModel} from './document/LegalDocumentGroupModifyResult'
import {LegalDocumentGroupModifyResponse} from './document/LegalDocumentGroupModifyResponse'
import {LegalDocumentGroupModifyResponseModel} from './document/LegalDocumentGroupModifyResponse'
import {LegalDocumentGroupDeleteParams} from './document/LegalDocumentGroupDeleteParams'
import {LegalDocumentGroupDeleteParamsModel} from './document/LegalDocumentGroupDeleteParams'
import {LegalDocumentGroupDeleteRequest} from './document/LegalDocumentGroupDeleteRequest'
import {LegalDocumentGroupDeleteRequestModel} from './document/LegalDocumentGroupDeleteRequest'
import {LegalDocumentGroupDeleteResult} from './document/LegalDocumentGroupDeleteResult'
import {LegalDocumentGroupDeleteResultModel} from './document/LegalDocumentGroupDeleteResult'
import {LegalDocumentGroupDeleteResponse} from './document/LegalDocumentGroupDeleteResponse'
import {LegalDocumentGroupDeleteResponseModel} from './document/LegalDocumentGroupDeleteResponse'
import {LegalDocumentUploadParams} from './document/LegalDocumentUploadParams'
import {LegalDocumentUploadParamsModel} from './document/LegalDocumentUploadParams'
import {LegalDocumentUploadRequest} from './document/LegalDocumentUploadRequest'
import {LegalDocumentUploadRequestModel} from './document/LegalDocumentUploadRequest'
import {LegalDocumentUploadResult} from './document/LegalDocumentUploadResult'
import {LegalDocumentUploadResultModel} from './document/LegalDocumentUploadResult'
import {LegalDocumentUploadResponse} from './document/LegalDocumentUploadResponse'
import {LegalDocumentUploadResponseModel} from './document/LegalDocumentUploadResponse'
import {LegalDocumentGetParams} from './document/LegalDocumentGetParams'
import {LegalDocumentGetParamsModel} from './document/LegalDocumentGetParams'
import {LegalDocumentGetRequest} from './document/LegalDocumentGetRequest'
import {LegalDocumentGetRequestModel} from './document/LegalDocumentGetRequest'
import {LegalDocumentGetResult} from './document/LegalDocumentGetResult'
import {LegalDocumentGetResultModel} from './document/LegalDocumentGetResult'
import {LegalDocumentGetResponse} from './document/LegalDocumentGetResponse'
import {LegalDocumentGetResponseModel} from './document/LegalDocumentGetResponse'
import {LegalDocumentDeleteParams} from './document/LegalDocumentDeleteParams'
import {LegalDocumentDeleteParamsModel} from './document/LegalDocumentDeleteParams'
import {LegalDocumentDeleteRequest} from './document/LegalDocumentDeleteRequest'
import {LegalDocumentDeleteRequestModel} from './document/LegalDocumentDeleteRequest'
import {LegalDocumentDeleteResult} from './document/LegalDocumentDeleteResult'
import {LegalDocumentDeleteResultModel} from './document/LegalDocumentDeleteResult'
import {LegalDocumentDeleteResponse} from './document/LegalDocumentDeleteResponse'
import {LegalDocumentDeleteResponseModel} from './document/LegalDocumentDeleteResponse'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding document sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize document sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._LegalDocumentGroupGetFilterParamsModel = new LegalDocumentGroupGetFilterParamsModel(this.buffer)
    this._LegalDocumentGroupGetFilterRequestModel = new LegalDocumentGroupGetFilterRequestModel(this.buffer)
    this._LegalDocumentGroupGetFilterResultModel = new LegalDocumentGroupGetFilterResultModel(this.buffer)
    this._LegalDocumentGroupGetFilterResponseModel = new LegalDocumentGroupGetFilterResponseModel(this.buffer)
    this._LegalDocumentGroupCreateParamsModel = new LegalDocumentGroupCreateParamsModel(this.buffer)
    this._LegalDocumentGroupCreateRequestModel = new LegalDocumentGroupCreateRequestModel(this.buffer)
    this._LegalDocumentGroupCreateResultModel = new LegalDocumentGroupCreateResultModel(this.buffer)
    this._LegalDocumentGroupCreateResponseModel = new LegalDocumentGroupCreateResponseModel(this.buffer)
    this._LegalDocumentGroupModifyParamsModel = new LegalDocumentGroupModifyParamsModel(this.buffer)
    this._LegalDocumentGroupModifyRequestModel = new LegalDocumentGroupModifyRequestModel(this.buffer)
    this._LegalDocumentGroupModifyResultModel = new LegalDocumentGroupModifyResultModel(this.buffer)
    this._LegalDocumentGroupModifyResponseModel = new LegalDocumentGroupModifyResponseModel(this.buffer)
    this._LegalDocumentGroupDeleteParamsModel = new LegalDocumentGroupDeleteParamsModel(this.buffer)
    this._LegalDocumentGroupDeleteRequestModel = new LegalDocumentGroupDeleteRequestModel(this.buffer)
    this._LegalDocumentGroupDeleteResultModel = new LegalDocumentGroupDeleteResultModel(this.buffer)
    this._LegalDocumentGroupDeleteResponseModel = new LegalDocumentGroupDeleteResponseModel(this.buffer)
    this._LegalDocumentUploadParamsModel = new LegalDocumentUploadParamsModel(this.buffer)
    this._LegalDocumentUploadRequestModel = new LegalDocumentUploadRequestModel(this.buffer)
    this._LegalDocumentUploadResultModel = new LegalDocumentUploadResultModel(this.buffer)
    this._LegalDocumentUploadResponseModel = new LegalDocumentUploadResponseModel(this.buffer)
    this._LegalDocumentGetParamsModel = new LegalDocumentGetParamsModel(this.buffer)
    this._LegalDocumentGetRequestModel = new LegalDocumentGetRequestModel(this.buffer)
    this._LegalDocumentGetResultModel = new LegalDocumentGetResultModel(this.buffer)
    this._LegalDocumentGetResponseModel = new LegalDocumentGetResponseModel(this.buffer)
    this._LegalDocumentDeleteParamsModel = new LegalDocumentDeleteParamsModel(this.buffer)
    this._LegalDocumentDeleteRequestModel = new LegalDocumentDeleteRequestModel(this.buffer)
    this._LegalDocumentDeleteResultModel = new LegalDocumentDeleteResultModel(this.buffer)
    this._LegalDocumentDeleteResponseModel = new LegalDocumentDeleteResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  // Sender models accessors

  /**
   * Get LegalDocumentGroupGetFilterParams model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupGetFilterParamsModel} LegalDocumentGroupGetFilterParams model
   */
  get LegalDocumentGroupGetFilterParamsModel () {
    return this._LegalDocumentGroupGetFilterParamsModel
  }

  /**
   * Get LegalDocumentGroupGetFilterRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupGetFilterRequestModel} LegalDocumentGroupGetFilterRequest model
   */
  get LegalDocumentGroupGetFilterRequestModel () {
    return this._LegalDocumentGroupGetFilterRequestModel
  }

  /**
   * Get LegalDocumentGroupGetFilterResult model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupGetFilterResultModel} LegalDocumentGroupGetFilterResult model
   */
  get LegalDocumentGroupGetFilterResultModel () {
    return this._LegalDocumentGroupGetFilterResultModel
  }

  /**
   * Get LegalDocumentGroupGetFilterResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupGetFilterResponseModel} LegalDocumentGroupGetFilterResponse model
   */
  get LegalDocumentGroupGetFilterResponseModel () {
    return this._LegalDocumentGroupGetFilterResponseModel
  }

  /**
   * Get LegalDocumentGroupCreateParams model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupCreateParamsModel} LegalDocumentGroupCreateParams model
   */
  get LegalDocumentGroupCreateParamsModel () {
    return this._LegalDocumentGroupCreateParamsModel
  }

  /**
   * Get LegalDocumentGroupCreateRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupCreateRequestModel} LegalDocumentGroupCreateRequest model
   */
  get LegalDocumentGroupCreateRequestModel () {
    return this._LegalDocumentGroupCreateRequestModel
  }

  /**
   * Get LegalDocumentGroupCreateResult model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupCreateResultModel} LegalDocumentGroupCreateResult model
   */
  get LegalDocumentGroupCreateResultModel () {
    return this._LegalDocumentGroupCreateResultModel
  }

  /**
   * Get LegalDocumentGroupCreateResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupCreateResponseModel} LegalDocumentGroupCreateResponse model
   */
  get LegalDocumentGroupCreateResponseModel () {
    return this._LegalDocumentGroupCreateResponseModel
  }

  /**
   * Get LegalDocumentGroupModifyParams model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupModifyParamsModel} LegalDocumentGroupModifyParams model
   */
  get LegalDocumentGroupModifyParamsModel () {
    return this._LegalDocumentGroupModifyParamsModel
  }

  /**
   * Get LegalDocumentGroupModifyRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupModifyRequestModel} LegalDocumentGroupModifyRequest model
   */
  get LegalDocumentGroupModifyRequestModel () {
    return this._LegalDocumentGroupModifyRequestModel
  }

  /**
   * Get LegalDocumentGroupModifyResult model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupModifyResultModel} LegalDocumentGroupModifyResult model
   */
  get LegalDocumentGroupModifyResultModel () {
    return this._LegalDocumentGroupModifyResultModel
  }

  /**
   * Get LegalDocumentGroupModifyResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupModifyResponseModel} LegalDocumentGroupModifyResponse model
   */
  get LegalDocumentGroupModifyResponseModel () {
    return this._LegalDocumentGroupModifyResponseModel
  }

  /**
   * Get LegalDocumentGroupDeleteParams model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupDeleteParamsModel} LegalDocumentGroupDeleteParams model
   */
  get LegalDocumentGroupDeleteParamsModel () {
    return this._LegalDocumentGroupDeleteParamsModel
  }

  /**
   * Get LegalDocumentGroupDeleteRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupDeleteRequestModel} LegalDocumentGroupDeleteRequest model
   */
  get LegalDocumentGroupDeleteRequestModel () {
    return this._LegalDocumentGroupDeleteRequestModel
  }

  /**
   * Get LegalDocumentGroupDeleteResult model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupDeleteResultModel} LegalDocumentGroupDeleteResult model
   */
  get LegalDocumentGroupDeleteResultModel () {
    return this._LegalDocumentGroupDeleteResultModel
  }

  /**
   * Get LegalDocumentGroupDeleteResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupDeleteResponseModel} LegalDocumentGroupDeleteResponse model
   */
  get LegalDocumentGroupDeleteResponseModel () {
    return this._LegalDocumentGroupDeleteResponseModel
  }

  /**
   * Get LegalDocumentUploadParams model
   * @this {!Sender}
   * @returns {!LegalDocumentUploadParamsModel} LegalDocumentUploadParams model
   */
  get LegalDocumentUploadParamsModel () {
    return this._LegalDocumentUploadParamsModel
  }

  /**
   * Get LegalDocumentUploadRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentUploadRequestModel} LegalDocumentUploadRequest model
   */
  get LegalDocumentUploadRequestModel () {
    return this._LegalDocumentUploadRequestModel
  }

  /**
   * Get LegalDocumentUploadResult model
   * @this {!Sender}
   * @returns {!LegalDocumentUploadResultModel} LegalDocumentUploadResult model
   */
  get LegalDocumentUploadResultModel () {
    return this._LegalDocumentUploadResultModel
  }

  /**
   * Get LegalDocumentUploadResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentUploadResponseModel} LegalDocumentUploadResponse model
   */
  get LegalDocumentUploadResponseModel () {
    return this._LegalDocumentUploadResponseModel
  }

  /**
   * Get LegalDocumentGetParams model
   * @this {!Sender}
   * @returns {!LegalDocumentGetParamsModel} LegalDocumentGetParams model
   */
  get LegalDocumentGetParamsModel () {
    return this._LegalDocumentGetParamsModel
  }

  /**
   * Get LegalDocumentGetRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentGetRequestModel} LegalDocumentGetRequest model
   */
  get LegalDocumentGetRequestModel () {
    return this._LegalDocumentGetRequestModel
  }

  /**
   * Get LegalDocumentGetResult model
   * @this {!Sender}
   * @returns {!LegalDocumentGetResultModel} LegalDocumentGetResult model
   */
  get LegalDocumentGetResultModel () {
    return this._LegalDocumentGetResultModel
  }

  /**
   * Get LegalDocumentGetResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentGetResponseModel} LegalDocumentGetResponse model
   */
  get LegalDocumentGetResponseModel () {
    return this._LegalDocumentGetResponseModel
  }

  /**
   * Get LegalDocumentDeleteParams model
   * @this {!Sender}
   * @returns {!LegalDocumentDeleteParamsModel} LegalDocumentDeleteParams model
   */
  get LegalDocumentDeleteParamsModel () {
    return this._LegalDocumentDeleteParamsModel
  }

  /**
   * Get LegalDocumentDeleteRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentDeleteRequestModel} LegalDocumentDeleteRequest model
   */
  get LegalDocumentDeleteRequestModel () {
    return this._LegalDocumentDeleteRequestModel
  }

  /**
   * Get LegalDocumentDeleteResult model
   * @this {!Sender}
   * @returns {!LegalDocumentDeleteResultModel} LegalDocumentDeleteResult model
   */
  get LegalDocumentDeleteResultModel () {
    return this._LegalDocumentDeleteResultModel
  }

  /**
   * Get LegalDocumentDeleteResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentDeleteResponseModel} LegalDocumentDeleteResponse model
   */
  get LegalDocumentDeleteResponseModel () {
    return this._LegalDocumentDeleteResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof LegalDocumentGroupGetFilterParams) && (value.fbeType === this.LegalDocumentGroupGetFilterParamsModel.fbeType)) {
      return this.send_LegalDocumentGroupGetFilterParams(value)
    }
    if ((value instanceof LegalDocumentGroupGetFilterRequest) && (value.fbeType === this.LegalDocumentGroupGetFilterRequestModel.fbeType)) {
      return this.send_LegalDocumentGroupGetFilterRequest(value)
    }
    if ((value instanceof LegalDocumentGroupGetFilterResult) && (value.fbeType === this.LegalDocumentGroupGetFilterResultModel.fbeType)) {
      return this.send_LegalDocumentGroupGetFilterResult(value)
    }
    if ((value instanceof LegalDocumentGroupGetFilterResponse) && (value.fbeType === this.LegalDocumentGroupGetFilterResponseModel.fbeType)) {
      return this.send_LegalDocumentGroupGetFilterResponse(value)
    }
    if ((value instanceof LegalDocumentGroupCreateParams) && (value.fbeType === this.LegalDocumentGroupCreateParamsModel.fbeType)) {
      return this.send_LegalDocumentGroupCreateParams(value)
    }
    if ((value instanceof LegalDocumentGroupCreateRequest) && (value.fbeType === this.LegalDocumentGroupCreateRequestModel.fbeType)) {
      return this.send_LegalDocumentGroupCreateRequest(value)
    }
    if ((value instanceof LegalDocumentGroupCreateResult) && (value.fbeType === this.LegalDocumentGroupCreateResultModel.fbeType)) {
      return this.send_LegalDocumentGroupCreateResult(value)
    }
    if ((value instanceof LegalDocumentGroupCreateResponse) && (value.fbeType === this.LegalDocumentGroupCreateResponseModel.fbeType)) {
      return this.send_LegalDocumentGroupCreateResponse(value)
    }
    if ((value instanceof LegalDocumentGroupModifyParams) && (value.fbeType === this.LegalDocumentGroupModifyParamsModel.fbeType)) {
      return this.send_LegalDocumentGroupModifyParams(value)
    }
    if ((value instanceof LegalDocumentGroupModifyRequest) && (value.fbeType === this.LegalDocumentGroupModifyRequestModel.fbeType)) {
      return this.send_LegalDocumentGroupModifyRequest(value)
    }
    if ((value instanceof LegalDocumentGroupModifyResult) && (value.fbeType === this.LegalDocumentGroupModifyResultModel.fbeType)) {
      return this.send_LegalDocumentGroupModifyResult(value)
    }
    if ((value instanceof LegalDocumentGroupModifyResponse) && (value.fbeType === this.LegalDocumentGroupModifyResponseModel.fbeType)) {
      return this.send_LegalDocumentGroupModifyResponse(value)
    }
    if ((value instanceof LegalDocumentGroupDeleteParams) && (value.fbeType === this.LegalDocumentGroupDeleteParamsModel.fbeType)) {
      return this.send_LegalDocumentGroupDeleteParams(value)
    }
    if ((value instanceof LegalDocumentGroupDeleteRequest) && (value.fbeType === this.LegalDocumentGroupDeleteRequestModel.fbeType)) {
      return this.send_LegalDocumentGroupDeleteRequest(value)
    }
    if ((value instanceof LegalDocumentGroupDeleteResult) && (value.fbeType === this.LegalDocumentGroupDeleteResultModel.fbeType)) {
      return this.send_LegalDocumentGroupDeleteResult(value)
    }
    if ((value instanceof LegalDocumentGroupDeleteResponse) && (value.fbeType === this.LegalDocumentGroupDeleteResponseModel.fbeType)) {
      return this.send_LegalDocumentGroupDeleteResponse(value)
    }
    if ((value instanceof LegalDocumentUploadParams) && (value.fbeType === this.LegalDocumentUploadParamsModel.fbeType)) {
      return this.send_LegalDocumentUploadParams(value)
    }
    if ((value instanceof LegalDocumentUploadRequest) && (value.fbeType === this.LegalDocumentUploadRequestModel.fbeType)) {
      return this.send_LegalDocumentUploadRequest(value)
    }
    if ((value instanceof LegalDocumentUploadResult) && (value.fbeType === this.LegalDocumentUploadResultModel.fbeType)) {
      return this.send_LegalDocumentUploadResult(value)
    }
    if ((value instanceof LegalDocumentUploadResponse) && (value.fbeType === this.LegalDocumentUploadResponseModel.fbeType)) {
      return this.send_LegalDocumentUploadResponse(value)
    }
    if ((value instanceof LegalDocumentGetParams) && (value.fbeType === this.LegalDocumentGetParamsModel.fbeType)) {
      return this.send_LegalDocumentGetParams(value)
    }
    if ((value instanceof LegalDocumentGetRequest) && (value.fbeType === this.LegalDocumentGetRequestModel.fbeType)) {
      return this.send_LegalDocumentGetRequest(value)
    }
    if ((value instanceof LegalDocumentGetResult) && (value.fbeType === this.LegalDocumentGetResultModel.fbeType)) {
      return this.send_LegalDocumentGetResult(value)
    }
    if ((value instanceof LegalDocumentGetResponse) && (value.fbeType === this.LegalDocumentGetResponseModel.fbeType)) {
      return this.send_LegalDocumentGetResponse(value)
    }
    if ((value instanceof LegalDocumentDeleteParams) && (value.fbeType === this.LegalDocumentDeleteParamsModel.fbeType)) {
      return this.send_LegalDocumentDeleteParams(value)
    }
    if ((value instanceof LegalDocumentDeleteRequest) && (value.fbeType === this.LegalDocumentDeleteRequestModel.fbeType)) {
      return this.send_LegalDocumentDeleteRequest(value)
    }
    if ((value instanceof LegalDocumentDeleteResult) && (value.fbeType === this.LegalDocumentDeleteResultModel.fbeType)) {
      return this.send_LegalDocumentDeleteResult(value)
    }
    if ((value instanceof LegalDocumentDeleteResponse) && (value.fbeType === this.LegalDocumentDeleteResponseModel.fbeType)) {
      return this.send_LegalDocumentDeleteResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send LegalDocumentGroupGetFilterParams value
   * @this {!Sender}
   * @param {!LegalDocumentGroupGetFilterParams} value LegalDocumentGroupGetFilterParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupGetFilterParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupGetFilterParamsModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupGetFilterParams serialization failed!')
    console.assert(this.LegalDocumentGroupGetFilterParamsModel.verify(), 'document.LegalDocumentGroupGetFilterParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupGetFilterRequest value
   * @this {!Sender}
   * @param {!LegalDocumentGroupGetFilterRequest} value LegalDocumentGroupGetFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupGetFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupGetFilterRequestModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupGetFilterRequest serialization failed!')
    console.assert(this.LegalDocumentGroupGetFilterRequestModel.verify(), 'document.LegalDocumentGroupGetFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupGetFilterResult value
   * @this {!Sender}
   * @param {!LegalDocumentGroupGetFilterResult} value LegalDocumentGroupGetFilterResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupGetFilterResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupGetFilterResultModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupGetFilterResult serialization failed!')
    console.assert(this.LegalDocumentGroupGetFilterResultModel.verify(), 'document.LegalDocumentGroupGetFilterResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupGetFilterResponse value
   * @this {!Sender}
   * @param {!LegalDocumentGroupGetFilterResponse} value LegalDocumentGroupGetFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupGetFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupGetFilterResponseModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupGetFilterResponse serialization failed!')
    console.assert(this.LegalDocumentGroupGetFilterResponseModel.verify(), 'document.LegalDocumentGroupGetFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupCreateParams value
   * @this {!Sender}
   * @param {!LegalDocumentGroupCreateParams} value LegalDocumentGroupCreateParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupCreateParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupCreateParamsModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupCreateParams serialization failed!')
    console.assert(this.LegalDocumentGroupCreateParamsModel.verify(), 'document.LegalDocumentGroupCreateParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupCreateRequest value
   * @this {!Sender}
   * @param {!LegalDocumentGroupCreateRequest} value LegalDocumentGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupCreateRequest serialization failed!')
    console.assert(this.LegalDocumentGroupCreateRequestModel.verify(), 'document.LegalDocumentGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupCreateResult value
   * @this {!Sender}
   * @param {!LegalDocumentGroupCreateResult} value LegalDocumentGroupCreateResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupCreateResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupCreateResultModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupCreateResult serialization failed!')
    console.assert(this.LegalDocumentGroupCreateResultModel.verify(), 'document.LegalDocumentGroupCreateResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupCreateResponse value
   * @this {!Sender}
   * @param {!LegalDocumentGroupCreateResponse} value LegalDocumentGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupCreateResponse serialization failed!')
    console.assert(this.LegalDocumentGroupCreateResponseModel.verify(), 'document.LegalDocumentGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupModifyParams value
   * @this {!Sender}
   * @param {!LegalDocumentGroupModifyParams} value LegalDocumentGroupModifyParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupModifyParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupModifyParamsModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupModifyParams serialization failed!')
    console.assert(this.LegalDocumentGroupModifyParamsModel.verify(), 'document.LegalDocumentGroupModifyParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupModifyRequest value
   * @this {!Sender}
   * @param {!LegalDocumentGroupModifyRequest} value LegalDocumentGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupModifyRequest serialization failed!')
    console.assert(this.LegalDocumentGroupModifyRequestModel.verify(), 'document.LegalDocumentGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupModifyResult value
   * @this {!Sender}
   * @param {!LegalDocumentGroupModifyResult} value LegalDocumentGroupModifyResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupModifyResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupModifyResultModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupModifyResult serialization failed!')
    console.assert(this.LegalDocumentGroupModifyResultModel.verify(), 'document.LegalDocumentGroupModifyResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupModifyResponse value
   * @this {!Sender}
   * @param {!LegalDocumentGroupModifyResponse} value LegalDocumentGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupModifyResponse serialization failed!')
    console.assert(this.LegalDocumentGroupModifyResponseModel.verify(), 'document.LegalDocumentGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupDeleteParams value
   * @this {!Sender}
   * @param {!LegalDocumentGroupDeleteParams} value LegalDocumentGroupDeleteParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupDeleteParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupDeleteParamsModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupDeleteParams serialization failed!')
    console.assert(this.LegalDocumentGroupDeleteParamsModel.verify(), 'document.LegalDocumentGroupDeleteParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupDeleteRequest value
   * @this {!Sender}
   * @param {!LegalDocumentGroupDeleteRequest} value LegalDocumentGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupDeleteRequest serialization failed!')
    console.assert(this.LegalDocumentGroupDeleteRequestModel.verify(), 'document.LegalDocumentGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupDeleteResult value
   * @this {!Sender}
   * @param {!LegalDocumentGroupDeleteResult} value LegalDocumentGroupDeleteResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupDeleteResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupDeleteResultModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupDeleteResult serialization failed!')
    console.assert(this.LegalDocumentGroupDeleteResultModel.verify(), 'document.LegalDocumentGroupDeleteResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupDeleteResponse value
   * @this {!Sender}
   * @param {!LegalDocumentGroupDeleteResponse} value LegalDocumentGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupDeleteResponse serialization failed!')
    console.assert(this.LegalDocumentGroupDeleteResponseModel.verify(), 'document.LegalDocumentGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentUploadParams value
   * @this {!Sender}
   * @param {!LegalDocumentUploadParams} value LegalDocumentUploadParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentUploadParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentUploadParamsModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentUploadParams serialization failed!')
    console.assert(this.LegalDocumentUploadParamsModel.verify(), 'document.LegalDocumentUploadParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentUploadRequest value
   * @this {!Sender}
   * @param {!LegalDocumentUploadRequest} value LegalDocumentUploadRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentUploadRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentUploadRequestModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentUploadRequest serialization failed!')
    console.assert(this.LegalDocumentUploadRequestModel.verify(), 'document.LegalDocumentUploadRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentUploadResult value
   * @this {!Sender}
   * @param {!LegalDocumentUploadResult} value LegalDocumentUploadResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentUploadResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentUploadResultModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentUploadResult serialization failed!')
    console.assert(this.LegalDocumentUploadResultModel.verify(), 'document.LegalDocumentUploadResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentUploadResponse value
   * @this {!Sender}
   * @param {!LegalDocumentUploadResponse} value LegalDocumentUploadResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentUploadResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentUploadResponseModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentUploadResponse serialization failed!')
    console.assert(this.LegalDocumentUploadResponseModel.verify(), 'document.LegalDocumentUploadResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetParams value
   * @this {!Sender}
   * @param {!LegalDocumentGetParams} value LegalDocumentGetParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetParamsModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGetParams serialization failed!')
    console.assert(this.LegalDocumentGetParamsModel.verify(), 'document.LegalDocumentGetParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetRequest value
   * @this {!Sender}
   * @param {!LegalDocumentGetRequest} value LegalDocumentGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGetRequest serialization failed!')
    console.assert(this.LegalDocumentGetRequestModel.verify(), 'document.LegalDocumentGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetResult value
   * @this {!Sender}
   * @param {!LegalDocumentGetResult} value LegalDocumentGetResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetResultModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGetResult serialization failed!')
    console.assert(this.LegalDocumentGetResultModel.verify(), 'document.LegalDocumentGetResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetResponse value
   * @this {!Sender}
   * @param {!LegalDocumentGetResponse} value LegalDocumentGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGetResponse serialization failed!')
    console.assert(this.LegalDocumentGetResponseModel.verify(), 'document.LegalDocumentGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentDeleteParams value
   * @this {!Sender}
   * @param {!LegalDocumentDeleteParams} value LegalDocumentDeleteParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentDeleteParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentDeleteParamsModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentDeleteParams serialization failed!')
    console.assert(this.LegalDocumentDeleteParamsModel.verify(), 'document.LegalDocumentDeleteParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentDeleteRequest value
   * @this {!Sender}
   * @param {!LegalDocumentDeleteRequest} value LegalDocumentDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentDeleteRequest serialization failed!')
    console.assert(this.LegalDocumentDeleteRequestModel.verify(), 'document.LegalDocumentDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentDeleteResult value
   * @this {!Sender}
   * @param {!LegalDocumentDeleteResult} value LegalDocumentDeleteResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentDeleteResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentDeleteResultModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentDeleteResult serialization failed!')
    console.assert(this.LegalDocumentDeleteResultModel.verify(), 'document.LegalDocumentDeleteResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentDeleteResponse value
   * @this {!Sender}
   * @param {!LegalDocumentDeleteResponse} value LegalDocumentDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentDeleteResponse serialization failed!')
    console.assert(this.LegalDocumentDeleteResponseModel.verify(), 'document.LegalDocumentDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'document.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding document receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize document receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._LegalDocumentGroupGetFilterParamsValue = new LegalDocumentGroupGetFilterParams()
    this._LegalDocumentGroupGetFilterParamsModel = new LegalDocumentGroupGetFilterParamsModel()
    this._LegalDocumentGroupGetFilterRequestValue = new LegalDocumentGroupGetFilterRequest()
    this._LegalDocumentGroupGetFilterRequestModel = new LegalDocumentGroupGetFilterRequestModel()
    this._LegalDocumentGroupGetFilterResultValue = new LegalDocumentGroupGetFilterResult()
    this._LegalDocumentGroupGetFilterResultModel = new LegalDocumentGroupGetFilterResultModel()
    this._LegalDocumentGroupGetFilterResponseValue = new LegalDocumentGroupGetFilterResponse()
    this._LegalDocumentGroupGetFilterResponseModel = new LegalDocumentGroupGetFilterResponseModel()
    this._LegalDocumentGroupCreateParamsValue = new LegalDocumentGroupCreateParams()
    this._LegalDocumentGroupCreateParamsModel = new LegalDocumentGroupCreateParamsModel()
    this._LegalDocumentGroupCreateRequestValue = new LegalDocumentGroupCreateRequest()
    this._LegalDocumentGroupCreateRequestModel = new LegalDocumentGroupCreateRequestModel()
    this._LegalDocumentGroupCreateResultValue = new LegalDocumentGroupCreateResult()
    this._LegalDocumentGroupCreateResultModel = new LegalDocumentGroupCreateResultModel()
    this._LegalDocumentGroupCreateResponseValue = new LegalDocumentGroupCreateResponse()
    this._LegalDocumentGroupCreateResponseModel = new LegalDocumentGroupCreateResponseModel()
    this._LegalDocumentGroupModifyParamsValue = new LegalDocumentGroupModifyParams()
    this._LegalDocumentGroupModifyParamsModel = new LegalDocumentGroupModifyParamsModel()
    this._LegalDocumentGroupModifyRequestValue = new LegalDocumentGroupModifyRequest()
    this._LegalDocumentGroupModifyRequestModel = new LegalDocumentGroupModifyRequestModel()
    this._LegalDocumentGroupModifyResultValue = new LegalDocumentGroupModifyResult()
    this._LegalDocumentGroupModifyResultModel = new LegalDocumentGroupModifyResultModel()
    this._LegalDocumentGroupModifyResponseValue = new LegalDocumentGroupModifyResponse()
    this._LegalDocumentGroupModifyResponseModel = new LegalDocumentGroupModifyResponseModel()
    this._LegalDocumentGroupDeleteParamsValue = new LegalDocumentGroupDeleteParams()
    this._LegalDocumentGroupDeleteParamsModel = new LegalDocumentGroupDeleteParamsModel()
    this._LegalDocumentGroupDeleteRequestValue = new LegalDocumentGroupDeleteRequest()
    this._LegalDocumentGroupDeleteRequestModel = new LegalDocumentGroupDeleteRequestModel()
    this._LegalDocumentGroupDeleteResultValue = new LegalDocumentGroupDeleteResult()
    this._LegalDocumentGroupDeleteResultModel = new LegalDocumentGroupDeleteResultModel()
    this._LegalDocumentGroupDeleteResponseValue = new LegalDocumentGroupDeleteResponse()
    this._LegalDocumentGroupDeleteResponseModel = new LegalDocumentGroupDeleteResponseModel()
    this._LegalDocumentUploadParamsValue = new LegalDocumentUploadParams()
    this._LegalDocumentUploadParamsModel = new LegalDocumentUploadParamsModel()
    this._LegalDocumentUploadRequestValue = new LegalDocumentUploadRequest()
    this._LegalDocumentUploadRequestModel = new LegalDocumentUploadRequestModel()
    this._LegalDocumentUploadResultValue = new LegalDocumentUploadResult()
    this._LegalDocumentUploadResultModel = new LegalDocumentUploadResultModel()
    this._LegalDocumentUploadResponseValue = new LegalDocumentUploadResponse()
    this._LegalDocumentUploadResponseModel = new LegalDocumentUploadResponseModel()
    this._LegalDocumentGetParamsValue = new LegalDocumentGetParams()
    this._LegalDocumentGetParamsModel = new LegalDocumentGetParamsModel()
    this._LegalDocumentGetRequestValue = new LegalDocumentGetRequest()
    this._LegalDocumentGetRequestModel = new LegalDocumentGetRequestModel()
    this._LegalDocumentGetResultValue = new LegalDocumentGetResult()
    this._LegalDocumentGetResultModel = new LegalDocumentGetResultModel()
    this._LegalDocumentGetResponseValue = new LegalDocumentGetResponse()
    this._LegalDocumentGetResponseModel = new LegalDocumentGetResponseModel()
    this._LegalDocumentDeleteParamsValue = new LegalDocumentDeleteParams()
    this._LegalDocumentDeleteParamsModel = new LegalDocumentDeleteParamsModel()
    this._LegalDocumentDeleteRequestValue = new LegalDocumentDeleteRequest()
    this._LegalDocumentDeleteRequestModel = new LegalDocumentDeleteRequestModel()
    this._LegalDocumentDeleteResultValue = new LegalDocumentDeleteResult()
    this._LegalDocumentDeleteResultModel = new LegalDocumentDeleteResultModel()
    this._LegalDocumentDeleteResponseValue = new LegalDocumentDeleteResponse()
    this._LegalDocumentDeleteResponseModel = new LegalDocumentDeleteResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterParams receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupGetFilterParams} value LegalDocumentGroupGetFilterParams received value
   */
  onReceive_LegalDocumentGroupGetFilterParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupGetFilterRequest} value LegalDocumentGroupGetFilterRequest received value
   */
  onReceive_LegalDocumentGroupGetFilterRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterResult receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupGetFilterResult} value LegalDocumentGroupGetFilterResult received value
   */
  onReceive_LegalDocumentGroupGetFilterResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupGetFilterResponse} value LegalDocumentGroupGetFilterResponse received value
   */
  onReceive_LegalDocumentGroupGetFilterResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateParams receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupCreateParams} value LegalDocumentGroupCreateParams received value
   */
  onReceive_LegalDocumentGroupCreateParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupCreateRequest} value LegalDocumentGroupCreateRequest received value
   */
  onReceive_LegalDocumentGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateResult receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupCreateResult} value LegalDocumentGroupCreateResult received value
   */
  onReceive_LegalDocumentGroupCreateResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupCreateResponse} value LegalDocumentGroupCreateResponse received value
   */
  onReceive_LegalDocumentGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyParams receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupModifyParams} value LegalDocumentGroupModifyParams received value
   */
  onReceive_LegalDocumentGroupModifyParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupModifyRequest} value LegalDocumentGroupModifyRequest received value
   */
  onReceive_LegalDocumentGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyResult receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupModifyResult} value LegalDocumentGroupModifyResult received value
   */
  onReceive_LegalDocumentGroupModifyResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupModifyResponse} value LegalDocumentGroupModifyResponse received value
   */
  onReceive_LegalDocumentGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteParams receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupDeleteParams} value LegalDocumentGroupDeleteParams received value
   */
  onReceive_LegalDocumentGroupDeleteParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupDeleteRequest} value LegalDocumentGroupDeleteRequest received value
   */
  onReceive_LegalDocumentGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteResult receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupDeleteResult} value LegalDocumentGroupDeleteResult received value
   */
  onReceive_LegalDocumentGroupDeleteResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupDeleteResponse} value LegalDocumentGroupDeleteResponse received value
   */
  onReceive_LegalDocumentGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadParams receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentUploadParams} value LegalDocumentUploadParams received value
   */
  onReceive_LegalDocumentUploadParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentUploadRequest} value LegalDocumentUploadRequest received value
   */
  onReceive_LegalDocumentUploadRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadResult receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentUploadResult} value LegalDocumentUploadResult received value
   */
  onReceive_LegalDocumentUploadResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentUploadResponse} value LegalDocumentUploadResponse received value
   */
  onReceive_LegalDocumentUploadResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetParams receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGetParams} value LegalDocumentGetParams received value
   */
  onReceive_LegalDocumentGetParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGetRequest} value LegalDocumentGetRequest received value
   */
  onReceive_LegalDocumentGetRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResult receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGetResult} value LegalDocumentGetResult received value
   */
  onReceive_LegalDocumentGetResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGetResponse} value LegalDocumentGetResponse received value
   */
  onReceive_LegalDocumentGetResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteParams receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentDeleteParams} value LegalDocumentDeleteParams received value
   */
  onReceive_LegalDocumentDeleteParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentDeleteRequest} value LegalDocumentDeleteRequest received value
   */
  onReceive_LegalDocumentDeleteRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteResult receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentDeleteResult} value LegalDocumentDeleteResult received value
   */
  onReceive_LegalDocumentDeleteResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentDeleteResponse} value LegalDocumentDeleteResponse received value
   */
  onReceive_LegalDocumentDeleteResponse (value) {}  // eslint-disable-line

  /**
   * document receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case LegalDocumentGroupGetFilterParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupGetFilterParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterParamsModel.verify(), 'document.LegalDocumentGroupGetFilterParams validation failed!')
        let deserialized = this._LegalDocumentGroupGetFilterParamsModel.deserialize(this._LegalDocumentGroupGetFilterParamsValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupGetFilterParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupGetFilterParamsValue)
        this.onReceive_LegalDocumentGroupGetFilterParams(this._LegalDocumentGroupGetFilterParamsValue)
        return true
      }
      case LegalDocumentGroupGetFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupGetFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterRequestModel.verify(), 'document.LegalDocumentGroupGetFilterRequest validation failed!')
        let deserialized = this._LegalDocumentGroupGetFilterRequestModel.deserialize(this._LegalDocumentGroupGetFilterRequestValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupGetFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupGetFilterRequestValue)
        this.onReceive_LegalDocumentGroupGetFilterRequest(this._LegalDocumentGroupGetFilterRequestValue)
        return true
      }
      case LegalDocumentGroupGetFilterResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupGetFilterResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterResultModel.verify(), 'document.LegalDocumentGroupGetFilterResult validation failed!')
        let deserialized = this._LegalDocumentGroupGetFilterResultModel.deserialize(this._LegalDocumentGroupGetFilterResultValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupGetFilterResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupGetFilterResultValue)
        this.onReceive_LegalDocumentGroupGetFilterResult(this._LegalDocumentGroupGetFilterResultValue)
        return true
      }
      case LegalDocumentGroupGetFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupGetFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterResponseModel.verify(), 'document.LegalDocumentGroupGetFilterResponse validation failed!')
        let deserialized = this._LegalDocumentGroupGetFilterResponseModel.deserialize(this._LegalDocumentGroupGetFilterResponseValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupGetFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupGetFilterResponseValue)
        this.onReceive_LegalDocumentGroupGetFilterResponse(this._LegalDocumentGroupGetFilterResponseValue)
        return true
      }
      case LegalDocumentGroupCreateParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupCreateParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateParamsModel.verify(), 'document.LegalDocumentGroupCreateParams validation failed!')
        let deserialized = this._LegalDocumentGroupCreateParamsModel.deserialize(this._LegalDocumentGroupCreateParamsValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupCreateParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupCreateParamsValue)
        this.onReceive_LegalDocumentGroupCreateParams(this._LegalDocumentGroupCreateParamsValue)
        return true
      }
      case LegalDocumentGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateRequestModel.verify(), 'document.LegalDocumentGroupCreateRequest validation failed!')
        let deserialized = this._LegalDocumentGroupCreateRequestModel.deserialize(this._LegalDocumentGroupCreateRequestValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupCreateRequestValue)
        this.onReceive_LegalDocumentGroupCreateRequest(this._LegalDocumentGroupCreateRequestValue)
        return true
      }
      case LegalDocumentGroupCreateResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupCreateResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateResultModel.verify(), 'document.LegalDocumentGroupCreateResult validation failed!')
        let deserialized = this._LegalDocumentGroupCreateResultModel.deserialize(this._LegalDocumentGroupCreateResultValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupCreateResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupCreateResultValue)
        this.onReceive_LegalDocumentGroupCreateResult(this._LegalDocumentGroupCreateResultValue)
        return true
      }
      case LegalDocumentGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateResponseModel.verify(), 'document.LegalDocumentGroupCreateResponse validation failed!')
        let deserialized = this._LegalDocumentGroupCreateResponseModel.deserialize(this._LegalDocumentGroupCreateResponseValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupCreateResponseValue)
        this.onReceive_LegalDocumentGroupCreateResponse(this._LegalDocumentGroupCreateResponseValue)
        return true
      }
      case LegalDocumentGroupModifyParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupModifyParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyParamsModel.verify(), 'document.LegalDocumentGroupModifyParams validation failed!')
        let deserialized = this._LegalDocumentGroupModifyParamsModel.deserialize(this._LegalDocumentGroupModifyParamsValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupModifyParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupModifyParamsValue)
        this.onReceive_LegalDocumentGroupModifyParams(this._LegalDocumentGroupModifyParamsValue)
        return true
      }
      case LegalDocumentGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyRequestModel.verify(), 'document.LegalDocumentGroupModifyRequest validation failed!')
        let deserialized = this._LegalDocumentGroupModifyRequestModel.deserialize(this._LegalDocumentGroupModifyRequestValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupModifyRequestValue)
        this.onReceive_LegalDocumentGroupModifyRequest(this._LegalDocumentGroupModifyRequestValue)
        return true
      }
      case LegalDocumentGroupModifyResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupModifyResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyResultModel.verify(), 'document.LegalDocumentGroupModifyResult validation failed!')
        let deserialized = this._LegalDocumentGroupModifyResultModel.deserialize(this._LegalDocumentGroupModifyResultValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupModifyResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupModifyResultValue)
        this.onReceive_LegalDocumentGroupModifyResult(this._LegalDocumentGroupModifyResultValue)
        return true
      }
      case LegalDocumentGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyResponseModel.verify(), 'document.LegalDocumentGroupModifyResponse validation failed!')
        let deserialized = this._LegalDocumentGroupModifyResponseModel.deserialize(this._LegalDocumentGroupModifyResponseValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupModifyResponseValue)
        this.onReceive_LegalDocumentGroupModifyResponse(this._LegalDocumentGroupModifyResponseValue)
        return true
      }
      case LegalDocumentGroupDeleteParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupDeleteParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteParamsModel.verify(), 'document.LegalDocumentGroupDeleteParams validation failed!')
        let deserialized = this._LegalDocumentGroupDeleteParamsModel.deserialize(this._LegalDocumentGroupDeleteParamsValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupDeleteParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupDeleteParamsValue)
        this.onReceive_LegalDocumentGroupDeleteParams(this._LegalDocumentGroupDeleteParamsValue)
        return true
      }
      case LegalDocumentGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteRequestModel.verify(), 'document.LegalDocumentGroupDeleteRequest validation failed!')
        let deserialized = this._LegalDocumentGroupDeleteRequestModel.deserialize(this._LegalDocumentGroupDeleteRequestValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupDeleteRequestValue)
        this.onReceive_LegalDocumentGroupDeleteRequest(this._LegalDocumentGroupDeleteRequestValue)
        return true
      }
      case LegalDocumentGroupDeleteResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupDeleteResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteResultModel.verify(), 'document.LegalDocumentGroupDeleteResult validation failed!')
        let deserialized = this._LegalDocumentGroupDeleteResultModel.deserialize(this._LegalDocumentGroupDeleteResultValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupDeleteResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupDeleteResultValue)
        this.onReceive_LegalDocumentGroupDeleteResult(this._LegalDocumentGroupDeleteResultValue)
        return true
      }
      case LegalDocumentGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteResponseModel.verify(), 'document.LegalDocumentGroupDeleteResponse validation failed!')
        let deserialized = this._LegalDocumentGroupDeleteResponseModel.deserialize(this._LegalDocumentGroupDeleteResponseValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupDeleteResponseValue)
        this.onReceive_LegalDocumentGroupDeleteResponse(this._LegalDocumentGroupDeleteResponseValue)
        return true
      }
      case LegalDocumentUploadParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentUploadParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadParamsModel.verify(), 'document.LegalDocumentUploadParams validation failed!')
        let deserialized = this._LegalDocumentUploadParamsModel.deserialize(this._LegalDocumentUploadParamsValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentUploadParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentUploadParamsValue)
        this.onReceive_LegalDocumentUploadParams(this._LegalDocumentUploadParamsValue)
        return true
      }
      case LegalDocumentUploadRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentUploadRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadRequestModel.verify(), 'document.LegalDocumentUploadRequest validation failed!')
        let deserialized = this._LegalDocumentUploadRequestModel.deserialize(this._LegalDocumentUploadRequestValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentUploadRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentUploadRequestValue)
        this.onReceive_LegalDocumentUploadRequest(this._LegalDocumentUploadRequestValue)
        return true
      }
      case LegalDocumentUploadResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentUploadResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadResultModel.verify(), 'document.LegalDocumentUploadResult validation failed!')
        let deserialized = this._LegalDocumentUploadResultModel.deserialize(this._LegalDocumentUploadResultValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentUploadResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentUploadResultValue)
        this.onReceive_LegalDocumentUploadResult(this._LegalDocumentUploadResultValue)
        return true
      }
      case LegalDocumentUploadResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentUploadResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadResponseModel.verify(), 'document.LegalDocumentUploadResponse validation failed!')
        let deserialized = this._LegalDocumentUploadResponseModel.deserialize(this._LegalDocumentUploadResponseValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentUploadResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentUploadResponseValue)
        this.onReceive_LegalDocumentUploadResponse(this._LegalDocumentUploadResponseValue)
        return true
      }
      case LegalDocumentGetParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetParamsModel.verify(), 'document.LegalDocumentGetParams validation failed!')
        let deserialized = this._LegalDocumentGetParamsModel.deserialize(this._LegalDocumentGetParamsValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGetParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetParamsValue)
        this.onReceive_LegalDocumentGetParams(this._LegalDocumentGetParamsValue)
        return true
      }
      case LegalDocumentGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetRequestModel.verify(), 'document.LegalDocumentGetRequest validation failed!')
        let deserialized = this._LegalDocumentGetRequestModel.deserialize(this._LegalDocumentGetRequestValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetRequestValue)
        this.onReceive_LegalDocumentGetRequest(this._LegalDocumentGetRequestValue)
        return true
      }
      case LegalDocumentGetResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResultModel.verify(), 'document.LegalDocumentGetResult validation failed!')
        let deserialized = this._LegalDocumentGetResultModel.deserialize(this._LegalDocumentGetResultValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGetResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetResultValue)
        this.onReceive_LegalDocumentGetResult(this._LegalDocumentGetResultValue)
        return true
      }
      case LegalDocumentGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResponseModel.verify(), 'document.LegalDocumentGetResponse validation failed!')
        let deserialized = this._LegalDocumentGetResponseModel.deserialize(this._LegalDocumentGetResponseValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetResponseValue)
        this.onReceive_LegalDocumentGetResponse(this._LegalDocumentGetResponseValue)
        return true
      }
      case LegalDocumentDeleteParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentDeleteParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteParamsModel.verify(), 'document.LegalDocumentDeleteParams validation failed!')
        let deserialized = this._LegalDocumentDeleteParamsModel.deserialize(this._LegalDocumentDeleteParamsValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentDeleteParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentDeleteParamsValue)
        this.onReceive_LegalDocumentDeleteParams(this._LegalDocumentDeleteParamsValue)
        return true
      }
      case LegalDocumentDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteRequestModel.verify(), 'document.LegalDocumentDeleteRequest validation failed!')
        let deserialized = this._LegalDocumentDeleteRequestModel.deserialize(this._LegalDocumentDeleteRequestValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentDeleteRequestValue)
        this.onReceive_LegalDocumentDeleteRequest(this._LegalDocumentDeleteRequestValue)
        return true
      }
      case LegalDocumentDeleteResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentDeleteResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteResultModel.verify(), 'document.LegalDocumentDeleteResult validation failed!')
        let deserialized = this._LegalDocumentDeleteResultModel.deserialize(this._LegalDocumentDeleteResultValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentDeleteResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentDeleteResultValue)
        this.onReceive_LegalDocumentDeleteResult(this._LegalDocumentDeleteResultValue)
        return true
      }
      case LegalDocumentDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteResponseModel.verify(), 'document.LegalDocumentDeleteResponse validation failed!')
        let deserialized = this._LegalDocumentDeleteResponseModel.deserialize(this._LegalDocumentDeleteResponseValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentDeleteResponseValue)
        this.onReceive_LegalDocumentDeleteResponse(this._LegalDocumentDeleteResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding document proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize document proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._LegalDocumentGroupGetFilterParamsModel = new LegalDocumentGroupGetFilterParamsModel()
    this._LegalDocumentGroupGetFilterRequestModel = new LegalDocumentGroupGetFilterRequestModel()
    this._LegalDocumentGroupGetFilterResultModel = new LegalDocumentGroupGetFilterResultModel()
    this._LegalDocumentGroupGetFilterResponseModel = new LegalDocumentGroupGetFilterResponseModel()
    this._LegalDocumentGroupCreateParamsModel = new LegalDocumentGroupCreateParamsModel()
    this._LegalDocumentGroupCreateRequestModel = new LegalDocumentGroupCreateRequestModel()
    this._LegalDocumentGroupCreateResultModel = new LegalDocumentGroupCreateResultModel()
    this._LegalDocumentGroupCreateResponseModel = new LegalDocumentGroupCreateResponseModel()
    this._LegalDocumentGroupModifyParamsModel = new LegalDocumentGroupModifyParamsModel()
    this._LegalDocumentGroupModifyRequestModel = new LegalDocumentGroupModifyRequestModel()
    this._LegalDocumentGroupModifyResultModel = new LegalDocumentGroupModifyResultModel()
    this._LegalDocumentGroupModifyResponseModel = new LegalDocumentGroupModifyResponseModel()
    this._LegalDocumentGroupDeleteParamsModel = new LegalDocumentGroupDeleteParamsModel()
    this._LegalDocumentGroupDeleteRequestModel = new LegalDocumentGroupDeleteRequestModel()
    this._LegalDocumentGroupDeleteResultModel = new LegalDocumentGroupDeleteResultModel()
    this._LegalDocumentGroupDeleteResponseModel = new LegalDocumentGroupDeleteResponseModel()
    this._LegalDocumentUploadParamsModel = new LegalDocumentUploadParamsModel()
    this._LegalDocumentUploadRequestModel = new LegalDocumentUploadRequestModel()
    this._LegalDocumentUploadResultModel = new LegalDocumentUploadResultModel()
    this._LegalDocumentUploadResponseModel = new LegalDocumentUploadResponseModel()
    this._LegalDocumentGetParamsModel = new LegalDocumentGetParamsModel()
    this._LegalDocumentGetRequestModel = new LegalDocumentGetRequestModel()
    this._LegalDocumentGetResultModel = new LegalDocumentGetResultModel()
    this._LegalDocumentGetResponseModel = new LegalDocumentGetResponseModel()
    this._LegalDocumentDeleteParamsModel = new LegalDocumentDeleteParamsModel()
    this._LegalDocumentDeleteRequestModel = new LegalDocumentDeleteRequestModel()
    this._LegalDocumentDeleteResultModel = new LegalDocumentDeleteResultModel()
    this._LegalDocumentDeleteResponseModel = new LegalDocumentDeleteResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  // Proxy handlers

  /**
   * LegalDocumentGroupGetFilterParams proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupGetFilterParams} model LegalDocumentGroupGetFilterParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupGetFilterParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupGetFilterRequest} model LegalDocumentGroupGetFilterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupGetFilterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterResult proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupGetFilterResult} model LegalDocumentGroupGetFilterResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupGetFilterResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupGetFilterResponse} model LegalDocumentGroupGetFilterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupGetFilterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateParams proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupCreateParams} model LegalDocumentGroupCreateParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupCreateParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupCreateRequest} model LegalDocumentGroupCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateResult proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupCreateResult} model LegalDocumentGroupCreateResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupCreateResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupCreateResponse} model LegalDocumentGroupCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyParams proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupModifyParams} model LegalDocumentGroupModifyParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupModifyParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupModifyRequest} model LegalDocumentGroupModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyResult proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupModifyResult} model LegalDocumentGroupModifyResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupModifyResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupModifyResponse} model LegalDocumentGroupModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteParams proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupDeleteParams} model LegalDocumentGroupDeleteParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupDeleteParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupDeleteRequest} model LegalDocumentGroupDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteResult proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupDeleteResult} model LegalDocumentGroupDeleteResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupDeleteResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupDeleteResponse} model LegalDocumentGroupDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadParams proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentUploadParams} model LegalDocumentUploadParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentUploadParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentUploadRequest} model LegalDocumentUploadRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentUploadRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadResult proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentUploadResult} model LegalDocumentUploadResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentUploadResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentUploadResponse} model LegalDocumentUploadResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentUploadResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGetParams proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGetParams} model LegalDocumentGetParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGetParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGetRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGetRequest} model LegalDocumentGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResult proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGetResult} model LegalDocumentGetResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGetResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGetResponse} model LegalDocumentGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteParams proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentDeleteParams} model LegalDocumentDeleteParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentDeleteParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentDeleteRequest} model LegalDocumentDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteResult proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentDeleteResult} model LegalDocumentDeleteResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentDeleteResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentDeleteResponse} model LegalDocumentDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * document receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case LegalDocumentGroupGetFilterParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupGetFilterParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterParamsModel.verify(), 'document.LegalDocumentGroupGetFilterParams validation failed!')

        let fbeBegin = this._LegalDocumentGroupGetFilterParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupGetFilterParams(this._LegalDocumentGroupGetFilterParamsModel, type, buffer, offset, size)
        this._LegalDocumentGroupGetFilterParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupGetFilterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupGetFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterRequestModel.verify(), 'document.LegalDocumentGroupGetFilterRequest validation failed!')

        let fbeBegin = this._LegalDocumentGroupGetFilterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupGetFilterRequest(this._LegalDocumentGroupGetFilterRequestModel, type, buffer, offset, size)
        this._LegalDocumentGroupGetFilterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupGetFilterResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupGetFilterResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterResultModel.verify(), 'document.LegalDocumentGroupGetFilterResult validation failed!')

        let fbeBegin = this._LegalDocumentGroupGetFilterResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupGetFilterResult(this._LegalDocumentGroupGetFilterResultModel, type, buffer, offset, size)
        this._LegalDocumentGroupGetFilterResultModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupGetFilterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupGetFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterResponseModel.verify(), 'document.LegalDocumentGroupGetFilterResponse validation failed!')

        let fbeBegin = this._LegalDocumentGroupGetFilterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupGetFilterResponse(this._LegalDocumentGroupGetFilterResponseModel, type, buffer, offset, size)
        this._LegalDocumentGroupGetFilterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupCreateParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupCreateParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateParamsModel.verify(), 'document.LegalDocumentGroupCreateParams validation failed!')

        let fbeBegin = this._LegalDocumentGroupCreateParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupCreateParams(this._LegalDocumentGroupCreateParamsModel, type, buffer, offset, size)
        this._LegalDocumentGroupCreateParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateRequestModel.verify(), 'document.LegalDocumentGroupCreateRequest validation failed!')

        let fbeBegin = this._LegalDocumentGroupCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupCreateRequest(this._LegalDocumentGroupCreateRequestModel, type, buffer, offset, size)
        this._LegalDocumentGroupCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupCreateResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupCreateResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateResultModel.verify(), 'document.LegalDocumentGroupCreateResult validation failed!')

        let fbeBegin = this._LegalDocumentGroupCreateResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupCreateResult(this._LegalDocumentGroupCreateResultModel, type, buffer, offset, size)
        this._LegalDocumentGroupCreateResultModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateResponseModel.verify(), 'document.LegalDocumentGroupCreateResponse validation failed!')

        let fbeBegin = this._LegalDocumentGroupCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupCreateResponse(this._LegalDocumentGroupCreateResponseModel, type, buffer, offset, size)
        this._LegalDocumentGroupCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupModifyParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupModifyParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyParamsModel.verify(), 'document.LegalDocumentGroupModifyParams validation failed!')

        let fbeBegin = this._LegalDocumentGroupModifyParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupModifyParams(this._LegalDocumentGroupModifyParamsModel, type, buffer, offset, size)
        this._LegalDocumentGroupModifyParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyRequestModel.verify(), 'document.LegalDocumentGroupModifyRequest validation failed!')

        let fbeBegin = this._LegalDocumentGroupModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupModifyRequest(this._LegalDocumentGroupModifyRequestModel, type, buffer, offset, size)
        this._LegalDocumentGroupModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupModifyResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupModifyResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyResultModel.verify(), 'document.LegalDocumentGroupModifyResult validation failed!')

        let fbeBegin = this._LegalDocumentGroupModifyResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupModifyResult(this._LegalDocumentGroupModifyResultModel, type, buffer, offset, size)
        this._LegalDocumentGroupModifyResultModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyResponseModel.verify(), 'document.LegalDocumentGroupModifyResponse validation failed!')

        let fbeBegin = this._LegalDocumentGroupModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupModifyResponse(this._LegalDocumentGroupModifyResponseModel, type, buffer, offset, size)
        this._LegalDocumentGroupModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupDeleteParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupDeleteParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteParamsModel.verify(), 'document.LegalDocumentGroupDeleteParams validation failed!')

        let fbeBegin = this._LegalDocumentGroupDeleteParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupDeleteParams(this._LegalDocumentGroupDeleteParamsModel, type, buffer, offset, size)
        this._LegalDocumentGroupDeleteParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteRequestModel.verify(), 'document.LegalDocumentGroupDeleteRequest validation failed!')

        let fbeBegin = this._LegalDocumentGroupDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupDeleteRequest(this._LegalDocumentGroupDeleteRequestModel, type, buffer, offset, size)
        this._LegalDocumentGroupDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupDeleteResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupDeleteResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteResultModel.verify(), 'document.LegalDocumentGroupDeleteResult validation failed!')

        let fbeBegin = this._LegalDocumentGroupDeleteResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupDeleteResult(this._LegalDocumentGroupDeleteResultModel, type, buffer, offset, size)
        this._LegalDocumentGroupDeleteResultModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteResponseModel.verify(), 'document.LegalDocumentGroupDeleteResponse validation failed!')

        let fbeBegin = this._LegalDocumentGroupDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupDeleteResponse(this._LegalDocumentGroupDeleteResponseModel, type, buffer, offset, size)
        this._LegalDocumentGroupDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentUploadParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentUploadParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadParamsModel.verify(), 'document.LegalDocumentUploadParams validation failed!')

        let fbeBegin = this._LegalDocumentUploadParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentUploadParams(this._LegalDocumentUploadParamsModel, type, buffer, offset, size)
        this._LegalDocumentUploadParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentUploadRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentUploadRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadRequestModel.verify(), 'document.LegalDocumentUploadRequest validation failed!')

        let fbeBegin = this._LegalDocumentUploadRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentUploadRequest(this._LegalDocumentUploadRequestModel, type, buffer, offset, size)
        this._LegalDocumentUploadRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentUploadResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentUploadResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadResultModel.verify(), 'document.LegalDocumentUploadResult validation failed!')

        let fbeBegin = this._LegalDocumentUploadResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentUploadResult(this._LegalDocumentUploadResultModel, type, buffer, offset, size)
        this._LegalDocumentUploadResultModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentUploadResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentUploadResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadResponseModel.verify(), 'document.LegalDocumentUploadResponse validation failed!')

        let fbeBegin = this._LegalDocumentUploadResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentUploadResponse(this._LegalDocumentUploadResponseModel, type, buffer, offset, size)
        this._LegalDocumentUploadResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGetParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGetParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetParamsModel.verify(), 'document.LegalDocumentGetParams validation failed!')

        let fbeBegin = this._LegalDocumentGetParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGetParams(this._LegalDocumentGetParamsModel, type, buffer, offset, size)
        this._LegalDocumentGetParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetRequestModel.verify(), 'document.LegalDocumentGetRequest validation failed!')

        let fbeBegin = this._LegalDocumentGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGetRequest(this._LegalDocumentGetRequestModel, type, buffer, offset, size)
        this._LegalDocumentGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGetResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGetResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResultModel.verify(), 'document.LegalDocumentGetResult validation failed!')

        let fbeBegin = this._LegalDocumentGetResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGetResult(this._LegalDocumentGetResultModel, type, buffer, offset, size)
        this._LegalDocumentGetResultModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResponseModel.verify(), 'document.LegalDocumentGetResponse validation failed!')

        let fbeBegin = this._LegalDocumentGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGetResponse(this._LegalDocumentGetResponseModel, type, buffer, offset, size)
        this._LegalDocumentGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentDeleteParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentDeleteParamsModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteParamsModel.verify(), 'document.LegalDocumentDeleteParams validation failed!')

        let fbeBegin = this._LegalDocumentDeleteParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentDeleteParams(this._LegalDocumentDeleteParamsModel, type, buffer, offset, size)
        this._LegalDocumentDeleteParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteRequestModel.verify(), 'document.LegalDocumentDeleteRequest validation failed!')

        let fbeBegin = this._LegalDocumentDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentDeleteRequest(this._LegalDocumentDeleteRequestModel, type, buffer, offset, size)
        this._LegalDocumentDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentDeleteResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentDeleteResultModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteResultModel.verify(), 'document.LegalDocumentDeleteResult validation failed!')

        let fbeBegin = this._LegalDocumentDeleteResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentDeleteResult(this._LegalDocumentDeleteResultModel, type, buffer, offset, size)
        this._LegalDocumentDeleteResultModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteResponseModel.verify(), 'document.LegalDocumentDeleteResponse validation failed!')

        let fbeBegin = this._LegalDocumentDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentDeleteResponse(this._LegalDocumentDeleteResponseModel, type, buffer, offset, size)
        this._LegalDocumentDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding document client
 */
class Client extends fbe.Client {
  /**
   * Initialize document client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._LegalDocumentGroupGetFilterParamsSenderModel = new LegalDocumentGroupGetFilterParamsModel(this.sendBuffer)
    this._LegalDocumentGroupGetFilterParamsReceiverValue = new LegalDocumentGroupGetFilterParams()
    this._LegalDocumentGroupGetFilterParamsReceiverModel = new LegalDocumentGroupGetFilterParamsModel()
    this._LegalDocumentGroupGetFilterRequestSenderModel = new LegalDocumentGroupGetFilterRequestModel(this.sendBuffer)
    this._LegalDocumentGroupGetFilterRequestReceiverValue = new LegalDocumentGroupGetFilterRequest()
    this._LegalDocumentGroupGetFilterRequestReceiverModel = new LegalDocumentGroupGetFilterRequestModel()
    this._LegalDocumentGroupGetFilterResultSenderModel = new LegalDocumentGroupGetFilterResultModel(this.sendBuffer)
    this._LegalDocumentGroupGetFilterResultReceiverValue = new LegalDocumentGroupGetFilterResult()
    this._LegalDocumentGroupGetFilterResultReceiverModel = new LegalDocumentGroupGetFilterResultModel()
    this._LegalDocumentGroupGetFilterResponseSenderModel = new LegalDocumentGroupGetFilterResponseModel(this.sendBuffer)
    this._LegalDocumentGroupGetFilterResponseReceiverValue = new LegalDocumentGroupGetFilterResponse()
    this._LegalDocumentGroupGetFilterResponseReceiverModel = new LegalDocumentGroupGetFilterResponseModel()
    this._LegalDocumentGroupCreateParamsSenderModel = new LegalDocumentGroupCreateParamsModel(this.sendBuffer)
    this._LegalDocumentGroupCreateParamsReceiverValue = new LegalDocumentGroupCreateParams()
    this._LegalDocumentGroupCreateParamsReceiverModel = new LegalDocumentGroupCreateParamsModel()
    this._LegalDocumentGroupCreateRequestSenderModel = new LegalDocumentGroupCreateRequestModel(this.sendBuffer)
    this._LegalDocumentGroupCreateRequestReceiverValue = new LegalDocumentGroupCreateRequest()
    this._LegalDocumentGroupCreateRequestReceiverModel = new LegalDocumentGroupCreateRequestModel()
    this._LegalDocumentGroupCreateResultSenderModel = new LegalDocumentGroupCreateResultModel(this.sendBuffer)
    this._LegalDocumentGroupCreateResultReceiverValue = new LegalDocumentGroupCreateResult()
    this._LegalDocumentGroupCreateResultReceiverModel = new LegalDocumentGroupCreateResultModel()
    this._LegalDocumentGroupCreateResponseSenderModel = new LegalDocumentGroupCreateResponseModel(this.sendBuffer)
    this._LegalDocumentGroupCreateResponseReceiverValue = new LegalDocumentGroupCreateResponse()
    this._LegalDocumentGroupCreateResponseReceiverModel = new LegalDocumentGroupCreateResponseModel()
    this._LegalDocumentGroupModifyParamsSenderModel = new LegalDocumentGroupModifyParamsModel(this.sendBuffer)
    this._LegalDocumentGroupModifyParamsReceiverValue = new LegalDocumentGroupModifyParams()
    this._LegalDocumentGroupModifyParamsReceiverModel = new LegalDocumentGroupModifyParamsModel()
    this._LegalDocumentGroupModifyRequestSenderModel = new LegalDocumentGroupModifyRequestModel(this.sendBuffer)
    this._LegalDocumentGroupModifyRequestReceiverValue = new LegalDocumentGroupModifyRequest()
    this._LegalDocumentGroupModifyRequestReceiverModel = new LegalDocumentGroupModifyRequestModel()
    this._LegalDocumentGroupModifyResultSenderModel = new LegalDocumentGroupModifyResultModel(this.sendBuffer)
    this._LegalDocumentGroupModifyResultReceiverValue = new LegalDocumentGroupModifyResult()
    this._LegalDocumentGroupModifyResultReceiverModel = new LegalDocumentGroupModifyResultModel()
    this._LegalDocumentGroupModifyResponseSenderModel = new LegalDocumentGroupModifyResponseModel(this.sendBuffer)
    this._LegalDocumentGroupModifyResponseReceiverValue = new LegalDocumentGroupModifyResponse()
    this._LegalDocumentGroupModifyResponseReceiverModel = new LegalDocumentGroupModifyResponseModel()
    this._LegalDocumentGroupDeleteParamsSenderModel = new LegalDocumentGroupDeleteParamsModel(this.sendBuffer)
    this._LegalDocumentGroupDeleteParamsReceiverValue = new LegalDocumentGroupDeleteParams()
    this._LegalDocumentGroupDeleteParamsReceiverModel = new LegalDocumentGroupDeleteParamsModel()
    this._LegalDocumentGroupDeleteRequestSenderModel = new LegalDocumentGroupDeleteRequestModel(this.sendBuffer)
    this._LegalDocumentGroupDeleteRequestReceiverValue = new LegalDocumentGroupDeleteRequest()
    this._LegalDocumentGroupDeleteRequestReceiverModel = new LegalDocumentGroupDeleteRequestModel()
    this._LegalDocumentGroupDeleteResultSenderModel = new LegalDocumentGroupDeleteResultModel(this.sendBuffer)
    this._LegalDocumentGroupDeleteResultReceiverValue = new LegalDocumentGroupDeleteResult()
    this._LegalDocumentGroupDeleteResultReceiverModel = new LegalDocumentGroupDeleteResultModel()
    this._LegalDocumentGroupDeleteResponseSenderModel = new LegalDocumentGroupDeleteResponseModel(this.sendBuffer)
    this._LegalDocumentGroupDeleteResponseReceiverValue = new LegalDocumentGroupDeleteResponse()
    this._LegalDocumentGroupDeleteResponseReceiverModel = new LegalDocumentGroupDeleteResponseModel()
    this._LegalDocumentUploadParamsSenderModel = new LegalDocumentUploadParamsModel(this.sendBuffer)
    this._LegalDocumentUploadParamsReceiverValue = new LegalDocumentUploadParams()
    this._LegalDocumentUploadParamsReceiverModel = new LegalDocumentUploadParamsModel()
    this._LegalDocumentUploadRequestSenderModel = new LegalDocumentUploadRequestModel(this.sendBuffer)
    this._LegalDocumentUploadRequestReceiverValue = new LegalDocumentUploadRequest()
    this._LegalDocumentUploadRequestReceiverModel = new LegalDocumentUploadRequestModel()
    this._LegalDocumentUploadResultSenderModel = new LegalDocumentUploadResultModel(this.sendBuffer)
    this._LegalDocumentUploadResultReceiverValue = new LegalDocumentUploadResult()
    this._LegalDocumentUploadResultReceiverModel = new LegalDocumentUploadResultModel()
    this._LegalDocumentUploadResponseSenderModel = new LegalDocumentUploadResponseModel(this.sendBuffer)
    this._LegalDocumentUploadResponseReceiverValue = new LegalDocumentUploadResponse()
    this._LegalDocumentUploadResponseReceiverModel = new LegalDocumentUploadResponseModel()
    this._LegalDocumentGetParamsSenderModel = new LegalDocumentGetParamsModel(this.sendBuffer)
    this._LegalDocumentGetParamsReceiverValue = new LegalDocumentGetParams()
    this._LegalDocumentGetParamsReceiverModel = new LegalDocumentGetParamsModel()
    this._LegalDocumentGetRequestSenderModel = new LegalDocumentGetRequestModel(this.sendBuffer)
    this._LegalDocumentGetRequestReceiverValue = new LegalDocumentGetRequest()
    this._LegalDocumentGetRequestReceiverModel = new LegalDocumentGetRequestModel()
    this._LegalDocumentGetResultSenderModel = new LegalDocumentGetResultModel(this.sendBuffer)
    this._LegalDocumentGetResultReceiverValue = new LegalDocumentGetResult()
    this._LegalDocumentGetResultReceiverModel = new LegalDocumentGetResultModel()
    this._LegalDocumentGetResponseSenderModel = new LegalDocumentGetResponseModel(this.sendBuffer)
    this._LegalDocumentGetResponseReceiverValue = new LegalDocumentGetResponse()
    this._LegalDocumentGetResponseReceiverModel = new LegalDocumentGetResponseModel()
    this._LegalDocumentDeleteParamsSenderModel = new LegalDocumentDeleteParamsModel(this.sendBuffer)
    this._LegalDocumentDeleteParamsReceiverValue = new LegalDocumentDeleteParams()
    this._LegalDocumentDeleteParamsReceiverModel = new LegalDocumentDeleteParamsModel()
    this._LegalDocumentDeleteRequestSenderModel = new LegalDocumentDeleteRequestModel(this.sendBuffer)
    this._LegalDocumentDeleteRequestReceiverValue = new LegalDocumentDeleteRequest()
    this._LegalDocumentDeleteRequestReceiverModel = new LegalDocumentDeleteRequestModel()
    this._LegalDocumentDeleteResultSenderModel = new LegalDocumentDeleteResultModel(this.sendBuffer)
    this._LegalDocumentDeleteResultReceiverValue = new LegalDocumentDeleteResult()
    this._LegalDocumentDeleteResultReceiverModel = new LegalDocumentDeleteResultModel()
    this._LegalDocumentDeleteResponseSenderModel = new LegalDocumentDeleteResponseModel(this.sendBuffer)
    this._LegalDocumentDeleteResponseReceiverValue = new LegalDocumentDeleteResponse()
    this._LegalDocumentDeleteResponseReceiverModel = new LegalDocumentDeleteResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  // Sender models accessors

  /**
   * Get LegalDocumentGroupGetFilterParams model
   * @this {!Client}
   * @returns {!LegalDocumentGroupGetFilterParamsModel} LegalDocumentGroupGetFilterParams sender model
   */
  get LegalDocumentGroupGetFilterParamsSenderModel () {
    return this._LegalDocumentGroupGetFilterParamsSenderModel
  }

  /**
   * Get LegalDocumentGroupGetFilterRequest model
   * @this {!Client}
   * @returns {!LegalDocumentGroupGetFilterRequestModel} LegalDocumentGroupGetFilterRequest sender model
   */
  get LegalDocumentGroupGetFilterRequestSenderModel () {
    return this._LegalDocumentGroupGetFilterRequestSenderModel
  }

  /**
   * Get LegalDocumentGroupGetFilterResult model
   * @this {!Client}
   * @returns {!LegalDocumentGroupGetFilterResultModel} LegalDocumentGroupGetFilterResult sender model
   */
  get LegalDocumentGroupGetFilterResultSenderModel () {
    return this._LegalDocumentGroupGetFilterResultSenderModel
  }

  /**
   * Get LegalDocumentGroupGetFilterResponse model
   * @this {!Client}
   * @returns {!LegalDocumentGroupGetFilterResponseModel} LegalDocumentGroupGetFilterResponse sender model
   */
  get LegalDocumentGroupGetFilterResponseSenderModel () {
    return this._LegalDocumentGroupGetFilterResponseSenderModel
  }

  /**
   * Get LegalDocumentGroupCreateParams model
   * @this {!Client}
   * @returns {!LegalDocumentGroupCreateParamsModel} LegalDocumentGroupCreateParams sender model
   */
  get LegalDocumentGroupCreateParamsSenderModel () {
    return this._LegalDocumentGroupCreateParamsSenderModel
  }

  /**
   * Get LegalDocumentGroupCreateRequest model
   * @this {!Client}
   * @returns {!LegalDocumentGroupCreateRequestModel} LegalDocumentGroupCreateRequest sender model
   */
  get LegalDocumentGroupCreateRequestSenderModel () {
    return this._LegalDocumentGroupCreateRequestSenderModel
  }

  /**
   * Get LegalDocumentGroupCreateResult model
   * @this {!Client}
   * @returns {!LegalDocumentGroupCreateResultModel} LegalDocumentGroupCreateResult sender model
   */
  get LegalDocumentGroupCreateResultSenderModel () {
    return this._LegalDocumentGroupCreateResultSenderModel
  }

  /**
   * Get LegalDocumentGroupCreateResponse model
   * @this {!Client}
   * @returns {!LegalDocumentGroupCreateResponseModel} LegalDocumentGroupCreateResponse sender model
   */
  get LegalDocumentGroupCreateResponseSenderModel () {
    return this._LegalDocumentGroupCreateResponseSenderModel
  }

  /**
   * Get LegalDocumentGroupModifyParams model
   * @this {!Client}
   * @returns {!LegalDocumentGroupModifyParamsModel} LegalDocumentGroupModifyParams sender model
   */
  get LegalDocumentGroupModifyParamsSenderModel () {
    return this._LegalDocumentGroupModifyParamsSenderModel
  }

  /**
   * Get LegalDocumentGroupModifyRequest model
   * @this {!Client}
   * @returns {!LegalDocumentGroupModifyRequestModel} LegalDocumentGroupModifyRequest sender model
   */
  get LegalDocumentGroupModifyRequestSenderModel () {
    return this._LegalDocumentGroupModifyRequestSenderModel
  }

  /**
   * Get LegalDocumentGroupModifyResult model
   * @this {!Client}
   * @returns {!LegalDocumentGroupModifyResultModel} LegalDocumentGroupModifyResult sender model
   */
  get LegalDocumentGroupModifyResultSenderModel () {
    return this._LegalDocumentGroupModifyResultSenderModel
  }

  /**
   * Get LegalDocumentGroupModifyResponse model
   * @this {!Client}
   * @returns {!LegalDocumentGroupModifyResponseModel} LegalDocumentGroupModifyResponse sender model
   */
  get LegalDocumentGroupModifyResponseSenderModel () {
    return this._LegalDocumentGroupModifyResponseSenderModel
  }

  /**
   * Get LegalDocumentGroupDeleteParams model
   * @this {!Client}
   * @returns {!LegalDocumentGroupDeleteParamsModel} LegalDocumentGroupDeleteParams sender model
   */
  get LegalDocumentGroupDeleteParamsSenderModel () {
    return this._LegalDocumentGroupDeleteParamsSenderModel
  }

  /**
   * Get LegalDocumentGroupDeleteRequest model
   * @this {!Client}
   * @returns {!LegalDocumentGroupDeleteRequestModel} LegalDocumentGroupDeleteRequest sender model
   */
  get LegalDocumentGroupDeleteRequestSenderModel () {
    return this._LegalDocumentGroupDeleteRequestSenderModel
  }

  /**
   * Get LegalDocumentGroupDeleteResult model
   * @this {!Client}
   * @returns {!LegalDocumentGroupDeleteResultModel} LegalDocumentGroupDeleteResult sender model
   */
  get LegalDocumentGroupDeleteResultSenderModel () {
    return this._LegalDocumentGroupDeleteResultSenderModel
  }

  /**
   * Get LegalDocumentGroupDeleteResponse model
   * @this {!Client}
   * @returns {!LegalDocumentGroupDeleteResponseModel} LegalDocumentGroupDeleteResponse sender model
   */
  get LegalDocumentGroupDeleteResponseSenderModel () {
    return this._LegalDocumentGroupDeleteResponseSenderModel
  }

  /**
   * Get LegalDocumentUploadParams model
   * @this {!Client}
   * @returns {!LegalDocumentUploadParamsModel} LegalDocumentUploadParams sender model
   */
  get LegalDocumentUploadParamsSenderModel () {
    return this._LegalDocumentUploadParamsSenderModel
  }

  /**
   * Get LegalDocumentUploadRequest model
   * @this {!Client}
   * @returns {!LegalDocumentUploadRequestModel} LegalDocumentUploadRequest sender model
   */
  get LegalDocumentUploadRequestSenderModel () {
    return this._LegalDocumentUploadRequestSenderModel
  }

  /**
   * Get LegalDocumentUploadResult model
   * @this {!Client}
   * @returns {!LegalDocumentUploadResultModel} LegalDocumentUploadResult sender model
   */
  get LegalDocumentUploadResultSenderModel () {
    return this._LegalDocumentUploadResultSenderModel
  }

  /**
   * Get LegalDocumentUploadResponse model
   * @this {!Client}
   * @returns {!LegalDocumentUploadResponseModel} LegalDocumentUploadResponse sender model
   */
  get LegalDocumentUploadResponseSenderModel () {
    return this._LegalDocumentUploadResponseSenderModel
  }

  /**
   * Get LegalDocumentGetParams model
   * @this {!Client}
   * @returns {!LegalDocumentGetParamsModel} LegalDocumentGetParams sender model
   */
  get LegalDocumentGetParamsSenderModel () {
    return this._LegalDocumentGetParamsSenderModel
  }

  /**
   * Get LegalDocumentGetRequest model
   * @this {!Client}
   * @returns {!LegalDocumentGetRequestModel} LegalDocumentGetRequest sender model
   */
  get LegalDocumentGetRequestSenderModel () {
    return this._LegalDocumentGetRequestSenderModel
  }

  /**
   * Get LegalDocumentGetResult model
   * @this {!Client}
   * @returns {!LegalDocumentGetResultModel} LegalDocumentGetResult sender model
   */
  get LegalDocumentGetResultSenderModel () {
    return this._LegalDocumentGetResultSenderModel
  }

  /**
   * Get LegalDocumentGetResponse model
   * @this {!Client}
   * @returns {!LegalDocumentGetResponseModel} LegalDocumentGetResponse sender model
   */
  get LegalDocumentGetResponseSenderModel () {
    return this._LegalDocumentGetResponseSenderModel
  }

  /**
   * Get LegalDocumentDeleteParams model
   * @this {!Client}
   * @returns {!LegalDocumentDeleteParamsModel} LegalDocumentDeleteParams sender model
   */
  get LegalDocumentDeleteParamsSenderModel () {
    return this._LegalDocumentDeleteParamsSenderModel
  }

  /**
   * Get LegalDocumentDeleteRequest model
   * @this {!Client}
   * @returns {!LegalDocumentDeleteRequestModel} LegalDocumentDeleteRequest sender model
   */
  get LegalDocumentDeleteRequestSenderModel () {
    return this._LegalDocumentDeleteRequestSenderModel
  }

  /**
   * Get LegalDocumentDeleteResult model
   * @this {!Client}
   * @returns {!LegalDocumentDeleteResultModel} LegalDocumentDeleteResult sender model
   */
  get LegalDocumentDeleteResultSenderModel () {
    return this._LegalDocumentDeleteResultSenderModel
  }

  /**
   * Get LegalDocumentDeleteResponse model
   * @this {!Client}
   * @returns {!LegalDocumentDeleteResponseModel} LegalDocumentDeleteResponse sender model
   */
  get LegalDocumentDeleteResponseSenderModel () {
    return this._LegalDocumentDeleteResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof LegalDocumentGroupGetFilterParams) && (value.fbeType === this.LegalDocumentGroupGetFilterParamsSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupGetFilterParams(value)
    }
    if ((value instanceof LegalDocumentGroupGetFilterRequest) && (value.fbeType === this.LegalDocumentGroupGetFilterRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupGetFilterRequest(value)
    }
    if ((value instanceof LegalDocumentGroupGetFilterResult) && (value.fbeType === this.LegalDocumentGroupGetFilterResultSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupGetFilterResult(value)
    }
    if ((value instanceof LegalDocumentGroupGetFilterResponse) && (value.fbeType === this.LegalDocumentGroupGetFilterResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupGetFilterResponse(value)
    }
    if ((value instanceof LegalDocumentGroupCreateParams) && (value.fbeType === this.LegalDocumentGroupCreateParamsSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupCreateParams(value)
    }
    if ((value instanceof LegalDocumentGroupCreateRequest) && (value.fbeType === this.LegalDocumentGroupCreateRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupCreateRequest(value)
    }
    if ((value instanceof LegalDocumentGroupCreateResult) && (value.fbeType === this.LegalDocumentGroupCreateResultSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupCreateResult(value)
    }
    if ((value instanceof LegalDocumentGroupCreateResponse) && (value.fbeType === this.LegalDocumentGroupCreateResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupCreateResponse(value)
    }
    if ((value instanceof LegalDocumentGroupModifyParams) && (value.fbeType === this.LegalDocumentGroupModifyParamsSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupModifyParams(value)
    }
    if ((value instanceof LegalDocumentGroupModifyRequest) && (value.fbeType === this.LegalDocumentGroupModifyRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupModifyRequest(value)
    }
    if ((value instanceof LegalDocumentGroupModifyResult) && (value.fbeType === this.LegalDocumentGroupModifyResultSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupModifyResult(value)
    }
    if ((value instanceof LegalDocumentGroupModifyResponse) && (value.fbeType === this.LegalDocumentGroupModifyResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupModifyResponse(value)
    }
    if ((value instanceof LegalDocumentGroupDeleteParams) && (value.fbeType === this.LegalDocumentGroupDeleteParamsSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupDeleteParams(value)
    }
    if ((value instanceof LegalDocumentGroupDeleteRequest) && (value.fbeType === this.LegalDocumentGroupDeleteRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupDeleteRequest(value)
    }
    if ((value instanceof LegalDocumentGroupDeleteResult) && (value.fbeType === this.LegalDocumentGroupDeleteResultSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupDeleteResult(value)
    }
    if ((value instanceof LegalDocumentGroupDeleteResponse) && (value.fbeType === this.LegalDocumentGroupDeleteResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupDeleteResponse(value)
    }
    if ((value instanceof LegalDocumentUploadParams) && (value.fbeType === this.LegalDocumentUploadParamsSenderModel.fbeType)) {
      return this.send_LegalDocumentUploadParams(value)
    }
    if ((value instanceof LegalDocumentUploadRequest) && (value.fbeType === this.LegalDocumentUploadRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentUploadRequest(value)
    }
    if ((value instanceof LegalDocumentUploadResult) && (value.fbeType === this.LegalDocumentUploadResultSenderModel.fbeType)) {
      return this.send_LegalDocumentUploadResult(value)
    }
    if ((value instanceof LegalDocumentUploadResponse) && (value.fbeType === this.LegalDocumentUploadResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentUploadResponse(value)
    }
    if ((value instanceof LegalDocumentGetParams) && (value.fbeType === this.LegalDocumentGetParamsSenderModel.fbeType)) {
      return this.send_LegalDocumentGetParams(value)
    }
    if ((value instanceof LegalDocumentGetRequest) && (value.fbeType === this.LegalDocumentGetRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentGetRequest(value)
    }
    if ((value instanceof LegalDocumentGetResult) && (value.fbeType === this.LegalDocumentGetResultSenderModel.fbeType)) {
      return this.send_LegalDocumentGetResult(value)
    }
    if ((value instanceof LegalDocumentGetResponse) && (value.fbeType === this.LegalDocumentGetResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentGetResponse(value)
    }
    if ((value instanceof LegalDocumentDeleteParams) && (value.fbeType === this.LegalDocumentDeleteParamsSenderModel.fbeType)) {
      return this.send_LegalDocumentDeleteParams(value)
    }
    if ((value instanceof LegalDocumentDeleteRequest) && (value.fbeType === this.LegalDocumentDeleteRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentDeleteRequest(value)
    }
    if ((value instanceof LegalDocumentDeleteResult) && (value.fbeType === this.LegalDocumentDeleteResultSenderModel.fbeType)) {
      return this.send_LegalDocumentDeleteResult(value)
    }
    if ((value instanceof LegalDocumentDeleteResponse) && (value.fbeType === this.LegalDocumentDeleteResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentDeleteResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send LegalDocumentGroupGetFilterParams value
   * @this {!Client}
   * @param {!LegalDocumentGroupGetFilterParams} value LegalDocumentGroupGetFilterParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupGetFilterParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupGetFilterParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupGetFilterParams serialization failed!')
    console.assert(this.LegalDocumentGroupGetFilterParamsSenderModel.verify(), 'document.LegalDocumentGroupGetFilterParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupGetFilterRequest value
   * @this {!Client}
   * @param {!LegalDocumentGroupGetFilterRequest} value LegalDocumentGroupGetFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupGetFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupGetFilterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupGetFilterRequest serialization failed!')
    console.assert(this.LegalDocumentGroupGetFilterRequestSenderModel.verify(), 'document.LegalDocumentGroupGetFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupGetFilterResult value
   * @this {!Client}
   * @param {!LegalDocumentGroupGetFilterResult} value LegalDocumentGroupGetFilterResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupGetFilterResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupGetFilterResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupGetFilterResult serialization failed!')
    console.assert(this.LegalDocumentGroupGetFilterResultSenderModel.verify(), 'document.LegalDocumentGroupGetFilterResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupGetFilterResponse value
   * @this {!Client}
   * @param {!LegalDocumentGroupGetFilterResponse} value LegalDocumentGroupGetFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupGetFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupGetFilterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupGetFilterResponse serialization failed!')
    console.assert(this.LegalDocumentGroupGetFilterResponseSenderModel.verify(), 'document.LegalDocumentGroupGetFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupCreateParams value
   * @this {!Client}
   * @param {!LegalDocumentGroupCreateParams} value LegalDocumentGroupCreateParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupCreateParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupCreateParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupCreateParams serialization failed!')
    console.assert(this.LegalDocumentGroupCreateParamsSenderModel.verify(), 'document.LegalDocumentGroupCreateParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupCreateRequest value
   * @this {!Client}
   * @param {!LegalDocumentGroupCreateRequest} value LegalDocumentGroupCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupCreateRequest serialization failed!')
    console.assert(this.LegalDocumentGroupCreateRequestSenderModel.verify(), 'document.LegalDocumentGroupCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupCreateResult value
   * @this {!Client}
   * @param {!LegalDocumentGroupCreateResult} value LegalDocumentGroupCreateResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupCreateResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupCreateResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupCreateResult serialization failed!')
    console.assert(this.LegalDocumentGroupCreateResultSenderModel.verify(), 'document.LegalDocumentGroupCreateResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupCreateResponse value
   * @this {!Client}
   * @param {!LegalDocumentGroupCreateResponse} value LegalDocumentGroupCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupCreateResponse serialization failed!')
    console.assert(this.LegalDocumentGroupCreateResponseSenderModel.verify(), 'document.LegalDocumentGroupCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupModifyParams value
   * @this {!Client}
   * @param {!LegalDocumentGroupModifyParams} value LegalDocumentGroupModifyParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupModifyParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupModifyParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupModifyParams serialization failed!')
    console.assert(this.LegalDocumentGroupModifyParamsSenderModel.verify(), 'document.LegalDocumentGroupModifyParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupModifyRequest value
   * @this {!Client}
   * @param {!LegalDocumentGroupModifyRequest} value LegalDocumentGroupModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupModifyRequest serialization failed!')
    console.assert(this.LegalDocumentGroupModifyRequestSenderModel.verify(), 'document.LegalDocumentGroupModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupModifyResult value
   * @this {!Client}
   * @param {!LegalDocumentGroupModifyResult} value LegalDocumentGroupModifyResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupModifyResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupModifyResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupModifyResult serialization failed!')
    console.assert(this.LegalDocumentGroupModifyResultSenderModel.verify(), 'document.LegalDocumentGroupModifyResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupModifyResponse value
   * @this {!Client}
   * @param {!LegalDocumentGroupModifyResponse} value LegalDocumentGroupModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupModifyResponse serialization failed!')
    console.assert(this.LegalDocumentGroupModifyResponseSenderModel.verify(), 'document.LegalDocumentGroupModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupDeleteParams value
   * @this {!Client}
   * @param {!LegalDocumentGroupDeleteParams} value LegalDocumentGroupDeleteParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupDeleteParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupDeleteParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupDeleteParams serialization failed!')
    console.assert(this.LegalDocumentGroupDeleteParamsSenderModel.verify(), 'document.LegalDocumentGroupDeleteParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupDeleteRequest value
   * @this {!Client}
   * @param {!LegalDocumentGroupDeleteRequest} value LegalDocumentGroupDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupDeleteRequest serialization failed!')
    console.assert(this.LegalDocumentGroupDeleteRequestSenderModel.verify(), 'document.LegalDocumentGroupDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupDeleteResult value
   * @this {!Client}
   * @param {!LegalDocumentGroupDeleteResult} value LegalDocumentGroupDeleteResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupDeleteResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupDeleteResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupDeleteResult serialization failed!')
    console.assert(this.LegalDocumentGroupDeleteResultSenderModel.verify(), 'document.LegalDocumentGroupDeleteResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupDeleteResponse value
   * @this {!Client}
   * @param {!LegalDocumentGroupDeleteResponse} value LegalDocumentGroupDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGroupDeleteResponse serialization failed!')
    console.assert(this.LegalDocumentGroupDeleteResponseSenderModel.verify(), 'document.LegalDocumentGroupDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentUploadParams value
   * @this {!Client}
   * @param {!LegalDocumentUploadParams} value LegalDocumentUploadParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentUploadParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentUploadParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentUploadParams serialization failed!')
    console.assert(this.LegalDocumentUploadParamsSenderModel.verify(), 'document.LegalDocumentUploadParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentUploadRequest value
   * @this {!Client}
   * @param {!LegalDocumentUploadRequest} value LegalDocumentUploadRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentUploadRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentUploadRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentUploadRequest serialization failed!')
    console.assert(this.LegalDocumentUploadRequestSenderModel.verify(), 'document.LegalDocumentUploadRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentUploadResult value
   * @this {!Client}
   * @param {!LegalDocumentUploadResult} value LegalDocumentUploadResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentUploadResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentUploadResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentUploadResult serialization failed!')
    console.assert(this.LegalDocumentUploadResultSenderModel.verify(), 'document.LegalDocumentUploadResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentUploadResponse value
   * @this {!Client}
   * @param {!LegalDocumentUploadResponse} value LegalDocumentUploadResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentUploadResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentUploadResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentUploadResponse serialization failed!')
    console.assert(this.LegalDocumentUploadResponseSenderModel.verify(), 'document.LegalDocumentUploadResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetParams value
   * @this {!Client}
   * @param {!LegalDocumentGetParams} value LegalDocumentGetParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGetParams serialization failed!')
    console.assert(this.LegalDocumentGetParamsSenderModel.verify(), 'document.LegalDocumentGetParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetRequest value
   * @this {!Client}
   * @param {!LegalDocumentGetRequest} value LegalDocumentGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGetRequest serialization failed!')
    console.assert(this.LegalDocumentGetRequestSenderModel.verify(), 'document.LegalDocumentGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetResult value
   * @this {!Client}
   * @param {!LegalDocumentGetResult} value LegalDocumentGetResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGetResult serialization failed!')
    console.assert(this.LegalDocumentGetResultSenderModel.verify(), 'document.LegalDocumentGetResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetResponse value
   * @this {!Client}
   * @param {!LegalDocumentGetResponse} value LegalDocumentGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentGetResponse serialization failed!')
    console.assert(this.LegalDocumentGetResponseSenderModel.verify(), 'document.LegalDocumentGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentDeleteParams value
   * @this {!Client}
   * @param {!LegalDocumentDeleteParams} value LegalDocumentDeleteParams value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentDeleteParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentDeleteParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentDeleteParams serialization failed!')
    console.assert(this.LegalDocumentDeleteParamsSenderModel.verify(), 'document.LegalDocumentDeleteParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentDeleteRequest value
   * @this {!Client}
   * @param {!LegalDocumentDeleteRequest} value LegalDocumentDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentDeleteRequest serialization failed!')
    console.assert(this.LegalDocumentDeleteRequestSenderModel.verify(), 'document.LegalDocumentDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentDeleteResult value
   * @this {!Client}
   * @param {!LegalDocumentDeleteResult} value LegalDocumentDeleteResult value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentDeleteResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentDeleteResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentDeleteResult serialization failed!')
    console.assert(this.LegalDocumentDeleteResultSenderModel.verify(), 'document.LegalDocumentDeleteResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentDeleteResponse value
   * @this {!Client}
   * @param {!LegalDocumentDeleteResponse} value LegalDocumentDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'document.LegalDocumentDeleteResponse serialization failed!')
    console.assert(this.LegalDocumentDeleteResponseSenderModel.verify(), 'document.LegalDocumentDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'document.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterParams receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupGetFilterParams} value LegalDocumentGroupGetFilterParams received value
   */
  onReceive_LegalDocumentGroupGetFilterParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupGetFilterRequest} value LegalDocumentGroupGetFilterRequest received value
   */
  onReceive_LegalDocumentGroupGetFilterRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterResult receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupGetFilterResult} value LegalDocumentGroupGetFilterResult received value
   */
  onReceive_LegalDocumentGroupGetFilterResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupGetFilterResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupGetFilterResponse} value LegalDocumentGroupGetFilterResponse received value
   */
  onReceive_LegalDocumentGroupGetFilterResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateParams receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupCreateParams} value LegalDocumentGroupCreateParams received value
   */
  onReceive_LegalDocumentGroupCreateParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupCreateRequest} value LegalDocumentGroupCreateRequest received value
   */
  onReceive_LegalDocumentGroupCreateRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateResult receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupCreateResult} value LegalDocumentGroupCreateResult received value
   */
  onReceive_LegalDocumentGroupCreateResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupCreateResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupCreateResponse} value LegalDocumentGroupCreateResponse received value
   */
  onReceive_LegalDocumentGroupCreateResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyParams receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupModifyParams} value LegalDocumentGroupModifyParams received value
   */
  onReceive_LegalDocumentGroupModifyParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupModifyRequest} value LegalDocumentGroupModifyRequest received value
   */
  onReceive_LegalDocumentGroupModifyRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyResult receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupModifyResult} value LegalDocumentGroupModifyResult received value
   */
  onReceive_LegalDocumentGroupModifyResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupModifyResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupModifyResponse} value LegalDocumentGroupModifyResponse received value
   */
  onReceive_LegalDocumentGroupModifyResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteParams receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupDeleteParams} value LegalDocumentGroupDeleteParams received value
   */
  onReceive_LegalDocumentGroupDeleteParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupDeleteRequest} value LegalDocumentGroupDeleteRequest received value
   */
  onReceive_LegalDocumentGroupDeleteRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteResult receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupDeleteResult} value LegalDocumentGroupDeleteResult received value
   */
  onReceive_LegalDocumentGroupDeleteResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupDeleteResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupDeleteResponse} value LegalDocumentGroupDeleteResponse received value
   */
  onReceive_LegalDocumentGroupDeleteResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadParams receive handler
   * @this {!Client}
   * @param {!LegalDocumentUploadParams} value LegalDocumentUploadParams received value
   */
  onReceive_LegalDocumentUploadParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentUploadRequest} value LegalDocumentUploadRequest received value
   */
  onReceive_LegalDocumentUploadRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadResult receive handler
   * @this {!Client}
   * @param {!LegalDocumentUploadResult} value LegalDocumentUploadResult received value
   */
  onReceive_LegalDocumentUploadResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentUploadResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentUploadResponse} value LegalDocumentUploadResponse received value
   */
  onReceive_LegalDocumentUploadResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetParams receive handler
   * @this {!Client}
   * @param {!LegalDocumentGetParams} value LegalDocumentGetParams received value
   */
  onReceive_LegalDocumentGetParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentGetRequest} value LegalDocumentGetRequest received value
   */
  onReceive_LegalDocumentGetRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResult receive handler
   * @this {!Client}
   * @param {!LegalDocumentGetResult} value LegalDocumentGetResult received value
   */
  onReceive_LegalDocumentGetResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentGetResponse} value LegalDocumentGetResponse received value
   */
  onReceive_LegalDocumentGetResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteParams receive handler
   * @this {!Client}
   * @param {!LegalDocumentDeleteParams} value LegalDocumentDeleteParams received value
   */
  onReceive_LegalDocumentDeleteParams (value) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentDeleteRequest} value LegalDocumentDeleteRequest received value
   */
  onReceive_LegalDocumentDeleteRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteResult receive handler
   * @this {!Client}
   * @param {!LegalDocumentDeleteResult} value LegalDocumentDeleteResult received value
   */
  onReceive_LegalDocumentDeleteResult (value) {}  // eslint-disable-line

  /**
   * LegalDocumentDeleteResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentDeleteResponse} value LegalDocumentDeleteResponse received value
   */
  onReceive_LegalDocumentDeleteResponse (value) {}  // eslint-disable-line

  /**
   * document receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case LegalDocumentGroupGetFilterParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupGetFilterParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterParamsReceiverModel.verify(), 'document.LegalDocumentGroupGetFilterParams validation failed!')
        let deserialized = this._LegalDocumentGroupGetFilterParamsReceiverModel.deserialize(this._LegalDocumentGroupGetFilterParamsReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupGetFilterParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupGetFilterParamsReceiverValue)
        this.onReceive_LegalDocumentGroupGetFilterParams(this._LegalDocumentGroupGetFilterParamsReceiverValue)
        return true
      }
      case LegalDocumentGroupGetFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupGetFilterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterRequestReceiverModel.verify(), 'document.LegalDocumentGroupGetFilterRequest validation failed!')
        let deserialized = this._LegalDocumentGroupGetFilterRequestReceiverModel.deserialize(this._LegalDocumentGroupGetFilterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupGetFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupGetFilterRequestReceiverValue)
        this.onReceive_LegalDocumentGroupGetFilterRequest(this._LegalDocumentGroupGetFilterRequestReceiverValue)
        return true
      }
      case LegalDocumentGroupGetFilterResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupGetFilterResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterResultReceiverModel.verify(), 'document.LegalDocumentGroupGetFilterResult validation failed!')
        let deserialized = this._LegalDocumentGroupGetFilterResultReceiverModel.deserialize(this._LegalDocumentGroupGetFilterResultReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupGetFilterResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupGetFilterResultReceiverValue)
        this.onReceive_LegalDocumentGroupGetFilterResult(this._LegalDocumentGroupGetFilterResultReceiverValue)
        return true
      }
      case LegalDocumentGroupGetFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupGetFilterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupGetFilterResponseReceiverModel.verify(), 'document.LegalDocumentGroupGetFilterResponse validation failed!')
        let deserialized = this._LegalDocumentGroupGetFilterResponseReceiverModel.deserialize(this._LegalDocumentGroupGetFilterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupGetFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupGetFilterResponseReceiverValue)
        this.onReceive_LegalDocumentGroupGetFilterResponse(this._LegalDocumentGroupGetFilterResponseReceiverValue)
        return true
      }
      case LegalDocumentGroupCreateParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupCreateParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateParamsReceiverModel.verify(), 'document.LegalDocumentGroupCreateParams validation failed!')
        let deserialized = this._LegalDocumentGroupCreateParamsReceiverModel.deserialize(this._LegalDocumentGroupCreateParamsReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupCreateParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupCreateParamsReceiverValue)
        this.onReceive_LegalDocumentGroupCreateParams(this._LegalDocumentGroupCreateParamsReceiverValue)
        return true
      }
      case LegalDocumentGroupCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateRequestReceiverModel.verify(), 'document.LegalDocumentGroupCreateRequest validation failed!')
        let deserialized = this._LegalDocumentGroupCreateRequestReceiverModel.deserialize(this._LegalDocumentGroupCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupCreateRequestReceiverValue)
        this.onReceive_LegalDocumentGroupCreateRequest(this._LegalDocumentGroupCreateRequestReceiverValue)
        return true
      }
      case LegalDocumentGroupCreateResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupCreateResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateResultReceiverModel.verify(), 'document.LegalDocumentGroupCreateResult validation failed!')
        let deserialized = this._LegalDocumentGroupCreateResultReceiverModel.deserialize(this._LegalDocumentGroupCreateResultReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupCreateResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupCreateResultReceiverValue)
        this.onReceive_LegalDocumentGroupCreateResult(this._LegalDocumentGroupCreateResultReceiverValue)
        return true
      }
      case LegalDocumentGroupCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupCreateResponseReceiverModel.verify(), 'document.LegalDocumentGroupCreateResponse validation failed!')
        let deserialized = this._LegalDocumentGroupCreateResponseReceiverModel.deserialize(this._LegalDocumentGroupCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupCreateResponseReceiverValue)
        this.onReceive_LegalDocumentGroupCreateResponse(this._LegalDocumentGroupCreateResponseReceiverValue)
        return true
      }
      case LegalDocumentGroupModifyParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupModifyParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyParamsReceiverModel.verify(), 'document.LegalDocumentGroupModifyParams validation failed!')
        let deserialized = this._LegalDocumentGroupModifyParamsReceiverModel.deserialize(this._LegalDocumentGroupModifyParamsReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupModifyParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupModifyParamsReceiverValue)
        this.onReceive_LegalDocumentGroupModifyParams(this._LegalDocumentGroupModifyParamsReceiverValue)
        return true
      }
      case LegalDocumentGroupModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyRequestReceiverModel.verify(), 'document.LegalDocumentGroupModifyRequest validation failed!')
        let deserialized = this._LegalDocumentGroupModifyRequestReceiverModel.deserialize(this._LegalDocumentGroupModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupModifyRequestReceiverValue)
        this.onReceive_LegalDocumentGroupModifyRequest(this._LegalDocumentGroupModifyRequestReceiverValue)
        return true
      }
      case LegalDocumentGroupModifyResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupModifyResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyResultReceiverModel.verify(), 'document.LegalDocumentGroupModifyResult validation failed!')
        let deserialized = this._LegalDocumentGroupModifyResultReceiverModel.deserialize(this._LegalDocumentGroupModifyResultReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupModifyResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupModifyResultReceiverValue)
        this.onReceive_LegalDocumentGroupModifyResult(this._LegalDocumentGroupModifyResultReceiverValue)
        return true
      }
      case LegalDocumentGroupModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupModifyResponseReceiverModel.verify(), 'document.LegalDocumentGroupModifyResponse validation failed!')
        let deserialized = this._LegalDocumentGroupModifyResponseReceiverModel.deserialize(this._LegalDocumentGroupModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupModifyResponseReceiverValue)
        this.onReceive_LegalDocumentGroupModifyResponse(this._LegalDocumentGroupModifyResponseReceiverValue)
        return true
      }
      case LegalDocumentGroupDeleteParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupDeleteParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteParamsReceiverModel.verify(), 'document.LegalDocumentGroupDeleteParams validation failed!')
        let deserialized = this._LegalDocumentGroupDeleteParamsReceiverModel.deserialize(this._LegalDocumentGroupDeleteParamsReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupDeleteParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupDeleteParamsReceiverValue)
        this.onReceive_LegalDocumentGroupDeleteParams(this._LegalDocumentGroupDeleteParamsReceiverValue)
        return true
      }
      case LegalDocumentGroupDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteRequestReceiverModel.verify(), 'document.LegalDocumentGroupDeleteRequest validation failed!')
        let deserialized = this._LegalDocumentGroupDeleteRequestReceiverModel.deserialize(this._LegalDocumentGroupDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupDeleteRequestReceiverValue)
        this.onReceive_LegalDocumentGroupDeleteRequest(this._LegalDocumentGroupDeleteRequestReceiverValue)
        return true
      }
      case LegalDocumentGroupDeleteResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupDeleteResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteResultReceiverModel.verify(), 'document.LegalDocumentGroupDeleteResult validation failed!')
        let deserialized = this._LegalDocumentGroupDeleteResultReceiverModel.deserialize(this._LegalDocumentGroupDeleteResultReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupDeleteResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupDeleteResultReceiverValue)
        this.onReceive_LegalDocumentGroupDeleteResult(this._LegalDocumentGroupDeleteResultReceiverValue)
        return true
      }
      case LegalDocumentGroupDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupDeleteResponseReceiverModel.verify(), 'document.LegalDocumentGroupDeleteResponse validation failed!')
        let deserialized = this._LegalDocumentGroupDeleteResponseReceiverModel.deserialize(this._LegalDocumentGroupDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGroupDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupDeleteResponseReceiverValue)
        this.onReceive_LegalDocumentGroupDeleteResponse(this._LegalDocumentGroupDeleteResponseReceiverValue)
        return true
      }
      case LegalDocumentUploadParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentUploadParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadParamsReceiverModel.verify(), 'document.LegalDocumentUploadParams validation failed!')
        let deserialized = this._LegalDocumentUploadParamsReceiverModel.deserialize(this._LegalDocumentUploadParamsReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentUploadParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentUploadParamsReceiverValue)
        this.onReceive_LegalDocumentUploadParams(this._LegalDocumentUploadParamsReceiverValue)
        return true
      }
      case LegalDocumentUploadRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentUploadRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadRequestReceiverModel.verify(), 'document.LegalDocumentUploadRequest validation failed!')
        let deserialized = this._LegalDocumentUploadRequestReceiverModel.deserialize(this._LegalDocumentUploadRequestReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentUploadRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentUploadRequestReceiverValue)
        this.onReceive_LegalDocumentUploadRequest(this._LegalDocumentUploadRequestReceiverValue)
        return true
      }
      case LegalDocumentUploadResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentUploadResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadResultReceiverModel.verify(), 'document.LegalDocumentUploadResult validation failed!')
        let deserialized = this._LegalDocumentUploadResultReceiverModel.deserialize(this._LegalDocumentUploadResultReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentUploadResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentUploadResultReceiverValue)
        this.onReceive_LegalDocumentUploadResult(this._LegalDocumentUploadResultReceiverValue)
        return true
      }
      case LegalDocumentUploadResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentUploadResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentUploadResponseReceiverModel.verify(), 'document.LegalDocumentUploadResponse validation failed!')
        let deserialized = this._LegalDocumentUploadResponseReceiverModel.deserialize(this._LegalDocumentUploadResponseReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentUploadResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentUploadResponseReceiverValue)
        this.onReceive_LegalDocumentUploadResponse(this._LegalDocumentUploadResponseReceiverValue)
        return true
      }
      case LegalDocumentGetParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetParamsReceiverModel.verify(), 'document.LegalDocumentGetParams validation failed!')
        let deserialized = this._LegalDocumentGetParamsReceiverModel.deserialize(this._LegalDocumentGetParamsReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGetParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetParamsReceiverValue)
        this.onReceive_LegalDocumentGetParams(this._LegalDocumentGetParamsReceiverValue)
        return true
      }
      case LegalDocumentGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetRequestReceiverModel.verify(), 'document.LegalDocumentGetRequest validation failed!')
        let deserialized = this._LegalDocumentGetRequestReceiverModel.deserialize(this._LegalDocumentGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetRequestReceiverValue)
        this.onReceive_LegalDocumentGetRequest(this._LegalDocumentGetRequestReceiverValue)
        return true
      }
      case LegalDocumentGetResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResultReceiverModel.verify(), 'document.LegalDocumentGetResult validation failed!')
        let deserialized = this._LegalDocumentGetResultReceiverModel.deserialize(this._LegalDocumentGetResultReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGetResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetResultReceiverValue)
        this.onReceive_LegalDocumentGetResult(this._LegalDocumentGetResultReceiverValue)
        return true
      }
      case LegalDocumentGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResponseReceiverModel.verify(), 'document.LegalDocumentGetResponse validation failed!')
        let deserialized = this._LegalDocumentGetResponseReceiverModel.deserialize(this._LegalDocumentGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetResponseReceiverValue)
        this.onReceive_LegalDocumentGetResponse(this._LegalDocumentGetResponseReceiverValue)
        return true
      }
      case LegalDocumentDeleteParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentDeleteParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteParamsReceiverModel.verify(), 'document.LegalDocumentDeleteParams validation failed!')
        let deserialized = this._LegalDocumentDeleteParamsReceiverModel.deserialize(this._LegalDocumentDeleteParamsReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentDeleteParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentDeleteParamsReceiverValue)
        this.onReceive_LegalDocumentDeleteParams(this._LegalDocumentDeleteParamsReceiverValue)
        return true
      }
      case LegalDocumentDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteRequestReceiverModel.verify(), 'document.LegalDocumentDeleteRequest validation failed!')
        let deserialized = this._LegalDocumentDeleteRequestReceiverModel.deserialize(this._LegalDocumentDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentDeleteRequestReceiverValue)
        this.onReceive_LegalDocumentDeleteRequest(this._LegalDocumentDeleteRequestReceiverValue)
        return true
      }
      case LegalDocumentDeleteResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentDeleteResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteResultReceiverModel.verify(), 'document.LegalDocumentDeleteResult validation failed!')
        let deserialized = this._LegalDocumentDeleteResultReceiverModel.deserialize(this._LegalDocumentDeleteResultReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentDeleteResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentDeleteResultReceiverValue)
        this.onReceive_LegalDocumentDeleteResult(this._LegalDocumentDeleteResultReceiverValue)
        return true
      }
      case LegalDocumentDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentDeleteResponseReceiverModel.verify(), 'document.LegalDocumentDeleteResponse validation failed!')
        let deserialized = this._LegalDocumentDeleteResponseReceiverModel.deserialize(this._LegalDocumentDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'document.LegalDocumentDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentDeleteResponseReceiverValue)
        this.onReceive_LegalDocumentDeleteResponse(this._LegalDocumentDeleteResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
