/* eslint-disable no-underscore-dangle */
import { makeAutoObservable } from 'mobx';
import { createBrowserHistory } from 'history';
import { UInt64 } from '@api/fbe/int64';
import { SocketClient } from '@api/SocketClient';
import { LoginReject } from '@api/fbe/client/LoginReject';
import { LoginRequest } from '@api/fbe/client/LoginRequest';
import { LoginResponse } from '@api/fbe/client/LoginResponse';
import { LogoutRequest } from '@api/fbe/client/LogoutRequest';
import { LogoutNotify } from '@api/fbe/client/LogoutNotify';
import { ProtocolVersionMajor, ProtocolVersionMinor } from '@api/fbe/manager';
import { ManagerGetRequest } from '@api/fbe/manager/ManagerGetRequest';
import { ModulesSystemInfoNotify } from '@api/fbe/manager/ModulesSystemInfoNotify';
import StorageHelper from '@utils/StorageHelper';
import { adaptFromApi } from '@store/managers/utils';
import { isValidWsUrl } from '@models/Auth';
import { resetStore } from './resetStore';
import { ManagerSessionInfoRequest } from '@api/fbe/manager/ManagerSessionInfoRequest';
import { ManagerSessionInfo } from '@api/fbe/manager/ManagerSessionInfo';
import { getBrowserFingerprint } from '@utils/fingerprint';
import { ManagerPlatformTimezoneGetRequest } from '@api/fbe/manager/ManagerPlatformTimezoneGetRequest';
import { Timezone } from '@api/fbe/core/Timezone';
import { ManagerGroupUpdateNotify } from '@api/fbe/manager/ManagerGroupUpdateNotify';
import { ManagerUpdateNotify } from '@api/fbe/manager/ManagerUpdateNotify';
import { managersStore } from './managers/managerStore';

type LogoutReason = {
    code: number;
    message: string;
};

type ManagerInfo = {
    login: string;
    token: string;
};

type OwnProps = {
    wsUrl?: string | null;
    login?: string;
    password?: string;
    token?: string;
    onSuccess?(): void;
    onFail?(reason: LoginReject): void;
};

export const history = createBrowserHistory();

export class AuthStore {
    constructor() {
        makeAutoObservable(this);
    }

    isLoading = false;

    fingerPrint = getBrowserFingerprint();

    token = '';

    login = '';

    wsUrl = '';

    rights: number[] | null = null;

    auth = false;

    sessionInfo: ManagerSessionInfo | null = null;

    managerTimezone: Timezone | null = null;

    setAuth(auth: boolean) {
        this.auth = auth;
    }

    addLogin({ login, token }: ManagerInfo) {
        this.token = token;
        this.login = login;
        this.auth = true;
    }

    setRights(rights: number[] | null) {
        this.rights = rights;
    }

    private setSessionInfo(info: ManagerSessionInfo) {
        this.sessionInfo = info;
    }

    async authHandler({ wsUrl, login, password, token, onSuccess, onFail }: OwnProps) {
        this.isLoading = true;
        if (
            (!SocketClient.instance.websocket || !SocketClient.instance.websocket._ws) &&
            wsUrl &&
            isValidWsUrl(wsUrl)
        ) {
            await SocketClient.instance.init(wsUrl);
        }
        SocketClient.instance.responseListener.addListener(LoginResponse, async (data) => {
            StorageHelper.saveToCookies('SessionData', [
                {
                    key: 'Login',
                    value: data.Login.toString(),
                },
                {
                    key: 'AuthToken',
                    value: data.AuthToken,
                },
            ]);
            this.getManagerTimezoneOffset();
            await this.getUserRights(data.Login.toString());
            this.addLogin({ login: data.Login.toString(), token: data.AuthToken });

            this.isLoading = false;
            this.wsUrl = wsUrl ?? '';
            if (onSuccess) {
                onSuccess();
            }
        });
        SocketClient.instance.responseListener.addListener(LoginReject, (reason) => {
            this.isLoading = false;
            if (onFail) {
                onFail(reason);
            }
        });

        const request = new LoginRequest();
        request.AdditionalParameters = this.fingerPrint;
        if (token) {
            request.AuthToken = token;
        } else {
            if (login) request.Login = UInt64.fromValue(login);
            request.Password = password;
        }

        request.ProtocolVersionMajor = ProtocolVersionMajor;
        request.ProtocolVersionMinor = ProtocolVersionMinor;

        await SocketClient.instance.sendRequest(request);
    }

    async logout(reason?: LogoutReason) {
        if (reason) console.warn('Logout: ', reason);
        this.clearSession();
        this.isLoading = false;
        StorageHelper.removeFromCookie('SessionData');
        SocketClient.instance.websocket.close(undefined, 'Logout');
        SocketClient.instance.websocket._ws = null!;
        const request = new LogoutRequest();
        await SocketClient.instance.request(request);
    }

    async getUserRights(login: string) {
        const managerRequest = new ManagerGetRequest();
        managerRequest.Login = UInt64.fromString(login);
        const manager = (await SocketClient.instance.request(managerRequest)).Manager;
        this.setRights(adaptFromApi(manager).ManagerRights?.Rights || null);
    }

    async restoreSession() {
        const request = new ManagerSessionInfoRequest();
        try {
            const sessionData = await SocketClient.instance.request(request).finally(() => {});
            this.setSessionInfo(sessionData.Session);
        } catch (err) {
            this.authHandler({
                wsUrl: this.wsUrl,
                login: this.login,
                token: this.token,
                onSuccess: () => {
                    console.log('Session restored');
                },
            });
            this.isLoading = false;
        }
    }

    async getManagerTimezoneOffset() {
        const request = new ManagerPlatformTimezoneGetRequest();
        const timezone = await (await SocketClient.instance.request(request)).Timezone;
        this.managerTimezone = timezone;
        const timezoneOffset = timezone.Offset + timezone.Daylight;
        globalThis.managerTimezoneOffset = timezoneOffset;
    }

    logoutNotifyCallback = () => {
        StorageHelper.removeFromCookie('SessionData');
        this.clearSession();
    };

    subscribeSessionEvents() {
        SocketClient.instance.responseListener.addListener(LogoutNotify, this.logoutNotifyCallback);
        const request = new ModulesSystemInfoNotify();
        SocketClient.instance.sendRequest(request);
    }

    unsubscribeSessionEvents() {
        SocketClient.instance.responseListener.removeListener(LogoutNotify, this.logoutNotifyCallback);
    }

    managerRightUpdateCallback = (data: ManagerUpdateNotify) => {
        const prevRights = JSON.stringify(data.Previous?.ManagerRights);
        const currRights = JSON.stringify(data.Current?.ManagerRights);

        if (
            data.Current?.Login &&
            this.login === data.Current.Login.toString() &&
            (prevRights !== currRights || data.Previous?.IsManagerRightsSet !== data.Current?.IsManagerRightsSet)
        ) {
            this.getUserRights(this.login);
        }
    };

    managerGroupRightUpdateCallback(data: ManagerGroupUpdateNotify) {
        const updatedManagerGroupId = data.Current?.id.toString();
        if (updatedManagerGroupId) {
            const request = new ManagerGetRequest();
            request.Login = UInt64.fromValue(this.login);
            managersStore.getManagerById(this.login).then((managerDetails) => {
                const currentManagerGroupId = managerDetails.ManagerGroupId;
                if (managerDetails.IsManagerRightsSet === false && currentManagerGroupId === updatedManagerGroupId) {
                    const prevRights = JSON.stringify(data.Previous?.ManagerRights);
                    const currRights = JSON.stringify(data.Current?.ManagerRights);
                    if (prevRights !== currRights) {
                        this.getUserRights(this.login);
                    }
                }
            });
        }
    }

    subscribeManagerEvents() {
        SocketClient.instance.responseListener.addListener(ManagerUpdateNotify, this.managerRightUpdateCallback);
        SocketClient.instance.sendRequest(new ManagerUpdateNotify());

        SocketClient.instance.responseListener.addListener(
            ManagerGroupUpdateNotify,
            this.managerGroupRightUpdateCallback.bind(this),
        );
        SocketClient.instance.sendRequest(new ManagerGroupUpdateNotify());
    }

    unsubscribeManagerEvents() {
        SocketClient.instance.responseListener.removeListener(ManagerUpdateNotify, this.managerRightUpdateCallback);
        SocketClient.instance.responseListener.removeListener(
            ManagerGroupUpdateNotify,
            this.managerGroupRightUpdateCallback,
        );
    }

    clearSession() {
        this.token = '';
        this.login = '';
        this.auth = false;
        this.isLoading = false;
        this.wsUrl = '';
        this.sessionInfo = null;
        history.replace('/signin');
        resetStore();
    }
}

const authStore = new AuthStore();
export { authStore };
