
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {GatewayModifyResponse} from './GatewayModifyResponse';
import {GatewayStatus} from '../core/GatewayStatus';
import {FieldModelGatewayStatus} from '../core/GatewayStatus';

/**
 * GatewayModifyRequest struct
 */
class GatewayModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {!UUID=} gatewayId
   * @param {string=} newModuleName
   * @param {string=} newName
   * @param {string=} newHost
   * @param {!boolean=} newHostReset
   * @param {number=} newPort
   * @param {!boolean=} newPortReset
   * @param {boolean=} newIsEnabled
   * @param {GatewayStatus=} newGatewayStatus
   * @param {UUID=} newSymbolMapId
   * @param {!boolean=} newSymbolMapIdReset
   * @param {!Map=} parametersToModify
   * @param {!Set=} parametersToDelete
   * @param {boolean=} newIsRemote
   * @param {boolean=} newSendNewOrderAccountGroup
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), gatewayId = new UUID(), newModuleName = null, newName = null, newHost = null, newHostReset = false, newPort = null, newPortReset = false, newIsEnabled = null, newGatewayStatus = null, newSymbolMapId = null, newSymbolMapIdReset = false, parametersToModify = new Map(), parametersToDelete = new Set(), newIsRemote = null, newSendNewOrderAccountGroup = null) {
    super()
    super.copy(parent)
    this.GatewayId = gatewayId
    this.NewModuleName = newModuleName
    this.NewName = newName
    this.NewHost = newHost
    this.NewHostReset = newHostReset
    this.NewPort = newPort
    this.NewPortReset = newPortReset
    this.NewIsEnabled = newIsEnabled
    this.NewGatewayStatus = newGatewayStatus
    this.NewSymbolMapId = newSymbolMapId
    this.NewSymbolMapIdReset = newSymbolMapIdReset
    this.ParametersToModify = parametersToModify
    this.ParametersToDelete = parametersToDelete
    this.NewIsRemote = newIsRemote
    this.NewSendNewOrderAccountGroup = newSendNewOrderAccountGroup
  }

  /**
   * Copy struct (shallow copy)
   * @this {!GatewayModifyRequest}
   * @param {!GatewayModifyRequest} other Other struct
   * @returns {!GatewayModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.GatewayId != null) {
      this.GatewayId = new UUID(other.GatewayId)
    } else {
      this.GatewayId = null
    }
    if (other.NewModuleName != null) {
      this.NewModuleName = other.NewModuleName
    } else {
      this.NewModuleName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewHost != null) {
      this.NewHost = other.NewHost
    } else {
      this.NewHost = null
    }
    if (other.NewHostReset != null) {
      this.NewHostReset = other.NewHostReset
    } else {
      this.NewHostReset = null
    }
    if (other.NewPort != null) {
      this.NewPort = other.NewPort
    } else {
      this.NewPort = null
    }
    if (other.NewPortReset != null) {
      this.NewPortReset = other.NewPortReset
    } else {
      this.NewPortReset = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    if (other.NewGatewayStatus != null) {
      this.NewGatewayStatus = GatewayStatus.fromObject(other.NewGatewayStatus)
    } else {
      this.NewGatewayStatus = null
    }
    if (other.NewSymbolMapId != null) {
      this.NewSymbolMapId = new UUID(other.NewSymbolMapId)
    } else {
      this.NewSymbolMapId = null
    }
    if (other.NewSymbolMapIdReset != null) {
      this.NewSymbolMapIdReset = other.NewSymbolMapIdReset
    } else {
      this.NewSymbolMapIdReset = null
    }
    if (other.ParametersToModify != null) {
      this.ParametersToModify = new Map()
      Object.keys(other.ParametersToModify).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = key
          if (other.ParametersToModify[key] != null) {
            let tempValue
            tempValue = other.ParametersToModify[key]
            this.ParametersToModify.set(tempKey, tempValue)
          } else {
            this.ParametersToModify.set(tempKey, null)
          }
        }
      })
    } else {
      this.ParametersToModify = null
    }
    if (other.ParametersToDelete != null) {
      this.ParametersToDelete = new Set()
      for (let item of other.ParametersToDelete) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.ParametersToDelete.add(tempItem)
        } else {
          this.ParametersToDelete.add(null)
        }
      }
    } else {
      this.ParametersToDelete = null
    }
    if (other.NewIsRemote != null) {
      this.NewIsRemote = other.NewIsRemote
    } else {
      this.NewIsRemote = null
    }
    if (other.NewSendNewOrderAccountGroup != null) {
      this.NewSendNewOrderAccountGroup = other.NewSendNewOrderAccountGroup
    } else {
      this.NewSendNewOrderAccountGroup = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!GatewayModifyRequest}
   * @returns {!GatewayModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new GatewayModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new GatewayModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!GatewayModifyRequest}
   * @param {!GatewayModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof GatewayModifyRequest)) {
      throw new TypeError('Instance of GatewayModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!GatewayModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      GatewayId: ((this.GatewayId != null) ? this.GatewayId.toString() : null),
      NewModuleName: ((this.NewModuleName != null) ? this.NewModuleName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewHost: ((this.NewHost != null) ? this.NewHost : null),
      NewHostReset: ((this.NewHostReset != null) ? this.NewHostReset : null),
      NewPort: ((this.NewPort != null) ? this.NewPort : null),
      NewPortReset: ((this.NewPortReset != null) ? this.NewPortReset : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null),
      NewGatewayStatus: ((this.NewGatewayStatus != null) ? this.NewGatewayStatus : null),
      NewSymbolMapId: ((this.NewSymbolMapId != null) ? this.NewSymbolMapId.toString() : null),
      NewSymbolMapIdReset: ((this.NewSymbolMapIdReset != null) ? this.NewSymbolMapIdReset : null),
      ParametersToModify: ((this.ParametersToModify != null) ? fbe.MapToObject(new Map(Array.from(this.ParametersToModify, item => [((item[0] != null) ? item[0] : null), ((item[1] != null) ? item[1] : null)]))) : null),
      ParametersToDelete: ((this.ParametersToDelete != null) ? Array.from(this.ParametersToDelete, item => ((item != null) ? item : null)) : null),
      NewIsRemote: ((this.NewIsRemote != null) ? this.NewIsRemote : null),
      NewSendNewOrderAccountGroup: ((this.NewSendNewOrderAccountGroup != null) ? this.NewSendNewOrderAccountGroup : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return GatewayModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!GatewayModifyRequest} other Object value
   * @returns {!GatewayModifyRequest} Created struct
   */
  static fromObject (other) {
    return new GatewayModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!GatewayModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return GatewayModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!GatewayModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6328
  }
}

export { GatewayModifyRequest };

/**
 * Fast Binary Encoding GatewayModifyRequest field model
 */
class FieldModelGatewayModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.GatewayId = new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.NewModuleName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.GatewayId.fbeOffset + this.GatewayId.fbeSize), buffer, this.GatewayId.fbeOffset + this.GatewayId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewModuleName.fbeOffset + this.NewModuleName.fbeSize), buffer, this.NewModuleName.fbeOffset + this.NewModuleName.fbeSize)
    this.NewHost = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewHostReset = new fbe.FieldModelBool(buffer, this.NewHost.fbeOffset + this.NewHost.fbeSize)
    this.NewPort = new fbe.FieldModelOptional(new fbe.FieldModelUInt16(buffer, this.NewHostReset.fbeOffset + this.NewHostReset.fbeSize), buffer, this.NewHostReset.fbeOffset + this.NewHostReset.fbeSize)
    this.NewPortReset = new fbe.FieldModelBool(buffer, this.NewPort.fbeOffset + this.NewPort.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewPortReset.fbeOffset + this.NewPortReset.fbeSize), buffer, this.NewPortReset.fbeOffset + this.NewPortReset.fbeSize)
    this.NewGatewayStatus = new fbe.FieldModelOptional(new FieldModelGatewayStatus(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize), buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize)
    this.NewSymbolMapId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewGatewayStatus.fbeOffset + this.NewGatewayStatus.fbeSize), buffer, this.NewGatewayStatus.fbeOffset + this.NewGatewayStatus.fbeSize)
    this.NewSymbolMapIdReset = new fbe.FieldModelBool(buffer, this.NewSymbolMapId.fbeOffset + this.NewSymbolMapId.fbeSize)
    this.ParametersToModify = new fbe.FieldModelMap(new fbe.FieldModelString(buffer, this.NewSymbolMapIdReset.fbeOffset + this.NewSymbolMapIdReset.fbeSize), new fbe.FieldModelString(buffer, this.NewSymbolMapIdReset.fbeOffset + this.NewSymbolMapIdReset.fbeSize), buffer, this.NewSymbolMapIdReset.fbeOffset + this.NewSymbolMapIdReset.fbeSize)
    this.ParametersToDelete = new fbe.FieldModelSet(new fbe.FieldModelString(buffer, this.ParametersToModify.fbeOffset + this.ParametersToModify.fbeSize), buffer, this.ParametersToModify.fbeOffset + this.ParametersToModify.fbeSize)
    this.NewIsRemote = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.ParametersToDelete.fbeOffset + this.ParametersToDelete.fbeSize), buffer, this.ParametersToDelete.fbeOffset + this.ParametersToDelete.fbeSize)
    this.NewSendNewOrderAccountGroup = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsRemote.fbeOffset + this.NewIsRemote.fbeSize), buffer, this.NewIsRemote.fbeOffset + this.NewIsRemote.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelGatewayModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelGatewayModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelGatewayModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.GatewayId.fbeSize + this.NewModuleName.fbeSize + this.NewName.fbeSize + this.NewHost.fbeSize + this.NewHostReset.fbeSize + this.NewPort.fbeSize + this.NewPortReset.fbeSize + this.NewIsEnabled.fbeSize + this.NewGatewayStatus.fbeSize + this.NewSymbolMapId.fbeSize + this.NewSymbolMapIdReset.fbeSize + this.ParametersToModify.fbeSize + this.ParametersToDelete.fbeSize + this.NewIsRemote.fbeSize + this.NewSendNewOrderAccountGroup.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelGatewayModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.GatewayId.fbeExtra + this.NewModuleName.fbeExtra + this.NewName.fbeExtra + this.NewHost.fbeExtra + this.NewHostReset.fbeExtra + this.NewPort.fbeExtra + this.NewPortReset.fbeExtra + this.NewIsEnabled.fbeExtra + this.NewGatewayStatus.fbeExtra + this.NewSymbolMapId.fbeExtra + this.NewSymbolMapIdReset.fbeExtra + this.ParametersToModify.fbeExtra + this.ParametersToDelete.fbeExtra + this.NewIsRemote.fbeExtra + this.NewSendNewOrderAccountGroup.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelGatewayModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelGatewayModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelGatewayModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6328
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelGatewayModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelGatewayModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.GatewayId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayId.fbeSize

    if ((fbeCurrentSize + this.NewModuleName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewModuleName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewModuleName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewHost.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewHost.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHost.fbeSize

    if ((fbeCurrentSize + this.NewHostReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewHostReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHostReset.fbeSize

    if ((fbeCurrentSize + this.NewPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPort.fbeSize

    if ((fbeCurrentSize + this.NewPortReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPortReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPortReset.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewGatewayStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewGatewayStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewGatewayStatus.fbeSize

    if ((fbeCurrentSize + this.NewSymbolMapId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolMapId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolMapId.fbeSize

    if ((fbeCurrentSize + this.NewSymbolMapIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolMapIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolMapIdReset.fbeSize

    if ((fbeCurrentSize + this.ParametersToModify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ParametersToModify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ParametersToModify.fbeSize

    if ((fbeCurrentSize + this.ParametersToDelete.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ParametersToDelete.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ParametersToDelete.fbeSize

    if ((fbeCurrentSize + this.NewIsRemote.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsRemote.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsRemote.fbeSize

    if ((fbeCurrentSize + this.NewSendNewOrderAccountGroup.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSendNewOrderAccountGroup.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSendNewOrderAccountGroup.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelGatewayModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelGatewayModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelGatewayModifyRequest}
   * @param {!GatewayModifyRequest} fbeValue Default value, defaults is new GatewayModifyRequest()
   * @returns {!GatewayModifyRequest} GatewayModifyRequest value
   */
  get (fbeValue = new GatewayModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelGatewayModifyRequest}
   * @param {!GatewayModifyRequest} fbeValue GatewayModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.GatewayId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayId = this.GatewayId.get()
    } else {
      fbeValue.GatewayId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayId.fbeSize

    if ((fbeCurrentSize + this.NewModuleName.fbeSize) <= fbeStructSize) {
      fbeValue.NewModuleName = this.NewModuleName.get()
    } else {
      fbeValue.NewModuleName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewModuleName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewHost.fbeSize) <= fbeStructSize) {
      fbeValue.NewHost = this.NewHost.get()
    } else {
      fbeValue.NewHost = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHost.fbeSize

    if ((fbeCurrentSize + this.NewHostReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewHostReset = this.NewHostReset.get()
    } else {
      fbeValue.NewHostReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHostReset.fbeSize

    if ((fbeCurrentSize + this.NewPort.fbeSize) <= fbeStructSize) {
      fbeValue.NewPort = this.NewPort.get()
    } else {
      fbeValue.NewPort = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPort.fbeSize

    if ((fbeCurrentSize + this.NewPortReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewPortReset = this.NewPortReset.get()
    } else {
      fbeValue.NewPortReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPortReset.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewGatewayStatus.fbeSize) <= fbeStructSize) {
      fbeValue.NewGatewayStatus = this.NewGatewayStatus.get()
    } else {
      fbeValue.NewGatewayStatus = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewGatewayStatus.fbeSize

    if ((fbeCurrentSize + this.NewSymbolMapId.fbeSize) <= fbeStructSize) {
      fbeValue.NewSymbolMapId = this.NewSymbolMapId.get()
    } else {
      fbeValue.NewSymbolMapId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolMapId.fbeSize

    if ((fbeCurrentSize + this.NewSymbolMapIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewSymbolMapIdReset = this.NewSymbolMapIdReset.get()
    } else {
      fbeValue.NewSymbolMapIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolMapIdReset.fbeSize

    if ((fbeCurrentSize + this.ParametersToModify.fbeSize) <= fbeStructSize) {
      this.ParametersToModify.get(fbeValue.ParametersToModify)
    } else {
      fbeValue.ParametersToModify.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ParametersToModify.fbeSize

    if ((fbeCurrentSize + this.ParametersToDelete.fbeSize) <= fbeStructSize) {
      this.ParametersToDelete.get(fbeValue.ParametersToDelete)
    } else {
      fbeValue.ParametersToDelete.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ParametersToDelete.fbeSize

    if ((fbeCurrentSize + this.NewIsRemote.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsRemote = this.NewIsRemote.get()
    } else {
      fbeValue.NewIsRemote = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsRemote.fbeSize

    if ((fbeCurrentSize + this.NewSendNewOrderAccountGroup.fbeSize) <= fbeStructSize) {
      fbeValue.NewSendNewOrderAccountGroup = this.NewSendNewOrderAccountGroup.get()
    } else {
      fbeValue.NewSendNewOrderAccountGroup = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSendNewOrderAccountGroup.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelGatewayModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelGatewayModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelGatewayModifyRequest}
   * @param {!GatewayModifyRequest} fbeValue GatewayModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelGatewayModifyRequest}
   * @param {GatewayModifyRequest} fbeValue GatewayModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.GatewayId.set(fbeValue.GatewayId)
    this.NewModuleName.set(fbeValue.NewModuleName)
    this.NewName.set(fbeValue.NewName)
    this.NewHost.set(fbeValue.NewHost)
    this.NewHostReset.set(fbeValue.NewHostReset)
    this.NewPort.set(fbeValue.NewPort)
    this.NewPortReset.set(fbeValue.NewPortReset)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
    this.NewGatewayStatus.set(fbeValue.NewGatewayStatus)
    this.NewSymbolMapId.set(fbeValue.NewSymbolMapId)
    this.NewSymbolMapIdReset.set(fbeValue.NewSymbolMapIdReset)
    this.ParametersToModify.set(fbeValue.ParametersToModify)
    this.ParametersToDelete.set(fbeValue.ParametersToDelete)
    this.NewIsRemote.set(fbeValue.NewIsRemote)
    this.NewSendNewOrderAccountGroup.set(fbeValue.NewSendNewOrderAccountGroup)
  }
}

export { FieldModelGatewayModifyRequest };

/**
 * Fast Binary Encoding GatewayModifyRequest model
 */
class GatewayModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelGatewayModifyRequest(this.buffer, 4)
  }

  /**
   * Get the GatewayModifyRequest model
   * @this {!GatewayModifyRequestModel}
   * @returns {!FieldModelGatewayModifyRequest} model GatewayModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!GatewayModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!GatewayModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return GatewayModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!GatewayModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelGatewayModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!GatewayModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!GatewayModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!GatewayModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!GatewayModifyRequestModel}
   * @param {!GatewayModifyRequest} value GatewayModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!GatewayModifyRequestModel}
   * @param {!GatewayModifyRequest} value GatewayModifyRequest value, defaults is new GatewayModifyRequest()
   * @return {!object} Deserialized GatewayModifyRequest value and its size
   */
  deserialize (value = new GatewayModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new GatewayModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new GatewayModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!GatewayModifyRequestModel}
   * @param {!number} prev Previous GatewayModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { GatewayModifyRequestModel };
GatewayModifyRequest.__has_response = true;
GatewayModifyRequest.__response_class = GatewayModifyResponse;
