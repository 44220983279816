import { UInt64 } from '@api/fbe/int64';
import { LegalDocumentMetadata } from '@api/fbe/manager/LegalDocumentMetadata';

export type LegalDocumentMetadataModel = {
    Name: string;
    ContentType: string;
    Size: number | null;
    Language: string;
    Created: Date;
};

export type UploadDocumentModel = {
    GroupId: number | null;
    Content: ArrayBuffer | null;
    Metadata: LegalDocumentMetadataModel;
};

export const convertLegalDocumentMetadataToModel = (record: LegalDocumentMetadata): LegalDocumentMetadataModel => ({
    Name: record.Name,
    ContentType: record.ContentType,
    Size: +record.Size.toNumber(),
    Language: record.Language,
    Created: record.Created,
});

export const convertModelToLegalDocumentMetadata = (record: LegalDocumentMetadataModel): LegalDocumentMetadata => {
    const result = new LegalDocumentMetadata();
    result.Name = record.Name;
    result.ContentType = record.ContentType;
    result.Language = record.Language;
    result.Created = record.Created;
    if (record.Size !== null && Number.isFinite(record.Size)) {
        result.Size = UInt64.fromNumber(record.Size);
    }
    return result;
};
