import { LegalDocumentGroupWithDocumentsDescriptions } from '@api/fbe/manager/LegalDocumentGroupWithDocumentsDescriptions';
import { convertLegalDocumentDescriptionToModel, convertModelToLegalDocumentDescription, LegalDocumentDescriptionModel } from './LegalDocumentDescription';
import { LegalDocumentGroup } from '@api/fbe/manager/LegalDocumentGroup';
import { UInt64 } from '@api/fbe/int64';
import { LegalDocumentType } from '@api/fbe/manager/LegalDocumentType';

export type LegalDocumentGroupModel = {
    GroupId: number | null;
    LegalDocumentType: number | null;
    SortOrder: number | null;
    IsEnabled: boolean;
    Comment: string;
};

export type LegalDocumentGroupWithDocumentsDescriptionsModel = {
    Group: LegalDocumentGroupModel;
    Documents: LegalDocumentDescriptionModel[];
};

export const convertLegalDocumentGroupToModel = (record: LegalDocumentGroup): LegalDocumentGroupModel => ({
    GroupId: +record.GroupId.toNumber(),
    LegalDocumentType: record.LegalDocumentType.valueOf(),
    SortOrder: +record.SortOrder.toNumber(),
    IsEnabled: record.IsEnabled,
    Comment: record.Comment,
});

export const convertModelToLegalDocumentGroup = (record: LegalDocumentGroupModel): LegalDocumentGroup => {
    const group = new LegalDocumentGroup();
    if (record.GroupId !== null && Number.isFinite(record.GroupId)) group.GroupId = UInt64.fromNumber(record.GroupId);
    if (record.LegalDocumentType !== null) group.LegalDocumentType = new LegalDocumentType(record.LegalDocumentType);
    if (record.SortOrder !== null && Number.isFinite(record.SortOrder))
        group.SortOrder = UInt64.fromNumber(record.SortOrder);
    group.IsEnabled = record.IsEnabled;
    group.Comment = record.Comment;
    return group;
};

export const convertLegalDocumentGroupWithDocumentsDescriptionsToModel = (
    record: LegalDocumentGroupWithDocumentsDescriptions,
): LegalDocumentGroupWithDocumentsDescriptionsModel => ({
    Group: convertLegalDocumentGroupToModel(record.Group),
    Documents: record.Documents.map((item) => convertLegalDocumentDescriptionToModel(item)),
});

export const convertModelToLegalDocumentGroupWithDocumentsDescriptions = (
    record: LegalDocumentGroupWithDocumentsDescriptionsModel,
): LegalDocumentGroupWithDocumentsDescriptions => {
    const result = new LegalDocumentGroupWithDocumentsDescriptions();
    result.Group = convertModelToLegalDocumentGroup(record.Group);
    result.Documents = record.Documents.map((item) => convertModelToLegalDocumentDescription(item));
    return result;
};
