// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: client.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'
import * as exposed from './exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {LoginType} from './client/LoginType'
import {ClientLoginType} from './client/ClientLoginType'
import {LogoutReason} from './client/LogoutReason'
import {OrderRequestClientSourceType} from './client/OrderRequestClientSourceType'
import {LegalDocumentType} from './client/LegalDocumentType'
import {PingRequest} from './client/PingRequest'
import {PingRequestModel} from './client/PingRequest'
import {PongResponse} from './client/PongResponse'
import {PongResponseModel} from './client/PongResponse'
import {LoginMAParams} from './client/LoginMAParams'
import {LoginMAParamsModel} from './client/LoginMAParams'
import {LoginInfo} from './client/LoginInfo'
import {LoginInfoModel} from './client/LoginInfo'
import {LoginRequest} from './client/LoginRequest'
import {LoginRequestModel} from './client/LoginRequest'
import {LoginResponse} from './client/LoginResponse'
import {LoginResponseModel} from './client/LoginResponse'
import {LoginReject} from './client/LoginReject'
import {LoginRejectModel} from './client/LoginReject'
import {LogoutRequest} from './client/LogoutRequest'
import {LogoutRequestModel} from './client/LogoutRequest'
import {LogoutResponse} from './client/LogoutResponse'
import {LogoutResponseModel} from './client/LogoutResponse'
import {LogoutNotify} from './client/LogoutNotify'
import {LogoutNotifyModel} from './client/LogoutNotify'
import {SessionInfo} from './client/SessionInfo'
import {SessionInfoModel} from './client/SessionInfo'
import {SessionInfoRequest} from './client/SessionInfoRequest'
import {SessionInfoRequestModel} from './client/SessionInfoRequest'
import {SessionInfoResponse} from './client/SessionInfoResponse'
import {SessionInfoResponseModel} from './client/SessionInfoResponse'
import {TimeInfo} from './client/TimeInfo'
import {TimeInfoModel} from './client/TimeInfo'
import {TimeInfoRequest} from './client/TimeInfoRequest'
import {TimeInfoRequestModel} from './client/TimeInfoRequest'
import {TimeInfoResponse} from './client/TimeInfoResponse'
import {TimeInfoResponseModel} from './client/TimeInfoResponse'
import {TimezoneInfo} from './client/TimezoneInfo'
import {TimezoneInfoModel} from './client/TimezoneInfo'
import {TimezoneInfoRequest} from './client/TimezoneInfoRequest'
import {TimezoneInfoRequestModel} from './client/TimezoneInfoRequest'
import {TimezoneInfoResponse} from './client/TimezoneInfoResponse'
import {TimezoneInfoResponseModel} from './client/TimezoneInfoResponse'
import {TimezoneInfoUpdateNotify} from './client/TimezoneInfoUpdateNotify'
import {TimezoneInfoUpdateNotifyModel} from './client/TimezoneInfoUpdateNotify'
import {CurrencyInfo} from './client/CurrencyInfo'
import {CurrencyInfoModel} from './client/CurrencyInfo'
import {CurrencyInfoRequest} from './client/CurrencyInfoRequest'
import {CurrencyInfoRequestModel} from './client/CurrencyInfoRequest'
import {CurrencyInfoResponse} from './client/CurrencyInfoResponse'
import {CurrencyInfoResponseModel} from './client/CurrencyInfoResponse'
import {CurrencyListRequest} from './client/CurrencyListRequest'
import {CurrencyListRequestModel} from './client/CurrencyListRequest'
import {CurrencyListResponse} from './client/CurrencyListResponse'
import {CurrencyListResponseModel} from './client/CurrencyListResponse'
import {CurrencyInfoUpdateNotify} from './client/CurrencyInfoUpdateNotify'
import {CurrencyInfoUpdateNotifyModel} from './client/CurrencyInfoUpdateNotify'
import {SymbolInfo} from './client/SymbolInfo'
import {SymbolInfoModel} from './client/SymbolInfo'
import {SymbolInfoRequest} from './client/SymbolInfoRequest'
import {SymbolInfoRequestModel} from './client/SymbolInfoRequest'
import {SymbolInfoResponse} from './client/SymbolInfoResponse'
import {SymbolInfoResponseModel} from './client/SymbolInfoResponse'
import {SymbolListRequest} from './client/SymbolListRequest'
import {SymbolListRequestModel} from './client/SymbolListRequest'
import {SymbolListResponse} from './client/SymbolListResponse'
import {SymbolListResponseModel} from './client/SymbolListResponse'
import {SymbolInfoUpdateNotify} from './client/SymbolInfoUpdateNotify'
import {SymbolInfoUpdateNotifyModel} from './client/SymbolInfoUpdateNotify'
import {TickInfoRequest} from './client/TickInfoRequest'
import {TickInfoRequestModel} from './client/TickInfoRequest'
import {TickInfoResponse} from './client/TickInfoResponse'
import {TickInfoResponseModel} from './client/TickInfoResponse'
import {TickFilterRequest} from './client/TickFilterRequest'
import {TickFilterRequestModel} from './client/TickFilterRequest'
import {TickFilterResponse} from './client/TickFilterResponse'
import {TickFilterResponseModel} from './client/TickFilterResponse'
import {TickListRequest} from './client/TickListRequest'
import {TickListRequestModel} from './client/TickListRequest'
import {TickListResponse} from './client/TickListResponse'
import {TickListResponseModel} from './client/TickListResponse'
import {Level2InfoRequest} from './client/Level2InfoRequest'
import {Level2InfoRequestModel} from './client/Level2InfoRequest'
import {Level2InfoResponse} from './client/Level2InfoResponse'
import {Level2InfoResponseModel} from './client/Level2InfoResponse'
import {Level2FilterRequest} from './client/Level2FilterRequest'
import {Level2FilterRequestModel} from './client/Level2FilterRequest'
import {Level2FilterResponse} from './client/Level2FilterResponse'
import {Level2FilterResponseModel} from './client/Level2FilterResponse'
import {Level2ListRequest} from './client/Level2ListRequest'
import {Level2ListRequestModel} from './client/Level2ListRequest'
import {Level2ListResponse} from './client/Level2ListResponse'
import {Level2ListResponseModel} from './client/Level2ListResponse'
import {FeedSubscriptionInfoRequest} from './client/FeedSubscriptionInfoRequest'
import {FeedSubscriptionInfoRequestModel} from './client/FeedSubscriptionInfoRequest'
import {FeedSubscriptionInfoResponse} from './client/FeedSubscriptionInfoResponse'
import {FeedSubscriptionInfoResponseModel} from './client/FeedSubscriptionInfoResponse'
import {FeedSubscribeRequest} from './client/FeedSubscribeRequest'
import {FeedSubscribeRequestModel} from './client/FeedSubscribeRequest'
import {FeedSubscribeAllRequest} from './client/FeedSubscribeAllRequest'
import {FeedSubscribeAllRequestModel} from './client/FeedSubscribeAllRequest'
import {FeedSubscribeResponse} from './client/FeedSubscribeResponse'
import {FeedSubscribeResponseModel} from './client/FeedSubscribeResponse'
import {FeedUnsubscribeRequest} from './client/FeedUnsubscribeRequest'
import {FeedUnsubscribeRequestModel} from './client/FeedUnsubscribeRequest'
import {FeedUnsubscribeAllRequest} from './client/FeedUnsubscribeAllRequest'
import {FeedUnsubscribeAllRequestModel} from './client/FeedUnsubscribeAllRequest'
import {FeedUnsubscribeResponse} from './client/FeedUnsubscribeResponse'
import {FeedUnsubscribeResponseModel} from './client/FeedUnsubscribeResponse'
import {TickSnapshotNotify} from './client/TickSnapshotNotify'
import {TickSnapshotNotifyModel} from './client/TickSnapshotNotify'
import {TickUpdateNotify} from './client/TickUpdateNotify'
import {TickUpdateNotifyModel} from './client/TickUpdateNotify'
import {Level2SnapshotNotify} from './client/Level2SnapshotNotify'
import {Level2SnapshotNotifyModel} from './client/Level2SnapshotNotify'
import {Level2UpdateNotify} from './client/Level2UpdateNotify'
import {Level2UpdateNotifyModel} from './client/Level2UpdateNotify'
import {ChartBarInfo} from './client/ChartBarInfo'
import {ChartBarInfoModel} from './client/ChartBarInfo'
import {ChartBarHistoryRequest} from './client/ChartBarHistoryRequest'
import {ChartBarHistoryRequestModel} from './client/ChartBarHistoryRequest'
import {ChartBarHistoryResponse} from './client/ChartBarHistoryResponse'
import {ChartBarHistoryResponseModel} from './client/ChartBarHistoryResponse'
import {ChartBarSubscribeRequest} from './client/ChartBarSubscribeRequest'
import {ChartBarSubscribeRequestModel} from './client/ChartBarSubscribeRequest'
import {ChartBarSubscribeResponse} from './client/ChartBarSubscribeResponse'
import {ChartBarSubscribeResponseModel} from './client/ChartBarSubscribeResponse'
import {ChartBarUnsubscribeRequest} from './client/ChartBarUnsubscribeRequest'
import {ChartBarUnsubscribeRequestModel} from './client/ChartBarUnsubscribeRequest'
import {ChartBarUnsubscribeResponse} from './client/ChartBarUnsubscribeResponse'
import {ChartBarUnsubscribeResponseModel} from './client/ChartBarUnsubscribeResponse'
import {ChartBarNotify} from './client/ChartBarNotify'
import {ChartBarNotifyModel} from './client/ChartBarNotify'
import {ChartBarUpdateNotify} from './client/ChartBarUpdateNotify'
import {ChartBarUpdateNotifyModel} from './client/ChartBarUpdateNotify'
import {PlatformInfo} from './client/PlatformInfo'
import {PlatformInfoModel} from './client/PlatformInfo'
import {PlatformInfoRequest} from './client/PlatformInfoRequest'
import {PlatformInfoRequestModel} from './client/PlatformInfoRequest'
import {PlatformInfoResponse} from './client/PlatformInfoResponse'
import {PlatformInfoResponseModel} from './client/PlatformInfoResponse'
import {PlatformInfoUpdateNotify} from './client/PlatformInfoUpdateNotify'
import {PlatformInfoUpdateNotifyModel} from './client/PlatformInfoUpdateNotify'
import {SocialTradingMasterInfo} from './client/SocialTradingMasterInfo'
import {SocialTradingMasterInfoModel} from './client/SocialTradingMasterInfo'
import {SocialTradingInvestorInfo} from './client/SocialTradingInvestorInfo'
import {SocialTradingInvestorInfoModel} from './client/SocialTradingInvestorInfo'
import {AccountInfo} from './client/AccountInfo'
import {AccountInfoModel} from './client/AccountInfo'
import {AccountInfoRequest} from './client/AccountInfoRequest'
import {AccountInfoRequestModel} from './client/AccountInfoRequest'
import {AccountInfoResponse} from './client/AccountInfoResponse'
import {AccountInfoResponseModel} from './client/AccountInfoResponse'
import {AccountInfoUpdateNotify} from './client/AccountInfoUpdateNotify'
import {AccountInfoUpdateNotifyModel} from './client/AccountInfoUpdateNotify'
import {MarginInfo} from './client/MarginInfo'
import {MarginInfoModel} from './client/MarginInfo'
import {MarginInfoRequest} from './client/MarginInfoRequest'
import {MarginInfoRequestModel} from './client/MarginInfoRequest'
import {MarginInfoResponse} from './client/MarginInfoResponse'
import {MarginInfoResponseModel} from './client/MarginInfoResponse'
import {MarginInfoUpdateNotify} from './client/MarginInfoUpdateNotify'
import {MarginInfoUpdateNotifyModel} from './client/MarginInfoUpdateNotify'
import {MarginCallNotify} from './client/MarginCallNotify'
import {MarginCallNotifyModel} from './client/MarginCallNotify'
import {StopOutNotify} from './client/StopOutNotify'
import {StopOutNotifyModel} from './client/StopOutNotify'
import {AssetInfo} from './client/AssetInfo'
import {AssetInfoModel} from './client/AssetInfo'
import {AssetInfoRequest} from './client/AssetInfoRequest'
import {AssetInfoRequestModel} from './client/AssetInfoRequest'
import {AssetInfoResponse} from './client/AssetInfoResponse'
import {AssetInfoResponseModel} from './client/AssetInfoResponse'
import {AssetListRequest} from './client/AssetListRequest'
import {AssetListRequestModel} from './client/AssetListRequest'
import {AssetListResponse} from './client/AssetListResponse'
import {AssetListResponseModel} from './client/AssetListResponse'
import {AssetInfoUpdateNotify} from './client/AssetInfoUpdateNotify'
import {AssetInfoUpdateNotifyModel} from './client/AssetInfoUpdateNotify'
import {OrderInfo} from './client/OrderInfo'
import {OrderInfoModel} from './client/OrderInfo'
import {OrderInfoRequest} from './client/OrderInfoRequest'
import {OrderInfoRequestModel} from './client/OrderInfoRequest'
import {OrderInfoResponse} from './client/OrderInfoResponse'
import {OrderInfoResponseModel} from './client/OrderInfoResponse'
import {OrderListRequest} from './client/OrderListRequest'
import {OrderListRequestModel} from './client/OrderListRequest'
import {OrderListResponse} from './client/OrderListResponse'
import {OrderListResponseModel} from './client/OrderListResponse'
import {OrderOpenRequest} from './client/OrderOpenRequest'
import {OrderOpenRequestModel} from './client/OrderOpenRequest'
import {OrderOpenResponse} from './client/OrderOpenResponse'
import {OrderOpenResponseModel} from './client/OrderOpenResponse'
import {OrderUpdateRequest} from './client/OrderUpdateRequest'
import {OrderUpdateRequestModel} from './client/OrderUpdateRequest'
import {OrderUpdateResponse} from './client/OrderUpdateResponse'
import {OrderUpdateResponseModel} from './client/OrderUpdateResponse'
import {OrderCancelRequest} from './client/OrderCancelRequest'
import {OrderCancelRequestModel} from './client/OrderCancelRequest'
import {OrderCancelResponse} from './client/OrderCancelResponse'
import {OrderCancelResponseModel} from './client/OrderCancelResponse'
import {OrderCancelAllRequest} from './client/OrderCancelAllRequest'
import {OrderCancelAllRequestModel} from './client/OrderCancelAllRequest'
import {OrderCancelAllResponse} from './client/OrderCancelAllResponse'
import {OrderCancelAllResponseModel} from './client/OrderCancelAllResponse'
import {OrderCancelAllByIdRequest} from './client/OrderCancelAllByIdRequest'
import {OrderCancelAllByIdRequestModel} from './client/OrderCancelAllByIdRequest'
import {OrderCloseRequest} from './client/OrderCloseRequest'
import {OrderCloseRequestModel} from './client/OrderCloseRequest'
import {OrderCloseResponse} from './client/OrderCloseResponse'
import {OrderCloseResponseModel} from './client/OrderCloseResponse'
import {OrderCloseAllRequest} from './client/OrderCloseAllRequest'
import {OrderCloseAllRequestModel} from './client/OrderCloseAllRequest'
import {OrderCloseAllResponse} from './client/OrderCloseAllResponse'
import {OrderCloseAllResponseModel} from './client/OrderCloseAllResponse'
import {OrderCloseAllByIdRequest} from './client/OrderCloseAllByIdRequest'
import {OrderCloseAllByIdRequestModel} from './client/OrderCloseAllByIdRequest'
import {OrderCloseByRequest} from './client/OrderCloseByRequest'
import {OrderCloseByRequestModel} from './client/OrderCloseByRequest'
import {OrderCloseByResponse} from './client/OrderCloseByResponse'
import {OrderCloseByResponseModel} from './client/OrderCloseByResponse'
import {OrderCloseByAllRequest} from './client/OrderCloseByAllRequest'
import {OrderCloseByAllRequestModel} from './client/OrderCloseByAllRequest'
import {OrderCloseByAllResponse} from './client/OrderCloseByAllResponse'
import {OrderCloseByAllResponseModel} from './client/OrderCloseByAllResponse'
import {OrderCancelOrCloseRequest} from './client/OrderCancelOrCloseRequest'
import {OrderCancelOrCloseRequestModel} from './client/OrderCancelOrCloseRequest'
import {OrderCancelOrCloseResponse} from './client/OrderCancelOrCloseResponse'
import {OrderCancelOrCloseResponseModel} from './client/OrderCancelOrCloseResponse'
import {OrderCancelOrCloseAllRequest} from './client/OrderCancelOrCloseAllRequest'
import {OrderCancelOrCloseAllRequestModel} from './client/OrderCancelOrCloseAllRequest'
import {OrderCancelOrCloseAllResponse} from './client/OrderCancelOrCloseAllResponse'
import {OrderCancelOrCloseAllResponseModel} from './client/OrderCancelOrCloseAllResponse'
import {OrderInfoUpdateNotify} from './client/OrderInfoUpdateNotify'
import {OrderInfoUpdateNotifyModel} from './client/OrderInfoUpdateNotify'
import {PositionInfo} from './client/PositionInfo'
import {PositionInfoModel} from './client/PositionInfo'
import {PositionInfoRequest} from './client/PositionInfoRequest'
import {PositionInfoRequestModel} from './client/PositionInfoRequest'
import {PositionInfoResponse} from './client/PositionInfoResponse'
import {PositionInfoResponseModel} from './client/PositionInfoResponse'
import {PositionListRequest} from './client/PositionListRequest'
import {PositionListRequestModel} from './client/PositionListRequest'
import {PositionListResponse} from './client/PositionListResponse'
import {PositionListResponseModel} from './client/PositionListResponse'
import {PositionInfoUpdateNotify} from './client/PositionInfoUpdateNotify'
import {PositionInfoUpdateNotifyModel} from './client/PositionInfoUpdateNotify'
import {TradeHistoryInfo} from './client/TradeHistoryInfo'
import {TradeHistoryInfoModel} from './client/TradeHistoryInfo'
import {TradeHistoryInfoRequest} from './client/TradeHistoryInfoRequest'
import {TradeHistoryInfoRequestModel} from './client/TradeHistoryInfoRequest'
import {TradeHistoryInfoResponse} from './client/TradeHistoryInfoResponse'
import {TradeHistoryInfoResponseModel} from './client/TradeHistoryInfoResponse'
import {TradeHistoryInfoFilterRequest} from './client/TradeHistoryInfoFilterRequest'
import {TradeHistoryInfoFilterRequestModel} from './client/TradeHistoryInfoFilterRequest'
import {TradeHistoryInfoByIdRequest} from './client/TradeHistoryInfoByIdRequest'
import {TradeHistoryInfoByIdRequestModel} from './client/TradeHistoryInfoByIdRequest'
import {TradeHistoryInfoByIdResponse} from './client/TradeHistoryInfoByIdResponse'
import {TradeHistoryInfoByIdResponseModel} from './client/TradeHistoryInfoByIdResponse'
import {TradeHistoryInfoUpdateNotify} from './client/TradeHistoryInfoUpdateNotify'
import {TradeHistoryInfoUpdateNotifyModel} from './client/TradeHistoryInfoUpdateNotify'
import {CalendarRegularRule} from './client/CalendarRegularRule'
import {CalendarRegularRuleModel} from './client/CalendarRegularRule'
import {CalendarRegularRuleGetRequest} from './client/CalendarRegularRuleGetRequest'
import {CalendarRegularRuleGetRequestModel} from './client/CalendarRegularRuleGetRequest'
import {CalendarRegularRuleGetResponse} from './client/CalendarRegularRuleGetResponse'
import {CalendarRegularRuleGetResponseModel} from './client/CalendarRegularRuleGetResponse'
import {CalendarRegularRuleUpdateNotify} from './client/CalendarRegularRuleUpdateNotify'
import {CalendarRegularRuleUpdateNotifyModel} from './client/CalendarRegularRuleUpdateNotify'
import {CalendarOffTimeRuleGetRequest} from './client/CalendarOffTimeRuleGetRequest'
import {CalendarOffTimeRuleGetRequestModel} from './client/CalendarOffTimeRuleGetRequest'
import {CalendarOffTimeRuleGetResponse} from './client/CalendarOffTimeRuleGetResponse'
import {CalendarOffTimeRuleGetResponseModel} from './client/CalendarOffTimeRuleGetResponse'
import {CalendarOffTimeRuleUpdateNotify} from './client/CalendarOffTimeRuleUpdateNotify'
import {CalendarOffTimeRuleUpdateNotifyModel} from './client/CalendarOffTimeRuleUpdateNotify'
import {CalendarRule} from './client/CalendarRule'
import {CalendarRuleModel} from './client/CalendarRule'
import {CalendarRulesGetRequest} from './client/CalendarRulesGetRequest'
import {CalendarRulesGetRequestModel} from './client/CalendarRulesGetRequest'
import {CalendarRulesGetResponse} from './client/CalendarRulesGetResponse'
import {CalendarRulesGetResponseModel} from './client/CalendarRulesGetResponse'
import {ChartBarHistoryRangeRequest} from './client/ChartBarHistoryRangeRequest'
import {ChartBarHistoryRangeRequestModel} from './client/ChartBarHistoryRangeRequest'
import {OrderMarginSubscribeRequest} from './client/OrderMarginSubscribeRequest'
import {OrderMarginSubscribeRequestModel} from './client/OrderMarginSubscribeRequest'
import {OrderMarginSubscribeResponse} from './client/OrderMarginSubscribeResponse'
import {OrderMarginSubscribeResponseModel} from './client/OrderMarginSubscribeResponse'
import {OrderMarginNotify} from './client/OrderMarginNotify'
import {OrderMarginNotifyModel} from './client/OrderMarginNotify'
import {OrderMarginUpdateSubscriptionRequest} from './client/OrderMarginUpdateSubscriptionRequest'
import {OrderMarginUpdateSubscriptionRequestModel} from './client/OrderMarginUpdateSubscriptionRequest'
import {OrderMarginUnsubscribeRequest} from './client/OrderMarginUnsubscribeRequest'
import {OrderMarginUnsubscribeRequestModel} from './client/OrderMarginUnsubscribeRequest'
import {OrderMarginUnsubscribeResponse} from './client/OrderMarginUnsubscribeResponse'
import {OrderMarginUnsubscribeResponseModel} from './client/OrderMarginUnsubscribeResponse'
import {OrderMarginGetRequest} from './client/OrderMarginGetRequest'
import {OrderMarginGetRequestModel} from './client/OrderMarginGetRequest'
import {OrderMarginGetResponse} from './client/OrderMarginGetResponse'
import {OrderMarginGetResponseModel} from './client/OrderMarginGetResponse'
import {OrderStateInfo} from './client/OrderStateInfo'
import {OrderStateInfoModel} from './client/OrderStateInfo'
import {OrderStateInfoSubscribeRequest} from './client/OrderStateInfoSubscribeRequest'
import {OrderStateInfoSubscribeRequestModel} from './client/OrderStateInfoSubscribeRequest'
import {OrderStateInfoSubscribeResponse} from './client/OrderStateInfoSubscribeResponse'
import {OrderStateInfoSubscribeResponseModel} from './client/OrderStateInfoSubscribeResponse'
import {OrderStateInfoUnsubscribeRequest} from './client/OrderStateInfoUnsubscribeRequest'
import {OrderStateInfoUnsubscribeRequestModel} from './client/OrderStateInfoUnsubscribeRequest'
import {OrderStateInfoUnsubscribeResponse} from './client/OrderStateInfoUnsubscribeResponse'
import {OrderStateInfoUnsubscribeResponseModel} from './client/OrderStateInfoUnsubscribeResponse'
import {OrderStateInfoNotify} from './client/OrderStateInfoNotify'
import {OrderStateInfoNotifyModel} from './client/OrderStateInfoNotify'
import {Deprecated001} from './client/Deprecated001'
import {Deprecated001Model} from './client/Deprecated001'
import {Deprecated002} from './client/Deprecated002'
import {Deprecated002Model} from './client/Deprecated002'
import {Deprecated003} from './client/Deprecated003'
import {Deprecated003Model} from './client/Deprecated003'
import {Deprecated004} from './client/Deprecated004'
import {Deprecated004Model} from './client/Deprecated004'
import {Deprecated005} from './client/Deprecated005'
import {Deprecated005Model} from './client/Deprecated005'
import {Deprecated006} from './client/Deprecated006'
import {Deprecated006Model} from './client/Deprecated006'
import {Deprecated007} from './client/Deprecated007'
import {Deprecated007Model} from './client/Deprecated007'
import {Deprecated008} from './client/Deprecated008'
import {Deprecated008Model} from './client/Deprecated008'
import {AccountUpdateRequest} from './client/AccountUpdateRequest'
import {AccountUpdateRequestModel} from './client/AccountUpdateRequest'
import {AccountUpdateResponse} from './client/AccountUpdateResponse'
import {AccountUpdateResponseModel} from './client/AccountUpdateResponse'
import {ProfitClosedInfoGetRequest} from './client/ProfitClosedInfoGetRequest'
import {ProfitClosedInfoGetRequestModel} from './client/ProfitClosedInfoGetRequest'
import {ProfitClosedInfoGetResponse} from './client/ProfitClosedInfoGetResponse'
import {ProfitClosedInfoGetResponseModel} from './client/ProfitClosedInfoGetResponse'
import {SymbolDayProfitLossInfoSubscribeRequest} from './client/SymbolDayProfitLossInfoSubscribeRequest'
import {SymbolDayProfitLossInfoSubscribeRequestModel} from './client/SymbolDayProfitLossInfoSubscribeRequest'
import {SymbolDayProfitLossInfoSubscribeResponse} from './client/SymbolDayProfitLossInfoSubscribeResponse'
import {SymbolDayProfitLossInfoSubscribeResponseModel} from './client/SymbolDayProfitLossInfoSubscribeResponse'
import {SymbolDayProfitLossInfoUpdateSubscriptionRequest} from './client/SymbolDayProfitLossInfoUpdateSubscriptionRequest'
import {SymbolDayProfitLossInfoUpdateSubscriptionRequestModel} from './client/SymbolDayProfitLossInfoUpdateSubscriptionRequest'
import {SymbolDayProfitLossInfoUnsubscribeRequest} from './client/SymbolDayProfitLossInfoUnsubscribeRequest'
import {SymbolDayProfitLossInfoUnsubscribeRequestModel} from './client/SymbolDayProfitLossInfoUnsubscribeRequest'
import {SymbolDayProfitLossInfoUnsubscribeResponse} from './client/SymbolDayProfitLossInfoUnsubscribeResponse'
import {SymbolDayProfitLossInfoUnsubscribeResponseModel} from './client/SymbolDayProfitLossInfoUnsubscribeResponse'
import {SymbolDayProfitLossInfoNotify} from './client/SymbolDayProfitLossInfoNotify'
import {SymbolDayProfitLossInfoNotifyModel} from './client/SymbolDayProfitLossInfoNotify'
import {LoginInfoRecord} from './client/LoginInfoRecord'
import {LoginInfoRecordModel} from './client/LoginInfoRecord'
import {LoginInfoGetRequest} from './client/LoginInfoGetRequest'
import {LoginInfoGetRequestModel} from './client/LoginInfoGetRequest'
import {LoginInfoGetResponse} from './client/LoginInfoGetResponse'
import {LoginInfoGetResponseModel} from './client/LoginInfoGetResponse'
import {DashboardInfoShort} from './client/DashboardInfoShort'
import {DashboardInfoShortModel} from './client/DashboardInfoShort'
import {DashboardInfoFull} from './client/DashboardInfoFull'
import {DashboardInfoFullModel} from './client/DashboardInfoFull'
import {DashboardInfoCreateRequest} from './client/DashboardInfoCreateRequest'
import {DashboardInfoCreateRequestModel} from './client/DashboardInfoCreateRequest'
import {DashboardInfoCreateResponse} from './client/DashboardInfoCreateResponse'
import {DashboardInfoCreateResponseModel} from './client/DashboardInfoCreateResponse'
import {DashboardInfoUpdateRequest} from './client/DashboardInfoUpdateRequest'
import {DashboardInfoUpdateRequestModel} from './client/DashboardInfoUpdateRequest'
import {DashboardInfoUpdateResponse} from './client/DashboardInfoUpdateResponse'
import {DashboardInfoUpdateResponseModel} from './client/DashboardInfoUpdateResponse'
import {DashboardInfoListRequest} from './client/DashboardInfoListRequest'
import {DashboardInfoListRequestModel} from './client/DashboardInfoListRequest'
import {DashboardInfoListResponse} from './client/DashboardInfoListResponse'
import {DashboardInfoListResponseModel} from './client/DashboardInfoListResponse'
import {DashboardInfoGetRequest} from './client/DashboardInfoGetRequest'
import {DashboardInfoGetRequestModel} from './client/DashboardInfoGetRequest'
import {DashboardInfoGetResponse} from './client/DashboardInfoGetResponse'
import {DashboardInfoGetResponseModel} from './client/DashboardInfoGetResponse'
import {DashboardInfoRenameRequest} from './client/DashboardInfoRenameRequest'
import {DashboardInfoRenameRequestModel} from './client/DashboardInfoRenameRequest'
import {DashboardInfoRenameResponse} from './client/DashboardInfoRenameResponse'
import {DashboardInfoRenameResponseModel} from './client/DashboardInfoRenameResponse'
import {DashboardInfoDeleteRequest} from './client/DashboardInfoDeleteRequest'
import {DashboardInfoDeleteRequestModel} from './client/DashboardInfoDeleteRequest'
import {DashboardInfoDeleteResponse} from './client/DashboardInfoDeleteResponse'
import {DashboardInfoDeleteResponseModel} from './client/DashboardInfoDeleteResponse'
import {ChartInfoShort} from './client/ChartInfoShort'
import {ChartInfoShortModel} from './client/ChartInfoShort'
import {ChartInfoFull} from './client/ChartInfoFull'
import {ChartInfoFullModel} from './client/ChartInfoFull'
import {ChartInfoCreateRequest} from './client/ChartInfoCreateRequest'
import {ChartInfoCreateRequestModel} from './client/ChartInfoCreateRequest'
import {ChartInfoCreateResponse} from './client/ChartInfoCreateResponse'
import {ChartInfoCreateResponseModel} from './client/ChartInfoCreateResponse'
import {ChartInfoUpdateRequest} from './client/ChartInfoUpdateRequest'
import {ChartInfoUpdateRequestModel} from './client/ChartInfoUpdateRequest'
import {ChartInfoUpdateResponse} from './client/ChartInfoUpdateResponse'
import {ChartInfoUpdateResponseModel} from './client/ChartInfoUpdateResponse'
import {ChartInfoListRequest} from './client/ChartInfoListRequest'
import {ChartInfoListRequestModel} from './client/ChartInfoListRequest'
import {ChartInfoListResponse} from './client/ChartInfoListResponse'
import {ChartInfoListResponseModel} from './client/ChartInfoListResponse'
import {ChartInfoDeleteRequest} from './client/ChartInfoDeleteRequest'
import {ChartInfoDeleteRequestModel} from './client/ChartInfoDeleteRequest'
import {ChartInfoDeleteResponse} from './client/ChartInfoDeleteResponse'
import {ChartInfoDeleteResponseModel} from './client/ChartInfoDeleteResponse'
import {ChartInfoGetRequest} from './client/ChartInfoGetRequest'
import {ChartInfoGetRequestModel} from './client/ChartInfoGetRequest'
import {ChartInfoGetResponse} from './client/ChartInfoGetResponse'
import {ChartInfoGetResponseModel} from './client/ChartInfoGetResponse'
import {SymbolDayProfitLossMultipleInfoSubscribeRequest} from './client/SymbolDayProfitLossMultipleInfoSubscribeRequest'
import {SymbolDayProfitLossMultipleInfoSubscribeRequestModel} from './client/SymbolDayProfitLossMultipleInfoSubscribeRequest'
import {SymbolDayProfitLossMultipleInfoSubscribeResponse} from './client/SymbolDayProfitLossMultipleInfoSubscribeResponse'
import {SymbolDayProfitLossMultipleInfoSubscribeResponseModel} from './client/SymbolDayProfitLossMultipleInfoSubscribeResponse'
import {SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest} from './client/SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest'
import {SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel} from './client/SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest'
import {SymbolDayProfitLossMultipleInfoUnsubscribeRequest} from './client/SymbolDayProfitLossMultipleInfoUnsubscribeRequest'
import {SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel} from './client/SymbolDayProfitLossMultipleInfoUnsubscribeRequest'
import {SymbolDayProfitLossMultipleInfoUnsubscribeResponse} from './client/SymbolDayProfitLossMultipleInfoUnsubscribeResponse'
import {SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel} from './client/SymbolDayProfitLossMultipleInfoUnsubscribeResponse'
import {SymbolDayProfitLossMultipleInfoNotify} from './client/SymbolDayProfitLossMultipleInfoNotify'
import {SymbolDayProfitLossMultipleInfoNotifyModel} from './client/SymbolDayProfitLossMultipleInfoNotify'
import {NewsServiceGetAllRequest} from './client/NewsServiceGetAllRequest'
import {NewsServiceGetAllRequestModel} from './client/NewsServiceGetAllRequest'
import {NewsServiceGetAllResponse} from './client/NewsServiceGetAllResponse'
import {NewsServiceGetAllResponseModel} from './client/NewsServiceGetAllResponse'
import {TradingCentralArticlesQuery} from './client/TradingCentralArticlesQuery'
import {TradingCentralArticlesQueryModel} from './client/TradingCentralArticlesQuery'
import {NewsRecentArticlesGetRequest} from './client/NewsRecentArticlesGetRequest'
import {NewsRecentArticlesGetRequestModel} from './client/NewsRecentArticlesGetRequest'
import {TradingCentralNewsArticleInfo} from './client/TradingCentralNewsArticleInfo'
import {TradingCentralNewsArticleInfoModel} from './client/TradingCentralNewsArticleInfo'
import {NewsRecentArticlesGetResponse} from './client/NewsRecentArticlesGetResponse'
import {NewsRecentArticlesGetResponseModel} from './client/NewsRecentArticlesGetResponse'
import {TradingCentralArticleQuery} from './client/TradingCentralArticleQuery'
import {TradingCentralArticleQueryModel} from './client/TradingCentralArticleQuery'
import {NewsArticleGetRequest} from './client/NewsArticleGetRequest'
import {NewsArticleGetRequestModel} from './client/NewsArticleGetRequest'
import {TradingCentralNewsArticle} from './client/TradingCentralNewsArticle'
import {TradingCentralNewsArticleModel} from './client/TradingCentralNewsArticle'
import {NewsArticleGetResponse} from './client/NewsArticleGetResponse'
import {NewsArticleGetResponseModel} from './client/NewsArticleGetResponse'
import {OrderUpdateWithTypeRequest} from './client/OrderUpdateWithTypeRequest'
import {OrderUpdateWithTypeRequestModel} from './client/OrderUpdateWithTypeRequest'
import {OrderUpdateWithTypeResponse} from './client/OrderUpdateWithTypeResponse'
import {OrderUpdateWithTypeResponseModel} from './client/OrderUpdateWithTypeResponse'
import {SymbolProfitLossMultipleInfoSubscribeRequest} from './client/SymbolProfitLossMultipleInfoSubscribeRequest'
import {SymbolProfitLossMultipleInfoSubscribeRequestModel} from './client/SymbolProfitLossMultipleInfoSubscribeRequest'
import {SymbolProfitLossMultipleInfoSubscribeResponse} from './client/SymbolProfitLossMultipleInfoSubscribeResponse'
import {SymbolProfitLossMultipleInfoSubscribeResponseModel} from './client/SymbolProfitLossMultipleInfoSubscribeResponse'
import {SymbolProfitLossMultipleInfoUpdateSubscriptionRequest} from './client/SymbolProfitLossMultipleInfoUpdateSubscriptionRequest'
import {SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel} from './client/SymbolProfitLossMultipleInfoUpdateSubscriptionRequest'
import {SymbolProfitLossMultipleInfoUnsubscribeRequest} from './client/SymbolProfitLossMultipleInfoUnsubscribeRequest'
import {SymbolProfitLossMultipleInfoUnsubscribeRequestModel} from './client/SymbolProfitLossMultipleInfoUnsubscribeRequest'
import {SymbolProfitLossMultipleInfoUnsubscribeResponse} from './client/SymbolProfitLossMultipleInfoUnsubscribeResponse'
import {SymbolProfitLossMultipleInfoUnsubscribeResponseModel} from './client/SymbolProfitLossMultipleInfoUnsubscribeResponse'
import {SymbolProfitLossMultipleInfo} from './client/SymbolProfitLossMultipleInfo'
import {SymbolProfitLossMultipleInfoModel} from './client/SymbolProfitLossMultipleInfo'
import {SymbolProfitLossMultipleInfoNotify} from './client/SymbolProfitLossMultipleInfoNotify'
import {SymbolProfitLossMultipleInfoNotifyModel} from './client/SymbolProfitLossMultipleInfoNotify'
import {LoginWithPasswordRequest} from './client/LoginWithPasswordRequest'
import {LoginWithPasswordRequestModel} from './client/LoginWithPasswordRequest'
import {LoginWithPasswordResponse} from './client/LoginWithPasswordResponse'
import {LoginWithPasswordResponseModel} from './client/LoginWithPasswordResponse'
import {LoginWithTokenRequest} from './client/LoginWithTokenRequest'
import {LoginWithTokenRequestModel} from './client/LoginWithTokenRequest'
import {LoginWithTokenResponse} from './client/LoginWithTokenResponse'
import {LoginWithTokenResponseModel} from './client/LoginWithTokenResponse'
import {RefreshTokenRequest} from './client/RefreshTokenRequest'
import {RefreshTokenRequestModel} from './client/RefreshTokenRequest'
import {RefreshTokenResponse} from './client/RefreshTokenResponse'
import {RefreshTokenResponseModel} from './client/RefreshTokenResponse'
import {Logout2Request} from './client/Logout2Request'
import {Logout2RequestModel} from './client/Logout2Request'
import {Logout2Response} from './client/Logout2Response'
import {Logout2ResponseModel} from './client/Logout2Response'
import {AccountChangePasswordRequest} from './client/AccountChangePasswordRequest'
import {AccountChangePasswordRequestModel} from './client/AccountChangePasswordRequest'
import {AccountChangePasswordResponse} from './client/AccountChangePasswordResponse'
import {AccountChangePasswordResponseModel} from './client/AccountChangePasswordResponse'
import {PushNotificationTokenRegisterRequest} from './client/PushNotificationTokenRegisterRequest'
import {PushNotificationTokenRegisterRequestModel} from './client/PushNotificationTokenRegisterRequest'
import {PushNotificationTokenRegisterResponse} from './client/PushNotificationTokenRegisterResponse'
import {PushNotificationTokenRegisterResponseModel} from './client/PushNotificationTokenRegisterResponse'
import {DesiredAppSettingsGetRequest} from './client/DesiredAppSettingsGetRequest'
import {DesiredAppSettingsGetRequestModel} from './client/DesiredAppSettingsGetRequest'
import {DesiredAppSettingsGetResponse} from './client/DesiredAppSettingsGetResponse'
import {DesiredAppSettingsGetResponseModel} from './client/DesiredAppSettingsGetResponse'
import {AuthTokenInfo} from './client/AuthTokenInfo'
import {AuthTokenInfoModel} from './client/AuthTokenInfo'
import {AuthTokensGetAllRequest} from './client/AuthTokensGetAllRequest'
import {AuthTokensGetAllRequestModel} from './client/AuthTokensGetAllRequest'
import {AuthTokensGetAllResponse} from './client/AuthTokensGetAllResponse'
import {AuthTokensGetAllResponseModel} from './client/AuthTokensGetAllResponse'
import {AuthTokensDeleteRequest} from './client/AuthTokensDeleteRequest'
import {AuthTokensDeleteRequestModel} from './client/AuthTokensDeleteRequest'
import {AuthTokensDeleteResponse} from './client/AuthTokensDeleteResponse'
import {AuthTokensDeleteResponseModel} from './client/AuthTokensDeleteResponse'
import {LegalDocumentMetadata} from './client/LegalDocumentMetadata'
import {LegalDocumentMetadataModel} from './client/LegalDocumentMetadata'
import {LegalDocumentDescription} from './client/LegalDocumentDescription'
import {LegalDocumentDescriptionModel} from './client/LegalDocumentDescription'
import {LegalDocumentGroup} from './client/LegalDocumentGroup'
import {LegalDocumentGroupModel} from './client/LegalDocumentGroup'
import {LegalDocumentGroupWithDocumentsDescriptions} from './client/LegalDocumentGroupWithDocumentsDescriptions'
import {LegalDocumentGroupWithDocumentsDescriptionsModel} from './client/LegalDocumentGroupWithDocumentsDescriptions'
import {LegalDocumentGroupListRequest} from './client/LegalDocumentGroupListRequest'
import {LegalDocumentGroupListRequestModel} from './client/LegalDocumentGroupListRequest'
import {LegalDocumentGroupListResponse} from './client/LegalDocumentGroupListResponse'
import {LegalDocumentGroupListResponseModel} from './client/LegalDocumentGroupListResponse'
import {LegalDocumentGetRequest} from './client/LegalDocumentGetRequest'
import {LegalDocumentGetRequestModel} from './client/LegalDocumentGetRequest'
import {LegalDocumentGetResponse} from './client/LegalDocumentGetResponse'
import {LegalDocumentGetResponseModel} from './client/LegalDocumentGetResponse'
import {PushNotificationTokenUnRegisterRequest} from './client/PushNotificationTokenUnRegisterRequest'
import {PushNotificationTokenUnRegisterRequestModel} from './client/PushNotificationTokenUnRegisterRequest'
import {PushNotificationTokenUnRegisterResponse} from './client/PushNotificationTokenUnRegisterResponse'
import {PushNotificationTokenUnRegisterResponseModel} from './client/PushNotificationTokenUnRegisterResponse'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding client sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize client sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._exposedSender = new exposed.Sender(this.buffer)
    this._PingRequestModel = new PingRequestModel(this.buffer)
    this._PongResponseModel = new PongResponseModel(this.buffer)
    this._LoginRequestModel = new LoginRequestModel(this.buffer)
    this._LoginResponseModel = new LoginResponseModel(this.buffer)
    this._LoginRejectModel = new LoginRejectModel(this.buffer)
    this._LogoutRequestModel = new LogoutRequestModel(this.buffer)
    this._LogoutResponseModel = new LogoutResponseModel(this.buffer)
    this._LogoutNotifyModel = new LogoutNotifyModel(this.buffer)
    this._SessionInfoRequestModel = new SessionInfoRequestModel(this.buffer)
    this._SessionInfoResponseModel = new SessionInfoResponseModel(this.buffer)
    this._TimeInfoRequestModel = new TimeInfoRequestModel(this.buffer)
    this._TimeInfoResponseModel = new TimeInfoResponseModel(this.buffer)
    this._TimezoneInfoRequestModel = new TimezoneInfoRequestModel(this.buffer)
    this._TimezoneInfoResponseModel = new TimezoneInfoResponseModel(this.buffer)
    this._TimezoneInfoUpdateNotifyModel = new TimezoneInfoUpdateNotifyModel(this.buffer)
    this._CurrencyInfoRequestModel = new CurrencyInfoRequestModel(this.buffer)
    this._CurrencyInfoResponseModel = new CurrencyInfoResponseModel(this.buffer)
    this._CurrencyListRequestModel = new CurrencyListRequestModel(this.buffer)
    this._CurrencyListResponseModel = new CurrencyListResponseModel(this.buffer)
    this._CurrencyInfoUpdateNotifyModel = new CurrencyInfoUpdateNotifyModel(this.buffer)
    this._SymbolInfoRequestModel = new SymbolInfoRequestModel(this.buffer)
    this._SymbolInfoResponseModel = new SymbolInfoResponseModel(this.buffer)
    this._SymbolListRequestModel = new SymbolListRequestModel(this.buffer)
    this._SymbolListResponseModel = new SymbolListResponseModel(this.buffer)
    this._SymbolInfoUpdateNotifyModel = new SymbolInfoUpdateNotifyModel(this.buffer)
    this._TickInfoRequestModel = new TickInfoRequestModel(this.buffer)
    this._TickInfoResponseModel = new TickInfoResponseModel(this.buffer)
    this._TickFilterRequestModel = new TickFilterRequestModel(this.buffer)
    this._TickFilterResponseModel = new TickFilterResponseModel(this.buffer)
    this._TickListRequestModel = new TickListRequestModel(this.buffer)
    this._TickListResponseModel = new TickListResponseModel(this.buffer)
    this._Level2InfoRequestModel = new Level2InfoRequestModel(this.buffer)
    this._Level2InfoResponseModel = new Level2InfoResponseModel(this.buffer)
    this._Level2FilterRequestModel = new Level2FilterRequestModel(this.buffer)
    this._Level2FilterResponseModel = new Level2FilterResponseModel(this.buffer)
    this._Level2ListRequestModel = new Level2ListRequestModel(this.buffer)
    this._Level2ListResponseModel = new Level2ListResponseModel(this.buffer)
    this._FeedSubscriptionInfoRequestModel = new FeedSubscriptionInfoRequestModel(this.buffer)
    this._FeedSubscriptionInfoResponseModel = new FeedSubscriptionInfoResponseModel(this.buffer)
    this._FeedSubscribeRequestModel = new FeedSubscribeRequestModel(this.buffer)
    this._FeedSubscribeAllRequestModel = new FeedSubscribeAllRequestModel(this.buffer)
    this._FeedSubscribeResponseModel = new FeedSubscribeResponseModel(this.buffer)
    this._FeedUnsubscribeRequestModel = new FeedUnsubscribeRequestModel(this.buffer)
    this._FeedUnsubscribeAllRequestModel = new FeedUnsubscribeAllRequestModel(this.buffer)
    this._FeedUnsubscribeResponseModel = new FeedUnsubscribeResponseModel(this.buffer)
    this._TickSnapshotNotifyModel = new TickSnapshotNotifyModel(this.buffer)
    this._TickUpdateNotifyModel = new TickUpdateNotifyModel(this.buffer)
    this._Level2SnapshotNotifyModel = new Level2SnapshotNotifyModel(this.buffer)
    this._Level2UpdateNotifyModel = new Level2UpdateNotifyModel(this.buffer)
    this._ChartBarHistoryRequestModel = new ChartBarHistoryRequestModel(this.buffer)
    this._ChartBarHistoryResponseModel = new ChartBarHistoryResponseModel(this.buffer)
    this._ChartBarSubscribeRequestModel = new ChartBarSubscribeRequestModel(this.buffer)
    this._ChartBarSubscribeResponseModel = new ChartBarSubscribeResponseModel(this.buffer)
    this._ChartBarUnsubscribeRequestModel = new ChartBarUnsubscribeRequestModel(this.buffer)
    this._ChartBarUnsubscribeResponseModel = new ChartBarUnsubscribeResponseModel(this.buffer)
    this._ChartBarNotifyModel = new ChartBarNotifyModel(this.buffer)
    this._ChartBarUpdateNotifyModel = new ChartBarUpdateNotifyModel(this.buffer)
    this._PlatformInfoRequestModel = new PlatformInfoRequestModel(this.buffer)
    this._PlatformInfoResponseModel = new PlatformInfoResponseModel(this.buffer)
    this._PlatformInfoUpdateNotifyModel = new PlatformInfoUpdateNotifyModel(this.buffer)
    this._AccountInfoRequestModel = new AccountInfoRequestModel(this.buffer)
    this._AccountInfoResponseModel = new AccountInfoResponseModel(this.buffer)
    this._AccountInfoUpdateNotifyModel = new AccountInfoUpdateNotifyModel(this.buffer)
    this._MarginInfoRequestModel = new MarginInfoRequestModel(this.buffer)
    this._MarginInfoResponseModel = new MarginInfoResponseModel(this.buffer)
    this._MarginInfoUpdateNotifyModel = new MarginInfoUpdateNotifyModel(this.buffer)
    this._MarginCallNotifyModel = new MarginCallNotifyModel(this.buffer)
    this._StopOutNotifyModel = new StopOutNotifyModel(this.buffer)
    this._AssetInfoRequestModel = new AssetInfoRequestModel(this.buffer)
    this._AssetInfoResponseModel = new AssetInfoResponseModel(this.buffer)
    this._AssetListRequestModel = new AssetListRequestModel(this.buffer)
    this._AssetListResponseModel = new AssetListResponseModel(this.buffer)
    this._AssetInfoUpdateNotifyModel = new AssetInfoUpdateNotifyModel(this.buffer)
    this._OrderInfoRequestModel = new OrderInfoRequestModel(this.buffer)
    this._OrderInfoResponseModel = new OrderInfoResponseModel(this.buffer)
    this._OrderListRequestModel = new OrderListRequestModel(this.buffer)
    this._OrderListResponseModel = new OrderListResponseModel(this.buffer)
    this._OrderOpenRequestModel = new OrderOpenRequestModel(this.buffer)
    this._OrderOpenResponseModel = new OrderOpenResponseModel(this.buffer)
    this._OrderUpdateRequestModel = new OrderUpdateRequestModel(this.buffer)
    this._OrderUpdateResponseModel = new OrderUpdateResponseModel(this.buffer)
    this._OrderCancelRequestModel = new OrderCancelRequestModel(this.buffer)
    this._OrderCancelResponseModel = new OrderCancelResponseModel(this.buffer)
    this._OrderCancelAllRequestModel = new OrderCancelAllRequestModel(this.buffer)
    this._OrderCancelAllResponseModel = new OrderCancelAllResponseModel(this.buffer)
    this._OrderCancelAllByIdRequestModel = new OrderCancelAllByIdRequestModel(this.buffer)
    this._OrderCloseRequestModel = new OrderCloseRequestModel(this.buffer)
    this._OrderCloseResponseModel = new OrderCloseResponseModel(this.buffer)
    this._OrderCloseAllRequestModel = new OrderCloseAllRequestModel(this.buffer)
    this._OrderCloseAllResponseModel = new OrderCloseAllResponseModel(this.buffer)
    this._OrderCloseAllByIdRequestModel = new OrderCloseAllByIdRequestModel(this.buffer)
    this._OrderCloseByRequestModel = new OrderCloseByRequestModel(this.buffer)
    this._OrderCloseByResponseModel = new OrderCloseByResponseModel(this.buffer)
    this._OrderCloseByAllRequestModel = new OrderCloseByAllRequestModel(this.buffer)
    this._OrderCloseByAllResponseModel = new OrderCloseByAllResponseModel(this.buffer)
    this._OrderCancelOrCloseRequestModel = new OrderCancelOrCloseRequestModel(this.buffer)
    this._OrderCancelOrCloseResponseModel = new OrderCancelOrCloseResponseModel(this.buffer)
    this._OrderCancelOrCloseAllRequestModel = new OrderCancelOrCloseAllRequestModel(this.buffer)
    this._OrderCancelOrCloseAllResponseModel = new OrderCancelOrCloseAllResponseModel(this.buffer)
    this._OrderInfoUpdateNotifyModel = new OrderInfoUpdateNotifyModel(this.buffer)
    this._PositionInfoRequestModel = new PositionInfoRequestModel(this.buffer)
    this._PositionInfoResponseModel = new PositionInfoResponseModel(this.buffer)
    this._PositionListRequestModel = new PositionListRequestModel(this.buffer)
    this._PositionListResponseModel = new PositionListResponseModel(this.buffer)
    this._PositionInfoUpdateNotifyModel = new PositionInfoUpdateNotifyModel(this.buffer)
    this._TradeHistoryInfoRequestModel = new TradeHistoryInfoRequestModel(this.buffer)
    this._TradeHistoryInfoResponseModel = new TradeHistoryInfoResponseModel(this.buffer)
    this._TradeHistoryInfoFilterRequestModel = new TradeHistoryInfoFilterRequestModel(this.buffer)
    this._TradeHistoryInfoByIdRequestModel = new TradeHistoryInfoByIdRequestModel(this.buffer)
    this._TradeHistoryInfoByIdResponseModel = new TradeHistoryInfoByIdResponseModel(this.buffer)
    this._TradeHistoryInfoUpdateNotifyModel = new TradeHistoryInfoUpdateNotifyModel(this.buffer)
    this._CalendarRegularRuleGetRequestModel = new CalendarRegularRuleGetRequestModel(this.buffer)
    this._CalendarRegularRuleGetResponseModel = new CalendarRegularRuleGetResponseModel(this.buffer)
    this._CalendarRegularRuleUpdateNotifyModel = new CalendarRegularRuleUpdateNotifyModel(this.buffer)
    this._CalendarOffTimeRuleGetRequestModel = new CalendarOffTimeRuleGetRequestModel(this.buffer)
    this._CalendarOffTimeRuleGetResponseModel = new CalendarOffTimeRuleGetResponseModel(this.buffer)
    this._CalendarOffTimeRuleUpdateNotifyModel = new CalendarOffTimeRuleUpdateNotifyModel(this.buffer)
    this._CalendarRulesGetRequestModel = new CalendarRulesGetRequestModel(this.buffer)
    this._CalendarRulesGetResponseModel = new CalendarRulesGetResponseModel(this.buffer)
    this._ChartBarHistoryRangeRequestModel = new ChartBarHistoryRangeRequestModel(this.buffer)
    this._OrderMarginSubscribeRequestModel = new OrderMarginSubscribeRequestModel(this.buffer)
    this._OrderMarginSubscribeResponseModel = new OrderMarginSubscribeResponseModel(this.buffer)
    this._OrderMarginNotifyModel = new OrderMarginNotifyModel(this.buffer)
    this._OrderMarginUpdateSubscriptionRequestModel = new OrderMarginUpdateSubscriptionRequestModel(this.buffer)
    this._OrderMarginUnsubscribeRequestModel = new OrderMarginUnsubscribeRequestModel(this.buffer)
    this._OrderMarginUnsubscribeResponseModel = new OrderMarginUnsubscribeResponseModel(this.buffer)
    this._OrderMarginGetRequestModel = new OrderMarginGetRequestModel(this.buffer)
    this._OrderMarginGetResponseModel = new OrderMarginGetResponseModel(this.buffer)
    this._OrderStateInfoSubscribeRequestModel = new OrderStateInfoSubscribeRequestModel(this.buffer)
    this._OrderStateInfoSubscribeResponseModel = new OrderStateInfoSubscribeResponseModel(this.buffer)
    this._OrderStateInfoUnsubscribeRequestModel = new OrderStateInfoUnsubscribeRequestModel(this.buffer)
    this._OrderStateInfoUnsubscribeResponseModel = new OrderStateInfoUnsubscribeResponseModel(this.buffer)
    this._OrderStateInfoNotifyModel = new OrderStateInfoNotifyModel(this.buffer)
    this._Deprecated003Model = new Deprecated003Model(this.buffer)
    this._Deprecated004Model = new Deprecated004Model(this.buffer)
    this._Deprecated005Model = new Deprecated005Model(this.buffer)
    this._Deprecated006Model = new Deprecated006Model(this.buffer)
    this._Deprecated007Model = new Deprecated007Model(this.buffer)
    this._Deprecated008Model = new Deprecated008Model(this.buffer)
    this._AccountUpdateRequestModel = new AccountUpdateRequestModel(this.buffer)
    this._AccountUpdateResponseModel = new AccountUpdateResponseModel(this.buffer)
    this._ProfitClosedInfoGetRequestModel = new ProfitClosedInfoGetRequestModel(this.buffer)
    this._ProfitClosedInfoGetResponseModel = new ProfitClosedInfoGetResponseModel(this.buffer)
    this._SymbolDayProfitLossInfoSubscribeRequestModel = new SymbolDayProfitLossInfoSubscribeRequestModel(this.buffer)
    this._SymbolDayProfitLossInfoSubscribeResponseModel = new SymbolDayProfitLossInfoSubscribeResponseModel(this.buffer)
    this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel = new SymbolDayProfitLossInfoUpdateSubscriptionRequestModel(this.buffer)
    this._SymbolDayProfitLossInfoUnsubscribeRequestModel = new SymbolDayProfitLossInfoUnsubscribeRequestModel(this.buffer)
    this._SymbolDayProfitLossInfoUnsubscribeResponseModel = new SymbolDayProfitLossInfoUnsubscribeResponseModel(this.buffer)
    this._SymbolDayProfitLossInfoNotifyModel = new SymbolDayProfitLossInfoNotifyModel(this.buffer)
    this._LoginInfoGetRequestModel = new LoginInfoGetRequestModel(this.buffer)
    this._LoginInfoGetResponseModel = new LoginInfoGetResponseModel(this.buffer)
    this._DashboardInfoCreateRequestModel = new DashboardInfoCreateRequestModel(this.buffer)
    this._DashboardInfoCreateResponseModel = new DashboardInfoCreateResponseModel(this.buffer)
    this._DashboardInfoUpdateRequestModel = new DashboardInfoUpdateRequestModel(this.buffer)
    this._DashboardInfoUpdateResponseModel = new DashboardInfoUpdateResponseModel(this.buffer)
    this._DashboardInfoListRequestModel = new DashboardInfoListRequestModel(this.buffer)
    this._DashboardInfoListResponseModel = new DashboardInfoListResponseModel(this.buffer)
    this._DashboardInfoGetRequestModel = new DashboardInfoGetRequestModel(this.buffer)
    this._DashboardInfoGetResponseModel = new DashboardInfoGetResponseModel(this.buffer)
    this._DashboardInfoRenameRequestModel = new DashboardInfoRenameRequestModel(this.buffer)
    this._DashboardInfoRenameResponseModel = new DashboardInfoRenameResponseModel(this.buffer)
    this._DashboardInfoDeleteRequestModel = new DashboardInfoDeleteRequestModel(this.buffer)
    this._DashboardInfoDeleteResponseModel = new DashboardInfoDeleteResponseModel(this.buffer)
    this._ChartInfoCreateRequestModel = new ChartInfoCreateRequestModel(this.buffer)
    this._ChartInfoCreateResponseModel = new ChartInfoCreateResponseModel(this.buffer)
    this._ChartInfoUpdateRequestModel = new ChartInfoUpdateRequestModel(this.buffer)
    this._ChartInfoUpdateResponseModel = new ChartInfoUpdateResponseModel(this.buffer)
    this._ChartInfoListRequestModel = new ChartInfoListRequestModel(this.buffer)
    this._ChartInfoListResponseModel = new ChartInfoListResponseModel(this.buffer)
    this._ChartInfoDeleteRequestModel = new ChartInfoDeleteRequestModel(this.buffer)
    this._ChartInfoDeleteResponseModel = new ChartInfoDeleteResponseModel(this.buffer)
    this._ChartInfoGetRequestModel = new ChartInfoGetRequestModel(this.buffer)
    this._ChartInfoGetResponseModel = new ChartInfoGetResponseModel(this.buffer)
    this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel = new SymbolDayProfitLossMultipleInfoSubscribeRequestModel(this.buffer)
    this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel = new SymbolDayProfitLossMultipleInfoSubscribeResponseModel(this.buffer)
    this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel = new SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel(this.buffer)
    this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel = new SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel(this.buffer)
    this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel = new SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel(this.buffer)
    this._SymbolDayProfitLossMultipleInfoNotifyModel = new SymbolDayProfitLossMultipleInfoNotifyModel(this.buffer)
    this._NewsServiceGetAllRequestModel = new NewsServiceGetAllRequestModel(this.buffer)
    this._NewsServiceGetAllResponseModel = new NewsServiceGetAllResponseModel(this.buffer)
    this._NewsRecentArticlesGetRequestModel = new NewsRecentArticlesGetRequestModel(this.buffer)
    this._NewsRecentArticlesGetResponseModel = new NewsRecentArticlesGetResponseModel(this.buffer)
    this._NewsArticleGetRequestModel = new NewsArticleGetRequestModel(this.buffer)
    this._NewsArticleGetResponseModel = new NewsArticleGetResponseModel(this.buffer)
    this._OrderUpdateWithTypeRequestModel = new OrderUpdateWithTypeRequestModel(this.buffer)
    this._OrderUpdateWithTypeResponseModel = new OrderUpdateWithTypeResponseModel(this.buffer)
    this._SymbolProfitLossMultipleInfoSubscribeRequestModel = new SymbolProfitLossMultipleInfoSubscribeRequestModel(this.buffer)
    this._SymbolProfitLossMultipleInfoSubscribeResponseModel = new SymbolProfitLossMultipleInfoSubscribeResponseModel(this.buffer)
    this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel = new SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel(this.buffer)
    this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel = new SymbolProfitLossMultipleInfoUnsubscribeRequestModel(this.buffer)
    this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel = new SymbolProfitLossMultipleInfoUnsubscribeResponseModel(this.buffer)
    this._SymbolProfitLossMultipleInfoNotifyModel = new SymbolProfitLossMultipleInfoNotifyModel(this.buffer)
    this._LoginWithPasswordRequestModel = new LoginWithPasswordRequestModel(this.buffer)
    this._LoginWithPasswordResponseModel = new LoginWithPasswordResponseModel(this.buffer)
    this._LoginWithTokenRequestModel = new LoginWithTokenRequestModel(this.buffer)
    this._LoginWithTokenResponseModel = new LoginWithTokenResponseModel(this.buffer)
    this._RefreshTokenRequestModel = new RefreshTokenRequestModel(this.buffer)
    this._RefreshTokenResponseModel = new RefreshTokenResponseModel(this.buffer)
    this._Logout2RequestModel = new Logout2RequestModel(this.buffer)
    this._Logout2ResponseModel = new Logout2ResponseModel(this.buffer)
    this._AccountChangePasswordRequestModel = new AccountChangePasswordRequestModel(this.buffer)
    this._AccountChangePasswordResponseModel = new AccountChangePasswordResponseModel(this.buffer)
    this._PushNotificationTokenRegisterRequestModel = new PushNotificationTokenRegisterRequestModel(this.buffer)
    this._PushNotificationTokenRegisterResponseModel = new PushNotificationTokenRegisterResponseModel(this.buffer)
    this._DesiredAppSettingsGetRequestModel = new DesiredAppSettingsGetRequestModel(this.buffer)
    this._DesiredAppSettingsGetResponseModel = new DesiredAppSettingsGetResponseModel(this.buffer)
    this._AuthTokensGetAllRequestModel = new AuthTokensGetAllRequestModel(this.buffer)
    this._AuthTokensGetAllResponseModel = new AuthTokensGetAllResponseModel(this.buffer)
    this._AuthTokensDeleteRequestModel = new AuthTokensDeleteRequestModel(this.buffer)
    this._AuthTokensDeleteResponseModel = new AuthTokensDeleteResponseModel(this.buffer)
    this._LegalDocumentGroupListRequestModel = new LegalDocumentGroupListRequestModel(this.buffer)
    this._LegalDocumentGroupListResponseModel = new LegalDocumentGroupListResponseModel(this.buffer)
    this._LegalDocumentGetRequestModel = new LegalDocumentGetRequestModel(this.buffer)
    this._LegalDocumentGetResponseModel = new LegalDocumentGetResponseModel(this.buffer)
    this._PushNotificationTokenUnRegisterRequestModel = new PushNotificationTokenUnRegisterRequestModel(this.buffer)
    this._PushNotificationTokenUnRegisterResponseModel = new PushNotificationTokenUnRegisterResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  /**
   * Get imported exposed sender
   * @this {!Sender}
   * @returns {!exposed.Sender} exposed sender
   */
  get exposedSender () {
    return this._exposedSender
  }

  // Sender models accessors

  /**
   * Get PingRequest model
   * @this {!Sender}
   * @returns {!PingRequestModel} PingRequest model
   */
  get PingRequestModel () {
    return this._PingRequestModel
  }

  /**
   * Get PongResponse model
   * @this {!Sender}
   * @returns {!PongResponseModel} PongResponse model
   */
  get PongResponseModel () {
    return this._PongResponseModel
  }

  /**
   * Get LoginRequest model
   * @this {!Sender}
   * @returns {!LoginRequestModel} LoginRequest model
   */
  get LoginRequestModel () {
    return this._LoginRequestModel
  }

  /**
   * Get LoginResponse model
   * @this {!Sender}
   * @returns {!LoginResponseModel} LoginResponse model
   */
  get LoginResponseModel () {
    return this._LoginResponseModel
  }

  /**
   * Get LoginReject model
   * @this {!Sender}
   * @returns {!LoginRejectModel} LoginReject model
   */
  get LoginRejectModel () {
    return this._LoginRejectModel
  }

  /**
   * Get LogoutRequest model
   * @this {!Sender}
   * @returns {!LogoutRequestModel} LogoutRequest model
   */
  get LogoutRequestModel () {
    return this._LogoutRequestModel
  }

  /**
   * Get LogoutResponse model
   * @this {!Sender}
   * @returns {!LogoutResponseModel} LogoutResponse model
   */
  get LogoutResponseModel () {
    return this._LogoutResponseModel
  }

  /**
   * Get LogoutNotify model
   * @this {!Sender}
   * @returns {!LogoutNotifyModel} LogoutNotify model
   */
  get LogoutNotifyModel () {
    return this._LogoutNotifyModel
  }

  /**
   * Get SessionInfoRequest model
   * @this {!Sender}
   * @returns {!SessionInfoRequestModel} SessionInfoRequest model
   */
  get SessionInfoRequestModel () {
    return this._SessionInfoRequestModel
  }

  /**
   * Get SessionInfoResponse model
   * @this {!Sender}
   * @returns {!SessionInfoResponseModel} SessionInfoResponse model
   */
  get SessionInfoResponseModel () {
    return this._SessionInfoResponseModel
  }

  /**
   * Get TimeInfoRequest model
   * @this {!Sender}
   * @returns {!TimeInfoRequestModel} TimeInfoRequest model
   */
  get TimeInfoRequestModel () {
    return this._TimeInfoRequestModel
  }

  /**
   * Get TimeInfoResponse model
   * @this {!Sender}
   * @returns {!TimeInfoResponseModel} TimeInfoResponse model
   */
  get TimeInfoResponseModel () {
    return this._TimeInfoResponseModel
  }

  /**
   * Get TimezoneInfoRequest model
   * @this {!Sender}
   * @returns {!TimezoneInfoRequestModel} TimezoneInfoRequest model
   */
  get TimezoneInfoRequestModel () {
    return this._TimezoneInfoRequestModel
  }

  /**
   * Get TimezoneInfoResponse model
   * @this {!Sender}
   * @returns {!TimezoneInfoResponseModel} TimezoneInfoResponse model
   */
  get TimezoneInfoResponseModel () {
    return this._TimezoneInfoResponseModel
  }

  /**
   * Get TimezoneInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!TimezoneInfoUpdateNotifyModel} TimezoneInfoUpdateNotify model
   */
  get TimezoneInfoUpdateNotifyModel () {
    return this._TimezoneInfoUpdateNotifyModel
  }

  /**
   * Get CurrencyInfoRequest model
   * @this {!Sender}
   * @returns {!CurrencyInfoRequestModel} CurrencyInfoRequest model
   */
  get CurrencyInfoRequestModel () {
    return this._CurrencyInfoRequestModel
  }

  /**
   * Get CurrencyInfoResponse model
   * @this {!Sender}
   * @returns {!CurrencyInfoResponseModel} CurrencyInfoResponse model
   */
  get CurrencyInfoResponseModel () {
    return this._CurrencyInfoResponseModel
  }

  /**
   * Get CurrencyListRequest model
   * @this {!Sender}
   * @returns {!CurrencyListRequestModel} CurrencyListRequest model
   */
  get CurrencyListRequestModel () {
    return this._CurrencyListRequestModel
  }

  /**
   * Get CurrencyListResponse model
   * @this {!Sender}
   * @returns {!CurrencyListResponseModel} CurrencyListResponse model
   */
  get CurrencyListResponseModel () {
    return this._CurrencyListResponseModel
  }

  /**
   * Get CurrencyInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!CurrencyInfoUpdateNotifyModel} CurrencyInfoUpdateNotify model
   */
  get CurrencyInfoUpdateNotifyModel () {
    return this._CurrencyInfoUpdateNotifyModel
  }

  /**
   * Get SymbolInfoRequest model
   * @this {!Sender}
   * @returns {!SymbolInfoRequestModel} SymbolInfoRequest model
   */
  get SymbolInfoRequestModel () {
    return this._SymbolInfoRequestModel
  }

  /**
   * Get SymbolInfoResponse model
   * @this {!Sender}
   * @returns {!SymbolInfoResponseModel} SymbolInfoResponse model
   */
  get SymbolInfoResponseModel () {
    return this._SymbolInfoResponseModel
  }

  /**
   * Get SymbolListRequest model
   * @this {!Sender}
   * @returns {!SymbolListRequestModel} SymbolListRequest model
   */
  get SymbolListRequestModel () {
    return this._SymbolListRequestModel
  }

  /**
   * Get SymbolListResponse model
   * @this {!Sender}
   * @returns {!SymbolListResponseModel} SymbolListResponse model
   */
  get SymbolListResponseModel () {
    return this._SymbolListResponseModel
  }

  /**
   * Get SymbolInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolInfoUpdateNotifyModel} SymbolInfoUpdateNotify model
   */
  get SymbolInfoUpdateNotifyModel () {
    return this._SymbolInfoUpdateNotifyModel
  }

  /**
   * Get TickInfoRequest model
   * @this {!Sender}
   * @returns {!TickInfoRequestModel} TickInfoRequest model
   */
  get TickInfoRequestModel () {
    return this._TickInfoRequestModel
  }

  /**
   * Get TickInfoResponse model
   * @this {!Sender}
   * @returns {!TickInfoResponseModel} TickInfoResponse model
   */
  get TickInfoResponseModel () {
    return this._TickInfoResponseModel
  }

  /**
   * Get TickFilterRequest model
   * @this {!Sender}
   * @returns {!TickFilterRequestModel} TickFilterRequest model
   */
  get TickFilterRequestModel () {
    return this._TickFilterRequestModel
  }

  /**
   * Get TickFilterResponse model
   * @this {!Sender}
   * @returns {!TickFilterResponseModel} TickFilterResponse model
   */
  get TickFilterResponseModel () {
    return this._TickFilterResponseModel
  }

  /**
   * Get TickListRequest model
   * @this {!Sender}
   * @returns {!TickListRequestModel} TickListRequest model
   */
  get TickListRequestModel () {
    return this._TickListRequestModel
  }

  /**
   * Get TickListResponse model
   * @this {!Sender}
   * @returns {!TickListResponseModel} TickListResponse model
   */
  get TickListResponseModel () {
    return this._TickListResponseModel
  }

  /**
   * Get Level2InfoRequest model
   * @this {!Sender}
   * @returns {!Level2InfoRequestModel} Level2InfoRequest model
   */
  get Level2InfoRequestModel () {
    return this._Level2InfoRequestModel
  }

  /**
   * Get Level2InfoResponse model
   * @this {!Sender}
   * @returns {!Level2InfoResponseModel} Level2InfoResponse model
   */
  get Level2InfoResponseModel () {
    return this._Level2InfoResponseModel
  }

  /**
   * Get Level2FilterRequest model
   * @this {!Sender}
   * @returns {!Level2FilterRequestModel} Level2FilterRequest model
   */
  get Level2FilterRequestModel () {
    return this._Level2FilterRequestModel
  }

  /**
   * Get Level2FilterResponse model
   * @this {!Sender}
   * @returns {!Level2FilterResponseModel} Level2FilterResponse model
   */
  get Level2FilterResponseModel () {
    return this._Level2FilterResponseModel
  }

  /**
   * Get Level2ListRequest model
   * @this {!Sender}
   * @returns {!Level2ListRequestModel} Level2ListRequest model
   */
  get Level2ListRequestModel () {
    return this._Level2ListRequestModel
  }

  /**
   * Get Level2ListResponse model
   * @this {!Sender}
   * @returns {!Level2ListResponseModel} Level2ListResponse model
   */
  get Level2ListResponseModel () {
    return this._Level2ListResponseModel
  }

  /**
   * Get FeedSubscriptionInfoRequest model
   * @this {!Sender}
   * @returns {!FeedSubscriptionInfoRequestModel} FeedSubscriptionInfoRequest model
   */
  get FeedSubscriptionInfoRequestModel () {
    return this._FeedSubscriptionInfoRequestModel
  }

  /**
   * Get FeedSubscriptionInfoResponse model
   * @this {!Sender}
   * @returns {!FeedSubscriptionInfoResponseModel} FeedSubscriptionInfoResponse model
   */
  get FeedSubscriptionInfoResponseModel () {
    return this._FeedSubscriptionInfoResponseModel
  }

  /**
   * Get FeedSubscribeRequest model
   * @this {!Sender}
   * @returns {!FeedSubscribeRequestModel} FeedSubscribeRequest model
   */
  get FeedSubscribeRequestModel () {
    return this._FeedSubscribeRequestModel
  }

  /**
   * Get FeedSubscribeAllRequest model
   * @this {!Sender}
   * @returns {!FeedSubscribeAllRequestModel} FeedSubscribeAllRequest model
   */
  get FeedSubscribeAllRequestModel () {
    return this._FeedSubscribeAllRequestModel
  }

  /**
   * Get FeedSubscribeResponse model
   * @this {!Sender}
   * @returns {!FeedSubscribeResponseModel} FeedSubscribeResponse model
   */
  get FeedSubscribeResponseModel () {
    return this._FeedSubscribeResponseModel
  }

  /**
   * Get FeedUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!FeedUnsubscribeRequestModel} FeedUnsubscribeRequest model
   */
  get FeedUnsubscribeRequestModel () {
    return this._FeedUnsubscribeRequestModel
  }

  /**
   * Get FeedUnsubscribeAllRequest model
   * @this {!Sender}
   * @returns {!FeedUnsubscribeAllRequestModel} FeedUnsubscribeAllRequest model
   */
  get FeedUnsubscribeAllRequestModel () {
    return this._FeedUnsubscribeAllRequestModel
  }

  /**
   * Get FeedUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!FeedUnsubscribeResponseModel} FeedUnsubscribeResponse model
   */
  get FeedUnsubscribeResponseModel () {
    return this._FeedUnsubscribeResponseModel
  }

  /**
   * Get TickSnapshotNotify model
   * @this {!Sender}
   * @returns {!TickSnapshotNotifyModel} TickSnapshotNotify model
   */
  get TickSnapshotNotifyModel () {
    return this._TickSnapshotNotifyModel
  }

  /**
   * Get TickUpdateNotify model
   * @this {!Sender}
   * @returns {!TickUpdateNotifyModel} TickUpdateNotify model
   */
  get TickUpdateNotifyModel () {
    return this._TickUpdateNotifyModel
  }

  /**
   * Get Level2SnapshotNotify model
   * @this {!Sender}
   * @returns {!Level2SnapshotNotifyModel} Level2SnapshotNotify model
   */
  get Level2SnapshotNotifyModel () {
    return this._Level2SnapshotNotifyModel
  }

  /**
   * Get Level2UpdateNotify model
   * @this {!Sender}
   * @returns {!Level2UpdateNotifyModel} Level2UpdateNotify model
   */
  get Level2UpdateNotifyModel () {
    return this._Level2UpdateNotifyModel
  }

  /**
   * Get ChartBarHistoryRequest model
   * @this {!Sender}
   * @returns {!ChartBarHistoryRequestModel} ChartBarHistoryRequest model
   */
  get ChartBarHistoryRequestModel () {
    return this._ChartBarHistoryRequestModel
  }

  /**
   * Get ChartBarHistoryResponse model
   * @this {!Sender}
   * @returns {!ChartBarHistoryResponseModel} ChartBarHistoryResponse model
   */
  get ChartBarHistoryResponseModel () {
    return this._ChartBarHistoryResponseModel
  }

  /**
   * Get ChartBarSubscribeRequest model
   * @this {!Sender}
   * @returns {!ChartBarSubscribeRequestModel} ChartBarSubscribeRequest model
   */
  get ChartBarSubscribeRequestModel () {
    return this._ChartBarSubscribeRequestModel
  }

  /**
   * Get ChartBarSubscribeResponse model
   * @this {!Sender}
   * @returns {!ChartBarSubscribeResponseModel} ChartBarSubscribeResponse model
   */
  get ChartBarSubscribeResponseModel () {
    return this._ChartBarSubscribeResponseModel
  }

  /**
   * Get ChartBarUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!ChartBarUnsubscribeRequestModel} ChartBarUnsubscribeRequest model
   */
  get ChartBarUnsubscribeRequestModel () {
    return this._ChartBarUnsubscribeRequestModel
  }

  /**
   * Get ChartBarUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!ChartBarUnsubscribeResponseModel} ChartBarUnsubscribeResponse model
   */
  get ChartBarUnsubscribeResponseModel () {
    return this._ChartBarUnsubscribeResponseModel
  }

  /**
   * Get ChartBarNotify model
   * @this {!Sender}
   * @returns {!ChartBarNotifyModel} ChartBarNotify model
   */
  get ChartBarNotifyModel () {
    return this._ChartBarNotifyModel
  }

  /**
   * Get ChartBarUpdateNotify model
   * @this {!Sender}
   * @returns {!ChartBarUpdateNotifyModel} ChartBarUpdateNotify model
   */
  get ChartBarUpdateNotifyModel () {
    return this._ChartBarUpdateNotifyModel
  }

  /**
   * Get PlatformInfoRequest model
   * @this {!Sender}
   * @returns {!PlatformInfoRequestModel} PlatformInfoRequest model
   */
  get PlatformInfoRequestModel () {
    return this._PlatformInfoRequestModel
  }

  /**
   * Get PlatformInfoResponse model
   * @this {!Sender}
   * @returns {!PlatformInfoResponseModel} PlatformInfoResponse model
   */
  get PlatformInfoResponseModel () {
    return this._PlatformInfoResponseModel
  }

  /**
   * Get PlatformInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!PlatformInfoUpdateNotifyModel} PlatformInfoUpdateNotify model
   */
  get PlatformInfoUpdateNotifyModel () {
    return this._PlatformInfoUpdateNotifyModel
  }

  /**
   * Get AccountInfoRequest model
   * @this {!Sender}
   * @returns {!AccountInfoRequestModel} AccountInfoRequest model
   */
  get AccountInfoRequestModel () {
    return this._AccountInfoRequestModel
  }

  /**
   * Get AccountInfoResponse model
   * @this {!Sender}
   * @returns {!AccountInfoResponseModel} AccountInfoResponse model
   */
  get AccountInfoResponseModel () {
    return this._AccountInfoResponseModel
  }

  /**
   * Get AccountInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!AccountInfoUpdateNotifyModel} AccountInfoUpdateNotify model
   */
  get AccountInfoUpdateNotifyModel () {
    return this._AccountInfoUpdateNotifyModel
  }

  /**
   * Get MarginInfoRequest model
   * @this {!Sender}
   * @returns {!MarginInfoRequestModel} MarginInfoRequest model
   */
  get MarginInfoRequestModel () {
    return this._MarginInfoRequestModel
  }

  /**
   * Get MarginInfoResponse model
   * @this {!Sender}
   * @returns {!MarginInfoResponseModel} MarginInfoResponse model
   */
  get MarginInfoResponseModel () {
    return this._MarginInfoResponseModel
  }

  /**
   * Get MarginInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!MarginInfoUpdateNotifyModel} MarginInfoUpdateNotify model
   */
  get MarginInfoUpdateNotifyModel () {
    return this._MarginInfoUpdateNotifyModel
  }

  /**
   * Get MarginCallNotify model
   * @this {!Sender}
   * @returns {!MarginCallNotifyModel} MarginCallNotify model
   */
  get MarginCallNotifyModel () {
    return this._MarginCallNotifyModel
  }

  /**
   * Get StopOutNotify model
   * @this {!Sender}
   * @returns {!StopOutNotifyModel} StopOutNotify model
   */
  get StopOutNotifyModel () {
    return this._StopOutNotifyModel
  }

  /**
   * Get AssetInfoRequest model
   * @this {!Sender}
   * @returns {!AssetInfoRequestModel} AssetInfoRequest model
   */
  get AssetInfoRequestModel () {
    return this._AssetInfoRequestModel
  }

  /**
   * Get AssetInfoResponse model
   * @this {!Sender}
   * @returns {!AssetInfoResponseModel} AssetInfoResponse model
   */
  get AssetInfoResponseModel () {
    return this._AssetInfoResponseModel
  }

  /**
   * Get AssetListRequest model
   * @this {!Sender}
   * @returns {!AssetListRequestModel} AssetListRequest model
   */
  get AssetListRequestModel () {
    return this._AssetListRequestModel
  }

  /**
   * Get AssetListResponse model
   * @this {!Sender}
   * @returns {!AssetListResponseModel} AssetListResponse model
   */
  get AssetListResponseModel () {
    return this._AssetListResponseModel
  }

  /**
   * Get AssetInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!AssetInfoUpdateNotifyModel} AssetInfoUpdateNotify model
   */
  get AssetInfoUpdateNotifyModel () {
    return this._AssetInfoUpdateNotifyModel
  }

  /**
   * Get OrderInfoRequest model
   * @this {!Sender}
   * @returns {!OrderInfoRequestModel} OrderInfoRequest model
   */
  get OrderInfoRequestModel () {
    return this._OrderInfoRequestModel
  }

  /**
   * Get OrderInfoResponse model
   * @this {!Sender}
   * @returns {!OrderInfoResponseModel} OrderInfoResponse model
   */
  get OrderInfoResponseModel () {
    return this._OrderInfoResponseModel
  }

  /**
   * Get OrderListRequest model
   * @this {!Sender}
   * @returns {!OrderListRequestModel} OrderListRequest model
   */
  get OrderListRequestModel () {
    return this._OrderListRequestModel
  }

  /**
   * Get OrderListResponse model
   * @this {!Sender}
   * @returns {!OrderListResponseModel} OrderListResponse model
   */
  get OrderListResponseModel () {
    return this._OrderListResponseModel
  }

  /**
   * Get OrderOpenRequest model
   * @this {!Sender}
   * @returns {!OrderOpenRequestModel} OrderOpenRequest model
   */
  get OrderOpenRequestModel () {
    return this._OrderOpenRequestModel
  }

  /**
   * Get OrderOpenResponse model
   * @this {!Sender}
   * @returns {!OrderOpenResponseModel} OrderOpenResponse model
   */
  get OrderOpenResponseModel () {
    return this._OrderOpenResponseModel
  }

  /**
   * Get OrderUpdateRequest model
   * @this {!Sender}
   * @returns {!OrderUpdateRequestModel} OrderUpdateRequest model
   */
  get OrderUpdateRequestModel () {
    return this._OrderUpdateRequestModel
  }

  /**
   * Get OrderUpdateResponse model
   * @this {!Sender}
   * @returns {!OrderUpdateResponseModel} OrderUpdateResponse model
   */
  get OrderUpdateResponseModel () {
    return this._OrderUpdateResponseModel
  }

  /**
   * Get OrderCancelRequest model
   * @this {!Sender}
   * @returns {!OrderCancelRequestModel} OrderCancelRequest model
   */
  get OrderCancelRequestModel () {
    return this._OrderCancelRequestModel
  }

  /**
   * Get OrderCancelResponse model
   * @this {!Sender}
   * @returns {!OrderCancelResponseModel} OrderCancelResponse model
   */
  get OrderCancelResponseModel () {
    return this._OrderCancelResponseModel
  }

  /**
   * Get OrderCancelAllRequest model
   * @this {!Sender}
   * @returns {!OrderCancelAllRequestModel} OrderCancelAllRequest model
   */
  get OrderCancelAllRequestModel () {
    return this._OrderCancelAllRequestModel
  }

  /**
   * Get OrderCancelAllResponse model
   * @this {!Sender}
   * @returns {!OrderCancelAllResponseModel} OrderCancelAllResponse model
   */
  get OrderCancelAllResponseModel () {
    return this._OrderCancelAllResponseModel
  }

  /**
   * Get OrderCancelAllByIdRequest model
   * @this {!Sender}
   * @returns {!OrderCancelAllByIdRequestModel} OrderCancelAllByIdRequest model
   */
  get OrderCancelAllByIdRequestModel () {
    return this._OrderCancelAllByIdRequestModel
  }

  /**
   * Get OrderCloseRequest model
   * @this {!Sender}
   * @returns {!OrderCloseRequestModel} OrderCloseRequest model
   */
  get OrderCloseRequestModel () {
    return this._OrderCloseRequestModel
  }

  /**
   * Get OrderCloseResponse model
   * @this {!Sender}
   * @returns {!OrderCloseResponseModel} OrderCloseResponse model
   */
  get OrderCloseResponseModel () {
    return this._OrderCloseResponseModel
  }

  /**
   * Get OrderCloseAllRequest model
   * @this {!Sender}
   * @returns {!OrderCloseAllRequestModel} OrderCloseAllRequest model
   */
  get OrderCloseAllRequestModel () {
    return this._OrderCloseAllRequestModel
  }

  /**
   * Get OrderCloseAllResponse model
   * @this {!Sender}
   * @returns {!OrderCloseAllResponseModel} OrderCloseAllResponse model
   */
  get OrderCloseAllResponseModel () {
    return this._OrderCloseAllResponseModel
  }

  /**
   * Get OrderCloseAllByIdRequest model
   * @this {!Sender}
   * @returns {!OrderCloseAllByIdRequestModel} OrderCloseAllByIdRequest model
   */
  get OrderCloseAllByIdRequestModel () {
    return this._OrderCloseAllByIdRequestModel
  }

  /**
   * Get OrderCloseByRequest model
   * @this {!Sender}
   * @returns {!OrderCloseByRequestModel} OrderCloseByRequest model
   */
  get OrderCloseByRequestModel () {
    return this._OrderCloseByRequestModel
  }

  /**
   * Get OrderCloseByResponse model
   * @this {!Sender}
   * @returns {!OrderCloseByResponseModel} OrderCloseByResponse model
   */
  get OrderCloseByResponseModel () {
    return this._OrderCloseByResponseModel
  }

  /**
   * Get OrderCloseByAllRequest model
   * @this {!Sender}
   * @returns {!OrderCloseByAllRequestModel} OrderCloseByAllRequest model
   */
  get OrderCloseByAllRequestModel () {
    return this._OrderCloseByAllRequestModel
  }

  /**
   * Get OrderCloseByAllResponse model
   * @this {!Sender}
   * @returns {!OrderCloseByAllResponseModel} OrderCloseByAllResponse model
   */
  get OrderCloseByAllResponseModel () {
    return this._OrderCloseByAllResponseModel
  }

  /**
   * Get OrderCancelOrCloseRequest model
   * @this {!Sender}
   * @returns {!OrderCancelOrCloseRequestModel} OrderCancelOrCloseRequest model
   */
  get OrderCancelOrCloseRequestModel () {
    return this._OrderCancelOrCloseRequestModel
  }

  /**
   * Get OrderCancelOrCloseResponse model
   * @this {!Sender}
   * @returns {!OrderCancelOrCloseResponseModel} OrderCancelOrCloseResponse model
   */
  get OrderCancelOrCloseResponseModel () {
    return this._OrderCancelOrCloseResponseModel
  }

  /**
   * Get OrderCancelOrCloseAllRequest model
   * @this {!Sender}
   * @returns {!OrderCancelOrCloseAllRequestModel} OrderCancelOrCloseAllRequest model
   */
  get OrderCancelOrCloseAllRequestModel () {
    return this._OrderCancelOrCloseAllRequestModel
  }

  /**
   * Get OrderCancelOrCloseAllResponse model
   * @this {!Sender}
   * @returns {!OrderCancelOrCloseAllResponseModel} OrderCancelOrCloseAllResponse model
   */
  get OrderCancelOrCloseAllResponseModel () {
    return this._OrderCancelOrCloseAllResponseModel
  }

  /**
   * Get OrderInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!OrderInfoUpdateNotifyModel} OrderInfoUpdateNotify model
   */
  get OrderInfoUpdateNotifyModel () {
    return this._OrderInfoUpdateNotifyModel
  }

  /**
   * Get PositionInfoRequest model
   * @this {!Sender}
   * @returns {!PositionInfoRequestModel} PositionInfoRequest model
   */
  get PositionInfoRequestModel () {
    return this._PositionInfoRequestModel
  }

  /**
   * Get PositionInfoResponse model
   * @this {!Sender}
   * @returns {!PositionInfoResponseModel} PositionInfoResponse model
   */
  get PositionInfoResponseModel () {
    return this._PositionInfoResponseModel
  }

  /**
   * Get PositionListRequest model
   * @this {!Sender}
   * @returns {!PositionListRequestModel} PositionListRequest model
   */
  get PositionListRequestModel () {
    return this._PositionListRequestModel
  }

  /**
   * Get PositionListResponse model
   * @this {!Sender}
   * @returns {!PositionListResponseModel} PositionListResponse model
   */
  get PositionListResponseModel () {
    return this._PositionListResponseModel
  }

  /**
   * Get PositionInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!PositionInfoUpdateNotifyModel} PositionInfoUpdateNotify model
   */
  get PositionInfoUpdateNotifyModel () {
    return this._PositionInfoUpdateNotifyModel
  }

  /**
   * Get TradeHistoryInfoRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryInfoRequestModel} TradeHistoryInfoRequest model
   */
  get TradeHistoryInfoRequestModel () {
    return this._TradeHistoryInfoRequestModel
  }

  /**
   * Get TradeHistoryInfoResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryInfoResponseModel} TradeHistoryInfoResponse model
   */
  get TradeHistoryInfoResponseModel () {
    return this._TradeHistoryInfoResponseModel
  }

  /**
   * Get TradeHistoryInfoFilterRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryInfoFilterRequestModel} TradeHistoryInfoFilterRequest model
   */
  get TradeHistoryInfoFilterRequestModel () {
    return this._TradeHistoryInfoFilterRequestModel
  }

  /**
   * Get TradeHistoryInfoByIdRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryInfoByIdRequestModel} TradeHistoryInfoByIdRequest model
   */
  get TradeHistoryInfoByIdRequestModel () {
    return this._TradeHistoryInfoByIdRequestModel
  }

  /**
   * Get TradeHistoryInfoByIdResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryInfoByIdResponseModel} TradeHistoryInfoByIdResponse model
   */
  get TradeHistoryInfoByIdResponseModel () {
    return this._TradeHistoryInfoByIdResponseModel
  }

  /**
   * Get TradeHistoryInfoUpdateNotify model
   * @this {!Sender}
   * @returns {!TradeHistoryInfoUpdateNotifyModel} TradeHistoryInfoUpdateNotify model
   */
  get TradeHistoryInfoUpdateNotifyModel () {
    return this._TradeHistoryInfoUpdateNotifyModel
  }

  /**
   * Get CalendarRegularRuleGetRequest model
   * @this {!Sender}
   * @returns {!CalendarRegularRuleGetRequestModel} CalendarRegularRuleGetRequest model
   */
  get CalendarRegularRuleGetRequestModel () {
    return this._CalendarRegularRuleGetRequestModel
  }

  /**
   * Get CalendarRegularRuleGetResponse model
   * @this {!Sender}
   * @returns {!CalendarRegularRuleGetResponseModel} CalendarRegularRuleGetResponse model
   */
  get CalendarRegularRuleGetResponseModel () {
    return this._CalendarRegularRuleGetResponseModel
  }

  /**
   * Get CalendarRegularRuleUpdateNotify model
   * @this {!Sender}
   * @returns {!CalendarRegularRuleUpdateNotifyModel} CalendarRegularRuleUpdateNotify model
   */
  get CalendarRegularRuleUpdateNotifyModel () {
    return this._CalendarRegularRuleUpdateNotifyModel
  }

  /**
   * Get CalendarOffTimeRuleGetRequest model
   * @this {!Sender}
   * @returns {!CalendarOffTimeRuleGetRequestModel} CalendarOffTimeRuleGetRequest model
   */
  get CalendarOffTimeRuleGetRequestModel () {
    return this._CalendarOffTimeRuleGetRequestModel
  }

  /**
   * Get CalendarOffTimeRuleGetResponse model
   * @this {!Sender}
   * @returns {!CalendarOffTimeRuleGetResponseModel} CalendarOffTimeRuleGetResponse model
   */
  get CalendarOffTimeRuleGetResponseModel () {
    return this._CalendarOffTimeRuleGetResponseModel
  }

  /**
   * Get CalendarOffTimeRuleUpdateNotify model
   * @this {!Sender}
   * @returns {!CalendarOffTimeRuleUpdateNotifyModel} CalendarOffTimeRuleUpdateNotify model
   */
  get CalendarOffTimeRuleUpdateNotifyModel () {
    return this._CalendarOffTimeRuleUpdateNotifyModel
  }

  /**
   * Get CalendarRulesGetRequest model
   * @this {!Sender}
   * @returns {!CalendarRulesGetRequestModel} CalendarRulesGetRequest model
   */
  get CalendarRulesGetRequestModel () {
    return this._CalendarRulesGetRequestModel
  }

  /**
   * Get CalendarRulesGetResponse model
   * @this {!Sender}
   * @returns {!CalendarRulesGetResponseModel} CalendarRulesGetResponse model
   */
  get CalendarRulesGetResponseModel () {
    return this._CalendarRulesGetResponseModel
  }

  /**
   * Get ChartBarHistoryRangeRequest model
   * @this {!Sender}
   * @returns {!ChartBarHistoryRangeRequestModel} ChartBarHistoryRangeRequest model
   */
  get ChartBarHistoryRangeRequestModel () {
    return this._ChartBarHistoryRangeRequestModel
  }

  /**
   * Get OrderMarginSubscribeRequest model
   * @this {!Sender}
   * @returns {!OrderMarginSubscribeRequestModel} OrderMarginSubscribeRequest model
   */
  get OrderMarginSubscribeRequestModel () {
    return this._OrderMarginSubscribeRequestModel
  }

  /**
   * Get OrderMarginSubscribeResponse model
   * @this {!Sender}
   * @returns {!OrderMarginSubscribeResponseModel} OrderMarginSubscribeResponse model
   */
  get OrderMarginSubscribeResponseModel () {
    return this._OrderMarginSubscribeResponseModel
  }

  /**
   * Get OrderMarginNotify model
   * @this {!Sender}
   * @returns {!OrderMarginNotifyModel} OrderMarginNotify model
   */
  get OrderMarginNotifyModel () {
    return this._OrderMarginNotifyModel
  }

  /**
   * Get OrderMarginUpdateSubscriptionRequest model
   * @this {!Sender}
   * @returns {!OrderMarginUpdateSubscriptionRequestModel} OrderMarginUpdateSubscriptionRequest model
   */
  get OrderMarginUpdateSubscriptionRequestModel () {
    return this._OrderMarginUpdateSubscriptionRequestModel
  }

  /**
   * Get OrderMarginUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!OrderMarginUnsubscribeRequestModel} OrderMarginUnsubscribeRequest model
   */
  get OrderMarginUnsubscribeRequestModel () {
    return this._OrderMarginUnsubscribeRequestModel
  }

  /**
   * Get OrderMarginUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!OrderMarginUnsubscribeResponseModel} OrderMarginUnsubscribeResponse model
   */
  get OrderMarginUnsubscribeResponseModel () {
    return this._OrderMarginUnsubscribeResponseModel
  }

  /**
   * Get OrderMarginGetRequest model
   * @this {!Sender}
   * @returns {!OrderMarginGetRequestModel} OrderMarginGetRequest model
   */
  get OrderMarginGetRequestModel () {
    return this._OrderMarginGetRequestModel
  }

  /**
   * Get OrderMarginGetResponse model
   * @this {!Sender}
   * @returns {!OrderMarginGetResponseModel} OrderMarginGetResponse model
   */
  get OrderMarginGetResponseModel () {
    return this._OrderMarginGetResponseModel
  }

  /**
   * Get OrderStateInfoSubscribeRequest model
   * @this {!Sender}
   * @returns {!OrderStateInfoSubscribeRequestModel} OrderStateInfoSubscribeRequest model
   */
  get OrderStateInfoSubscribeRequestModel () {
    return this._OrderStateInfoSubscribeRequestModel
  }

  /**
   * Get OrderStateInfoSubscribeResponse model
   * @this {!Sender}
   * @returns {!OrderStateInfoSubscribeResponseModel} OrderStateInfoSubscribeResponse model
   */
  get OrderStateInfoSubscribeResponseModel () {
    return this._OrderStateInfoSubscribeResponseModel
  }

  /**
   * Get OrderStateInfoUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!OrderStateInfoUnsubscribeRequestModel} OrderStateInfoUnsubscribeRequest model
   */
  get OrderStateInfoUnsubscribeRequestModel () {
    return this._OrderStateInfoUnsubscribeRequestModel
  }

  /**
   * Get OrderStateInfoUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!OrderStateInfoUnsubscribeResponseModel} OrderStateInfoUnsubscribeResponse model
   */
  get OrderStateInfoUnsubscribeResponseModel () {
    return this._OrderStateInfoUnsubscribeResponseModel
  }

  /**
   * Get OrderStateInfoNotify model
   * @this {!Sender}
   * @returns {!OrderStateInfoNotifyModel} OrderStateInfoNotify model
   */
  get OrderStateInfoNotifyModel () {
    return this._OrderStateInfoNotifyModel
  }

  /**
   * Get Deprecated003 model
   * @this {!Sender}
   * @returns {!Deprecated003Model} Deprecated003 model
   */
  get Deprecated003Model () {
    return this._Deprecated003Model
  }

  /**
   * Get Deprecated004 model
   * @this {!Sender}
   * @returns {!Deprecated004Model} Deprecated004 model
   */
  get Deprecated004Model () {
    return this._Deprecated004Model
  }

  /**
   * Get Deprecated005 model
   * @this {!Sender}
   * @returns {!Deprecated005Model} Deprecated005 model
   */
  get Deprecated005Model () {
    return this._Deprecated005Model
  }

  /**
   * Get Deprecated006 model
   * @this {!Sender}
   * @returns {!Deprecated006Model} Deprecated006 model
   */
  get Deprecated006Model () {
    return this._Deprecated006Model
  }

  /**
   * Get Deprecated007 model
   * @this {!Sender}
   * @returns {!Deprecated007Model} Deprecated007 model
   */
  get Deprecated007Model () {
    return this._Deprecated007Model
  }

  /**
   * Get Deprecated008 model
   * @this {!Sender}
   * @returns {!Deprecated008Model} Deprecated008 model
   */
  get Deprecated008Model () {
    return this._Deprecated008Model
  }

  /**
   * Get AccountUpdateRequest model
   * @this {!Sender}
   * @returns {!AccountUpdateRequestModel} AccountUpdateRequest model
   */
  get AccountUpdateRequestModel () {
    return this._AccountUpdateRequestModel
  }

  /**
   * Get AccountUpdateResponse model
   * @this {!Sender}
   * @returns {!AccountUpdateResponseModel} AccountUpdateResponse model
   */
  get AccountUpdateResponseModel () {
    return this._AccountUpdateResponseModel
  }

  /**
   * Get ProfitClosedInfoGetRequest model
   * @this {!Sender}
   * @returns {!ProfitClosedInfoGetRequestModel} ProfitClosedInfoGetRequest model
   */
  get ProfitClosedInfoGetRequestModel () {
    return this._ProfitClosedInfoGetRequestModel
  }

  /**
   * Get ProfitClosedInfoGetResponse model
   * @this {!Sender}
   * @returns {!ProfitClosedInfoGetResponseModel} ProfitClosedInfoGetResponse model
   */
  get ProfitClosedInfoGetResponseModel () {
    return this._ProfitClosedInfoGetResponseModel
  }

  /**
   * Get SymbolDayProfitLossInfoSubscribeRequest model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossInfoSubscribeRequestModel} SymbolDayProfitLossInfoSubscribeRequest model
   */
  get SymbolDayProfitLossInfoSubscribeRequestModel () {
    return this._SymbolDayProfitLossInfoSubscribeRequestModel
  }

  /**
   * Get SymbolDayProfitLossInfoSubscribeResponse model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossInfoSubscribeResponseModel} SymbolDayProfitLossInfoSubscribeResponse model
   */
  get SymbolDayProfitLossInfoSubscribeResponseModel () {
    return this._SymbolDayProfitLossInfoSubscribeResponseModel
  }

  /**
   * Get SymbolDayProfitLossInfoUpdateSubscriptionRequest model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossInfoUpdateSubscriptionRequestModel} SymbolDayProfitLossInfoUpdateSubscriptionRequest model
   */
  get SymbolDayProfitLossInfoUpdateSubscriptionRequestModel () {
    return this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel
  }

  /**
   * Get SymbolDayProfitLossInfoUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossInfoUnsubscribeRequestModel} SymbolDayProfitLossInfoUnsubscribeRequest model
   */
  get SymbolDayProfitLossInfoUnsubscribeRequestModel () {
    return this._SymbolDayProfitLossInfoUnsubscribeRequestModel
  }

  /**
   * Get SymbolDayProfitLossInfoUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossInfoUnsubscribeResponseModel} SymbolDayProfitLossInfoUnsubscribeResponse model
   */
  get SymbolDayProfitLossInfoUnsubscribeResponseModel () {
    return this._SymbolDayProfitLossInfoUnsubscribeResponseModel
  }

  /**
   * Get SymbolDayProfitLossInfoNotify model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossInfoNotifyModel} SymbolDayProfitLossInfoNotify model
   */
  get SymbolDayProfitLossInfoNotifyModel () {
    return this._SymbolDayProfitLossInfoNotifyModel
  }

  /**
   * Get LoginInfoGetRequest model
   * @this {!Sender}
   * @returns {!LoginInfoGetRequestModel} LoginInfoGetRequest model
   */
  get LoginInfoGetRequestModel () {
    return this._LoginInfoGetRequestModel
  }

  /**
   * Get LoginInfoGetResponse model
   * @this {!Sender}
   * @returns {!LoginInfoGetResponseModel} LoginInfoGetResponse model
   */
  get LoginInfoGetResponseModel () {
    return this._LoginInfoGetResponseModel
  }

  /**
   * Get DashboardInfoCreateRequest model
   * @this {!Sender}
   * @returns {!DashboardInfoCreateRequestModel} DashboardInfoCreateRequest model
   */
  get DashboardInfoCreateRequestModel () {
    return this._DashboardInfoCreateRequestModel
  }

  /**
   * Get DashboardInfoCreateResponse model
   * @this {!Sender}
   * @returns {!DashboardInfoCreateResponseModel} DashboardInfoCreateResponse model
   */
  get DashboardInfoCreateResponseModel () {
    return this._DashboardInfoCreateResponseModel
  }

  /**
   * Get DashboardInfoUpdateRequest model
   * @this {!Sender}
   * @returns {!DashboardInfoUpdateRequestModel} DashboardInfoUpdateRequest model
   */
  get DashboardInfoUpdateRequestModel () {
    return this._DashboardInfoUpdateRequestModel
  }

  /**
   * Get DashboardInfoUpdateResponse model
   * @this {!Sender}
   * @returns {!DashboardInfoUpdateResponseModel} DashboardInfoUpdateResponse model
   */
  get DashboardInfoUpdateResponseModel () {
    return this._DashboardInfoUpdateResponseModel
  }

  /**
   * Get DashboardInfoListRequest model
   * @this {!Sender}
   * @returns {!DashboardInfoListRequestModel} DashboardInfoListRequest model
   */
  get DashboardInfoListRequestModel () {
    return this._DashboardInfoListRequestModel
  }

  /**
   * Get DashboardInfoListResponse model
   * @this {!Sender}
   * @returns {!DashboardInfoListResponseModel} DashboardInfoListResponse model
   */
  get DashboardInfoListResponseModel () {
    return this._DashboardInfoListResponseModel
  }

  /**
   * Get DashboardInfoGetRequest model
   * @this {!Sender}
   * @returns {!DashboardInfoGetRequestModel} DashboardInfoGetRequest model
   */
  get DashboardInfoGetRequestModel () {
    return this._DashboardInfoGetRequestModel
  }

  /**
   * Get DashboardInfoGetResponse model
   * @this {!Sender}
   * @returns {!DashboardInfoGetResponseModel} DashboardInfoGetResponse model
   */
  get DashboardInfoGetResponseModel () {
    return this._DashboardInfoGetResponseModel
  }

  /**
   * Get DashboardInfoRenameRequest model
   * @this {!Sender}
   * @returns {!DashboardInfoRenameRequestModel} DashboardInfoRenameRequest model
   */
  get DashboardInfoRenameRequestModel () {
    return this._DashboardInfoRenameRequestModel
  }

  /**
   * Get DashboardInfoRenameResponse model
   * @this {!Sender}
   * @returns {!DashboardInfoRenameResponseModel} DashboardInfoRenameResponse model
   */
  get DashboardInfoRenameResponseModel () {
    return this._DashboardInfoRenameResponseModel
  }

  /**
   * Get DashboardInfoDeleteRequest model
   * @this {!Sender}
   * @returns {!DashboardInfoDeleteRequestModel} DashboardInfoDeleteRequest model
   */
  get DashboardInfoDeleteRequestModel () {
    return this._DashboardInfoDeleteRequestModel
  }

  /**
   * Get DashboardInfoDeleteResponse model
   * @this {!Sender}
   * @returns {!DashboardInfoDeleteResponseModel} DashboardInfoDeleteResponse model
   */
  get DashboardInfoDeleteResponseModel () {
    return this._DashboardInfoDeleteResponseModel
  }

  /**
   * Get ChartInfoCreateRequest model
   * @this {!Sender}
   * @returns {!ChartInfoCreateRequestModel} ChartInfoCreateRequest model
   */
  get ChartInfoCreateRequestModel () {
    return this._ChartInfoCreateRequestModel
  }

  /**
   * Get ChartInfoCreateResponse model
   * @this {!Sender}
   * @returns {!ChartInfoCreateResponseModel} ChartInfoCreateResponse model
   */
  get ChartInfoCreateResponseModel () {
    return this._ChartInfoCreateResponseModel
  }

  /**
   * Get ChartInfoUpdateRequest model
   * @this {!Sender}
   * @returns {!ChartInfoUpdateRequestModel} ChartInfoUpdateRequest model
   */
  get ChartInfoUpdateRequestModel () {
    return this._ChartInfoUpdateRequestModel
  }

  /**
   * Get ChartInfoUpdateResponse model
   * @this {!Sender}
   * @returns {!ChartInfoUpdateResponseModel} ChartInfoUpdateResponse model
   */
  get ChartInfoUpdateResponseModel () {
    return this._ChartInfoUpdateResponseModel
  }

  /**
   * Get ChartInfoListRequest model
   * @this {!Sender}
   * @returns {!ChartInfoListRequestModel} ChartInfoListRequest model
   */
  get ChartInfoListRequestModel () {
    return this._ChartInfoListRequestModel
  }

  /**
   * Get ChartInfoListResponse model
   * @this {!Sender}
   * @returns {!ChartInfoListResponseModel} ChartInfoListResponse model
   */
  get ChartInfoListResponseModel () {
    return this._ChartInfoListResponseModel
  }

  /**
   * Get ChartInfoDeleteRequest model
   * @this {!Sender}
   * @returns {!ChartInfoDeleteRequestModel} ChartInfoDeleteRequest model
   */
  get ChartInfoDeleteRequestModel () {
    return this._ChartInfoDeleteRequestModel
  }

  /**
   * Get ChartInfoDeleteResponse model
   * @this {!Sender}
   * @returns {!ChartInfoDeleteResponseModel} ChartInfoDeleteResponse model
   */
  get ChartInfoDeleteResponseModel () {
    return this._ChartInfoDeleteResponseModel
  }

  /**
   * Get ChartInfoGetRequest model
   * @this {!Sender}
   * @returns {!ChartInfoGetRequestModel} ChartInfoGetRequest model
   */
  get ChartInfoGetRequestModel () {
    return this._ChartInfoGetRequestModel
  }

  /**
   * Get ChartInfoGetResponse model
   * @this {!Sender}
   * @returns {!ChartInfoGetResponseModel} ChartInfoGetResponse model
   */
  get ChartInfoGetResponseModel () {
    return this._ChartInfoGetResponseModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoSubscribeRequest model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossMultipleInfoSubscribeRequestModel} SymbolDayProfitLossMultipleInfoSubscribeRequest model
   */
  get SymbolDayProfitLossMultipleInfoSubscribeRequestModel () {
    return this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoSubscribeResponse model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossMultipleInfoSubscribeResponseModel} SymbolDayProfitLossMultipleInfoSubscribeResponse model
   */
  get SymbolDayProfitLossMultipleInfoSubscribeResponseModel () {
    return this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel} SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest model
   */
  get SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel () {
    return this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel} SymbolDayProfitLossMultipleInfoUnsubscribeRequest model
   */
  get SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel () {
    return this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel} SymbolDayProfitLossMultipleInfoUnsubscribeResponse model
   */
  get SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel () {
    return this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoNotify model
   * @this {!Sender}
   * @returns {!SymbolDayProfitLossMultipleInfoNotifyModel} SymbolDayProfitLossMultipleInfoNotify model
   */
  get SymbolDayProfitLossMultipleInfoNotifyModel () {
    return this._SymbolDayProfitLossMultipleInfoNotifyModel
  }

  /**
   * Get NewsServiceGetAllRequest model
   * @this {!Sender}
   * @returns {!NewsServiceGetAllRequestModel} NewsServiceGetAllRequest model
   */
  get NewsServiceGetAllRequestModel () {
    return this._NewsServiceGetAllRequestModel
  }

  /**
   * Get NewsServiceGetAllResponse model
   * @this {!Sender}
   * @returns {!NewsServiceGetAllResponseModel} NewsServiceGetAllResponse model
   */
  get NewsServiceGetAllResponseModel () {
    return this._NewsServiceGetAllResponseModel
  }

  /**
   * Get NewsRecentArticlesGetRequest model
   * @this {!Sender}
   * @returns {!NewsRecentArticlesGetRequestModel} NewsRecentArticlesGetRequest model
   */
  get NewsRecentArticlesGetRequestModel () {
    return this._NewsRecentArticlesGetRequestModel
  }

  /**
   * Get NewsRecentArticlesGetResponse model
   * @this {!Sender}
   * @returns {!NewsRecentArticlesGetResponseModel} NewsRecentArticlesGetResponse model
   */
  get NewsRecentArticlesGetResponseModel () {
    return this._NewsRecentArticlesGetResponseModel
  }

  /**
   * Get NewsArticleGetRequest model
   * @this {!Sender}
   * @returns {!NewsArticleGetRequestModel} NewsArticleGetRequest model
   */
  get NewsArticleGetRequestModel () {
    return this._NewsArticleGetRequestModel
  }

  /**
   * Get NewsArticleGetResponse model
   * @this {!Sender}
   * @returns {!NewsArticleGetResponseModel} NewsArticleGetResponse model
   */
  get NewsArticleGetResponseModel () {
    return this._NewsArticleGetResponseModel
  }

  /**
   * Get OrderUpdateWithTypeRequest model
   * @this {!Sender}
   * @returns {!OrderUpdateWithTypeRequestModel} OrderUpdateWithTypeRequest model
   */
  get OrderUpdateWithTypeRequestModel () {
    return this._OrderUpdateWithTypeRequestModel
  }

  /**
   * Get OrderUpdateWithTypeResponse model
   * @this {!Sender}
   * @returns {!OrderUpdateWithTypeResponseModel} OrderUpdateWithTypeResponse model
   */
  get OrderUpdateWithTypeResponseModel () {
    return this._OrderUpdateWithTypeResponseModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoSubscribeRequest model
   * @this {!Sender}
   * @returns {!SymbolProfitLossMultipleInfoSubscribeRequestModel} SymbolProfitLossMultipleInfoSubscribeRequest model
   */
  get SymbolProfitLossMultipleInfoSubscribeRequestModel () {
    return this._SymbolProfitLossMultipleInfoSubscribeRequestModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoSubscribeResponse model
   * @this {!Sender}
   * @returns {!SymbolProfitLossMultipleInfoSubscribeResponseModel} SymbolProfitLossMultipleInfoSubscribeResponse model
   */
  get SymbolProfitLossMultipleInfoSubscribeResponseModel () {
    return this._SymbolProfitLossMultipleInfoSubscribeResponseModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoUpdateSubscriptionRequest model
   * @this {!Sender}
   * @returns {!SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel} SymbolProfitLossMultipleInfoUpdateSubscriptionRequest model
   */
  get SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel () {
    return this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!SymbolProfitLossMultipleInfoUnsubscribeRequestModel} SymbolProfitLossMultipleInfoUnsubscribeRequest model
   */
  get SymbolProfitLossMultipleInfoUnsubscribeRequestModel () {
    return this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!SymbolProfitLossMultipleInfoUnsubscribeResponseModel} SymbolProfitLossMultipleInfoUnsubscribeResponse model
   */
  get SymbolProfitLossMultipleInfoUnsubscribeResponseModel () {
    return this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoNotify model
   * @this {!Sender}
   * @returns {!SymbolProfitLossMultipleInfoNotifyModel} SymbolProfitLossMultipleInfoNotify model
   */
  get SymbolProfitLossMultipleInfoNotifyModel () {
    return this._SymbolProfitLossMultipleInfoNotifyModel
  }

  /**
   * Get LoginWithPasswordRequest model
   * @this {!Sender}
   * @returns {!LoginWithPasswordRequestModel} LoginWithPasswordRequest model
   */
  get LoginWithPasswordRequestModel () {
    return this._LoginWithPasswordRequestModel
  }

  /**
   * Get LoginWithPasswordResponse model
   * @this {!Sender}
   * @returns {!LoginWithPasswordResponseModel} LoginWithPasswordResponse model
   */
  get LoginWithPasswordResponseModel () {
    return this._LoginWithPasswordResponseModel
  }

  /**
   * Get LoginWithTokenRequest model
   * @this {!Sender}
   * @returns {!LoginWithTokenRequestModel} LoginWithTokenRequest model
   */
  get LoginWithTokenRequestModel () {
    return this._LoginWithTokenRequestModel
  }

  /**
   * Get LoginWithTokenResponse model
   * @this {!Sender}
   * @returns {!LoginWithTokenResponseModel} LoginWithTokenResponse model
   */
  get LoginWithTokenResponseModel () {
    return this._LoginWithTokenResponseModel
  }

  /**
   * Get RefreshTokenRequest model
   * @this {!Sender}
   * @returns {!RefreshTokenRequestModel} RefreshTokenRequest model
   */
  get RefreshTokenRequestModel () {
    return this._RefreshTokenRequestModel
  }

  /**
   * Get RefreshTokenResponse model
   * @this {!Sender}
   * @returns {!RefreshTokenResponseModel} RefreshTokenResponse model
   */
  get RefreshTokenResponseModel () {
    return this._RefreshTokenResponseModel
  }

  /**
   * Get Logout2Request model
   * @this {!Sender}
   * @returns {!Logout2RequestModel} Logout2Request model
   */
  get Logout2RequestModel () {
    return this._Logout2RequestModel
  }

  /**
   * Get Logout2Response model
   * @this {!Sender}
   * @returns {!Logout2ResponseModel} Logout2Response model
   */
  get Logout2ResponseModel () {
    return this._Logout2ResponseModel
  }

  /**
   * Get AccountChangePasswordRequest model
   * @this {!Sender}
   * @returns {!AccountChangePasswordRequestModel} AccountChangePasswordRequest model
   */
  get AccountChangePasswordRequestModel () {
    return this._AccountChangePasswordRequestModel
  }

  /**
   * Get AccountChangePasswordResponse model
   * @this {!Sender}
   * @returns {!AccountChangePasswordResponseModel} AccountChangePasswordResponse model
   */
  get AccountChangePasswordResponseModel () {
    return this._AccountChangePasswordResponseModel
  }

  /**
   * Get PushNotificationTokenRegisterRequest model
   * @this {!Sender}
   * @returns {!PushNotificationTokenRegisterRequestModel} PushNotificationTokenRegisterRequest model
   */
  get PushNotificationTokenRegisterRequestModel () {
    return this._PushNotificationTokenRegisterRequestModel
  }

  /**
   * Get PushNotificationTokenRegisterResponse model
   * @this {!Sender}
   * @returns {!PushNotificationTokenRegisterResponseModel} PushNotificationTokenRegisterResponse model
   */
  get PushNotificationTokenRegisterResponseModel () {
    return this._PushNotificationTokenRegisterResponseModel
  }

  /**
   * Get DesiredAppSettingsGetRequest model
   * @this {!Sender}
   * @returns {!DesiredAppSettingsGetRequestModel} DesiredAppSettingsGetRequest model
   */
  get DesiredAppSettingsGetRequestModel () {
    return this._DesiredAppSettingsGetRequestModel
  }

  /**
   * Get DesiredAppSettingsGetResponse model
   * @this {!Sender}
   * @returns {!DesiredAppSettingsGetResponseModel} DesiredAppSettingsGetResponse model
   */
  get DesiredAppSettingsGetResponseModel () {
    return this._DesiredAppSettingsGetResponseModel
  }

  /**
   * Get AuthTokensGetAllRequest model
   * @this {!Sender}
   * @returns {!AuthTokensGetAllRequestModel} AuthTokensGetAllRequest model
   */
  get AuthTokensGetAllRequestModel () {
    return this._AuthTokensGetAllRequestModel
  }

  /**
   * Get AuthTokensGetAllResponse model
   * @this {!Sender}
   * @returns {!AuthTokensGetAllResponseModel} AuthTokensGetAllResponse model
   */
  get AuthTokensGetAllResponseModel () {
    return this._AuthTokensGetAllResponseModel
  }

  /**
   * Get AuthTokensDeleteRequest model
   * @this {!Sender}
   * @returns {!AuthTokensDeleteRequestModel} AuthTokensDeleteRequest model
   */
  get AuthTokensDeleteRequestModel () {
    return this._AuthTokensDeleteRequestModel
  }

  /**
   * Get AuthTokensDeleteResponse model
   * @this {!Sender}
   * @returns {!AuthTokensDeleteResponseModel} AuthTokensDeleteResponse model
   */
  get AuthTokensDeleteResponseModel () {
    return this._AuthTokensDeleteResponseModel
  }

  /**
   * Get LegalDocumentGroupListRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupListRequestModel} LegalDocumentGroupListRequest model
   */
  get LegalDocumentGroupListRequestModel () {
    return this._LegalDocumentGroupListRequestModel
  }

  /**
   * Get LegalDocumentGroupListResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentGroupListResponseModel} LegalDocumentGroupListResponse model
   */
  get LegalDocumentGroupListResponseModel () {
    return this._LegalDocumentGroupListResponseModel
  }

  /**
   * Get LegalDocumentGetRequest model
   * @this {!Sender}
   * @returns {!LegalDocumentGetRequestModel} LegalDocumentGetRequest model
   */
  get LegalDocumentGetRequestModel () {
    return this._LegalDocumentGetRequestModel
  }

  /**
   * Get LegalDocumentGetResponse model
   * @this {!Sender}
   * @returns {!LegalDocumentGetResponseModel} LegalDocumentGetResponse model
   */
  get LegalDocumentGetResponseModel () {
    return this._LegalDocumentGetResponseModel
  }

  /**
   * Get PushNotificationTokenUnRegisterRequest model
   * @this {!Sender}
   * @returns {!PushNotificationTokenUnRegisterRequestModel} PushNotificationTokenUnRegisterRequest model
   */
  get PushNotificationTokenUnRegisterRequestModel () {
    return this._PushNotificationTokenUnRegisterRequestModel
  }

  /**
   * Get PushNotificationTokenUnRegisterResponse model
   * @this {!Sender}
   * @returns {!PushNotificationTokenUnRegisterResponseModel} PushNotificationTokenUnRegisterResponse model
   */
  get PushNotificationTokenUnRegisterResponseModel () {
    return this._PushNotificationTokenUnRegisterResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof PingRequest) && (value.fbeType === this.PingRequestModel.fbeType)) {
      return this.send_PingRequest(value)
    }
    if ((value instanceof PongResponse) && (value.fbeType === this.PongResponseModel.fbeType)) {
      return this.send_PongResponse(value)
    }
    if ((value instanceof LoginRequest) && (value.fbeType === this.LoginRequestModel.fbeType)) {
      return this.send_LoginRequest(value)
    }
    if ((value instanceof LoginResponse) && (value.fbeType === this.LoginResponseModel.fbeType)) {
      return this.send_LoginResponse(value)
    }
    if ((value instanceof LoginReject) && (value.fbeType === this.LoginRejectModel.fbeType)) {
      return this.send_LoginReject(value)
    }
    if ((value instanceof LogoutRequest) && (value.fbeType === this.LogoutRequestModel.fbeType)) {
      return this.send_LogoutRequest(value)
    }
    if ((value instanceof LogoutResponse) && (value.fbeType === this.LogoutResponseModel.fbeType)) {
      return this.send_LogoutResponse(value)
    }
    if ((value instanceof LogoutNotify) && (value.fbeType === this.LogoutNotifyModel.fbeType)) {
      return this.send_LogoutNotify(value)
    }
    if ((value instanceof SessionInfoRequest) && (value.fbeType === this.SessionInfoRequestModel.fbeType)) {
      return this.send_SessionInfoRequest(value)
    }
    if ((value instanceof SessionInfoResponse) && (value.fbeType === this.SessionInfoResponseModel.fbeType)) {
      return this.send_SessionInfoResponse(value)
    }
    if ((value instanceof TimeInfoRequest) && (value.fbeType === this.TimeInfoRequestModel.fbeType)) {
      return this.send_TimeInfoRequest(value)
    }
    if ((value instanceof TimeInfoResponse) && (value.fbeType === this.TimeInfoResponseModel.fbeType)) {
      return this.send_TimeInfoResponse(value)
    }
    if ((value instanceof TimezoneInfoRequest) && (value.fbeType === this.TimezoneInfoRequestModel.fbeType)) {
      return this.send_TimezoneInfoRequest(value)
    }
    if ((value instanceof TimezoneInfoResponse) && (value.fbeType === this.TimezoneInfoResponseModel.fbeType)) {
      return this.send_TimezoneInfoResponse(value)
    }
    if ((value instanceof TimezoneInfoUpdateNotify) && (value.fbeType === this.TimezoneInfoUpdateNotifyModel.fbeType)) {
      return this.send_TimezoneInfoUpdateNotify(value)
    }
    if ((value instanceof CurrencyInfoRequest) && (value.fbeType === this.CurrencyInfoRequestModel.fbeType)) {
      return this.send_CurrencyInfoRequest(value)
    }
    if ((value instanceof CurrencyInfoResponse) && (value.fbeType === this.CurrencyInfoResponseModel.fbeType)) {
      return this.send_CurrencyInfoResponse(value)
    }
    if ((value instanceof CurrencyListRequest) && (value.fbeType === this.CurrencyListRequestModel.fbeType)) {
      return this.send_CurrencyListRequest(value)
    }
    if ((value instanceof CurrencyListResponse) && (value.fbeType === this.CurrencyListResponseModel.fbeType)) {
      return this.send_CurrencyListResponse(value)
    }
    if ((value instanceof CurrencyInfoUpdateNotify) && (value.fbeType === this.CurrencyInfoUpdateNotifyModel.fbeType)) {
      return this.send_CurrencyInfoUpdateNotify(value)
    }
    if ((value instanceof SymbolInfoRequest) && (value.fbeType === this.SymbolInfoRequestModel.fbeType)) {
      return this.send_SymbolInfoRequest(value)
    }
    if ((value instanceof SymbolInfoResponse) && (value.fbeType === this.SymbolInfoResponseModel.fbeType)) {
      return this.send_SymbolInfoResponse(value)
    }
    if ((value instanceof SymbolListRequest) && (value.fbeType === this.SymbolListRequestModel.fbeType)) {
      return this.send_SymbolListRequest(value)
    }
    if ((value instanceof SymbolListResponse) && (value.fbeType === this.SymbolListResponseModel.fbeType)) {
      return this.send_SymbolListResponse(value)
    }
    if ((value instanceof SymbolInfoUpdateNotify) && (value.fbeType === this.SymbolInfoUpdateNotifyModel.fbeType)) {
      return this.send_SymbolInfoUpdateNotify(value)
    }
    if ((value instanceof TickInfoRequest) && (value.fbeType === this.TickInfoRequestModel.fbeType)) {
      return this.send_TickInfoRequest(value)
    }
    if ((value instanceof TickInfoResponse) && (value.fbeType === this.TickInfoResponseModel.fbeType)) {
      return this.send_TickInfoResponse(value)
    }
    if ((value instanceof TickFilterRequest) && (value.fbeType === this.TickFilterRequestModel.fbeType)) {
      return this.send_TickFilterRequest(value)
    }
    if ((value instanceof TickFilterResponse) && (value.fbeType === this.TickFilterResponseModel.fbeType)) {
      return this.send_TickFilterResponse(value)
    }
    if ((value instanceof TickListRequest) && (value.fbeType === this.TickListRequestModel.fbeType)) {
      return this.send_TickListRequest(value)
    }
    if ((value instanceof TickListResponse) && (value.fbeType === this.TickListResponseModel.fbeType)) {
      return this.send_TickListResponse(value)
    }
    if ((value instanceof Level2InfoRequest) && (value.fbeType === this.Level2InfoRequestModel.fbeType)) {
      return this.send_Level2InfoRequest(value)
    }
    if ((value instanceof Level2InfoResponse) && (value.fbeType === this.Level2InfoResponseModel.fbeType)) {
      return this.send_Level2InfoResponse(value)
    }
    if ((value instanceof Level2FilterRequest) && (value.fbeType === this.Level2FilterRequestModel.fbeType)) {
      return this.send_Level2FilterRequest(value)
    }
    if ((value instanceof Level2FilterResponse) && (value.fbeType === this.Level2FilterResponseModel.fbeType)) {
      return this.send_Level2FilterResponse(value)
    }
    if ((value instanceof Level2ListRequest) && (value.fbeType === this.Level2ListRequestModel.fbeType)) {
      return this.send_Level2ListRequest(value)
    }
    if ((value instanceof Level2ListResponse) && (value.fbeType === this.Level2ListResponseModel.fbeType)) {
      return this.send_Level2ListResponse(value)
    }
    if ((value instanceof FeedSubscriptionInfoRequest) && (value.fbeType === this.FeedSubscriptionInfoRequestModel.fbeType)) {
      return this.send_FeedSubscriptionInfoRequest(value)
    }
    if ((value instanceof FeedSubscriptionInfoResponse) && (value.fbeType === this.FeedSubscriptionInfoResponseModel.fbeType)) {
      return this.send_FeedSubscriptionInfoResponse(value)
    }
    if ((value instanceof FeedSubscribeRequest) && (value.fbeType === this.FeedSubscribeRequestModel.fbeType)) {
      return this.send_FeedSubscribeRequest(value)
    }
    if ((value instanceof FeedSubscribeAllRequest) && (value.fbeType === this.FeedSubscribeAllRequestModel.fbeType)) {
      return this.send_FeedSubscribeAllRequest(value)
    }
    if ((value instanceof FeedSubscribeResponse) && (value.fbeType === this.FeedSubscribeResponseModel.fbeType)) {
      return this.send_FeedSubscribeResponse(value)
    }
    if ((value instanceof FeedUnsubscribeRequest) && (value.fbeType === this.FeedUnsubscribeRequestModel.fbeType)) {
      return this.send_FeedUnsubscribeRequest(value)
    }
    if ((value instanceof FeedUnsubscribeAllRequest) && (value.fbeType === this.FeedUnsubscribeAllRequestModel.fbeType)) {
      return this.send_FeedUnsubscribeAllRequest(value)
    }
    if ((value instanceof FeedUnsubscribeResponse) && (value.fbeType === this.FeedUnsubscribeResponseModel.fbeType)) {
      return this.send_FeedUnsubscribeResponse(value)
    }
    if ((value instanceof TickSnapshotNotify) && (value.fbeType === this.TickSnapshotNotifyModel.fbeType)) {
      return this.send_TickSnapshotNotify(value)
    }
    if ((value instanceof TickUpdateNotify) && (value.fbeType === this.TickUpdateNotifyModel.fbeType)) {
      return this.send_TickUpdateNotify(value)
    }
    if ((value instanceof Level2SnapshotNotify) && (value.fbeType === this.Level2SnapshotNotifyModel.fbeType)) {
      return this.send_Level2SnapshotNotify(value)
    }
    if ((value instanceof Level2UpdateNotify) && (value.fbeType === this.Level2UpdateNotifyModel.fbeType)) {
      return this.send_Level2UpdateNotify(value)
    }
    if ((value instanceof ChartBarHistoryRequest) && (value.fbeType === this.ChartBarHistoryRequestModel.fbeType)) {
      return this.send_ChartBarHistoryRequest(value)
    }
    if ((value instanceof ChartBarHistoryResponse) && (value.fbeType === this.ChartBarHistoryResponseModel.fbeType)) {
      return this.send_ChartBarHistoryResponse(value)
    }
    if ((value instanceof ChartBarSubscribeRequest) && (value.fbeType === this.ChartBarSubscribeRequestModel.fbeType)) {
      return this.send_ChartBarSubscribeRequest(value)
    }
    if ((value instanceof ChartBarSubscribeResponse) && (value.fbeType === this.ChartBarSubscribeResponseModel.fbeType)) {
      return this.send_ChartBarSubscribeResponse(value)
    }
    if ((value instanceof ChartBarUnsubscribeRequest) && (value.fbeType === this.ChartBarUnsubscribeRequestModel.fbeType)) {
      return this.send_ChartBarUnsubscribeRequest(value)
    }
    if ((value instanceof ChartBarUnsubscribeResponse) && (value.fbeType === this.ChartBarUnsubscribeResponseModel.fbeType)) {
      return this.send_ChartBarUnsubscribeResponse(value)
    }
    if ((value instanceof ChartBarNotify) && (value.fbeType === this.ChartBarNotifyModel.fbeType)) {
      return this.send_ChartBarNotify(value)
    }
    if ((value instanceof ChartBarUpdateNotify) && (value.fbeType === this.ChartBarUpdateNotifyModel.fbeType)) {
      return this.send_ChartBarUpdateNotify(value)
    }
    if ((value instanceof PlatformInfoRequest) && (value.fbeType === this.PlatformInfoRequestModel.fbeType)) {
      return this.send_PlatformInfoRequest(value)
    }
    if ((value instanceof PlatformInfoResponse) && (value.fbeType === this.PlatformInfoResponseModel.fbeType)) {
      return this.send_PlatformInfoResponse(value)
    }
    if ((value instanceof PlatformInfoUpdateNotify) && (value.fbeType === this.PlatformInfoUpdateNotifyModel.fbeType)) {
      return this.send_PlatformInfoUpdateNotify(value)
    }
    if ((value instanceof AccountInfoRequest) && (value.fbeType === this.AccountInfoRequestModel.fbeType)) {
      return this.send_AccountInfoRequest(value)
    }
    if ((value instanceof AccountInfoResponse) && (value.fbeType === this.AccountInfoResponseModel.fbeType)) {
      return this.send_AccountInfoResponse(value)
    }
    if ((value instanceof AccountInfoUpdateNotify) && (value.fbeType === this.AccountInfoUpdateNotifyModel.fbeType)) {
      return this.send_AccountInfoUpdateNotify(value)
    }
    if ((value instanceof MarginInfoRequest) && (value.fbeType === this.MarginInfoRequestModel.fbeType)) {
      return this.send_MarginInfoRequest(value)
    }
    if ((value instanceof MarginInfoResponse) && (value.fbeType === this.MarginInfoResponseModel.fbeType)) {
      return this.send_MarginInfoResponse(value)
    }
    if ((value instanceof MarginInfoUpdateNotify) && (value.fbeType === this.MarginInfoUpdateNotifyModel.fbeType)) {
      return this.send_MarginInfoUpdateNotify(value)
    }
    if ((value instanceof MarginCallNotify) && (value.fbeType === this.MarginCallNotifyModel.fbeType)) {
      return this.send_MarginCallNotify(value)
    }
    if ((value instanceof StopOutNotify) && (value.fbeType === this.StopOutNotifyModel.fbeType)) {
      return this.send_StopOutNotify(value)
    }
    if ((value instanceof AssetInfoRequest) && (value.fbeType === this.AssetInfoRequestModel.fbeType)) {
      return this.send_AssetInfoRequest(value)
    }
    if ((value instanceof AssetInfoResponse) && (value.fbeType === this.AssetInfoResponseModel.fbeType)) {
      return this.send_AssetInfoResponse(value)
    }
    if ((value instanceof AssetListRequest) && (value.fbeType === this.AssetListRequestModel.fbeType)) {
      return this.send_AssetListRequest(value)
    }
    if ((value instanceof AssetListResponse) && (value.fbeType === this.AssetListResponseModel.fbeType)) {
      return this.send_AssetListResponse(value)
    }
    if ((value instanceof AssetInfoUpdateNotify) && (value.fbeType === this.AssetInfoUpdateNotifyModel.fbeType)) {
      return this.send_AssetInfoUpdateNotify(value)
    }
    if ((value instanceof OrderInfoRequest) && (value.fbeType === this.OrderInfoRequestModel.fbeType)) {
      return this.send_OrderInfoRequest(value)
    }
    if ((value instanceof OrderInfoResponse) && (value.fbeType === this.OrderInfoResponseModel.fbeType)) {
      return this.send_OrderInfoResponse(value)
    }
    if ((value instanceof OrderListRequest) && (value.fbeType === this.OrderListRequestModel.fbeType)) {
      return this.send_OrderListRequest(value)
    }
    if ((value instanceof OrderListResponse) && (value.fbeType === this.OrderListResponseModel.fbeType)) {
      return this.send_OrderListResponse(value)
    }
    if ((value instanceof OrderOpenRequest) && (value.fbeType === this.OrderOpenRequestModel.fbeType)) {
      return this.send_OrderOpenRequest(value)
    }
    if ((value instanceof OrderOpenResponse) && (value.fbeType === this.OrderOpenResponseModel.fbeType)) {
      return this.send_OrderOpenResponse(value)
    }
    if ((value instanceof OrderUpdateRequest) && (value.fbeType === this.OrderUpdateRequestModel.fbeType)) {
      return this.send_OrderUpdateRequest(value)
    }
    if ((value instanceof OrderUpdateResponse) && (value.fbeType === this.OrderUpdateResponseModel.fbeType)) {
      return this.send_OrderUpdateResponse(value)
    }
    if ((value instanceof OrderCancelRequest) && (value.fbeType === this.OrderCancelRequestModel.fbeType)) {
      return this.send_OrderCancelRequest(value)
    }
    if ((value instanceof OrderCancelResponse) && (value.fbeType === this.OrderCancelResponseModel.fbeType)) {
      return this.send_OrderCancelResponse(value)
    }
    if ((value instanceof OrderCancelAllRequest) && (value.fbeType === this.OrderCancelAllRequestModel.fbeType)) {
      return this.send_OrderCancelAllRequest(value)
    }
    if ((value instanceof OrderCancelAllResponse) && (value.fbeType === this.OrderCancelAllResponseModel.fbeType)) {
      return this.send_OrderCancelAllResponse(value)
    }
    if ((value instanceof OrderCancelAllByIdRequest) && (value.fbeType === this.OrderCancelAllByIdRequestModel.fbeType)) {
      return this.send_OrderCancelAllByIdRequest(value)
    }
    if ((value instanceof OrderCloseRequest) && (value.fbeType === this.OrderCloseRequestModel.fbeType)) {
      return this.send_OrderCloseRequest(value)
    }
    if ((value instanceof OrderCloseResponse) && (value.fbeType === this.OrderCloseResponseModel.fbeType)) {
      return this.send_OrderCloseResponse(value)
    }
    if ((value instanceof OrderCloseAllRequest) && (value.fbeType === this.OrderCloseAllRequestModel.fbeType)) {
      return this.send_OrderCloseAllRequest(value)
    }
    if ((value instanceof OrderCloseAllResponse) && (value.fbeType === this.OrderCloseAllResponseModel.fbeType)) {
      return this.send_OrderCloseAllResponse(value)
    }
    if ((value instanceof OrderCloseAllByIdRequest) && (value.fbeType === this.OrderCloseAllByIdRequestModel.fbeType)) {
      return this.send_OrderCloseAllByIdRequest(value)
    }
    if ((value instanceof OrderCloseByRequest) && (value.fbeType === this.OrderCloseByRequestModel.fbeType)) {
      return this.send_OrderCloseByRequest(value)
    }
    if ((value instanceof OrderCloseByResponse) && (value.fbeType === this.OrderCloseByResponseModel.fbeType)) {
      return this.send_OrderCloseByResponse(value)
    }
    if ((value instanceof OrderCloseByAllRequest) && (value.fbeType === this.OrderCloseByAllRequestModel.fbeType)) {
      return this.send_OrderCloseByAllRequest(value)
    }
    if ((value instanceof OrderCloseByAllResponse) && (value.fbeType === this.OrderCloseByAllResponseModel.fbeType)) {
      return this.send_OrderCloseByAllResponse(value)
    }
    if ((value instanceof OrderCancelOrCloseRequest) && (value.fbeType === this.OrderCancelOrCloseRequestModel.fbeType)) {
      return this.send_OrderCancelOrCloseRequest(value)
    }
    if ((value instanceof OrderCancelOrCloseResponse) && (value.fbeType === this.OrderCancelOrCloseResponseModel.fbeType)) {
      return this.send_OrderCancelOrCloseResponse(value)
    }
    if ((value instanceof OrderCancelOrCloseAllRequest) && (value.fbeType === this.OrderCancelOrCloseAllRequestModel.fbeType)) {
      return this.send_OrderCancelOrCloseAllRequest(value)
    }
    if ((value instanceof OrderCancelOrCloseAllResponse) && (value.fbeType === this.OrderCancelOrCloseAllResponseModel.fbeType)) {
      return this.send_OrderCancelOrCloseAllResponse(value)
    }
    if ((value instanceof OrderInfoUpdateNotify) && (value.fbeType === this.OrderInfoUpdateNotifyModel.fbeType)) {
      return this.send_OrderInfoUpdateNotify(value)
    }
    if ((value instanceof PositionInfoRequest) && (value.fbeType === this.PositionInfoRequestModel.fbeType)) {
      return this.send_PositionInfoRequest(value)
    }
    if ((value instanceof PositionInfoResponse) && (value.fbeType === this.PositionInfoResponseModel.fbeType)) {
      return this.send_PositionInfoResponse(value)
    }
    if ((value instanceof PositionListRequest) && (value.fbeType === this.PositionListRequestModel.fbeType)) {
      return this.send_PositionListRequest(value)
    }
    if ((value instanceof PositionListResponse) && (value.fbeType === this.PositionListResponseModel.fbeType)) {
      return this.send_PositionListResponse(value)
    }
    if ((value instanceof PositionInfoUpdateNotify) && (value.fbeType === this.PositionInfoUpdateNotifyModel.fbeType)) {
      return this.send_PositionInfoUpdateNotify(value)
    }
    if ((value instanceof TradeHistoryInfoRequest) && (value.fbeType === this.TradeHistoryInfoRequestModel.fbeType)) {
      return this.send_TradeHistoryInfoRequest(value)
    }
    if ((value instanceof TradeHistoryInfoResponse) && (value.fbeType === this.TradeHistoryInfoResponseModel.fbeType)) {
      return this.send_TradeHistoryInfoResponse(value)
    }
    if ((value instanceof TradeHistoryInfoFilterRequest) && (value.fbeType === this.TradeHistoryInfoFilterRequestModel.fbeType)) {
      return this.send_TradeHistoryInfoFilterRequest(value)
    }
    if ((value instanceof TradeHistoryInfoByIdRequest) && (value.fbeType === this.TradeHistoryInfoByIdRequestModel.fbeType)) {
      return this.send_TradeHistoryInfoByIdRequest(value)
    }
    if ((value instanceof TradeHistoryInfoByIdResponse) && (value.fbeType === this.TradeHistoryInfoByIdResponseModel.fbeType)) {
      return this.send_TradeHistoryInfoByIdResponse(value)
    }
    if ((value instanceof TradeHistoryInfoUpdateNotify) && (value.fbeType === this.TradeHistoryInfoUpdateNotifyModel.fbeType)) {
      return this.send_TradeHistoryInfoUpdateNotify(value)
    }
    if ((value instanceof CalendarRegularRuleGetRequest) && (value.fbeType === this.CalendarRegularRuleGetRequestModel.fbeType)) {
      return this.send_CalendarRegularRuleGetRequest(value)
    }
    if ((value instanceof CalendarRegularRuleGetResponse) && (value.fbeType === this.CalendarRegularRuleGetResponseModel.fbeType)) {
      return this.send_CalendarRegularRuleGetResponse(value)
    }
    if ((value instanceof CalendarRegularRuleUpdateNotify) && (value.fbeType === this.CalendarRegularRuleUpdateNotifyModel.fbeType)) {
      return this.send_CalendarRegularRuleUpdateNotify(value)
    }
    if ((value instanceof CalendarOffTimeRuleGetRequest) && (value.fbeType === this.CalendarOffTimeRuleGetRequestModel.fbeType)) {
      return this.send_CalendarOffTimeRuleGetRequest(value)
    }
    if ((value instanceof CalendarOffTimeRuleGetResponse) && (value.fbeType === this.CalendarOffTimeRuleGetResponseModel.fbeType)) {
      return this.send_CalendarOffTimeRuleGetResponse(value)
    }
    if ((value instanceof CalendarOffTimeRuleUpdateNotify) && (value.fbeType === this.CalendarOffTimeRuleUpdateNotifyModel.fbeType)) {
      return this.send_CalendarOffTimeRuleUpdateNotify(value)
    }
    if ((value instanceof CalendarRulesGetRequest) && (value.fbeType === this.CalendarRulesGetRequestModel.fbeType)) {
      return this.send_CalendarRulesGetRequest(value)
    }
    if ((value instanceof CalendarRulesGetResponse) && (value.fbeType === this.CalendarRulesGetResponseModel.fbeType)) {
      return this.send_CalendarRulesGetResponse(value)
    }
    if ((value instanceof ChartBarHistoryRangeRequest) && (value.fbeType === this.ChartBarHistoryRangeRequestModel.fbeType)) {
      return this.send_ChartBarHistoryRangeRequest(value)
    }
    if ((value instanceof OrderMarginSubscribeRequest) && (value.fbeType === this.OrderMarginSubscribeRequestModel.fbeType)) {
      return this.send_OrderMarginSubscribeRequest(value)
    }
    if ((value instanceof OrderMarginSubscribeResponse) && (value.fbeType === this.OrderMarginSubscribeResponseModel.fbeType)) {
      return this.send_OrderMarginSubscribeResponse(value)
    }
    if ((value instanceof OrderMarginNotify) && (value.fbeType === this.OrderMarginNotifyModel.fbeType)) {
      return this.send_OrderMarginNotify(value)
    }
    if ((value instanceof OrderMarginUpdateSubscriptionRequest) && (value.fbeType === this.OrderMarginUpdateSubscriptionRequestModel.fbeType)) {
      return this.send_OrderMarginUpdateSubscriptionRequest(value)
    }
    if ((value instanceof OrderMarginUnsubscribeRequest) && (value.fbeType === this.OrderMarginUnsubscribeRequestModel.fbeType)) {
      return this.send_OrderMarginUnsubscribeRequest(value)
    }
    if ((value instanceof OrderMarginUnsubscribeResponse) && (value.fbeType === this.OrderMarginUnsubscribeResponseModel.fbeType)) {
      return this.send_OrderMarginUnsubscribeResponse(value)
    }
    if ((value instanceof OrderMarginGetRequest) && (value.fbeType === this.OrderMarginGetRequestModel.fbeType)) {
      return this.send_OrderMarginGetRequest(value)
    }
    if ((value instanceof OrderMarginGetResponse) && (value.fbeType === this.OrderMarginGetResponseModel.fbeType)) {
      return this.send_OrderMarginGetResponse(value)
    }
    if ((value instanceof OrderStateInfoSubscribeRequest) && (value.fbeType === this.OrderStateInfoSubscribeRequestModel.fbeType)) {
      return this.send_OrderStateInfoSubscribeRequest(value)
    }
    if ((value instanceof OrderStateInfoSubscribeResponse) && (value.fbeType === this.OrderStateInfoSubscribeResponseModel.fbeType)) {
      return this.send_OrderStateInfoSubscribeResponse(value)
    }
    if ((value instanceof OrderStateInfoUnsubscribeRequest) && (value.fbeType === this.OrderStateInfoUnsubscribeRequestModel.fbeType)) {
      return this.send_OrderStateInfoUnsubscribeRequest(value)
    }
    if ((value instanceof OrderStateInfoUnsubscribeResponse) && (value.fbeType === this.OrderStateInfoUnsubscribeResponseModel.fbeType)) {
      return this.send_OrderStateInfoUnsubscribeResponse(value)
    }
    if ((value instanceof OrderStateInfoNotify) && (value.fbeType === this.OrderStateInfoNotifyModel.fbeType)) {
      return this.send_OrderStateInfoNotify(value)
    }
    if ((value instanceof Deprecated003) && (value.fbeType === this.Deprecated003Model.fbeType)) {
      return this.send_Deprecated003(value)
    }
    if ((value instanceof Deprecated004) && (value.fbeType === this.Deprecated004Model.fbeType)) {
      return this.send_Deprecated004(value)
    }
    if ((value instanceof Deprecated005) && (value.fbeType === this.Deprecated005Model.fbeType)) {
      return this.send_Deprecated005(value)
    }
    if ((value instanceof Deprecated006) && (value.fbeType === this.Deprecated006Model.fbeType)) {
      return this.send_Deprecated006(value)
    }
    if ((value instanceof Deprecated007) && (value.fbeType === this.Deprecated007Model.fbeType)) {
      return this.send_Deprecated007(value)
    }
    if ((value instanceof Deprecated008) && (value.fbeType === this.Deprecated008Model.fbeType)) {
      return this.send_Deprecated008(value)
    }
    if ((value instanceof AccountUpdateRequest) && (value.fbeType === this.AccountUpdateRequestModel.fbeType)) {
      return this.send_AccountUpdateRequest(value)
    }
    if ((value instanceof AccountUpdateResponse) && (value.fbeType === this.AccountUpdateResponseModel.fbeType)) {
      return this.send_AccountUpdateResponse(value)
    }
    if ((value instanceof ProfitClosedInfoGetRequest) && (value.fbeType === this.ProfitClosedInfoGetRequestModel.fbeType)) {
      return this.send_ProfitClosedInfoGetRequest(value)
    }
    if ((value instanceof ProfitClosedInfoGetResponse) && (value.fbeType === this.ProfitClosedInfoGetResponseModel.fbeType)) {
      return this.send_ProfitClosedInfoGetResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoSubscribeRequest) && (value.fbeType === this.SymbolDayProfitLossInfoSubscribeRequestModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoSubscribeRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoSubscribeResponse) && (value.fbeType === this.SymbolDayProfitLossInfoSubscribeResponseModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoSubscribeResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoUpdateSubscriptionRequest) && (value.fbeType === this.SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoUpdateSubscriptionRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoUnsubscribeRequest) && (value.fbeType === this.SymbolDayProfitLossInfoUnsubscribeRequestModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoUnsubscribeRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoUnsubscribeResponse) && (value.fbeType === this.SymbolDayProfitLossInfoUnsubscribeResponseModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoUnsubscribeResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoNotify) && (value.fbeType === this.SymbolDayProfitLossInfoNotifyModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoNotify(value)
    }
    if ((value instanceof LoginInfoGetRequest) && (value.fbeType === this.LoginInfoGetRequestModel.fbeType)) {
      return this.send_LoginInfoGetRequest(value)
    }
    if ((value instanceof LoginInfoGetResponse) && (value.fbeType === this.LoginInfoGetResponseModel.fbeType)) {
      return this.send_LoginInfoGetResponse(value)
    }
    if ((value instanceof DashboardInfoCreateRequest) && (value.fbeType === this.DashboardInfoCreateRequestModel.fbeType)) {
      return this.send_DashboardInfoCreateRequest(value)
    }
    if ((value instanceof DashboardInfoCreateResponse) && (value.fbeType === this.DashboardInfoCreateResponseModel.fbeType)) {
      return this.send_DashboardInfoCreateResponse(value)
    }
    if ((value instanceof DashboardInfoUpdateRequest) && (value.fbeType === this.DashboardInfoUpdateRequestModel.fbeType)) {
      return this.send_DashboardInfoUpdateRequest(value)
    }
    if ((value instanceof DashboardInfoUpdateResponse) && (value.fbeType === this.DashboardInfoUpdateResponseModel.fbeType)) {
      return this.send_DashboardInfoUpdateResponse(value)
    }
    if ((value instanceof DashboardInfoListRequest) && (value.fbeType === this.DashboardInfoListRequestModel.fbeType)) {
      return this.send_DashboardInfoListRequest(value)
    }
    if ((value instanceof DashboardInfoListResponse) && (value.fbeType === this.DashboardInfoListResponseModel.fbeType)) {
      return this.send_DashboardInfoListResponse(value)
    }
    if ((value instanceof DashboardInfoGetRequest) && (value.fbeType === this.DashboardInfoGetRequestModel.fbeType)) {
      return this.send_DashboardInfoGetRequest(value)
    }
    if ((value instanceof DashboardInfoGetResponse) && (value.fbeType === this.DashboardInfoGetResponseModel.fbeType)) {
      return this.send_DashboardInfoGetResponse(value)
    }
    if ((value instanceof DashboardInfoRenameRequest) && (value.fbeType === this.DashboardInfoRenameRequestModel.fbeType)) {
      return this.send_DashboardInfoRenameRequest(value)
    }
    if ((value instanceof DashboardInfoRenameResponse) && (value.fbeType === this.DashboardInfoRenameResponseModel.fbeType)) {
      return this.send_DashboardInfoRenameResponse(value)
    }
    if ((value instanceof DashboardInfoDeleteRequest) && (value.fbeType === this.DashboardInfoDeleteRequestModel.fbeType)) {
      return this.send_DashboardInfoDeleteRequest(value)
    }
    if ((value instanceof DashboardInfoDeleteResponse) && (value.fbeType === this.DashboardInfoDeleteResponseModel.fbeType)) {
      return this.send_DashboardInfoDeleteResponse(value)
    }
    if ((value instanceof ChartInfoCreateRequest) && (value.fbeType === this.ChartInfoCreateRequestModel.fbeType)) {
      return this.send_ChartInfoCreateRequest(value)
    }
    if ((value instanceof ChartInfoCreateResponse) && (value.fbeType === this.ChartInfoCreateResponseModel.fbeType)) {
      return this.send_ChartInfoCreateResponse(value)
    }
    if ((value instanceof ChartInfoUpdateRequest) && (value.fbeType === this.ChartInfoUpdateRequestModel.fbeType)) {
      return this.send_ChartInfoUpdateRequest(value)
    }
    if ((value instanceof ChartInfoUpdateResponse) && (value.fbeType === this.ChartInfoUpdateResponseModel.fbeType)) {
      return this.send_ChartInfoUpdateResponse(value)
    }
    if ((value instanceof ChartInfoListRequest) && (value.fbeType === this.ChartInfoListRequestModel.fbeType)) {
      return this.send_ChartInfoListRequest(value)
    }
    if ((value instanceof ChartInfoListResponse) && (value.fbeType === this.ChartInfoListResponseModel.fbeType)) {
      return this.send_ChartInfoListResponse(value)
    }
    if ((value instanceof ChartInfoDeleteRequest) && (value.fbeType === this.ChartInfoDeleteRequestModel.fbeType)) {
      return this.send_ChartInfoDeleteRequest(value)
    }
    if ((value instanceof ChartInfoDeleteResponse) && (value.fbeType === this.ChartInfoDeleteResponseModel.fbeType)) {
      return this.send_ChartInfoDeleteResponse(value)
    }
    if ((value instanceof ChartInfoGetRequest) && (value.fbeType === this.ChartInfoGetRequestModel.fbeType)) {
      return this.send_ChartInfoGetRequest(value)
    }
    if ((value instanceof ChartInfoGetResponse) && (value.fbeType === this.ChartInfoGetResponseModel.fbeType)) {
      return this.send_ChartInfoGetResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoSubscribeRequest) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoSubscribeRequestModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoSubscribeRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoSubscribeResponse) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoSubscribeResponseModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoSubscribeResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoUnsubscribeRequest) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoUnsubscribeRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoUnsubscribeResponse) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoUnsubscribeResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoNotify) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoNotifyModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoNotify(value)
    }
    if ((value instanceof NewsServiceGetAllRequest) && (value.fbeType === this.NewsServiceGetAllRequestModel.fbeType)) {
      return this.send_NewsServiceGetAllRequest(value)
    }
    if ((value instanceof NewsServiceGetAllResponse) && (value.fbeType === this.NewsServiceGetAllResponseModel.fbeType)) {
      return this.send_NewsServiceGetAllResponse(value)
    }
    if ((value instanceof NewsRecentArticlesGetRequest) && (value.fbeType === this.NewsRecentArticlesGetRequestModel.fbeType)) {
      return this.send_NewsRecentArticlesGetRequest(value)
    }
    if ((value instanceof NewsRecentArticlesGetResponse) && (value.fbeType === this.NewsRecentArticlesGetResponseModel.fbeType)) {
      return this.send_NewsRecentArticlesGetResponse(value)
    }
    if ((value instanceof NewsArticleGetRequest) && (value.fbeType === this.NewsArticleGetRequestModel.fbeType)) {
      return this.send_NewsArticleGetRequest(value)
    }
    if ((value instanceof NewsArticleGetResponse) && (value.fbeType === this.NewsArticleGetResponseModel.fbeType)) {
      return this.send_NewsArticleGetResponse(value)
    }
    if ((value instanceof OrderUpdateWithTypeRequest) && (value.fbeType === this.OrderUpdateWithTypeRequestModel.fbeType)) {
      return this.send_OrderUpdateWithTypeRequest(value)
    }
    if ((value instanceof OrderUpdateWithTypeResponse) && (value.fbeType === this.OrderUpdateWithTypeResponseModel.fbeType)) {
      return this.send_OrderUpdateWithTypeResponse(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoSubscribeRequest) && (value.fbeType === this.SymbolProfitLossMultipleInfoSubscribeRequestModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoSubscribeRequest(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoSubscribeResponse) && (value.fbeType === this.SymbolProfitLossMultipleInfoSubscribeResponseModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoSubscribeResponse(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoUpdateSubscriptionRequest) && (value.fbeType === this.SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoUnsubscribeRequest) && (value.fbeType === this.SymbolProfitLossMultipleInfoUnsubscribeRequestModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoUnsubscribeRequest(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoUnsubscribeResponse) && (value.fbeType === this.SymbolProfitLossMultipleInfoUnsubscribeResponseModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoUnsubscribeResponse(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoNotify) && (value.fbeType === this.SymbolProfitLossMultipleInfoNotifyModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoNotify(value)
    }
    if ((value instanceof LoginWithPasswordRequest) && (value.fbeType === this.LoginWithPasswordRequestModel.fbeType)) {
      return this.send_LoginWithPasswordRequest(value)
    }
    if ((value instanceof LoginWithPasswordResponse) && (value.fbeType === this.LoginWithPasswordResponseModel.fbeType)) {
      return this.send_LoginWithPasswordResponse(value)
    }
    if ((value instanceof LoginWithTokenRequest) && (value.fbeType === this.LoginWithTokenRequestModel.fbeType)) {
      return this.send_LoginWithTokenRequest(value)
    }
    if ((value instanceof LoginWithTokenResponse) && (value.fbeType === this.LoginWithTokenResponseModel.fbeType)) {
      return this.send_LoginWithTokenResponse(value)
    }
    if ((value instanceof RefreshTokenRequest) && (value.fbeType === this.RefreshTokenRequestModel.fbeType)) {
      return this.send_RefreshTokenRequest(value)
    }
    if ((value instanceof RefreshTokenResponse) && (value.fbeType === this.RefreshTokenResponseModel.fbeType)) {
      return this.send_RefreshTokenResponse(value)
    }
    if ((value instanceof Logout2Request) && (value.fbeType === this.Logout2RequestModel.fbeType)) {
      return this.send_Logout2Request(value)
    }
    if ((value instanceof Logout2Response) && (value.fbeType === this.Logout2ResponseModel.fbeType)) {
      return this.send_Logout2Response(value)
    }
    if ((value instanceof AccountChangePasswordRequest) && (value.fbeType === this.AccountChangePasswordRequestModel.fbeType)) {
      return this.send_AccountChangePasswordRequest(value)
    }
    if ((value instanceof AccountChangePasswordResponse) && (value.fbeType === this.AccountChangePasswordResponseModel.fbeType)) {
      return this.send_AccountChangePasswordResponse(value)
    }
    if ((value instanceof PushNotificationTokenRegisterRequest) && (value.fbeType === this.PushNotificationTokenRegisterRequestModel.fbeType)) {
      return this.send_PushNotificationTokenRegisterRequest(value)
    }
    if ((value instanceof PushNotificationTokenRegisterResponse) && (value.fbeType === this.PushNotificationTokenRegisterResponseModel.fbeType)) {
      return this.send_PushNotificationTokenRegisterResponse(value)
    }
    if ((value instanceof DesiredAppSettingsGetRequest) && (value.fbeType === this.DesiredAppSettingsGetRequestModel.fbeType)) {
      return this.send_DesiredAppSettingsGetRequest(value)
    }
    if ((value instanceof DesiredAppSettingsGetResponse) && (value.fbeType === this.DesiredAppSettingsGetResponseModel.fbeType)) {
      return this.send_DesiredAppSettingsGetResponse(value)
    }
    if ((value instanceof AuthTokensGetAllRequest) && (value.fbeType === this.AuthTokensGetAllRequestModel.fbeType)) {
      return this.send_AuthTokensGetAllRequest(value)
    }
    if ((value instanceof AuthTokensGetAllResponse) && (value.fbeType === this.AuthTokensGetAllResponseModel.fbeType)) {
      return this.send_AuthTokensGetAllResponse(value)
    }
    if ((value instanceof AuthTokensDeleteRequest) && (value.fbeType === this.AuthTokensDeleteRequestModel.fbeType)) {
      return this.send_AuthTokensDeleteRequest(value)
    }
    if ((value instanceof AuthTokensDeleteResponse) && (value.fbeType === this.AuthTokensDeleteResponseModel.fbeType)) {
      return this.send_AuthTokensDeleteResponse(value)
    }
    if ((value instanceof LegalDocumentGroupListRequest) && (value.fbeType === this.LegalDocumentGroupListRequestModel.fbeType)) {
      return this.send_LegalDocumentGroupListRequest(value)
    }
    if ((value instanceof LegalDocumentGroupListResponse) && (value.fbeType === this.LegalDocumentGroupListResponseModel.fbeType)) {
      return this.send_LegalDocumentGroupListResponse(value)
    }
    if ((value instanceof LegalDocumentGetRequest) && (value.fbeType === this.LegalDocumentGetRequestModel.fbeType)) {
      return this.send_LegalDocumentGetRequest(value)
    }
    if ((value instanceof LegalDocumentGetResponse) && (value.fbeType === this.LegalDocumentGetResponseModel.fbeType)) {
      return this.send_LegalDocumentGetResponse(value)
    }
    if ((value instanceof PushNotificationTokenUnRegisterRequest) && (value.fbeType === this.PushNotificationTokenUnRegisterRequestModel.fbeType)) {
      return this.send_PushNotificationTokenUnRegisterRequest(value)
    }
    if ((value instanceof PushNotificationTokenUnRegisterResponse) && (value.fbeType === this.PushNotificationTokenUnRegisterResponseModel.fbeType)) {
      return this.send_PushNotificationTokenUnRegisterResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._exposedSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send PingRequest value
   * @this {!Sender}
   * @param {!PingRequest} value PingRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PingRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PingRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.PingRequest serialization failed!')
    console.assert(this.PingRequestModel.verify(), 'client.PingRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PongResponse value
   * @this {!Sender}
   * @param {!PongResponse} value PongResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PongResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PongResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.PongResponse serialization failed!')
    console.assert(this.PongResponseModel.verify(), 'client.PongResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginRequest value
   * @this {!Sender}
   * @param {!LoginRequest} value LoginRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginRequest serialization failed!')
    console.assert(this.LoginRequestModel.verify(), 'client.LoginRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginResponse value
   * @this {!Sender}
   * @param {!LoginResponse} value LoginResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginResponse serialization failed!')
    console.assert(this.LoginResponseModel.verify(), 'client.LoginResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginReject value
   * @this {!Sender}
   * @param {!LoginReject} value LoginReject value to send
   * @returns {!number} Sent bytes
   */
  send_LoginReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginRejectModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginReject serialization failed!')
    console.assert(this.LoginRejectModel.verify(), 'client.LoginReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LogoutRequest value
   * @this {!Sender}
   * @param {!LogoutRequest} value LogoutRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LogoutRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LogoutRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.LogoutRequest serialization failed!')
    console.assert(this.LogoutRequestModel.verify(), 'client.LogoutRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LogoutResponse value
   * @this {!Sender}
   * @param {!LogoutResponse} value LogoutResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LogoutResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LogoutResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.LogoutResponse serialization failed!')
    console.assert(this.LogoutResponseModel.verify(), 'client.LogoutResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LogoutNotify value
   * @this {!Sender}
   * @param {!LogoutNotify} value LogoutNotify value to send
   * @returns {!number} Sent bytes
   */
  send_LogoutNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LogoutNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.LogoutNotify serialization failed!')
    console.assert(this.LogoutNotifyModel.verify(), 'client.LogoutNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SessionInfoRequest value
   * @this {!Sender}
   * @param {!SessionInfoRequest} value SessionInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SessionInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SessionInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SessionInfoRequest serialization failed!')
    console.assert(this.SessionInfoRequestModel.verify(), 'client.SessionInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SessionInfoResponse value
   * @this {!Sender}
   * @param {!SessionInfoResponse} value SessionInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SessionInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SessionInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SessionInfoResponse serialization failed!')
    console.assert(this.SessionInfoResponseModel.verify(), 'client.SessionInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimeInfoRequest value
   * @this {!Sender}
   * @param {!TimeInfoRequest} value TimeInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimeInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimeInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.TimeInfoRequest serialization failed!')
    console.assert(this.TimeInfoRequestModel.verify(), 'client.TimeInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimeInfoResponse value
   * @this {!Sender}
   * @param {!TimeInfoResponse} value TimeInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimeInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimeInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.TimeInfoResponse serialization failed!')
    console.assert(this.TimeInfoResponseModel.verify(), 'client.TimeInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneInfoRequest value
   * @this {!Sender}
   * @param {!TimezoneInfoRequest} value TimezoneInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.TimezoneInfoRequest serialization failed!')
    console.assert(this.TimezoneInfoRequestModel.verify(), 'client.TimezoneInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneInfoResponse value
   * @this {!Sender}
   * @param {!TimezoneInfoResponse} value TimezoneInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.TimezoneInfoResponse serialization failed!')
    console.assert(this.TimezoneInfoResponseModel.verify(), 'client.TimezoneInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneInfoUpdateNotify value
   * @this {!Sender}
   * @param {!TimezoneInfoUpdateNotify} value TimezoneInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.TimezoneInfoUpdateNotify serialization failed!')
    console.assert(this.TimezoneInfoUpdateNotifyModel.verify(), 'client.TimezoneInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyInfoRequest value
   * @this {!Sender}
   * @param {!CurrencyInfoRequest} value CurrencyInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyInfoRequest serialization failed!')
    console.assert(this.CurrencyInfoRequestModel.verify(), 'client.CurrencyInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyInfoResponse value
   * @this {!Sender}
   * @param {!CurrencyInfoResponse} value CurrencyInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyInfoResponse serialization failed!')
    console.assert(this.CurrencyInfoResponseModel.verify(), 'client.CurrencyInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyListRequest value
   * @this {!Sender}
   * @param {!CurrencyListRequest} value CurrencyListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyListRequest serialization failed!')
    console.assert(this.CurrencyListRequestModel.verify(), 'client.CurrencyListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyListResponse value
   * @this {!Sender}
   * @param {!CurrencyListResponse} value CurrencyListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyListResponse serialization failed!')
    console.assert(this.CurrencyListResponseModel.verify(), 'client.CurrencyListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyInfoUpdateNotify value
   * @this {!Sender}
   * @param {!CurrencyInfoUpdateNotify} value CurrencyInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyInfoUpdateNotify serialization failed!')
    console.assert(this.CurrencyInfoUpdateNotifyModel.verify(), 'client.CurrencyInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolInfoRequest value
   * @this {!Sender}
   * @param {!SymbolInfoRequest} value SymbolInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolInfoRequest serialization failed!')
    console.assert(this.SymbolInfoRequestModel.verify(), 'client.SymbolInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolInfoResponse value
   * @this {!Sender}
   * @param {!SymbolInfoResponse} value SymbolInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolInfoResponse serialization failed!')
    console.assert(this.SymbolInfoResponseModel.verify(), 'client.SymbolInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolListRequest value
   * @this {!Sender}
   * @param {!SymbolListRequest} value SymbolListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolListRequest serialization failed!')
    console.assert(this.SymbolListRequestModel.verify(), 'client.SymbolListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolListResponse value
   * @this {!Sender}
   * @param {!SymbolListResponse} value SymbolListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolListResponse serialization failed!')
    console.assert(this.SymbolListResponseModel.verify(), 'client.SymbolListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolInfoUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolInfoUpdateNotify} value SymbolInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolInfoUpdateNotify serialization failed!')
    console.assert(this.SymbolInfoUpdateNotifyModel.verify(), 'client.SymbolInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickInfoRequest value
   * @this {!Sender}
   * @param {!TickInfoRequest} value TickInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.TickInfoRequest serialization failed!')
    console.assert(this.TickInfoRequestModel.verify(), 'client.TickInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickInfoResponse value
   * @this {!Sender}
   * @param {!TickInfoResponse} value TickInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.TickInfoResponse serialization failed!')
    console.assert(this.TickInfoResponseModel.verify(), 'client.TickInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickFilterRequest value
   * @this {!Sender}
   * @param {!TickFilterRequest} value TickFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickFilterRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.TickFilterRequest serialization failed!')
    console.assert(this.TickFilterRequestModel.verify(), 'client.TickFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickFilterResponse value
   * @this {!Sender}
   * @param {!TickFilterResponse} value TickFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickFilterResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.TickFilterResponse serialization failed!')
    console.assert(this.TickFilterResponseModel.verify(), 'client.TickFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickListRequest value
   * @this {!Sender}
   * @param {!TickListRequest} value TickListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.TickListRequest serialization failed!')
    console.assert(this.TickListRequestModel.verify(), 'client.TickListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickListResponse value
   * @this {!Sender}
   * @param {!TickListResponse} value TickListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.TickListResponse serialization failed!')
    console.assert(this.TickListResponseModel.verify(), 'client.TickListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2InfoRequest value
   * @this {!Sender}
   * @param {!Level2InfoRequest} value Level2InfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2InfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2InfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2InfoRequest serialization failed!')
    console.assert(this.Level2InfoRequestModel.verify(), 'client.Level2InfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2InfoResponse value
   * @this {!Sender}
   * @param {!Level2InfoResponse} value Level2InfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2InfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2InfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2InfoResponse serialization failed!')
    console.assert(this.Level2InfoResponseModel.verify(), 'client.Level2InfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2FilterRequest value
   * @this {!Sender}
   * @param {!Level2FilterRequest} value Level2FilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2FilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2FilterRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2FilterRequest serialization failed!')
    console.assert(this.Level2FilterRequestModel.verify(), 'client.Level2FilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2FilterResponse value
   * @this {!Sender}
   * @param {!Level2FilterResponse} value Level2FilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2FilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2FilterResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2FilterResponse serialization failed!')
    console.assert(this.Level2FilterResponseModel.verify(), 'client.Level2FilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2ListRequest value
   * @this {!Sender}
   * @param {!Level2ListRequest} value Level2ListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2ListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2ListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2ListRequest serialization failed!')
    console.assert(this.Level2ListRequestModel.verify(), 'client.Level2ListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2ListResponse value
   * @this {!Sender}
   * @param {!Level2ListResponse} value Level2ListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2ListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2ListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2ListResponse serialization failed!')
    console.assert(this.Level2ListResponseModel.verify(), 'client.Level2ListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscriptionInfoRequest value
   * @this {!Sender}
   * @param {!FeedSubscriptionInfoRequest} value FeedSubscriptionInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscriptionInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscriptionInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscriptionInfoRequest serialization failed!')
    console.assert(this.FeedSubscriptionInfoRequestModel.verify(), 'client.FeedSubscriptionInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscriptionInfoResponse value
   * @this {!Sender}
   * @param {!FeedSubscriptionInfoResponse} value FeedSubscriptionInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscriptionInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscriptionInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscriptionInfoResponse serialization failed!')
    console.assert(this.FeedSubscriptionInfoResponseModel.verify(), 'client.FeedSubscriptionInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeRequest value
   * @this {!Sender}
   * @param {!FeedSubscribeRequest} value FeedSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscribeRequest serialization failed!')
    console.assert(this.FeedSubscribeRequestModel.verify(), 'client.FeedSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeAllRequest value
   * @this {!Sender}
   * @param {!FeedSubscribeAllRequest} value FeedSubscribeAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscribeAllRequest serialization failed!')
    console.assert(this.FeedSubscribeAllRequestModel.verify(), 'client.FeedSubscribeAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeResponse value
   * @this {!Sender}
   * @param {!FeedSubscribeResponse} value FeedSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscribeResponse serialization failed!')
    console.assert(this.FeedSubscribeResponseModel.verify(), 'client.FeedSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeRequest value
   * @this {!Sender}
   * @param {!FeedUnsubscribeRequest} value FeedUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedUnsubscribeRequest serialization failed!')
    console.assert(this.FeedUnsubscribeRequestModel.verify(), 'client.FeedUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeAllRequest value
   * @this {!Sender}
   * @param {!FeedUnsubscribeAllRequest} value FeedUnsubscribeAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedUnsubscribeAllRequest serialization failed!')
    console.assert(this.FeedUnsubscribeAllRequestModel.verify(), 'client.FeedUnsubscribeAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeResponse value
   * @this {!Sender}
   * @param {!FeedUnsubscribeResponse} value FeedUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedUnsubscribeResponse serialization failed!')
    console.assert(this.FeedUnsubscribeResponseModel.verify(), 'client.FeedUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickSnapshotNotify value
   * @this {!Sender}
   * @param {!TickSnapshotNotify} value TickSnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TickSnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickSnapshotNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.TickSnapshotNotify serialization failed!')
    console.assert(this.TickSnapshotNotifyModel.verify(), 'client.TickSnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickUpdateNotify value
   * @this {!Sender}
   * @param {!TickUpdateNotify} value TickUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TickUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.TickUpdateNotify serialization failed!')
    console.assert(this.TickUpdateNotifyModel.verify(), 'client.TickUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2SnapshotNotify value
   * @this {!Sender}
   * @param {!Level2SnapshotNotify} value Level2SnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_Level2SnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2SnapshotNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2SnapshotNotify serialization failed!')
    console.assert(this.Level2SnapshotNotifyModel.verify(), 'client.Level2SnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2UpdateNotify value
   * @this {!Sender}
   * @param {!Level2UpdateNotify} value Level2UpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_Level2UpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2UpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2UpdateNotify serialization failed!')
    console.assert(this.Level2UpdateNotifyModel.verify(), 'client.Level2UpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryRequest value
   * @this {!Sender}
   * @param {!ChartBarHistoryRequest} value ChartBarHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarHistoryRequest serialization failed!')
    console.assert(this.ChartBarHistoryRequestModel.verify(), 'client.ChartBarHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryResponse value
   * @this {!Sender}
   * @param {!ChartBarHistoryResponse} value ChartBarHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarHistoryResponse serialization failed!')
    console.assert(this.ChartBarHistoryResponseModel.verify(), 'client.ChartBarHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarSubscribeRequest value
   * @this {!Sender}
   * @param {!ChartBarSubscribeRequest} value ChartBarSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarSubscribeRequest serialization failed!')
    console.assert(this.ChartBarSubscribeRequestModel.verify(), 'client.ChartBarSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarSubscribeResponse value
   * @this {!Sender}
   * @param {!ChartBarSubscribeResponse} value ChartBarSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarSubscribeResponse serialization failed!')
    console.assert(this.ChartBarSubscribeResponseModel.verify(), 'client.ChartBarSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarUnsubscribeRequest value
   * @this {!Sender}
   * @param {!ChartBarUnsubscribeRequest} value ChartBarUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarUnsubscribeRequest serialization failed!')
    console.assert(this.ChartBarUnsubscribeRequestModel.verify(), 'client.ChartBarUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarUnsubscribeResponse value
   * @this {!Sender}
   * @param {!ChartBarUnsubscribeResponse} value ChartBarUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarUnsubscribeResponse serialization failed!')
    console.assert(this.ChartBarUnsubscribeResponseModel.verify(), 'client.ChartBarUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarNotify value
   * @this {!Sender}
   * @param {!ChartBarNotify} value ChartBarNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarNotify serialization failed!')
    console.assert(this.ChartBarNotifyModel.verify(), 'client.ChartBarNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarUpdateNotify value
   * @this {!Sender}
   * @param {!ChartBarUpdateNotify} value ChartBarUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarUpdateNotify serialization failed!')
    console.assert(this.ChartBarUpdateNotifyModel.verify(), 'client.ChartBarUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformInfoRequest value
   * @this {!Sender}
   * @param {!PlatformInfoRequest} value PlatformInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.PlatformInfoRequest serialization failed!')
    console.assert(this.PlatformInfoRequestModel.verify(), 'client.PlatformInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformInfoResponse value
   * @this {!Sender}
   * @param {!PlatformInfoResponse} value PlatformInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.PlatformInfoResponse serialization failed!')
    console.assert(this.PlatformInfoResponseModel.verify(), 'client.PlatformInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformInfoUpdateNotify value
   * @this {!Sender}
   * @param {!PlatformInfoUpdateNotify} value PlatformInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.PlatformInfoUpdateNotify serialization failed!')
    console.assert(this.PlatformInfoUpdateNotifyModel.verify(), 'client.PlatformInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInfoRequest value
   * @this {!Sender}
   * @param {!AccountInfoRequest} value AccountInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountInfoRequest serialization failed!')
    console.assert(this.AccountInfoRequestModel.verify(), 'client.AccountInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInfoResponse value
   * @this {!Sender}
   * @param {!AccountInfoResponse} value AccountInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountInfoResponse serialization failed!')
    console.assert(this.AccountInfoResponseModel.verify(), 'client.AccountInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInfoUpdateNotify value
   * @this {!Sender}
   * @param {!AccountInfoUpdateNotify} value AccountInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountInfoUpdateNotify serialization failed!')
    console.assert(this.AccountInfoUpdateNotifyModel.verify(), 'client.AccountInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginInfoRequest value
   * @this {!Sender}
   * @param {!MarginInfoRequest} value MarginInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.MarginInfoRequest serialization failed!')
    console.assert(this.MarginInfoRequestModel.verify(), 'client.MarginInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginInfoResponse value
   * @this {!Sender}
   * @param {!MarginInfoResponse} value MarginInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.MarginInfoResponse serialization failed!')
    console.assert(this.MarginInfoResponseModel.verify(), 'client.MarginInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginInfoUpdateNotify value
   * @this {!Sender}
   * @param {!MarginInfoUpdateNotify} value MarginInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.MarginInfoUpdateNotify serialization failed!')
    console.assert(this.MarginInfoUpdateNotifyModel.verify(), 'client.MarginInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginCallNotify value
   * @this {!Sender}
   * @param {!MarginCallNotify} value MarginCallNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginCallNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginCallNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.MarginCallNotify serialization failed!')
    console.assert(this.MarginCallNotifyModel.verify(), 'client.MarginCallNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StopOutNotify value
   * @this {!Sender}
   * @param {!StopOutNotify} value StopOutNotify value to send
   * @returns {!number} Sent bytes
   */
  send_StopOutNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StopOutNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.StopOutNotify serialization failed!')
    console.assert(this.StopOutNotifyModel.verify(), 'client.StopOutNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInfoRequest value
   * @this {!Sender}
   * @param {!AssetInfoRequest} value AssetInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetInfoRequest serialization failed!')
    console.assert(this.AssetInfoRequestModel.verify(), 'client.AssetInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInfoResponse value
   * @this {!Sender}
   * @param {!AssetInfoResponse} value AssetInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetInfoResponse serialization failed!')
    console.assert(this.AssetInfoResponseModel.verify(), 'client.AssetInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetListRequest value
   * @this {!Sender}
   * @param {!AssetListRequest} value AssetListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetListRequest serialization failed!')
    console.assert(this.AssetListRequestModel.verify(), 'client.AssetListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetListResponse value
   * @this {!Sender}
   * @param {!AssetListResponse} value AssetListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetListResponse serialization failed!')
    console.assert(this.AssetListResponseModel.verify(), 'client.AssetListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInfoUpdateNotify value
   * @this {!Sender}
   * @param {!AssetInfoUpdateNotify} value AssetInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetInfoUpdateNotify serialization failed!')
    console.assert(this.AssetInfoUpdateNotifyModel.verify(), 'client.AssetInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInfoRequest value
   * @this {!Sender}
   * @param {!OrderInfoRequest} value OrderInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderInfoRequest serialization failed!')
    console.assert(this.OrderInfoRequestModel.verify(), 'client.OrderInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInfoResponse value
   * @this {!Sender}
   * @param {!OrderInfoResponse} value OrderInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderInfoResponse serialization failed!')
    console.assert(this.OrderInfoResponseModel.verify(), 'client.OrderInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderListRequest value
   * @this {!Sender}
   * @param {!OrderListRequest} value OrderListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderListRequest serialization failed!')
    console.assert(this.OrderListRequestModel.verify(), 'client.OrderListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderListResponse value
   * @this {!Sender}
   * @param {!OrderListResponse} value OrderListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderListResponse serialization failed!')
    console.assert(this.OrderListResponseModel.verify(), 'client.OrderListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderOpenRequest value
   * @this {!Sender}
   * @param {!OrderOpenRequest} value OrderOpenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderOpenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderOpenRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderOpenRequest serialization failed!')
    console.assert(this.OrderOpenRequestModel.verify(), 'client.OrderOpenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderOpenResponse value
   * @this {!Sender}
   * @param {!OrderOpenResponse} value OrderOpenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderOpenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderOpenResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderOpenResponse serialization failed!')
    console.assert(this.OrderOpenResponseModel.verify(), 'client.OrderOpenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateRequest value
   * @this {!Sender}
   * @param {!OrderUpdateRequest} value OrderUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderUpdateRequest serialization failed!')
    console.assert(this.OrderUpdateRequestModel.verify(), 'client.OrderUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateResponse value
   * @this {!Sender}
   * @param {!OrderUpdateResponse} value OrderUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderUpdateResponse serialization failed!')
    console.assert(this.OrderUpdateResponseModel.verify(), 'client.OrderUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelRequest value
   * @this {!Sender}
   * @param {!OrderCancelRequest} value OrderCancelRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelRequest serialization failed!')
    console.assert(this.OrderCancelRequestModel.verify(), 'client.OrderCancelRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelResponse value
   * @this {!Sender}
   * @param {!OrderCancelResponse} value OrderCancelResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelResponse serialization failed!')
    console.assert(this.OrderCancelResponseModel.verify(), 'client.OrderCancelResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllRequest value
   * @this {!Sender}
   * @param {!OrderCancelAllRequest} value OrderCancelAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelAllRequest serialization failed!')
    console.assert(this.OrderCancelAllRequestModel.verify(), 'client.OrderCancelAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllResponse value
   * @this {!Sender}
   * @param {!OrderCancelAllResponse} value OrderCancelAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelAllResponse serialization failed!')
    console.assert(this.OrderCancelAllResponseModel.verify(), 'client.OrderCancelAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllByIdRequest value
   * @this {!Sender}
   * @param {!OrderCancelAllByIdRequest} value OrderCancelAllByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllByIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelAllByIdRequest serialization failed!')
    console.assert(this.OrderCancelAllByIdRequestModel.verify(), 'client.OrderCancelAllByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseRequest value
   * @this {!Sender}
   * @param {!OrderCloseRequest} value OrderCloseRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseRequest serialization failed!')
    console.assert(this.OrderCloseRequestModel.verify(), 'client.OrderCloseRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseResponse value
   * @this {!Sender}
   * @param {!OrderCloseResponse} value OrderCloseResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseResponse serialization failed!')
    console.assert(this.OrderCloseResponseModel.verify(), 'client.OrderCloseResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllRequest value
   * @this {!Sender}
   * @param {!OrderCloseAllRequest} value OrderCloseAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseAllRequest serialization failed!')
    console.assert(this.OrderCloseAllRequestModel.verify(), 'client.OrderCloseAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllResponse value
   * @this {!Sender}
   * @param {!OrderCloseAllResponse} value OrderCloseAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseAllResponse serialization failed!')
    console.assert(this.OrderCloseAllResponseModel.verify(), 'client.OrderCloseAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllByIdRequest value
   * @this {!Sender}
   * @param {!OrderCloseAllByIdRequest} value OrderCloseAllByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllByIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseAllByIdRequest serialization failed!')
    console.assert(this.OrderCloseAllByIdRequestModel.verify(), 'client.OrderCloseAllByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByRequest value
   * @this {!Sender}
   * @param {!OrderCloseByRequest} value OrderCloseByRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseByRequest serialization failed!')
    console.assert(this.OrderCloseByRequestModel.verify(), 'client.OrderCloseByRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByResponse value
   * @this {!Sender}
   * @param {!OrderCloseByResponse} value OrderCloseByResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseByResponse serialization failed!')
    console.assert(this.OrderCloseByResponseModel.verify(), 'client.OrderCloseByResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByAllRequest value
   * @this {!Sender}
   * @param {!OrderCloseByAllRequest} value OrderCloseByAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseByAllRequest serialization failed!')
    console.assert(this.OrderCloseByAllRequestModel.verify(), 'client.OrderCloseByAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByAllResponse value
   * @this {!Sender}
   * @param {!OrderCloseByAllResponse} value OrderCloseByAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseByAllResponse serialization failed!')
    console.assert(this.OrderCloseByAllResponseModel.verify(), 'client.OrderCloseByAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseRequest value
   * @this {!Sender}
   * @param {!OrderCancelOrCloseRequest} value OrderCancelOrCloseRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelOrCloseRequest serialization failed!')
    console.assert(this.OrderCancelOrCloseRequestModel.verify(), 'client.OrderCancelOrCloseRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseResponse value
   * @this {!Sender}
   * @param {!OrderCancelOrCloseResponse} value OrderCancelOrCloseResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelOrCloseResponse serialization failed!')
    console.assert(this.OrderCancelOrCloseResponseModel.verify(), 'client.OrderCancelOrCloseResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseAllRequest value
   * @this {!Sender}
   * @param {!OrderCancelOrCloseAllRequest} value OrderCancelOrCloseAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelOrCloseAllRequest serialization failed!')
    console.assert(this.OrderCancelOrCloseAllRequestModel.verify(), 'client.OrderCancelOrCloseAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseAllResponse value
   * @this {!Sender}
   * @param {!OrderCancelOrCloseAllResponse} value OrderCancelOrCloseAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelOrCloseAllResponse serialization failed!')
    console.assert(this.OrderCancelOrCloseAllResponseModel.verify(), 'client.OrderCancelOrCloseAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInfoUpdateNotify value
   * @this {!Sender}
   * @param {!OrderInfoUpdateNotify} value OrderInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderInfoUpdateNotify serialization failed!')
    console.assert(this.OrderInfoUpdateNotifyModel.verify(), 'client.OrderInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInfoRequest value
   * @this {!Sender}
   * @param {!PositionInfoRequest} value PositionInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionInfoRequest serialization failed!')
    console.assert(this.PositionInfoRequestModel.verify(), 'client.PositionInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInfoResponse value
   * @this {!Sender}
   * @param {!PositionInfoResponse} value PositionInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionInfoResponse serialization failed!')
    console.assert(this.PositionInfoResponseModel.verify(), 'client.PositionInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionListRequest value
   * @this {!Sender}
   * @param {!PositionListRequest} value PositionListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionListRequest serialization failed!')
    console.assert(this.PositionListRequestModel.verify(), 'client.PositionListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionListResponse value
   * @this {!Sender}
   * @param {!PositionListResponse} value PositionListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionListResponse serialization failed!')
    console.assert(this.PositionListResponseModel.verify(), 'client.PositionListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInfoUpdateNotify value
   * @this {!Sender}
   * @param {!PositionInfoUpdateNotify} value PositionInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionInfoUpdateNotify serialization failed!')
    console.assert(this.PositionInfoUpdateNotifyModel.verify(), 'client.PositionInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoRequest value
   * @this {!Sender}
   * @param {!TradeHistoryInfoRequest} value TradeHistoryInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoRequest serialization failed!')
    console.assert(this.TradeHistoryInfoRequestModel.verify(), 'client.TradeHistoryInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoResponse value
   * @this {!Sender}
   * @param {!TradeHistoryInfoResponse} value TradeHistoryInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoResponse serialization failed!')
    console.assert(this.TradeHistoryInfoResponseModel.verify(), 'client.TradeHistoryInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoFilterRequest value
   * @this {!Sender}
   * @param {!TradeHistoryInfoFilterRequest} value TradeHistoryInfoFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoFilterRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoFilterRequest serialization failed!')
    console.assert(this.TradeHistoryInfoFilterRequestModel.verify(), 'client.TradeHistoryInfoFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoByIdRequest value
   * @this {!Sender}
   * @param {!TradeHistoryInfoByIdRequest} value TradeHistoryInfoByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoByIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoByIdRequest serialization failed!')
    console.assert(this.TradeHistoryInfoByIdRequestModel.verify(), 'client.TradeHistoryInfoByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoByIdResponse value
   * @this {!Sender}
   * @param {!TradeHistoryInfoByIdResponse} value TradeHistoryInfoByIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoByIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoByIdResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoByIdResponse serialization failed!')
    console.assert(this.TradeHistoryInfoByIdResponseModel.verify(), 'client.TradeHistoryInfoByIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoUpdateNotify value
   * @this {!Sender}
   * @param {!TradeHistoryInfoUpdateNotify} value TradeHistoryInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoUpdateNotify serialization failed!')
    console.assert(this.TradeHistoryInfoUpdateNotifyModel.verify(), 'client.TradeHistoryInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRegularRuleGetRequest value
   * @this {!Sender}
   * @param {!CalendarRegularRuleGetRequest} value CalendarRegularRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRegularRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRegularRuleGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRegularRuleGetRequest serialization failed!')
    console.assert(this.CalendarRegularRuleGetRequestModel.verify(), 'client.CalendarRegularRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRegularRuleGetResponse value
   * @this {!Sender}
   * @param {!CalendarRegularRuleGetResponse} value CalendarRegularRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRegularRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRegularRuleGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRegularRuleGetResponse serialization failed!')
    console.assert(this.CalendarRegularRuleGetResponseModel.verify(), 'client.CalendarRegularRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRegularRuleUpdateNotify value
   * @this {!Sender}
   * @param {!CalendarRegularRuleUpdateNotify} value CalendarRegularRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRegularRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRegularRuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRegularRuleUpdateNotify serialization failed!')
    console.assert(this.CalendarRegularRuleUpdateNotifyModel.verify(), 'client.CalendarRegularRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarOffTimeRuleGetRequest value
   * @this {!Sender}
   * @param {!CalendarOffTimeRuleGetRequest} value CalendarOffTimeRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarOffTimeRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarOffTimeRuleGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarOffTimeRuleGetRequest serialization failed!')
    console.assert(this.CalendarOffTimeRuleGetRequestModel.verify(), 'client.CalendarOffTimeRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarOffTimeRuleGetResponse value
   * @this {!Sender}
   * @param {!CalendarOffTimeRuleGetResponse} value CalendarOffTimeRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarOffTimeRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarOffTimeRuleGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarOffTimeRuleGetResponse serialization failed!')
    console.assert(this.CalendarOffTimeRuleGetResponseModel.verify(), 'client.CalendarOffTimeRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarOffTimeRuleUpdateNotify value
   * @this {!Sender}
   * @param {!CalendarOffTimeRuleUpdateNotify} value CalendarOffTimeRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarOffTimeRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarOffTimeRuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarOffTimeRuleUpdateNotify serialization failed!')
    console.assert(this.CalendarOffTimeRuleUpdateNotifyModel.verify(), 'client.CalendarOffTimeRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRulesGetRequest value
   * @this {!Sender}
   * @param {!CalendarRulesGetRequest} value CalendarRulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRulesGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRulesGetRequest serialization failed!')
    console.assert(this.CalendarRulesGetRequestModel.verify(), 'client.CalendarRulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRulesGetResponse value
   * @this {!Sender}
   * @param {!CalendarRulesGetResponse} value CalendarRulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRulesGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRulesGetResponse serialization failed!')
    console.assert(this.CalendarRulesGetResponseModel.verify(), 'client.CalendarRulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryRangeRequest value
   * @this {!Sender}
   * @param {!ChartBarHistoryRangeRequest} value ChartBarHistoryRangeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryRangeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryRangeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarHistoryRangeRequest serialization failed!')
    console.assert(this.ChartBarHistoryRangeRequestModel.verify(), 'client.ChartBarHistoryRangeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginSubscribeRequest value
   * @this {!Sender}
   * @param {!OrderMarginSubscribeRequest} value OrderMarginSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginSubscribeRequest serialization failed!')
    console.assert(this.OrderMarginSubscribeRequestModel.verify(), 'client.OrderMarginSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginSubscribeResponse value
   * @this {!Sender}
   * @param {!OrderMarginSubscribeResponse} value OrderMarginSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginSubscribeResponse serialization failed!')
    console.assert(this.OrderMarginSubscribeResponseModel.verify(), 'client.OrderMarginSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginNotify value
   * @this {!Sender}
   * @param {!OrderMarginNotify} value OrderMarginNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginNotify serialization failed!')
    console.assert(this.OrderMarginNotifyModel.verify(), 'client.OrderMarginNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginUpdateSubscriptionRequest value
   * @this {!Sender}
   * @param {!OrderMarginUpdateSubscriptionRequest} value OrderMarginUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginUpdateSubscriptionRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginUpdateSubscriptionRequest serialization failed!')
    console.assert(this.OrderMarginUpdateSubscriptionRequestModel.verify(), 'client.OrderMarginUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginUnsubscribeRequest value
   * @this {!Sender}
   * @param {!OrderMarginUnsubscribeRequest} value OrderMarginUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginUnsubscribeRequest serialization failed!')
    console.assert(this.OrderMarginUnsubscribeRequestModel.verify(), 'client.OrderMarginUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginUnsubscribeResponse value
   * @this {!Sender}
   * @param {!OrderMarginUnsubscribeResponse} value OrderMarginUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginUnsubscribeResponse serialization failed!')
    console.assert(this.OrderMarginUnsubscribeResponseModel.verify(), 'client.OrderMarginUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginGetRequest value
   * @this {!Sender}
   * @param {!OrderMarginGetRequest} value OrderMarginGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginGetRequest serialization failed!')
    console.assert(this.OrderMarginGetRequestModel.verify(), 'client.OrderMarginGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginGetResponse value
   * @this {!Sender}
   * @param {!OrderMarginGetResponse} value OrderMarginGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginGetResponse serialization failed!')
    console.assert(this.OrderMarginGetResponseModel.verify(), 'client.OrderMarginGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoSubscribeRequest value
   * @this {!Sender}
   * @param {!OrderStateInfoSubscribeRequest} value OrderStateInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoSubscribeRequest serialization failed!')
    console.assert(this.OrderStateInfoSubscribeRequestModel.verify(), 'client.OrderStateInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoSubscribeResponse value
   * @this {!Sender}
   * @param {!OrderStateInfoSubscribeResponse} value OrderStateInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoSubscribeResponse serialization failed!')
    console.assert(this.OrderStateInfoSubscribeResponseModel.verify(), 'client.OrderStateInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoUnsubscribeRequest value
   * @this {!Sender}
   * @param {!OrderStateInfoUnsubscribeRequest} value OrderStateInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoUnsubscribeRequest serialization failed!')
    console.assert(this.OrderStateInfoUnsubscribeRequestModel.verify(), 'client.OrderStateInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoUnsubscribeResponse value
   * @this {!Sender}
   * @param {!OrderStateInfoUnsubscribeResponse} value OrderStateInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoUnsubscribeResponse serialization failed!')
    console.assert(this.OrderStateInfoUnsubscribeResponseModel.verify(), 'client.OrderStateInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoNotify value
   * @this {!Sender}
   * @param {!OrderStateInfoNotify} value OrderStateInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoNotify serialization failed!')
    console.assert(this.OrderStateInfoNotifyModel.verify(), 'client.OrderStateInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated003 value
   * @this {!Sender}
   * @param {!Deprecated003} value Deprecated003 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated003 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated003Model.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated003 serialization failed!')
    console.assert(this.Deprecated003Model.verify(), 'client.Deprecated003 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated004 value
   * @this {!Sender}
   * @param {!Deprecated004} value Deprecated004 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated004 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated004Model.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated004 serialization failed!')
    console.assert(this.Deprecated004Model.verify(), 'client.Deprecated004 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated005 value
   * @this {!Sender}
   * @param {!Deprecated005} value Deprecated005 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated005 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated005Model.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated005 serialization failed!')
    console.assert(this.Deprecated005Model.verify(), 'client.Deprecated005 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated006 value
   * @this {!Sender}
   * @param {!Deprecated006} value Deprecated006 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated006 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated006Model.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated006 serialization failed!')
    console.assert(this.Deprecated006Model.verify(), 'client.Deprecated006 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated007 value
   * @this {!Sender}
   * @param {!Deprecated007} value Deprecated007 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated007 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated007Model.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated007 serialization failed!')
    console.assert(this.Deprecated007Model.verify(), 'client.Deprecated007 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated008 value
   * @this {!Sender}
   * @param {!Deprecated008} value Deprecated008 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated008 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated008Model.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated008 serialization failed!')
    console.assert(this.Deprecated008Model.verify(), 'client.Deprecated008 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateRequest value
   * @this {!Sender}
   * @param {!AccountUpdateRequest} value AccountUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountUpdateRequest serialization failed!')
    console.assert(this.AccountUpdateRequestModel.verify(), 'client.AccountUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateResponse value
   * @this {!Sender}
   * @param {!AccountUpdateResponse} value AccountUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountUpdateResponse serialization failed!')
    console.assert(this.AccountUpdateResponseModel.verify(), 'client.AccountUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedInfoGetRequest value
   * @this {!Sender}
   * @param {!ProfitClosedInfoGetRequest} value ProfitClosedInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedInfoGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ProfitClosedInfoGetRequest serialization failed!')
    console.assert(this.ProfitClosedInfoGetRequestModel.verify(), 'client.ProfitClosedInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedInfoGetResponse value
   * @this {!Sender}
   * @param {!ProfitClosedInfoGetResponse} value ProfitClosedInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedInfoGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ProfitClosedInfoGetResponse serialization failed!')
    console.assert(this.ProfitClosedInfoGetResponseModel.verify(), 'client.ProfitClosedInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoSubscribeRequest value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossInfoSubscribeRequest} value SymbolDayProfitLossInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoSubscribeRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoSubscribeRequestModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoSubscribeResponse value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossInfoSubscribeResponse} value SymbolDayProfitLossInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoSubscribeResponse serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoSubscribeResponseModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoUpdateSubscriptionRequest value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossInfoUpdateSubscriptionRequest} value SymbolDayProfitLossInfoUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoUnsubscribeRequest value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossInfoUnsubscribeRequest} value SymbolDayProfitLossInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoUnsubscribeRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoUnsubscribeRequestModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoUnsubscribeResponse value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossInfoUnsubscribeResponse} value SymbolDayProfitLossInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoUnsubscribeResponse serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoUnsubscribeResponseModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoNotify value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossInfoNotify} value SymbolDayProfitLossInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoNotify serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoNotifyModel.verify(), 'client.SymbolDayProfitLossInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginInfoGetRequest value
   * @this {!Sender}
   * @param {!LoginInfoGetRequest} value LoginInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginInfoGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginInfoGetRequest serialization failed!')
    console.assert(this.LoginInfoGetRequestModel.verify(), 'client.LoginInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginInfoGetResponse value
   * @this {!Sender}
   * @param {!LoginInfoGetResponse} value LoginInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginInfoGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginInfoGetResponse serialization failed!')
    console.assert(this.LoginInfoGetResponseModel.verify(), 'client.LoginInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoCreateRequest value
   * @this {!Sender}
   * @param {!DashboardInfoCreateRequest} value DashboardInfoCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoCreateRequest serialization failed!')
    console.assert(this.DashboardInfoCreateRequestModel.verify(), 'client.DashboardInfoCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoCreateResponse value
   * @this {!Sender}
   * @param {!DashboardInfoCreateResponse} value DashboardInfoCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoCreateResponse serialization failed!')
    console.assert(this.DashboardInfoCreateResponseModel.verify(), 'client.DashboardInfoCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoUpdateRequest value
   * @this {!Sender}
   * @param {!DashboardInfoUpdateRequest} value DashboardInfoUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoUpdateRequest serialization failed!')
    console.assert(this.DashboardInfoUpdateRequestModel.verify(), 'client.DashboardInfoUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoUpdateResponse value
   * @this {!Sender}
   * @param {!DashboardInfoUpdateResponse} value DashboardInfoUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoUpdateResponse serialization failed!')
    console.assert(this.DashboardInfoUpdateResponseModel.verify(), 'client.DashboardInfoUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoListRequest value
   * @this {!Sender}
   * @param {!DashboardInfoListRequest} value DashboardInfoListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoListRequest serialization failed!')
    console.assert(this.DashboardInfoListRequestModel.verify(), 'client.DashboardInfoListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoListResponse value
   * @this {!Sender}
   * @param {!DashboardInfoListResponse} value DashboardInfoListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoListResponse serialization failed!')
    console.assert(this.DashboardInfoListResponseModel.verify(), 'client.DashboardInfoListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoGetRequest value
   * @this {!Sender}
   * @param {!DashboardInfoGetRequest} value DashboardInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoGetRequest serialization failed!')
    console.assert(this.DashboardInfoGetRequestModel.verify(), 'client.DashboardInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoGetResponse value
   * @this {!Sender}
   * @param {!DashboardInfoGetResponse} value DashboardInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoGetResponse serialization failed!')
    console.assert(this.DashboardInfoGetResponseModel.verify(), 'client.DashboardInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoRenameRequest value
   * @this {!Sender}
   * @param {!DashboardInfoRenameRequest} value DashboardInfoRenameRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoRenameRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoRenameRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoRenameRequest serialization failed!')
    console.assert(this.DashboardInfoRenameRequestModel.verify(), 'client.DashboardInfoRenameRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoRenameResponse value
   * @this {!Sender}
   * @param {!DashboardInfoRenameResponse} value DashboardInfoRenameResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoRenameResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoRenameResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoRenameResponse serialization failed!')
    console.assert(this.DashboardInfoRenameResponseModel.verify(), 'client.DashboardInfoRenameResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoDeleteRequest value
   * @this {!Sender}
   * @param {!DashboardInfoDeleteRequest} value DashboardInfoDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoDeleteRequest serialization failed!')
    console.assert(this.DashboardInfoDeleteRequestModel.verify(), 'client.DashboardInfoDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoDeleteResponse value
   * @this {!Sender}
   * @param {!DashboardInfoDeleteResponse} value DashboardInfoDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoDeleteResponse serialization failed!')
    console.assert(this.DashboardInfoDeleteResponseModel.verify(), 'client.DashboardInfoDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoCreateRequest value
   * @this {!Sender}
   * @param {!ChartInfoCreateRequest} value ChartInfoCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoCreateRequest serialization failed!')
    console.assert(this.ChartInfoCreateRequestModel.verify(), 'client.ChartInfoCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoCreateResponse value
   * @this {!Sender}
   * @param {!ChartInfoCreateResponse} value ChartInfoCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoCreateResponse serialization failed!')
    console.assert(this.ChartInfoCreateResponseModel.verify(), 'client.ChartInfoCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoUpdateRequest value
   * @this {!Sender}
   * @param {!ChartInfoUpdateRequest} value ChartInfoUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoUpdateRequest serialization failed!')
    console.assert(this.ChartInfoUpdateRequestModel.verify(), 'client.ChartInfoUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoUpdateResponse value
   * @this {!Sender}
   * @param {!ChartInfoUpdateResponse} value ChartInfoUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoUpdateResponse serialization failed!')
    console.assert(this.ChartInfoUpdateResponseModel.verify(), 'client.ChartInfoUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoListRequest value
   * @this {!Sender}
   * @param {!ChartInfoListRequest} value ChartInfoListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoListRequest serialization failed!')
    console.assert(this.ChartInfoListRequestModel.verify(), 'client.ChartInfoListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoListResponse value
   * @this {!Sender}
   * @param {!ChartInfoListResponse} value ChartInfoListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoListResponse serialization failed!')
    console.assert(this.ChartInfoListResponseModel.verify(), 'client.ChartInfoListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoDeleteRequest value
   * @this {!Sender}
   * @param {!ChartInfoDeleteRequest} value ChartInfoDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoDeleteRequest serialization failed!')
    console.assert(this.ChartInfoDeleteRequestModel.verify(), 'client.ChartInfoDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoDeleteResponse value
   * @this {!Sender}
   * @param {!ChartInfoDeleteResponse} value ChartInfoDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoDeleteResponse serialization failed!')
    console.assert(this.ChartInfoDeleteResponseModel.verify(), 'client.ChartInfoDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoGetRequest value
   * @this {!Sender}
   * @param {!ChartInfoGetRequest} value ChartInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoGetRequest serialization failed!')
    console.assert(this.ChartInfoGetRequestModel.verify(), 'client.ChartInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoGetResponse value
   * @this {!Sender}
   * @param {!ChartInfoGetResponse} value ChartInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoGetResponse serialization failed!')
    console.assert(this.ChartInfoGetResponseModel.verify(), 'client.ChartInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoSubscribeRequest value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeRequest} value SymbolDayProfitLossMultipleInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoSubscribeRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoSubscribeResponse value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeResponse} value SymbolDayProfitLossMultipleInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoSubscribeResponseModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest} value SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoUnsubscribeRequest value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeRequest} value SymbolDayProfitLossMultipleInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoUnsubscribeResponse value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeResponse} value SymbolDayProfitLossMultipleInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoNotify value
   * @this {!Sender}
   * @param {!SymbolDayProfitLossMultipleInfoNotify} value SymbolDayProfitLossMultipleInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoNotify serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoNotifyModel.verify(), 'client.SymbolDayProfitLossMultipleInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsServiceGetAllRequest value
   * @this {!Sender}
   * @param {!NewsServiceGetAllRequest} value NewsServiceGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsServiceGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsServiceGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsServiceGetAllRequest serialization failed!')
    console.assert(this.NewsServiceGetAllRequestModel.verify(), 'client.NewsServiceGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsServiceGetAllResponse value
   * @this {!Sender}
   * @param {!NewsServiceGetAllResponse} value NewsServiceGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsServiceGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsServiceGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsServiceGetAllResponse serialization failed!')
    console.assert(this.NewsServiceGetAllResponseModel.verify(), 'client.NewsServiceGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsRecentArticlesGetRequest value
   * @this {!Sender}
   * @param {!NewsRecentArticlesGetRequest} value NewsRecentArticlesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsRecentArticlesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsRecentArticlesGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsRecentArticlesGetRequest serialization failed!')
    console.assert(this.NewsRecentArticlesGetRequestModel.verify(), 'client.NewsRecentArticlesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsRecentArticlesGetResponse value
   * @this {!Sender}
   * @param {!NewsRecentArticlesGetResponse} value NewsRecentArticlesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsRecentArticlesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsRecentArticlesGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsRecentArticlesGetResponse serialization failed!')
    console.assert(this.NewsRecentArticlesGetResponseModel.verify(), 'client.NewsRecentArticlesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsArticleGetRequest value
   * @this {!Sender}
   * @param {!NewsArticleGetRequest} value NewsArticleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsArticleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsArticleGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsArticleGetRequest serialization failed!')
    console.assert(this.NewsArticleGetRequestModel.verify(), 'client.NewsArticleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsArticleGetResponse value
   * @this {!Sender}
   * @param {!NewsArticleGetResponse} value NewsArticleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsArticleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsArticleGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsArticleGetResponse serialization failed!')
    console.assert(this.NewsArticleGetResponseModel.verify(), 'client.NewsArticleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithTypeRequest value
   * @this {!Sender}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithTypeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithTypeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderUpdateWithTypeRequest serialization failed!')
    console.assert(this.OrderUpdateWithTypeRequestModel.verify(), 'client.OrderUpdateWithTypeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithTypeResponse value
   * @this {!Sender}
   * @param {!OrderUpdateWithTypeResponse} value OrderUpdateWithTypeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithTypeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithTypeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderUpdateWithTypeResponse serialization failed!')
    console.assert(this.OrderUpdateWithTypeResponseModel.verify(), 'client.OrderUpdateWithTypeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoSubscribeRequest value
   * @this {!Sender}
   * @param {!SymbolProfitLossMultipleInfoSubscribeRequest} value SymbolProfitLossMultipleInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoSubscribeRequest serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoSubscribeRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoSubscribeResponse value
   * @this {!Sender}
   * @param {!SymbolProfitLossMultipleInfoSubscribeResponse} value SymbolProfitLossMultipleInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoSubscribeResponse serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoSubscribeResponseModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoUpdateSubscriptionRequest value
   * @this {!Sender}
   * @param {!SymbolProfitLossMultipleInfoUpdateSubscriptionRequest} value SymbolProfitLossMultipleInfoUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoUnsubscribeRequest value
   * @this {!Sender}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeRequest} value SymbolProfitLossMultipleInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoUnsubscribeRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoUnsubscribeResponse value
   * @this {!Sender}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeResponse} value SymbolProfitLossMultipleInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoUnsubscribeResponseModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoNotify value
   * @this {!Sender}
   * @param {!SymbolProfitLossMultipleInfoNotify} value SymbolProfitLossMultipleInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoNotifyModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoNotify serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoNotifyModel.verify(), 'client.SymbolProfitLossMultipleInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithPasswordRequest value
   * @this {!Sender}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithPasswordRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithPasswordRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginWithPasswordRequest serialization failed!')
    console.assert(this.LoginWithPasswordRequestModel.verify(), 'client.LoginWithPasswordRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithPasswordResponse value
   * @this {!Sender}
   * @param {!LoginWithPasswordResponse} value LoginWithPasswordResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithPasswordResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithPasswordResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginWithPasswordResponse serialization failed!')
    console.assert(this.LoginWithPasswordResponseModel.verify(), 'client.LoginWithPasswordResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithTokenRequest value
   * @this {!Sender}
   * @param {!LoginWithTokenRequest} value LoginWithTokenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithTokenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithTokenRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginWithTokenRequest serialization failed!')
    console.assert(this.LoginWithTokenRequestModel.verify(), 'client.LoginWithTokenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithTokenResponse value
   * @this {!Sender}
   * @param {!LoginWithTokenResponse} value LoginWithTokenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithTokenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithTokenResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginWithTokenResponse serialization failed!')
    console.assert(this.LoginWithTokenResponseModel.verify(), 'client.LoginWithTokenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RefreshTokenRequest value
   * @this {!Sender}
   * @param {!RefreshTokenRequest} value RefreshTokenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_RefreshTokenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RefreshTokenRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.RefreshTokenRequest serialization failed!')
    console.assert(this.RefreshTokenRequestModel.verify(), 'client.RefreshTokenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RefreshTokenResponse value
   * @this {!Sender}
   * @param {!RefreshTokenResponse} value RefreshTokenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_RefreshTokenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RefreshTokenResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.RefreshTokenResponse serialization failed!')
    console.assert(this.RefreshTokenResponseModel.verify(), 'client.RefreshTokenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Logout2Request value
   * @this {!Sender}
   * @param {!Logout2Request} value Logout2Request value to send
   * @returns {!number} Sent bytes
   */
  send_Logout2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Logout2RequestModel.serialize(value)
    console.assert((serialized > 0), 'client.Logout2Request serialization failed!')
    console.assert(this.Logout2RequestModel.verify(), 'client.Logout2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Logout2Response value
   * @this {!Sender}
   * @param {!Logout2Response} value Logout2Response value to send
   * @returns {!number} Sent bytes
   */
  send_Logout2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Logout2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.Logout2Response serialization failed!')
    console.assert(this.Logout2ResponseModel.verify(), 'client.Logout2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountChangePasswordRequest value
   * @this {!Sender}
   * @param {!AccountChangePasswordRequest} value AccountChangePasswordRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountChangePasswordRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountChangePasswordRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountChangePasswordRequest serialization failed!')
    console.assert(this.AccountChangePasswordRequestModel.verify(), 'client.AccountChangePasswordRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountChangePasswordResponse value
   * @this {!Sender}
   * @param {!AccountChangePasswordResponse} value AccountChangePasswordResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountChangePasswordResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountChangePasswordResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountChangePasswordResponse serialization failed!')
    console.assert(this.AccountChangePasswordResponseModel.verify(), 'client.AccountChangePasswordResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PushNotificationTokenRegisterRequest value
   * @this {!Sender}
   * @param {!PushNotificationTokenRegisterRequest} value PushNotificationTokenRegisterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PushNotificationTokenRegisterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PushNotificationTokenRegisterRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.PushNotificationTokenRegisterRequest serialization failed!')
    console.assert(this.PushNotificationTokenRegisterRequestModel.verify(), 'client.PushNotificationTokenRegisterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PushNotificationTokenRegisterResponse value
   * @this {!Sender}
   * @param {!PushNotificationTokenRegisterResponse} value PushNotificationTokenRegisterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PushNotificationTokenRegisterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PushNotificationTokenRegisterResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.PushNotificationTokenRegisterResponse serialization failed!')
    console.assert(this.PushNotificationTokenRegisterResponseModel.verify(), 'client.PushNotificationTokenRegisterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DesiredAppSettingsGetRequest value
   * @this {!Sender}
   * @param {!DesiredAppSettingsGetRequest} value DesiredAppSettingsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DesiredAppSettingsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DesiredAppSettingsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.DesiredAppSettingsGetRequest serialization failed!')
    console.assert(this.DesiredAppSettingsGetRequestModel.verify(), 'client.DesiredAppSettingsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DesiredAppSettingsGetResponse value
   * @this {!Sender}
   * @param {!DesiredAppSettingsGetResponse} value DesiredAppSettingsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DesiredAppSettingsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DesiredAppSettingsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.DesiredAppSettingsGetResponse serialization failed!')
    console.assert(this.DesiredAppSettingsGetResponseModel.verify(), 'client.DesiredAppSettingsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AuthTokensGetAllRequest value
   * @this {!Sender}
   * @param {!AuthTokensGetAllRequest} value AuthTokensGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AuthTokensGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AuthTokensGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.AuthTokensGetAllRequest serialization failed!')
    console.assert(this.AuthTokensGetAllRequestModel.verify(), 'client.AuthTokensGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AuthTokensGetAllResponse value
   * @this {!Sender}
   * @param {!AuthTokensGetAllResponse} value AuthTokensGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AuthTokensGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AuthTokensGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.AuthTokensGetAllResponse serialization failed!')
    console.assert(this.AuthTokensGetAllResponseModel.verify(), 'client.AuthTokensGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AuthTokensDeleteRequest value
   * @this {!Sender}
   * @param {!AuthTokensDeleteRequest} value AuthTokensDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AuthTokensDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AuthTokensDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.AuthTokensDeleteRequest serialization failed!')
    console.assert(this.AuthTokensDeleteRequestModel.verify(), 'client.AuthTokensDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AuthTokensDeleteResponse value
   * @this {!Sender}
   * @param {!AuthTokensDeleteResponse} value AuthTokensDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AuthTokensDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AuthTokensDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.AuthTokensDeleteResponse serialization failed!')
    console.assert(this.AuthTokensDeleteResponseModel.verify(), 'client.AuthTokensDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupListRequest value
   * @this {!Sender}
   * @param {!LegalDocumentGroupListRequest} value LegalDocumentGroupListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupListRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.LegalDocumentGroupListRequest serialization failed!')
    console.assert(this.LegalDocumentGroupListRequestModel.verify(), 'client.LegalDocumentGroupListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupListResponse value
   * @this {!Sender}
   * @param {!LegalDocumentGroupListResponse} value LegalDocumentGroupListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupListResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.LegalDocumentGroupListResponse serialization failed!')
    console.assert(this.LegalDocumentGroupListResponseModel.verify(), 'client.LegalDocumentGroupListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetRequest value
   * @this {!Sender}
   * @param {!LegalDocumentGetRequest} value LegalDocumentGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.LegalDocumentGetRequest serialization failed!')
    console.assert(this.LegalDocumentGetRequestModel.verify(), 'client.LegalDocumentGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetResponse value
   * @this {!Sender}
   * @param {!LegalDocumentGetResponse} value LegalDocumentGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.LegalDocumentGetResponse serialization failed!')
    console.assert(this.LegalDocumentGetResponseModel.verify(), 'client.LegalDocumentGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PushNotificationTokenUnRegisterRequest value
   * @this {!Sender}
   * @param {!PushNotificationTokenUnRegisterRequest} value PushNotificationTokenUnRegisterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PushNotificationTokenUnRegisterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PushNotificationTokenUnRegisterRequestModel.serialize(value)
    console.assert((serialized > 0), 'client.PushNotificationTokenUnRegisterRequest serialization failed!')
    console.assert(this.PushNotificationTokenUnRegisterRequestModel.verify(), 'client.PushNotificationTokenUnRegisterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PushNotificationTokenUnRegisterResponse value
   * @this {!Sender}
   * @param {!PushNotificationTokenUnRegisterResponse} value PushNotificationTokenUnRegisterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PushNotificationTokenUnRegisterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PushNotificationTokenUnRegisterResponseModel.serialize(value)
    console.assert((serialized > 0), 'client.PushNotificationTokenUnRegisterResponse serialization failed!')
    console.assert(this.PushNotificationTokenUnRegisterResponseModel.verify(), 'client.PushNotificationTokenUnRegisterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'client.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
    this._exposedSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
    this._exposedSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding client receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize client receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._exposedReceiver = new exposed.Receiver(this.buffer)
    this._PingRequestValue = new PingRequest()
    this._PingRequestModel = new PingRequestModel()
    this._PongResponseValue = new PongResponse()
    this._PongResponseModel = new PongResponseModel()
    this._LoginRequestValue = new LoginRequest()
    this._LoginRequestModel = new LoginRequestModel()
    this._LoginResponseValue = new LoginResponse()
    this._LoginResponseModel = new LoginResponseModel()
    this._LoginRejectValue = new LoginReject()
    this._LoginRejectModel = new LoginRejectModel()
    this._LogoutRequestValue = new LogoutRequest()
    this._LogoutRequestModel = new LogoutRequestModel()
    this._LogoutResponseValue = new LogoutResponse()
    this._LogoutResponseModel = new LogoutResponseModel()
    this._LogoutNotifyValue = new LogoutNotify()
    this._LogoutNotifyModel = new LogoutNotifyModel()
    this._SessionInfoRequestValue = new SessionInfoRequest()
    this._SessionInfoRequestModel = new SessionInfoRequestModel()
    this._SessionInfoResponseValue = new SessionInfoResponse()
    this._SessionInfoResponseModel = new SessionInfoResponseModel()
    this._TimeInfoRequestValue = new TimeInfoRequest()
    this._TimeInfoRequestModel = new TimeInfoRequestModel()
    this._TimeInfoResponseValue = new TimeInfoResponse()
    this._TimeInfoResponseModel = new TimeInfoResponseModel()
    this._TimezoneInfoRequestValue = new TimezoneInfoRequest()
    this._TimezoneInfoRequestModel = new TimezoneInfoRequestModel()
    this._TimezoneInfoResponseValue = new TimezoneInfoResponse()
    this._TimezoneInfoResponseModel = new TimezoneInfoResponseModel()
    this._TimezoneInfoUpdateNotifyValue = new TimezoneInfoUpdateNotify()
    this._TimezoneInfoUpdateNotifyModel = new TimezoneInfoUpdateNotifyModel()
    this._CurrencyInfoRequestValue = new CurrencyInfoRequest()
    this._CurrencyInfoRequestModel = new CurrencyInfoRequestModel()
    this._CurrencyInfoResponseValue = new CurrencyInfoResponse()
    this._CurrencyInfoResponseModel = new CurrencyInfoResponseModel()
    this._CurrencyListRequestValue = new CurrencyListRequest()
    this._CurrencyListRequestModel = new CurrencyListRequestModel()
    this._CurrencyListResponseValue = new CurrencyListResponse()
    this._CurrencyListResponseModel = new CurrencyListResponseModel()
    this._CurrencyInfoUpdateNotifyValue = new CurrencyInfoUpdateNotify()
    this._CurrencyInfoUpdateNotifyModel = new CurrencyInfoUpdateNotifyModel()
    this._SymbolInfoRequestValue = new SymbolInfoRequest()
    this._SymbolInfoRequestModel = new SymbolInfoRequestModel()
    this._SymbolInfoResponseValue = new SymbolInfoResponse()
    this._SymbolInfoResponseModel = new SymbolInfoResponseModel()
    this._SymbolListRequestValue = new SymbolListRequest()
    this._SymbolListRequestModel = new SymbolListRequestModel()
    this._SymbolListResponseValue = new SymbolListResponse()
    this._SymbolListResponseModel = new SymbolListResponseModel()
    this._SymbolInfoUpdateNotifyValue = new SymbolInfoUpdateNotify()
    this._SymbolInfoUpdateNotifyModel = new SymbolInfoUpdateNotifyModel()
    this._TickInfoRequestValue = new TickInfoRequest()
    this._TickInfoRequestModel = new TickInfoRequestModel()
    this._TickInfoResponseValue = new TickInfoResponse()
    this._TickInfoResponseModel = new TickInfoResponseModel()
    this._TickFilterRequestValue = new TickFilterRequest()
    this._TickFilterRequestModel = new TickFilterRequestModel()
    this._TickFilterResponseValue = new TickFilterResponse()
    this._TickFilterResponseModel = new TickFilterResponseModel()
    this._TickListRequestValue = new TickListRequest()
    this._TickListRequestModel = new TickListRequestModel()
    this._TickListResponseValue = new TickListResponse()
    this._TickListResponseModel = new TickListResponseModel()
    this._Level2InfoRequestValue = new Level2InfoRequest()
    this._Level2InfoRequestModel = new Level2InfoRequestModel()
    this._Level2InfoResponseValue = new Level2InfoResponse()
    this._Level2InfoResponseModel = new Level2InfoResponseModel()
    this._Level2FilterRequestValue = new Level2FilterRequest()
    this._Level2FilterRequestModel = new Level2FilterRequestModel()
    this._Level2FilterResponseValue = new Level2FilterResponse()
    this._Level2FilterResponseModel = new Level2FilterResponseModel()
    this._Level2ListRequestValue = new Level2ListRequest()
    this._Level2ListRequestModel = new Level2ListRequestModel()
    this._Level2ListResponseValue = new Level2ListResponse()
    this._Level2ListResponseModel = new Level2ListResponseModel()
    this._FeedSubscriptionInfoRequestValue = new FeedSubscriptionInfoRequest()
    this._FeedSubscriptionInfoRequestModel = new FeedSubscriptionInfoRequestModel()
    this._FeedSubscriptionInfoResponseValue = new FeedSubscriptionInfoResponse()
    this._FeedSubscriptionInfoResponseModel = new FeedSubscriptionInfoResponseModel()
    this._FeedSubscribeRequestValue = new FeedSubscribeRequest()
    this._FeedSubscribeRequestModel = new FeedSubscribeRequestModel()
    this._FeedSubscribeAllRequestValue = new FeedSubscribeAllRequest()
    this._FeedSubscribeAllRequestModel = new FeedSubscribeAllRequestModel()
    this._FeedSubscribeResponseValue = new FeedSubscribeResponse()
    this._FeedSubscribeResponseModel = new FeedSubscribeResponseModel()
    this._FeedUnsubscribeRequestValue = new FeedUnsubscribeRequest()
    this._FeedUnsubscribeRequestModel = new FeedUnsubscribeRequestModel()
    this._FeedUnsubscribeAllRequestValue = new FeedUnsubscribeAllRequest()
    this._FeedUnsubscribeAllRequestModel = new FeedUnsubscribeAllRequestModel()
    this._FeedUnsubscribeResponseValue = new FeedUnsubscribeResponse()
    this._FeedUnsubscribeResponseModel = new FeedUnsubscribeResponseModel()
    this._TickSnapshotNotifyValue = new TickSnapshotNotify()
    this._TickSnapshotNotifyModel = new TickSnapshotNotifyModel()
    this._TickUpdateNotifyValue = new TickUpdateNotify()
    this._TickUpdateNotifyModel = new TickUpdateNotifyModel()
    this._Level2SnapshotNotifyValue = new Level2SnapshotNotify()
    this._Level2SnapshotNotifyModel = new Level2SnapshotNotifyModel()
    this._Level2UpdateNotifyValue = new Level2UpdateNotify()
    this._Level2UpdateNotifyModel = new Level2UpdateNotifyModel()
    this._ChartBarHistoryRequestValue = new ChartBarHistoryRequest()
    this._ChartBarHistoryRequestModel = new ChartBarHistoryRequestModel()
    this._ChartBarHistoryResponseValue = new ChartBarHistoryResponse()
    this._ChartBarHistoryResponseModel = new ChartBarHistoryResponseModel()
    this._ChartBarSubscribeRequestValue = new ChartBarSubscribeRequest()
    this._ChartBarSubscribeRequestModel = new ChartBarSubscribeRequestModel()
    this._ChartBarSubscribeResponseValue = new ChartBarSubscribeResponse()
    this._ChartBarSubscribeResponseModel = new ChartBarSubscribeResponseModel()
    this._ChartBarUnsubscribeRequestValue = new ChartBarUnsubscribeRequest()
    this._ChartBarUnsubscribeRequestModel = new ChartBarUnsubscribeRequestModel()
    this._ChartBarUnsubscribeResponseValue = new ChartBarUnsubscribeResponse()
    this._ChartBarUnsubscribeResponseModel = new ChartBarUnsubscribeResponseModel()
    this._ChartBarNotifyValue = new ChartBarNotify()
    this._ChartBarNotifyModel = new ChartBarNotifyModel()
    this._ChartBarUpdateNotifyValue = new ChartBarUpdateNotify()
    this._ChartBarUpdateNotifyModel = new ChartBarUpdateNotifyModel()
    this._PlatformInfoRequestValue = new PlatformInfoRequest()
    this._PlatformInfoRequestModel = new PlatformInfoRequestModel()
    this._PlatformInfoResponseValue = new PlatformInfoResponse()
    this._PlatformInfoResponseModel = new PlatformInfoResponseModel()
    this._PlatformInfoUpdateNotifyValue = new PlatformInfoUpdateNotify()
    this._PlatformInfoUpdateNotifyModel = new PlatformInfoUpdateNotifyModel()
    this._AccountInfoRequestValue = new AccountInfoRequest()
    this._AccountInfoRequestModel = new AccountInfoRequestModel()
    this._AccountInfoResponseValue = new AccountInfoResponse()
    this._AccountInfoResponseModel = new AccountInfoResponseModel()
    this._AccountInfoUpdateNotifyValue = new AccountInfoUpdateNotify()
    this._AccountInfoUpdateNotifyModel = new AccountInfoUpdateNotifyModel()
    this._MarginInfoRequestValue = new MarginInfoRequest()
    this._MarginInfoRequestModel = new MarginInfoRequestModel()
    this._MarginInfoResponseValue = new MarginInfoResponse()
    this._MarginInfoResponseModel = new MarginInfoResponseModel()
    this._MarginInfoUpdateNotifyValue = new MarginInfoUpdateNotify()
    this._MarginInfoUpdateNotifyModel = new MarginInfoUpdateNotifyModel()
    this._MarginCallNotifyValue = new MarginCallNotify()
    this._MarginCallNotifyModel = new MarginCallNotifyModel()
    this._StopOutNotifyValue = new StopOutNotify()
    this._StopOutNotifyModel = new StopOutNotifyModel()
    this._AssetInfoRequestValue = new AssetInfoRequest()
    this._AssetInfoRequestModel = new AssetInfoRequestModel()
    this._AssetInfoResponseValue = new AssetInfoResponse()
    this._AssetInfoResponseModel = new AssetInfoResponseModel()
    this._AssetListRequestValue = new AssetListRequest()
    this._AssetListRequestModel = new AssetListRequestModel()
    this._AssetListResponseValue = new AssetListResponse()
    this._AssetListResponseModel = new AssetListResponseModel()
    this._AssetInfoUpdateNotifyValue = new AssetInfoUpdateNotify()
    this._AssetInfoUpdateNotifyModel = new AssetInfoUpdateNotifyModel()
    this._OrderInfoRequestValue = new OrderInfoRequest()
    this._OrderInfoRequestModel = new OrderInfoRequestModel()
    this._OrderInfoResponseValue = new OrderInfoResponse()
    this._OrderInfoResponseModel = new OrderInfoResponseModel()
    this._OrderListRequestValue = new OrderListRequest()
    this._OrderListRequestModel = new OrderListRequestModel()
    this._OrderListResponseValue = new OrderListResponse()
    this._OrderListResponseModel = new OrderListResponseModel()
    this._OrderOpenRequestValue = new OrderOpenRequest()
    this._OrderOpenRequestModel = new OrderOpenRequestModel()
    this._OrderOpenResponseValue = new OrderOpenResponse()
    this._OrderOpenResponseModel = new OrderOpenResponseModel()
    this._OrderUpdateRequestValue = new OrderUpdateRequest()
    this._OrderUpdateRequestModel = new OrderUpdateRequestModel()
    this._OrderUpdateResponseValue = new OrderUpdateResponse()
    this._OrderUpdateResponseModel = new OrderUpdateResponseModel()
    this._OrderCancelRequestValue = new OrderCancelRequest()
    this._OrderCancelRequestModel = new OrderCancelRequestModel()
    this._OrderCancelResponseValue = new OrderCancelResponse()
    this._OrderCancelResponseModel = new OrderCancelResponseModel()
    this._OrderCancelAllRequestValue = new OrderCancelAllRequest()
    this._OrderCancelAllRequestModel = new OrderCancelAllRequestModel()
    this._OrderCancelAllResponseValue = new OrderCancelAllResponse()
    this._OrderCancelAllResponseModel = new OrderCancelAllResponseModel()
    this._OrderCancelAllByIdRequestValue = new OrderCancelAllByIdRequest()
    this._OrderCancelAllByIdRequestModel = new OrderCancelAllByIdRequestModel()
    this._OrderCloseRequestValue = new OrderCloseRequest()
    this._OrderCloseRequestModel = new OrderCloseRequestModel()
    this._OrderCloseResponseValue = new OrderCloseResponse()
    this._OrderCloseResponseModel = new OrderCloseResponseModel()
    this._OrderCloseAllRequestValue = new OrderCloseAllRequest()
    this._OrderCloseAllRequestModel = new OrderCloseAllRequestModel()
    this._OrderCloseAllResponseValue = new OrderCloseAllResponse()
    this._OrderCloseAllResponseModel = new OrderCloseAllResponseModel()
    this._OrderCloseAllByIdRequestValue = new OrderCloseAllByIdRequest()
    this._OrderCloseAllByIdRequestModel = new OrderCloseAllByIdRequestModel()
    this._OrderCloseByRequestValue = new OrderCloseByRequest()
    this._OrderCloseByRequestModel = new OrderCloseByRequestModel()
    this._OrderCloseByResponseValue = new OrderCloseByResponse()
    this._OrderCloseByResponseModel = new OrderCloseByResponseModel()
    this._OrderCloseByAllRequestValue = new OrderCloseByAllRequest()
    this._OrderCloseByAllRequestModel = new OrderCloseByAllRequestModel()
    this._OrderCloseByAllResponseValue = new OrderCloseByAllResponse()
    this._OrderCloseByAllResponseModel = new OrderCloseByAllResponseModel()
    this._OrderCancelOrCloseRequestValue = new OrderCancelOrCloseRequest()
    this._OrderCancelOrCloseRequestModel = new OrderCancelOrCloseRequestModel()
    this._OrderCancelOrCloseResponseValue = new OrderCancelOrCloseResponse()
    this._OrderCancelOrCloseResponseModel = new OrderCancelOrCloseResponseModel()
    this._OrderCancelOrCloseAllRequestValue = new OrderCancelOrCloseAllRequest()
    this._OrderCancelOrCloseAllRequestModel = new OrderCancelOrCloseAllRequestModel()
    this._OrderCancelOrCloseAllResponseValue = new OrderCancelOrCloseAllResponse()
    this._OrderCancelOrCloseAllResponseModel = new OrderCancelOrCloseAllResponseModel()
    this._OrderInfoUpdateNotifyValue = new OrderInfoUpdateNotify()
    this._OrderInfoUpdateNotifyModel = new OrderInfoUpdateNotifyModel()
    this._PositionInfoRequestValue = new PositionInfoRequest()
    this._PositionInfoRequestModel = new PositionInfoRequestModel()
    this._PositionInfoResponseValue = new PositionInfoResponse()
    this._PositionInfoResponseModel = new PositionInfoResponseModel()
    this._PositionListRequestValue = new PositionListRequest()
    this._PositionListRequestModel = new PositionListRequestModel()
    this._PositionListResponseValue = new PositionListResponse()
    this._PositionListResponseModel = new PositionListResponseModel()
    this._PositionInfoUpdateNotifyValue = new PositionInfoUpdateNotify()
    this._PositionInfoUpdateNotifyModel = new PositionInfoUpdateNotifyModel()
    this._TradeHistoryInfoRequestValue = new TradeHistoryInfoRequest()
    this._TradeHistoryInfoRequestModel = new TradeHistoryInfoRequestModel()
    this._TradeHistoryInfoResponseValue = new TradeHistoryInfoResponse()
    this._TradeHistoryInfoResponseModel = new TradeHistoryInfoResponseModel()
    this._TradeHistoryInfoFilterRequestValue = new TradeHistoryInfoFilterRequest()
    this._TradeHistoryInfoFilterRequestModel = new TradeHistoryInfoFilterRequestModel()
    this._TradeHistoryInfoByIdRequestValue = new TradeHistoryInfoByIdRequest()
    this._TradeHistoryInfoByIdRequestModel = new TradeHistoryInfoByIdRequestModel()
    this._TradeHistoryInfoByIdResponseValue = new TradeHistoryInfoByIdResponse()
    this._TradeHistoryInfoByIdResponseModel = new TradeHistoryInfoByIdResponseModel()
    this._TradeHistoryInfoUpdateNotifyValue = new TradeHistoryInfoUpdateNotify()
    this._TradeHistoryInfoUpdateNotifyModel = new TradeHistoryInfoUpdateNotifyModel()
    this._CalendarRegularRuleGetRequestValue = new CalendarRegularRuleGetRequest()
    this._CalendarRegularRuleGetRequestModel = new CalendarRegularRuleGetRequestModel()
    this._CalendarRegularRuleGetResponseValue = new CalendarRegularRuleGetResponse()
    this._CalendarRegularRuleGetResponseModel = new CalendarRegularRuleGetResponseModel()
    this._CalendarRegularRuleUpdateNotifyValue = new CalendarRegularRuleUpdateNotify()
    this._CalendarRegularRuleUpdateNotifyModel = new CalendarRegularRuleUpdateNotifyModel()
    this._CalendarOffTimeRuleGetRequestValue = new CalendarOffTimeRuleGetRequest()
    this._CalendarOffTimeRuleGetRequestModel = new CalendarOffTimeRuleGetRequestModel()
    this._CalendarOffTimeRuleGetResponseValue = new CalendarOffTimeRuleGetResponse()
    this._CalendarOffTimeRuleGetResponseModel = new CalendarOffTimeRuleGetResponseModel()
    this._CalendarOffTimeRuleUpdateNotifyValue = new CalendarOffTimeRuleUpdateNotify()
    this._CalendarOffTimeRuleUpdateNotifyModel = new CalendarOffTimeRuleUpdateNotifyModel()
    this._CalendarRulesGetRequestValue = new CalendarRulesGetRequest()
    this._CalendarRulesGetRequestModel = new CalendarRulesGetRequestModel()
    this._CalendarRulesGetResponseValue = new CalendarRulesGetResponse()
    this._CalendarRulesGetResponseModel = new CalendarRulesGetResponseModel()
    this._ChartBarHistoryRangeRequestValue = new ChartBarHistoryRangeRequest()
    this._ChartBarHistoryRangeRequestModel = new ChartBarHistoryRangeRequestModel()
    this._OrderMarginSubscribeRequestValue = new OrderMarginSubscribeRequest()
    this._OrderMarginSubscribeRequestModel = new OrderMarginSubscribeRequestModel()
    this._OrderMarginSubscribeResponseValue = new OrderMarginSubscribeResponse()
    this._OrderMarginSubscribeResponseModel = new OrderMarginSubscribeResponseModel()
    this._OrderMarginNotifyValue = new OrderMarginNotify()
    this._OrderMarginNotifyModel = new OrderMarginNotifyModel()
    this._OrderMarginUpdateSubscriptionRequestValue = new OrderMarginUpdateSubscriptionRequest()
    this._OrderMarginUpdateSubscriptionRequestModel = new OrderMarginUpdateSubscriptionRequestModel()
    this._OrderMarginUnsubscribeRequestValue = new OrderMarginUnsubscribeRequest()
    this._OrderMarginUnsubscribeRequestModel = new OrderMarginUnsubscribeRequestModel()
    this._OrderMarginUnsubscribeResponseValue = new OrderMarginUnsubscribeResponse()
    this._OrderMarginUnsubscribeResponseModel = new OrderMarginUnsubscribeResponseModel()
    this._OrderMarginGetRequestValue = new OrderMarginGetRequest()
    this._OrderMarginGetRequestModel = new OrderMarginGetRequestModel()
    this._OrderMarginGetResponseValue = new OrderMarginGetResponse()
    this._OrderMarginGetResponseModel = new OrderMarginGetResponseModel()
    this._OrderStateInfoSubscribeRequestValue = new OrderStateInfoSubscribeRequest()
    this._OrderStateInfoSubscribeRequestModel = new OrderStateInfoSubscribeRequestModel()
    this._OrderStateInfoSubscribeResponseValue = new OrderStateInfoSubscribeResponse()
    this._OrderStateInfoSubscribeResponseModel = new OrderStateInfoSubscribeResponseModel()
    this._OrderStateInfoUnsubscribeRequestValue = new OrderStateInfoUnsubscribeRequest()
    this._OrderStateInfoUnsubscribeRequestModel = new OrderStateInfoUnsubscribeRequestModel()
    this._OrderStateInfoUnsubscribeResponseValue = new OrderStateInfoUnsubscribeResponse()
    this._OrderStateInfoUnsubscribeResponseModel = new OrderStateInfoUnsubscribeResponseModel()
    this._OrderStateInfoNotifyValue = new OrderStateInfoNotify()
    this._OrderStateInfoNotifyModel = new OrderStateInfoNotifyModel()
    this._Deprecated003Value = new Deprecated003()
    this._Deprecated003Model = new Deprecated003Model()
    this._Deprecated004Value = new Deprecated004()
    this._Deprecated004Model = new Deprecated004Model()
    this._Deprecated005Value = new Deprecated005()
    this._Deprecated005Model = new Deprecated005Model()
    this._Deprecated006Value = new Deprecated006()
    this._Deprecated006Model = new Deprecated006Model()
    this._Deprecated007Value = new Deprecated007()
    this._Deprecated007Model = new Deprecated007Model()
    this._Deprecated008Value = new Deprecated008()
    this._Deprecated008Model = new Deprecated008Model()
    this._AccountUpdateRequestValue = new AccountUpdateRequest()
    this._AccountUpdateRequestModel = new AccountUpdateRequestModel()
    this._AccountUpdateResponseValue = new AccountUpdateResponse()
    this._AccountUpdateResponseModel = new AccountUpdateResponseModel()
    this._ProfitClosedInfoGetRequestValue = new ProfitClosedInfoGetRequest()
    this._ProfitClosedInfoGetRequestModel = new ProfitClosedInfoGetRequestModel()
    this._ProfitClosedInfoGetResponseValue = new ProfitClosedInfoGetResponse()
    this._ProfitClosedInfoGetResponseModel = new ProfitClosedInfoGetResponseModel()
    this._SymbolDayProfitLossInfoSubscribeRequestValue = new SymbolDayProfitLossInfoSubscribeRequest()
    this._SymbolDayProfitLossInfoSubscribeRequestModel = new SymbolDayProfitLossInfoSubscribeRequestModel()
    this._SymbolDayProfitLossInfoSubscribeResponseValue = new SymbolDayProfitLossInfoSubscribeResponse()
    this._SymbolDayProfitLossInfoSubscribeResponseModel = new SymbolDayProfitLossInfoSubscribeResponseModel()
    this._SymbolDayProfitLossInfoUpdateSubscriptionRequestValue = new SymbolDayProfitLossInfoUpdateSubscriptionRequest()
    this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel = new SymbolDayProfitLossInfoUpdateSubscriptionRequestModel()
    this._SymbolDayProfitLossInfoUnsubscribeRequestValue = new SymbolDayProfitLossInfoUnsubscribeRequest()
    this._SymbolDayProfitLossInfoUnsubscribeRequestModel = new SymbolDayProfitLossInfoUnsubscribeRequestModel()
    this._SymbolDayProfitLossInfoUnsubscribeResponseValue = new SymbolDayProfitLossInfoUnsubscribeResponse()
    this._SymbolDayProfitLossInfoUnsubscribeResponseModel = new SymbolDayProfitLossInfoUnsubscribeResponseModel()
    this._SymbolDayProfitLossInfoNotifyValue = new SymbolDayProfitLossInfoNotify()
    this._SymbolDayProfitLossInfoNotifyModel = new SymbolDayProfitLossInfoNotifyModel()
    this._LoginInfoGetRequestValue = new LoginInfoGetRequest()
    this._LoginInfoGetRequestModel = new LoginInfoGetRequestModel()
    this._LoginInfoGetResponseValue = new LoginInfoGetResponse()
    this._LoginInfoGetResponseModel = new LoginInfoGetResponseModel()
    this._DashboardInfoCreateRequestValue = new DashboardInfoCreateRequest()
    this._DashboardInfoCreateRequestModel = new DashboardInfoCreateRequestModel()
    this._DashboardInfoCreateResponseValue = new DashboardInfoCreateResponse()
    this._DashboardInfoCreateResponseModel = new DashboardInfoCreateResponseModel()
    this._DashboardInfoUpdateRequestValue = new DashboardInfoUpdateRequest()
    this._DashboardInfoUpdateRequestModel = new DashboardInfoUpdateRequestModel()
    this._DashboardInfoUpdateResponseValue = new DashboardInfoUpdateResponse()
    this._DashboardInfoUpdateResponseModel = new DashboardInfoUpdateResponseModel()
    this._DashboardInfoListRequestValue = new DashboardInfoListRequest()
    this._DashboardInfoListRequestModel = new DashboardInfoListRequestModel()
    this._DashboardInfoListResponseValue = new DashboardInfoListResponse()
    this._DashboardInfoListResponseModel = new DashboardInfoListResponseModel()
    this._DashboardInfoGetRequestValue = new DashboardInfoGetRequest()
    this._DashboardInfoGetRequestModel = new DashboardInfoGetRequestModel()
    this._DashboardInfoGetResponseValue = new DashboardInfoGetResponse()
    this._DashboardInfoGetResponseModel = new DashboardInfoGetResponseModel()
    this._DashboardInfoRenameRequestValue = new DashboardInfoRenameRequest()
    this._DashboardInfoRenameRequestModel = new DashboardInfoRenameRequestModel()
    this._DashboardInfoRenameResponseValue = new DashboardInfoRenameResponse()
    this._DashboardInfoRenameResponseModel = new DashboardInfoRenameResponseModel()
    this._DashboardInfoDeleteRequestValue = new DashboardInfoDeleteRequest()
    this._DashboardInfoDeleteRequestModel = new DashboardInfoDeleteRequestModel()
    this._DashboardInfoDeleteResponseValue = new DashboardInfoDeleteResponse()
    this._DashboardInfoDeleteResponseModel = new DashboardInfoDeleteResponseModel()
    this._ChartInfoCreateRequestValue = new ChartInfoCreateRequest()
    this._ChartInfoCreateRequestModel = new ChartInfoCreateRequestModel()
    this._ChartInfoCreateResponseValue = new ChartInfoCreateResponse()
    this._ChartInfoCreateResponseModel = new ChartInfoCreateResponseModel()
    this._ChartInfoUpdateRequestValue = new ChartInfoUpdateRequest()
    this._ChartInfoUpdateRequestModel = new ChartInfoUpdateRequestModel()
    this._ChartInfoUpdateResponseValue = new ChartInfoUpdateResponse()
    this._ChartInfoUpdateResponseModel = new ChartInfoUpdateResponseModel()
    this._ChartInfoListRequestValue = new ChartInfoListRequest()
    this._ChartInfoListRequestModel = new ChartInfoListRequestModel()
    this._ChartInfoListResponseValue = new ChartInfoListResponse()
    this._ChartInfoListResponseModel = new ChartInfoListResponseModel()
    this._ChartInfoDeleteRequestValue = new ChartInfoDeleteRequest()
    this._ChartInfoDeleteRequestModel = new ChartInfoDeleteRequestModel()
    this._ChartInfoDeleteResponseValue = new ChartInfoDeleteResponse()
    this._ChartInfoDeleteResponseModel = new ChartInfoDeleteResponseModel()
    this._ChartInfoGetRequestValue = new ChartInfoGetRequest()
    this._ChartInfoGetRequestModel = new ChartInfoGetRequestModel()
    this._ChartInfoGetResponseValue = new ChartInfoGetResponse()
    this._ChartInfoGetResponseModel = new ChartInfoGetResponseModel()
    this._SymbolDayProfitLossMultipleInfoSubscribeRequestValue = new SymbolDayProfitLossMultipleInfoSubscribeRequest()
    this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel = new SymbolDayProfitLossMultipleInfoSubscribeRequestModel()
    this._SymbolDayProfitLossMultipleInfoSubscribeResponseValue = new SymbolDayProfitLossMultipleInfoSubscribeResponse()
    this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel = new SymbolDayProfitLossMultipleInfoSubscribeResponseModel()
    this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestValue = new SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest()
    this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel = new SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestValue = new SymbolDayProfitLossMultipleInfoUnsubscribeRequest()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel = new SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseValue = new SymbolDayProfitLossMultipleInfoUnsubscribeResponse()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel = new SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel()
    this._SymbolDayProfitLossMultipleInfoNotifyValue = new SymbolDayProfitLossMultipleInfoNotify()
    this._SymbolDayProfitLossMultipleInfoNotifyModel = new SymbolDayProfitLossMultipleInfoNotifyModel()
    this._NewsServiceGetAllRequestValue = new NewsServiceGetAllRequest()
    this._NewsServiceGetAllRequestModel = new NewsServiceGetAllRequestModel()
    this._NewsServiceGetAllResponseValue = new NewsServiceGetAllResponse()
    this._NewsServiceGetAllResponseModel = new NewsServiceGetAllResponseModel()
    this._NewsRecentArticlesGetRequestValue = new NewsRecentArticlesGetRequest()
    this._NewsRecentArticlesGetRequestModel = new NewsRecentArticlesGetRequestModel()
    this._NewsRecentArticlesGetResponseValue = new NewsRecentArticlesGetResponse()
    this._NewsRecentArticlesGetResponseModel = new NewsRecentArticlesGetResponseModel()
    this._NewsArticleGetRequestValue = new NewsArticleGetRequest()
    this._NewsArticleGetRequestModel = new NewsArticleGetRequestModel()
    this._NewsArticleGetResponseValue = new NewsArticleGetResponse()
    this._NewsArticleGetResponseModel = new NewsArticleGetResponseModel()
    this._OrderUpdateWithTypeRequestValue = new OrderUpdateWithTypeRequest()
    this._OrderUpdateWithTypeRequestModel = new OrderUpdateWithTypeRequestModel()
    this._OrderUpdateWithTypeResponseValue = new OrderUpdateWithTypeResponse()
    this._OrderUpdateWithTypeResponseModel = new OrderUpdateWithTypeResponseModel()
    this._SymbolProfitLossMultipleInfoSubscribeRequestValue = new SymbolProfitLossMultipleInfoSubscribeRequest()
    this._SymbolProfitLossMultipleInfoSubscribeRequestModel = new SymbolProfitLossMultipleInfoSubscribeRequestModel()
    this._SymbolProfitLossMultipleInfoSubscribeResponseValue = new SymbolProfitLossMultipleInfoSubscribeResponse()
    this._SymbolProfitLossMultipleInfoSubscribeResponseModel = new SymbolProfitLossMultipleInfoSubscribeResponseModel()
    this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestValue = new SymbolProfitLossMultipleInfoUpdateSubscriptionRequest()
    this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel = new SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel()
    this._SymbolProfitLossMultipleInfoUnsubscribeRequestValue = new SymbolProfitLossMultipleInfoUnsubscribeRequest()
    this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel = new SymbolProfitLossMultipleInfoUnsubscribeRequestModel()
    this._SymbolProfitLossMultipleInfoUnsubscribeResponseValue = new SymbolProfitLossMultipleInfoUnsubscribeResponse()
    this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel = new SymbolProfitLossMultipleInfoUnsubscribeResponseModel()
    this._SymbolProfitLossMultipleInfoNotifyValue = new SymbolProfitLossMultipleInfoNotify()
    this._SymbolProfitLossMultipleInfoNotifyModel = new SymbolProfitLossMultipleInfoNotifyModel()
    this._LoginWithPasswordRequestValue = new LoginWithPasswordRequest()
    this._LoginWithPasswordRequestModel = new LoginWithPasswordRequestModel()
    this._LoginWithPasswordResponseValue = new LoginWithPasswordResponse()
    this._LoginWithPasswordResponseModel = new LoginWithPasswordResponseModel()
    this._LoginWithTokenRequestValue = new LoginWithTokenRequest()
    this._LoginWithTokenRequestModel = new LoginWithTokenRequestModel()
    this._LoginWithTokenResponseValue = new LoginWithTokenResponse()
    this._LoginWithTokenResponseModel = new LoginWithTokenResponseModel()
    this._RefreshTokenRequestValue = new RefreshTokenRequest()
    this._RefreshTokenRequestModel = new RefreshTokenRequestModel()
    this._RefreshTokenResponseValue = new RefreshTokenResponse()
    this._RefreshTokenResponseModel = new RefreshTokenResponseModel()
    this._Logout2RequestValue = new Logout2Request()
    this._Logout2RequestModel = new Logout2RequestModel()
    this._Logout2ResponseValue = new Logout2Response()
    this._Logout2ResponseModel = new Logout2ResponseModel()
    this._AccountChangePasswordRequestValue = new AccountChangePasswordRequest()
    this._AccountChangePasswordRequestModel = new AccountChangePasswordRequestModel()
    this._AccountChangePasswordResponseValue = new AccountChangePasswordResponse()
    this._AccountChangePasswordResponseModel = new AccountChangePasswordResponseModel()
    this._PushNotificationTokenRegisterRequestValue = new PushNotificationTokenRegisterRequest()
    this._PushNotificationTokenRegisterRequestModel = new PushNotificationTokenRegisterRequestModel()
    this._PushNotificationTokenRegisterResponseValue = new PushNotificationTokenRegisterResponse()
    this._PushNotificationTokenRegisterResponseModel = new PushNotificationTokenRegisterResponseModel()
    this._DesiredAppSettingsGetRequestValue = new DesiredAppSettingsGetRequest()
    this._DesiredAppSettingsGetRequestModel = new DesiredAppSettingsGetRequestModel()
    this._DesiredAppSettingsGetResponseValue = new DesiredAppSettingsGetResponse()
    this._DesiredAppSettingsGetResponseModel = new DesiredAppSettingsGetResponseModel()
    this._AuthTokensGetAllRequestValue = new AuthTokensGetAllRequest()
    this._AuthTokensGetAllRequestModel = new AuthTokensGetAllRequestModel()
    this._AuthTokensGetAllResponseValue = new AuthTokensGetAllResponse()
    this._AuthTokensGetAllResponseModel = new AuthTokensGetAllResponseModel()
    this._AuthTokensDeleteRequestValue = new AuthTokensDeleteRequest()
    this._AuthTokensDeleteRequestModel = new AuthTokensDeleteRequestModel()
    this._AuthTokensDeleteResponseValue = new AuthTokensDeleteResponse()
    this._AuthTokensDeleteResponseModel = new AuthTokensDeleteResponseModel()
    this._LegalDocumentGroupListRequestValue = new LegalDocumentGroupListRequest()
    this._LegalDocumentGroupListRequestModel = new LegalDocumentGroupListRequestModel()
    this._LegalDocumentGroupListResponseValue = new LegalDocumentGroupListResponse()
    this._LegalDocumentGroupListResponseModel = new LegalDocumentGroupListResponseModel()
    this._LegalDocumentGetRequestValue = new LegalDocumentGetRequest()
    this._LegalDocumentGetRequestModel = new LegalDocumentGetRequestModel()
    this._LegalDocumentGetResponseValue = new LegalDocumentGetResponse()
    this._LegalDocumentGetResponseModel = new LegalDocumentGetResponseModel()
    this._PushNotificationTokenUnRegisterRequestValue = new PushNotificationTokenUnRegisterRequest()
    this._PushNotificationTokenUnRegisterRequestModel = new PushNotificationTokenUnRegisterRequestModel()
    this._PushNotificationTokenUnRegisterResponseValue = new PushNotificationTokenUnRegisterResponse()
    this._PushNotificationTokenUnRegisterResponseModel = new PushNotificationTokenUnRegisterResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  /**
   * Get imported exposed receiver
   * @this {!Receiver}
   * @returns {Receiver} exposed receiver
   */
  get exposedReceiver () {
    return this._exposedReceiver
  }

  /**
   * Set imported exposed receiver
   * @this {!Receiver}
   * @param {Receiver} receiver exposed receiver
   */
  set exposedReceiver (receiver) {
    this._exposedReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * PingRequest receive handler
   * @this {!Receiver}
   * @param {!PingRequest} value PingRequest received value
   */
  onReceive_PingRequest (value) {}  // eslint-disable-line

  /**
   * PongResponse receive handler
   * @this {!Receiver}
   * @param {!PongResponse} value PongResponse received value
   */
  onReceive_PongResponse (value) {}  // eslint-disable-line

  /**
   * LoginRequest receive handler
   * @this {!Receiver}
   * @param {!LoginRequest} value LoginRequest received value
   */
  onReceive_LoginRequest (value) {}  // eslint-disable-line

  /**
   * LoginResponse receive handler
   * @this {!Receiver}
   * @param {!LoginResponse} value LoginResponse received value
   */
  onReceive_LoginResponse (value) {}  // eslint-disable-line

  /**
   * LoginReject receive handler
   * @this {!Receiver}
   * @param {!LoginReject} value LoginReject received value
   */
  onReceive_LoginReject (value) {}  // eslint-disable-line

  /**
   * LogoutRequest receive handler
   * @this {!Receiver}
   * @param {!LogoutRequest} value LogoutRequest received value
   */
  onReceive_LogoutRequest (value) {}  // eslint-disable-line

  /**
   * LogoutResponse receive handler
   * @this {!Receiver}
   * @param {!LogoutResponse} value LogoutResponse received value
   */
  onReceive_LogoutResponse (value) {}  // eslint-disable-line

  /**
   * LogoutNotify receive handler
   * @this {!Receiver}
   * @param {!LogoutNotify} value LogoutNotify received value
   */
  onReceive_LogoutNotify (value) {}  // eslint-disable-line

  /**
   * SessionInfoRequest receive handler
   * @this {!Receiver}
   * @param {!SessionInfoRequest} value SessionInfoRequest received value
   */
  onReceive_SessionInfoRequest (value) {}  // eslint-disable-line

  /**
   * SessionInfoResponse receive handler
   * @this {!Receiver}
   * @param {!SessionInfoResponse} value SessionInfoResponse received value
   */
  onReceive_SessionInfoResponse (value) {}  // eslint-disable-line

  /**
   * TimeInfoRequest receive handler
   * @this {!Receiver}
   * @param {!TimeInfoRequest} value TimeInfoRequest received value
   */
  onReceive_TimeInfoRequest (value) {}  // eslint-disable-line

  /**
   * TimeInfoResponse receive handler
   * @this {!Receiver}
   * @param {!TimeInfoResponse} value TimeInfoResponse received value
   */
  onReceive_TimeInfoResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneInfoRequest receive handler
   * @this {!Receiver}
   * @param {!TimezoneInfoRequest} value TimezoneInfoRequest received value
   */
  onReceive_TimezoneInfoRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneInfoResponse receive handler
   * @this {!Receiver}
   * @param {!TimezoneInfoResponse} value TimezoneInfoResponse received value
   */
  onReceive_TimezoneInfoResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TimezoneInfoUpdateNotify} value TimezoneInfoUpdateNotify received value
   */
  onReceive_TimezoneInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyInfoRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyInfoRequest} value CurrencyInfoRequest received value
   */
  onReceive_CurrencyInfoRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyInfoResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyInfoResponse} value CurrencyInfoResponse received value
   */
  onReceive_CurrencyInfoResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyListRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyListRequest} value CurrencyListRequest received value
   */
  onReceive_CurrencyListRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyListResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyListResponse} value CurrencyListResponse received value
   */
  onReceive_CurrencyListResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!CurrencyInfoUpdateNotify} value CurrencyInfoUpdateNotify received value
   */
  onReceive_CurrencyInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolInfoRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolInfoRequest} value SymbolInfoRequest received value
   */
  onReceive_SymbolInfoRequest (value) {}  // eslint-disable-line

  /**
   * SymbolInfoResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolInfoResponse} value SymbolInfoResponse received value
   */
  onReceive_SymbolInfoResponse (value) {}  // eslint-disable-line

  /**
   * SymbolListRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolListRequest} value SymbolListRequest received value
   */
  onReceive_SymbolListRequest (value) {}  // eslint-disable-line

  /**
   * SymbolListResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolListResponse} value SymbolListResponse received value
   */
  onReceive_SymbolListResponse (value) {}  // eslint-disable-line

  /**
   * SymbolInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolInfoUpdateNotify} value SymbolInfoUpdateNotify received value
   */
  onReceive_SymbolInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TickInfoRequest receive handler
   * @this {!Receiver}
   * @param {!TickInfoRequest} value TickInfoRequest received value
   */
  onReceive_TickInfoRequest (value) {}  // eslint-disable-line

  /**
   * TickInfoResponse receive handler
   * @this {!Receiver}
   * @param {!TickInfoResponse} value TickInfoResponse received value
   */
  onReceive_TickInfoResponse (value) {}  // eslint-disable-line

  /**
   * TickFilterRequest receive handler
   * @this {!Receiver}
   * @param {!TickFilterRequest} value TickFilterRequest received value
   */
  onReceive_TickFilterRequest (value) {}  // eslint-disable-line

  /**
   * TickFilterResponse receive handler
   * @this {!Receiver}
   * @param {!TickFilterResponse} value TickFilterResponse received value
   */
  onReceive_TickFilterResponse (value) {}  // eslint-disable-line

  /**
   * TickListRequest receive handler
   * @this {!Receiver}
   * @param {!TickListRequest} value TickListRequest received value
   */
  onReceive_TickListRequest (value) {}  // eslint-disable-line

  /**
   * TickListResponse receive handler
   * @this {!Receiver}
   * @param {!TickListResponse} value TickListResponse received value
   */
  onReceive_TickListResponse (value) {}  // eslint-disable-line

  /**
   * Level2InfoRequest receive handler
   * @this {!Receiver}
   * @param {!Level2InfoRequest} value Level2InfoRequest received value
   */
  onReceive_Level2InfoRequest (value) {}  // eslint-disable-line

  /**
   * Level2InfoResponse receive handler
   * @this {!Receiver}
   * @param {!Level2InfoResponse} value Level2InfoResponse received value
   */
  onReceive_Level2InfoResponse (value) {}  // eslint-disable-line

  /**
   * Level2FilterRequest receive handler
   * @this {!Receiver}
   * @param {!Level2FilterRequest} value Level2FilterRequest received value
   */
  onReceive_Level2FilterRequest (value) {}  // eslint-disable-line

  /**
   * Level2FilterResponse receive handler
   * @this {!Receiver}
   * @param {!Level2FilterResponse} value Level2FilterResponse received value
   */
  onReceive_Level2FilterResponse (value) {}  // eslint-disable-line

  /**
   * Level2ListRequest receive handler
   * @this {!Receiver}
   * @param {!Level2ListRequest} value Level2ListRequest received value
   */
  onReceive_Level2ListRequest (value) {}  // eslint-disable-line

  /**
   * Level2ListResponse receive handler
   * @this {!Receiver}
   * @param {!Level2ListResponse} value Level2ListResponse received value
   */
  onReceive_Level2ListResponse (value) {}  // eslint-disable-line

  /**
   * FeedSubscriptionInfoRequest receive handler
   * @this {!Receiver}
   * @param {!FeedSubscriptionInfoRequest} value FeedSubscriptionInfoRequest received value
   */
  onReceive_FeedSubscriptionInfoRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscriptionInfoResponse receive handler
   * @this {!Receiver}
   * @param {!FeedSubscriptionInfoResponse} value FeedSubscriptionInfoResponse received value
   */
  onReceive_FeedSubscriptionInfoResponse (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!FeedSubscribeRequest} value FeedSubscribeRequest received value
   */
  onReceive_FeedSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeAllRequest receive handler
   * @this {!Receiver}
   * @param {!FeedSubscribeAllRequest} value FeedSubscribeAllRequest received value
   */
  onReceive_FeedSubscribeAllRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!FeedSubscribeResponse} value FeedSubscribeResponse received value
   */
  onReceive_FeedSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUnsubscribeRequest} value FeedUnsubscribeRequest received value
   */
  onReceive_FeedUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeAllRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUnsubscribeAllRequest} value FeedUnsubscribeAllRequest received value
   */
  onReceive_FeedUnsubscribeAllRequest (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!FeedUnsubscribeResponse} value FeedUnsubscribeResponse received value
   */
  onReceive_FeedUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * TickSnapshotNotify receive handler
   * @this {!Receiver}
   * @param {!TickSnapshotNotify} value TickSnapshotNotify received value
   */
  onReceive_TickSnapshotNotify (value) {}  // eslint-disable-line

  /**
   * TickUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TickUpdateNotify} value TickUpdateNotify received value
   */
  onReceive_TickUpdateNotify (value) {}  // eslint-disable-line

  /**
   * Level2SnapshotNotify receive handler
   * @this {!Receiver}
   * @param {!Level2SnapshotNotify} value Level2SnapshotNotify received value
   */
  onReceive_Level2SnapshotNotify (value) {}  // eslint-disable-line

  /**
   * Level2UpdateNotify receive handler
   * @this {!Receiver}
   * @param {!Level2UpdateNotify} value Level2UpdateNotify received value
   */
  onReceive_Level2UpdateNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryRequest} value ChartBarHistoryRequest received value
   */
  onReceive_ChartBarHistoryRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryResponse} value ChartBarHistoryResponse received value
   */
  onReceive_ChartBarHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarSubscribeRequest} value ChartBarSubscribeRequest received value
   */
  onReceive_ChartBarSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarSubscribeResponse} value ChartBarSubscribeResponse received value
   */
  onReceive_ChartBarSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarUnsubscribeRequest} value ChartBarUnsubscribeRequest received value
   */
  onReceive_ChartBarUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarUnsubscribeResponse} value ChartBarUnsubscribeResponse received value
   */
  onReceive_ChartBarUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarNotify receive handler
   * @this {!Receiver}
   * @param {!ChartBarNotify} value ChartBarNotify received value
   */
  onReceive_ChartBarNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ChartBarUpdateNotify} value ChartBarUpdateNotify received value
   */
  onReceive_ChartBarUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PlatformInfoRequest receive handler
   * @this {!Receiver}
   * @param {!PlatformInfoRequest} value PlatformInfoRequest received value
   */
  onReceive_PlatformInfoRequest (value) {}  // eslint-disable-line

  /**
   * PlatformInfoResponse receive handler
   * @this {!Receiver}
   * @param {!PlatformInfoResponse} value PlatformInfoResponse received value
   */
  onReceive_PlatformInfoResponse (value) {}  // eslint-disable-line

  /**
   * PlatformInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!PlatformInfoUpdateNotify} value PlatformInfoUpdateNotify received value
   */
  onReceive_PlatformInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountInfoRequest receive handler
   * @this {!Receiver}
   * @param {!AccountInfoRequest} value AccountInfoRequest received value
   */
  onReceive_AccountInfoRequest (value) {}  // eslint-disable-line

  /**
   * AccountInfoResponse receive handler
   * @this {!Receiver}
   * @param {!AccountInfoResponse} value AccountInfoResponse received value
   */
  onReceive_AccountInfoResponse (value) {}  // eslint-disable-line

  /**
   * AccountInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!AccountInfoUpdateNotify} value AccountInfoUpdateNotify received value
   */
  onReceive_AccountInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginInfoRequest receive handler
   * @this {!Receiver}
   * @param {!MarginInfoRequest} value MarginInfoRequest received value
   */
  onReceive_MarginInfoRequest (value) {}  // eslint-disable-line

  /**
   * MarginInfoResponse receive handler
   * @this {!Receiver}
   * @param {!MarginInfoResponse} value MarginInfoResponse received value
   */
  onReceive_MarginInfoResponse (value) {}  // eslint-disable-line

  /**
   * MarginInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!MarginInfoUpdateNotify} value MarginInfoUpdateNotify received value
   */
  onReceive_MarginInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginCallNotify receive handler
   * @this {!Receiver}
   * @param {!MarginCallNotify} value MarginCallNotify received value
   */
  onReceive_MarginCallNotify (value) {}  // eslint-disable-line

  /**
   * StopOutNotify receive handler
   * @this {!Receiver}
   * @param {!StopOutNotify} value StopOutNotify received value
   */
  onReceive_StopOutNotify (value) {}  // eslint-disable-line

  /**
   * AssetInfoRequest receive handler
   * @this {!Receiver}
   * @param {!AssetInfoRequest} value AssetInfoRequest received value
   */
  onReceive_AssetInfoRequest (value) {}  // eslint-disable-line

  /**
   * AssetInfoResponse receive handler
   * @this {!Receiver}
   * @param {!AssetInfoResponse} value AssetInfoResponse received value
   */
  onReceive_AssetInfoResponse (value) {}  // eslint-disable-line

  /**
   * AssetListRequest receive handler
   * @this {!Receiver}
   * @param {!AssetListRequest} value AssetListRequest received value
   */
  onReceive_AssetListRequest (value) {}  // eslint-disable-line

  /**
   * AssetListResponse receive handler
   * @this {!Receiver}
   * @param {!AssetListResponse} value AssetListResponse received value
   */
  onReceive_AssetListResponse (value) {}  // eslint-disable-line

  /**
   * AssetInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!AssetInfoUpdateNotify} value AssetInfoUpdateNotify received value
   */
  onReceive_AssetInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * OrderInfoRequest receive handler
   * @this {!Receiver}
   * @param {!OrderInfoRequest} value OrderInfoRequest received value
   */
  onReceive_OrderInfoRequest (value) {}  // eslint-disable-line

  /**
   * OrderInfoResponse receive handler
   * @this {!Receiver}
   * @param {!OrderInfoResponse} value OrderInfoResponse received value
   */
  onReceive_OrderInfoResponse (value) {}  // eslint-disable-line

  /**
   * OrderListRequest receive handler
   * @this {!Receiver}
   * @param {!OrderListRequest} value OrderListRequest received value
   */
  onReceive_OrderListRequest (value) {}  // eslint-disable-line

  /**
   * OrderListResponse receive handler
   * @this {!Receiver}
   * @param {!OrderListResponse} value OrderListResponse received value
   */
  onReceive_OrderListResponse (value) {}  // eslint-disable-line

  /**
   * OrderOpenRequest receive handler
   * @this {!Receiver}
   * @param {!OrderOpenRequest} value OrderOpenRequest received value
   */
  onReceive_OrderOpenRequest (value) {}  // eslint-disable-line

  /**
   * OrderOpenResponse receive handler
   * @this {!Receiver}
   * @param {!OrderOpenResponse} value OrderOpenResponse received value
   */
  onReceive_OrderOpenResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateRequest} value OrderUpdateRequest received value
   */
  onReceive_OrderUpdateRequest (value) {}  // eslint-disable-line

  /**
   * OrderUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateResponse} value OrderUpdateResponse received value
   */
  onReceive_OrderUpdateResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelRequest} value OrderCancelRequest received value
   */
  onReceive_OrderCancelRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCancelResponse} value OrderCancelResponse received value
   */
  onReceive_OrderCancelResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelAllRequest} value OrderCancelAllRequest received value
   */
  onReceive_OrderCancelAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCancelAllResponse} value OrderCancelAllResponse received value
   */
  onReceive_OrderCancelAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllByIdRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelAllByIdRequest} value OrderCancelAllByIdRequest received value
   */
  onReceive_OrderCancelAllByIdRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseRequest} value OrderCloseRequest received value
   */
  onReceive_OrderCloseRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCloseResponse} value OrderCloseResponse received value
   */
  onReceive_OrderCloseResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseAllRequest} value OrderCloseAllRequest received value
   */
  onReceive_OrderCloseAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCloseAllResponse} value OrderCloseAllResponse received value
   */
  onReceive_OrderCloseAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllByIdRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseAllByIdRequest} value OrderCloseAllByIdRequest received value
   */
  onReceive_OrderCloseAllByIdRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseByRequest} value OrderCloseByRequest received value
   */
  onReceive_OrderCloseByRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCloseByResponse} value OrderCloseByResponse received value
   */
  onReceive_OrderCloseByResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseByAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCloseByAllRequest} value OrderCloseByAllRequest received value
   */
  onReceive_OrderCloseByAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCloseByAllResponse} value OrderCloseByAllResponse received value
   */
  onReceive_OrderCloseByAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelOrCloseRequest} value OrderCancelOrCloseRequest received value
   */
  onReceive_OrderCancelOrCloseRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCancelOrCloseResponse} value OrderCancelOrCloseResponse received value
   */
  onReceive_OrderCancelOrCloseResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderCancelOrCloseAllRequest} value OrderCancelOrCloseAllRequest received value
   */
  onReceive_OrderCancelOrCloseAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderCancelOrCloseAllResponse} value OrderCancelOrCloseAllResponse received value
   */
  onReceive_OrderCancelOrCloseAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!OrderInfoUpdateNotify} value OrderInfoUpdateNotify received value
   */
  onReceive_OrderInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PositionInfoRequest receive handler
   * @this {!Receiver}
   * @param {!PositionInfoRequest} value PositionInfoRequest received value
   */
  onReceive_PositionInfoRequest (value) {}  // eslint-disable-line

  /**
   * PositionInfoResponse receive handler
   * @this {!Receiver}
   * @param {!PositionInfoResponse} value PositionInfoResponse received value
   */
  onReceive_PositionInfoResponse (value) {}  // eslint-disable-line

  /**
   * PositionListRequest receive handler
   * @this {!Receiver}
   * @param {!PositionListRequest} value PositionListRequest received value
   */
  onReceive_PositionListRequest (value) {}  // eslint-disable-line

  /**
   * PositionListResponse receive handler
   * @this {!Receiver}
   * @param {!PositionListResponse} value PositionListResponse received value
   */
  onReceive_PositionListResponse (value) {}  // eslint-disable-line

  /**
   * PositionInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!PositionInfoUpdateNotify} value PositionInfoUpdateNotify received value
   */
  onReceive_PositionInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryInfoRequest} value TradeHistoryInfoRequest received value
   */
  onReceive_TradeHistoryInfoRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryInfoResponse} value TradeHistoryInfoResponse received value
   */
  onReceive_TradeHistoryInfoResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoFilterRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryInfoFilterRequest} value TradeHistoryInfoFilterRequest received value
   */
  onReceive_TradeHistoryInfoFilterRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoByIdRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryInfoByIdRequest} value TradeHistoryInfoByIdRequest received value
   */
  onReceive_TradeHistoryInfoByIdRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoByIdResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryInfoByIdResponse} value TradeHistoryInfoByIdResponse received value
   */
  onReceive_TradeHistoryInfoByIdResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryInfoUpdateNotify} value TradeHistoryInfoUpdateNotify received value
   */
  onReceive_TradeHistoryInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleGetRequest receive handler
   * @this {!Receiver}
   * @param {!CalendarRegularRuleGetRequest} value CalendarRegularRuleGetRequest received value
   */
  onReceive_CalendarRegularRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleGetResponse receive handler
   * @this {!Receiver}
   * @param {!CalendarRegularRuleGetResponse} value CalendarRegularRuleGetResponse received value
   */
  onReceive_CalendarRegularRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!CalendarRegularRuleUpdateNotify} value CalendarRegularRuleUpdateNotify received value
   */
  onReceive_CalendarRegularRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleGetRequest receive handler
   * @this {!Receiver}
   * @param {!CalendarOffTimeRuleGetRequest} value CalendarOffTimeRuleGetRequest received value
   */
  onReceive_CalendarOffTimeRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleGetResponse receive handler
   * @this {!Receiver}
   * @param {!CalendarOffTimeRuleGetResponse} value CalendarOffTimeRuleGetResponse received value
   */
  onReceive_CalendarOffTimeRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!CalendarOffTimeRuleUpdateNotify} value CalendarOffTimeRuleUpdateNotify received value
   */
  onReceive_CalendarOffTimeRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CalendarRulesGetRequest receive handler
   * @this {!Receiver}
   * @param {!CalendarRulesGetRequest} value CalendarRulesGetRequest received value
   */
  onReceive_CalendarRulesGetRequest (value) {}  // eslint-disable-line

  /**
   * CalendarRulesGetResponse receive handler
   * @this {!Receiver}
   * @param {!CalendarRulesGetResponse} value CalendarRulesGetResponse received value
   */
  onReceive_CalendarRulesGetResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRangeRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryRangeRequest} value ChartBarHistoryRangeRequest received value
   */
  onReceive_ChartBarHistoryRangeRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!OrderMarginSubscribeRequest} value OrderMarginSubscribeRequest received value
   */
  onReceive_OrderMarginSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!OrderMarginSubscribeResponse} value OrderMarginSubscribeResponse received value
   */
  onReceive_OrderMarginSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderMarginNotify receive handler
   * @this {!Receiver}
   * @param {!OrderMarginNotify} value OrderMarginNotify received value
   */
  onReceive_OrderMarginNotify (value) {}  // eslint-disable-line

  /**
   * OrderMarginUpdateSubscriptionRequest receive handler
   * @this {!Receiver}
   * @param {!OrderMarginUpdateSubscriptionRequest} value OrderMarginUpdateSubscriptionRequest received value
   */
  onReceive_OrderMarginUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!OrderMarginUnsubscribeRequest} value OrderMarginUnsubscribeRequest received value
   */
  onReceive_OrderMarginUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!OrderMarginUnsubscribeResponse} value OrderMarginUnsubscribeResponse received value
   */
  onReceive_OrderMarginUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderMarginGetRequest receive handler
   * @this {!Receiver}
   * @param {!OrderMarginGetRequest} value OrderMarginGetRequest received value
   */
  onReceive_OrderMarginGetRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginGetResponse receive handler
   * @this {!Receiver}
   * @param {!OrderMarginGetResponse} value OrderMarginGetResponse received value
   */
  onReceive_OrderMarginGetResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!OrderStateInfoSubscribeRequest} value OrderStateInfoSubscribeRequest received value
   */
  onReceive_OrderStateInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!OrderStateInfoSubscribeResponse} value OrderStateInfoSubscribeResponse received value
   */
  onReceive_OrderStateInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!OrderStateInfoUnsubscribeRequest} value OrderStateInfoUnsubscribeRequest received value
   */
  onReceive_OrderStateInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!OrderStateInfoUnsubscribeResponse} value OrderStateInfoUnsubscribeResponse received value
   */
  onReceive_OrderStateInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoNotify receive handler
   * @this {!Receiver}
   * @param {!OrderStateInfoNotify} value OrderStateInfoNotify received value
   */
  onReceive_OrderStateInfoNotify (value) {}  // eslint-disable-line

  /**
   * Deprecated003 receive handler
   * @this {!Receiver}
   * @param {!Deprecated003} value Deprecated003 received value
   */
  onReceive_Deprecated003 (value) {}  // eslint-disable-line

  /**
   * Deprecated004 receive handler
   * @this {!Receiver}
   * @param {!Deprecated004} value Deprecated004 received value
   */
  onReceive_Deprecated004 (value) {}  // eslint-disable-line

  /**
   * Deprecated005 receive handler
   * @this {!Receiver}
   * @param {!Deprecated005} value Deprecated005 received value
   */
  onReceive_Deprecated005 (value) {}  // eslint-disable-line

  /**
   * Deprecated006 receive handler
   * @this {!Receiver}
   * @param {!Deprecated006} value Deprecated006 received value
   */
  onReceive_Deprecated006 (value) {}  // eslint-disable-line

  /**
   * Deprecated007 receive handler
   * @this {!Receiver}
   * @param {!Deprecated007} value Deprecated007 received value
   */
  onReceive_Deprecated007 (value) {}  // eslint-disable-line

  /**
   * Deprecated008 receive handler
   * @this {!Receiver}
   * @param {!Deprecated008} value Deprecated008 received value
   */
  onReceive_Deprecated008 (value) {}  // eslint-disable-line

  /**
   * AccountUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!AccountUpdateRequest} value AccountUpdateRequest received value
   */
  onReceive_AccountUpdateRequest (value) {}  // eslint-disable-line

  /**
   * AccountUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!AccountUpdateResponse} value AccountUpdateResponse received value
   */
  onReceive_AccountUpdateResponse (value) {}  // eslint-disable-line

  /**
   * ProfitClosedInfoGetRequest receive handler
   * @this {!Receiver}
   * @param {!ProfitClosedInfoGetRequest} value ProfitClosedInfoGetRequest received value
   */
  onReceive_ProfitClosedInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * ProfitClosedInfoGetResponse receive handler
   * @this {!Receiver}
   * @param {!ProfitClosedInfoGetResponse} value ProfitClosedInfoGetResponse received value
   */
  onReceive_ProfitClosedInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossInfoSubscribeRequest} value SymbolDayProfitLossInfoSubscribeRequest received value
   */
  onReceive_SymbolDayProfitLossInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossInfoSubscribeResponse} value SymbolDayProfitLossInfoSubscribeResponse received value
   */
  onReceive_SymbolDayProfitLossInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUpdateSubscriptionRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossInfoUpdateSubscriptionRequest} value SymbolDayProfitLossInfoUpdateSubscriptionRequest received value
   */
  onReceive_SymbolDayProfitLossInfoUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossInfoUnsubscribeRequest} value SymbolDayProfitLossInfoUnsubscribeRequest received value
   */
  onReceive_SymbolDayProfitLossInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossInfoUnsubscribeResponse} value SymbolDayProfitLossInfoUnsubscribeResponse received value
   */
  onReceive_SymbolDayProfitLossInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossInfoNotify} value SymbolDayProfitLossInfoNotify received value
   */
  onReceive_SymbolDayProfitLossInfoNotify (value) {}  // eslint-disable-line

  /**
   * LoginInfoGetRequest receive handler
   * @this {!Receiver}
   * @param {!LoginInfoGetRequest} value LoginInfoGetRequest received value
   */
  onReceive_LoginInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * LoginInfoGetResponse receive handler
   * @this {!Receiver}
   * @param {!LoginInfoGetResponse} value LoginInfoGetResponse received value
   */
  onReceive_LoginInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoCreateRequest receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoCreateRequest} value DashboardInfoCreateRequest received value
   */
  onReceive_DashboardInfoCreateRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoCreateResponse receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoCreateResponse} value DashboardInfoCreateResponse received value
   */
  onReceive_DashboardInfoCreateResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoUpdateRequest} value DashboardInfoUpdateRequest received value
   */
  onReceive_DashboardInfoUpdateRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoUpdateResponse} value DashboardInfoUpdateResponse received value
   */
  onReceive_DashboardInfoUpdateResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoListRequest receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoListRequest} value DashboardInfoListRequest received value
   */
  onReceive_DashboardInfoListRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoListResponse receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoListResponse} value DashboardInfoListResponse received value
   */
  onReceive_DashboardInfoListResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoGetRequest receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoGetRequest} value DashboardInfoGetRequest received value
   */
  onReceive_DashboardInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoGetResponse receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoGetResponse} value DashboardInfoGetResponse received value
   */
  onReceive_DashboardInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoRenameRequest receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoRenameRequest} value DashboardInfoRenameRequest received value
   */
  onReceive_DashboardInfoRenameRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoRenameResponse receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoRenameResponse} value DashboardInfoRenameResponse received value
   */
  onReceive_DashboardInfoRenameResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoDeleteRequest} value DashboardInfoDeleteRequest received value
   */
  onReceive_DashboardInfoDeleteRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!DashboardInfoDeleteResponse} value DashboardInfoDeleteResponse received value
   */
  onReceive_DashboardInfoDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoCreateRequest receive handler
   * @this {!Receiver}
   * @param {!ChartInfoCreateRequest} value ChartInfoCreateRequest received value
   */
  onReceive_ChartInfoCreateRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoCreateResponse receive handler
   * @this {!Receiver}
   * @param {!ChartInfoCreateResponse} value ChartInfoCreateResponse received value
   */
  onReceive_ChartInfoCreateResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!ChartInfoUpdateRequest} value ChartInfoUpdateRequest received value
   */
  onReceive_ChartInfoUpdateRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!ChartInfoUpdateResponse} value ChartInfoUpdateResponse received value
   */
  onReceive_ChartInfoUpdateResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoListRequest receive handler
   * @this {!Receiver}
   * @param {!ChartInfoListRequest} value ChartInfoListRequest received value
   */
  onReceive_ChartInfoListRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoListResponse receive handler
   * @this {!Receiver}
   * @param {!ChartInfoListResponse} value ChartInfoListResponse received value
   */
  onReceive_ChartInfoListResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!ChartInfoDeleteRequest} value ChartInfoDeleteRequest received value
   */
  onReceive_ChartInfoDeleteRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!ChartInfoDeleteResponse} value ChartInfoDeleteResponse received value
   */
  onReceive_ChartInfoDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoGetRequest receive handler
   * @this {!Receiver}
   * @param {!ChartInfoGetRequest} value ChartInfoGetRequest received value
   */
  onReceive_ChartInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoGetResponse receive handler
   * @this {!Receiver}
   * @param {!ChartInfoGetResponse} value ChartInfoGetResponse received value
   */
  onReceive_ChartInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeRequest} value SymbolDayProfitLossMultipleInfoSubscribeRequest received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeResponse} value SymbolDayProfitLossMultipleInfoSubscribeResponse received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest} value SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeRequest} value SymbolDayProfitLossMultipleInfoUnsubscribeRequest received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeResponse} value SymbolDayProfitLossMultipleInfoUnsubscribeResponse received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolDayProfitLossMultipleInfoNotify} value SymbolDayProfitLossMultipleInfoNotify received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoNotify (value) {}  // eslint-disable-line

  /**
   * NewsServiceGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!NewsServiceGetAllRequest} value NewsServiceGetAllRequest received value
   */
  onReceive_NewsServiceGetAllRequest (value) {}  // eslint-disable-line

  /**
   * NewsServiceGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!NewsServiceGetAllResponse} value NewsServiceGetAllResponse received value
   */
  onReceive_NewsServiceGetAllResponse (value) {}  // eslint-disable-line

  /**
   * NewsRecentArticlesGetRequest receive handler
   * @this {!Receiver}
   * @param {!NewsRecentArticlesGetRequest} value NewsRecentArticlesGetRequest received value
   */
  onReceive_NewsRecentArticlesGetRequest (value) {}  // eslint-disable-line

  /**
   * NewsRecentArticlesGetResponse receive handler
   * @this {!Receiver}
   * @param {!NewsRecentArticlesGetResponse} value NewsRecentArticlesGetResponse received value
   */
  onReceive_NewsRecentArticlesGetResponse (value) {}  // eslint-disable-line

  /**
   * NewsArticleGetRequest receive handler
   * @this {!Receiver}
   * @param {!NewsArticleGetRequest} value NewsArticleGetRequest received value
   */
  onReceive_NewsArticleGetRequest (value) {}  // eslint-disable-line

  /**
   * NewsArticleGetResponse receive handler
   * @this {!Receiver}
   * @param {!NewsArticleGetResponse} value NewsArticleGetResponse received value
   */
  onReceive_NewsArticleGetResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeRequest receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest received value
   */
  onReceive_OrderUpdateWithTypeRequest (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeResponse receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateWithTypeResponse} value OrderUpdateWithTypeResponse received value
   */
  onReceive_OrderUpdateWithTypeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolProfitLossMultipleInfoSubscribeRequest} value SymbolProfitLossMultipleInfoSubscribeRequest received value
   */
  onReceive_SymbolProfitLossMultipleInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolProfitLossMultipleInfoSubscribeResponse} value SymbolProfitLossMultipleInfoSubscribeResponse received value
   */
  onReceive_SymbolProfitLossMultipleInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUpdateSubscriptionRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolProfitLossMultipleInfoUpdateSubscriptionRequest} value SymbolProfitLossMultipleInfoUpdateSubscriptionRequest received value
   */
  onReceive_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeRequest} value SymbolProfitLossMultipleInfoUnsubscribeRequest received value
   */
  onReceive_SymbolProfitLossMultipleInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeResponse} value SymbolProfitLossMultipleInfoUnsubscribeResponse received value
   */
  onReceive_SymbolProfitLossMultipleInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolProfitLossMultipleInfoNotify} value SymbolProfitLossMultipleInfoNotify received value
   */
  onReceive_SymbolProfitLossMultipleInfoNotify (value) {}  // eslint-disable-line

  /**
   * LoginWithPasswordRequest receive handler
   * @this {!Receiver}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest received value
   */
  onReceive_LoginWithPasswordRequest (value) {}  // eslint-disable-line

  /**
   * LoginWithPasswordResponse receive handler
   * @this {!Receiver}
   * @param {!LoginWithPasswordResponse} value LoginWithPasswordResponse received value
   */
  onReceive_LoginWithPasswordResponse (value) {}  // eslint-disable-line

  /**
   * LoginWithTokenRequest receive handler
   * @this {!Receiver}
   * @param {!LoginWithTokenRequest} value LoginWithTokenRequest received value
   */
  onReceive_LoginWithTokenRequest (value) {}  // eslint-disable-line

  /**
   * LoginWithTokenResponse receive handler
   * @this {!Receiver}
   * @param {!LoginWithTokenResponse} value LoginWithTokenResponse received value
   */
  onReceive_LoginWithTokenResponse (value) {}  // eslint-disable-line

  /**
   * RefreshTokenRequest receive handler
   * @this {!Receiver}
   * @param {!RefreshTokenRequest} value RefreshTokenRequest received value
   */
  onReceive_RefreshTokenRequest (value) {}  // eslint-disable-line

  /**
   * RefreshTokenResponse receive handler
   * @this {!Receiver}
   * @param {!RefreshTokenResponse} value RefreshTokenResponse received value
   */
  onReceive_RefreshTokenResponse (value) {}  // eslint-disable-line

  /**
   * Logout2Request receive handler
   * @this {!Receiver}
   * @param {!Logout2Request} value Logout2Request received value
   */
  onReceive_Logout2Request (value) {}  // eslint-disable-line

  /**
   * Logout2Response receive handler
   * @this {!Receiver}
   * @param {!Logout2Response} value Logout2Response received value
   */
  onReceive_Logout2Response (value) {}  // eslint-disable-line

  /**
   * AccountChangePasswordRequest receive handler
   * @this {!Receiver}
   * @param {!AccountChangePasswordRequest} value AccountChangePasswordRequest received value
   */
  onReceive_AccountChangePasswordRequest (value) {}  // eslint-disable-line

  /**
   * AccountChangePasswordResponse receive handler
   * @this {!Receiver}
   * @param {!AccountChangePasswordResponse} value AccountChangePasswordResponse received value
   */
  onReceive_AccountChangePasswordResponse (value) {}  // eslint-disable-line

  /**
   * PushNotificationTokenRegisterRequest receive handler
   * @this {!Receiver}
   * @param {!PushNotificationTokenRegisterRequest} value PushNotificationTokenRegisterRequest received value
   */
  onReceive_PushNotificationTokenRegisterRequest (value) {}  // eslint-disable-line

  /**
   * PushNotificationTokenRegisterResponse receive handler
   * @this {!Receiver}
   * @param {!PushNotificationTokenRegisterResponse} value PushNotificationTokenRegisterResponse received value
   */
  onReceive_PushNotificationTokenRegisterResponse (value) {}  // eslint-disable-line

  /**
   * DesiredAppSettingsGetRequest receive handler
   * @this {!Receiver}
   * @param {!DesiredAppSettingsGetRequest} value DesiredAppSettingsGetRequest received value
   */
  onReceive_DesiredAppSettingsGetRequest (value) {}  // eslint-disable-line

  /**
   * DesiredAppSettingsGetResponse receive handler
   * @this {!Receiver}
   * @param {!DesiredAppSettingsGetResponse} value DesiredAppSettingsGetResponse received value
   */
  onReceive_DesiredAppSettingsGetResponse (value) {}  // eslint-disable-line

  /**
   * AuthTokensGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AuthTokensGetAllRequest} value AuthTokensGetAllRequest received value
   */
  onReceive_AuthTokensGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AuthTokensGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AuthTokensGetAllResponse} value AuthTokensGetAllResponse received value
   */
  onReceive_AuthTokensGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AuthTokensDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!AuthTokensDeleteRequest} value AuthTokensDeleteRequest received value
   */
  onReceive_AuthTokensDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AuthTokensDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!AuthTokensDeleteResponse} value AuthTokensDeleteResponse received value
   */
  onReceive_AuthTokensDeleteResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupListRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupListRequest} value LegalDocumentGroupListRequest received value
   */
  onReceive_LegalDocumentGroupListRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupListResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGroupListResponse} value LegalDocumentGroupListResponse received value
   */
  onReceive_LegalDocumentGroupListResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetRequest receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGetRequest} value LegalDocumentGetRequest received value
   */
  onReceive_LegalDocumentGetRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResponse receive handler
   * @this {!Receiver}
   * @param {!LegalDocumentGetResponse} value LegalDocumentGetResponse received value
   */
  onReceive_LegalDocumentGetResponse (value) {}  // eslint-disable-line

  /**
   * PushNotificationTokenUnRegisterRequest receive handler
   * @this {!Receiver}
   * @param {!PushNotificationTokenUnRegisterRequest} value PushNotificationTokenUnRegisterRequest received value
   */
  onReceive_PushNotificationTokenUnRegisterRequest (value) {}  // eslint-disable-line

  /**
   * PushNotificationTokenUnRegisterResponse receive handler
   * @this {!Receiver}
   * @param {!PushNotificationTokenUnRegisterResponse} value PushNotificationTokenUnRegisterResponse received value
   */
  onReceive_PushNotificationTokenUnRegisterResponse (value) {}  // eslint-disable-line

  /**
   * client receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case PingRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PingRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PingRequestModel.verify(), 'client.PingRequest validation failed!')
        let deserialized = this._PingRequestModel.deserialize(this._PingRequestValue)
        console.assert((deserialized.size > 0), 'client.PingRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PingRequestValue)
        this.onReceive_PingRequest(this._PingRequestValue)
        return true
      }
      case PongResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PongResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PongResponseModel.verify(), 'client.PongResponse validation failed!')
        let deserialized = this._PongResponseModel.deserialize(this._PongResponseValue)
        console.assert((deserialized.size > 0), 'client.PongResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PongResponseValue)
        this.onReceive_PongResponse(this._PongResponseValue)
        return true
      }
      case LoginRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginRequestModel.verify(), 'client.LoginRequest validation failed!')
        let deserialized = this._LoginRequestModel.deserialize(this._LoginRequestValue)
        console.assert((deserialized.size > 0), 'client.LoginRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginRequestValue)
        this.onReceive_LoginRequest(this._LoginRequestValue)
        return true
      }
      case LoginResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginResponseModel.verify(), 'client.LoginResponse validation failed!')
        let deserialized = this._LoginResponseModel.deserialize(this._LoginResponseValue)
        console.assert((deserialized.size > 0), 'client.LoginResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginResponseValue)
        this.onReceive_LoginResponse(this._LoginResponseValue)
        return true
      }
      case LoginRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginRejectModel.attachBuffer(buffer, offset)
        console.assert(this._LoginRejectModel.verify(), 'client.LoginReject validation failed!')
        let deserialized = this._LoginRejectModel.deserialize(this._LoginRejectValue)
        console.assert((deserialized.size > 0), 'client.LoginReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginRejectValue)
        this.onReceive_LoginReject(this._LoginRejectValue)
        return true
      }
      case LogoutRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LogoutRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutRequestModel.verify(), 'client.LogoutRequest validation failed!')
        let deserialized = this._LogoutRequestModel.deserialize(this._LogoutRequestValue)
        console.assert((deserialized.size > 0), 'client.LogoutRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LogoutRequestValue)
        this.onReceive_LogoutRequest(this._LogoutRequestValue)
        return true
      }
      case LogoutResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LogoutResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutResponseModel.verify(), 'client.LogoutResponse validation failed!')
        let deserialized = this._LogoutResponseModel.deserialize(this._LogoutResponseValue)
        console.assert((deserialized.size > 0), 'client.LogoutResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LogoutResponseValue)
        this.onReceive_LogoutResponse(this._LogoutResponseValue)
        return true
      }
      case LogoutNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LogoutNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutNotifyModel.verify(), 'client.LogoutNotify validation failed!')
        let deserialized = this._LogoutNotifyModel.deserialize(this._LogoutNotifyValue)
        console.assert((deserialized.size > 0), 'client.LogoutNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LogoutNotifyValue)
        this.onReceive_LogoutNotify(this._LogoutNotifyValue)
        return true
      }
      case SessionInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SessionInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SessionInfoRequestModel.verify(), 'client.SessionInfoRequest validation failed!')
        let deserialized = this._SessionInfoRequestModel.deserialize(this._SessionInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.SessionInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SessionInfoRequestValue)
        this.onReceive_SessionInfoRequest(this._SessionInfoRequestValue)
        return true
      }
      case SessionInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SessionInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SessionInfoResponseModel.verify(), 'client.SessionInfoResponse validation failed!')
        let deserialized = this._SessionInfoResponseModel.deserialize(this._SessionInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.SessionInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SessionInfoResponseValue)
        this.onReceive_SessionInfoResponse(this._SessionInfoResponseValue)
        return true
      }
      case TimeInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimeInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimeInfoRequestModel.verify(), 'client.TimeInfoRequest validation failed!')
        let deserialized = this._TimeInfoRequestModel.deserialize(this._TimeInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.TimeInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimeInfoRequestValue)
        this.onReceive_TimeInfoRequest(this._TimeInfoRequestValue)
        return true
      }
      case TimeInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimeInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimeInfoResponseModel.verify(), 'client.TimeInfoResponse validation failed!')
        let deserialized = this._TimeInfoResponseModel.deserialize(this._TimeInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.TimeInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimeInfoResponseValue)
        this.onReceive_TimeInfoResponse(this._TimeInfoResponseValue)
        return true
      }
      case TimezoneInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoRequestModel.verify(), 'client.TimezoneInfoRequest validation failed!')
        let deserialized = this._TimezoneInfoRequestModel.deserialize(this._TimezoneInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.TimezoneInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneInfoRequestValue)
        this.onReceive_TimezoneInfoRequest(this._TimezoneInfoRequestValue)
        return true
      }
      case TimezoneInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoResponseModel.verify(), 'client.TimezoneInfoResponse validation failed!')
        let deserialized = this._TimezoneInfoResponseModel.deserialize(this._TimezoneInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.TimezoneInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneInfoResponseValue)
        this.onReceive_TimezoneInfoResponse(this._TimezoneInfoResponseValue)
        return true
      }
      case TimezoneInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoUpdateNotifyModel.verify(), 'client.TimezoneInfoUpdateNotify validation failed!')
        let deserialized = this._TimezoneInfoUpdateNotifyModel.deserialize(this._TimezoneInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.TimezoneInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneInfoUpdateNotifyValue)
        this.onReceive_TimezoneInfoUpdateNotify(this._TimezoneInfoUpdateNotifyValue)
        return true
      }
      case CurrencyInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoRequestModel.verify(), 'client.CurrencyInfoRequest validation failed!')
        let deserialized = this._CurrencyInfoRequestModel.deserialize(this._CurrencyInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.CurrencyInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyInfoRequestValue)
        this.onReceive_CurrencyInfoRequest(this._CurrencyInfoRequestValue)
        return true
      }
      case CurrencyInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoResponseModel.verify(), 'client.CurrencyInfoResponse validation failed!')
        let deserialized = this._CurrencyInfoResponseModel.deserialize(this._CurrencyInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.CurrencyInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyInfoResponseValue)
        this.onReceive_CurrencyInfoResponse(this._CurrencyInfoResponseValue)
        return true
      }
      case CurrencyListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyListRequestModel.verify(), 'client.CurrencyListRequest validation failed!')
        let deserialized = this._CurrencyListRequestModel.deserialize(this._CurrencyListRequestValue)
        console.assert((deserialized.size > 0), 'client.CurrencyListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyListRequestValue)
        this.onReceive_CurrencyListRequest(this._CurrencyListRequestValue)
        return true
      }
      case CurrencyListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyListResponseModel.verify(), 'client.CurrencyListResponse validation failed!')
        let deserialized = this._CurrencyListResponseModel.deserialize(this._CurrencyListResponseValue)
        console.assert((deserialized.size > 0), 'client.CurrencyListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyListResponseValue)
        this.onReceive_CurrencyListResponse(this._CurrencyListResponseValue)
        return true
      }
      case CurrencyInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoUpdateNotifyModel.verify(), 'client.CurrencyInfoUpdateNotify validation failed!')
        let deserialized = this._CurrencyInfoUpdateNotifyModel.deserialize(this._CurrencyInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.CurrencyInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyInfoUpdateNotifyValue)
        this.onReceive_CurrencyInfoUpdateNotify(this._CurrencyInfoUpdateNotifyValue)
        return true
      }
      case SymbolInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoRequestModel.verify(), 'client.SymbolInfoRequest validation failed!')
        let deserialized = this._SymbolInfoRequestModel.deserialize(this._SymbolInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolInfoRequestValue)
        this.onReceive_SymbolInfoRequest(this._SymbolInfoRequestValue)
        return true
      }
      case SymbolInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoResponseModel.verify(), 'client.SymbolInfoResponse validation failed!')
        let deserialized = this._SymbolInfoResponseModel.deserialize(this._SymbolInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.SymbolInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolInfoResponseValue)
        this.onReceive_SymbolInfoResponse(this._SymbolInfoResponseValue)
        return true
      }
      case SymbolListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolListRequestModel.verify(), 'client.SymbolListRequest validation failed!')
        let deserialized = this._SymbolListRequestModel.deserialize(this._SymbolListRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolListRequestValue)
        this.onReceive_SymbolListRequest(this._SymbolListRequestValue)
        return true
      }
      case SymbolListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolListResponseModel.verify(), 'client.SymbolListResponse validation failed!')
        let deserialized = this._SymbolListResponseModel.deserialize(this._SymbolListResponseValue)
        console.assert((deserialized.size > 0), 'client.SymbolListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolListResponseValue)
        this.onReceive_SymbolListResponse(this._SymbolListResponseValue)
        return true
      }
      case SymbolInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoUpdateNotifyModel.verify(), 'client.SymbolInfoUpdateNotify validation failed!')
        let deserialized = this._SymbolInfoUpdateNotifyModel.deserialize(this._SymbolInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.SymbolInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolInfoUpdateNotifyValue)
        this.onReceive_SymbolInfoUpdateNotify(this._SymbolInfoUpdateNotifyValue)
        return true
      }
      case TickInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickInfoRequestModel.verify(), 'client.TickInfoRequest validation failed!')
        let deserialized = this._TickInfoRequestModel.deserialize(this._TickInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.TickInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickInfoRequestValue)
        this.onReceive_TickInfoRequest(this._TickInfoRequestValue)
        return true
      }
      case TickInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickInfoResponseModel.verify(), 'client.TickInfoResponse validation failed!')
        let deserialized = this._TickInfoResponseModel.deserialize(this._TickInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.TickInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickInfoResponseValue)
        this.onReceive_TickInfoResponse(this._TickInfoResponseValue)
        return true
      }
      case TickFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickFilterRequestModel.verify(), 'client.TickFilterRequest validation failed!')
        let deserialized = this._TickFilterRequestModel.deserialize(this._TickFilterRequestValue)
        console.assert((deserialized.size > 0), 'client.TickFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickFilterRequestValue)
        this.onReceive_TickFilterRequest(this._TickFilterRequestValue)
        return true
      }
      case TickFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickFilterResponseModel.verify(), 'client.TickFilterResponse validation failed!')
        let deserialized = this._TickFilterResponseModel.deserialize(this._TickFilterResponseValue)
        console.assert((deserialized.size > 0), 'client.TickFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickFilterResponseValue)
        this.onReceive_TickFilterResponse(this._TickFilterResponseValue)
        return true
      }
      case TickListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickListRequestModel.verify(), 'client.TickListRequest validation failed!')
        let deserialized = this._TickListRequestModel.deserialize(this._TickListRequestValue)
        console.assert((deserialized.size > 0), 'client.TickListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickListRequestValue)
        this.onReceive_TickListRequest(this._TickListRequestValue)
        return true
      }
      case TickListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickListResponseModel.verify(), 'client.TickListResponse validation failed!')
        let deserialized = this._TickListResponseModel.deserialize(this._TickListResponseValue)
        console.assert((deserialized.size > 0), 'client.TickListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickListResponseValue)
        this.onReceive_TickListResponse(this._TickListResponseValue)
        return true
      }
      case Level2InfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2InfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2InfoRequestModel.verify(), 'client.Level2InfoRequest validation failed!')
        let deserialized = this._Level2InfoRequestModel.deserialize(this._Level2InfoRequestValue)
        console.assert((deserialized.size > 0), 'client.Level2InfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2InfoRequestValue)
        this.onReceive_Level2InfoRequest(this._Level2InfoRequestValue)
        return true
      }
      case Level2InfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2InfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2InfoResponseModel.verify(), 'client.Level2InfoResponse validation failed!')
        let deserialized = this._Level2InfoResponseModel.deserialize(this._Level2InfoResponseValue)
        console.assert((deserialized.size > 0), 'client.Level2InfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2InfoResponseValue)
        this.onReceive_Level2InfoResponse(this._Level2InfoResponseValue)
        return true
      }
      case Level2FilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2FilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2FilterRequestModel.verify(), 'client.Level2FilterRequest validation failed!')
        let deserialized = this._Level2FilterRequestModel.deserialize(this._Level2FilterRequestValue)
        console.assert((deserialized.size > 0), 'client.Level2FilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2FilterRequestValue)
        this.onReceive_Level2FilterRequest(this._Level2FilterRequestValue)
        return true
      }
      case Level2FilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2FilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2FilterResponseModel.verify(), 'client.Level2FilterResponse validation failed!')
        let deserialized = this._Level2FilterResponseModel.deserialize(this._Level2FilterResponseValue)
        console.assert((deserialized.size > 0), 'client.Level2FilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2FilterResponseValue)
        this.onReceive_Level2FilterResponse(this._Level2FilterResponseValue)
        return true
      }
      case Level2ListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2ListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2ListRequestModel.verify(), 'client.Level2ListRequest validation failed!')
        let deserialized = this._Level2ListRequestModel.deserialize(this._Level2ListRequestValue)
        console.assert((deserialized.size > 0), 'client.Level2ListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2ListRequestValue)
        this.onReceive_Level2ListRequest(this._Level2ListRequestValue)
        return true
      }
      case Level2ListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2ListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2ListResponseModel.verify(), 'client.Level2ListResponse validation failed!')
        let deserialized = this._Level2ListResponseModel.deserialize(this._Level2ListResponseValue)
        console.assert((deserialized.size > 0), 'client.Level2ListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2ListResponseValue)
        this.onReceive_Level2ListResponse(this._Level2ListResponseValue)
        return true
      }
      case FeedSubscriptionInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscriptionInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionInfoRequestModel.verify(), 'client.FeedSubscriptionInfoRequest validation failed!')
        let deserialized = this._FeedSubscriptionInfoRequestModel.deserialize(this._FeedSubscriptionInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscriptionInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscriptionInfoRequestValue)
        this.onReceive_FeedSubscriptionInfoRequest(this._FeedSubscriptionInfoRequestValue)
        return true
      }
      case FeedSubscriptionInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscriptionInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionInfoResponseModel.verify(), 'client.FeedSubscriptionInfoResponse validation failed!')
        let deserialized = this._FeedSubscriptionInfoResponseModel.deserialize(this._FeedSubscriptionInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscriptionInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscriptionInfoResponseValue)
        this.onReceive_FeedSubscriptionInfoResponse(this._FeedSubscriptionInfoResponseValue)
        return true
      }
      case FeedSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeRequestModel.verify(), 'client.FeedSubscribeRequest validation failed!')
        let deserialized = this._FeedSubscribeRequestModel.deserialize(this._FeedSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeRequestValue)
        this.onReceive_FeedSubscribeRequest(this._FeedSubscribeRequestValue)
        return true
      }
      case FeedSubscribeAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeAllRequestModel.verify(), 'client.FeedSubscribeAllRequest validation failed!')
        let deserialized = this._FeedSubscribeAllRequestModel.deserialize(this._FeedSubscribeAllRequestValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscribeAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeAllRequestValue)
        this.onReceive_FeedSubscribeAllRequest(this._FeedSubscribeAllRequestValue)
        return true
      }
      case FeedSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeResponseModel.verify(), 'client.FeedSubscribeResponse validation failed!')
        let deserialized = this._FeedSubscribeResponseModel.deserialize(this._FeedSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeResponseValue)
        this.onReceive_FeedSubscribeResponse(this._FeedSubscribeResponseValue)
        return true
      }
      case FeedUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeRequestModel.verify(), 'client.FeedUnsubscribeRequest validation failed!')
        let deserialized = this._FeedUnsubscribeRequestModel.deserialize(this._FeedUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.FeedUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeRequestValue)
        this.onReceive_FeedUnsubscribeRequest(this._FeedUnsubscribeRequestValue)
        return true
      }
      case FeedUnsubscribeAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeAllRequestModel.verify(), 'client.FeedUnsubscribeAllRequest validation failed!')
        let deserialized = this._FeedUnsubscribeAllRequestModel.deserialize(this._FeedUnsubscribeAllRequestValue)
        console.assert((deserialized.size > 0), 'client.FeedUnsubscribeAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeAllRequestValue)
        this.onReceive_FeedUnsubscribeAllRequest(this._FeedUnsubscribeAllRequestValue)
        return true
      }
      case FeedUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeResponseModel.verify(), 'client.FeedUnsubscribeResponse validation failed!')
        let deserialized = this._FeedUnsubscribeResponseModel.deserialize(this._FeedUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.FeedUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeResponseValue)
        this.onReceive_FeedUnsubscribeResponse(this._FeedUnsubscribeResponseValue)
        return true
      }
      case TickSnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickSnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TickSnapshotNotifyModel.verify(), 'client.TickSnapshotNotify validation failed!')
        let deserialized = this._TickSnapshotNotifyModel.deserialize(this._TickSnapshotNotifyValue)
        console.assert((deserialized.size > 0), 'client.TickSnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickSnapshotNotifyValue)
        this.onReceive_TickSnapshotNotify(this._TickSnapshotNotifyValue)
        return true
      }
      case TickUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TickUpdateNotifyModel.verify(), 'client.TickUpdateNotify validation failed!')
        let deserialized = this._TickUpdateNotifyModel.deserialize(this._TickUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.TickUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickUpdateNotifyValue)
        this.onReceive_TickUpdateNotify(this._TickUpdateNotifyValue)
        return true
      }
      case Level2SnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2SnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._Level2SnapshotNotifyModel.verify(), 'client.Level2SnapshotNotify validation failed!')
        let deserialized = this._Level2SnapshotNotifyModel.deserialize(this._Level2SnapshotNotifyValue)
        console.assert((deserialized.size > 0), 'client.Level2SnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2SnapshotNotifyValue)
        this.onReceive_Level2SnapshotNotify(this._Level2SnapshotNotifyValue)
        return true
      }
      case Level2UpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2UpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._Level2UpdateNotifyModel.verify(), 'client.Level2UpdateNotify validation failed!')
        let deserialized = this._Level2UpdateNotifyModel.deserialize(this._Level2UpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.Level2UpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2UpdateNotifyValue)
        this.onReceive_Level2UpdateNotify(this._Level2UpdateNotifyValue)
        return true
      }
      case ChartBarHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRequestModel.verify(), 'client.ChartBarHistoryRequest validation failed!')
        let deserialized = this._ChartBarHistoryRequestModel.deserialize(this._ChartBarHistoryRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartBarHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryRequestValue)
        this.onReceive_ChartBarHistoryRequest(this._ChartBarHistoryRequestValue)
        return true
      }
      case ChartBarHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryResponseModel.verify(), 'client.ChartBarHistoryResponse validation failed!')
        let deserialized = this._ChartBarHistoryResponseModel.deserialize(this._ChartBarHistoryResponseValue)
        console.assert((deserialized.size > 0), 'client.ChartBarHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryResponseValue)
        this.onReceive_ChartBarHistoryResponse(this._ChartBarHistoryResponseValue)
        return true
      }
      case ChartBarSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarSubscribeRequestModel.verify(), 'client.ChartBarSubscribeRequest validation failed!')
        let deserialized = this._ChartBarSubscribeRequestModel.deserialize(this._ChartBarSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartBarSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarSubscribeRequestValue)
        this.onReceive_ChartBarSubscribeRequest(this._ChartBarSubscribeRequestValue)
        return true
      }
      case ChartBarSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarSubscribeResponseModel.verify(), 'client.ChartBarSubscribeResponse validation failed!')
        let deserialized = this._ChartBarSubscribeResponseModel.deserialize(this._ChartBarSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.ChartBarSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarSubscribeResponseValue)
        this.onReceive_ChartBarSubscribeResponse(this._ChartBarSubscribeResponseValue)
        return true
      }
      case ChartBarUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUnsubscribeRequestModel.verify(), 'client.ChartBarUnsubscribeRequest validation failed!')
        let deserialized = this._ChartBarUnsubscribeRequestModel.deserialize(this._ChartBarUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartBarUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarUnsubscribeRequestValue)
        this.onReceive_ChartBarUnsubscribeRequest(this._ChartBarUnsubscribeRequestValue)
        return true
      }
      case ChartBarUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUnsubscribeResponseModel.verify(), 'client.ChartBarUnsubscribeResponse validation failed!')
        let deserialized = this._ChartBarUnsubscribeResponseModel.deserialize(this._ChartBarUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.ChartBarUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarUnsubscribeResponseValue)
        this.onReceive_ChartBarUnsubscribeResponse(this._ChartBarUnsubscribeResponseValue)
        return true
      }
      case ChartBarNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarNotifyModel.verify(), 'client.ChartBarNotify validation failed!')
        let deserialized = this._ChartBarNotifyModel.deserialize(this._ChartBarNotifyValue)
        console.assert((deserialized.size > 0), 'client.ChartBarNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarNotifyValue)
        this.onReceive_ChartBarNotify(this._ChartBarNotifyValue)
        return true
      }
      case ChartBarUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUpdateNotifyModel.verify(), 'client.ChartBarUpdateNotify validation failed!')
        let deserialized = this._ChartBarUpdateNotifyModel.deserialize(this._ChartBarUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.ChartBarUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarUpdateNotifyValue)
        this.onReceive_ChartBarUpdateNotify(this._ChartBarUpdateNotifyValue)
        return true
      }
      case PlatformInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoRequestModel.verify(), 'client.PlatformInfoRequest validation failed!')
        let deserialized = this._PlatformInfoRequestModel.deserialize(this._PlatformInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.PlatformInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformInfoRequestValue)
        this.onReceive_PlatformInfoRequest(this._PlatformInfoRequestValue)
        return true
      }
      case PlatformInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoResponseModel.verify(), 'client.PlatformInfoResponse validation failed!')
        let deserialized = this._PlatformInfoResponseModel.deserialize(this._PlatformInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.PlatformInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformInfoResponseValue)
        this.onReceive_PlatformInfoResponse(this._PlatformInfoResponseValue)
        return true
      }
      case PlatformInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoUpdateNotifyModel.verify(), 'client.PlatformInfoUpdateNotify validation failed!')
        let deserialized = this._PlatformInfoUpdateNotifyModel.deserialize(this._PlatformInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.PlatformInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformInfoUpdateNotifyValue)
        this.onReceive_PlatformInfoUpdateNotify(this._PlatformInfoUpdateNotifyValue)
        return true
      }
      case AccountInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoRequestModel.verify(), 'client.AccountInfoRequest validation failed!')
        let deserialized = this._AccountInfoRequestModel.deserialize(this._AccountInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.AccountInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInfoRequestValue)
        this.onReceive_AccountInfoRequest(this._AccountInfoRequestValue)
        return true
      }
      case AccountInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoResponseModel.verify(), 'client.AccountInfoResponse validation failed!')
        let deserialized = this._AccountInfoResponseModel.deserialize(this._AccountInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.AccountInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInfoResponseValue)
        this.onReceive_AccountInfoResponse(this._AccountInfoResponseValue)
        return true
      }
      case AccountInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoUpdateNotifyModel.verify(), 'client.AccountInfoUpdateNotify validation failed!')
        let deserialized = this._AccountInfoUpdateNotifyModel.deserialize(this._AccountInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.AccountInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInfoUpdateNotifyValue)
        this.onReceive_AccountInfoUpdateNotify(this._AccountInfoUpdateNotifyValue)
        return true
      }
      case MarginInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoRequestModel.verify(), 'client.MarginInfoRequest validation failed!')
        let deserialized = this._MarginInfoRequestModel.deserialize(this._MarginInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.MarginInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginInfoRequestValue)
        this.onReceive_MarginInfoRequest(this._MarginInfoRequestValue)
        return true
      }
      case MarginInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoResponseModel.verify(), 'client.MarginInfoResponse validation failed!')
        let deserialized = this._MarginInfoResponseModel.deserialize(this._MarginInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.MarginInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginInfoResponseValue)
        this.onReceive_MarginInfoResponse(this._MarginInfoResponseValue)
        return true
      }
      case MarginInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoUpdateNotifyModel.verify(), 'client.MarginInfoUpdateNotify validation failed!')
        let deserialized = this._MarginInfoUpdateNotifyModel.deserialize(this._MarginInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.MarginInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginInfoUpdateNotifyValue)
        this.onReceive_MarginInfoUpdateNotify(this._MarginInfoUpdateNotifyValue)
        return true
      }
      case MarginCallNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginCallNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginCallNotifyModel.verify(), 'client.MarginCallNotify validation failed!')
        let deserialized = this._MarginCallNotifyModel.deserialize(this._MarginCallNotifyValue)
        console.assert((deserialized.size > 0), 'client.MarginCallNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginCallNotifyValue)
        this.onReceive_MarginCallNotify(this._MarginCallNotifyValue)
        return true
      }
      case StopOutNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StopOutNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._StopOutNotifyModel.verify(), 'client.StopOutNotify validation failed!')
        let deserialized = this._StopOutNotifyModel.deserialize(this._StopOutNotifyValue)
        console.assert((deserialized.size > 0), 'client.StopOutNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StopOutNotifyValue)
        this.onReceive_StopOutNotify(this._StopOutNotifyValue)
        return true
      }
      case AssetInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoRequestModel.verify(), 'client.AssetInfoRequest validation failed!')
        let deserialized = this._AssetInfoRequestModel.deserialize(this._AssetInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.AssetInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInfoRequestValue)
        this.onReceive_AssetInfoRequest(this._AssetInfoRequestValue)
        return true
      }
      case AssetInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoResponseModel.verify(), 'client.AssetInfoResponse validation failed!')
        let deserialized = this._AssetInfoResponseModel.deserialize(this._AssetInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.AssetInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInfoResponseValue)
        this.onReceive_AssetInfoResponse(this._AssetInfoResponseValue)
        return true
      }
      case AssetListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetListRequestModel.verify(), 'client.AssetListRequest validation failed!')
        let deserialized = this._AssetListRequestModel.deserialize(this._AssetListRequestValue)
        console.assert((deserialized.size > 0), 'client.AssetListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetListRequestValue)
        this.onReceive_AssetListRequest(this._AssetListRequestValue)
        return true
      }
      case AssetListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetListResponseModel.verify(), 'client.AssetListResponse validation failed!')
        let deserialized = this._AssetListResponseModel.deserialize(this._AssetListResponseValue)
        console.assert((deserialized.size > 0), 'client.AssetListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetListResponseValue)
        this.onReceive_AssetListResponse(this._AssetListResponseValue)
        return true
      }
      case AssetInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoUpdateNotifyModel.verify(), 'client.AssetInfoUpdateNotify validation failed!')
        let deserialized = this._AssetInfoUpdateNotifyModel.deserialize(this._AssetInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.AssetInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInfoUpdateNotifyValue)
        this.onReceive_AssetInfoUpdateNotify(this._AssetInfoUpdateNotifyValue)
        return true
      }
      case OrderInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoRequestModel.verify(), 'client.OrderInfoRequest validation failed!')
        let deserialized = this._OrderInfoRequestModel.deserialize(this._OrderInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInfoRequestValue)
        this.onReceive_OrderInfoRequest(this._OrderInfoRequestValue)
        return true
      }
      case OrderInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoResponseModel.verify(), 'client.OrderInfoResponse validation failed!')
        let deserialized = this._OrderInfoResponseModel.deserialize(this._OrderInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInfoResponseValue)
        this.onReceive_OrderInfoResponse(this._OrderInfoResponseValue)
        return true
      }
      case OrderListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderListRequestModel.verify(), 'client.OrderListRequest validation failed!')
        let deserialized = this._OrderListRequestModel.deserialize(this._OrderListRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderListRequestValue)
        this.onReceive_OrderListRequest(this._OrderListRequestValue)
        return true
      }
      case OrderListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderListResponseModel.verify(), 'client.OrderListResponse validation failed!')
        let deserialized = this._OrderListResponseModel.deserialize(this._OrderListResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderListResponseValue)
        this.onReceive_OrderListResponse(this._OrderListResponseValue)
        return true
      }
      case OrderOpenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderOpenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderOpenRequestModel.verify(), 'client.OrderOpenRequest validation failed!')
        let deserialized = this._OrderOpenRequestModel.deserialize(this._OrderOpenRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderOpenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderOpenRequestValue)
        this.onReceive_OrderOpenRequest(this._OrderOpenRequestValue)
        return true
      }
      case OrderOpenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderOpenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderOpenResponseModel.verify(), 'client.OrderOpenResponse validation failed!')
        let deserialized = this._OrderOpenResponseModel.deserialize(this._OrderOpenResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderOpenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderOpenResponseValue)
        this.onReceive_OrderOpenResponse(this._OrderOpenResponseValue)
        return true
      }
      case OrderUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateRequestModel.verify(), 'client.OrderUpdateRequest validation failed!')
        let deserialized = this._OrderUpdateRequestModel.deserialize(this._OrderUpdateRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateRequestValue)
        this.onReceive_OrderUpdateRequest(this._OrderUpdateRequestValue)
        return true
      }
      case OrderUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateResponseModel.verify(), 'client.OrderUpdateResponse validation failed!')
        let deserialized = this._OrderUpdateResponseModel.deserialize(this._OrderUpdateResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateResponseValue)
        this.onReceive_OrderUpdateResponse(this._OrderUpdateResponseValue)
        return true
      }
      case OrderCancelRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelRequestModel.verify(), 'client.OrderCancelRequest validation failed!')
        let deserialized = this._OrderCancelRequestModel.deserialize(this._OrderCancelRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelRequestValue)
        this.onReceive_OrderCancelRequest(this._OrderCancelRequestValue)
        return true
      }
      case OrderCancelResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelResponseModel.verify(), 'client.OrderCancelResponse validation failed!')
        let deserialized = this._OrderCancelResponseModel.deserialize(this._OrderCancelResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelResponseValue)
        this.onReceive_OrderCancelResponse(this._OrderCancelResponseValue)
        return true
      }
      case OrderCancelAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllRequestModel.verify(), 'client.OrderCancelAllRequest validation failed!')
        let deserialized = this._OrderCancelAllRequestModel.deserialize(this._OrderCancelAllRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllRequestValue)
        this.onReceive_OrderCancelAllRequest(this._OrderCancelAllRequestValue)
        return true
      }
      case OrderCancelAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllResponseModel.verify(), 'client.OrderCancelAllResponse validation failed!')
        let deserialized = this._OrderCancelAllResponseModel.deserialize(this._OrderCancelAllResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllResponseValue)
        this.onReceive_OrderCancelAllResponse(this._OrderCancelAllResponseValue)
        return true
      }
      case OrderCancelAllByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllByIdRequestModel.verify(), 'client.OrderCancelAllByIdRequest validation failed!')
        let deserialized = this._OrderCancelAllByIdRequestModel.deserialize(this._OrderCancelAllByIdRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelAllByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllByIdRequestValue)
        this.onReceive_OrderCancelAllByIdRequest(this._OrderCancelAllByIdRequestValue)
        return true
      }
      case OrderCloseRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseRequestModel.verify(), 'client.OrderCloseRequest validation failed!')
        let deserialized = this._OrderCloseRequestModel.deserialize(this._OrderCloseRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseRequestValue)
        this.onReceive_OrderCloseRequest(this._OrderCloseRequestValue)
        return true
      }
      case OrderCloseResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseResponseModel.verify(), 'client.OrderCloseResponse validation failed!')
        let deserialized = this._OrderCloseResponseModel.deserialize(this._OrderCloseResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseResponseValue)
        this.onReceive_OrderCloseResponse(this._OrderCloseResponseValue)
        return true
      }
      case OrderCloseAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllRequestModel.verify(), 'client.OrderCloseAllRequest validation failed!')
        let deserialized = this._OrderCloseAllRequestModel.deserialize(this._OrderCloseAllRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllRequestValue)
        this.onReceive_OrderCloseAllRequest(this._OrderCloseAllRequestValue)
        return true
      }
      case OrderCloseAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllResponseModel.verify(), 'client.OrderCloseAllResponse validation failed!')
        let deserialized = this._OrderCloseAllResponseModel.deserialize(this._OrderCloseAllResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllResponseValue)
        this.onReceive_OrderCloseAllResponse(this._OrderCloseAllResponseValue)
        return true
      }
      case OrderCloseAllByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllByIdRequestModel.verify(), 'client.OrderCloseAllByIdRequest validation failed!')
        let deserialized = this._OrderCloseAllByIdRequestModel.deserialize(this._OrderCloseAllByIdRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseAllByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllByIdRequestValue)
        this.onReceive_OrderCloseAllByIdRequest(this._OrderCloseAllByIdRequestValue)
        return true
      }
      case OrderCloseByRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByRequestModel.verify(), 'client.OrderCloseByRequest validation failed!')
        let deserialized = this._OrderCloseByRequestModel.deserialize(this._OrderCloseByRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseByRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByRequestValue)
        this.onReceive_OrderCloseByRequest(this._OrderCloseByRequestValue)
        return true
      }
      case OrderCloseByResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByResponseModel.verify(), 'client.OrderCloseByResponse validation failed!')
        let deserialized = this._OrderCloseByResponseModel.deserialize(this._OrderCloseByResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseByResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByResponseValue)
        this.onReceive_OrderCloseByResponse(this._OrderCloseByResponseValue)
        return true
      }
      case OrderCloseByAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllRequestModel.verify(), 'client.OrderCloseByAllRequest validation failed!')
        let deserialized = this._OrderCloseByAllRequestModel.deserialize(this._OrderCloseByAllRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseByAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByAllRequestValue)
        this.onReceive_OrderCloseByAllRequest(this._OrderCloseByAllRequestValue)
        return true
      }
      case OrderCloseByAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllResponseModel.verify(), 'client.OrderCloseByAllResponse validation failed!')
        let deserialized = this._OrderCloseByAllResponseModel.deserialize(this._OrderCloseByAllResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseByAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByAllResponseValue)
        this.onReceive_OrderCloseByAllResponse(this._OrderCloseByAllResponseValue)
        return true
      }
      case OrderCancelOrCloseRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseRequestModel.verify(), 'client.OrderCancelOrCloseRequest validation failed!')
        let deserialized = this._OrderCancelOrCloseRequestModel.deserialize(this._OrderCancelOrCloseRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelOrCloseRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseRequestValue)
        this.onReceive_OrderCancelOrCloseRequest(this._OrderCancelOrCloseRequestValue)
        return true
      }
      case OrderCancelOrCloseResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseResponseModel.verify(), 'client.OrderCancelOrCloseResponse validation failed!')
        let deserialized = this._OrderCancelOrCloseResponseModel.deserialize(this._OrderCancelOrCloseResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelOrCloseResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseResponseValue)
        this.onReceive_OrderCancelOrCloseResponse(this._OrderCancelOrCloseResponseValue)
        return true
      }
      case OrderCancelOrCloseAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllRequestModel.verify(), 'client.OrderCancelOrCloseAllRequest validation failed!')
        let deserialized = this._OrderCancelOrCloseAllRequestModel.deserialize(this._OrderCancelOrCloseAllRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelOrCloseAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseAllRequestValue)
        this.onReceive_OrderCancelOrCloseAllRequest(this._OrderCancelOrCloseAllRequestValue)
        return true
      }
      case OrderCancelOrCloseAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllResponseModel.verify(), 'client.OrderCancelOrCloseAllResponse validation failed!')
        let deserialized = this._OrderCancelOrCloseAllResponseModel.deserialize(this._OrderCancelOrCloseAllResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelOrCloseAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseAllResponseValue)
        this.onReceive_OrderCancelOrCloseAllResponse(this._OrderCancelOrCloseAllResponseValue)
        return true
      }
      case OrderInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoUpdateNotifyModel.verify(), 'client.OrderInfoUpdateNotify validation failed!')
        let deserialized = this._OrderInfoUpdateNotifyModel.deserialize(this._OrderInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.OrderInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInfoUpdateNotifyValue)
        this.onReceive_OrderInfoUpdateNotify(this._OrderInfoUpdateNotifyValue)
        return true
      }
      case PositionInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoRequestModel.verify(), 'client.PositionInfoRequest validation failed!')
        let deserialized = this._PositionInfoRequestModel.deserialize(this._PositionInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.PositionInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInfoRequestValue)
        this.onReceive_PositionInfoRequest(this._PositionInfoRequestValue)
        return true
      }
      case PositionInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoResponseModel.verify(), 'client.PositionInfoResponse validation failed!')
        let deserialized = this._PositionInfoResponseModel.deserialize(this._PositionInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.PositionInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInfoResponseValue)
        this.onReceive_PositionInfoResponse(this._PositionInfoResponseValue)
        return true
      }
      case PositionListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionListRequestModel.verify(), 'client.PositionListRequest validation failed!')
        let deserialized = this._PositionListRequestModel.deserialize(this._PositionListRequestValue)
        console.assert((deserialized.size > 0), 'client.PositionListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionListRequestValue)
        this.onReceive_PositionListRequest(this._PositionListRequestValue)
        return true
      }
      case PositionListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionListResponseModel.verify(), 'client.PositionListResponse validation failed!')
        let deserialized = this._PositionListResponseModel.deserialize(this._PositionListResponseValue)
        console.assert((deserialized.size > 0), 'client.PositionListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionListResponseValue)
        this.onReceive_PositionListResponse(this._PositionListResponseValue)
        return true
      }
      case PositionInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoUpdateNotifyModel.verify(), 'client.PositionInfoUpdateNotify validation failed!')
        let deserialized = this._PositionInfoUpdateNotifyModel.deserialize(this._PositionInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.PositionInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInfoUpdateNotifyValue)
        this.onReceive_PositionInfoUpdateNotify(this._PositionInfoUpdateNotifyValue)
        return true
      }
      case TradeHistoryInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoRequestModel.verify(), 'client.TradeHistoryInfoRequest validation failed!')
        let deserialized = this._TradeHistoryInfoRequestModel.deserialize(this._TradeHistoryInfoRequestValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoRequestValue)
        this.onReceive_TradeHistoryInfoRequest(this._TradeHistoryInfoRequestValue)
        return true
      }
      case TradeHistoryInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoResponseModel.verify(), 'client.TradeHistoryInfoResponse validation failed!')
        let deserialized = this._TradeHistoryInfoResponseModel.deserialize(this._TradeHistoryInfoResponseValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoResponseValue)
        this.onReceive_TradeHistoryInfoResponse(this._TradeHistoryInfoResponseValue)
        return true
      }
      case TradeHistoryInfoFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoFilterRequestModel.verify(), 'client.TradeHistoryInfoFilterRequest validation failed!')
        let deserialized = this._TradeHistoryInfoFilterRequestModel.deserialize(this._TradeHistoryInfoFilterRequestValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoFilterRequestValue)
        this.onReceive_TradeHistoryInfoFilterRequest(this._TradeHistoryInfoFilterRequestValue)
        return true
      }
      case TradeHistoryInfoByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoByIdRequestModel.verify(), 'client.TradeHistoryInfoByIdRequest validation failed!')
        let deserialized = this._TradeHistoryInfoByIdRequestModel.deserialize(this._TradeHistoryInfoByIdRequestValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoByIdRequestValue)
        this.onReceive_TradeHistoryInfoByIdRequest(this._TradeHistoryInfoByIdRequestValue)
        return true
      }
      case TradeHistoryInfoByIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoByIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoByIdResponseModel.verify(), 'client.TradeHistoryInfoByIdResponse validation failed!')
        let deserialized = this._TradeHistoryInfoByIdResponseModel.deserialize(this._TradeHistoryInfoByIdResponseValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoByIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoByIdResponseValue)
        this.onReceive_TradeHistoryInfoByIdResponse(this._TradeHistoryInfoByIdResponseValue)
        return true
      }
      case TradeHistoryInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoUpdateNotifyModel.verify(), 'client.TradeHistoryInfoUpdateNotify validation failed!')
        let deserialized = this._TradeHistoryInfoUpdateNotifyModel.deserialize(this._TradeHistoryInfoUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoUpdateNotifyValue)
        this.onReceive_TradeHistoryInfoUpdateNotify(this._TradeHistoryInfoUpdateNotifyValue)
        return true
      }
      case CalendarRegularRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRegularRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleGetRequestModel.verify(), 'client.CalendarRegularRuleGetRequest validation failed!')
        let deserialized = this._CalendarRegularRuleGetRequestModel.deserialize(this._CalendarRegularRuleGetRequestValue)
        console.assert((deserialized.size > 0), 'client.CalendarRegularRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRegularRuleGetRequestValue)
        this.onReceive_CalendarRegularRuleGetRequest(this._CalendarRegularRuleGetRequestValue)
        return true
      }
      case CalendarRegularRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRegularRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleGetResponseModel.verify(), 'client.CalendarRegularRuleGetResponse validation failed!')
        let deserialized = this._CalendarRegularRuleGetResponseModel.deserialize(this._CalendarRegularRuleGetResponseValue)
        console.assert((deserialized.size > 0), 'client.CalendarRegularRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRegularRuleGetResponseValue)
        this.onReceive_CalendarRegularRuleGetResponse(this._CalendarRegularRuleGetResponseValue)
        return true
      }
      case CalendarRegularRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRegularRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleUpdateNotifyModel.verify(), 'client.CalendarRegularRuleUpdateNotify validation failed!')
        let deserialized = this._CalendarRegularRuleUpdateNotifyModel.deserialize(this._CalendarRegularRuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.CalendarRegularRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRegularRuleUpdateNotifyValue)
        this.onReceive_CalendarRegularRuleUpdateNotify(this._CalendarRegularRuleUpdateNotifyValue)
        return true
      }
      case CalendarOffTimeRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarOffTimeRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleGetRequestModel.verify(), 'client.CalendarOffTimeRuleGetRequest validation failed!')
        let deserialized = this._CalendarOffTimeRuleGetRequestModel.deserialize(this._CalendarOffTimeRuleGetRequestValue)
        console.assert((deserialized.size > 0), 'client.CalendarOffTimeRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarOffTimeRuleGetRequestValue)
        this.onReceive_CalendarOffTimeRuleGetRequest(this._CalendarOffTimeRuleGetRequestValue)
        return true
      }
      case CalendarOffTimeRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarOffTimeRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleGetResponseModel.verify(), 'client.CalendarOffTimeRuleGetResponse validation failed!')
        let deserialized = this._CalendarOffTimeRuleGetResponseModel.deserialize(this._CalendarOffTimeRuleGetResponseValue)
        console.assert((deserialized.size > 0), 'client.CalendarOffTimeRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarOffTimeRuleGetResponseValue)
        this.onReceive_CalendarOffTimeRuleGetResponse(this._CalendarOffTimeRuleGetResponseValue)
        return true
      }
      case CalendarOffTimeRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarOffTimeRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleUpdateNotifyModel.verify(), 'client.CalendarOffTimeRuleUpdateNotify validation failed!')
        let deserialized = this._CalendarOffTimeRuleUpdateNotifyModel.deserialize(this._CalendarOffTimeRuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'client.CalendarOffTimeRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarOffTimeRuleUpdateNotifyValue)
        this.onReceive_CalendarOffTimeRuleUpdateNotify(this._CalendarOffTimeRuleUpdateNotifyValue)
        return true
      }
      case CalendarRulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRulesGetRequestModel.verify(), 'client.CalendarRulesGetRequest validation failed!')
        let deserialized = this._CalendarRulesGetRequestModel.deserialize(this._CalendarRulesGetRequestValue)
        console.assert((deserialized.size > 0), 'client.CalendarRulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRulesGetRequestValue)
        this.onReceive_CalendarRulesGetRequest(this._CalendarRulesGetRequestValue)
        return true
      }
      case CalendarRulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRulesGetResponseModel.verify(), 'client.CalendarRulesGetResponse validation failed!')
        let deserialized = this._CalendarRulesGetResponseModel.deserialize(this._CalendarRulesGetResponseValue)
        console.assert((deserialized.size > 0), 'client.CalendarRulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRulesGetResponseValue)
        this.onReceive_CalendarRulesGetResponse(this._CalendarRulesGetResponseValue)
        return true
      }
      case ChartBarHistoryRangeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryRangeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRangeRequestModel.verify(), 'client.ChartBarHistoryRangeRequest validation failed!')
        let deserialized = this._ChartBarHistoryRangeRequestModel.deserialize(this._ChartBarHistoryRangeRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartBarHistoryRangeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryRangeRequestValue)
        this.onReceive_ChartBarHistoryRangeRequest(this._ChartBarHistoryRangeRequestValue)
        return true
      }
      case OrderMarginSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginSubscribeRequestModel.verify(), 'client.OrderMarginSubscribeRequest validation failed!')
        let deserialized = this._OrderMarginSubscribeRequestModel.deserialize(this._OrderMarginSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginSubscribeRequestValue)
        this.onReceive_OrderMarginSubscribeRequest(this._OrderMarginSubscribeRequestValue)
        return true
      }
      case OrderMarginSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginSubscribeResponseModel.verify(), 'client.OrderMarginSubscribeResponse validation failed!')
        let deserialized = this._OrderMarginSubscribeResponseModel.deserialize(this._OrderMarginSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginSubscribeResponseValue)
        this.onReceive_OrderMarginSubscribeResponse(this._OrderMarginSubscribeResponseValue)
        return true
      }
      case OrderMarginNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginNotifyModel.verify(), 'client.OrderMarginNotify validation failed!')
        let deserialized = this._OrderMarginNotifyModel.deserialize(this._OrderMarginNotifyValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginNotifyValue)
        this.onReceive_OrderMarginNotify(this._OrderMarginNotifyValue)
        return true
      }
      case OrderMarginUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUpdateSubscriptionRequestModel.verify(), 'client.OrderMarginUpdateSubscriptionRequest validation failed!')
        let deserialized = this._OrderMarginUpdateSubscriptionRequestModel.deserialize(this._OrderMarginUpdateSubscriptionRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginUpdateSubscriptionRequestValue)
        this.onReceive_OrderMarginUpdateSubscriptionRequest(this._OrderMarginUpdateSubscriptionRequestValue)
        return true
      }
      case OrderMarginUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUnsubscribeRequestModel.verify(), 'client.OrderMarginUnsubscribeRequest validation failed!')
        let deserialized = this._OrderMarginUnsubscribeRequestModel.deserialize(this._OrderMarginUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginUnsubscribeRequestValue)
        this.onReceive_OrderMarginUnsubscribeRequest(this._OrderMarginUnsubscribeRequestValue)
        return true
      }
      case OrderMarginUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUnsubscribeResponseModel.verify(), 'client.OrderMarginUnsubscribeResponse validation failed!')
        let deserialized = this._OrderMarginUnsubscribeResponseModel.deserialize(this._OrderMarginUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginUnsubscribeResponseValue)
        this.onReceive_OrderMarginUnsubscribeResponse(this._OrderMarginUnsubscribeResponseValue)
        return true
      }
      case OrderMarginGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginGetRequestModel.verify(), 'client.OrderMarginGetRequest validation failed!')
        let deserialized = this._OrderMarginGetRequestModel.deserialize(this._OrderMarginGetRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginGetRequestValue)
        this.onReceive_OrderMarginGetRequest(this._OrderMarginGetRequestValue)
        return true
      }
      case OrderMarginGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginGetResponseModel.verify(), 'client.OrderMarginGetResponse validation failed!')
        let deserialized = this._OrderMarginGetResponseModel.deserialize(this._OrderMarginGetResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginGetResponseValue)
        this.onReceive_OrderMarginGetResponse(this._OrderMarginGetResponseValue)
        return true
      }
      case OrderStateInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoSubscribeRequestModel.verify(), 'client.OrderStateInfoSubscribeRequest validation failed!')
        let deserialized = this._OrderStateInfoSubscribeRequestModel.deserialize(this._OrderStateInfoSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoSubscribeRequestValue)
        this.onReceive_OrderStateInfoSubscribeRequest(this._OrderStateInfoSubscribeRequestValue)
        return true
      }
      case OrderStateInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoSubscribeResponseModel.verify(), 'client.OrderStateInfoSubscribeResponse validation failed!')
        let deserialized = this._OrderStateInfoSubscribeResponseModel.deserialize(this._OrderStateInfoSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoSubscribeResponseValue)
        this.onReceive_OrderStateInfoSubscribeResponse(this._OrderStateInfoSubscribeResponseValue)
        return true
      }
      case OrderStateInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoUnsubscribeRequestModel.verify(), 'client.OrderStateInfoUnsubscribeRequest validation failed!')
        let deserialized = this._OrderStateInfoUnsubscribeRequestModel.deserialize(this._OrderStateInfoUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoUnsubscribeRequestValue)
        this.onReceive_OrderStateInfoUnsubscribeRequest(this._OrderStateInfoUnsubscribeRequestValue)
        return true
      }
      case OrderStateInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoUnsubscribeResponseModel.verify(), 'client.OrderStateInfoUnsubscribeResponse validation failed!')
        let deserialized = this._OrderStateInfoUnsubscribeResponseModel.deserialize(this._OrderStateInfoUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoUnsubscribeResponseValue)
        this.onReceive_OrderStateInfoUnsubscribeResponse(this._OrderStateInfoUnsubscribeResponseValue)
        return true
      }
      case OrderStateInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoNotifyModel.verify(), 'client.OrderStateInfoNotify validation failed!')
        let deserialized = this._OrderStateInfoNotifyModel.deserialize(this._OrderStateInfoNotifyValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoNotifyValue)
        this.onReceive_OrderStateInfoNotify(this._OrderStateInfoNotifyValue)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated003Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003Model.verify(), 'client.Deprecated003 validation failed!')
        let deserialized = this._Deprecated003Model.deserialize(this._Deprecated003Value)
        console.assert((deserialized.size > 0), 'client.Deprecated003 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated003Value)
        this.onReceive_Deprecated003(this._Deprecated003Value)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated004Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004Model.verify(), 'client.Deprecated004 validation failed!')
        let deserialized = this._Deprecated004Model.deserialize(this._Deprecated004Value)
        console.assert((deserialized.size > 0), 'client.Deprecated004 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated004Value)
        this.onReceive_Deprecated004(this._Deprecated004Value)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated005Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005Model.verify(), 'client.Deprecated005 validation failed!')
        let deserialized = this._Deprecated005Model.deserialize(this._Deprecated005Value)
        console.assert((deserialized.size > 0), 'client.Deprecated005 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated005Value)
        this.onReceive_Deprecated005(this._Deprecated005Value)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated006Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006Model.verify(), 'client.Deprecated006 validation failed!')
        let deserialized = this._Deprecated006Model.deserialize(this._Deprecated006Value)
        console.assert((deserialized.size > 0), 'client.Deprecated006 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated006Value)
        this.onReceive_Deprecated006(this._Deprecated006Value)
        return true
      }
      case Deprecated007Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated007Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated007Model.verify(), 'client.Deprecated007 validation failed!')
        let deserialized = this._Deprecated007Model.deserialize(this._Deprecated007Value)
        console.assert((deserialized.size > 0), 'client.Deprecated007 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated007Value)
        this.onReceive_Deprecated007(this._Deprecated007Value)
        return true
      }
      case Deprecated008Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated008Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated008Model.verify(), 'client.Deprecated008 validation failed!')
        let deserialized = this._Deprecated008Model.deserialize(this._Deprecated008Value)
        console.assert((deserialized.size > 0), 'client.Deprecated008 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated008Value)
        this.onReceive_Deprecated008(this._Deprecated008Value)
        return true
      }
      case AccountUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateRequestModel.verify(), 'client.AccountUpdateRequest validation failed!')
        let deserialized = this._AccountUpdateRequestModel.deserialize(this._AccountUpdateRequestValue)
        console.assert((deserialized.size > 0), 'client.AccountUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateRequestValue)
        this.onReceive_AccountUpdateRequest(this._AccountUpdateRequestValue)
        return true
      }
      case AccountUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateResponseModel.verify(), 'client.AccountUpdateResponse validation failed!')
        let deserialized = this._AccountUpdateResponseModel.deserialize(this._AccountUpdateResponseValue)
        console.assert((deserialized.size > 0), 'client.AccountUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateResponseValue)
        this.onReceive_AccountUpdateResponse(this._AccountUpdateResponseValue)
        return true
      }
      case ProfitClosedInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedInfoGetRequestModel.verify(), 'client.ProfitClosedInfoGetRequest validation failed!')
        let deserialized = this._ProfitClosedInfoGetRequestModel.deserialize(this._ProfitClosedInfoGetRequestValue)
        console.assert((deserialized.size > 0), 'client.ProfitClosedInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedInfoGetRequestValue)
        this.onReceive_ProfitClosedInfoGetRequest(this._ProfitClosedInfoGetRequestValue)
        return true
      }
      case ProfitClosedInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedInfoGetResponseModel.verify(), 'client.ProfitClosedInfoGetResponse validation failed!')
        let deserialized = this._ProfitClosedInfoGetResponseModel.deserialize(this._ProfitClosedInfoGetResponseValue)
        console.assert((deserialized.size > 0), 'client.ProfitClosedInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedInfoGetResponseValue)
        this.onReceive_ProfitClosedInfoGetResponse(this._ProfitClosedInfoGetResponseValue)
        return true
      }
      case SymbolDayProfitLossInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoSubscribeRequestModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoSubscribeRequestModel.deserialize(this._SymbolDayProfitLossInfoSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoSubscribeRequestValue)
        this.onReceive_SymbolDayProfitLossInfoSubscribeRequest(this._SymbolDayProfitLossInfoSubscribeRequestValue)
        return true
      }
      case SymbolDayProfitLossInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoSubscribeResponseModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeResponse validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoSubscribeResponseModel.deserialize(this._SymbolDayProfitLossInfoSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoSubscribeResponseValue)
        this.onReceive_SymbolDayProfitLossInfoSubscribeResponse(this._SymbolDayProfitLossInfoSubscribeResponseValue)
        return true
      }
      case SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.deserialize(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestValue)
        this.onReceive_SymbolDayProfitLossInfoUpdateSubscriptionRequest(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestValue)
        return true
      }
      case SymbolDayProfitLossInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUnsubscribeRequestModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoUnsubscribeRequestModel.deserialize(this._SymbolDayProfitLossInfoUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoUnsubscribeRequestValue)
        this.onReceive_SymbolDayProfitLossInfoUnsubscribeRequest(this._SymbolDayProfitLossInfoUnsubscribeRequestValue)
        return true
      }
      case SymbolDayProfitLossInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUnsubscribeResponseModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeResponse validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoUnsubscribeResponseModel.deserialize(this._SymbolDayProfitLossInfoUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoUnsubscribeResponseValue)
        this.onReceive_SymbolDayProfitLossInfoUnsubscribeResponse(this._SymbolDayProfitLossInfoUnsubscribeResponseValue)
        return true
      }
      case SymbolDayProfitLossInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoNotifyModel.verify(), 'client.SymbolDayProfitLossInfoNotify validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoNotifyModel.deserialize(this._SymbolDayProfitLossInfoNotifyValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoNotifyValue)
        this.onReceive_SymbolDayProfitLossInfoNotify(this._SymbolDayProfitLossInfoNotifyValue)
        return true
      }
      case LoginInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginInfoGetRequestModel.verify(), 'client.LoginInfoGetRequest validation failed!')
        let deserialized = this._LoginInfoGetRequestModel.deserialize(this._LoginInfoGetRequestValue)
        console.assert((deserialized.size > 0), 'client.LoginInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginInfoGetRequestValue)
        this.onReceive_LoginInfoGetRequest(this._LoginInfoGetRequestValue)
        return true
      }
      case LoginInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginInfoGetResponseModel.verify(), 'client.LoginInfoGetResponse validation failed!')
        let deserialized = this._LoginInfoGetResponseModel.deserialize(this._LoginInfoGetResponseValue)
        console.assert((deserialized.size > 0), 'client.LoginInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginInfoGetResponseValue)
        this.onReceive_LoginInfoGetResponse(this._LoginInfoGetResponseValue)
        return true
      }
      case DashboardInfoCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoCreateRequestModel.verify(), 'client.DashboardInfoCreateRequest validation failed!')
        let deserialized = this._DashboardInfoCreateRequestModel.deserialize(this._DashboardInfoCreateRequestValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoCreateRequestValue)
        this.onReceive_DashboardInfoCreateRequest(this._DashboardInfoCreateRequestValue)
        return true
      }
      case DashboardInfoCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoCreateResponseModel.verify(), 'client.DashboardInfoCreateResponse validation failed!')
        let deserialized = this._DashboardInfoCreateResponseModel.deserialize(this._DashboardInfoCreateResponseValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoCreateResponseValue)
        this.onReceive_DashboardInfoCreateResponse(this._DashboardInfoCreateResponseValue)
        return true
      }
      case DashboardInfoUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoUpdateRequestModel.verify(), 'client.DashboardInfoUpdateRequest validation failed!')
        let deserialized = this._DashboardInfoUpdateRequestModel.deserialize(this._DashboardInfoUpdateRequestValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoUpdateRequestValue)
        this.onReceive_DashboardInfoUpdateRequest(this._DashboardInfoUpdateRequestValue)
        return true
      }
      case DashboardInfoUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoUpdateResponseModel.verify(), 'client.DashboardInfoUpdateResponse validation failed!')
        let deserialized = this._DashboardInfoUpdateResponseModel.deserialize(this._DashboardInfoUpdateResponseValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoUpdateResponseValue)
        this.onReceive_DashboardInfoUpdateResponse(this._DashboardInfoUpdateResponseValue)
        return true
      }
      case DashboardInfoListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoListRequestModel.verify(), 'client.DashboardInfoListRequest validation failed!')
        let deserialized = this._DashboardInfoListRequestModel.deserialize(this._DashboardInfoListRequestValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoListRequestValue)
        this.onReceive_DashboardInfoListRequest(this._DashboardInfoListRequestValue)
        return true
      }
      case DashboardInfoListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoListResponseModel.verify(), 'client.DashboardInfoListResponse validation failed!')
        let deserialized = this._DashboardInfoListResponseModel.deserialize(this._DashboardInfoListResponseValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoListResponseValue)
        this.onReceive_DashboardInfoListResponse(this._DashboardInfoListResponseValue)
        return true
      }
      case DashboardInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoGetRequestModel.verify(), 'client.DashboardInfoGetRequest validation failed!')
        let deserialized = this._DashboardInfoGetRequestModel.deserialize(this._DashboardInfoGetRequestValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoGetRequestValue)
        this.onReceive_DashboardInfoGetRequest(this._DashboardInfoGetRequestValue)
        return true
      }
      case DashboardInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoGetResponseModel.verify(), 'client.DashboardInfoGetResponse validation failed!')
        let deserialized = this._DashboardInfoGetResponseModel.deserialize(this._DashboardInfoGetResponseValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoGetResponseValue)
        this.onReceive_DashboardInfoGetResponse(this._DashboardInfoGetResponseValue)
        return true
      }
      case DashboardInfoRenameRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoRenameRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoRenameRequestModel.verify(), 'client.DashboardInfoRenameRequest validation failed!')
        let deserialized = this._DashboardInfoRenameRequestModel.deserialize(this._DashboardInfoRenameRequestValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoRenameRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoRenameRequestValue)
        this.onReceive_DashboardInfoRenameRequest(this._DashboardInfoRenameRequestValue)
        return true
      }
      case DashboardInfoRenameResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoRenameResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoRenameResponseModel.verify(), 'client.DashboardInfoRenameResponse validation failed!')
        let deserialized = this._DashboardInfoRenameResponseModel.deserialize(this._DashboardInfoRenameResponseValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoRenameResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoRenameResponseValue)
        this.onReceive_DashboardInfoRenameResponse(this._DashboardInfoRenameResponseValue)
        return true
      }
      case DashboardInfoDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoDeleteRequestModel.verify(), 'client.DashboardInfoDeleteRequest validation failed!')
        let deserialized = this._DashboardInfoDeleteRequestModel.deserialize(this._DashboardInfoDeleteRequestValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoDeleteRequestValue)
        this.onReceive_DashboardInfoDeleteRequest(this._DashboardInfoDeleteRequestValue)
        return true
      }
      case DashboardInfoDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoDeleteResponseModel.verify(), 'client.DashboardInfoDeleteResponse validation failed!')
        let deserialized = this._DashboardInfoDeleteResponseModel.deserialize(this._DashboardInfoDeleteResponseValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoDeleteResponseValue)
        this.onReceive_DashboardInfoDeleteResponse(this._DashboardInfoDeleteResponseValue)
        return true
      }
      case ChartInfoCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoCreateRequestModel.verify(), 'client.ChartInfoCreateRequest validation failed!')
        let deserialized = this._ChartInfoCreateRequestModel.deserialize(this._ChartInfoCreateRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoCreateRequestValue)
        this.onReceive_ChartInfoCreateRequest(this._ChartInfoCreateRequestValue)
        return true
      }
      case ChartInfoCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoCreateResponseModel.verify(), 'client.ChartInfoCreateResponse validation failed!')
        let deserialized = this._ChartInfoCreateResponseModel.deserialize(this._ChartInfoCreateResponseValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoCreateResponseValue)
        this.onReceive_ChartInfoCreateResponse(this._ChartInfoCreateResponseValue)
        return true
      }
      case ChartInfoUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoUpdateRequestModel.verify(), 'client.ChartInfoUpdateRequest validation failed!')
        let deserialized = this._ChartInfoUpdateRequestModel.deserialize(this._ChartInfoUpdateRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoUpdateRequestValue)
        this.onReceive_ChartInfoUpdateRequest(this._ChartInfoUpdateRequestValue)
        return true
      }
      case ChartInfoUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoUpdateResponseModel.verify(), 'client.ChartInfoUpdateResponse validation failed!')
        let deserialized = this._ChartInfoUpdateResponseModel.deserialize(this._ChartInfoUpdateResponseValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoUpdateResponseValue)
        this.onReceive_ChartInfoUpdateResponse(this._ChartInfoUpdateResponseValue)
        return true
      }
      case ChartInfoListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoListRequestModel.verify(), 'client.ChartInfoListRequest validation failed!')
        let deserialized = this._ChartInfoListRequestModel.deserialize(this._ChartInfoListRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoListRequestValue)
        this.onReceive_ChartInfoListRequest(this._ChartInfoListRequestValue)
        return true
      }
      case ChartInfoListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoListResponseModel.verify(), 'client.ChartInfoListResponse validation failed!')
        let deserialized = this._ChartInfoListResponseModel.deserialize(this._ChartInfoListResponseValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoListResponseValue)
        this.onReceive_ChartInfoListResponse(this._ChartInfoListResponseValue)
        return true
      }
      case ChartInfoDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoDeleteRequestModel.verify(), 'client.ChartInfoDeleteRequest validation failed!')
        let deserialized = this._ChartInfoDeleteRequestModel.deserialize(this._ChartInfoDeleteRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoDeleteRequestValue)
        this.onReceive_ChartInfoDeleteRequest(this._ChartInfoDeleteRequestValue)
        return true
      }
      case ChartInfoDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoDeleteResponseModel.verify(), 'client.ChartInfoDeleteResponse validation failed!')
        let deserialized = this._ChartInfoDeleteResponseModel.deserialize(this._ChartInfoDeleteResponseValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoDeleteResponseValue)
        this.onReceive_ChartInfoDeleteResponse(this._ChartInfoDeleteResponseValue)
        return true
      }
      case ChartInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoGetRequestModel.verify(), 'client.ChartInfoGetRequest validation failed!')
        let deserialized = this._ChartInfoGetRequestModel.deserialize(this._ChartInfoGetRequestValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoGetRequestValue)
        this.onReceive_ChartInfoGetRequest(this._ChartInfoGetRequestValue)
        return true
      }
      case ChartInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoGetResponseModel.verify(), 'client.ChartInfoGetResponse validation failed!')
        let deserialized = this._ChartInfoGetResponseModel.deserialize(this._ChartInfoGetResponseValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoGetResponseValue)
        this.onReceive_ChartInfoGetResponse(this._ChartInfoGetResponseValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel.deserialize(this._SymbolDayProfitLossMultipleInfoSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoSubscribeRequestValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoSubscribeRequest(this._SymbolDayProfitLossMultipleInfoSubscribeRequestValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel.deserialize(this._SymbolDayProfitLossMultipleInfoSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoSubscribeResponseValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoSubscribeResponse(this._SymbolDayProfitLossMultipleInfoSubscribeResponseValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.deserialize(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.deserialize(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoUnsubscribeRequest(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.deserialize(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoUnsubscribeResponse(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoNotifyModel.verify(), 'client.SymbolDayProfitLossMultipleInfoNotify validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoNotifyModel.deserialize(this._SymbolDayProfitLossMultipleInfoNotifyValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoNotifyValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoNotify(this._SymbolDayProfitLossMultipleInfoNotifyValue)
        return true
      }
      case NewsServiceGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsServiceGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsServiceGetAllRequestModel.verify(), 'client.NewsServiceGetAllRequest validation failed!')
        let deserialized = this._NewsServiceGetAllRequestModel.deserialize(this._NewsServiceGetAllRequestValue)
        console.assert((deserialized.size > 0), 'client.NewsServiceGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsServiceGetAllRequestValue)
        this.onReceive_NewsServiceGetAllRequest(this._NewsServiceGetAllRequestValue)
        return true
      }
      case NewsServiceGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsServiceGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsServiceGetAllResponseModel.verify(), 'client.NewsServiceGetAllResponse validation failed!')
        let deserialized = this._NewsServiceGetAllResponseModel.deserialize(this._NewsServiceGetAllResponseValue)
        console.assert((deserialized.size > 0), 'client.NewsServiceGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsServiceGetAllResponseValue)
        this.onReceive_NewsServiceGetAllResponse(this._NewsServiceGetAllResponseValue)
        return true
      }
      case NewsRecentArticlesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsRecentArticlesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsRecentArticlesGetRequestModel.verify(), 'client.NewsRecentArticlesGetRequest validation failed!')
        let deserialized = this._NewsRecentArticlesGetRequestModel.deserialize(this._NewsRecentArticlesGetRequestValue)
        console.assert((deserialized.size > 0), 'client.NewsRecentArticlesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsRecentArticlesGetRequestValue)
        this.onReceive_NewsRecentArticlesGetRequest(this._NewsRecentArticlesGetRequestValue)
        return true
      }
      case NewsRecentArticlesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsRecentArticlesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsRecentArticlesGetResponseModel.verify(), 'client.NewsRecentArticlesGetResponse validation failed!')
        let deserialized = this._NewsRecentArticlesGetResponseModel.deserialize(this._NewsRecentArticlesGetResponseValue)
        console.assert((deserialized.size > 0), 'client.NewsRecentArticlesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsRecentArticlesGetResponseValue)
        this.onReceive_NewsRecentArticlesGetResponse(this._NewsRecentArticlesGetResponseValue)
        return true
      }
      case NewsArticleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsArticleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsArticleGetRequestModel.verify(), 'client.NewsArticleGetRequest validation failed!')
        let deserialized = this._NewsArticleGetRequestModel.deserialize(this._NewsArticleGetRequestValue)
        console.assert((deserialized.size > 0), 'client.NewsArticleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsArticleGetRequestValue)
        this.onReceive_NewsArticleGetRequest(this._NewsArticleGetRequestValue)
        return true
      }
      case NewsArticleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsArticleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsArticleGetResponseModel.verify(), 'client.NewsArticleGetResponse validation failed!')
        let deserialized = this._NewsArticleGetResponseModel.deserialize(this._NewsArticleGetResponseValue)
        console.assert((deserialized.size > 0), 'client.NewsArticleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsArticleGetResponseValue)
        this.onReceive_NewsArticleGetResponse(this._NewsArticleGetResponseValue)
        return true
      }
      case OrderUpdateWithTypeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithTypeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeRequestModel.verify(), 'client.OrderUpdateWithTypeRequest validation failed!')
        let deserialized = this._OrderUpdateWithTypeRequestModel.deserialize(this._OrderUpdateWithTypeRequestValue)
        console.assert((deserialized.size > 0), 'client.OrderUpdateWithTypeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithTypeRequestValue)
        this.onReceive_OrderUpdateWithTypeRequest(this._OrderUpdateWithTypeRequestValue)
        return true
      }
      case OrderUpdateWithTypeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithTypeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeResponseModel.verify(), 'client.OrderUpdateWithTypeResponse validation failed!')
        let deserialized = this._OrderUpdateWithTypeResponseModel.deserialize(this._OrderUpdateWithTypeResponseValue)
        console.assert((deserialized.size > 0), 'client.OrderUpdateWithTypeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithTypeResponseValue)
        this.onReceive_OrderUpdateWithTypeResponse(this._OrderUpdateWithTypeResponseValue)
        return true
      }
      case SymbolProfitLossMultipleInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoSubscribeRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeRequest validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoSubscribeRequestModel.deserialize(this._SymbolProfitLossMultipleInfoSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoSubscribeRequestValue)
        this.onReceive_SymbolProfitLossMultipleInfoSubscribeRequest(this._SymbolProfitLossMultipleInfoSubscribeRequestValue)
        return true
      }
      case SymbolProfitLossMultipleInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoSubscribeResponseModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeResponse validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoSubscribeResponseModel.deserialize(this._SymbolProfitLossMultipleInfoSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoSubscribeResponseValue)
        this.onReceive_SymbolProfitLossMultipleInfoSubscribeResponse(this._SymbolProfitLossMultipleInfoSubscribeResponseValue)
        return true
      }
      case SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.deserialize(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestValue)
        this.onReceive_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestValue)
        return true
      }
      case SymbolProfitLossMultipleInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel.deserialize(this._SymbolProfitLossMultipleInfoUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoUnsubscribeRequestValue)
        this.onReceive_SymbolProfitLossMultipleInfoUnsubscribeRequest(this._SymbolProfitLossMultipleInfoUnsubscribeRequestValue)
        return true
      }
      case SymbolProfitLossMultipleInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel.deserialize(this._SymbolProfitLossMultipleInfoUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoUnsubscribeResponseValue)
        this.onReceive_SymbolProfitLossMultipleInfoUnsubscribeResponse(this._SymbolProfitLossMultipleInfoUnsubscribeResponseValue)
        return true
      }
      case SymbolProfitLossMultipleInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoNotifyModel.verify(), 'client.SymbolProfitLossMultipleInfoNotify validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoNotifyModel.deserialize(this._SymbolProfitLossMultipleInfoNotifyValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoNotifyValue)
        this.onReceive_SymbolProfitLossMultipleInfoNotify(this._SymbolProfitLossMultipleInfoNotifyValue)
        return true
      }
      case LoginWithPasswordRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithPasswordRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordRequestModel.verify(), 'client.LoginWithPasswordRequest validation failed!')
        let deserialized = this._LoginWithPasswordRequestModel.deserialize(this._LoginWithPasswordRequestValue)
        console.assert((deserialized.size > 0), 'client.LoginWithPasswordRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithPasswordRequestValue)
        this.onReceive_LoginWithPasswordRequest(this._LoginWithPasswordRequestValue)
        return true
      }
      case LoginWithPasswordResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithPasswordResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordResponseModel.verify(), 'client.LoginWithPasswordResponse validation failed!')
        let deserialized = this._LoginWithPasswordResponseModel.deserialize(this._LoginWithPasswordResponseValue)
        console.assert((deserialized.size > 0), 'client.LoginWithPasswordResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithPasswordResponseValue)
        this.onReceive_LoginWithPasswordResponse(this._LoginWithPasswordResponseValue)
        return true
      }
      case LoginWithTokenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithTokenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenRequestModel.verify(), 'client.LoginWithTokenRequest validation failed!')
        let deserialized = this._LoginWithTokenRequestModel.deserialize(this._LoginWithTokenRequestValue)
        console.assert((deserialized.size > 0), 'client.LoginWithTokenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithTokenRequestValue)
        this.onReceive_LoginWithTokenRequest(this._LoginWithTokenRequestValue)
        return true
      }
      case LoginWithTokenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithTokenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenResponseModel.verify(), 'client.LoginWithTokenResponse validation failed!')
        let deserialized = this._LoginWithTokenResponseModel.deserialize(this._LoginWithTokenResponseValue)
        console.assert((deserialized.size > 0), 'client.LoginWithTokenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithTokenResponseValue)
        this.onReceive_LoginWithTokenResponse(this._LoginWithTokenResponseValue)
        return true
      }
      case RefreshTokenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RefreshTokenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenRequestModel.verify(), 'client.RefreshTokenRequest validation failed!')
        let deserialized = this._RefreshTokenRequestModel.deserialize(this._RefreshTokenRequestValue)
        console.assert((deserialized.size > 0), 'client.RefreshTokenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RefreshTokenRequestValue)
        this.onReceive_RefreshTokenRequest(this._RefreshTokenRequestValue)
        return true
      }
      case RefreshTokenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RefreshTokenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenResponseModel.verify(), 'client.RefreshTokenResponse validation failed!')
        let deserialized = this._RefreshTokenResponseModel.deserialize(this._RefreshTokenResponseValue)
        console.assert((deserialized.size > 0), 'client.RefreshTokenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RefreshTokenResponseValue)
        this.onReceive_RefreshTokenResponse(this._RefreshTokenResponseValue)
        return true
      }
      case Logout2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Logout2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._Logout2RequestModel.verify(), 'client.Logout2Request validation failed!')
        let deserialized = this._Logout2RequestModel.deserialize(this._Logout2RequestValue)
        console.assert((deserialized.size > 0), 'client.Logout2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Logout2RequestValue)
        this.onReceive_Logout2Request(this._Logout2RequestValue)
        return true
      }
      case Logout2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Logout2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Logout2ResponseModel.verify(), 'client.Logout2Response validation failed!')
        let deserialized = this._Logout2ResponseModel.deserialize(this._Logout2ResponseValue)
        console.assert((deserialized.size > 0), 'client.Logout2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Logout2ResponseValue)
        this.onReceive_Logout2Response(this._Logout2ResponseValue)
        return true
      }
      case AccountChangePasswordRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountChangePasswordRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountChangePasswordRequestModel.verify(), 'client.AccountChangePasswordRequest validation failed!')
        let deserialized = this._AccountChangePasswordRequestModel.deserialize(this._AccountChangePasswordRequestValue)
        console.assert((deserialized.size > 0), 'client.AccountChangePasswordRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountChangePasswordRequestValue)
        this.onReceive_AccountChangePasswordRequest(this._AccountChangePasswordRequestValue)
        return true
      }
      case AccountChangePasswordResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountChangePasswordResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountChangePasswordResponseModel.verify(), 'client.AccountChangePasswordResponse validation failed!')
        let deserialized = this._AccountChangePasswordResponseModel.deserialize(this._AccountChangePasswordResponseValue)
        console.assert((deserialized.size > 0), 'client.AccountChangePasswordResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountChangePasswordResponseValue)
        this.onReceive_AccountChangePasswordResponse(this._AccountChangePasswordResponseValue)
        return true
      }
      case PushNotificationTokenRegisterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PushNotificationTokenRegisterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenRegisterRequestModel.verify(), 'client.PushNotificationTokenRegisterRequest validation failed!')
        let deserialized = this._PushNotificationTokenRegisterRequestModel.deserialize(this._PushNotificationTokenRegisterRequestValue)
        console.assert((deserialized.size > 0), 'client.PushNotificationTokenRegisterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PushNotificationTokenRegisterRequestValue)
        this.onReceive_PushNotificationTokenRegisterRequest(this._PushNotificationTokenRegisterRequestValue)
        return true
      }
      case PushNotificationTokenRegisterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PushNotificationTokenRegisterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenRegisterResponseModel.verify(), 'client.PushNotificationTokenRegisterResponse validation failed!')
        let deserialized = this._PushNotificationTokenRegisterResponseModel.deserialize(this._PushNotificationTokenRegisterResponseValue)
        console.assert((deserialized.size > 0), 'client.PushNotificationTokenRegisterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PushNotificationTokenRegisterResponseValue)
        this.onReceive_PushNotificationTokenRegisterResponse(this._PushNotificationTokenRegisterResponseValue)
        return true
      }
      case DesiredAppSettingsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DesiredAppSettingsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DesiredAppSettingsGetRequestModel.verify(), 'client.DesiredAppSettingsGetRequest validation failed!')
        let deserialized = this._DesiredAppSettingsGetRequestModel.deserialize(this._DesiredAppSettingsGetRequestValue)
        console.assert((deserialized.size > 0), 'client.DesiredAppSettingsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DesiredAppSettingsGetRequestValue)
        this.onReceive_DesiredAppSettingsGetRequest(this._DesiredAppSettingsGetRequestValue)
        return true
      }
      case DesiredAppSettingsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DesiredAppSettingsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DesiredAppSettingsGetResponseModel.verify(), 'client.DesiredAppSettingsGetResponse validation failed!')
        let deserialized = this._DesiredAppSettingsGetResponseModel.deserialize(this._DesiredAppSettingsGetResponseValue)
        console.assert((deserialized.size > 0), 'client.DesiredAppSettingsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DesiredAppSettingsGetResponseValue)
        this.onReceive_DesiredAppSettingsGetResponse(this._DesiredAppSettingsGetResponseValue)
        return true
      }
      case AuthTokensGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AuthTokensGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensGetAllRequestModel.verify(), 'client.AuthTokensGetAllRequest validation failed!')
        let deserialized = this._AuthTokensGetAllRequestModel.deserialize(this._AuthTokensGetAllRequestValue)
        console.assert((deserialized.size > 0), 'client.AuthTokensGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AuthTokensGetAllRequestValue)
        this.onReceive_AuthTokensGetAllRequest(this._AuthTokensGetAllRequestValue)
        return true
      }
      case AuthTokensGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AuthTokensGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensGetAllResponseModel.verify(), 'client.AuthTokensGetAllResponse validation failed!')
        let deserialized = this._AuthTokensGetAllResponseModel.deserialize(this._AuthTokensGetAllResponseValue)
        console.assert((deserialized.size > 0), 'client.AuthTokensGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AuthTokensGetAllResponseValue)
        this.onReceive_AuthTokensGetAllResponse(this._AuthTokensGetAllResponseValue)
        return true
      }
      case AuthTokensDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AuthTokensDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensDeleteRequestModel.verify(), 'client.AuthTokensDeleteRequest validation failed!')
        let deserialized = this._AuthTokensDeleteRequestModel.deserialize(this._AuthTokensDeleteRequestValue)
        console.assert((deserialized.size > 0), 'client.AuthTokensDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AuthTokensDeleteRequestValue)
        this.onReceive_AuthTokensDeleteRequest(this._AuthTokensDeleteRequestValue)
        return true
      }
      case AuthTokensDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AuthTokensDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensDeleteResponseModel.verify(), 'client.AuthTokensDeleteResponse validation failed!')
        let deserialized = this._AuthTokensDeleteResponseModel.deserialize(this._AuthTokensDeleteResponseValue)
        console.assert((deserialized.size > 0), 'client.AuthTokensDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AuthTokensDeleteResponseValue)
        this.onReceive_AuthTokensDeleteResponse(this._AuthTokensDeleteResponseValue)
        return true
      }
      case LegalDocumentGroupListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupListRequestModel.verify(), 'client.LegalDocumentGroupListRequest validation failed!')
        let deserialized = this._LegalDocumentGroupListRequestModel.deserialize(this._LegalDocumentGroupListRequestValue)
        console.assert((deserialized.size > 0), 'client.LegalDocumentGroupListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupListRequestValue)
        this.onReceive_LegalDocumentGroupListRequest(this._LegalDocumentGroupListRequestValue)
        return true
      }
      case LegalDocumentGroupListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupListResponseModel.verify(), 'client.LegalDocumentGroupListResponse validation failed!')
        let deserialized = this._LegalDocumentGroupListResponseModel.deserialize(this._LegalDocumentGroupListResponseValue)
        console.assert((deserialized.size > 0), 'client.LegalDocumentGroupListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupListResponseValue)
        this.onReceive_LegalDocumentGroupListResponse(this._LegalDocumentGroupListResponseValue)
        return true
      }
      case LegalDocumentGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetRequestModel.verify(), 'client.LegalDocumentGetRequest validation failed!')
        let deserialized = this._LegalDocumentGetRequestModel.deserialize(this._LegalDocumentGetRequestValue)
        console.assert((deserialized.size > 0), 'client.LegalDocumentGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetRequestValue)
        this.onReceive_LegalDocumentGetRequest(this._LegalDocumentGetRequestValue)
        return true
      }
      case LegalDocumentGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResponseModel.verify(), 'client.LegalDocumentGetResponse validation failed!')
        let deserialized = this._LegalDocumentGetResponseModel.deserialize(this._LegalDocumentGetResponseValue)
        console.assert((deserialized.size > 0), 'client.LegalDocumentGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetResponseValue)
        this.onReceive_LegalDocumentGetResponse(this._LegalDocumentGetResponseValue)
        return true
      }
      case PushNotificationTokenUnRegisterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PushNotificationTokenUnRegisterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenUnRegisterRequestModel.verify(), 'client.PushNotificationTokenUnRegisterRequest validation failed!')
        let deserialized = this._PushNotificationTokenUnRegisterRequestModel.deserialize(this._PushNotificationTokenUnRegisterRequestValue)
        console.assert((deserialized.size > 0), 'client.PushNotificationTokenUnRegisterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PushNotificationTokenUnRegisterRequestValue)
        this.onReceive_PushNotificationTokenUnRegisterRequest(this._PushNotificationTokenUnRegisterRequestValue)
        return true
      }
      case PushNotificationTokenUnRegisterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PushNotificationTokenUnRegisterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenUnRegisterResponseModel.verify(), 'client.PushNotificationTokenUnRegisterResponse validation failed!')
        let deserialized = this._PushNotificationTokenUnRegisterResponseModel.deserialize(this._PushNotificationTokenUnRegisterResponseValue)
        console.assert((deserialized.size > 0), 'client.PushNotificationTokenUnRegisterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PushNotificationTokenUnRegisterResponseValue)
        this.onReceive_PushNotificationTokenUnRegisterResponse(this._PushNotificationTokenUnRegisterResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.exposedReceiver != null) && this.exposedReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
    this._exposedReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding client proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize client proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._exposedProxy = new exposed.Proxy(this.buffer)
    this._PingRequestModel = new PingRequestModel()
    this._PongResponseModel = new PongResponseModel()
    this._LoginRequestModel = new LoginRequestModel()
    this._LoginResponseModel = new LoginResponseModel()
    this._LoginRejectModel = new LoginRejectModel()
    this._LogoutRequestModel = new LogoutRequestModel()
    this._LogoutResponseModel = new LogoutResponseModel()
    this._LogoutNotifyModel = new LogoutNotifyModel()
    this._SessionInfoRequestModel = new SessionInfoRequestModel()
    this._SessionInfoResponseModel = new SessionInfoResponseModel()
    this._TimeInfoRequestModel = new TimeInfoRequestModel()
    this._TimeInfoResponseModel = new TimeInfoResponseModel()
    this._TimezoneInfoRequestModel = new TimezoneInfoRequestModel()
    this._TimezoneInfoResponseModel = new TimezoneInfoResponseModel()
    this._TimezoneInfoUpdateNotifyModel = new TimezoneInfoUpdateNotifyModel()
    this._CurrencyInfoRequestModel = new CurrencyInfoRequestModel()
    this._CurrencyInfoResponseModel = new CurrencyInfoResponseModel()
    this._CurrencyListRequestModel = new CurrencyListRequestModel()
    this._CurrencyListResponseModel = new CurrencyListResponseModel()
    this._CurrencyInfoUpdateNotifyModel = new CurrencyInfoUpdateNotifyModel()
    this._SymbolInfoRequestModel = new SymbolInfoRequestModel()
    this._SymbolInfoResponseModel = new SymbolInfoResponseModel()
    this._SymbolListRequestModel = new SymbolListRequestModel()
    this._SymbolListResponseModel = new SymbolListResponseModel()
    this._SymbolInfoUpdateNotifyModel = new SymbolInfoUpdateNotifyModel()
    this._TickInfoRequestModel = new TickInfoRequestModel()
    this._TickInfoResponseModel = new TickInfoResponseModel()
    this._TickFilterRequestModel = new TickFilterRequestModel()
    this._TickFilterResponseModel = new TickFilterResponseModel()
    this._TickListRequestModel = new TickListRequestModel()
    this._TickListResponseModel = new TickListResponseModel()
    this._Level2InfoRequestModel = new Level2InfoRequestModel()
    this._Level2InfoResponseModel = new Level2InfoResponseModel()
    this._Level2FilterRequestModel = new Level2FilterRequestModel()
    this._Level2FilterResponseModel = new Level2FilterResponseModel()
    this._Level2ListRequestModel = new Level2ListRequestModel()
    this._Level2ListResponseModel = new Level2ListResponseModel()
    this._FeedSubscriptionInfoRequestModel = new FeedSubscriptionInfoRequestModel()
    this._FeedSubscriptionInfoResponseModel = new FeedSubscriptionInfoResponseModel()
    this._FeedSubscribeRequestModel = new FeedSubscribeRequestModel()
    this._FeedSubscribeAllRequestModel = new FeedSubscribeAllRequestModel()
    this._FeedSubscribeResponseModel = new FeedSubscribeResponseModel()
    this._FeedUnsubscribeRequestModel = new FeedUnsubscribeRequestModel()
    this._FeedUnsubscribeAllRequestModel = new FeedUnsubscribeAllRequestModel()
    this._FeedUnsubscribeResponseModel = new FeedUnsubscribeResponseModel()
    this._TickSnapshotNotifyModel = new TickSnapshotNotifyModel()
    this._TickUpdateNotifyModel = new TickUpdateNotifyModel()
    this._Level2SnapshotNotifyModel = new Level2SnapshotNotifyModel()
    this._Level2UpdateNotifyModel = new Level2UpdateNotifyModel()
    this._ChartBarHistoryRequestModel = new ChartBarHistoryRequestModel()
    this._ChartBarHistoryResponseModel = new ChartBarHistoryResponseModel()
    this._ChartBarSubscribeRequestModel = new ChartBarSubscribeRequestModel()
    this._ChartBarSubscribeResponseModel = new ChartBarSubscribeResponseModel()
    this._ChartBarUnsubscribeRequestModel = new ChartBarUnsubscribeRequestModel()
    this._ChartBarUnsubscribeResponseModel = new ChartBarUnsubscribeResponseModel()
    this._ChartBarNotifyModel = new ChartBarNotifyModel()
    this._ChartBarUpdateNotifyModel = new ChartBarUpdateNotifyModel()
    this._PlatformInfoRequestModel = new PlatformInfoRequestModel()
    this._PlatformInfoResponseModel = new PlatformInfoResponseModel()
    this._PlatformInfoUpdateNotifyModel = new PlatformInfoUpdateNotifyModel()
    this._AccountInfoRequestModel = new AccountInfoRequestModel()
    this._AccountInfoResponseModel = new AccountInfoResponseModel()
    this._AccountInfoUpdateNotifyModel = new AccountInfoUpdateNotifyModel()
    this._MarginInfoRequestModel = new MarginInfoRequestModel()
    this._MarginInfoResponseModel = new MarginInfoResponseModel()
    this._MarginInfoUpdateNotifyModel = new MarginInfoUpdateNotifyModel()
    this._MarginCallNotifyModel = new MarginCallNotifyModel()
    this._StopOutNotifyModel = new StopOutNotifyModel()
    this._AssetInfoRequestModel = new AssetInfoRequestModel()
    this._AssetInfoResponseModel = new AssetInfoResponseModel()
    this._AssetListRequestModel = new AssetListRequestModel()
    this._AssetListResponseModel = new AssetListResponseModel()
    this._AssetInfoUpdateNotifyModel = new AssetInfoUpdateNotifyModel()
    this._OrderInfoRequestModel = new OrderInfoRequestModel()
    this._OrderInfoResponseModel = new OrderInfoResponseModel()
    this._OrderListRequestModel = new OrderListRequestModel()
    this._OrderListResponseModel = new OrderListResponseModel()
    this._OrderOpenRequestModel = new OrderOpenRequestModel()
    this._OrderOpenResponseModel = new OrderOpenResponseModel()
    this._OrderUpdateRequestModel = new OrderUpdateRequestModel()
    this._OrderUpdateResponseModel = new OrderUpdateResponseModel()
    this._OrderCancelRequestModel = new OrderCancelRequestModel()
    this._OrderCancelResponseModel = new OrderCancelResponseModel()
    this._OrderCancelAllRequestModel = new OrderCancelAllRequestModel()
    this._OrderCancelAllResponseModel = new OrderCancelAllResponseModel()
    this._OrderCancelAllByIdRequestModel = new OrderCancelAllByIdRequestModel()
    this._OrderCloseRequestModel = new OrderCloseRequestModel()
    this._OrderCloseResponseModel = new OrderCloseResponseModel()
    this._OrderCloseAllRequestModel = new OrderCloseAllRequestModel()
    this._OrderCloseAllResponseModel = new OrderCloseAllResponseModel()
    this._OrderCloseAllByIdRequestModel = new OrderCloseAllByIdRequestModel()
    this._OrderCloseByRequestModel = new OrderCloseByRequestModel()
    this._OrderCloseByResponseModel = new OrderCloseByResponseModel()
    this._OrderCloseByAllRequestModel = new OrderCloseByAllRequestModel()
    this._OrderCloseByAllResponseModel = new OrderCloseByAllResponseModel()
    this._OrderCancelOrCloseRequestModel = new OrderCancelOrCloseRequestModel()
    this._OrderCancelOrCloseResponseModel = new OrderCancelOrCloseResponseModel()
    this._OrderCancelOrCloseAllRequestModel = new OrderCancelOrCloseAllRequestModel()
    this._OrderCancelOrCloseAllResponseModel = new OrderCancelOrCloseAllResponseModel()
    this._OrderInfoUpdateNotifyModel = new OrderInfoUpdateNotifyModel()
    this._PositionInfoRequestModel = new PositionInfoRequestModel()
    this._PositionInfoResponseModel = new PositionInfoResponseModel()
    this._PositionListRequestModel = new PositionListRequestModel()
    this._PositionListResponseModel = new PositionListResponseModel()
    this._PositionInfoUpdateNotifyModel = new PositionInfoUpdateNotifyModel()
    this._TradeHistoryInfoRequestModel = new TradeHistoryInfoRequestModel()
    this._TradeHistoryInfoResponseModel = new TradeHistoryInfoResponseModel()
    this._TradeHistoryInfoFilterRequestModel = new TradeHistoryInfoFilterRequestModel()
    this._TradeHistoryInfoByIdRequestModel = new TradeHistoryInfoByIdRequestModel()
    this._TradeHistoryInfoByIdResponseModel = new TradeHistoryInfoByIdResponseModel()
    this._TradeHistoryInfoUpdateNotifyModel = new TradeHistoryInfoUpdateNotifyModel()
    this._CalendarRegularRuleGetRequestModel = new CalendarRegularRuleGetRequestModel()
    this._CalendarRegularRuleGetResponseModel = new CalendarRegularRuleGetResponseModel()
    this._CalendarRegularRuleUpdateNotifyModel = new CalendarRegularRuleUpdateNotifyModel()
    this._CalendarOffTimeRuleGetRequestModel = new CalendarOffTimeRuleGetRequestModel()
    this._CalendarOffTimeRuleGetResponseModel = new CalendarOffTimeRuleGetResponseModel()
    this._CalendarOffTimeRuleUpdateNotifyModel = new CalendarOffTimeRuleUpdateNotifyModel()
    this._CalendarRulesGetRequestModel = new CalendarRulesGetRequestModel()
    this._CalendarRulesGetResponseModel = new CalendarRulesGetResponseModel()
    this._ChartBarHistoryRangeRequestModel = new ChartBarHistoryRangeRequestModel()
    this._OrderMarginSubscribeRequestModel = new OrderMarginSubscribeRequestModel()
    this._OrderMarginSubscribeResponseModel = new OrderMarginSubscribeResponseModel()
    this._OrderMarginNotifyModel = new OrderMarginNotifyModel()
    this._OrderMarginUpdateSubscriptionRequestModel = new OrderMarginUpdateSubscriptionRequestModel()
    this._OrderMarginUnsubscribeRequestModel = new OrderMarginUnsubscribeRequestModel()
    this._OrderMarginUnsubscribeResponseModel = new OrderMarginUnsubscribeResponseModel()
    this._OrderMarginGetRequestModel = new OrderMarginGetRequestModel()
    this._OrderMarginGetResponseModel = new OrderMarginGetResponseModel()
    this._OrderStateInfoSubscribeRequestModel = new OrderStateInfoSubscribeRequestModel()
    this._OrderStateInfoSubscribeResponseModel = new OrderStateInfoSubscribeResponseModel()
    this._OrderStateInfoUnsubscribeRequestModel = new OrderStateInfoUnsubscribeRequestModel()
    this._OrderStateInfoUnsubscribeResponseModel = new OrderStateInfoUnsubscribeResponseModel()
    this._OrderStateInfoNotifyModel = new OrderStateInfoNotifyModel()
    this._Deprecated003Model = new Deprecated003Model()
    this._Deprecated004Model = new Deprecated004Model()
    this._Deprecated005Model = new Deprecated005Model()
    this._Deprecated006Model = new Deprecated006Model()
    this._Deprecated007Model = new Deprecated007Model()
    this._Deprecated008Model = new Deprecated008Model()
    this._AccountUpdateRequestModel = new AccountUpdateRequestModel()
    this._AccountUpdateResponseModel = new AccountUpdateResponseModel()
    this._ProfitClosedInfoGetRequestModel = new ProfitClosedInfoGetRequestModel()
    this._ProfitClosedInfoGetResponseModel = new ProfitClosedInfoGetResponseModel()
    this._SymbolDayProfitLossInfoSubscribeRequestModel = new SymbolDayProfitLossInfoSubscribeRequestModel()
    this._SymbolDayProfitLossInfoSubscribeResponseModel = new SymbolDayProfitLossInfoSubscribeResponseModel()
    this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel = new SymbolDayProfitLossInfoUpdateSubscriptionRequestModel()
    this._SymbolDayProfitLossInfoUnsubscribeRequestModel = new SymbolDayProfitLossInfoUnsubscribeRequestModel()
    this._SymbolDayProfitLossInfoUnsubscribeResponseModel = new SymbolDayProfitLossInfoUnsubscribeResponseModel()
    this._SymbolDayProfitLossInfoNotifyModel = new SymbolDayProfitLossInfoNotifyModel()
    this._LoginInfoGetRequestModel = new LoginInfoGetRequestModel()
    this._LoginInfoGetResponseModel = new LoginInfoGetResponseModel()
    this._DashboardInfoCreateRequestModel = new DashboardInfoCreateRequestModel()
    this._DashboardInfoCreateResponseModel = new DashboardInfoCreateResponseModel()
    this._DashboardInfoUpdateRequestModel = new DashboardInfoUpdateRequestModel()
    this._DashboardInfoUpdateResponseModel = new DashboardInfoUpdateResponseModel()
    this._DashboardInfoListRequestModel = new DashboardInfoListRequestModel()
    this._DashboardInfoListResponseModel = new DashboardInfoListResponseModel()
    this._DashboardInfoGetRequestModel = new DashboardInfoGetRequestModel()
    this._DashboardInfoGetResponseModel = new DashboardInfoGetResponseModel()
    this._DashboardInfoRenameRequestModel = new DashboardInfoRenameRequestModel()
    this._DashboardInfoRenameResponseModel = new DashboardInfoRenameResponseModel()
    this._DashboardInfoDeleteRequestModel = new DashboardInfoDeleteRequestModel()
    this._DashboardInfoDeleteResponseModel = new DashboardInfoDeleteResponseModel()
    this._ChartInfoCreateRequestModel = new ChartInfoCreateRequestModel()
    this._ChartInfoCreateResponseModel = new ChartInfoCreateResponseModel()
    this._ChartInfoUpdateRequestModel = new ChartInfoUpdateRequestModel()
    this._ChartInfoUpdateResponseModel = new ChartInfoUpdateResponseModel()
    this._ChartInfoListRequestModel = new ChartInfoListRequestModel()
    this._ChartInfoListResponseModel = new ChartInfoListResponseModel()
    this._ChartInfoDeleteRequestModel = new ChartInfoDeleteRequestModel()
    this._ChartInfoDeleteResponseModel = new ChartInfoDeleteResponseModel()
    this._ChartInfoGetRequestModel = new ChartInfoGetRequestModel()
    this._ChartInfoGetResponseModel = new ChartInfoGetResponseModel()
    this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel = new SymbolDayProfitLossMultipleInfoSubscribeRequestModel()
    this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel = new SymbolDayProfitLossMultipleInfoSubscribeResponseModel()
    this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel = new SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel = new SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel = new SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel()
    this._SymbolDayProfitLossMultipleInfoNotifyModel = new SymbolDayProfitLossMultipleInfoNotifyModel()
    this._NewsServiceGetAllRequestModel = new NewsServiceGetAllRequestModel()
    this._NewsServiceGetAllResponseModel = new NewsServiceGetAllResponseModel()
    this._NewsRecentArticlesGetRequestModel = new NewsRecentArticlesGetRequestModel()
    this._NewsRecentArticlesGetResponseModel = new NewsRecentArticlesGetResponseModel()
    this._NewsArticleGetRequestModel = new NewsArticleGetRequestModel()
    this._NewsArticleGetResponseModel = new NewsArticleGetResponseModel()
    this._OrderUpdateWithTypeRequestModel = new OrderUpdateWithTypeRequestModel()
    this._OrderUpdateWithTypeResponseModel = new OrderUpdateWithTypeResponseModel()
    this._SymbolProfitLossMultipleInfoSubscribeRequestModel = new SymbolProfitLossMultipleInfoSubscribeRequestModel()
    this._SymbolProfitLossMultipleInfoSubscribeResponseModel = new SymbolProfitLossMultipleInfoSubscribeResponseModel()
    this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel = new SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel()
    this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel = new SymbolProfitLossMultipleInfoUnsubscribeRequestModel()
    this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel = new SymbolProfitLossMultipleInfoUnsubscribeResponseModel()
    this._SymbolProfitLossMultipleInfoNotifyModel = new SymbolProfitLossMultipleInfoNotifyModel()
    this._LoginWithPasswordRequestModel = new LoginWithPasswordRequestModel()
    this._LoginWithPasswordResponseModel = new LoginWithPasswordResponseModel()
    this._LoginWithTokenRequestModel = new LoginWithTokenRequestModel()
    this._LoginWithTokenResponseModel = new LoginWithTokenResponseModel()
    this._RefreshTokenRequestModel = new RefreshTokenRequestModel()
    this._RefreshTokenResponseModel = new RefreshTokenResponseModel()
    this._Logout2RequestModel = new Logout2RequestModel()
    this._Logout2ResponseModel = new Logout2ResponseModel()
    this._AccountChangePasswordRequestModel = new AccountChangePasswordRequestModel()
    this._AccountChangePasswordResponseModel = new AccountChangePasswordResponseModel()
    this._PushNotificationTokenRegisterRequestModel = new PushNotificationTokenRegisterRequestModel()
    this._PushNotificationTokenRegisterResponseModel = new PushNotificationTokenRegisterResponseModel()
    this._DesiredAppSettingsGetRequestModel = new DesiredAppSettingsGetRequestModel()
    this._DesiredAppSettingsGetResponseModel = new DesiredAppSettingsGetResponseModel()
    this._AuthTokensGetAllRequestModel = new AuthTokensGetAllRequestModel()
    this._AuthTokensGetAllResponseModel = new AuthTokensGetAllResponseModel()
    this._AuthTokensDeleteRequestModel = new AuthTokensDeleteRequestModel()
    this._AuthTokensDeleteResponseModel = new AuthTokensDeleteResponseModel()
    this._LegalDocumentGroupListRequestModel = new LegalDocumentGroupListRequestModel()
    this._LegalDocumentGroupListResponseModel = new LegalDocumentGroupListResponseModel()
    this._LegalDocumentGetRequestModel = new LegalDocumentGetRequestModel()
    this._LegalDocumentGetResponseModel = new LegalDocumentGetResponseModel()
    this._PushNotificationTokenUnRegisterRequestModel = new PushNotificationTokenUnRegisterRequestModel()
    this._PushNotificationTokenUnRegisterResponseModel = new PushNotificationTokenUnRegisterResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  /**
   * Get imported exposed proxy
   * @this {!Proxy}
   * @returns {Proxy} exposed proxy
   */
  get exposedProxy () {
    return this._exposedProxy
  }

  /**
   * Set imported exposed proxy
   * @this {!Proxy}
   * @param {Proxy} proxy exposed proxy
   */
  set exposedProxy (proxy) {
    this._exposedProxy = proxy
  }

  // Proxy handlers

  /**
   * PingRequest proxy handler
   * @this {!Proxy}
   * @param {!PingRequest} model PingRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PingRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PongResponse proxy handler
   * @this {!Proxy}
   * @param {!PongResponse} model PongResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PongResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginRequest proxy handler
   * @this {!Proxy}
   * @param {!LoginRequest} model LoginRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginResponse proxy handler
   * @this {!Proxy}
   * @param {!LoginResponse} model LoginResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginReject proxy handler
   * @this {!Proxy}
   * @param {!LoginReject} model LoginReject model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginReject (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LogoutRequest proxy handler
   * @this {!Proxy}
   * @param {!LogoutRequest} model LogoutRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LogoutRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LogoutResponse proxy handler
   * @this {!Proxy}
   * @param {!LogoutResponse} model LogoutResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LogoutResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LogoutNotify proxy handler
   * @this {!Proxy}
   * @param {!LogoutNotify} model LogoutNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LogoutNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SessionInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!SessionInfoRequest} model SessionInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SessionInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SessionInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!SessionInfoResponse} model SessionInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SessionInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimeInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!TimeInfoRequest} model TimeInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimeInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimeInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!TimeInfoResponse} model TimeInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimeInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!TimezoneInfoRequest} model TimezoneInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!TimezoneInfoResponse} model TimezoneInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TimezoneInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TimezoneInfoUpdateNotify} model TimezoneInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TimezoneInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyInfoRequest} model CurrencyInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyInfoResponse} model CurrencyInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyListRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyListRequest} model CurrencyListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyListResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyListResponse} model CurrencyListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!CurrencyInfoUpdateNotify} model CurrencyInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolInfoRequest} model SymbolInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolInfoResponse} model SymbolInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolListRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolListRequest} model SymbolListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolListResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolListResponse} model SymbolListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolInfoUpdateNotify} model SymbolInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!TickInfoRequest} model TickInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!TickInfoResponse} model TickInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickFilterRequest proxy handler
   * @this {!Proxy}
   * @param {!TickFilterRequest} model TickFilterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickFilterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickFilterResponse proxy handler
   * @this {!Proxy}
   * @param {!TickFilterResponse} model TickFilterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickFilterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickListRequest proxy handler
   * @this {!Proxy}
   * @param {!TickListRequest} model TickListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickListResponse proxy handler
   * @this {!Proxy}
   * @param {!TickListResponse} model TickListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2InfoRequest proxy handler
   * @this {!Proxy}
   * @param {!Level2InfoRequest} model Level2InfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2InfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2InfoResponse proxy handler
   * @this {!Proxy}
   * @param {!Level2InfoResponse} model Level2InfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2InfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2FilterRequest proxy handler
   * @this {!Proxy}
   * @param {!Level2FilterRequest} model Level2FilterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2FilterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2FilterResponse proxy handler
   * @this {!Proxy}
   * @param {!Level2FilterResponse} model Level2FilterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2FilterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2ListRequest proxy handler
   * @this {!Proxy}
   * @param {!Level2ListRequest} model Level2ListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2ListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2ListResponse proxy handler
   * @this {!Proxy}
   * @param {!Level2ListResponse} model Level2ListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2ListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscriptionInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscriptionInfoRequest} model FeedSubscriptionInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscriptionInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscriptionInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscriptionInfoResponse} model FeedSubscriptionInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscriptionInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscribeRequest} model FeedSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscribeAllRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscribeAllRequest} model FeedSubscribeAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscribeAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscribeResponse} model FeedSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUnsubscribeRequest} model FeedUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeAllRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUnsubscribeAllRequest} model FeedUnsubscribeAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUnsubscribeAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedUnsubscribeResponse} model FeedUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickSnapshotNotify proxy handler
   * @this {!Proxy}
   * @param {!TickSnapshotNotify} model TickSnapshotNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickSnapshotNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TickUpdateNotify} model TickUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2SnapshotNotify proxy handler
   * @this {!Proxy}
   * @param {!Level2SnapshotNotify} model Level2SnapshotNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2SnapshotNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2UpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!Level2UpdateNotify} model Level2UpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2UpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryRequest} model ChartBarHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryResponse} model ChartBarHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarSubscribeRequest} model ChartBarSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarSubscribeResponse} model ChartBarSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarUnsubscribeRequest} model ChartBarUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarUnsubscribeResponse} model ChartBarUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarNotify proxy handler
   * @this {!Proxy}
   * @param {!ChartBarNotify} model ChartBarNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ChartBarUpdateNotify} model ChartBarUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!PlatformInfoRequest} model PlatformInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!PlatformInfoResponse} model PlatformInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PlatformInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!PlatformInfoUpdateNotify} model PlatformInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PlatformInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountInfoRequest} model AccountInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountInfoResponse} model AccountInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!AccountInfoUpdateNotify} model AccountInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginInfoRequest} model MarginInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginInfoResponse} model MarginInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!MarginInfoUpdateNotify} model MarginInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginCallNotify proxy handler
   * @this {!Proxy}
   * @param {!MarginCallNotify} model MarginCallNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginCallNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StopOutNotify proxy handler
   * @this {!Proxy}
   * @param {!StopOutNotify} model StopOutNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StopOutNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetInfoRequest} model AssetInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetInfoResponse} model AssetInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetListRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetListRequest} model AssetListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetListResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetListResponse} model AssetListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!AssetInfoUpdateNotify} model AssetInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderInfoRequest} model OrderInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderInfoResponse} model OrderInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderListRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderListRequest} model OrderListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderListResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderListResponse} model OrderListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderOpenRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderOpenRequest} model OrderOpenRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderOpenRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderOpenResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderOpenResponse} model OrderOpenResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderOpenResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateRequest} model OrderUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateResponse} model OrderUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelRequest} model OrderCancelRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelResponse} model OrderCancelResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelAllRequest} model OrderCancelAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelAllResponse} model OrderCancelAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelAllByIdRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelAllByIdRequest} model OrderCancelAllByIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelAllByIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseRequest} model OrderCloseRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseResponse} model OrderCloseResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseAllRequest} model OrderCloseAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseAllResponse} model OrderCloseAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseAllByIdRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseAllByIdRequest} model OrderCloseAllByIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseAllByIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseByRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseByRequest} model OrderCloseByRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseByRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseByResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseByResponse} model OrderCloseByResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseByResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseByAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseByAllRequest} model OrderCloseByAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseByAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCloseByAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCloseByAllResponse} model OrderCloseByAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCloseByAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelOrCloseRequest} model OrderCancelOrCloseRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelOrCloseRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelOrCloseResponse} model OrderCancelOrCloseResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelOrCloseResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelOrCloseAllRequest} model OrderCancelOrCloseAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelOrCloseAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderCancelOrCloseAllResponse} model OrderCancelOrCloseAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderCancelOrCloseAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!OrderInfoUpdateNotify} model OrderInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionInfoRequest} model PositionInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionInfoResponse} model PositionInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionListRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionListRequest} model PositionListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionListResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionListResponse} model PositionListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!PositionInfoUpdateNotify} model PositionInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryInfoRequest} model TradeHistoryInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryInfoResponse} model TradeHistoryInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoFilterRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryInfoFilterRequest} model TradeHistoryInfoFilterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryInfoFilterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoByIdRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryInfoByIdRequest} model TradeHistoryInfoByIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryInfoByIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoByIdResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryInfoByIdResponse} model TradeHistoryInfoByIdResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryInfoByIdResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryInfoUpdateNotify} model TradeHistoryInfoUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryInfoUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleGetRequest proxy handler
   * @this {!Proxy}
   * @param {!CalendarRegularRuleGetRequest} model CalendarRegularRuleGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CalendarRegularRuleGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleGetResponse proxy handler
   * @this {!Proxy}
   * @param {!CalendarRegularRuleGetResponse} model CalendarRegularRuleGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CalendarRegularRuleGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!CalendarRegularRuleUpdateNotify} model CalendarRegularRuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CalendarRegularRuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleGetRequest proxy handler
   * @this {!Proxy}
   * @param {!CalendarOffTimeRuleGetRequest} model CalendarOffTimeRuleGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CalendarOffTimeRuleGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleGetResponse proxy handler
   * @this {!Proxy}
   * @param {!CalendarOffTimeRuleGetResponse} model CalendarOffTimeRuleGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CalendarOffTimeRuleGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!CalendarOffTimeRuleUpdateNotify} model CalendarOffTimeRuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CalendarOffTimeRuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CalendarRulesGetRequest proxy handler
   * @this {!Proxy}
   * @param {!CalendarRulesGetRequest} model CalendarRulesGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CalendarRulesGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CalendarRulesGetResponse proxy handler
   * @this {!Proxy}
   * @param {!CalendarRulesGetResponse} model CalendarRulesGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CalendarRulesGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRangeRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryRangeRequest} model ChartBarHistoryRangeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryRangeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderMarginSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderMarginSubscribeRequest} model OrderMarginSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderMarginSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderMarginSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderMarginSubscribeResponse} model OrderMarginSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderMarginSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderMarginNotify proxy handler
   * @this {!Proxy}
   * @param {!OrderMarginNotify} model OrderMarginNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderMarginNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderMarginUpdateSubscriptionRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderMarginUpdateSubscriptionRequest} model OrderMarginUpdateSubscriptionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderMarginUpdateSubscriptionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderMarginUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderMarginUnsubscribeRequest} model OrderMarginUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderMarginUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderMarginUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderMarginUnsubscribeResponse} model OrderMarginUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderMarginUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderMarginGetRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderMarginGetRequest} model OrderMarginGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderMarginGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderMarginGetResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderMarginGetResponse} model OrderMarginGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderMarginGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateInfoSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderStateInfoSubscribeRequest} model OrderStateInfoSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateInfoSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateInfoSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderStateInfoSubscribeResponse} model OrderStateInfoSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateInfoSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateInfoUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderStateInfoUnsubscribeRequest} model OrderStateInfoUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateInfoUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateInfoUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderStateInfoUnsubscribeResponse} model OrderStateInfoUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateInfoUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderStateInfoNotify proxy handler
   * @this {!Proxy}
   * @param {!OrderStateInfoNotify} model OrderStateInfoNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderStateInfoNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated003 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated003} model Deprecated003 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated003 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated004 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated004} model Deprecated004 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated004 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated005 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated005} model Deprecated005 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated005 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated006 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated006} model Deprecated006 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated006 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated007 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated007} model Deprecated007 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated007 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated008 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated008} model Deprecated008 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated008 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountUpdateRequest} model AccountUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountUpdateResponse} model AccountUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ProfitClosedInfoGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ProfitClosedInfoGetRequest} model ProfitClosedInfoGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ProfitClosedInfoGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ProfitClosedInfoGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ProfitClosedInfoGetResponse} model ProfitClosedInfoGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ProfitClosedInfoGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossInfoSubscribeRequest} model SymbolDayProfitLossInfoSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossInfoSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossInfoSubscribeResponse} model SymbolDayProfitLossInfoSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossInfoSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUpdateSubscriptionRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossInfoUpdateSubscriptionRequest} model SymbolDayProfitLossInfoUpdateSubscriptionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossInfoUpdateSubscriptionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossInfoUnsubscribeRequest} model SymbolDayProfitLossInfoUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossInfoUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossInfoUnsubscribeResponse} model SymbolDayProfitLossInfoUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossInfoUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossInfoNotify} model SymbolDayProfitLossInfoNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossInfoNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginInfoGetRequest proxy handler
   * @this {!Proxy}
   * @param {!LoginInfoGetRequest} model LoginInfoGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginInfoGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginInfoGetResponse proxy handler
   * @this {!Proxy}
   * @param {!LoginInfoGetResponse} model LoginInfoGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginInfoGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoCreateRequest} model DashboardInfoCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoCreateResponse} model DashboardInfoCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoUpdateRequest} model DashboardInfoUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoUpdateResponse} model DashboardInfoUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoListRequest proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoListRequest} model DashboardInfoListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoListResponse proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoListResponse} model DashboardInfoListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoGetRequest proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoGetRequest} model DashboardInfoGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoGetResponse proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoGetResponse} model DashboardInfoGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoRenameRequest proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoRenameRequest} model DashboardInfoRenameRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoRenameRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoRenameResponse proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoRenameResponse} model DashboardInfoRenameResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoRenameResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoDeleteRequest} model DashboardInfoDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DashboardInfoDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!DashboardInfoDeleteResponse} model DashboardInfoDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DashboardInfoDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoCreateRequest} model ChartInfoCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoCreateResponse} model ChartInfoCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoUpdateRequest} model ChartInfoUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoUpdateResponse} model ChartInfoUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoListRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoListRequest} model ChartInfoListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoListResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoListResponse} model ChartInfoListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoDeleteRequest} model ChartInfoDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoDeleteResponse} model ChartInfoDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoGetRequest} model ChartInfoGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartInfoGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartInfoGetResponse} model ChartInfoGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartInfoGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeRequest} model SymbolDayProfitLossMultipleInfoSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossMultipleInfoSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeResponse} model SymbolDayProfitLossMultipleInfoSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossMultipleInfoSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest} model SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeRequest} model SymbolDayProfitLossMultipleInfoUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossMultipleInfoUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeResponse} model SymbolDayProfitLossMultipleInfoUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossMultipleInfoUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolDayProfitLossMultipleInfoNotify} model SymbolDayProfitLossMultipleInfoNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolDayProfitLossMultipleInfoNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsServiceGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsServiceGetAllRequest} model NewsServiceGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsServiceGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsServiceGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsServiceGetAllResponse} model NewsServiceGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsServiceGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsRecentArticlesGetRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsRecentArticlesGetRequest} model NewsRecentArticlesGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsRecentArticlesGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsRecentArticlesGetResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsRecentArticlesGetResponse} model NewsRecentArticlesGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsRecentArticlesGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsArticleGetRequest proxy handler
   * @this {!Proxy}
   * @param {!NewsArticleGetRequest} model NewsArticleGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsArticleGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * NewsArticleGetResponse proxy handler
   * @this {!Proxy}
   * @param {!NewsArticleGetResponse} model NewsArticleGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_NewsArticleGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateWithTypeRequest} model OrderUpdateWithTypeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateWithTypeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateWithTypeResponse} model OrderUpdateWithTypeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateWithTypeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolProfitLossMultipleInfoSubscribeRequest} model SymbolProfitLossMultipleInfoSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolProfitLossMultipleInfoSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolProfitLossMultipleInfoSubscribeResponse} model SymbolProfitLossMultipleInfoSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolProfitLossMultipleInfoSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUpdateSubscriptionRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolProfitLossMultipleInfoUpdateSubscriptionRequest} model SymbolProfitLossMultipleInfoUpdateSubscriptionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeRequest} model SymbolProfitLossMultipleInfoUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolProfitLossMultipleInfoUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeResponse} model SymbolProfitLossMultipleInfoUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolProfitLossMultipleInfoUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolProfitLossMultipleInfoNotify} model SymbolProfitLossMultipleInfoNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolProfitLossMultipleInfoNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginWithPasswordRequest proxy handler
   * @this {!Proxy}
   * @param {!LoginWithPasswordRequest} model LoginWithPasswordRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginWithPasswordRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginWithPasswordResponse proxy handler
   * @this {!Proxy}
   * @param {!LoginWithPasswordResponse} model LoginWithPasswordResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginWithPasswordResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginWithTokenRequest proxy handler
   * @this {!Proxy}
   * @param {!LoginWithTokenRequest} model LoginWithTokenRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginWithTokenRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LoginWithTokenResponse proxy handler
   * @this {!Proxy}
   * @param {!LoginWithTokenResponse} model LoginWithTokenResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LoginWithTokenResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RefreshTokenRequest proxy handler
   * @this {!Proxy}
   * @param {!RefreshTokenRequest} model RefreshTokenRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RefreshTokenRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * RefreshTokenResponse proxy handler
   * @this {!Proxy}
   * @param {!RefreshTokenResponse} model RefreshTokenResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_RefreshTokenResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Logout2Request proxy handler
   * @this {!Proxy}
   * @param {!Logout2Request} model Logout2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Logout2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Logout2Response proxy handler
   * @this {!Proxy}
   * @param {!Logout2Response} model Logout2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Logout2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountChangePasswordRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountChangePasswordRequest} model AccountChangePasswordRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountChangePasswordRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountChangePasswordResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountChangePasswordResponse} model AccountChangePasswordResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountChangePasswordResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PushNotificationTokenRegisterRequest proxy handler
   * @this {!Proxy}
   * @param {!PushNotificationTokenRegisterRequest} model PushNotificationTokenRegisterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PushNotificationTokenRegisterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PushNotificationTokenRegisterResponse proxy handler
   * @this {!Proxy}
   * @param {!PushNotificationTokenRegisterResponse} model PushNotificationTokenRegisterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PushNotificationTokenRegisterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DesiredAppSettingsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!DesiredAppSettingsGetRequest} model DesiredAppSettingsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DesiredAppSettingsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DesiredAppSettingsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!DesiredAppSettingsGetResponse} model DesiredAppSettingsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DesiredAppSettingsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AuthTokensGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AuthTokensGetAllRequest} model AuthTokensGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AuthTokensGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AuthTokensGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AuthTokensGetAllResponse} model AuthTokensGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AuthTokensGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AuthTokensDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!AuthTokensDeleteRequest} model AuthTokensDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AuthTokensDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AuthTokensDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!AuthTokensDeleteResponse} model AuthTokensDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AuthTokensDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupListRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupListRequest} model LegalDocumentGroupListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupListResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGroupListResponse} model LegalDocumentGroupListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGroupListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGetRequest proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGetRequest} model LegalDocumentGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResponse proxy handler
   * @this {!Proxy}
   * @param {!LegalDocumentGetResponse} model LegalDocumentGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LegalDocumentGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PushNotificationTokenUnRegisterRequest proxy handler
   * @this {!Proxy}
   * @param {!PushNotificationTokenUnRegisterRequest} model PushNotificationTokenUnRegisterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PushNotificationTokenUnRegisterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PushNotificationTokenUnRegisterResponse proxy handler
   * @this {!Proxy}
   * @param {!PushNotificationTokenUnRegisterResponse} model PushNotificationTokenUnRegisterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PushNotificationTokenUnRegisterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * client receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case PingRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PingRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PingRequestModel.verify(), 'client.PingRequest validation failed!')

        let fbeBegin = this._PingRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PingRequest(this._PingRequestModel, type, buffer, offset, size)
        this._PingRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PongResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PongResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PongResponseModel.verify(), 'client.PongResponse validation failed!')

        let fbeBegin = this._PongResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PongResponse(this._PongResponseModel, type, buffer, offset, size)
        this._PongResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginRequestModel.verify(), 'client.LoginRequest validation failed!')

        let fbeBegin = this._LoginRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginRequest(this._LoginRequestModel, type, buffer, offset, size)
        this._LoginRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginResponseModel.verify(), 'client.LoginResponse validation failed!')

        let fbeBegin = this._LoginResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginResponse(this._LoginResponseModel, type, buffer, offset, size)
        this._LoginResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginRejectModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginRejectModel.attachBuffer(buffer, offset)
        console.assert(this._LoginRejectModel.verify(), 'client.LoginReject validation failed!')

        let fbeBegin = this._LoginRejectModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginReject(this._LoginRejectModel, type, buffer, offset, size)
        this._LoginRejectModel.model.getEnd(fbeBegin)
        return true
      }
      case LogoutRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LogoutRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutRequestModel.verify(), 'client.LogoutRequest validation failed!')

        let fbeBegin = this._LogoutRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LogoutRequest(this._LogoutRequestModel, type, buffer, offset, size)
        this._LogoutRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LogoutResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LogoutResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutResponseModel.verify(), 'client.LogoutResponse validation failed!')

        let fbeBegin = this._LogoutResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LogoutResponse(this._LogoutResponseModel, type, buffer, offset, size)
        this._LogoutResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LogoutNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LogoutNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutNotifyModel.verify(), 'client.LogoutNotify validation failed!')

        let fbeBegin = this._LogoutNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LogoutNotify(this._LogoutNotifyModel, type, buffer, offset, size)
        this._LogoutNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SessionInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SessionInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SessionInfoRequestModel.verify(), 'client.SessionInfoRequest validation failed!')

        let fbeBegin = this._SessionInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SessionInfoRequest(this._SessionInfoRequestModel, type, buffer, offset, size)
        this._SessionInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SessionInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SessionInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SessionInfoResponseModel.verify(), 'client.SessionInfoResponse validation failed!')

        let fbeBegin = this._SessionInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SessionInfoResponse(this._SessionInfoResponseModel, type, buffer, offset, size)
        this._SessionInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimeInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimeInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimeInfoRequestModel.verify(), 'client.TimeInfoRequest validation failed!')

        let fbeBegin = this._TimeInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimeInfoRequest(this._TimeInfoRequestModel, type, buffer, offset, size)
        this._TimeInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TimeInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimeInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimeInfoResponseModel.verify(), 'client.TimeInfoResponse validation failed!')

        let fbeBegin = this._TimeInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimeInfoResponse(this._TimeInfoResponseModel, type, buffer, offset, size)
        this._TimeInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoRequestModel.verify(), 'client.TimezoneInfoRequest validation failed!')

        let fbeBegin = this._TimezoneInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneInfoRequest(this._TimezoneInfoRequestModel, type, buffer, offset, size)
        this._TimezoneInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoResponseModel.verify(), 'client.TimezoneInfoResponse validation failed!')

        let fbeBegin = this._TimezoneInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneInfoResponse(this._TimezoneInfoResponseModel, type, buffer, offset, size)
        this._TimezoneInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TimezoneInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TimezoneInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoUpdateNotifyModel.verify(), 'client.TimezoneInfoUpdateNotify validation failed!')

        let fbeBegin = this._TimezoneInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TimezoneInfoUpdateNotify(this._TimezoneInfoUpdateNotifyModel, type, buffer, offset, size)
        this._TimezoneInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoRequestModel.verify(), 'client.CurrencyInfoRequest validation failed!')

        let fbeBegin = this._CurrencyInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyInfoRequest(this._CurrencyInfoRequestModel, type, buffer, offset, size)
        this._CurrencyInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoResponseModel.verify(), 'client.CurrencyInfoResponse validation failed!')

        let fbeBegin = this._CurrencyInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyInfoResponse(this._CurrencyInfoResponseModel, type, buffer, offset, size)
        this._CurrencyInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyListRequestModel.verify(), 'client.CurrencyListRequest validation failed!')

        let fbeBegin = this._CurrencyListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyListRequest(this._CurrencyListRequestModel, type, buffer, offset, size)
        this._CurrencyListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyListResponseModel.verify(), 'client.CurrencyListResponse validation failed!')

        let fbeBegin = this._CurrencyListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyListResponse(this._CurrencyListResponseModel, type, buffer, offset, size)
        this._CurrencyListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoUpdateNotifyModel.verify(), 'client.CurrencyInfoUpdateNotify validation failed!')

        let fbeBegin = this._CurrencyInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyInfoUpdateNotify(this._CurrencyInfoUpdateNotifyModel, type, buffer, offset, size)
        this._CurrencyInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoRequestModel.verify(), 'client.SymbolInfoRequest validation failed!')

        let fbeBegin = this._SymbolInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolInfoRequest(this._SymbolInfoRequestModel, type, buffer, offset, size)
        this._SymbolInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoResponseModel.verify(), 'client.SymbolInfoResponse validation failed!')

        let fbeBegin = this._SymbolInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolInfoResponse(this._SymbolInfoResponseModel, type, buffer, offset, size)
        this._SymbolInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolListRequestModel.verify(), 'client.SymbolListRequest validation failed!')

        let fbeBegin = this._SymbolListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolListRequest(this._SymbolListRequestModel, type, buffer, offset, size)
        this._SymbolListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolListResponseModel.verify(), 'client.SymbolListResponse validation failed!')

        let fbeBegin = this._SymbolListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolListResponse(this._SymbolListResponseModel, type, buffer, offset, size)
        this._SymbolListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoUpdateNotifyModel.verify(), 'client.SymbolInfoUpdateNotify validation failed!')

        let fbeBegin = this._SymbolInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolInfoUpdateNotify(this._SymbolInfoUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TickInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickInfoRequestModel.verify(), 'client.TickInfoRequest validation failed!')

        let fbeBegin = this._TickInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickInfoRequest(this._TickInfoRequestModel, type, buffer, offset, size)
        this._TickInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TickInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickInfoResponseModel.verify(), 'client.TickInfoResponse validation failed!')

        let fbeBegin = this._TickInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickInfoResponse(this._TickInfoResponseModel, type, buffer, offset, size)
        this._TickInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TickFilterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickFilterRequestModel.verify(), 'client.TickFilterRequest validation failed!')

        let fbeBegin = this._TickFilterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickFilterRequest(this._TickFilterRequestModel, type, buffer, offset, size)
        this._TickFilterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TickFilterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickFilterResponseModel.verify(), 'client.TickFilterResponse validation failed!')

        let fbeBegin = this._TickFilterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickFilterResponse(this._TickFilterResponseModel, type, buffer, offset, size)
        this._TickFilterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TickListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickListRequestModel.verify(), 'client.TickListRequest validation failed!')

        let fbeBegin = this._TickListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickListRequest(this._TickListRequestModel, type, buffer, offset, size)
        this._TickListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TickListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickListResponseModel.verify(), 'client.TickListResponse validation failed!')

        let fbeBegin = this._TickListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickListResponse(this._TickListResponseModel, type, buffer, offset, size)
        this._TickListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2InfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2InfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2InfoRequestModel.verify(), 'client.Level2InfoRequest validation failed!')

        let fbeBegin = this._Level2InfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2InfoRequest(this._Level2InfoRequestModel, type, buffer, offset, size)
        this._Level2InfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2InfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2InfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2InfoResponseModel.verify(), 'client.Level2InfoResponse validation failed!')

        let fbeBegin = this._Level2InfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2InfoResponse(this._Level2InfoResponseModel, type, buffer, offset, size)
        this._Level2InfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2FilterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2FilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2FilterRequestModel.verify(), 'client.Level2FilterRequest validation failed!')

        let fbeBegin = this._Level2FilterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2FilterRequest(this._Level2FilterRequestModel, type, buffer, offset, size)
        this._Level2FilterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2FilterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2FilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2FilterResponseModel.verify(), 'client.Level2FilterResponse validation failed!')

        let fbeBegin = this._Level2FilterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2FilterResponse(this._Level2FilterResponseModel, type, buffer, offset, size)
        this._Level2FilterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2ListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2ListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2ListRequestModel.verify(), 'client.Level2ListRequest validation failed!')

        let fbeBegin = this._Level2ListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2ListRequest(this._Level2ListRequestModel, type, buffer, offset, size)
        this._Level2ListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2ListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2ListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2ListResponseModel.verify(), 'client.Level2ListResponse validation failed!')

        let fbeBegin = this._Level2ListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2ListResponse(this._Level2ListResponseModel, type, buffer, offset, size)
        this._Level2ListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscriptionInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscriptionInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionInfoRequestModel.verify(), 'client.FeedSubscriptionInfoRequest validation failed!')

        let fbeBegin = this._FeedSubscriptionInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscriptionInfoRequest(this._FeedSubscriptionInfoRequestModel, type, buffer, offset, size)
        this._FeedSubscriptionInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscriptionInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscriptionInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionInfoResponseModel.verify(), 'client.FeedSubscriptionInfoResponse validation failed!')

        let fbeBegin = this._FeedSubscriptionInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscriptionInfoResponse(this._FeedSubscriptionInfoResponseModel, type, buffer, offset, size)
        this._FeedSubscriptionInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeRequestModel.verify(), 'client.FeedSubscribeRequest validation failed!')

        let fbeBegin = this._FeedSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscribeRequest(this._FeedSubscribeRequestModel, type, buffer, offset, size)
        this._FeedSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscribeAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscribeAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeAllRequestModel.verify(), 'client.FeedSubscribeAllRequest validation failed!')

        let fbeBegin = this._FeedSubscribeAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscribeAllRequest(this._FeedSubscribeAllRequestModel, type, buffer, offset, size)
        this._FeedSubscribeAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeResponseModel.verify(), 'client.FeedSubscribeResponse validation failed!')

        let fbeBegin = this._FeedSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscribeResponse(this._FeedSubscribeResponseModel, type, buffer, offset, size)
        this._FeedSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeRequestModel.verify(), 'client.FeedUnsubscribeRequest validation failed!')

        let fbeBegin = this._FeedUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUnsubscribeRequest(this._FeedUnsubscribeRequestModel, type, buffer, offset, size)
        this._FeedUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUnsubscribeAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUnsubscribeAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeAllRequestModel.verify(), 'client.FeedUnsubscribeAllRequest validation failed!')

        let fbeBegin = this._FeedUnsubscribeAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUnsubscribeAllRequest(this._FeedUnsubscribeAllRequestModel, type, buffer, offset, size)
        this._FeedUnsubscribeAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeResponseModel.verify(), 'client.FeedUnsubscribeResponse validation failed!')

        let fbeBegin = this._FeedUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUnsubscribeResponse(this._FeedUnsubscribeResponseModel, type, buffer, offset, size)
        this._FeedUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TickSnapshotNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickSnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TickSnapshotNotifyModel.verify(), 'client.TickSnapshotNotify validation failed!')

        let fbeBegin = this._TickSnapshotNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickSnapshotNotify(this._TickSnapshotNotifyModel, type, buffer, offset, size)
        this._TickSnapshotNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TickUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TickUpdateNotifyModel.verify(), 'client.TickUpdateNotify validation failed!')

        let fbeBegin = this._TickUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickUpdateNotify(this._TickUpdateNotifyModel, type, buffer, offset, size)
        this._TickUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2SnapshotNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2SnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._Level2SnapshotNotifyModel.verify(), 'client.Level2SnapshotNotify validation failed!')

        let fbeBegin = this._Level2SnapshotNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2SnapshotNotify(this._Level2SnapshotNotifyModel, type, buffer, offset, size)
        this._Level2SnapshotNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2UpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2UpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._Level2UpdateNotifyModel.verify(), 'client.Level2UpdateNotify validation failed!')

        let fbeBegin = this._Level2UpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2UpdateNotify(this._Level2UpdateNotifyModel, type, buffer, offset, size)
        this._Level2UpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRequestModel.verify(), 'client.ChartBarHistoryRequest validation failed!')

        let fbeBegin = this._ChartBarHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryRequest(this._ChartBarHistoryRequestModel, type, buffer, offset, size)
        this._ChartBarHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryResponseModel.verify(), 'client.ChartBarHistoryResponse validation failed!')

        let fbeBegin = this._ChartBarHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryResponse(this._ChartBarHistoryResponseModel, type, buffer, offset, size)
        this._ChartBarHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarSubscribeRequestModel.verify(), 'client.ChartBarSubscribeRequest validation failed!')

        let fbeBegin = this._ChartBarSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarSubscribeRequest(this._ChartBarSubscribeRequestModel, type, buffer, offset, size)
        this._ChartBarSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarSubscribeResponseModel.verify(), 'client.ChartBarSubscribeResponse validation failed!')

        let fbeBegin = this._ChartBarSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarSubscribeResponse(this._ChartBarSubscribeResponseModel, type, buffer, offset, size)
        this._ChartBarSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUnsubscribeRequestModel.verify(), 'client.ChartBarUnsubscribeRequest validation failed!')

        let fbeBegin = this._ChartBarUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarUnsubscribeRequest(this._ChartBarUnsubscribeRequestModel, type, buffer, offset, size)
        this._ChartBarUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUnsubscribeResponseModel.verify(), 'client.ChartBarUnsubscribeResponse validation failed!')

        let fbeBegin = this._ChartBarUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarUnsubscribeResponse(this._ChartBarUnsubscribeResponseModel, type, buffer, offset, size)
        this._ChartBarUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarNotifyModel.verify(), 'client.ChartBarNotify validation failed!')

        let fbeBegin = this._ChartBarNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarNotify(this._ChartBarNotifyModel, type, buffer, offset, size)
        this._ChartBarNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUpdateNotifyModel.verify(), 'client.ChartBarUpdateNotify validation failed!')

        let fbeBegin = this._ChartBarUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarUpdateNotify(this._ChartBarUpdateNotifyModel, type, buffer, offset, size)
        this._ChartBarUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoRequestModel.verify(), 'client.PlatformInfoRequest validation failed!')

        let fbeBegin = this._PlatformInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformInfoRequest(this._PlatformInfoRequestModel, type, buffer, offset, size)
        this._PlatformInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoResponseModel.verify(), 'client.PlatformInfoResponse validation failed!')

        let fbeBegin = this._PlatformInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformInfoResponse(this._PlatformInfoResponseModel, type, buffer, offset, size)
        this._PlatformInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PlatformInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PlatformInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoUpdateNotifyModel.verify(), 'client.PlatformInfoUpdateNotify validation failed!')

        let fbeBegin = this._PlatformInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PlatformInfoUpdateNotify(this._PlatformInfoUpdateNotifyModel, type, buffer, offset, size)
        this._PlatformInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoRequestModel.verify(), 'client.AccountInfoRequest validation failed!')

        let fbeBegin = this._AccountInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountInfoRequest(this._AccountInfoRequestModel, type, buffer, offset, size)
        this._AccountInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoResponseModel.verify(), 'client.AccountInfoResponse validation failed!')

        let fbeBegin = this._AccountInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountInfoResponse(this._AccountInfoResponseModel, type, buffer, offset, size)
        this._AccountInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoUpdateNotifyModel.verify(), 'client.AccountInfoUpdateNotify validation failed!')

        let fbeBegin = this._AccountInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountInfoUpdateNotify(this._AccountInfoUpdateNotifyModel, type, buffer, offset, size)
        this._AccountInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoRequestModel.verify(), 'client.MarginInfoRequest validation failed!')

        let fbeBegin = this._MarginInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginInfoRequest(this._MarginInfoRequestModel, type, buffer, offset, size)
        this._MarginInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoResponseModel.verify(), 'client.MarginInfoResponse validation failed!')

        let fbeBegin = this._MarginInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginInfoResponse(this._MarginInfoResponseModel, type, buffer, offset, size)
        this._MarginInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoUpdateNotifyModel.verify(), 'client.MarginInfoUpdateNotify validation failed!')

        let fbeBegin = this._MarginInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginInfoUpdateNotify(this._MarginInfoUpdateNotifyModel, type, buffer, offset, size)
        this._MarginInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginCallNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginCallNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginCallNotifyModel.verify(), 'client.MarginCallNotify validation failed!')

        let fbeBegin = this._MarginCallNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginCallNotify(this._MarginCallNotifyModel, type, buffer, offset, size)
        this._MarginCallNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case StopOutNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StopOutNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._StopOutNotifyModel.verify(), 'client.StopOutNotify validation failed!')

        let fbeBegin = this._StopOutNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StopOutNotify(this._StopOutNotifyModel, type, buffer, offset, size)
        this._StopOutNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoRequestModel.verify(), 'client.AssetInfoRequest validation failed!')

        let fbeBegin = this._AssetInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetInfoRequest(this._AssetInfoRequestModel, type, buffer, offset, size)
        this._AssetInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoResponseModel.verify(), 'client.AssetInfoResponse validation failed!')

        let fbeBegin = this._AssetInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetInfoResponse(this._AssetInfoResponseModel, type, buffer, offset, size)
        this._AssetInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetListRequestModel.verify(), 'client.AssetListRequest validation failed!')

        let fbeBegin = this._AssetListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetListRequest(this._AssetListRequestModel, type, buffer, offset, size)
        this._AssetListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetListResponseModel.verify(), 'client.AssetListResponse validation failed!')

        let fbeBegin = this._AssetListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetListResponse(this._AssetListResponseModel, type, buffer, offset, size)
        this._AssetListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoUpdateNotifyModel.verify(), 'client.AssetInfoUpdateNotify validation failed!')

        let fbeBegin = this._AssetInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetInfoUpdateNotify(this._AssetInfoUpdateNotifyModel, type, buffer, offset, size)
        this._AssetInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoRequestModel.verify(), 'client.OrderInfoRequest validation failed!')

        let fbeBegin = this._OrderInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderInfoRequest(this._OrderInfoRequestModel, type, buffer, offset, size)
        this._OrderInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoResponseModel.verify(), 'client.OrderInfoResponse validation failed!')

        let fbeBegin = this._OrderInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderInfoResponse(this._OrderInfoResponseModel, type, buffer, offset, size)
        this._OrderInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderListRequestModel.verify(), 'client.OrderListRequest validation failed!')

        let fbeBegin = this._OrderListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderListRequest(this._OrderListRequestModel, type, buffer, offset, size)
        this._OrderListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderListResponseModel.verify(), 'client.OrderListResponse validation failed!')

        let fbeBegin = this._OrderListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderListResponse(this._OrderListResponseModel, type, buffer, offset, size)
        this._OrderListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderOpenRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderOpenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderOpenRequestModel.verify(), 'client.OrderOpenRequest validation failed!')

        let fbeBegin = this._OrderOpenRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderOpenRequest(this._OrderOpenRequestModel, type, buffer, offset, size)
        this._OrderOpenRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderOpenResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderOpenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderOpenResponseModel.verify(), 'client.OrderOpenResponse validation failed!')

        let fbeBegin = this._OrderOpenResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderOpenResponse(this._OrderOpenResponseModel, type, buffer, offset, size)
        this._OrderOpenResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateRequestModel.verify(), 'client.OrderUpdateRequest validation failed!')

        let fbeBegin = this._OrderUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateRequest(this._OrderUpdateRequestModel, type, buffer, offset, size)
        this._OrderUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateResponseModel.verify(), 'client.OrderUpdateResponse validation failed!')

        let fbeBegin = this._OrderUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateResponse(this._OrderUpdateResponseModel, type, buffer, offset, size)
        this._OrderUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelRequestModel.verify(), 'client.OrderCancelRequest validation failed!')

        let fbeBegin = this._OrderCancelRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelRequest(this._OrderCancelRequestModel, type, buffer, offset, size)
        this._OrderCancelRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelResponseModel.verify(), 'client.OrderCancelResponse validation failed!')

        let fbeBegin = this._OrderCancelResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelResponse(this._OrderCancelResponseModel, type, buffer, offset, size)
        this._OrderCancelResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllRequestModel.verify(), 'client.OrderCancelAllRequest validation failed!')

        let fbeBegin = this._OrderCancelAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelAllRequest(this._OrderCancelAllRequestModel, type, buffer, offset, size)
        this._OrderCancelAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllResponseModel.verify(), 'client.OrderCancelAllResponse validation failed!')

        let fbeBegin = this._OrderCancelAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelAllResponse(this._OrderCancelAllResponseModel, type, buffer, offset, size)
        this._OrderCancelAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelAllByIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelAllByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllByIdRequestModel.verify(), 'client.OrderCancelAllByIdRequest validation failed!')

        let fbeBegin = this._OrderCancelAllByIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelAllByIdRequest(this._OrderCancelAllByIdRequestModel, type, buffer, offset, size)
        this._OrderCancelAllByIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseRequestModel.verify(), 'client.OrderCloseRequest validation failed!')

        let fbeBegin = this._OrderCloseRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseRequest(this._OrderCloseRequestModel, type, buffer, offset, size)
        this._OrderCloseRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseResponseModel.verify(), 'client.OrderCloseResponse validation failed!')

        let fbeBegin = this._OrderCloseResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseResponse(this._OrderCloseResponseModel, type, buffer, offset, size)
        this._OrderCloseResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllRequestModel.verify(), 'client.OrderCloseAllRequest validation failed!')

        let fbeBegin = this._OrderCloseAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseAllRequest(this._OrderCloseAllRequestModel, type, buffer, offset, size)
        this._OrderCloseAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllResponseModel.verify(), 'client.OrderCloseAllResponse validation failed!')

        let fbeBegin = this._OrderCloseAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseAllResponse(this._OrderCloseAllResponseModel, type, buffer, offset, size)
        this._OrderCloseAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseAllByIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseAllByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllByIdRequestModel.verify(), 'client.OrderCloseAllByIdRequest validation failed!')

        let fbeBegin = this._OrderCloseAllByIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseAllByIdRequest(this._OrderCloseAllByIdRequestModel, type, buffer, offset, size)
        this._OrderCloseAllByIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseByRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseByRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByRequestModel.verify(), 'client.OrderCloseByRequest validation failed!')

        let fbeBegin = this._OrderCloseByRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseByRequest(this._OrderCloseByRequestModel, type, buffer, offset, size)
        this._OrderCloseByRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseByResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseByResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByResponseModel.verify(), 'client.OrderCloseByResponse validation failed!')

        let fbeBegin = this._OrderCloseByResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseByResponse(this._OrderCloseByResponseModel, type, buffer, offset, size)
        this._OrderCloseByResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseByAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseByAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllRequestModel.verify(), 'client.OrderCloseByAllRequest validation failed!')

        let fbeBegin = this._OrderCloseByAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseByAllRequest(this._OrderCloseByAllRequestModel, type, buffer, offset, size)
        this._OrderCloseByAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCloseByAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCloseByAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllResponseModel.verify(), 'client.OrderCloseByAllResponse validation failed!')

        let fbeBegin = this._OrderCloseByAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCloseByAllResponse(this._OrderCloseByAllResponseModel, type, buffer, offset, size)
        this._OrderCloseByAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelOrCloseRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelOrCloseRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseRequestModel.verify(), 'client.OrderCancelOrCloseRequest validation failed!')

        let fbeBegin = this._OrderCancelOrCloseRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelOrCloseRequest(this._OrderCancelOrCloseRequestModel, type, buffer, offset, size)
        this._OrderCancelOrCloseRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelOrCloseResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelOrCloseResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseResponseModel.verify(), 'client.OrderCancelOrCloseResponse validation failed!')

        let fbeBegin = this._OrderCancelOrCloseResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelOrCloseResponse(this._OrderCancelOrCloseResponseModel, type, buffer, offset, size)
        this._OrderCancelOrCloseResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelOrCloseAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelOrCloseAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllRequestModel.verify(), 'client.OrderCancelOrCloseAllRequest validation failed!')

        let fbeBegin = this._OrderCancelOrCloseAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelOrCloseAllRequest(this._OrderCancelOrCloseAllRequestModel, type, buffer, offset, size)
        this._OrderCancelOrCloseAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderCancelOrCloseAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderCancelOrCloseAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllResponseModel.verify(), 'client.OrderCancelOrCloseAllResponse validation failed!')

        let fbeBegin = this._OrderCancelOrCloseAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderCancelOrCloseAllResponse(this._OrderCancelOrCloseAllResponseModel, type, buffer, offset, size)
        this._OrderCancelOrCloseAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoUpdateNotifyModel.verify(), 'client.OrderInfoUpdateNotify validation failed!')

        let fbeBegin = this._OrderInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderInfoUpdateNotify(this._OrderInfoUpdateNotifyModel, type, buffer, offset, size)
        this._OrderInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoRequestModel.verify(), 'client.PositionInfoRequest validation failed!')

        let fbeBegin = this._PositionInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionInfoRequest(this._PositionInfoRequestModel, type, buffer, offset, size)
        this._PositionInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoResponseModel.verify(), 'client.PositionInfoResponse validation failed!')

        let fbeBegin = this._PositionInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionInfoResponse(this._PositionInfoResponseModel, type, buffer, offset, size)
        this._PositionInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionListRequestModel.verify(), 'client.PositionListRequest validation failed!')

        let fbeBegin = this._PositionListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionListRequest(this._PositionListRequestModel, type, buffer, offset, size)
        this._PositionListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionListResponseModel.verify(), 'client.PositionListResponse validation failed!')

        let fbeBegin = this._PositionListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionListResponse(this._PositionListResponseModel, type, buffer, offset, size)
        this._PositionListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoUpdateNotifyModel.verify(), 'client.PositionInfoUpdateNotify validation failed!')

        let fbeBegin = this._PositionInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionInfoUpdateNotify(this._PositionInfoUpdateNotifyModel, type, buffer, offset, size)
        this._PositionInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoRequestModel.verify(), 'client.TradeHistoryInfoRequest validation failed!')

        let fbeBegin = this._TradeHistoryInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryInfoRequest(this._TradeHistoryInfoRequestModel, type, buffer, offset, size)
        this._TradeHistoryInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoResponseModel.verify(), 'client.TradeHistoryInfoResponse validation failed!')

        let fbeBegin = this._TradeHistoryInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryInfoResponse(this._TradeHistoryInfoResponseModel, type, buffer, offset, size)
        this._TradeHistoryInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryInfoFilterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryInfoFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoFilterRequestModel.verify(), 'client.TradeHistoryInfoFilterRequest validation failed!')

        let fbeBegin = this._TradeHistoryInfoFilterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryInfoFilterRequest(this._TradeHistoryInfoFilterRequestModel, type, buffer, offset, size)
        this._TradeHistoryInfoFilterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryInfoByIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryInfoByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoByIdRequestModel.verify(), 'client.TradeHistoryInfoByIdRequest validation failed!')

        let fbeBegin = this._TradeHistoryInfoByIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryInfoByIdRequest(this._TradeHistoryInfoByIdRequestModel, type, buffer, offset, size)
        this._TradeHistoryInfoByIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryInfoByIdResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryInfoByIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoByIdResponseModel.verify(), 'client.TradeHistoryInfoByIdResponse validation failed!')

        let fbeBegin = this._TradeHistoryInfoByIdResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryInfoByIdResponse(this._TradeHistoryInfoByIdResponseModel, type, buffer, offset, size)
        this._TradeHistoryInfoByIdResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryInfoUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryInfoUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoUpdateNotifyModel.verify(), 'client.TradeHistoryInfoUpdateNotify validation failed!')

        let fbeBegin = this._TradeHistoryInfoUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryInfoUpdateNotify(this._TradeHistoryInfoUpdateNotifyModel, type, buffer, offset, size)
        this._TradeHistoryInfoUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case CalendarRegularRuleGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CalendarRegularRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleGetRequestModel.verify(), 'client.CalendarRegularRuleGetRequest validation failed!')

        let fbeBegin = this._CalendarRegularRuleGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CalendarRegularRuleGetRequest(this._CalendarRegularRuleGetRequestModel, type, buffer, offset, size)
        this._CalendarRegularRuleGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CalendarRegularRuleGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CalendarRegularRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleGetResponseModel.verify(), 'client.CalendarRegularRuleGetResponse validation failed!')

        let fbeBegin = this._CalendarRegularRuleGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CalendarRegularRuleGetResponse(this._CalendarRegularRuleGetResponseModel, type, buffer, offset, size)
        this._CalendarRegularRuleGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CalendarRegularRuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CalendarRegularRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleUpdateNotifyModel.verify(), 'client.CalendarRegularRuleUpdateNotify validation failed!')

        let fbeBegin = this._CalendarRegularRuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CalendarRegularRuleUpdateNotify(this._CalendarRegularRuleUpdateNotifyModel, type, buffer, offset, size)
        this._CalendarRegularRuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case CalendarOffTimeRuleGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CalendarOffTimeRuleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleGetRequestModel.verify(), 'client.CalendarOffTimeRuleGetRequest validation failed!')

        let fbeBegin = this._CalendarOffTimeRuleGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CalendarOffTimeRuleGetRequest(this._CalendarOffTimeRuleGetRequestModel, type, buffer, offset, size)
        this._CalendarOffTimeRuleGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CalendarOffTimeRuleGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CalendarOffTimeRuleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleGetResponseModel.verify(), 'client.CalendarOffTimeRuleGetResponse validation failed!')

        let fbeBegin = this._CalendarOffTimeRuleGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CalendarOffTimeRuleGetResponse(this._CalendarOffTimeRuleGetResponseModel, type, buffer, offset, size)
        this._CalendarOffTimeRuleGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CalendarOffTimeRuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CalendarOffTimeRuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleUpdateNotifyModel.verify(), 'client.CalendarOffTimeRuleUpdateNotify validation failed!')

        let fbeBegin = this._CalendarOffTimeRuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CalendarOffTimeRuleUpdateNotify(this._CalendarOffTimeRuleUpdateNotifyModel, type, buffer, offset, size)
        this._CalendarOffTimeRuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case CalendarRulesGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CalendarRulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRulesGetRequestModel.verify(), 'client.CalendarRulesGetRequest validation failed!')

        let fbeBegin = this._CalendarRulesGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CalendarRulesGetRequest(this._CalendarRulesGetRequestModel, type, buffer, offset, size)
        this._CalendarRulesGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CalendarRulesGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CalendarRulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRulesGetResponseModel.verify(), 'client.CalendarRulesGetResponse validation failed!')

        let fbeBegin = this._CalendarRulesGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CalendarRulesGetResponse(this._CalendarRulesGetResponseModel, type, buffer, offset, size)
        this._CalendarRulesGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryRangeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryRangeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRangeRequestModel.verify(), 'client.ChartBarHistoryRangeRequest validation failed!')

        let fbeBegin = this._ChartBarHistoryRangeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryRangeRequest(this._ChartBarHistoryRangeRequestModel, type, buffer, offset, size)
        this._ChartBarHistoryRangeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderMarginSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderMarginSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginSubscribeRequestModel.verify(), 'client.OrderMarginSubscribeRequest validation failed!')

        let fbeBegin = this._OrderMarginSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderMarginSubscribeRequest(this._OrderMarginSubscribeRequestModel, type, buffer, offset, size)
        this._OrderMarginSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderMarginSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderMarginSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginSubscribeResponseModel.verify(), 'client.OrderMarginSubscribeResponse validation failed!')

        let fbeBegin = this._OrderMarginSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderMarginSubscribeResponse(this._OrderMarginSubscribeResponseModel, type, buffer, offset, size)
        this._OrderMarginSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderMarginNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderMarginNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginNotifyModel.verify(), 'client.OrderMarginNotify validation failed!')

        let fbeBegin = this._OrderMarginNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderMarginNotify(this._OrderMarginNotifyModel, type, buffer, offset, size)
        this._OrderMarginNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderMarginUpdateSubscriptionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderMarginUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUpdateSubscriptionRequestModel.verify(), 'client.OrderMarginUpdateSubscriptionRequest validation failed!')

        let fbeBegin = this._OrderMarginUpdateSubscriptionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderMarginUpdateSubscriptionRequest(this._OrderMarginUpdateSubscriptionRequestModel, type, buffer, offset, size)
        this._OrderMarginUpdateSubscriptionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderMarginUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderMarginUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUnsubscribeRequestModel.verify(), 'client.OrderMarginUnsubscribeRequest validation failed!')

        let fbeBegin = this._OrderMarginUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderMarginUnsubscribeRequest(this._OrderMarginUnsubscribeRequestModel, type, buffer, offset, size)
        this._OrderMarginUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderMarginUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderMarginUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUnsubscribeResponseModel.verify(), 'client.OrderMarginUnsubscribeResponse validation failed!')

        let fbeBegin = this._OrderMarginUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderMarginUnsubscribeResponse(this._OrderMarginUnsubscribeResponseModel, type, buffer, offset, size)
        this._OrderMarginUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderMarginGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderMarginGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginGetRequestModel.verify(), 'client.OrderMarginGetRequest validation failed!')

        let fbeBegin = this._OrderMarginGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderMarginGetRequest(this._OrderMarginGetRequestModel, type, buffer, offset, size)
        this._OrderMarginGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderMarginGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderMarginGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginGetResponseModel.verify(), 'client.OrderMarginGetResponse validation failed!')

        let fbeBegin = this._OrderMarginGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderMarginGetResponse(this._OrderMarginGetResponseModel, type, buffer, offset, size)
        this._OrderMarginGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateInfoSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoSubscribeRequestModel.verify(), 'client.OrderStateInfoSubscribeRequest validation failed!')

        let fbeBegin = this._OrderStateInfoSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateInfoSubscribeRequest(this._OrderStateInfoSubscribeRequestModel, type, buffer, offset, size)
        this._OrderStateInfoSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateInfoSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoSubscribeResponseModel.verify(), 'client.OrderStateInfoSubscribeResponse validation failed!')

        let fbeBegin = this._OrderStateInfoSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateInfoSubscribeResponse(this._OrderStateInfoSubscribeResponseModel, type, buffer, offset, size)
        this._OrderStateInfoSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateInfoUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoUnsubscribeRequestModel.verify(), 'client.OrderStateInfoUnsubscribeRequest validation failed!')

        let fbeBegin = this._OrderStateInfoUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateInfoUnsubscribeRequest(this._OrderStateInfoUnsubscribeRequestModel, type, buffer, offset, size)
        this._OrderStateInfoUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateInfoUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoUnsubscribeResponseModel.verify(), 'client.OrderStateInfoUnsubscribeResponse validation failed!')

        let fbeBegin = this._OrderStateInfoUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateInfoUnsubscribeResponse(this._OrderStateInfoUnsubscribeResponseModel, type, buffer, offset, size)
        this._OrderStateInfoUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderStateInfoNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderStateInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoNotifyModel.verify(), 'client.OrderStateInfoNotify validation failed!')

        let fbeBegin = this._OrderStateInfoNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderStateInfoNotify(this._OrderStateInfoNotifyModel, type, buffer, offset, size)
        this._OrderStateInfoNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated003Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003Model.verify(), 'client.Deprecated003 validation failed!')

        let fbeBegin = this._Deprecated003Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated003(this._Deprecated003Model, type, buffer, offset, size)
        this._Deprecated003Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated004Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004Model.verify(), 'client.Deprecated004 validation failed!')

        let fbeBegin = this._Deprecated004Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated004(this._Deprecated004Model, type, buffer, offset, size)
        this._Deprecated004Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated005Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005Model.verify(), 'client.Deprecated005 validation failed!')

        let fbeBegin = this._Deprecated005Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated005(this._Deprecated005Model, type, buffer, offset, size)
        this._Deprecated005Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated006Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006Model.verify(), 'client.Deprecated006 validation failed!')

        let fbeBegin = this._Deprecated006Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated006(this._Deprecated006Model, type, buffer, offset, size)
        this._Deprecated006Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated007Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated007Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated007Model.verify(), 'client.Deprecated007 validation failed!')

        let fbeBegin = this._Deprecated007Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated007(this._Deprecated007Model, type, buffer, offset, size)
        this._Deprecated007Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated008Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated008Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated008Model.verify(), 'client.Deprecated008 validation failed!')

        let fbeBegin = this._Deprecated008Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated008(this._Deprecated008Model, type, buffer, offset, size)
        this._Deprecated008Model.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateRequestModel.verify(), 'client.AccountUpdateRequest validation failed!')

        let fbeBegin = this._AccountUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpdateRequest(this._AccountUpdateRequestModel, type, buffer, offset, size)
        this._AccountUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateResponseModel.verify(), 'client.AccountUpdateResponse validation failed!')

        let fbeBegin = this._AccountUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpdateResponse(this._AccountUpdateResponseModel, type, buffer, offset, size)
        this._AccountUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ProfitClosedInfoGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ProfitClosedInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedInfoGetRequestModel.verify(), 'client.ProfitClosedInfoGetRequest validation failed!')

        let fbeBegin = this._ProfitClosedInfoGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ProfitClosedInfoGetRequest(this._ProfitClosedInfoGetRequestModel, type, buffer, offset, size)
        this._ProfitClosedInfoGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ProfitClosedInfoGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ProfitClosedInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedInfoGetResponseModel.verify(), 'client.ProfitClosedInfoGetResponse validation failed!')

        let fbeBegin = this._ProfitClosedInfoGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ProfitClosedInfoGetResponse(this._ProfitClosedInfoGetResponseModel, type, buffer, offset, size)
        this._ProfitClosedInfoGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossInfoSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoSubscribeRequestModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeRequest validation failed!')

        let fbeBegin = this._SymbolDayProfitLossInfoSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossInfoSubscribeRequest(this._SymbolDayProfitLossInfoSubscribeRequestModel, type, buffer, offset, size)
        this._SymbolDayProfitLossInfoSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossInfoSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoSubscribeResponseModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeResponse validation failed!')

        let fbeBegin = this._SymbolDayProfitLossInfoSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossInfoSubscribeResponse(this._SymbolDayProfitLossInfoSubscribeResponseModel, type, buffer, offset, size)
        this._SymbolDayProfitLossInfoSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest validation failed!')

        let fbeBegin = this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossInfoUpdateSubscriptionRequest(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel, type, buffer, offset, size)
        this._SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossInfoUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUnsubscribeRequestModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeRequest validation failed!')

        let fbeBegin = this._SymbolDayProfitLossInfoUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossInfoUnsubscribeRequest(this._SymbolDayProfitLossInfoUnsubscribeRequestModel, type, buffer, offset, size)
        this._SymbolDayProfitLossInfoUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossInfoUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUnsubscribeResponseModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeResponse validation failed!')

        let fbeBegin = this._SymbolDayProfitLossInfoUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossInfoUnsubscribeResponse(this._SymbolDayProfitLossInfoUnsubscribeResponseModel, type, buffer, offset, size)
        this._SymbolDayProfitLossInfoUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossInfoNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoNotifyModel.verify(), 'client.SymbolDayProfitLossInfoNotify validation failed!')

        let fbeBegin = this._SymbolDayProfitLossInfoNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossInfoNotify(this._SymbolDayProfitLossInfoNotifyModel, type, buffer, offset, size)
        this._SymbolDayProfitLossInfoNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginInfoGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginInfoGetRequestModel.verify(), 'client.LoginInfoGetRequest validation failed!')

        let fbeBegin = this._LoginInfoGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginInfoGetRequest(this._LoginInfoGetRequestModel, type, buffer, offset, size)
        this._LoginInfoGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginInfoGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginInfoGetResponseModel.verify(), 'client.LoginInfoGetResponse validation failed!')

        let fbeBegin = this._LoginInfoGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginInfoGetResponse(this._LoginInfoGetResponseModel, type, buffer, offset, size)
        this._LoginInfoGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoCreateRequestModel.verify(), 'client.DashboardInfoCreateRequest validation failed!')

        let fbeBegin = this._DashboardInfoCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoCreateRequest(this._DashboardInfoCreateRequestModel, type, buffer, offset, size)
        this._DashboardInfoCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoCreateResponseModel.verify(), 'client.DashboardInfoCreateResponse validation failed!')

        let fbeBegin = this._DashboardInfoCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoCreateResponse(this._DashboardInfoCreateResponseModel, type, buffer, offset, size)
        this._DashboardInfoCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoUpdateRequestModel.verify(), 'client.DashboardInfoUpdateRequest validation failed!')

        let fbeBegin = this._DashboardInfoUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoUpdateRequest(this._DashboardInfoUpdateRequestModel, type, buffer, offset, size)
        this._DashboardInfoUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoUpdateResponseModel.verify(), 'client.DashboardInfoUpdateResponse validation failed!')

        let fbeBegin = this._DashboardInfoUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoUpdateResponse(this._DashboardInfoUpdateResponseModel, type, buffer, offset, size)
        this._DashboardInfoUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoListRequestModel.verify(), 'client.DashboardInfoListRequest validation failed!')

        let fbeBegin = this._DashboardInfoListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoListRequest(this._DashboardInfoListRequestModel, type, buffer, offset, size)
        this._DashboardInfoListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoListResponseModel.verify(), 'client.DashboardInfoListResponse validation failed!')

        let fbeBegin = this._DashboardInfoListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoListResponse(this._DashboardInfoListResponseModel, type, buffer, offset, size)
        this._DashboardInfoListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoGetRequestModel.verify(), 'client.DashboardInfoGetRequest validation failed!')

        let fbeBegin = this._DashboardInfoGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoGetRequest(this._DashboardInfoGetRequestModel, type, buffer, offset, size)
        this._DashboardInfoGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoGetResponseModel.verify(), 'client.DashboardInfoGetResponse validation failed!')

        let fbeBegin = this._DashboardInfoGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoGetResponse(this._DashboardInfoGetResponseModel, type, buffer, offset, size)
        this._DashboardInfoGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoRenameRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoRenameRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoRenameRequestModel.verify(), 'client.DashboardInfoRenameRequest validation failed!')

        let fbeBegin = this._DashboardInfoRenameRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoRenameRequest(this._DashboardInfoRenameRequestModel, type, buffer, offset, size)
        this._DashboardInfoRenameRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoRenameResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoRenameResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoRenameResponseModel.verify(), 'client.DashboardInfoRenameResponse validation failed!')

        let fbeBegin = this._DashboardInfoRenameResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoRenameResponse(this._DashboardInfoRenameResponseModel, type, buffer, offset, size)
        this._DashboardInfoRenameResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoDeleteRequestModel.verify(), 'client.DashboardInfoDeleteRequest validation failed!')

        let fbeBegin = this._DashboardInfoDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoDeleteRequest(this._DashboardInfoDeleteRequestModel, type, buffer, offset, size)
        this._DashboardInfoDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DashboardInfoDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DashboardInfoDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoDeleteResponseModel.verify(), 'client.DashboardInfoDeleteResponse validation failed!')

        let fbeBegin = this._DashboardInfoDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DashboardInfoDeleteResponse(this._DashboardInfoDeleteResponseModel, type, buffer, offset, size)
        this._DashboardInfoDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoCreateRequestModel.verify(), 'client.ChartInfoCreateRequest validation failed!')

        let fbeBegin = this._ChartInfoCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoCreateRequest(this._ChartInfoCreateRequestModel, type, buffer, offset, size)
        this._ChartInfoCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoCreateResponseModel.verify(), 'client.ChartInfoCreateResponse validation failed!')

        let fbeBegin = this._ChartInfoCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoCreateResponse(this._ChartInfoCreateResponseModel, type, buffer, offset, size)
        this._ChartInfoCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoUpdateRequestModel.verify(), 'client.ChartInfoUpdateRequest validation failed!')

        let fbeBegin = this._ChartInfoUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoUpdateRequest(this._ChartInfoUpdateRequestModel, type, buffer, offset, size)
        this._ChartInfoUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoUpdateResponseModel.verify(), 'client.ChartInfoUpdateResponse validation failed!')

        let fbeBegin = this._ChartInfoUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoUpdateResponse(this._ChartInfoUpdateResponseModel, type, buffer, offset, size)
        this._ChartInfoUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoListRequestModel.verify(), 'client.ChartInfoListRequest validation failed!')

        let fbeBegin = this._ChartInfoListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoListRequest(this._ChartInfoListRequestModel, type, buffer, offset, size)
        this._ChartInfoListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoListResponseModel.verify(), 'client.ChartInfoListResponse validation failed!')

        let fbeBegin = this._ChartInfoListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoListResponse(this._ChartInfoListResponseModel, type, buffer, offset, size)
        this._ChartInfoListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoDeleteRequestModel.verify(), 'client.ChartInfoDeleteRequest validation failed!')

        let fbeBegin = this._ChartInfoDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoDeleteRequest(this._ChartInfoDeleteRequestModel, type, buffer, offset, size)
        this._ChartInfoDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoDeleteResponseModel.verify(), 'client.ChartInfoDeleteResponse validation failed!')

        let fbeBegin = this._ChartInfoDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoDeleteResponse(this._ChartInfoDeleteResponseModel, type, buffer, offset, size)
        this._ChartInfoDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoGetRequestModel.verify(), 'client.ChartInfoGetRequest validation failed!')

        let fbeBegin = this._ChartInfoGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoGetRequest(this._ChartInfoGetRequestModel, type, buffer, offset, size)
        this._ChartInfoGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartInfoGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoGetResponseModel.verify(), 'client.ChartInfoGetResponse validation failed!')

        let fbeBegin = this._ChartInfoGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartInfoGetResponse(this._ChartInfoGetResponseModel, type, buffer, offset, size)
        this._ChartInfoGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossMultipleInfoSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest validation failed!')

        let fbeBegin = this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossMultipleInfoSubscribeRequest(this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel, type, buffer, offset, size)
        this._SymbolDayProfitLossMultipleInfoSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossMultipleInfoSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse validation failed!')

        let fbeBegin = this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossMultipleInfoSubscribeResponse(this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel, type, buffer, offset, size)
        this._SymbolDayProfitLossMultipleInfoSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')

        let fbeBegin = this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel, type, buffer, offset, size)
        this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest validation failed!')

        let fbeBegin = this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossMultipleInfoUnsubscribeRequest(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel, type, buffer, offset, size)
        this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse validation failed!')

        let fbeBegin = this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossMultipleInfoUnsubscribeResponse(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel, type, buffer, offset, size)
        this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolDayProfitLossMultipleInfoNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolDayProfitLossMultipleInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoNotifyModel.verify(), 'client.SymbolDayProfitLossMultipleInfoNotify validation failed!')

        let fbeBegin = this._SymbolDayProfitLossMultipleInfoNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolDayProfitLossMultipleInfoNotify(this._SymbolDayProfitLossMultipleInfoNotifyModel, type, buffer, offset, size)
        this._SymbolDayProfitLossMultipleInfoNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsServiceGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsServiceGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsServiceGetAllRequestModel.verify(), 'client.NewsServiceGetAllRequest validation failed!')

        let fbeBegin = this._NewsServiceGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsServiceGetAllRequest(this._NewsServiceGetAllRequestModel, type, buffer, offset, size)
        this._NewsServiceGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsServiceGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsServiceGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsServiceGetAllResponseModel.verify(), 'client.NewsServiceGetAllResponse validation failed!')

        let fbeBegin = this._NewsServiceGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsServiceGetAllResponse(this._NewsServiceGetAllResponseModel, type, buffer, offset, size)
        this._NewsServiceGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsRecentArticlesGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsRecentArticlesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsRecentArticlesGetRequestModel.verify(), 'client.NewsRecentArticlesGetRequest validation failed!')

        let fbeBegin = this._NewsRecentArticlesGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsRecentArticlesGetRequest(this._NewsRecentArticlesGetRequestModel, type, buffer, offset, size)
        this._NewsRecentArticlesGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsRecentArticlesGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsRecentArticlesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsRecentArticlesGetResponseModel.verify(), 'client.NewsRecentArticlesGetResponse validation failed!')

        let fbeBegin = this._NewsRecentArticlesGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsRecentArticlesGetResponse(this._NewsRecentArticlesGetResponseModel, type, buffer, offset, size)
        this._NewsRecentArticlesGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsArticleGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsArticleGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._NewsArticleGetRequestModel.verify(), 'client.NewsArticleGetRequest validation failed!')

        let fbeBegin = this._NewsArticleGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsArticleGetRequest(this._NewsArticleGetRequestModel, type, buffer, offset, size)
        this._NewsArticleGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case NewsArticleGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._NewsArticleGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._NewsArticleGetResponseModel.verify(), 'client.NewsArticleGetResponse validation failed!')

        let fbeBegin = this._NewsArticleGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_NewsArticleGetResponse(this._NewsArticleGetResponseModel, type, buffer, offset, size)
        this._NewsArticleGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateWithTypeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateWithTypeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeRequestModel.verify(), 'client.OrderUpdateWithTypeRequest validation failed!')

        let fbeBegin = this._OrderUpdateWithTypeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateWithTypeRequest(this._OrderUpdateWithTypeRequestModel, type, buffer, offset, size)
        this._OrderUpdateWithTypeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateWithTypeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateWithTypeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeResponseModel.verify(), 'client.OrderUpdateWithTypeResponse validation failed!')

        let fbeBegin = this._OrderUpdateWithTypeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateWithTypeResponse(this._OrderUpdateWithTypeResponseModel, type, buffer, offset, size)
        this._OrderUpdateWithTypeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolProfitLossMultipleInfoSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolProfitLossMultipleInfoSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoSubscribeRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeRequest validation failed!')

        let fbeBegin = this._SymbolProfitLossMultipleInfoSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolProfitLossMultipleInfoSubscribeRequest(this._SymbolProfitLossMultipleInfoSubscribeRequestModel, type, buffer, offset, size)
        this._SymbolProfitLossMultipleInfoSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolProfitLossMultipleInfoSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolProfitLossMultipleInfoSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoSubscribeResponseModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeResponse validation failed!')

        let fbeBegin = this._SymbolProfitLossMultipleInfoSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolProfitLossMultipleInfoSubscribeResponse(this._SymbolProfitLossMultipleInfoSubscribeResponseModel, type, buffer, offset, size)
        this._SymbolProfitLossMultipleInfoSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')

        let fbeBegin = this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel, type, buffer, offset, size)
        this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolProfitLossMultipleInfoUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest validation failed!')

        let fbeBegin = this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolProfitLossMultipleInfoUnsubscribeRequest(this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel, type, buffer, offset, size)
        this._SymbolProfitLossMultipleInfoUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolProfitLossMultipleInfoUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse validation failed!')

        let fbeBegin = this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolProfitLossMultipleInfoUnsubscribeResponse(this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel, type, buffer, offset, size)
        this._SymbolProfitLossMultipleInfoUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolProfitLossMultipleInfoNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolProfitLossMultipleInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoNotifyModel.verify(), 'client.SymbolProfitLossMultipleInfoNotify validation failed!')

        let fbeBegin = this._SymbolProfitLossMultipleInfoNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolProfitLossMultipleInfoNotify(this._SymbolProfitLossMultipleInfoNotifyModel, type, buffer, offset, size)
        this._SymbolProfitLossMultipleInfoNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginWithPasswordRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginWithPasswordRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordRequestModel.verify(), 'client.LoginWithPasswordRequest validation failed!')

        let fbeBegin = this._LoginWithPasswordRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginWithPasswordRequest(this._LoginWithPasswordRequestModel, type, buffer, offset, size)
        this._LoginWithPasswordRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginWithPasswordResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginWithPasswordResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordResponseModel.verify(), 'client.LoginWithPasswordResponse validation failed!')

        let fbeBegin = this._LoginWithPasswordResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginWithPasswordResponse(this._LoginWithPasswordResponseModel, type, buffer, offset, size)
        this._LoginWithPasswordResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginWithTokenRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginWithTokenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenRequestModel.verify(), 'client.LoginWithTokenRequest validation failed!')

        let fbeBegin = this._LoginWithTokenRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginWithTokenRequest(this._LoginWithTokenRequestModel, type, buffer, offset, size)
        this._LoginWithTokenRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LoginWithTokenResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LoginWithTokenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenResponseModel.verify(), 'client.LoginWithTokenResponse validation failed!')

        let fbeBegin = this._LoginWithTokenResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LoginWithTokenResponse(this._LoginWithTokenResponseModel, type, buffer, offset, size)
        this._LoginWithTokenResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case RefreshTokenRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RefreshTokenRequestModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenRequestModel.verify(), 'client.RefreshTokenRequest validation failed!')

        let fbeBegin = this._RefreshTokenRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RefreshTokenRequest(this._RefreshTokenRequestModel, type, buffer, offset, size)
        this._RefreshTokenRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case RefreshTokenResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RefreshTokenResponseModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenResponseModel.verify(), 'client.RefreshTokenResponse validation failed!')

        let fbeBegin = this._RefreshTokenResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_RefreshTokenResponse(this._RefreshTokenResponseModel, type, buffer, offset, size)
        this._RefreshTokenResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Logout2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Logout2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._Logout2RequestModel.verify(), 'client.Logout2Request validation failed!')

        let fbeBegin = this._Logout2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Logout2Request(this._Logout2RequestModel, type, buffer, offset, size)
        this._Logout2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case Logout2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Logout2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Logout2ResponseModel.verify(), 'client.Logout2Response validation failed!')

        let fbeBegin = this._Logout2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Logout2Response(this._Logout2ResponseModel, type, buffer, offset, size)
        this._Logout2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountChangePasswordRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountChangePasswordRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountChangePasswordRequestModel.verify(), 'client.AccountChangePasswordRequest validation failed!')

        let fbeBegin = this._AccountChangePasswordRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountChangePasswordRequest(this._AccountChangePasswordRequestModel, type, buffer, offset, size)
        this._AccountChangePasswordRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountChangePasswordResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountChangePasswordResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountChangePasswordResponseModel.verify(), 'client.AccountChangePasswordResponse validation failed!')

        let fbeBegin = this._AccountChangePasswordResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountChangePasswordResponse(this._AccountChangePasswordResponseModel, type, buffer, offset, size)
        this._AccountChangePasswordResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PushNotificationTokenRegisterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PushNotificationTokenRegisterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenRegisterRequestModel.verify(), 'client.PushNotificationTokenRegisterRequest validation failed!')

        let fbeBegin = this._PushNotificationTokenRegisterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PushNotificationTokenRegisterRequest(this._PushNotificationTokenRegisterRequestModel, type, buffer, offset, size)
        this._PushNotificationTokenRegisterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PushNotificationTokenRegisterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PushNotificationTokenRegisterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenRegisterResponseModel.verify(), 'client.PushNotificationTokenRegisterResponse validation failed!')

        let fbeBegin = this._PushNotificationTokenRegisterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PushNotificationTokenRegisterResponse(this._PushNotificationTokenRegisterResponseModel, type, buffer, offset, size)
        this._PushNotificationTokenRegisterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DesiredAppSettingsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DesiredAppSettingsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DesiredAppSettingsGetRequestModel.verify(), 'client.DesiredAppSettingsGetRequest validation failed!')

        let fbeBegin = this._DesiredAppSettingsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DesiredAppSettingsGetRequest(this._DesiredAppSettingsGetRequestModel, type, buffer, offset, size)
        this._DesiredAppSettingsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DesiredAppSettingsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DesiredAppSettingsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DesiredAppSettingsGetResponseModel.verify(), 'client.DesiredAppSettingsGetResponse validation failed!')

        let fbeBegin = this._DesiredAppSettingsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DesiredAppSettingsGetResponse(this._DesiredAppSettingsGetResponseModel, type, buffer, offset, size)
        this._DesiredAppSettingsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AuthTokensGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AuthTokensGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensGetAllRequestModel.verify(), 'client.AuthTokensGetAllRequest validation failed!')

        let fbeBegin = this._AuthTokensGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AuthTokensGetAllRequest(this._AuthTokensGetAllRequestModel, type, buffer, offset, size)
        this._AuthTokensGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AuthTokensGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AuthTokensGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensGetAllResponseModel.verify(), 'client.AuthTokensGetAllResponse validation failed!')

        let fbeBegin = this._AuthTokensGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AuthTokensGetAllResponse(this._AuthTokensGetAllResponseModel, type, buffer, offset, size)
        this._AuthTokensGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AuthTokensDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AuthTokensDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensDeleteRequestModel.verify(), 'client.AuthTokensDeleteRequest validation failed!')

        let fbeBegin = this._AuthTokensDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AuthTokensDeleteRequest(this._AuthTokensDeleteRequestModel, type, buffer, offset, size)
        this._AuthTokensDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AuthTokensDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AuthTokensDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensDeleteResponseModel.verify(), 'client.AuthTokensDeleteResponse validation failed!')

        let fbeBegin = this._AuthTokensDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AuthTokensDeleteResponse(this._AuthTokensDeleteResponseModel, type, buffer, offset, size)
        this._AuthTokensDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupListRequestModel.verify(), 'client.LegalDocumentGroupListRequest validation failed!')

        let fbeBegin = this._LegalDocumentGroupListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupListRequest(this._LegalDocumentGroupListRequestModel, type, buffer, offset, size)
        this._LegalDocumentGroupListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGroupListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGroupListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupListResponseModel.verify(), 'client.LegalDocumentGroupListResponse validation failed!')

        let fbeBegin = this._LegalDocumentGroupListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGroupListResponse(this._LegalDocumentGroupListResponseModel, type, buffer, offset, size)
        this._LegalDocumentGroupListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetRequestModel.verify(), 'client.LegalDocumentGetRequest validation failed!')

        let fbeBegin = this._LegalDocumentGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGetRequest(this._LegalDocumentGetRequestModel, type, buffer, offset, size)
        this._LegalDocumentGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LegalDocumentGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LegalDocumentGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResponseModel.verify(), 'client.LegalDocumentGetResponse validation failed!')

        let fbeBegin = this._LegalDocumentGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LegalDocumentGetResponse(this._LegalDocumentGetResponseModel, type, buffer, offset, size)
        this._LegalDocumentGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PushNotificationTokenUnRegisterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PushNotificationTokenUnRegisterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenUnRegisterRequestModel.verify(), 'client.PushNotificationTokenUnRegisterRequest validation failed!')

        let fbeBegin = this._PushNotificationTokenUnRegisterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PushNotificationTokenUnRegisterRequest(this._PushNotificationTokenUnRegisterRequestModel, type, buffer, offset, size)
        this._PushNotificationTokenUnRegisterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PushNotificationTokenUnRegisterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PushNotificationTokenUnRegisterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenUnRegisterResponseModel.verify(), 'client.PushNotificationTokenUnRegisterResponse validation failed!')

        let fbeBegin = this._PushNotificationTokenUnRegisterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PushNotificationTokenUnRegisterResponse(this._PushNotificationTokenUnRegisterResponseModel, type, buffer, offset, size)
        this._PushNotificationTokenUnRegisterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.exposedProxy != null) && this.exposedProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding client client
 */
class Client extends fbe.Client {
  /**
   * Initialize client client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._exposedClient = new exposed.Client(this.sendBuffer, this.receiveBuffer)
    this._PingRequestSenderModel = new PingRequestModel(this.sendBuffer)
    this._PingRequestReceiverValue = new PingRequest()
    this._PingRequestReceiverModel = new PingRequestModel()
    this._PongResponseSenderModel = new PongResponseModel(this.sendBuffer)
    this._PongResponseReceiverValue = new PongResponse()
    this._PongResponseReceiverModel = new PongResponseModel()
    this._LoginRequestSenderModel = new LoginRequestModel(this.sendBuffer)
    this._LoginRequestReceiverValue = new LoginRequest()
    this._LoginRequestReceiverModel = new LoginRequestModel()
    this._LoginResponseSenderModel = new LoginResponseModel(this.sendBuffer)
    this._LoginResponseReceiverValue = new LoginResponse()
    this._LoginResponseReceiverModel = new LoginResponseModel()
    this._LoginRejectSenderModel = new LoginRejectModel(this.sendBuffer)
    this._LoginRejectReceiverValue = new LoginReject()
    this._LoginRejectReceiverModel = new LoginRejectModel()
    this._LogoutRequestSenderModel = new LogoutRequestModel(this.sendBuffer)
    this._LogoutRequestReceiverValue = new LogoutRequest()
    this._LogoutRequestReceiverModel = new LogoutRequestModel()
    this._LogoutResponseSenderModel = new LogoutResponseModel(this.sendBuffer)
    this._LogoutResponseReceiverValue = new LogoutResponse()
    this._LogoutResponseReceiverModel = new LogoutResponseModel()
    this._LogoutNotifySenderModel = new LogoutNotifyModel(this.sendBuffer)
    this._LogoutNotifyReceiverValue = new LogoutNotify()
    this._LogoutNotifyReceiverModel = new LogoutNotifyModel()
    this._SessionInfoRequestSenderModel = new SessionInfoRequestModel(this.sendBuffer)
    this._SessionInfoRequestReceiverValue = new SessionInfoRequest()
    this._SessionInfoRequestReceiverModel = new SessionInfoRequestModel()
    this._SessionInfoResponseSenderModel = new SessionInfoResponseModel(this.sendBuffer)
    this._SessionInfoResponseReceiverValue = new SessionInfoResponse()
    this._SessionInfoResponseReceiverModel = new SessionInfoResponseModel()
    this._TimeInfoRequestSenderModel = new TimeInfoRequestModel(this.sendBuffer)
    this._TimeInfoRequestReceiverValue = new TimeInfoRequest()
    this._TimeInfoRequestReceiverModel = new TimeInfoRequestModel()
    this._TimeInfoResponseSenderModel = new TimeInfoResponseModel(this.sendBuffer)
    this._TimeInfoResponseReceiverValue = new TimeInfoResponse()
    this._TimeInfoResponseReceiverModel = new TimeInfoResponseModel()
    this._TimezoneInfoRequestSenderModel = new TimezoneInfoRequestModel(this.sendBuffer)
    this._TimezoneInfoRequestReceiverValue = new TimezoneInfoRequest()
    this._TimezoneInfoRequestReceiverModel = new TimezoneInfoRequestModel()
    this._TimezoneInfoResponseSenderModel = new TimezoneInfoResponseModel(this.sendBuffer)
    this._TimezoneInfoResponseReceiverValue = new TimezoneInfoResponse()
    this._TimezoneInfoResponseReceiverModel = new TimezoneInfoResponseModel()
    this._TimezoneInfoUpdateNotifySenderModel = new TimezoneInfoUpdateNotifyModel(this.sendBuffer)
    this._TimezoneInfoUpdateNotifyReceiverValue = new TimezoneInfoUpdateNotify()
    this._TimezoneInfoUpdateNotifyReceiverModel = new TimezoneInfoUpdateNotifyModel()
    this._CurrencyInfoRequestSenderModel = new CurrencyInfoRequestModel(this.sendBuffer)
    this._CurrencyInfoRequestReceiverValue = new CurrencyInfoRequest()
    this._CurrencyInfoRequestReceiverModel = new CurrencyInfoRequestModel()
    this._CurrencyInfoResponseSenderModel = new CurrencyInfoResponseModel(this.sendBuffer)
    this._CurrencyInfoResponseReceiverValue = new CurrencyInfoResponse()
    this._CurrencyInfoResponseReceiverModel = new CurrencyInfoResponseModel()
    this._CurrencyListRequestSenderModel = new CurrencyListRequestModel(this.sendBuffer)
    this._CurrencyListRequestReceiverValue = new CurrencyListRequest()
    this._CurrencyListRequestReceiverModel = new CurrencyListRequestModel()
    this._CurrencyListResponseSenderModel = new CurrencyListResponseModel(this.sendBuffer)
    this._CurrencyListResponseReceiverValue = new CurrencyListResponse()
    this._CurrencyListResponseReceiverModel = new CurrencyListResponseModel()
    this._CurrencyInfoUpdateNotifySenderModel = new CurrencyInfoUpdateNotifyModel(this.sendBuffer)
    this._CurrencyInfoUpdateNotifyReceiverValue = new CurrencyInfoUpdateNotify()
    this._CurrencyInfoUpdateNotifyReceiverModel = new CurrencyInfoUpdateNotifyModel()
    this._SymbolInfoRequestSenderModel = new SymbolInfoRequestModel(this.sendBuffer)
    this._SymbolInfoRequestReceiverValue = new SymbolInfoRequest()
    this._SymbolInfoRequestReceiverModel = new SymbolInfoRequestModel()
    this._SymbolInfoResponseSenderModel = new SymbolInfoResponseModel(this.sendBuffer)
    this._SymbolInfoResponseReceiverValue = new SymbolInfoResponse()
    this._SymbolInfoResponseReceiverModel = new SymbolInfoResponseModel()
    this._SymbolListRequestSenderModel = new SymbolListRequestModel(this.sendBuffer)
    this._SymbolListRequestReceiverValue = new SymbolListRequest()
    this._SymbolListRequestReceiverModel = new SymbolListRequestModel()
    this._SymbolListResponseSenderModel = new SymbolListResponseModel(this.sendBuffer)
    this._SymbolListResponseReceiverValue = new SymbolListResponse()
    this._SymbolListResponseReceiverModel = new SymbolListResponseModel()
    this._SymbolInfoUpdateNotifySenderModel = new SymbolInfoUpdateNotifyModel(this.sendBuffer)
    this._SymbolInfoUpdateNotifyReceiverValue = new SymbolInfoUpdateNotify()
    this._SymbolInfoUpdateNotifyReceiverModel = new SymbolInfoUpdateNotifyModel()
    this._TickInfoRequestSenderModel = new TickInfoRequestModel(this.sendBuffer)
    this._TickInfoRequestReceiverValue = new TickInfoRequest()
    this._TickInfoRequestReceiverModel = new TickInfoRequestModel()
    this._TickInfoResponseSenderModel = new TickInfoResponseModel(this.sendBuffer)
    this._TickInfoResponseReceiverValue = new TickInfoResponse()
    this._TickInfoResponseReceiverModel = new TickInfoResponseModel()
    this._TickFilterRequestSenderModel = new TickFilterRequestModel(this.sendBuffer)
    this._TickFilterRequestReceiverValue = new TickFilterRequest()
    this._TickFilterRequestReceiverModel = new TickFilterRequestModel()
    this._TickFilterResponseSenderModel = new TickFilterResponseModel(this.sendBuffer)
    this._TickFilterResponseReceiverValue = new TickFilterResponse()
    this._TickFilterResponseReceiverModel = new TickFilterResponseModel()
    this._TickListRequestSenderModel = new TickListRequestModel(this.sendBuffer)
    this._TickListRequestReceiverValue = new TickListRequest()
    this._TickListRequestReceiverModel = new TickListRequestModel()
    this._TickListResponseSenderModel = new TickListResponseModel(this.sendBuffer)
    this._TickListResponseReceiverValue = new TickListResponse()
    this._TickListResponseReceiverModel = new TickListResponseModel()
    this._Level2InfoRequestSenderModel = new Level2InfoRequestModel(this.sendBuffer)
    this._Level2InfoRequestReceiverValue = new Level2InfoRequest()
    this._Level2InfoRequestReceiverModel = new Level2InfoRequestModel()
    this._Level2InfoResponseSenderModel = new Level2InfoResponseModel(this.sendBuffer)
    this._Level2InfoResponseReceiverValue = new Level2InfoResponse()
    this._Level2InfoResponseReceiverModel = new Level2InfoResponseModel()
    this._Level2FilterRequestSenderModel = new Level2FilterRequestModel(this.sendBuffer)
    this._Level2FilterRequestReceiverValue = new Level2FilterRequest()
    this._Level2FilterRequestReceiverModel = new Level2FilterRequestModel()
    this._Level2FilterResponseSenderModel = new Level2FilterResponseModel(this.sendBuffer)
    this._Level2FilterResponseReceiverValue = new Level2FilterResponse()
    this._Level2FilterResponseReceiverModel = new Level2FilterResponseModel()
    this._Level2ListRequestSenderModel = new Level2ListRequestModel(this.sendBuffer)
    this._Level2ListRequestReceiverValue = new Level2ListRequest()
    this._Level2ListRequestReceiverModel = new Level2ListRequestModel()
    this._Level2ListResponseSenderModel = new Level2ListResponseModel(this.sendBuffer)
    this._Level2ListResponseReceiverValue = new Level2ListResponse()
    this._Level2ListResponseReceiverModel = new Level2ListResponseModel()
    this._FeedSubscriptionInfoRequestSenderModel = new FeedSubscriptionInfoRequestModel(this.sendBuffer)
    this._FeedSubscriptionInfoRequestReceiverValue = new FeedSubscriptionInfoRequest()
    this._FeedSubscriptionInfoRequestReceiverModel = new FeedSubscriptionInfoRequestModel()
    this._FeedSubscriptionInfoResponseSenderModel = new FeedSubscriptionInfoResponseModel(this.sendBuffer)
    this._FeedSubscriptionInfoResponseReceiverValue = new FeedSubscriptionInfoResponse()
    this._FeedSubscriptionInfoResponseReceiverModel = new FeedSubscriptionInfoResponseModel()
    this._FeedSubscribeRequestSenderModel = new FeedSubscribeRequestModel(this.sendBuffer)
    this._FeedSubscribeRequestReceiverValue = new FeedSubscribeRequest()
    this._FeedSubscribeRequestReceiverModel = new FeedSubscribeRequestModel()
    this._FeedSubscribeAllRequestSenderModel = new FeedSubscribeAllRequestModel(this.sendBuffer)
    this._FeedSubscribeAllRequestReceiverValue = new FeedSubscribeAllRequest()
    this._FeedSubscribeAllRequestReceiverModel = new FeedSubscribeAllRequestModel()
    this._FeedSubscribeResponseSenderModel = new FeedSubscribeResponseModel(this.sendBuffer)
    this._FeedSubscribeResponseReceiverValue = new FeedSubscribeResponse()
    this._FeedSubscribeResponseReceiverModel = new FeedSubscribeResponseModel()
    this._FeedUnsubscribeRequestSenderModel = new FeedUnsubscribeRequestModel(this.sendBuffer)
    this._FeedUnsubscribeRequestReceiverValue = new FeedUnsubscribeRequest()
    this._FeedUnsubscribeRequestReceiverModel = new FeedUnsubscribeRequestModel()
    this._FeedUnsubscribeAllRequestSenderModel = new FeedUnsubscribeAllRequestModel(this.sendBuffer)
    this._FeedUnsubscribeAllRequestReceiverValue = new FeedUnsubscribeAllRequest()
    this._FeedUnsubscribeAllRequestReceiverModel = new FeedUnsubscribeAllRequestModel()
    this._FeedUnsubscribeResponseSenderModel = new FeedUnsubscribeResponseModel(this.sendBuffer)
    this._FeedUnsubscribeResponseReceiverValue = new FeedUnsubscribeResponse()
    this._FeedUnsubscribeResponseReceiverModel = new FeedUnsubscribeResponseModel()
    this._TickSnapshotNotifySenderModel = new TickSnapshotNotifyModel(this.sendBuffer)
    this._TickSnapshotNotifyReceiverValue = new TickSnapshotNotify()
    this._TickSnapshotNotifyReceiverModel = new TickSnapshotNotifyModel()
    this._TickUpdateNotifySenderModel = new TickUpdateNotifyModel(this.sendBuffer)
    this._TickUpdateNotifyReceiverValue = new TickUpdateNotify()
    this._TickUpdateNotifyReceiverModel = new TickUpdateNotifyModel()
    this._Level2SnapshotNotifySenderModel = new Level2SnapshotNotifyModel(this.sendBuffer)
    this._Level2SnapshotNotifyReceiverValue = new Level2SnapshotNotify()
    this._Level2SnapshotNotifyReceiverModel = new Level2SnapshotNotifyModel()
    this._Level2UpdateNotifySenderModel = new Level2UpdateNotifyModel(this.sendBuffer)
    this._Level2UpdateNotifyReceiverValue = new Level2UpdateNotify()
    this._Level2UpdateNotifyReceiverModel = new Level2UpdateNotifyModel()
    this._ChartBarHistoryRequestSenderModel = new ChartBarHistoryRequestModel(this.sendBuffer)
    this._ChartBarHistoryRequestReceiverValue = new ChartBarHistoryRequest()
    this._ChartBarHistoryRequestReceiverModel = new ChartBarHistoryRequestModel()
    this._ChartBarHistoryResponseSenderModel = new ChartBarHistoryResponseModel(this.sendBuffer)
    this._ChartBarHistoryResponseReceiverValue = new ChartBarHistoryResponse()
    this._ChartBarHistoryResponseReceiverModel = new ChartBarHistoryResponseModel()
    this._ChartBarSubscribeRequestSenderModel = new ChartBarSubscribeRequestModel(this.sendBuffer)
    this._ChartBarSubscribeRequestReceiverValue = new ChartBarSubscribeRequest()
    this._ChartBarSubscribeRequestReceiverModel = new ChartBarSubscribeRequestModel()
    this._ChartBarSubscribeResponseSenderModel = new ChartBarSubscribeResponseModel(this.sendBuffer)
    this._ChartBarSubscribeResponseReceiverValue = new ChartBarSubscribeResponse()
    this._ChartBarSubscribeResponseReceiverModel = new ChartBarSubscribeResponseModel()
    this._ChartBarUnsubscribeRequestSenderModel = new ChartBarUnsubscribeRequestModel(this.sendBuffer)
    this._ChartBarUnsubscribeRequestReceiverValue = new ChartBarUnsubscribeRequest()
    this._ChartBarUnsubscribeRequestReceiverModel = new ChartBarUnsubscribeRequestModel()
    this._ChartBarUnsubscribeResponseSenderModel = new ChartBarUnsubscribeResponseModel(this.sendBuffer)
    this._ChartBarUnsubscribeResponseReceiverValue = new ChartBarUnsubscribeResponse()
    this._ChartBarUnsubscribeResponseReceiverModel = new ChartBarUnsubscribeResponseModel()
    this._ChartBarNotifySenderModel = new ChartBarNotifyModel(this.sendBuffer)
    this._ChartBarNotifyReceiverValue = new ChartBarNotify()
    this._ChartBarNotifyReceiverModel = new ChartBarNotifyModel()
    this._ChartBarUpdateNotifySenderModel = new ChartBarUpdateNotifyModel(this.sendBuffer)
    this._ChartBarUpdateNotifyReceiverValue = new ChartBarUpdateNotify()
    this._ChartBarUpdateNotifyReceiverModel = new ChartBarUpdateNotifyModel()
    this._PlatformInfoRequestSenderModel = new PlatformInfoRequestModel(this.sendBuffer)
    this._PlatformInfoRequestReceiverValue = new PlatformInfoRequest()
    this._PlatformInfoRequestReceiverModel = new PlatformInfoRequestModel()
    this._PlatformInfoResponseSenderModel = new PlatformInfoResponseModel(this.sendBuffer)
    this._PlatformInfoResponseReceiverValue = new PlatformInfoResponse()
    this._PlatformInfoResponseReceiverModel = new PlatformInfoResponseModel()
    this._PlatformInfoUpdateNotifySenderModel = new PlatformInfoUpdateNotifyModel(this.sendBuffer)
    this._PlatformInfoUpdateNotifyReceiverValue = new PlatformInfoUpdateNotify()
    this._PlatformInfoUpdateNotifyReceiverModel = new PlatformInfoUpdateNotifyModel()
    this._AccountInfoRequestSenderModel = new AccountInfoRequestModel(this.sendBuffer)
    this._AccountInfoRequestReceiverValue = new AccountInfoRequest()
    this._AccountInfoRequestReceiverModel = new AccountInfoRequestModel()
    this._AccountInfoResponseSenderModel = new AccountInfoResponseModel(this.sendBuffer)
    this._AccountInfoResponseReceiverValue = new AccountInfoResponse()
    this._AccountInfoResponseReceiverModel = new AccountInfoResponseModel()
    this._AccountInfoUpdateNotifySenderModel = new AccountInfoUpdateNotifyModel(this.sendBuffer)
    this._AccountInfoUpdateNotifyReceiverValue = new AccountInfoUpdateNotify()
    this._AccountInfoUpdateNotifyReceiverModel = new AccountInfoUpdateNotifyModel()
    this._MarginInfoRequestSenderModel = new MarginInfoRequestModel(this.sendBuffer)
    this._MarginInfoRequestReceiverValue = new MarginInfoRequest()
    this._MarginInfoRequestReceiverModel = new MarginInfoRequestModel()
    this._MarginInfoResponseSenderModel = new MarginInfoResponseModel(this.sendBuffer)
    this._MarginInfoResponseReceiverValue = new MarginInfoResponse()
    this._MarginInfoResponseReceiverModel = new MarginInfoResponseModel()
    this._MarginInfoUpdateNotifySenderModel = new MarginInfoUpdateNotifyModel(this.sendBuffer)
    this._MarginInfoUpdateNotifyReceiverValue = new MarginInfoUpdateNotify()
    this._MarginInfoUpdateNotifyReceiverModel = new MarginInfoUpdateNotifyModel()
    this._MarginCallNotifySenderModel = new MarginCallNotifyModel(this.sendBuffer)
    this._MarginCallNotifyReceiverValue = new MarginCallNotify()
    this._MarginCallNotifyReceiverModel = new MarginCallNotifyModel()
    this._StopOutNotifySenderModel = new StopOutNotifyModel(this.sendBuffer)
    this._StopOutNotifyReceiverValue = new StopOutNotify()
    this._StopOutNotifyReceiverModel = new StopOutNotifyModel()
    this._AssetInfoRequestSenderModel = new AssetInfoRequestModel(this.sendBuffer)
    this._AssetInfoRequestReceiverValue = new AssetInfoRequest()
    this._AssetInfoRequestReceiverModel = new AssetInfoRequestModel()
    this._AssetInfoResponseSenderModel = new AssetInfoResponseModel(this.sendBuffer)
    this._AssetInfoResponseReceiverValue = new AssetInfoResponse()
    this._AssetInfoResponseReceiverModel = new AssetInfoResponseModel()
    this._AssetListRequestSenderModel = new AssetListRequestModel(this.sendBuffer)
    this._AssetListRequestReceiverValue = new AssetListRequest()
    this._AssetListRequestReceiverModel = new AssetListRequestModel()
    this._AssetListResponseSenderModel = new AssetListResponseModel(this.sendBuffer)
    this._AssetListResponseReceiverValue = new AssetListResponse()
    this._AssetListResponseReceiverModel = new AssetListResponseModel()
    this._AssetInfoUpdateNotifySenderModel = new AssetInfoUpdateNotifyModel(this.sendBuffer)
    this._AssetInfoUpdateNotifyReceiverValue = new AssetInfoUpdateNotify()
    this._AssetInfoUpdateNotifyReceiverModel = new AssetInfoUpdateNotifyModel()
    this._OrderInfoRequestSenderModel = new OrderInfoRequestModel(this.sendBuffer)
    this._OrderInfoRequestReceiverValue = new OrderInfoRequest()
    this._OrderInfoRequestReceiverModel = new OrderInfoRequestModel()
    this._OrderInfoResponseSenderModel = new OrderInfoResponseModel(this.sendBuffer)
    this._OrderInfoResponseReceiverValue = new OrderInfoResponse()
    this._OrderInfoResponseReceiverModel = new OrderInfoResponseModel()
    this._OrderListRequestSenderModel = new OrderListRequestModel(this.sendBuffer)
    this._OrderListRequestReceiverValue = new OrderListRequest()
    this._OrderListRequestReceiverModel = new OrderListRequestModel()
    this._OrderListResponseSenderModel = new OrderListResponseModel(this.sendBuffer)
    this._OrderListResponseReceiverValue = new OrderListResponse()
    this._OrderListResponseReceiverModel = new OrderListResponseModel()
    this._OrderOpenRequestSenderModel = new OrderOpenRequestModel(this.sendBuffer)
    this._OrderOpenRequestReceiverValue = new OrderOpenRequest()
    this._OrderOpenRequestReceiverModel = new OrderOpenRequestModel()
    this._OrderOpenResponseSenderModel = new OrderOpenResponseModel(this.sendBuffer)
    this._OrderOpenResponseReceiverValue = new OrderOpenResponse()
    this._OrderOpenResponseReceiverModel = new OrderOpenResponseModel()
    this._OrderUpdateRequestSenderModel = new OrderUpdateRequestModel(this.sendBuffer)
    this._OrderUpdateRequestReceiverValue = new OrderUpdateRequest()
    this._OrderUpdateRequestReceiverModel = new OrderUpdateRequestModel()
    this._OrderUpdateResponseSenderModel = new OrderUpdateResponseModel(this.sendBuffer)
    this._OrderUpdateResponseReceiverValue = new OrderUpdateResponse()
    this._OrderUpdateResponseReceiverModel = new OrderUpdateResponseModel()
    this._OrderCancelRequestSenderModel = new OrderCancelRequestModel(this.sendBuffer)
    this._OrderCancelRequestReceiverValue = new OrderCancelRequest()
    this._OrderCancelRequestReceiverModel = new OrderCancelRequestModel()
    this._OrderCancelResponseSenderModel = new OrderCancelResponseModel(this.sendBuffer)
    this._OrderCancelResponseReceiverValue = new OrderCancelResponse()
    this._OrderCancelResponseReceiverModel = new OrderCancelResponseModel()
    this._OrderCancelAllRequestSenderModel = new OrderCancelAllRequestModel(this.sendBuffer)
    this._OrderCancelAllRequestReceiverValue = new OrderCancelAllRequest()
    this._OrderCancelAllRequestReceiverModel = new OrderCancelAllRequestModel()
    this._OrderCancelAllResponseSenderModel = new OrderCancelAllResponseModel(this.sendBuffer)
    this._OrderCancelAllResponseReceiverValue = new OrderCancelAllResponse()
    this._OrderCancelAllResponseReceiverModel = new OrderCancelAllResponseModel()
    this._OrderCancelAllByIdRequestSenderModel = new OrderCancelAllByIdRequestModel(this.sendBuffer)
    this._OrderCancelAllByIdRequestReceiverValue = new OrderCancelAllByIdRequest()
    this._OrderCancelAllByIdRequestReceiverModel = new OrderCancelAllByIdRequestModel()
    this._OrderCloseRequestSenderModel = new OrderCloseRequestModel(this.sendBuffer)
    this._OrderCloseRequestReceiverValue = new OrderCloseRequest()
    this._OrderCloseRequestReceiverModel = new OrderCloseRequestModel()
    this._OrderCloseResponseSenderModel = new OrderCloseResponseModel(this.sendBuffer)
    this._OrderCloseResponseReceiverValue = new OrderCloseResponse()
    this._OrderCloseResponseReceiverModel = new OrderCloseResponseModel()
    this._OrderCloseAllRequestSenderModel = new OrderCloseAllRequestModel(this.sendBuffer)
    this._OrderCloseAllRequestReceiverValue = new OrderCloseAllRequest()
    this._OrderCloseAllRequestReceiverModel = new OrderCloseAllRequestModel()
    this._OrderCloseAllResponseSenderModel = new OrderCloseAllResponseModel(this.sendBuffer)
    this._OrderCloseAllResponseReceiverValue = new OrderCloseAllResponse()
    this._OrderCloseAllResponseReceiverModel = new OrderCloseAllResponseModel()
    this._OrderCloseAllByIdRequestSenderModel = new OrderCloseAllByIdRequestModel(this.sendBuffer)
    this._OrderCloseAllByIdRequestReceiverValue = new OrderCloseAllByIdRequest()
    this._OrderCloseAllByIdRequestReceiverModel = new OrderCloseAllByIdRequestModel()
    this._OrderCloseByRequestSenderModel = new OrderCloseByRequestModel(this.sendBuffer)
    this._OrderCloseByRequestReceiverValue = new OrderCloseByRequest()
    this._OrderCloseByRequestReceiverModel = new OrderCloseByRequestModel()
    this._OrderCloseByResponseSenderModel = new OrderCloseByResponseModel(this.sendBuffer)
    this._OrderCloseByResponseReceiverValue = new OrderCloseByResponse()
    this._OrderCloseByResponseReceiverModel = new OrderCloseByResponseModel()
    this._OrderCloseByAllRequestSenderModel = new OrderCloseByAllRequestModel(this.sendBuffer)
    this._OrderCloseByAllRequestReceiverValue = new OrderCloseByAllRequest()
    this._OrderCloseByAllRequestReceiverModel = new OrderCloseByAllRequestModel()
    this._OrderCloseByAllResponseSenderModel = new OrderCloseByAllResponseModel(this.sendBuffer)
    this._OrderCloseByAllResponseReceiverValue = new OrderCloseByAllResponse()
    this._OrderCloseByAllResponseReceiverModel = new OrderCloseByAllResponseModel()
    this._OrderCancelOrCloseRequestSenderModel = new OrderCancelOrCloseRequestModel(this.sendBuffer)
    this._OrderCancelOrCloseRequestReceiverValue = new OrderCancelOrCloseRequest()
    this._OrderCancelOrCloseRequestReceiverModel = new OrderCancelOrCloseRequestModel()
    this._OrderCancelOrCloseResponseSenderModel = new OrderCancelOrCloseResponseModel(this.sendBuffer)
    this._OrderCancelOrCloseResponseReceiverValue = new OrderCancelOrCloseResponse()
    this._OrderCancelOrCloseResponseReceiverModel = new OrderCancelOrCloseResponseModel()
    this._OrderCancelOrCloseAllRequestSenderModel = new OrderCancelOrCloseAllRequestModel(this.sendBuffer)
    this._OrderCancelOrCloseAllRequestReceiverValue = new OrderCancelOrCloseAllRequest()
    this._OrderCancelOrCloseAllRequestReceiverModel = new OrderCancelOrCloseAllRequestModel()
    this._OrderCancelOrCloseAllResponseSenderModel = new OrderCancelOrCloseAllResponseModel(this.sendBuffer)
    this._OrderCancelOrCloseAllResponseReceiverValue = new OrderCancelOrCloseAllResponse()
    this._OrderCancelOrCloseAllResponseReceiverModel = new OrderCancelOrCloseAllResponseModel()
    this._OrderInfoUpdateNotifySenderModel = new OrderInfoUpdateNotifyModel(this.sendBuffer)
    this._OrderInfoUpdateNotifyReceiverValue = new OrderInfoUpdateNotify()
    this._OrderInfoUpdateNotifyReceiverModel = new OrderInfoUpdateNotifyModel()
    this._PositionInfoRequestSenderModel = new PositionInfoRequestModel(this.sendBuffer)
    this._PositionInfoRequestReceiverValue = new PositionInfoRequest()
    this._PositionInfoRequestReceiverModel = new PositionInfoRequestModel()
    this._PositionInfoResponseSenderModel = new PositionInfoResponseModel(this.sendBuffer)
    this._PositionInfoResponseReceiverValue = new PositionInfoResponse()
    this._PositionInfoResponseReceiverModel = new PositionInfoResponseModel()
    this._PositionListRequestSenderModel = new PositionListRequestModel(this.sendBuffer)
    this._PositionListRequestReceiverValue = new PositionListRequest()
    this._PositionListRequestReceiverModel = new PositionListRequestModel()
    this._PositionListResponseSenderModel = new PositionListResponseModel(this.sendBuffer)
    this._PositionListResponseReceiverValue = new PositionListResponse()
    this._PositionListResponseReceiverModel = new PositionListResponseModel()
    this._PositionInfoUpdateNotifySenderModel = new PositionInfoUpdateNotifyModel(this.sendBuffer)
    this._PositionInfoUpdateNotifyReceiverValue = new PositionInfoUpdateNotify()
    this._PositionInfoUpdateNotifyReceiverModel = new PositionInfoUpdateNotifyModel()
    this._TradeHistoryInfoRequestSenderModel = new TradeHistoryInfoRequestModel(this.sendBuffer)
    this._TradeHistoryInfoRequestReceiverValue = new TradeHistoryInfoRequest()
    this._TradeHistoryInfoRequestReceiverModel = new TradeHistoryInfoRequestModel()
    this._TradeHistoryInfoResponseSenderModel = new TradeHistoryInfoResponseModel(this.sendBuffer)
    this._TradeHistoryInfoResponseReceiverValue = new TradeHistoryInfoResponse()
    this._TradeHistoryInfoResponseReceiverModel = new TradeHistoryInfoResponseModel()
    this._TradeHistoryInfoFilterRequestSenderModel = new TradeHistoryInfoFilterRequestModel(this.sendBuffer)
    this._TradeHistoryInfoFilterRequestReceiverValue = new TradeHistoryInfoFilterRequest()
    this._TradeHistoryInfoFilterRequestReceiverModel = new TradeHistoryInfoFilterRequestModel()
    this._TradeHistoryInfoByIdRequestSenderModel = new TradeHistoryInfoByIdRequestModel(this.sendBuffer)
    this._TradeHistoryInfoByIdRequestReceiverValue = new TradeHistoryInfoByIdRequest()
    this._TradeHistoryInfoByIdRequestReceiverModel = new TradeHistoryInfoByIdRequestModel()
    this._TradeHistoryInfoByIdResponseSenderModel = new TradeHistoryInfoByIdResponseModel(this.sendBuffer)
    this._TradeHistoryInfoByIdResponseReceiverValue = new TradeHistoryInfoByIdResponse()
    this._TradeHistoryInfoByIdResponseReceiverModel = new TradeHistoryInfoByIdResponseModel()
    this._TradeHistoryInfoUpdateNotifySenderModel = new TradeHistoryInfoUpdateNotifyModel(this.sendBuffer)
    this._TradeHistoryInfoUpdateNotifyReceiverValue = new TradeHistoryInfoUpdateNotify()
    this._TradeHistoryInfoUpdateNotifyReceiverModel = new TradeHistoryInfoUpdateNotifyModel()
    this._CalendarRegularRuleGetRequestSenderModel = new CalendarRegularRuleGetRequestModel(this.sendBuffer)
    this._CalendarRegularRuleGetRequestReceiverValue = new CalendarRegularRuleGetRequest()
    this._CalendarRegularRuleGetRequestReceiverModel = new CalendarRegularRuleGetRequestModel()
    this._CalendarRegularRuleGetResponseSenderModel = new CalendarRegularRuleGetResponseModel(this.sendBuffer)
    this._CalendarRegularRuleGetResponseReceiverValue = new CalendarRegularRuleGetResponse()
    this._CalendarRegularRuleGetResponseReceiverModel = new CalendarRegularRuleGetResponseModel()
    this._CalendarRegularRuleUpdateNotifySenderModel = new CalendarRegularRuleUpdateNotifyModel(this.sendBuffer)
    this._CalendarRegularRuleUpdateNotifyReceiverValue = new CalendarRegularRuleUpdateNotify()
    this._CalendarRegularRuleUpdateNotifyReceiverModel = new CalendarRegularRuleUpdateNotifyModel()
    this._CalendarOffTimeRuleGetRequestSenderModel = new CalendarOffTimeRuleGetRequestModel(this.sendBuffer)
    this._CalendarOffTimeRuleGetRequestReceiverValue = new CalendarOffTimeRuleGetRequest()
    this._CalendarOffTimeRuleGetRequestReceiverModel = new CalendarOffTimeRuleGetRequestModel()
    this._CalendarOffTimeRuleGetResponseSenderModel = new CalendarOffTimeRuleGetResponseModel(this.sendBuffer)
    this._CalendarOffTimeRuleGetResponseReceiverValue = new CalendarOffTimeRuleGetResponse()
    this._CalendarOffTimeRuleGetResponseReceiverModel = new CalendarOffTimeRuleGetResponseModel()
    this._CalendarOffTimeRuleUpdateNotifySenderModel = new CalendarOffTimeRuleUpdateNotifyModel(this.sendBuffer)
    this._CalendarOffTimeRuleUpdateNotifyReceiverValue = new CalendarOffTimeRuleUpdateNotify()
    this._CalendarOffTimeRuleUpdateNotifyReceiverModel = new CalendarOffTimeRuleUpdateNotifyModel()
    this._CalendarRulesGetRequestSenderModel = new CalendarRulesGetRequestModel(this.sendBuffer)
    this._CalendarRulesGetRequestReceiverValue = new CalendarRulesGetRequest()
    this._CalendarRulesGetRequestReceiverModel = new CalendarRulesGetRequestModel()
    this._CalendarRulesGetResponseSenderModel = new CalendarRulesGetResponseModel(this.sendBuffer)
    this._CalendarRulesGetResponseReceiverValue = new CalendarRulesGetResponse()
    this._CalendarRulesGetResponseReceiverModel = new CalendarRulesGetResponseModel()
    this._ChartBarHistoryRangeRequestSenderModel = new ChartBarHistoryRangeRequestModel(this.sendBuffer)
    this._ChartBarHistoryRangeRequestReceiverValue = new ChartBarHistoryRangeRequest()
    this._ChartBarHistoryRangeRequestReceiverModel = new ChartBarHistoryRangeRequestModel()
    this._OrderMarginSubscribeRequestSenderModel = new OrderMarginSubscribeRequestModel(this.sendBuffer)
    this._OrderMarginSubscribeRequestReceiverValue = new OrderMarginSubscribeRequest()
    this._OrderMarginSubscribeRequestReceiverModel = new OrderMarginSubscribeRequestModel()
    this._OrderMarginSubscribeResponseSenderModel = new OrderMarginSubscribeResponseModel(this.sendBuffer)
    this._OrderMarginSubscribeResponseReceiverValue = new OrderMarginSubscribeResponse()
    this._OrderMarginSubscribeResponseReceiverModel = new OrderMarginSubscribeResponseModel()
    this._OrderMarginNotifySenderModel = new OrderMarginNotifyModel(this.sendBuffer)
    this._OrderMarginNotifyReceiverValue = new OrderMarginNotify()
    this._OrderMarginNotifyReceiverModel = new OrderMarginNotifyModel()
    this._OrderMarginUpdateSubscriptionRequestSenderModel = new OrderMarginUpdateSubscriptionRequestModel(this.sendBuffer)
    this._OrderMarginUpdateSubscriptionRequestReceiverValue = new OrderMarginUpdateSubscriptionRequest()
    this._OrderMarginUpdateSubscriptionRequestReceiverModel = new OrderMarginUpdateSubscriptionRequestModel()
    this._OrderMarginUnsubscribeRequestSenderModel = new OrderMarginUnsubscribeRequestModel(this.sendBuffer)
    this._OrderMarginUnsubscribeRequestReceiverValue = new OrderMarginUnsubscribeRequest()
    this._OrderMarginUnsubscribeRequestReceiverModel = new OrderMarginUnsubscribeRequestModel()
    this._OrderMarginUnsubscribeResponseSenderModel = new OrderMarginUnsubscribeResponseModel(this.sendBuffer)
    this._OrderMarginUnsubscribeResponseReceiverValue = new OrderMarginUnsubscribeResponse()
    this._OrderMarginUnsubscribeResponseReceiverModel = new OrderMarginUnsubscribeResponseModel()
    this._OrderMarginGetRequestSenderModel = new OrderMarginGetRequestModel(this.sendBuffer)
    this._OrderMarginGetRequestReceiverValue = new OrderMarginGetRequest()
    this._OrderMarginGetRequestReceiverModel = new OrderMarginGetRequestModel()
    this._OrderMarginGetResponseSenderModel = new OrderMarginGetResponseModel(this.sendBuffer)
    this._OrderMarginGetResponseReceiverValue = new OrderMarginGetResponse()
    this._OrderMarginGetResponseReceiverModel = new OrderMarginGetResponseModel()
    this._OrderStateInfoSubscribeRequestSenderModel = new OrderStateInfoSubscribeRequestModel(this.sendBuffer)
    this._OrderStateInfoSubscribeRequestReceiverValue = new OrderStateInfoSubscribeRequest()
    this._OrderStateInfoSubscribeRequestReceiverModel = new OrderStateInfoSubscribeRequestModel()
    this._OrderStateInfoSubscribeResponseSenderModel = new OrderStateInfoSubscribeResponseModel(this.sendBuffer)
    this._OrderStateInfoSubscribeResponseReceiverValue = new OrderStateInfoSubscribeResponse()
    this._OrderStateInfoSubscribeResponseReceiverModel = new OrderStateInfoSubscribeResponseModel()
    this._OrderStateInfoUnsubscribeRequestSenderModel = new OrderStateInfoUnsubscribeRequestModel(this.sendBuffer)
    this._OrderStateInfoUnsubscribeRequestReceiverValue = new OrderStateInfoUnsubscribeRequest()
    this._OrderStateInfoUnsubscribeRequestReceiverModel = new OrderStateInfoUnsubscribeRequestModel()
    this._OrderStateInfoUnsubscribeResponseSenderModel = new OrderStateInfoUnsubscribeResponseModel(this.sendBuffer)
    this._OrderStateInfoUnsubscribeResponseReceiverValue = new OrderStateInfoUnsubscribeResponse()
    this._OrderStateInfoUnsubscribeResponseReceiverModel = new OrderStateInfoUnsubscribeResponseModel()
    this._OrderStateInfoNotifySenderModel = new OrderStateInfoNotifyModel(this.sendBuffer)
    this._OrderStateInfoNotifyReceiverValue = new OrderStateInfoNotify()
    this._OrderStateInfoNotifyReceiverModel = new OrderStateInfoNotifyModel()
    this._Deprecated003SenderModel = new Deprecated003Model(this.sendBuffer)
    this._Deprecated003ReceiverValue = new Deprecated003()
    this._Deprecated003ReceiverModel = new Deprecated003Model()
    this._Deprecated004SenderModel = new Deprecated004Model(this.sendBuffer)
    this._Deprecated004ReceiverValue = new Deprecated004()
    this._Deprecated004ReceiverModel = new Deprecated004Model()
    this._Deprecated005SenderModel = new Deprecated005Model(this.sendBuffer)
    this._Deprecated005ReceiverValue = new Deprecated005()
    this._Deprecated005ReceiverModel = new Deprecated005Model()
    this._Deprecated006SenderModel = new Deprecated006Model(this.sendBuffer)
    this._Deprecated006ReceiverValue = new Deprecated006()
    this._Deprecated006ReceiverModel = new Deprecated006Model()
    this._Deprecated007SenderModel = new Deprecated007Model(this.sendBuffer)
    this._Deprecated007ReceiverValue = new Deprecated007()
    this._Deprecated007ReceiverModel = new Deprecated007Model()
    this._Deprecated008SenderModel = new Deprecated008Model(this.sendBuffer)
    this._Deprecated008ReceiverValue = new Deprecated008()
    this._Deprecated008ReceiverModel = new Deprecated008Model()
    this._AccountUpdateRequestSenderModel = new AccountUpdateRequestModel(this.sendBuffer)
    this._AccountUpdateRequestReceiverValue = new AccountUpdateRequest()
    this._AccountUpdateRequestReceiverModel = new AccountUpdateRequestModel()
    this._AccountUpdateResponseSenderModel = new AccountUpdateResponseModel(this.sendBuffer)
    this._AccountUpdateResponseReceiverValue = new AccountUpdateResponse()
    this._AccountUpdateResponseReceiverModel = new AccountUpdateResponseModel()
    this._ProfitClosedInfoGetRequestSenderModel = new ProfitClosedInfoGetRequestModel(this.sendBuffer)
    this._ProfitClosedInfoGetRequestReceiverValue = new ProfitClosedInfoGetRequest()
    this._ProfitClosedInfoGetRequestReceiverModel = new ProfitClosedInfoGetRequestModel()
    this._ProfitClosedInfoGetResponseSenderModel = new ProfitClosedInfoGetResponseModel(this.sendBuffer)
    this._ProfitClosedInfoGetResponseReceiverValue = new ProfitClosedInfoGetResponse()
    this._ProfitClosedInfoGetResponseReceiverModel = new ProfitClosedInfoGetResponseModel()
    this._SymbolDayProfitLossInfoSubscribeRequestSenderModel = new SymbolDayProfitLossInfoSubscribeRequestModel(this.sendBuffer)
    this._SymbolDayProfitLossInfoSubscribeRequestReceiverValue = new SymbolDayProfitLossInfoSubscribeRequest()
    this._SymbolDayProfitLossInfoSubscribeRequestReceiverModel = new SymbolDayProfitLossInfoSubscribeRequestModel()
    this._SymbolDayProfitLossInfoSubscribeResponseSenderModel = new SymbolDayProfitLossInfoSubscribeResponseModel(this.sendBuffer)
    this._SymbolDayProfitLossInfoSubscribeResponseReceiverValue = new SymbolDayProfitLossInfoSubscribeResponse()
    this._SymbolDayProfitLossInfoSubscribeResponseReceiverModel = new SymbolDayProfitLossInfoSubscribeResponseModel()
    this._SymbolDayProfitLossInfoUpdateSubscriptionRequestSenderModel = new SymbolDayProfitLossInfoUpdateSubscriptionRequestModel(this.sendBuffer)
    this._SymbolDayProfitLossInfoUpdateSubscriptionRequestReceiverValue = new SymbolDayProfitLossInfoUpdateSubscriptionRequest()
    this._SymbolDayProfitLossInfoUpdateSubscriptionRequestReceiverModel = new SymbolDayProfitLossInfoUpdateSubscriptionRequestModel()
    this._SymbolDayProfitLossInfoUnsubscribeRequestSenderModel = new SymbolDayProfitLossInfoUnsubscribeRequestModel(this.sendBuffer)
    this._SymbolDayProfitLossInfoUnsubscribeRequestReceiverValue = new SymbolDayProfitLossInfoUnsubscribeRequest()
    this._SymbolDayProfitLossInfoUnsubscribeRequestReceiverModel = new SymbolDayProfitLossInfoUnsubscribeRequestModel()
    this._SymbolDayProfitLossInfoUnsubscribeResponseSenderModel = new SymbolDayProfitLossInfoUnsubscribeResponseModel(this.sendBuffer)
    this._SymbolDayProfitLossInfoUnsubscribeResponseReceiverValue = new SymbolDayProfitLossInfoUnsubscribeResponse()
    this._SymbolDayProfitLossInfoUnsubscribeResponseReceiverModel = new SymbolDayProfitLossInfoUnsubscribeResponseModel()
    this._SymbolDayProfitLossInfoNotifySenderModel = new SymbolDayProfitLossInfoNotifyModel(this.sendBuffer)
    this._SymbolDayProfitLossInfoNotifyReceiverValue = new SymbolDayProfitLossInfoNotify()
    this._SymbolDayProfitLossInfoNotifyReceiverModel = new SymbolDayProfitLossInfoNotifyModel()
    this._LoginInfoGetRequestSenderModel = new LoginInfoGetRequestModel(this.sendBuffer)
    this._LoginInfoGetRequestReceiverValue = new LoginInfoGetRequest()
    this._LoginInfoGetRequestReceiverModel = new LoginInfoGetRequestModel()
    this._LoginInfoGetResponseSenderModel = new LoginInfoGetResponseModel(this.sendBuffer)
    this._LoginInfoGetResponseReceiverValue = new LoginInfoGetResponse()
    this._LoginInfoGetResponseReceiverModel = new LoginInfoGetResponseModel()
    this._DashboardInfoCreateRequestSenderModel = new DashboardInfoCreateRequestModel(this.sendBuffer)
    this._DashboardInfoCreateRequestReceiverValue = new DashboardInfoCreateRequest()
    this._DashboardInfoCreateRequestReceiverModel = new DashboardInfoCreateRequestModel()
    this._DashboardInfoCreateResponseSenderModel = new DashboardInfoCreateResponseModel(this.sendBuffer)
    this._DashboardInfoCreateResponseReceiverValue = new DashboardInfoCreateResponse()
    this._DashboardInfoCreateResponseReceiverModel = new DashboardInfoCreateResponseModel()
    this._DashboardInfoUpdateRequestSenderModel = new DashboardInfoUpdateRequestModel(this.sendBuffer)
    this._DashboardInfoUpdateRequestReceiverValue = new DashboardInfoUpdateRequest()
    this._DashboardInfoUpdateRequestReceiverModel = new DashboardInfoUpdateRequestModel()
    this._DashboardInfoUpdateResponseSenderModel = new DashboardInfoUpdateResponseModel(this.sendBuffer)
    this._DashboardInfoUpdateResponseReceiverValue = new DashboardInfoUpdateResponse()
    this._DashboardInfoUpdateResponseReceiverModel = new DashboardInfoUpdateResponseModel()
    this._DashboardInfoListRequestSenderModel = new DashboardInfoListRequestModel(this.sendBuffer)
    this._DashboardInfoListRequestReceiverValue = new DashboardInfoListRequest()
    this._DashboardInfoListRequestReceiverModel = new DashboardInfoListRequestModel()
    this._DashboardInfoListResponseSenderModel = new DashboardInfoListResponseModel(this.sendBuffer)
    this._DashboardInfoListResponseReceiverValue = new DashboardInfoListResponse()
    this._DashboardInfoListResponseReceiverModel = new DashboardInfoListResponseModel()
    this._DashboardInfoGetRequestSenderModel = new DashboardInfoGetRequestModel(this.sendBuffer)
    this._DashboardInfoGetRequestReceiverValue = new DashboardInfoGetRequest()
    this._DashboardInfoGetRequestReceiverModel = new DashboardInfoGetRequestModel()
    this._DashboardInfoGetResponseSenderModel = new DashboardInfoGetResponseModel(this.sendBuffer)
    this._DashboardInfoGetResponseReceiverValue = new DashboardInfoGetResponse()
    this._DashboardInfoGetResponseReceiverModel = new DashboardInfoGetResponseModel()
    this._DashboardInfoRenameRequestSenderModel = new DashboardInfoRenameRequestModel(this.sendBuffer)
    this._DashboardInfoRenameRequestReceiverValue = new DashboardInfoRenameRequest()
    this._DashboardInfoRenameRequestReceiverModel = new DashboardInfoRenameRequestModel()
    this._DashboardInfoRenameResponseSenderModel = new DashboardInfoRenameResponseModel(this.sendBuffer)
    this._DashboardInfoRenameResponseReceiverValue = new DashboardInfoRenameResponse()
    this._DashboardInfoRenameResponseReceiverModel = new DashboardInfoRenameResponseModel()
    this._DashboardInfoDeleteRequestSenderModel = new DashboardInfoDeleteRequestModel(this.sendBuffer)
    this._DashboardInfoDeleteRequestReceiverValue = new DashboardInfoDeleteRequest()
    this._DashboardInfoDeleteRequestReceiverModel = new DashboardInfoDeleteRequestModel()
    this._DashboardInfoDeleteResponseSenderModel = new DashboardInfoDeleteResponseModel(this.sendBuffer)
    this._DashboardInfoDeleteResponseReceiverValue = new DashboardInfoDeleteResponse()
    this._DashboardInfoDeleteResponseReceiverModel = new DashboardInfoDeleteResponseModel()
    this._ChartInfoCreateRequestSenderModel = new ChartInfoCreateRequestModel(this.sendBuffer)
    this._ChartInfoCreateRequestReceiverValue = new ChartInfoCreateRequest()
    this._ChartInfoCreateRequestReceiverModel = new ChartInfoCreateRequestModel()
    this._ChartInfoCreateResponseSenderModel = new ChartInfoCreateResponseModel(this.sendBuffer)
    this._ChartInfoCreateResponseReceiverValue = new ChartInfoCreateResponse()
    this._ChartInfoCreateResponseReceiverModel = new ChartInfoCreateResponseModel()
    this._ChartInfoUpdateRequestSenderModel = new ChartInfoUpdateRequestModel(this.sendBuffer)
    this._ChartInfoUpdateRequestReceiverValue = new ChartInfoUpdateRequest()
    this._ChartInfoUpdateRequestReceiverModel = new ChartInfoUpdateRequestModel()
    this._ChartInfoUpdateResponseSenderModel = new ChartInfoUpdateResponseModel(this.sendBuffer)
    this._ChartInfoUpdateResponseReceiverValue = new ChartInfoUpdateResponse()
    this._ChartInfoUpdateResponseReceiverModel = new ChartInfoUpdateResponseModel()
    this._ChartInfoListRequestSenderModel = new ChartInfoListRequestModel(this.sendBuffer)
    this._ChartInfoListRequestReceiverValue = new ChartInfoListRequest()
    this._ChartInfoListRequestReceiverModel = new ChartInfoListRequestModel()
    this._ChartInfoListResponseSenderModel = new ChartInfoListResponseModel(this.sendBuffer)
    this._ChartInfoListResponseReceiverValue = new ChartInfoListResponse()
    this._ChartInfoListResponseReceiverModel = new ChartInfoListResponseModel()
    this._ChartInfoDeleteRequestSenderModel = new ChartInfoDeleteRequestModel(this.sendBuffer)
    this._ChartInfoDeleteRequestReceiverValue = new ChartInfoDeleteRequest()
    this._ChartInfoDeleteRequestReceiverModel = new ChartInfoDeleteRequestModel()
    this._ChartInfoDeleteResponseSenderModel = new ChartInfoDeleteResponseModel(this.sendBuffer)
    this._ChartInfoDeleteResponseReceiverValue = new ChartInfoDeleteResponse()
    this._ChartInfoDeleteResponseReceiverModel = new ChartInfoDeleteResponseModel()
    this._ChartInfoGetRequestSenderModel = new ChartInfoGetRequestModel(this.sendBuffer)
    this._ChartInfoGetRequestReceiverValue = new ChartInfoGetRequest()
    this._ChartInfoGetRequestReceiverModel = new ChartInfoGetRequestModel()
    this._ChartInfoGetResponseSenderModel = new ChartInfoGetResponseModel(this.sendBuffer)
    this._ChartInfoGetResponseReceiverValue = new ChartInfoGetResponse()
    this._ChartInfoGetResponseReceiverModel = new ChartInfoGetResponseModel()
    this._SymbolDayProfitLossMultipleInfoSubscribeRequestSenderModel = new SymbolDayProfitLossMultipleInfoSubscribeRequestModel(this.sendBuffer)
    this._SymbolDayProfitLossMultipleInfoSubscribeRequestReceiverValue = new SymbolDayProfitLossMultipleInfoSubscribeRequest()
    this._SymbolDayProfitLossMultipleInfoSubscribeRequestReceiverModel = new SymbolDayProfitLossMultipleInfoSubscribeRequestModel()
    this._SymbolDayProfitLossMultipleInfoSubscribeResponseSenderModel = new SymbolDayProfitLossMultipleInfoSubscribeResponseModel(this.sendBuffer)
    this._SymbolDayProfitLossMultipleInfoSubscribeResponseReceiverValue = new SymbolDayProfitLossMultipleInfoSubscribeResponse()
    this._SymbolDayProfitLossMultipleInfoSubscribeResponseReceiverModel = new SymbolDayProfitLossMultipleInfoSubscribeResponseModel()
    this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel = new SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel(this.sendBuffer)
    this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestReceiverValue = new SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest()
    this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestReceiverModel = new SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestSenderModel = new SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel(this.sendBuffer)
    this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestReceiverValue = new SymbolDayProfitLossMultipleInfoUnsubscribeRequest()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestReceiverModel = new SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseSenderModel = new SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel(this.sendBuffer)
    this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseReceiverValue = new SymbolDayProfitLossMultipleInfoUnsubscribeResponse()
    this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseReceiverModel = new SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel()
    this._SymbolDayProfitLossMultipleInfoNotifySenderModel = new SymbolDayProfitLossMultipleInfoNotifyModel(this.sendBuffer)
    this._SymbolDayProfitLossMultipleInfoNotifyReceiverValue = new SymbolDayProfitLossMultipleInfoNotify()
    this._SymbolDayProfitLossMultipleInfoNotifyReceiverModel = new SymbolDayProfitLossMultipleInfoNotifyModel()
    this._NewsServiceGetAllRequestSenderModel = new NewsServiceGetAllRequestModel(this.sendBuffer)
    this._NewsServiceGetAllRequestReceiverValue = new NewsServiceGetAllRequest()
    this._NewsServiceGetAllRequestReceiverModel = new NewsServiceGetAllRequestModel()
    this._NewsServiceGetAllResponseSenderModel = new NewsServiceGetAllResponseModel(this.sendBuffer)
    this._NewsServiceGetAllResponseReceiverValue = new NewsServiceGetAllResponse()
    this._NewsServiceGetAllResponseReceiverModel = new NewsServiceGetAllResponseModel()
    this._NewsRecentArticlesGetRequestSenderModel = new NewsRecentArticlesGetRequestModel(this.sendBuffer)
    this._NewsRecentArticlesGetRequestReceiverValue = new NewsRecentArticlesGetRequest()
    this._NewsRecentArticlesGetRequestReceiverModel = new NewsRecentArticlesGetRequestModel()
    this._NewsRecentArticlesGetResponseSenderModel = new NewsRecentArticlesGetResponseModel(this.sendBuffer)
    this._NewsRecentArticlesGetResponseReceiverValue = new NewsRecentArticlesGetResponse()
    this._NewsRecentArticlesGetResponseReceiverModel = new NewsRecentArticlesGetResponseModel()
    this._NewsArticleGetRequestSenderModel = new NewsArticleGetRequestModel(this.sendBuffer)
    this._NewsArticleGetRequestReceiverValue = new NewsArticleGetRequest()
    this._NewsArticleGetRequestReceiverModel = new NewsArticleGetRequestModel()
    this._NewsArticleGetResponseSenderModel = new NewsArticleGetResponseModel(this.sendBuffer)
    this._NewsArticleGetResponseReceiverValue = new NewsArticleGetResponse()
    this._NewsArticleGetResponseReceiverModel = new NewsArticleGetResponseModel()
    this._OrderUpdateWithTypeRequestSenderModel = new OrderUpdateWithTypeRequestModel(this.sendBuffer)
    this._OrderUpdateWithTypeRequestReceiverValue = new OrderUpdateWithTypeRequest()
    this._OrderUpdateWithTypeRequestReceiverModel = new OrderUpdateWithTypeRequestModel()
    this._OrderUpdateWithTypeResponseSenderModel = new OrderUpdateWithTypeResponseModel(this.sendBuffer)
    this._OrderUpdateWithTypeResponseReceiverValue = new OrderUpdateWithTypeResponse()
    this._OrderUpdateWithTypeResponseReceiverModel = new OrderUpdateWithTypeResponseModel()
    this._SymbolProfitLossMultipleInfoSubscribeRequestSenderModel = new SymbolProfitLossMultipleInfoSubscribeRequestModel(this.sendBuffer)
    this._SymbolProfitLossMultipleInfoSubscribeRequestReceiverValue = new SymbolProfitLossMultipleInfoSubscribeRequest()
    this._SymbolProfitLossMultipleInfoSubscribeRequestReceiverModel = new SymbolProfitLossMultipleInfoSubscribeRequestModel()
    this._SymbolProfitLossMultipleInfoSubscribeResponseSenderModel = new SymbolProfitLossMultipleInfoSubscribeResponseModel(this.sendBuffer)
    this._SymbolProfitLossMultipleInfoSubscribeResponseReceiverValue = new SymbolProfitLossMultipleInfoSubscribeResponse()
    this._SymbolProfitLossMultipleInfoSubscribeResponseReceiverModel = new SymbolProfitLossMultipleInfoSubscribeResponseModel()
    this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel = new SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel(this.sendBuffer)
    this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestReceiverValue = new SymbolProfitLossMultipleInfoUpdateSubscriptionRequest()
    this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestReceiverModel = new SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel()
    this._SymbolProfitLossMultipleInfoUnsubscribeRequestSenderModel = new SymbolProfitLossMultipleInfoUnsubscribeRequestModel(this.sendBuffer)
    this._SymbolProfitLossMultipleInfoUnsubscribeRequestReceiverValue = new SymbolProfitLossMultipleInfoUnsubscribeRequest()
    this._SymbolProfitLossMultipleInfoUnsubscribeRequestReceiverModel = new SymbolProfitLossMultipleInfoUnsubscribeRequestModel()
    this._SymbolProfitLossMultipleInfoUnsubscribeResponseSenderModel = new SymbolProfitLossMultipleInfoUnsubscribeResponseModel(this.sendBuffer)
    this._SymbolProfitLossMultipleInfoUnsubscribeResponseReceiverValue = new SymbolProfitLossMultipleInfoUnsubscribeResponse()
    this._SymbolProfitLossMultipleInfoUnsubscribeResponseReceiverModel = new SymbolProfitLossMultipleInfoUnsubscribeResponseModel()
    this._SymbolProfitLossMultipleInfoNotifySenderModel = new SymbolProfitLossMultipleInfoNotifyModel(this.sendBuffer)
    this._SymbolProfitLossMultipleInfoNotifyReceiverValue = new SymbolProfitLossMultipleInfoNotify()
    this._SymbolProfitLossMultipleInfoNotifyReceiverModel = new SymbolProfitLossMultipleInfoNotifyModel()
    this._LoginWithPasswordRequestSenderModel = new LoginWithPasswordRequestModel(this.sendBuffer)
    this._LoginWithPasswordRequestReceiverValue = new LoginWithPasswordRequest()
    this._LoginWithPasswordRequestReceiverModel = new LoginWithPasswordRequestModel()
    this._LoginWithPasswordResponseSenderModel = new LoginWithPasswordResponseModel(this.sendBuffer)
    this._LoginWithPasswordResponseReceiverValue = new LoginWithPasswordResponse()
    this._LoginWithPasswordResponseReceiverModel = new LoginWithPasswordResponseModel()
    this._LoginWithTokenRequestSenderModel = new LoginWithTokenRequestModel(this.sendBuffer)
    this._LoginWithTokenRequestReceiverValue = new LoginWithTokenRequest()
    this._LoginWithTokenRequestReceiverModel = new LoginWithTokenRequestModel()
    this._LoginWithTokenResponseSenderModel = new LoginWithTokenResponseModel(this.sendBuffer)
    this._LoginWithTokenResponseReceiverValue = new LoginWithTokenResponse()
    this._LoginWithTokenResponseReceiverModel = new LoginWithTokenResponseModel()
    this._RefreshTokenRequestSenderModel = new RefreshTokenRequestModel(this.sendBuffer)
    this._RefreshTokenRequestReceiverValue = new RefreshTokenRequest()
    this._RefreshTokenRequestReceiverModel = new RefreshTokenRequestModel()
    this._RefreshTokenResponseSenderModel = new RefreshTokenResponseModel(this.sendBuffer)
    this._RefreshTokenResponseReceiverValue = new RefreshTokenResponse()
    this._RefreshTokenResponseReceiverModel = new RefreshTokenResponseModel()
    this._Logout2RequestSenderModel = new Logout2RequestModel(this.sendBuffer)
    this._Logout2RequestReceiverValue = new Logout2Request()
    this._Logout2RequestReceiverModel = new Logout2RequestModel()
    this._Logout2ResponseSenderModel = new Logout2ResponseModel(this.sendBuffer)
    this._Logout2ResponseReceiverValue = new Logout2Response()
    this._Logout2ResponseReceiverModel = new Logout2ResponseModel()
    this._AccountChangePasswordRequestSenderModel = new AccountChangePasswordRequestModel(this.sendBuffer)
    this._AccountChangePasswordRequestReceiverValue = new AccountChangePasswordRequest()
    this._AccountChangePasswordRequestReceiverModel = new AccountChangePasswordRequestModel()
    this._AccountChangePasswordResponseSenderModel = new AccountChangePasswordResponseModel(this.sendBuffer)
    this._AccountChangePasswordResponseReceiverValue = new AccountChangePasswordResponse()
    this._AccountChangePasswordResponseReceiverModel = new AccountChangePasswordResponseModel()
    this._PushNotificationTokenRegisterRequestSenderModel = new PushNotificationTokenRegisterRequestModel(this.sendBuffer)
    this._PushNotificationTokenRegisterRequestReceiverValue = new PushNotificationTokenRegisterRequest()
    this._PushNotificationTokenRegisterRequestReceiverModel = new PushNotificationTokenRegisterRequestModel()
    this._PushNotificationTokenRegisterResponseSenderModel = new PushNotificationTokenRegisterResponseModel(this.sendBuffer)
    this._PushNotificationTokenRegisterResponseReceiverValue = new PushNotificationTokenRegisterResponse()
    this._PushNotificationTokenRegisterResponseReceiverModel = new PushNotificationTokenRegisterResponseModel()
    this._DesiredAppSettingsGetRequestSenderModel = new DesiredAppSettingsGetRequestModel(this.sendBuffer)
    this._DesiredAppSettingsGetRequestReceiverValue = new DesiredAppSettingsGetRequest()
    this._DesiredAppSettingsGetRequestReceiverModel = new DesiredAppSettingsGetRequestModel()
    this._DesiredAppSettingsGetResponseSenderModel = new DesiredAppSettingsGetResponseModel(this.sendBuffer)
    this._DesiredAppSettingsGetResponseReceiverValue = new DesiredAppSettingsGetResponse()
    this._DesiredAppSettingsGetResponseReceiverModel = new DesiredAppSettingsGetResponseModel()
    this._AuthTokensGetAllRequestSenderModel = new AuthTokensGetAllRequestModel(this.sendBuffer)
    this._AuthTokensGetAllRequestReceiverValue = new AuthTokensGetAllRequest()
    this._AuthTokensGetAllRequestReceiverModel = new AuthTokensGetAllRequestModel()
    this._AuthTokensGetAllResponseSenderModel = new AuthTokensGetAllResponseModel(this.sendBuffer)
    this._AuthTokensGetAllResponseReceiverValue = new AuthTokensGetAllResponse()
    this._AuthTokensGetAllResponseReceiverModel = new AuthTokensGetAllResponseModel()
    this._AuthTokensDeleteRequestSenderModel = new AuthTokensDeleteRequestModel(this.sendBuffer)
    this._AuthTokensDeleteRequestReceiverValue = new AuthTokensDeleteRequest()
    this._AuthTokensDeleteRequestReceiverModel = new AuthTokensDeleteRequestModel()
    this._AuthTokensDeleteResponseSenderModel = new AuthTokensDeleteResponseModel(this.sendBuffer)
    this._AuthTokensDeleteResponseReceiverValue = new AuthTokensDeleteResponse()
    this._AuthTokensDeleteResponseReceiverModel = new AuthTokensDeleteResponseModel()
    this._LegalDocumentGroupListRequestSenderModel = new LegalDocumentGroupListRequestModel(this.sendBuffer)
    this._LegalDocumentGroupListRequestReceiverValue = new LegalDocumentGroupListRequest()
    this._LegalDocumentGroupListRequestReceiverModel = new LegalDocumentGroupListRequestModel()
    this._LegalDocumentGroupListResponseSenderModel = new LegalDocumentGroupListResponseModel(this.sendBuffer)
    this._LegalDocumentGroupListResponseReceiverValue = new LegalDocumentGroupListResponse()
    this._LegalDocumentGroupListResponseReceiverModel = new LegalDocumentGroupListResponseModel()
    this._LegalDocumentGetRequestSenderModel = new LegalDocumentGetRequestModel(this.sendBuffer)
    this._LegalDocumentGetRequestReceiverValue = new LegalDocumentGetRequest()
    this._LegalDocumentGetRequestReceiverModel = new LegalDocumentGetRequestModel()
    this._LegalDocumentGetResponseSenderModel = new LegalDocumentGetResponseModel(this.sendBuffer)
    this._LegalDocumentGetResponseReceiverValue = new LegalDocumentGetResponse()
    this._LegalDocumentGetResponseReceiverModel = new LegalDocumentGetResponseModel()
    this._PushNotificationTokenUnRegisterRequestSenderModel = new PushNotificationTokenUnRegisterRequestModel(this.sendBuffer)
    this._PushNotificationTokenUnRegisterRequestReceiverValue = new PushNotificationTokenUnRegisterRequest()
    this._PushNotificationTokenUnRegisterRequestReceiverModel = new PushNotificationTokenUnRegisterRequestModel()
    this._PushNotificationTokenUnRegisterResponseSenderModel = new PushNotificationTokenUnRegisterResponseModel(this.sendBuffer)
    this._PushNotificationTokenUnRegisterResponseReceiverValue = new PushNotificationTokenUnRegisterResponse()
    this._PushNotificationTokenUnRegisterResponseReceiverModel = new PushNotificationTokenUnRegisterResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  /**
   * Get imported exposed client
   * @this {!Client}
   * @returns {!exposed.Client} exposed client
   */
  get exposedClient () {
    return this._exposedClient
  }

  // Sender models accessors

  /**
   * Get PingRequest model
   * @this {!Client}
   * @returns {!PingRequestModel} PingRequest sender model
   */
  get PingRequestSenderModel () {
    return this._PingRequestSenderModel
  }

  /**
   * Get PongResponse model
   * @this {!Client}
   * @returns {!PongResponseModel} PongResponse sender model
   */
  get PongResponseSenderModel () {
    return this._PongResponseSenderModel
  }

  /**
   * Get LoginRequest model
   * @this {!Client}
   * @returns {!LoginRequestModel} LoginRequest sender model
   */
  get LoginRequestSenderModel () {
    return this._LoginRequestSenderModel
  }

  /**
   * Get LoginResponse model
   * @this {!Client}
   * @returns {!LoginResponseModel} LoginResponse sender model
   */
  get LoginResponseSenderModel () {
    return this._LoginResponseSenderModel
  }

  /**
   * Get LoginReject model
   * @this {!Client}
   * @returns {!LoginRejectModel} LoginReject sender model
   */
  get LoginRejectSenderModel () {
    return this._LoginRejectSenderModel
  }

  /**
   * Get LogoutRequest model
   * @this {!Client}
   * @returns {!LogoutRequestModel} LogoutRequest sender model
   */
  get LogoutRequestSenderModel () {
    return this._LogoutRequestSenderModel
  }

  /**
   * Get LogoutResponse model
   * @this {!Client}
   * @returns {!LogoutResponseModel} LogoutResponse sender model
   */
  get LogoutResponseSenderModel () {
    return this._LogoutResponseSenderModel
  }

  /**
   * Get LogoutNotify model
   * @this {!Client}
   * @returns {!LogoutNotifyModel} LogoutNotify sender model
   */
  get LogoutNotifySenderModel () {
    return this._LogoutNotifySenderModel
  }

  /**
   * Get SessionInfoRequest model
   * @this {!Client}
   * @returns {!SessionInfoRequestModel} SessionInfoRequest sender model
   */
  get SessionInfoRequestSenderModel () {
    return this._SessionInfoRequestSenderModel
  }

  /**
   * Get SessionInfoResponse model
   * @this {!Client}
   * @returns {!SessionInfoResponseModel} SessionInfoResponse sender model
   */
  get SessionInfoResponseSenderModel () {
    return this._SessionInfoResponseSenderModel
  }

  /**
   * Get TimeInfoRequest model
   * @this {!Client}
   * @returns {!TimeInfoRequestModel} TimeInfoRequest sender model
   */
  get TimeInfoRequestSenderModel () {
    return this._TimeInfoRequestSenderModel
  }

  /**
   * Get TimeInfoResponse model
   * @this {!Client}
   * @returns {!TimeInfoResponseModel} TimeInfoResponse sender model
   */
  get TimeInfoResponseSenderModel () {
    return this._TimeInfoResponseSenderModel
  }

  /**
   * Get TimezoneInfoRequest model
   * @this {!Client}
   * @returns {!TimezoneInfoRequestModel} TimezoneInfoRequest sender model
   */
  get TimezoneInfoRequestSenderModel () {
    return this._TimezoneInfoRequestSenderModel
  }

  /**
   * Get TimezoneInfoResponse model
   * @this {!Client}
   * @returns {!TimezoneInfoResponseModel} TimezoneInfoResponse sender model
   */
  get TimezoneInfoResponseSenderModel () {
    return this._TimezoneInfoResponseSenderModel
  }

  /**
   * Get TimezoneInfoUpdateNotify model
   * @this {!Client}
   * @returns {!TimezoneInfoUpdateNotifyModel} TimezoneInfoUpdateNotify sender model
   */
  get TimezoneInfoUpdateNotifySenderModel () {
    return this._TimezoneInfoUpdateNotifySenderModel
  }

  /**
   * Get CurrencyInfoRequest model
   * @this {!Client}
   * @returns {!CurrencyInfoRequestModel} CurrencyInfoRequest sender model
   */
  get CurrencyInfoRequestSenderModel () {
    return this._CurrencyInfoRequestSenderModel
  }

  /**
   * Get CurrencyInfoResponse model
   * @this {!Client}
   * @returns {!CurrencyInfoResponseModel} CurrencyInfoResponse sender model
   */
  get CurrencyInfoResponseSenderModel () {
    return this._CurrencyInfoResponseSenderModel
  }

  /**
   * Get CurrencyListRequest model
   * @this {!Client}
   * @returns {!CurrencyListRequestModel} CurrencyListRequest sender model
   */
  get CurrencyListRequestSenderModel () {
    return this._CurrencyListRequestSenderModel
  }

  /**
   * Get CurrencyListResponse model
   * @this {!Client}
   * @returns {!CurrencyListResponseModel} CurrencyListResponse sender model
   */
  get CurrencyListResponseSenderModel () {
    return this._CurrencyListResponseSenderModel
  }

  /**
   * Get CurrencyInfoUpdateNotify model
   * @this {!Client}
   * @returns {!CurrencyInfoUpdateNotifyModel} CurrencyInfoUpdateNotify sender model
   */
  get CurrencyInfoUpdateNotifySenderModel () {
    return this._CurrencyInfoUpdateNotifySenderModel
  }

  /**
   * Get SymbolInfoRequest model
   * @this {!Client}
   * @returns {!SymbolInfoRequestModel} SymbolInfoRequest sender model
   */
  get SymbolInfoRequestSenderModel () {
    return this._SymbolInfoRequestSenderModel
  }

  /**
   * Get SymbolInfoResponse model
   * @this {!Client}
   * @returns {!SymbolInfoResponseModel} SymbolInfoResponse sender model
   */
  get SymbolInfoResponseSenderModel () {
    return this._SymbolInfoResponseSenderModel
  }

  /**
   * Get SymbolListRequest model
   * @this {!Client}
   * @returns {!SymbolListRequestModel} SymbolListRequest sender model
   */
  get SymbolListRequestSenderModel () {
    return this._SymbolListRequestSenderModel
  }

  /**
   * Get SymbolListResponse model
   * @this {!Client}
   * @returns {!SymbolListResponseModel} SymbolListResponse sender model
   */
  get SymbolListResponseSenderModel () {
    return this._SymbolListResponseSenderModel
  }

  /**
   * Get SymbolInfoUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolInfoUpdateNotifyModel} SymbolInfoUpdateNotify sender model
   */
  get SymbolInfoUpdateNotifySenderModel () {
    return this._SymbolInfoUpdateNotifySenderModel
  }

  /**
   * Get TickInfoRequest model
   * @this {!Client}
   * @returns {!TickInfoRequestModel} TickInfoRequest sender model
   */
  get TickInfoRequestSenderModel () {
    return this._TickInfoRequestSenderModel
  }

  /**
   * Get TickInfoResponse model
   * @this {!Client}
   * @returns {!TickInfoResponseModel} TickInfoResponse sender model
   */
  get TickInfoResponseSenderModel () {
    return this._TickInfoResponseSenderModel
  }

  /**
   * Get TickFilterRequest model
   * @this {!Client}
   * @returns {!TickFilterRequestModel} TickFilterRequest sender model
   */
  get TickFilterRequestSenderModel () {
    return this._TickFilterRequestSenderModel
  }

  /**
   * Get TickFilterResponse model
   * @this {!Client}
   * @returns {!TickFilterResponseModel} TickFilterResponse sender model
   */
  get TickFilterResponseSenderModel () {
    return this._TickFilterResponseSenderModel
  }

  /**
   * Get TickListRequest model
   * @this {!Client}
   * @returns {!TickListRequestModel} TickListRequest sender model
   */
  get TickListRequestSenderModel () {
    return this._TickListRequestSenderModel
  }

  /**
   * Get TickListResponse model
   * @this {!Client}
   * @returns {!TickListResponseModel} TickListResponse sender model
   */
  get TickListResponseSenderModel () {
    return this._TickListResponseSenderModel
  }

  /**
   * Get Level2InfoRequest model
   * @this {!Client}
   * @returns {!Level2InfoRequestModel} Level2InfoRequest sender model
   */
  get Level2InfoRequestSenderModel () {
    return this._Level2InfoRequestSenderModel
  }

  /**
   * Get Level2InfoResponse model
   * @this {!Client}
   * @returns {!Level2InfoResponseModel} Level2InfoResponse sender model
   */
  get Level2InfoResponseSenderModel () {
    return this._Level2InfoResponseSenderModel
  }

  /**
   * Get Level2FilterRequest model
   * @this {!Client}
   * @returns {!Level2FilterRequestModel} Level2FilterRequest sender model
   */
  get Level2FilterRequestSenderModel () {
    return this._Level2FilterRequestSenderModel
  }

  /**
   * Get Level2FilterResponse model
   * @this {!Client}
   * @returns {!Level2FilterResponseModel} Level2FilterResponse sender model
   */
  get Level2FilterResponseSenderModel () {
    return this._Level2FilterResponseSenderModel
  }

  /**
   * Get Level2ListRequest model
   * @this {!Client}
   * @returns {!Level2ListRequestModel} Level2ListRequest sender model
   */
  get Level2ListRequestSenderModel () {
    return this._Level2ListRequestSenderModel
  }

  /**
   * Get Level2ListResponse model
   * @this {!Client}
   * @returns {!Level2ListResponseModel} Level2ListResponse sender model
   */
  get Level2ListResponseSenderModel () {
    return this._Level2ListResponseSenderModel
  }

  /**
   * Get FeedSubscriptionInfoRequest model
   * @this {!Client}
   * @returns {!FeedSubscriptionInfoRequestModel} FeedSubscriptionInfoRequest sender model
   */
  get FeedSubscriptionInfoRequestSenderModel () {
    return this._FeedSubscriptionInfoRequestSenderModel
  }

  /**
   * Get FeedSubscriptionInfoResponse model
   * @this {!Client}
   * @returns {!FeedSubscriptionInfoResponseModel} FeedSubscriptionInfoResponse sender model
   */
  get FeedSubscriptionInfoResponseSenderModel () {
    return this._FeedSubscriptionInfoResponseSenderModel
  }

  /**
   * Get FeedSubscribeRequest model
   * @this {!Client}
   * @returns {!FeedSubscribeRequestModel} FeedSubscribeRequest sender model
   */
  get FeedSubscribeRequestSenderModel () {
    return this._FeedSubscribeRequestSenderModel
  }

  /**
   * Get FeedSubscribeAllRequest model
   * @this {!Client}
   * @returns {!FeedSubscribeAllRequestModel} FeedSubscribeAllRequest sender model
   */
  get FeedSubscribeAllRequestSenderModel () {
    return this._FeedSubscribeAllRequestSenderModel
  }

  /**
   * Get FeedSubscribeResponse model
   * @this {!Client}
   * @returns {!FeedSubscribeResponseModel} FeedSubscribeResponse sender model
   */
  get FeedSubscribeResponseSenderModel () {
    return this._FeedSubscribeResponseSenderModel
  }

  /**
   * Get FeedUnsubscribeRequest model
   * @this {!Client}
   * @returns {!FeedUnsubscribeRequestModel} FeedUnsubscribeRequest sender model
   */
  get FeedUnsubscribeRequestSenderModel () {
    return this._FeedUnsubscribeRequestSenderModel
  }

  /**
   * Get FeedUnsubscribeAllRequest model
   * @this {!Client}
   * @returns {!FeedUnsubscribeAllRequestModel} FeedUnsubscribeAllRequest sender model
   */
  get FeedUnsubscribeAllRequestSenderModel () {
    return this._FeedUnsubscribeAllRequestSenderModel
  }

  /**
   * Get FeedUnsubscribeResponse model
   * @this {!Client}
   * @returns {!FeedUnsubscribeResponseModel} FeedUnsubscribeResponse sender model
   */
  get FeedUnsubscribeResponseSenderModel () {
    return this._FeedUnsubscribeResponseSenderModel
  }

  /**
   * Get TickSnapshotNotify model
   * @this {!Client}
   * @returns {!TickSnapshotNotifyModel} TickSnapshotNotify sender model
   */
  get TickSnapshotNotifySenderModel () {
    return this._TickSnapshotNotifySenderModel
  }

  /**
   * Get TickUpdateNotify model
   * @this {!Client}
   * @returns {!TickUpdateNotifyModel} TickUpdateNotify sender model
   */
  get TickUpdateNotifySenderModel () {
    return this._TickUpdateNotifySenderModel
  }

  /**
   * Get Level2SnapshotNotify model
   * @this {!Client}
   * @returns {!Level2SnapshotNotifyModel} Level2SnapshotNotify sender model
   */
  get Level2SnapshotNotifySenderModel () {
    return this._Level2SnapshotNotifySenderModel
  }

  /**
   * Get Level2UpdateNotify model
   * @this {!Client}
   * @returns {!Level2UpdateNotifyModel} Level2UpdateNotify sender model
   */
  get Level2UpdateNotifySenderModel () {
    return this._Level2UpdateNotifySenderModel
  }

  /**
   * Get ChartBarHistoryRequest model
   * @this {!Client}
   * @returns {!ChartBarHistoryRequestModel} ChartBarHistoryRequest sender model
   */
  get ChartBarHistoryRequestSenderModel () {
    return this._ChartBarHistoryRequestSenderModel
  }

  /**
   * Get ChartBarHistoryResponse model
   * @this {!Client}
   * @returns {!ChartBarHistoryResponseModel} ChartBarHistoryResponse sender model
   */
  get ChartBarHistoryResponseSenderModel () {
    return this._ChartBarHistoryResponseSenderModel
  }

  /**
   * Get ChartBarSubscribeRequest model
   * @this {!Client}
   * @returns {!ChartBarSubscribeRequestModel} ChartBarSubscribeRequest sender model
   */
  get ChartBarSubscribeRequestSenderModel () {
    return this._ChartBarSubscribeRequestSenderModel
  }

  /**
   * Get ChartBarSubscribeResponse model
   * @this {!Client}
   * @returns {!ChartBarSubscribeResponseModel} ChartBarSubscribeResponse sender model
   */
  get ChartBarSubscribeResponseSenderModel () {
    return this._ChartBarSubscribeResponseSenderModel
  }

  /**
   * Get ChartBarUnsubscribeRequest model
   * @this {!Client}
   * @returns {!ChartBarUnsubscribeRequestModel} ChartBarUnsubscribeRequest sender model
   */
  get ChartBarUnsubscribeRequestSenderModel () {
    return this._ChartBarUnsubscribeRequestSenderModel
  }

  /**
   * Get ChartBarUnsubscribeResponse model
   * @this {!Client}
   * @returns {!ChartBarUnsubscribeResponseModel} ChartBarUnsubscribeResponse sender model
   */
  get ChartBarUnsubscribeResponseSenderModel () {
    return this._ChartBarUnsubscribeResponseSenderModel
  }

  /**
   * Get ChartBarNotify model
   * @this {!Client}
   * @returns {!ChartBarNotifyModel} ChartBarNotify sender model
   */
  get ChartBarNotifySenderModel () {
    return this._ChartBarNotifySenderModel
  }

  /**
   * Get ChartBarUpdateNotify model
   * @this {!Client}
   * @returns {!ChartBarUpdateNotifyModel} ChartBarUpdateNotify sender model
   */
  get ChartBarUpdateNotifySenderModel () {
    return this._ChartBarUpdateNotifySenderModel
  }

  /**
   * Get PlatformInfoRequest model
   * @this {!Client}
   * @returns {!PlatformInfoRequestModel} PlatformInfoRequest sender model
   */
  get PlatformInfoRequestSenderModel () {
    return this._PlatformInfoRequestSenderModel
  }

  /**
   * Get PlatformInfoResponse model
   * @this {!Client}
   * @returns {!PlatformInfoResponseModel} PlatformInfoResponse sender model
   */
  get PlatformInfoResponseSenderModel () {
    return this._PlatformInfoResponseSenderModel
  }

  /**
   * Get PlatformInfoUpdateNotify model
   * @this {!Client}
   * @returns {!PlatformInfoUpdateNotifyModel} PlatformInfoUpdateNotify sender model
   */
  get PlatformInfoUpdateNotifySenderModel () {
    return this._PlatformInfoUpdateNotifySenderModel
  }

  /**
   * Get AccountInfoRequest model
   * @this {!Client}
   * @returns {!AccountInfoRequestModel} AccountInfoRequest sender model
   */
  get AccountInfoRequestSenderModel () {
    return this._AccountInfoRequestSenderModel
  }

  /**
   * Get AccountInfoResponse model
   * @this {!Client}
   * @returns {!AccountInfoResponseModel} AccountInfoResponse sender model
   */
  get AccountInfoResponseSenderModel () {
    return this._AccountInfoResponseSenderModel
  }

  /**
   * Get AccountInfoUpdateNotify model
   * @this {!Client}
   * @returns {!AccountInfoUpdateNotifyModel} AccountInfoUpdateNotify sender model
   */
  get AccountInfoUpdateNotifySenderModel () {
    return this._AccountInfoUpdateNotifySenderModel
  }

  /**
   * Get MarginInfoRequest model
   * @this {!Client}
   * @returns {!MarginInfoRequestModel} MarginInfoRequest sender model
   */
  get MarginInfoRequestSenderModel () {
    return this._MarginInfoRequestSenderModel
  }

  /**
   * Get MarginInfoResponse model
   * @this {!Client}
   * @returns {!MarginInfoResponseModel} MarginInfoResponse sender model
   */
  get MarginInfoResponseSenderModel () {
    return this._MarginInfoResponseSenderModel
  }

  /**
   * Get MarginInfoUpdateNotify model
   * @this {!Client}
   * @returns {!MarginInfoUpdateNotifyModel} MarginInfoUpdateNotify sender model
   */
  get MarginInfoUpdateNotifySenderModel () {
    return this._MarginInfoUpdateNotifySenderModel
  }

  /**
   * Get MarginCallNotify model
   * @this {!Client}
   * @returns {!MarginCallNotifyModel} MarginCallNotify sender model
   */
  get MarginCallNotifySenderModel () {
    return this._MarginCallNotifySenderModel
  }

  /**
   * Get StopOutNotify model
   * @this {!Client}
   * @returns {!StopOutNotifyModel} StopOutNotify sender model
   */
  get StopOutNotifySenderModel () {
    return this._StopOutNotifySenderModel
  }

  /**
   * Get AssetInfoRequest model
   * @this {!Client}
   * @returns {!AssetInfoRequestModel} AssetInfoRequest sender model
   */
  get AssetInfoRequestSenderModel () {
    return this._AssetInfoRequestSenderModel
  }

  /**
   * Get AssetInfoResponse model
   * @this {!Client}
   * @returns {!AssetInfoResponseModel} AssetInfoResponse sender model
   */
  get AssetInfoResponseSenderModel () {
    return this._AssetInfoResponseSenderModel
  }

  /**
   * Get AssetListRequest model
   * @this {!Client}
   * @returns {!AssetListRequestModel} AssetListRequest sender model
   */
  get AssetListRequestSenderModel () {
    return this._AssetListRequestSenderModel
  }

  /**
   * Get AssetListResponse model
   * @this {!Client}
   * @returns {!AssetListResponseModel} AssetListResponse sender model
   */
  get AssetListResponseSenderModel () {
    return this._AssetListResponseSenderModel
  }

  /**
   * Get AssetInfoUpdateNotify model
   * @this {!Client}
   * @returns {!AssetInfoUpdateNotifyModel} AssetInfoUpdateNotify sender model
   */
  get AssetInfoUpdateNotifySenderModel () {
    return this._AssetInfoUpdateNotifySenderModel
  }

  /**
   * Get OrderInfoRequest model
   * @this {!Client}
   * @returns {!OrderInfoRequestModel} OrderInfoRequest sender model
   */
  get OrderInfoRequestSenderModel () {
    return this._OrderInfoRequestSenderModel
  }

  /**
   * Get OrderInfoResponse model
   * @this {!Client}
   * @returns {!OrderInfoResponseModel} OrderInfoResponse sender model
   */
  get OrderInfoResponseSenderModel () {
    return this._OrderInfoResponseSenderModel
  }

  /**
   * Get OrderListRequest model
   * @this {!Client}
   * @returns {!OrderListRequestModel} OrderListRequest sender model
   */
  get OrderListRequestSenderModel () {
    return this._OrderListRequestSenderModel
  }

  /**
   * Get OrderListResponse model
   * @this {!Client}
   * @returns {!OrderListResponseModel} OrderListResponse sender model
   */
  get OrderListResponseSenderModel () {
    return this._OrderListResponseSenderModel
  }

  /**
   * Get OrderOpenRequest model
   * @this {!Client}
   * @returns {!OrderOpenRequestModel} OrderOpenRequest sender model
   */
  get OrderOpenRequestSenderModel () {
    return this._OrderOpenRequestSenderModel
  }

  /**
   * Get OrderOpenResponse model
   * @this {!Client}
   * @returns {!OrderOpenResponseModel} OrderOpenResponse sender model
   */
  get OrderOpenResponseSenderModel () {
    return this._OrderOpenResponseSenderModel
  }

  /**
   * Get OrderUpdateRequest model
   * @this {!Client}
   * @returns {!OrderUpdateRequestModel} OrderUpdateRequest sender model
   */
  get OrderUpdateRequestSenderModel () {
    return this._OrderUpdateRequestSenderModel
  }

  /**
   * Get OrderUpdateResponse model
   * @this {!Client}
   * @returns {!OrderUpdateResponseModel} OrderUpdateResponse sender model
   */
  get OrderUpdateResponseSenderModel () {
    return this._OrderUpdateResponseSenderModel
  }

  /**
   * Get OrderCancelRequest model
   * @this {!Client}
   * @returns {!OrderCancelRequestModel} OrderCancelRequest sender model
   */
  get OrderCancelRequestSenderModel () {
    return this._OrderCancelRequestSenderModel
  }

  /**
   * Get OrderCancelResponse model
   * @this {!Client}
   * @returns {!OrderCancelResponseModel} OrderCancelResponse sender model
   */
  get OrderCancelResponseSenderModel () {
    return this._OrderCancelResponseSenderModel
  }

  /**
   * Get OrderCancelAllRequest model
   * @this {!Client}
   * @returns {!OrderCancelAllRequestModel} OrderCancelAllRequest sender model
   */
  get OrderCancelAllRequestSenderModel () {
    return this._OrderCancelAllRequestSenderModel
  }

  /**
   * Get OrderCancelAllResponse model
   * @this {!Client}
   * @returns {!OrderCancelAllResponseModel} OrderCancelAllResponse sender model
   */
  get OrderCancelAllResponseSenderModel () {
    return this._OrderCancelAllResponseSenderModel
  }

  /**
   * Get OrderCancelAllByIdRequest model
   * @this {!Client}
   * @returns {!OrderCancelAllByIdRequestModel} OrderCancelAllByIdRequest sender model
   */
  get OrderCancelAllByIdRequestSenderModel () {
    return this._OrderCancelAllByIdRequestSenderModel
  }

  /**
   * Get OrderCloseRequest model
   * @this {!Client}
   * @returns {!OrderCloseRequestModel} OrderCloseRequest sender model
   */
  get OrderCloseRequestSenderModel () {
    return this._OrderCloseRequestSenderModel
  }

  /**
   * Get OrderCloseResponse model
   * @this {!Client}
   * @returns {!OrderCloseResponseModel} OrderCloseResponse sender model
   */
  get OrderCloseResponseSenderModel () {
    return this._OrderCloseResponseSenderModel
  }

  /**
   * Get OrderCloseAllRequest model
   * @this {!Client}
   * @returns {!OrderCloseAllRequestModel} OrderCloseAllRequest sender model
   */
  get OrderCloseAllRequestSenderModel () {
    return this._OrderCloseAllRequestSenderModel
  }

  /**
   * Get OrderCloseAllResponse model
   * @this {!Client}
   * @returns {!OrderCloseAllResponseModel} OrderCloseAllResponse sender model
   */
  get OrderCloseAllResponseSenderModel () {
    return this._OrderCloseAllResponseSenderModel
  }

  /**
   * Get OrderCloseAllByIdRequest model
   * @this {!Client}
   * @returns {!OrderCloseAllByIdRequestModel} OrderCloseAllByIdRequest sender model
   */
  get OrderCloseAllByIdRequestSenderModel () {
    return this._OrderCloseAllByIdRequestSenderModel
  }

  /**
   * Get OrderCloseByRequest model
   * @this {!Client}
   * @returns {!OrderCloseByRequestModel} OrderCloseByRequest sender model
   */
  get OrderCloseByRequestSenderModel () {
    return this._OrderCloseByRequestSenderModel
  }

  /**
   * Get OrderCloseByResponse model
   * @this {!Client}
   * @returns {!OrderCloseByResponseModel} OrderCloseByResponse sender model
   */
  get OrderCloseByResponseSenderModel () {
    return this._OrderCloseByResponseSenderModel
  }

  /**
   * Get OrderCloseByAllRequest model
   * @this {!Client}
   * @returns {!OrderCloseByAllRequestModel} OrderCloseByAllRequest sender model
   */
  get OrderCloseByAllRequestSenderModel () {
    return this._OrderCloseByAllRequestSenderModel
  }

  /**
   * Get OrderCloseByAllResponse model
   * @this {!Client}
   * @returns {!OrderCloseByAllResponseModel} OrderCloseByAllResponse sender model
   */
  get OrderCloseByAllResponseSenderModel () {
    return this._OrderCloseByAllResponseSenderModel
  }

  /**
   * Get OrderCancelOrCloseRequest model
   * @this {!Client}
   * @returns {!OrderCancelOrCloseRequestModel} OrderCancelOrCloseRequest sender model
   */
  get OrderCancelOrCloseRequestSenderModel () {
    return this._OrderCancelOrCloseRequestSenderModel
  }

  /**
   * Get OrderCancelOrCloseResponse model
   * @this {!Client}
   * @returns {!OrderCancelOrCloseResponseModel} OrderCancelOrCloseResponse sender model
   */
  get OrderCancelOrCloseResponseSenderModel () {
    return this._OrderCancelOrCloseResponseSenderModel
  }

  /**
   * Get OrderCancelOrCloseAllRequest model
   * @this {!Client}
   * @returns {!OrderCancelOrCloseAllRequestModel} OrderCancelOrCloseAllRequest sender model
   */
  get OrderCancelOrCloseAllRequestSenderModel () {
    return this._OrderCancelOrCloseAllRequestSenderModel
  }

  /**
   * Get OrderCancelOrCloseAllResponse model
   * @this {!Client}
   * @returns {!OrderCancelOrCloseAllResponseModel} OrderCancelOrCloseAllResponse sender model
   */
  get OrderCancelOrCloseAllResponseSenderModel () {
    return this._OrderCancelOrCloseAllResponseSenderModel
  }

  /**
   * Get OrderInfoUpdateNotify model
   * @this {!Client}
   * @returns {!OrderInfoUpdateNotifyModel} OrderInfoUpdateNotify sender model
   */
  get OrderInfoUpdateNotifySenderModel () {
    return this._OrderInfoUpdateNotifySenderModel
  }

  /**
   * Get PositionInfoRequest model
   * @this {!Client}
   * @returns {!PositionInfoRequestModel} PositionInfoRequest sender model
   */
  get PositionInfoRequestSenderModel () {
    return this._PositionInfoRequestSenderModel
  }

  /**
   * Get PositionInfoResponse model
   * @this {!Client}
   * @returns {!PositionInfoResponseModel} PositionInfoResponse sender model
   */
  get PositionInfoResponseSenderModel () {
    return this._PositionInfoResponseSenderModel
  }

  /**
   * Get PositionListRequest model
   * @this {!Client}
   * @returns {!PositionListRequestModel} PositionListRequest sender model
   */
  get PositionListRequestSenderModel () {
    return this._PositionListRequestSenderModel
  }

  /**
   * Get PositionListResponse model
   * @this {!Client}
   * @returns {!PositionListResponseModel} PositionListResponse sender model
   */
  get PositionListResponseSenderModel () {
    return this._PositionListResponseSenderModel
  }

  /**
   * Get PositionInfoUpdateNotify model
   * @this {!Client}
   * @returns {!PositionInfoUpdateNotifyModel} PositionInfoUpdateNotify sender model
   */
  get PositionInfoUpdateNotifySenderModel () {
    return this._PositionInfoUpdateNotifySenderModel
  }

  /**
   * Get TradeHistoryInfoRequest model
   * @this {!Client}
   * @returns {!TradeHistoryInfoRequestModel} TradeHistoryInfoRequest sender model
   */
  get TradeHistoryInfoRequestSenderModel () {
    return this._TradeHistoryInfoRequestSenderModel
  }

  /**
   * Get TradeHistoryInfoResponse model
   * @this {!Client}
   * @returns {!TradeHistoryInfoResponseModel} TradeHistoryInfoResponse sender model
   */
  get TradeHistoryInfoResponseSenderModel () {
    return this._TradeHistoryInfoResponseSenderModel
  }

  /**
   * Get TradeHistoryInfoFilterRequest model
   * @this {!Client}
   * @returns {!TradeHistoryInfoFilterRequestModel} TradeHistoryInfoFilterRequest sender model
   */
  get TradeHistoryInfoFilterRequestSenderModel () {
    return this._TradeHistoryInfoFilterRequestSenderModel
  }

  /**
   * Get TradeHistoryInfoByIdRequest model
   * @this {!Client}
   * @returns {!TradeHistoryInfoByIdRequestModel} TradeHistoryInfoByIdRequest sender model
   */
  get TradeHistoryInfoByIdRequestSenderModel () {
    return this._TradeHistoryInfoByIdRequestSenderModel
  }

  /**
   * Get TradeHistoryInfoByIdResponse model
   * @this {!Client}
   * @returns {!TradeHistoryInfoByIdResponseModel} TradeHistoryInfoByIdResponse sender model
   */
  get TradeHistoryInfoByIdResponseSenderModel () {
    return this._TradeHistoryInfoByIdResponseSenderModel
  }

  /**
   * Get TradeHistoryInfoUpdateNotify model
   * @this {!Client}
   * @returns {!TradeHistoryInfoUpdateNotifyModel} TradeHistoryInfoUpdateNotify sender model
   */
  get TradeHistoryInfoUpdateNotifySenderModel () {
    return this._TradeHistoryInfoUpdateNotifySenderModel
  }

  /**
   * Get CalendarRegularRuleGetRequest model
   * @this {!Client}
   * @returns {!CalendarRegularRuleGetRequestModel} CalendarRegularRuleGetRequest sender model
   */
  get CalendarRegularRuleGetRequestSenderModel () {
    return this._CalendarRegularRuleGetRequestSenderModel
  }

  /**
   * Get CalendarRegularRuleGetResponse model
   * @this {!Client}
   * @returns {!CalendarRegularRuleGetResponseModel} CalendarRegularRuleGetResponse sender model
   */
  get CalendarRegularRuleGetResponseSenderModel () {
    return this._CalendarRegularRuleGetResponseSenderModel
  }

  /**
   * Get CalendarRegularRuleUpdateNotify model
   * @this {!Client}
   * @returns {!CalendarRegularRuleUpdateNotifyModel} CalendarRegularRuleUpdateNotify sender model
   */
  get CalendarRegularRuleUpdateNotifySenderModel () {
    return this._CalendarRegularRuleUpdateNotifySenderModel
  }

  /**
   * Get CalendarOffTimeRuleGetRequest model
   * @this {!Client}
   * @returns {!CalendarOffTimeRuleGetRequestModel} CalendarOffTimeRuleGetRequest sender model
   */
  get CalendarOffTimeRuleGetRequestSenderModel () {
    return this._CalendarOffTimeRuleGetRequestSenderModel
  }

  /**
   * Get CalendarOffTimeRuleGetResponse model
   * @this {!Client}
   * @returns {!CalendarOffTimeRuleGetResponseModel} CalendarOffTimeRuleGetResponse sender model
   */
  get CalendarOffTimeRuleGetResponseSenderModel () {
    return this._CalendarOffTimeRuleGetResponseSenderModel
  }

  /**
   * Get CalendarOffTimeRuleUpdateNotify model
   * @this {!Client}
   * @returns {!CalendarOffTimeRuleUpdateNotifyModel} CalendarOffTimeRuleUpdateNotify sender model
   */
  get CalendarOffTimeRuleUpdateNotifySenderModel () {
    return this._CalendarOffTimeRuleUpdateNotifySenderModel
  }

  /**
   * Get CalendarRulesGetRequest model
   * @this {!Client}
   * @returns {!CalendarRulesGetRequestModel} CalendarRulesGetRequest sender model
   */
  get CalendarRulesGetRequestSenderModel () {
    return this._CalendarRulesGetRequestSenderModel
  }

  /**
   * Get CalendarRulesGetResponse model
   * @this {!Client}
   * @returns {!CalendarRulesGetResponseModel} CalendarRulesGetResponse sender model
   */
  get CalendarRulesGetResponseSenderModel () {
    return this._CalendarRulesGetResponseSenderModel
  }

  /**
   * Get ChartBarHistoryRangeRequest model
   * @this {!Client}
   * @returns {!ChartBarHistoryRangeRequestModel} ChartBarHistoryRangeRequest sender model
   */
  get ChartBarHistoryRangeRequestSenderModel () {
    return this._ChartBarHistoryRangeRequestSenderModel
  }

  /**
   * Get OrderMarginSubscribeRequest model
   * @this {!Client}
   * @returns {!OrderMarginSubscribeRequestModel} OrderMarginSubscribeRequest sender model
   */
  get OrderMarginSubscribeRequestSenderModel () {
    return this._OrderMarginSubscribeRequestSenderModel
  }

  /**
   * Get OrderMarginSubscribeResponse model
   * @this {!Client}
   * @returns {!OrderMarginSubscribeResponseModel} OrderMarginSubscribeResponse sender model
   */
  get OrderMarginSubscribeResponseSenderModel () {
    return this._OrderMarginSubscribeResponseSenderModel
  }

  /**
   * Get OrderMarginNotify model
   * @this {!Client}
   * @returns {!OrderMarginNotifyModel} OrderMarginNotify sender model
   */
  get OrderMarginNotifySenderModel () {
    return this._OrderMarginNotifySenderModel
  }

  /**
   * Get OrderMarginUpdateSubscriptionRequest model
   * @this {!Client}
   * @returns {!OrderMarginUpdateSubscriptionRequestModel} OrderMarginUpdateSubscriptionRequest sender model
   */
  get OrderMarginUpdateSubscriptionRequestSenderModel () {
    return this._OrderMarginUpdateSubscriptionRequestSenderModel
  }

  /**
   * Get OrderMarginUnsubscribeRequest model
   * @this {!Client}
   * @returns {!OrderMarginUnsubscribeRequestModel} OrderMarginUnsubscribeRequest sender model
   */
  get OrderMarginUnsubscribeRequestSenderModel () {
    return this._OrderMarginUnsubscribeRequestSenderModel
  }

  /**
   * Get OrderMarginUnsubscribeResponse model
   * @this {!Client}
   * @returns {!OrderMarginUnsubscribeResponseModel} OrderMarginUnsubscribeResponse sender model
   */
  get OrderMarginUnsubscribeResponseSenderModel () {
    return this._OrderMarginUnsubscribeResponseSenderModel
  }

  /**
   * Get OrderMarginGetRequest model
   * @this {!Client}
   * @returns {!OrderMarginGetRequestModel} OrderMarginGetRequest sender model
   */
  get OrderMarginGetRequestSenderModel () {
    return this._OrderMarginGetRequestSenderModel
  }

  /**
   * Get OrderMarginGetResponse model
   * @this {!Client}
   * @returns {!OrderMarginGetResponseModel} OrderMarginGetResponse sender model
   */
  get OrderMarginGetResponseSenderModel () {
    return this._OrderMarginGetResponseSenderModel
  }

  /**
   * Get OrderStateInfoSubscribeRequest model
   * @this {!Client}
   * @returns {!OrderStateInfoSubscribeRequestModel} OrderStateInfoSubscribeRequest sender model
   */
  get OrderStateInfoSubscribeRequestSenderModel () {
    return this._OrderStateInfoSubscribeRequestSenderModel
  }

  /**
   * Get OrderStateInfoSubscribeResponse model
   * @this {!Client}
   * @returns {!OrderStateInfoSubscribeResponseModel} OrderStateInfoSubscribeResponse sender model
   */
  get OrderStateInfoSubscribeResponseSenderModel () {
    return this._OrderStateInfoSubscribeResponseSenderModel
  }

  /**
   * Get OrderStateInfoUnsubscribeRequest model
   * @this {!Client}
   * @returns {!OrderStateInfoUnsubscribeRequestModel} OrderStateInfoUnsubscribeRequest sender model
   */
  get OrderStateInfoUnsubscribeRequestSenderModel () {
    return this._OrderStateInfoUnsubscribeRequestSenderModel
  }

  /**
   * Get OrderStateInfoUnsubscribeResponse model
   * @this {!Client}
   * @returns {!OrderStateInfoUnsubscribeResponseModel} OrderStateInfoUnsubscribeResponse sender model
   */
  get OrderStateInfoUnsubscribeResponseSenderModel () {
    return this._OrderStateInfoUnsubscribeResponseSenderModel
  }

  /**
   * Get OrderStateInfoNotify model
   * @this {!Client}
   * @returns {!OrderStateInfoNotifyModel} OrderStateInfoNotify sender model
   */
  get OrderStateInfoNotifySenderModel () {
    return this._OrderStateInfoNotifySenderModel
  }

  /**
   * Get Deprecated003 model
   * @this {!Client}
   * @returns {!Deprecated003Model} Deprecated003 sender model
   */
  get Deprecated003SenderModel () {
    return this._Deprecated003SenderModel
  }

  /**
   * Get Deprecated004 model
   * @this {!Client}
   * @returns {!Deprecated004Model} Deprecated004 sender model
   */
  get Deprecated004SenderModel () {
    return this._Deprecated004SenderModel
  }

  /**
   * Get Deprecated005 model
   * @this {!Client}
   * @returns {!Deprecated005Model} Deprecated005 sender model
   */
  get Deprecated005SenderModel () {
    return this._Deprecated005SenderModel
  }

  /**
   * Get Deprecated006 model
   * @this {!Client}
   * @returns {!Deprecated006Model} Deprecated006 sender model
   */
  get Deprecated006SenderModel () {
    return this._Deprecated006SenderModel
  }

  /**
   * Get Deprecated007 model
   * @this {!Client}
   * @returns {!Deprecated007Model} Deprecated007 sender model
   */
  get Deprecated007SenderModel () {
    return this._Deprecated007SenderModel
  }

  /**
   * Get Deprecated008 model
   * @this {!Client}
   * @returns {!Deprecated008Model} Deprecated008 sender model
   */
  get Deprecated008SenderModel () {
    return this._Deprecated008SenderModel
  }

  /**
   * Get AccountUpdateRequest model
   * @this {!Client}
   * @returns {!AccountUpdateRequestModel} AccountUpdateRequest sender model
   */
  get AccountUpdateRequestSenderModel () {
    return this._AccountUpdateRequestSenderModel
  }

  /**
   * Get AccountUpdateResponse model
   * @this {!Client}
   * @returns {!AccountUpdateResponseModel} AccountUpdateResponse sender model
   */
  get AccountUpdateResponseSenderModel () {
    return this._AccountUpdateResponseSenderModel
  }

  /**
   * Get ProfitClosedInfoGetRequest model
   * @this {!Client}
   * @returns {!ProfitClosedInfoGetRequestModel} ProfitClosedInfoGetRequest sender model
   */
  get ProfitClosedInfoGetRequestSenderModel () {
    return this._ProfitClosedInfoGetRequestSenderModel
  }

  /**
   * Get ProfitClosedInfoGetResponse model
   * @this {!Client}
   * @returns {!ProfitClosedInfoGetResponseModel} ProfitClosedInfoGetResponse sender model
   */
  get ProfitClosedInfoGetResponseSenderModel () {
    return this._ProfitClosedInfoGetResponseSenderModel
  }

  /**
   * Get SymbolDayProfitLossInfoSubscribeRequest model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossInfoSubscribeRequestModel} SymbolDayProfitLossInfoSubscribeRequest sender model
   */
  get SymbolDayProfitLossInfoSubscribeRequestSenderModel () {
    return this._SymbolDayProfitLossInfoSubscribeRequestSenderModel
  }

  /**
   * Get SymbolDayProfitLossInfoSubscribeResponse model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossInfoSubscribeResponseModel} SymbolDayProfitLossInfoSubscribeResponse sender model
   */
  get SymbolDayProfitLossInfoSubscribeResponseSenderModel () {
    return this._SymbolDayProfitLossInfoSubscribeResponseSenderModel
  }

  /**
   * Get SymbolDayProfitLossInfoUpdateSubscriptionRequest model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossInfoUpdateSubscriptionRequestModel} SymbolDayProfitLossInfoUpdateSubscriptionRequest sender model
   */
  get SymbolDayProfitLossInfoUpdateSubscriptionRequestSenderModel () {
    return this._SymbolDayProfitLossInfoUpdateSubscriptionRequestSenderModel
  }

  /**
   * Get SymbolDayProfitLossInfoUnsubscribeRequest model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossInfoUnsubscribeRequestModel} SymbolDayProfitLossInfoUnsubscribeRequest sender model
   */
  get SymbolDayProfitLossInfoUnsubscribeRequestSenderModel () {
    return this._SymbolDayProfitLossInfoUnsubscribeRequestSenderModel
  }

  /**
   * Get SymbolDayProfitLossInfoUnsubscribeResponse model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossInfoUnsubscribeResponseModel} SymbolDayProfitLossInfoUnsubscribeResponse sender model
   */
  get SymbolDayProfitLossInfoUnsubscribeResponseSenderModel () {
    return this._SymbolDayProfitLossInfoUnsubscribeResponseSenderModel
  }

  /**
   * Get SymbolDayProfitLossInfoNotify model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossInfoNotifyModel} SymbolDayProfitLossInfoNotify sender model
   */
  get SymbolDayProfitLossInfoNotifySenderModel () {
    return this._SymbolDayProfitLossInfoNotifySenderModel
  }

  /**
   * Get LoginInfoGetRequest model
   * @this {!Client}
   * @returns {!LoginInfoGetRequestModel} LoginInfoGetRequest sender model
   */
  get LoginInfoGetRequestSenderModel () {
    return this._LoginInfoGetRequestSenderModel
  }

  /**
   * Get LoginInfoGetResponse model
   * @this {!Client}
   * @returns {!LoginInfoGetResponseModel} LoginInfoGetResponse sender model
   */
  get LoginInfoGetResponseSenderModel () {
    return this._LoginInfoGetResponseSenderModel
  }

  /**
   * Get DashboardInfoCreateRequest model
   * @this {!Client}
   * @returns {!DashboardInfoCreateRequestModel} DashboardInfoCreateRequest sender model
   */
  get DashboardInfoCreateRequestSenderModel () {
    return this._DashboardInfoCreateRequestSenderModel
  }

  /**
   * Get DashboardInfoCreateResponse model
   * @this {!Client}
   * @returns {!DashboardInfoCreateResponseModel} DashboardInfoCreateResponse sender model
   */
  get DashboardInfoCreateResponseSenderModel () {
    return this._DashboardInfoCreateResponseSenderModel
  }

  /**
   * Get DashboardInfoUpdateRequest model
   * @this {!Client}
   * @returns {!DashboardInfoUpdateRequestModel} DashboardInfoUpdateRequest sender model
   */
  get DashboardInfoUpdateRequestSenderModel () {
    return this._DashboardInfoUpdateRequestSenderModel
  }

  /**
   * Get DashboardInfoUpdateResponse model
   * @this {!Client}
   * @returns {!DashboardInfoUpdateResponseModel} DashboardInfoUpdateResponse sender model
   */
  get DashboardInfoUpdateResponseSenderModel () {
    return this._DashboardInfoUpdateResponseSenderModel
  }

  /**
   * Get DashboardInfoListRequest model
   * @this {!Client}
   * @returns {!DashboardInfoListRequestModel} DashboardInfoListRequest sender model
   */
  get DashboardInfoListRequestSenderModel () {
    return this._DashboardInfoListRequestSenderModel
  }

  /**
   * Get DashboardInfoListResponse model
   * @this {!Client}
   * @returns {!DashboardInfoListResponseModel} DashboardInfoListResponse sender model
   */
  get DashboardInfoListResponseSenderModel () {
    return this._DashboardInfoListResponseSenderModel
  }

  /**
   * Get DashboardInfoGetRequest model
   * @this {!Client}
   * @returns {!DashboardInfoGetRequestModel} DashboardInfoGetRequest sender model
   */
  get DashboardInfoGetRequestSenderModel () {
    return this._DashboardInfoGetRequestSenderModel
  }

  /**
   * Get DashboardInfoGetResponse model
   * @this {!Client}
   * @returns {!DashboardInfoGetResponseModel} DashboardInfoGetResponse sender model
   */
  get DashboardInfoGetResponseSenderModel () {
    return this._DashboardInfoGetResponseSenderModel
  }

  /**
   * Get DashboardInfoRenameRequest model
   * @this {!Client}
   * @returns {!DashboardInfoRenameRequestModel} DashboardInfoRenameRequest sender model
   */
  get DashboardInfoRenameRequestSenderModel () {
    return this._DashboardInfoRenameRequestSenderModel
  }

  /**
   * Get DashboardInfoRenameResponse model
   * @this {!Client}
   * @returns {!DashboardInfoRenameResponseModel} DashboardInfoRenameResponse sender model
   */
  get DashboardInfoRenameResponseSenderModel () {
    return this._DashboardInfoRenameResponseSenderModel
  }

  /**
   * Get DashboardInfoDeleteRequest model
   * @this {!Client}
   * @returns {!DashboardInfoDeleteRequestModel} DashboardInfoDeleteRequest sender model
   */
  get DashboardInfoDeleteRequestSenderModel () {
    return this._DashboardInfoDeleteRequestSenderModel
  }

  /**
   * Get DashboardInfoDeleteResponse model
   * @this {!Client}
   * @returns {!DashboardInfoDeleteResponseModel} DashboardInfoDeleteResponse sender model
   */
  get DashboardInfoDeleteResponseSenderModel () {
    return this._DashboardInfoDeleteResponseSenderModel
  }

  /**
   * Get ChartInfoCreateRequest model
   * @this {!Client}
   * @returns {!ChartInfoCreateRequestModel} ChartInfoCreateRequest sender model
   */
  get ChartInfoCreateRequestSenderModel () {
    return this._ChartInfoCreateRequestSenderModel
  }

  /**
   * Get ChartInfoCreateResponse model
   * @this {!Client}
   * @returns {!ChartInfoCreateResponseModel} ChartInfoCreateResponse sender model
   */
  get ChartInfoCreateResponseSenderModel () {
    return this._ChartInfoCreateResponseSenderModel
  }

  /**
   * Get ChartInfoUpdateRequest model
   * @this {!Client}
   * @returns {!ChartInfoUpdateRequestModel} ChartInfoUpdateRequest sender model
   */
  get ChartInfoUpdateRequestSenderModel () {
    return this._ChartInfoUpdateRequestSenderModel
  }

  /**
   * Get ChartInfoUpdateResponse model
   * @this {!Client}
   * @returns {!ChartInfoUpdateResponseModel} ChartInfoUpdateResponse sender model
   */
  get ChartInfoUpdateResponseSenderModel () {
    return this._ChartInfoUpdateResponseSenderModel
  }

  /**
   * Get ChartInfoListRequest model
   * @this {!Client}
   * @returns {!ChartInfoListRequestModel} ChartInfoListRequest sender model
   */
  get ChartInfoListRequestSenderModel () {
    return this._ChartInfoListRequestSenderModel
  }

  /**
   * Get ChartInfoListResponse model
   * @this {!Client}
   * @returns {!ChartInfoListResponseModel} ChartInfoListResponse sender model
   */
  get ChartInfoListResponseSenderModel () {
    return this._ChartInfoListResponseSenderModel
  }

  /**
   * Get ChartInfoDeleteRequest model
   * @this {!Client}
   * @returns {!ChartInfoDeleteRequestModel} ChartInfoDeleteRequest sender model
   */
  get ChartInfoDeleteRequestSenderModel () {
    return this._ChartInfoDeleteRequestSenderModel
  }

  /**
   * Get ChartInfoDeleteResponse model
   * @this {!Client}
   * @returns {!ChartInfoDeleteResponseModel} ChartInfoDeleteResponse sender model
   */
  get ChartInfoDeleteResponseSenderModel () {
    return this._ChartInfoDeleteResponseSenderModel
  }

  /**
   * Get ChartInfoGetRequest model
   * @this {!Client}
   * @returns {!ChartInfoGetRequestModel} ChartInfoGetRequest sender model
   */
  get ChartInfoGetRequestSenderModel () {
    return this._ChartInfoGetRequestSenderModel
  }

  /**
   * Get ChartInfoGetResponse model
   * @this {!Client}
   * @returns {!ChartInfoGetResponseModel} ChartInfoGetResponse sender model
   */
  get ChartInfoGetResponseSenderModel () {
    return this._ChartInfoGetResponseSenderModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoSubscribeRequest model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossMultipleInfoSubscribeRequestModel} SymbolDayProfitLossMultipleInfoSubscribeRequest sender model
   */
  get SymbolDayProfitLossMultipleInfoSubscribeRequestSenderModel () {
    return this._SymbolDayProfitLossMultipleInfoSubscribeRequestSenderModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoSubscribeResponse model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossMultipleInfoSubscribeResponseModel} SymbolDayProfitLossMultipleInfoSubscribeResponse sender model
   */
  get SymbolDayProfitLossMultipleInfoSubscribeResponseSenderModel () {
    return this._SymbolDayProfitLossMultipleInfoSubscribeResponseSenderModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel} SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest sender model
   */
  get SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel () {
    return this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoUnsubscribeRequest model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel} SymbolDayProfitLossMultipleInfoUnsubscribeRequest sender model
   */
  get SymbolDayProfitLossMultipleInfoUnsubscribeRequestSenderModel () {
    return this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestSenderModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoUnsubscribeResponse model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel} SymbolDayProfitLossMultipleInfoUnsubscribeResponse sender model
   */
  get SymbolDayProfitLossMultipleInfoUnsubscribeResponseSenderModel () {
    return this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseSenderModel
  }

  /**
   * Get SymbolDayProfitLossMultipleInfoNotify model
   * @this {!Client}
   * @returns {!SymbolDayProfitLossMultipleInfoNotifyModel} SymbolDayProfitLossMultipleInfoNotify sender model
   */
  get SymbolDayProfitLossMultipleInfoNotifySenderModel () {
    return this._SymbolDayProfitLossMultipleInfoNotifySenderModel
  }

  /**
   * Get NewsServiceGetAllRequest model
   * @this {!Client}
   * @returns {!NewsServiceGetAllRequestModel} NewsServiceGetAllRequest sender model
   */
  get NewsServiceGetAllRequestSenderModel () {
    return this._NewsServiceGetAllRequestSenderModel
  }

  /**
   * Get NewsServiceGetAllResponse model
   * @this {!Client}
   * @returns {!NewsServiceGetAllResponseModel} NewsServiceGetAllResponse sender model
   */
  get NewsServiceGetAllResponseSenderModel () {
    return this._NewsServiceGetAllResponseSenderModel
  }

  /**
   * Get NewsRecentArticlesGetRequest model
   * @this {!Client}
   * @returns {!NewsRecentArticlesGetRequestModel} NewsRecentArticlesGetRequest sender model
   */
  get NewsRecentArticlesGetRequestSenderModel () {
    return this._NewsRecentArticlesGetRequestSenderModel
  }

  /**
   * Get NewsRecentArticlesGetResponse model
   * @this {!Client}
   * @returns {!NewsRecentArticlesGetResponseModel} NewsRecentArticlesGetResponse sender model
   */
  get NewsRecentArticlesGetResponseSenderModel () {
    return this._NewsRecentArticlesGetResponseSenderModel
  }

  /**
   * Get NewsArticleGetRequest model
   * @this {!Client}
   * @returns {!NewsArticleGetRequestModel} NewsArticleGetRequest sender model
   */
  get NewsArticleGetRequestSenderModel () {
    return this._NewsArticleGetRequestSenderModel
  }

  /**
   * Get NewsArticleGetResponse model
   * @this {!Client}
   * @returns {!NewsArticleGetResponseModel} NewsArticleGetResponse sender model
   */
  get NewsArticleGetResponseSenderModel () {
    return this._NewsArticleGetResponseSenderModel
  }

  /**
   * Get OrderUpdateWithTypeRequest model
   * @this {!Client}
   * @returns {!OrderUpdateWithTypeRequestModel} OrderUpdateWithTypeRequest sender model
   */
  get OrderUpdateWithTypeRequestSenderModel () {
    return this._OrderUpdateWithTypeRequestSenderModel
  }

  /**
   * Get OrderUpdateWithTypeResponse model
   * @this {!Client}
   * @returns {!OrderUpdateWithTypeResponseModel} OrderUpdateWithTypeResponse sender model
   */
  get OrderUpdateWithTypeResponseSenderModel () {
    return this._OrderUpdateWithTypeResponseSenderModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoSubscribeRequest model
   * @this {!Client}
   * @returns {!SymbolProfitLossMultipleInfoSubscribeRequestModel} SymbolProfitLossMultipleInfoSubscribeRequest sender model
   */
  get SymbolProfitLossMultipleInfoSubscribeRequestSenderModel () {
    return this._SymbolProfitLossMultipleInfoSubscribeRequestSenderModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoSubscribeResponse model
   * @this {!Client}
   * @returns {!SymbolProfitLossMultipleInfoSubscribeResponseModel} SymbolProfitLossMultipleInfoSubscribeResponse sender model
   */
  get SymbolProfitLossMultipleInfoSubscribeResponseSenderModel () {
    return this._SymbolProfitLossMultipleInfoSubscribeResponseSenderModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoUpdateSubscriptionRequest model
   * @this {!Client}
   * @returns {!SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel} SymbolProfitLossMultipleInfoUpdateSubscriptionRequest sender model
   */
  get SymbolProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel () {
    return this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoUnsubscribeRequest model
   * @this {!Client}
   * @returns {!SymbolProfitLossMultipleInfoUnsubscribeRequestModel} SymbolProfitLossMultipleInfoUnsubscribeRequest sender model
   */
  get SymbolProfitLossMultipleInfoUnsubscribeRequestSenderModel () {
    return this._SymbolProfitLossMultipleInfoUnsubscribeRequestSenderModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoUnsubscribeResponse model
   * @this {!Client}
   * @returns {!SymbolProfitLossMultipleInfoUnsubscribeResponseModel} SymbolProfitLossMultipleInfoUnsubscribeResponse sender model
   */
  get SymbolProfitLossMultipleInfoUnsubscribeResponseSenderModel () {
    return this._SymbolProfitLossMultipleInfoUnsubscribeResponseSenderModel
  }

  /**
   * Get SymbolProfitLossMultipleInfoNotify model
   * @this {!Client}
   * @returns {!SymbolProfitLossMultipleInfoNotifyModel} SymbolProfitLossMultipleInfoNotify sender model
   */
  get SymbolProfitLossMultipleInfoNotifySenderModel () {
    return this._SymbolProfitLossMultipleInfoNotifySenderModel
  }

  /**
   * Get LoginWithPasswordRequest model
   * @this {!Client}
   * @returns {!LoginWithPasswordRequestModel} LoginWithPasswordRequest sender model
   */
  get LoginWithPasswordRequestSenderModel () {
    return this._LoginWithPasswordRequestSenderModel
  }

  /**
   * Get LoginWithPasswordResponse model
   * @this {!Client}
   * @returns {!LoginWithPasswordResponseModel} LoginWithPasswordResponse sender model
   */
  get LoginWithPasswordResponseSenderModel () {
    return this._LoginWithPasswordResponseSenderModel
  }

  /**
   * Get LoginWithTokenRequest model
   * @this {!Client}
   * @returns {!LoginWithTokenRequestModel} LoginWithTokenRequest sender model
   */
  get LoginWithTokenRequestSenderModel () {
    return this._LoginWithTokenRequestSenderModel
  }

  /**
   * Get LoginWithTokenResponse model
   * @this {!Client}
   * @returns {!LoginWithTokenResponseModel} LoginWithTokenResponse sender model
   */
  get LoginWithTokenResponseSenderModel () {
    return this._LoginWithTokenResponseSenderModel
  }

  /**
   * Get RefreshTokenRequest model
   * @this {!Client}
   * @returns {!RefreshTokenRequestModel} RefreshTokenRequest sender model
   */
  get RefreshTokenRequestSenderModel () {
    return this._RefreshTokenRequestSenderModel
  }

  /**
   * Get RefreshTokenResponse model
   * @this {!Client}
   * @returns {!RefreshTokenResponseModel} RefreshTokenResponse sender model
   */
  get RefreshTokenResponseSenderModel () {
    return this._RefreshTokenResponseSenderModel
  }

  /**
   * Get Logout2Request model
   * @this {!Client}
   * @returns {!Logout2RequestModel} Logout2Request sender model
   */
  get Logout2RequestSenderModel () {
    return this._Logout2RequestSenderModel
  }

  /**
   * Get Logout2Response model
   * @this {!Client}
   * @returns {!Logout2ResponseModel} Logout2Response sender model
   */
  get Logout2ResponseSenderModel () {
    return this._Logout2ResponseSenderModel
  }

  /**
   * Get AccountChangePasswordRequest model
   * @this {!Client}
   * @returns {!AccountChangePasswordRequestModel} AccountChangePasswordRequest sender model
   */
  get AccountChangePasswordRequestSenderModel () {
    return this._AccountChangePasswordRequestSenderModel
  }

  /**
   * Get AccountChangePasswordResponse model
   * @this {!Client}
   * @returns {!AccountChangePasswordResponseModel} AccountChangePasswordResponse sender model
   */
  get AccountChangePasswordResponseSenderModel () {
    return this._AccountChangePasswordResponseSenderModel
  }

  /**
   * Get PushNotificationTokenRegisterRequest model
   * @this {!Client}
   * @returns {!PushNotificationTokenRegisterRequestModel} PushNotificationTokenRegisterRequest sender model
   */
  get PushNotificationTokenRegisterRequestSenderModel () {
    return this._PushNotificationTokenRegisterRequestSenderModel
  }

  /**
   * Get PushNotificationTokenRegisterResponse model
   * @this {!Client}
   * @returns {!PushNotificationTokenRegisterResponseModel} PushNotificationTokenRegisterResponse sender model
   */
  get PushNotificationTokenRegisterResponseSenderModel () {
    return this._PushNotificationTokenRegisterResponseSenderModel
  }

  /**
   * Get DesiredAppSettingsGetRequest model
   * @this {!Client}
   * @returns {!DesiredAppSettingsGetRequestModel} DesiredAppSettingsGetRequest sender model
   */
  get DesiredAppSettingsGetRequestSenderModel () {
    return this._DesiredAppSettingsGetRequestSenderModel
  }

  /**
   * Get DesiredAppSettingsGetResponse model
   * @this {!Client}
   * @returns {!DesiredAppSettingsGetResponseModel} DesiredAppSettingsGetResponse sender model
   */
  get DesiredAppSettingsGetResponseSenderModel () {
    return this._DesiredAppSettingsGetResponseSenderModel
  }

  /**
   * Get AuthTokensGetAllRequest model
   * @this {!Client}
   * @returns {!AuthTokensGetAllRequestModel} AuthTokensGetAllRequest sender model
   */
  get AuthTokensGetAllRequestSenderModel () {
    return this._AuthTokensGetAllRequestSenderModel
  }

  /**
   * Get AuthTokensGetAllResponse model
   * @this {!Client}
   * @returns {!AuthTokensGetAllResponseModel} AuthTokensGetAllResponse sender model
   */
  get AuthTokensGetAllResponseSenderModel () {
    return this._AuthTokensGetAllResponseSenderModel
  }

  /**
   * Get AuthTokensDeleteRequest model
   * @this {!Client}
   * @returns {!AuthTokensDeleteRequestModel} AuthTokensDeleteRequest sender model
   */
  get AuthTokensDeleteRequestSenderModel () {
    return this._AuthTokensDeleteRequestSenderModel
  }

  /**
   * Get AuthTokensDeleteResponse model
   * @this {!Client}
   * @returns {!AuthTokensDeleteResponseModel} AuthTokensDeleteResponse sender model
   */
  get AuthTokensDeleteResponseSenderModel () {
    return this._AuthTokensDeleteResponseSenderModel
  }

  /**
   * Get LegalDocumentGroupListRequest model
   * @this {!Client}
   * @returns {!LegalDocumentGroupListRequestModel} LegalDocumentGroupListRequest sender model
   */
  get LegalDocumentGroupListRequestSenderModel () {
    return this._LegalDocumentGroupListRequestSenderModel
  }

  /**
   * Get LegalDocumentGroupListResponse model
   * @this {!Client}
   * @returns {!LegalDocumentGroupListResponseModel} LegalDocumentGroupListResponse sender model
   */
  get LegalDocumentGroupListResponseSenderModel () {
    return this._LegalDocumentGroupListResponseSenderModel
  }

  /**
   * Get LegalDocumentGetRequest model
   * @this {!Client}
   * @returns {!LegalDocumentGetRequestModel} LegalDocumentGetRequest sender model
   */
  get LegalDocumentGetRequestSenderModel () {
    return this._LegalDocumentGetRequestSenderModel
  }

  /**
   * Get LegalDocumentGetResponse model
   * @this {!Client}
   * @returns {!LegalDocumentGetResponseModel} LegalDocumentGetResponse sender model
   */
  get LegalDocumentGetResponseSenderModel () {
    return this._LegalDocumentGetResponseSenderModel
  }

  /**
   * Get PushNotificationTokenUnRegisterRequest model
   * @this {!Client}
   * @returns {!PushNotificationTokenUnRegisterRequestModel} PushNotificationTokenUnRegisterRequest sender model
   */
  get PushNotificationTokenUnRegisterRequestSenderModel () {
    return this._PushNotificationTokenUnRegisterRequestSenderModel
  }

  /**
   * Get PushNotificationTokenUnRegisterResponse model
   * @this {!Client}
   * @returns {!PushNotificationTokenUnRegisterResponseModel} PushNotificationTokenUnRegisterResponse sender model
   */
  get PushNotificationTokenUnRegisterResponseSenderModel () {
    return this._PushNotificationTokenUnRegisterResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof PingRequest) && (value.fbeType === this.PingRequestSenderModel.fbeType)) {
      return this.send_PingRequest(value)
    }
    if ((value instanceof PongResponse) && (value.fbeType === this.PongResponseSenderModel.fbeType)) {
      return this.send_PongResponse(value)
    }
    if ((value instanceof LoginRequest) && (value.fbeType === this.LoginRequestSenderModel.fbeType)) {
      return this.send_LoginRequest(value)
    }
    if ((value instanceof LoginResponse) && (value.fbeType === this.LoginResponseSenderModel.fbeType)) {
      return this.send_LoginResponse(value)
    }
    if ((value instanceof LoginReject) && (value.fbeType === this.LoginRejectSenderModel.fbeType)) {
      return this.send_LoginReject(value)
    }
    if ((value instanceof LogoutRequest) && (value.fbeType === this.LogoutRequestSenderModel.fbeType)) {
      return this.send_LogoutRequest(value)
    }
    if ((value instanceof LogoutResponse) && (value.fbeType === this.LogoutResponseSenderModel.fbeType)) {
      return this.send_LogoutResponse(value)
    }
    if ((value instanceof LogoutNotify) && (value.fbeType === this.LogoutNotifySenderModel.fbeType)) {
      return this.send_LogoutNotify(value)
    }
    if ((value instanceof SessionInfoRequest) && (value.fbeType === this.SessionInfoRequestSenderModel.fbeType)) {
      return this.send_SessionInfoRequest(value)
    }
    if ((value instanceof SessionInfoResponse) && (value.fbeType === this.SessionInfoResponseSenderModel.fbeType)) {
      return this.send_SessionInfoResponse(value)
    }
    if ((value instanceof TimeInfoRequest) && (value.fbeType === this.TimeInfoRequestSenderModel.fbeType)) {
      return this.send_TimeInfoRequest(value)
    }
    if ((value instanceof TimeInfoResponse) && (value.fbeType === this.TimeInfoResponseSenderModel.fbeType)) {
      return this.send_TimeInfoResponse(value)
    }
    if ((value instanceof TimezoneInfoRequest) && (value.fbeType === this.TimezoneInfoRequestSenderModel.fbeType)) {
      return this.send_TimezoneInfoRequest(value)
    }
    if ((value instanceof TimezoneInfoResponse) && (value.fbeType === this.TimezoneInfoResponseSenderModel.fbeType)) {
      return this.send_TimezoneInfoResponse(value)
    }
    if ((value instanceof TimezoneInfoUpdateNotify) && (value.fbeType === this.TimezoneInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_TimezoneInfoUpdateNotify(value)
    }
    if ((value instanceof CurrencyInfoRequest) && (value.fbeType === this.CurrencyInfoRequestSenderModel.fbeType)) {
      return this.send_CurrencyInfoRequest(value)
    }
    if ((value instanceof CurrencyInfoResponse) && (value.fbeType === this.CurrencyInfoResponseSenderModel.fbeType)) {
      return this.send_CurrencyInfoResponse(value)
    }
    if ((value instanceof CurrencyListRequest) && (value.fbeType === this.CurrencyListRequestSenderModel.fbeType)) {
      return this.send_CurrencyListRequest(value)
    }
    if ((value instanceof CurrencyListResponse) && (value.fbeType === this.CurrencyListResponseSenderModel.fbeType)) {
      return this.send_CurrencyListResponse(value)
    }
    if ((value instanceof CurrencyInfoUpdateNotify) && (value.fbeType === this.CurrencyInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_CurrencyInfoUpdateNotify(value)
    }
    if ((value instanceof SymbolInfoRequest) && (value.fbeType === this.SymbolInfoRequestSenderModel.fbeType)) {
      return this.send_SymbolInfoRequest(value)
    }
    if ((value instanceof SymbolInfoResponse) && (value.fbeType === this.SymbolInfoResponseSenderModel.fbeType)) {
      return this.send_SymbolInfoResponse(value)
    }
    if ((value instanceof SymbolListRequest) && (value.fbeType === this.SymbolListRequestSenderModel.fbeType)) {
      return this.send_SymbolListRequest(value)
    }
    if ((value instanceof SymbolListResponse) && (value.fbeType === this.SymbolListResponseSenderModel.fbeType)) {
      return this.send_SymbolListResponse(value)
    }
    if ((value instanceof SymbolInfoUpdateNotify) && (value.fbeType === this.SymbolInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolInfoUpdateNotify(value)
    }
    if ((value instanceof TickInfoRequest) && (value.fbeType === this.TickInfoRequestSenderModel.fbeType)) {
      return this.send_TickInfoRequest(value)
    }
    if ((value instanceof TickInfoResponse) && (value.fbeType === this.TickInfoResponseSenderModel.fbeType)) {
      return this.send_TickInfoResponse(value)
    }
    if ((value instanceof TickFilterRequest) && (value.fbeType === this.TickFilterRequestSenderModel.fbeType)) {
      return this.send_TickFilterRequest(value)
    }
    if ((value instanceof TickFilterResponse) && (value.fbeType === this.TickFilterResponseSenderModel.fbeType)) {
      return this.send_TickFilterResponse(value)
    }
    if ((value instanceof TickListRequest) && (value.fbeType === this.TickListRequestSenderModel.fbeType)) {
      return this.send_TickListRequest(value)
    }
    if ((value instanceof TickListResponse) && (value.fbeType === this.TickListResponseSenderModel.fbeType)) {
      return this.send_TickListResponse(value)
    }
    if ((value instanceof Level2InfoRequest) && (value.fbeType === this.Level2InfoRequestSenderModel.fbeType)) {
      return this.send_Level2InfoRequest(value)
    }
    if ((value instanceof Level2InfoResponse) && (value.fbeType === this.Level2InfoResponseSenderModel.fbeType)) {
      return this.send_Level2InfoResponse(value)
    }
    if ((value instanceof Level2FilterRequest) && (value.fbeType === this.Level2FilterRequestSenderModel.fbeType)) {
      return this.send_Level2FilterRequest(value)
    }
    if ((value instanceof Level2FilterResponse) && (value.fbeType === this.Level2FilterResponseSenderModel.fbeType)) {
      return this.send_Level2FilterResponse(value)
    }
    if ((value instanceof Level2ListRequest) && (value.fbeType === this.Level2ListRequestSenderModel.fbeType)) {
      return this.send_Level2ListRequest(value)
    }
    if ((value instanceof Level2ListResponse) && (value.fbeType === this.Level2ListResponseSenderModel.fbeType)) {
      return this.send_Level2ListResponse(value)
    }
    if ((value instanceof FeedSubscriptionInfoRequest) && (value.fbeType === this.FeedSubscriptionInfoRequestSenderModel.fbeType)) {
      return this.send_FeedSubscriptionInfoRequest(value)
    }
    if ((value instanceof FeedSubscriptionInfoResponse) && (value.fbeType === this.FeedSubscriptionInfoResponseSenderModel.fbeType)) {
      return this.send_FeedSubscriptionInfoResponse(value)
    }
    if ((value instanceof FeedSubscribeRequest) && (value.fbeType === this.FeedSubscribeRequestSenderModel.fbeType)) {
      return this.send_FeedSubscribeRequest(value)
    }
    if ((value instanceof FeedSubscribeAllRequest) && (value.fbeType === this.FeedSubscribeAllRequestSenderModel.fbeType)) {
      return this.send_FeedSubscribeAllRequest(value)
    }
    if ((value instanceof FeedSubscribeResponse) && (value.fbeType === this.FeedSubscribeResponseSenderModel.fbeType)) {
      return this.send_FeedSubscribeResponse(value)
    }
    if ((value instanceof FeedUnsubscribeRequest) && (value.fbeType === this.FeedUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_FeedUnsubscribeRequest(value)
    }
    if ((value instanceof FeedUnsubscribeAllRequest) && (value.fbeType === this.FeedUnsubscribeAllRequestSenderModel.fbeType)) {
      return this.send_FeedUnsubscribeAllRequest(value)
    }
    if ((value instanceof FeedUnsubscribeResponse) && (value.fbeType === this.FeedUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_FeedUnsubscribeResponse(value)
    }
    if ((value instanceof TickSnapshotNotify) && (value.fbeType === this.TickSnapshotNotifySenderModel.fbeType)) {
      return this.send_TickSnapshotNotify(value)
    }
    if ((value instanceof TickUpdateNotify) && (value.fbeType === this.TickUpdateNotifySenderModel.fbeType)) {
      return this.send_TickUpdateNotify(value)
    }
    if ((value instanceof Level2SnapshotNotify) && (value.fbeType === this.Level2SnapshotNotifySenderModel.fbeType)) {
      return this.send_Level2SnapshotNotify(value)
    }
    if ((value instanceof Level2UpdateNotify) && (value.fbeType === this.Level2UpdateNotifySenderModel.fbeType)) {
      return this.send_Level2UpdateNotify(value)
    }
    if ((value instanceof ChartBarHistoryRequest) && (value.fbeType === this.ChartBarHistoryRequestSenderModel.fbeType)) {
      return this.send_ChartBarHistoryRequest(value)
    }
    if ((value instanceof ChartBarHistoryResponse) && (value.fbeType === this.ChartBarHistoryResponseSenderModel.fbeType)) {
      return this.send_ChartBarHistoryResponse(value)
    }
    if ((value instanceof ChartBarSubscribeRequest) && (value.fbeType === this.ChartBarSubscribeRequestSenderModel.fbeType)) {
      return this.send_ChartBarSubscribeRequest(value)
    }
    if ((value instanceof ChartBarSubscribeResponse) && (value.fbeType === this.ChartBarSubscribeResponseSenderModel.fbeType)) {
      return this.send_ChartBarSubscribeResponse(value)
    }
    if ((value instanceof ChartBarUnsubscribeRequest) && (value.fbeType === this.ChartBarUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_ChartBarUnsubscribeRequest(value)
    }
    if ((value instanceof ChartBarUnsubscribeResponse) && (value.fbeType === this.ChartBarUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_ChartBarUnsubscribeResponse(value)
    }
    if ((value instanceof ChartBarNotify) && (value.fbeType === this.ChartBarNotifySenderModel.fbeType)) {
      return this.send_ChartBarNotify(value)
    }
    if ((value instanceof ChartBarUpdateNotify) && (value.fbeType === this.ChartBarUpdateNotifySenderModel.fbeType)) {
      return this.send_ChartBarUpdateNotify(value)
    }
    if ((value instanceof PlatformInfoRequest) && (value.fbeType === this.PlatformInfoRequestSenderModel.fbeType)) {
      return this.send_PlatformInfoRequest(value)
    }
    if ((value instanceof PlatformInfoResponse) && (value.fbeType === this.PlatformInfoResponseSenderModel.fbeType)) {
      return this.send_PlatformInfoResponse(value)
    }
    if ((value instanceof PlatformInfoUpdateNotify) && (value.fbeType === this.PlatformInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_PlatformInfoUpdateNotify(value)
    }
    if ((value instanceof AccountInfoRequest) && (value.fbeType === this.AccountInfoRequestSenderModel.fbeType)) {
      return this.send_AccountInfoRequest(value)
    }
    if ((value instanceof AccountInfoResponse) && (value.fbeType === this.AccountInfoResponseSenderModel.fbeType)) {
      return this.send_AccountInfoResponse(value)
    }
    if ((value instanceof AccountInfoUpdateNotify) && (value.fbeType === this.AccountInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_AccountInfoUpdateNotify(value)
    }
    if ((value instanceof MarginInfoRequest) && (value.fbeType === this.MarginInfoRequestSenderModel.fbeType)) {
      return this.send_MarginInfoRequest(value)
    }
    if ((value instanceof MarginInfoResponse) && (value.fbeType === this.MarginInfoResponseSenderModel.fbeType)) {
      return this.send_MarginInfoResponse(value)
    }
    if ((value instanceof MarginInfoUpdateNotify) && (value.fbeType === this.MarginInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_MarginInfoUpdateNotify(value)
    }
    if ((value instanceof MarginCallNotify) && (value.fbeType === this.MarginCallNotifySenderModel.fbeType)) {
      return this.send_MarginCallNotify(value)
    }
    if ((value instanceof StopOutNotify) && (value.fbeType === this.StopOutNotifySenderModel.fbeType)) {
      return this.send_StopOutNotify(value)
    }
    if ((value instanceof AssetInfoRequest) && (value.fbeType === this.AssetInfoRequestSenderModel.fbeType)) {
      return this.send_AssetInfoRequest(value)
    }
    if ((value instanceof AssetInfoResponse) && (value.fbeType === this.AssetInfoResponseSenderModel.fbeType)) {
      return this.send_AssetInfoResponse(value)
    }
    if ((value instanceof AssetListRequest) && (value.fbeType === this.AssetListRequestSenderModel.fbeType)) {
      return this.send_AssetListRequest(value)
    }
    if ((value instanceof AssetListResponse) && (value.fbeType === this.AssetListResponseSenderModel.fbeType)) {
      return this.send_AssetListResponse(value)
    }
    if ((value instanceof AssetInfoUpdateNotify) && (value.fbeType === this.AssetInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_AssetInfoUpdateNotify(value)
    }
    if ((value instanceof OrderInfoRequest) && (value.fbeType === this.OrderInfoRequestSenderModel.fbeType)) {
      return this.send_OrderInfoRequest(value)
    }
    if ((value instanceof OrderInfoResponse) && (value.fbeType === this.OrderInfoResponseSenderModel.fbeType)) {
      return this.send_OrderInfoResponse(value)
    }
    if ((value instanceof OrderListRequest) && (value.fbeType === this.OrderListRequestSenderModel.fbeType)) {
      return this.send_OrderListRequest(value)
    }
    if ((value instanceof OrderListResponse) && (value.fbeType === this.OrderListResponseSenderModel.fbeType)) {
      return this.send_OrderListResponse(value)
    }
    if ((value instanceof OrderOpenRequest) && (value.fbeType === this.OrderOpenRequestSenderModel.fbeType)) {
      return this.send_OrderOpenRequest(value)
    }
    if ((value instanceof OrderOpenResponse) && (value.fbeType === this.OrderOpenResponseSenderModel.fbeType)) {
      return this.send_OrderOpenResponse(value)
    }
    if ((value instanceof OrderUpdateRequest) && (value.fbeType === this.OrderUpdateRequestSenderModel.fbeType)) {
      return this.send_OrderUpdateRequest(value)
    }
    if ((value instanceof OrderUpdateResponse) && (value.fbeType === this.OrderUpdateResponseSenderModel.fbeType)) {
      return this.send_OrderUpdateResponse(value)
    }
    if ((value instanceof OrderCancelRequest) && (value.fbeType === this.OrderCancelRequestSenderModel.fbeType)) {
      return this.send_OrderCancelRequest(value)
    }
    if ((value instanceof OrderCancelResponse) && (value.fbeType === this.OrderCancelResponseSenderModel.fbeType)) {
      return this.send_OrderCancelResponse(value)
    }
    if ((value instanceof OrderCancelAllRequest) && (value.fbeType === this.OrderCancelAllRequestSenderModel.fbeType)) {
      return this.send_OrderCancelAllRequest(value)
    }
    if ((value instanceof OrderCancelAllResponse) && (value.fbeType === this.OrderCancelAllResponseSenderModel.fbeType)) {
      return this.send_OrderCancelAllResponse(value)
    }
    if ((value instanceof OrderCancelAllByIdRequest) && (value.fbeType === this.OrderCancelAllByIdRequestSenderModel.fbeType)) {
      return this.send_OrderCancelAllByIdRequest(value)
    }
    if ((value instanceof OrderCloseRequest) && (value.fbeType === this.OrderCloseRequestSenderModel.fbeType)) {
      return this.send_OrderCloseRequest(value)
    }
    if ((value instanceof OrderCloseResponse) && (value.fbeType === this.OrderCloseResponseSenderModel.fbeType)) {
      return this.send_OrderCloseResponse(value)
    }
    if ((value instanceof OrderCloseAllRequest) && (value.fbeType === this.OrderCloseAllRequestSenderModel.fbeType)) {
      return this.send_OrderCloseAllRequest(value)
    }
    if ((value instanceof OrderCloseAllResponse) && (value.fbeType === this.OrderCloseAllResponseSenderModel.fbeType)) {
      return this.send_OrderCloseAllResponse(value)
    }
    if ((value instanceof OrderCloseAllByIdRequest) && (value.fbeType === this.OrderCloseAllByIdRequestSenderModel.fbeType)) {
      return this.send_OrderCloseAllByIdRequest(value)
    }
    if ((value instanceof OrderCloseByRequest) && (value.fbeType === this.OrderCloseByRequestSenderModel.fbeType)) {
      return this.send_OrderCloseByRequest(value)
    }
    if ((value instanceof OrderCloseByResponse) && (value.fbeType === this.OrderCloseByResponseSenderModel.fbeType)) {
      return this.send_OrderCloseByResponse(value)
    }
    if ((value instanceof OrderCloseByAllRequest) && (value.fbeType === this.OrderCloseByAllRequestSenderModel.fbeType)) {
      return this.send_OrderCloseByAllRequest(value)
    }
    if ((value instanceof OrderCloseByAllResponse) && (value.fbeType === this.OrderCloseByAllResponseSenderModel.fbeType)) {
      return this.send_OrderCloseByAllResponse(value)
    }
    if ((value instanceof OrderCancelOrCloseRequest) && (value.fbeType === this.OrderCancelOrCloseRequestSenderModel.fbeType)) {
      return this.send_OrderCancelOrCloseRequest(value)
    }
    if ((value instanceof OrderCancelOrCloseResponse) && (value.fbeType === this.OrderCancelOrCloseResponseSenderModel.fbeType)) {
      return this.send_OrderCancelOrCloseResponse(value)
    }
    if ((value instanceof OrderCancelOrCloseAllRequest) && (value.fbeType === this.OrderCancelOrCloseAllRequestSenderModel.fbeType)) {
      return this.send_OrderCancelOrCloseAllRequest(value)
    }
    if ((value instanceof OrderCancelOrCloseAllResponse) && (value.fbeType === this.OrderCancelOrCloseAllResponseSenderModel.fbeType)) {
      return this.send_OrderCancelOrCloseAllResponse(value)
    }
    if ((value instanceof OrderInfoUpdateNotify) && (value.fbeType === this.OrderInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_OrderInfoUpdateNotify(value)
    }
    if ((value instanceof PositionInfoRequest) && (value.fbeType === this.PositionInfoRequestSenderModel.fbeType)) {
      return this.send_PositionInfoRequest(value)
    }
    if ((value instanceof PositionInfoResponse) && (value.fbeType === this.PositionInfoResponseSenderModel.fbeType)) {
      return this.send_PositionInfoResponse(value)
    }
    if ((value instanceof PositionListRequest) && (value.fbeType === this.PositionListRequestSenderModel.fbeType)) {
      return this.send_PositionListRequest(value)
    }
    if ((value instanceof PositionListResponse) && (value.fbeType === this.PositionListResponseSenderModel.fbeType)) {
      return this.send_PositionListResponse(value)
    }
    if ((value instanceof PositionInfoUpdateNotify) && (value.fbeType === this.PositionInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_PositionInfoUpdateNotify(value)
    }
    if ((value instanceof TradeHistoryInfoRequest) && (value.fbeType === this.TradeHistoryInfoRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryInfoRequest(value)
    }
    if ((value instanceof TradeHistoryInfoResponse) && (value.fbeType === this.TradeHistoryInfoResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryInfoResponse(value)
    }
    if ((value instanceof TradeHistoryInfoFilterRequest) && (value.fbeType === this.TradeHistoryInfoFilterRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryInfoFilterRequest(value)
    }
    if ((value instanceof TradeHistoryInfoByIdRequest) && (value.fbeType === this.TradeHistoryInfoByIdRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryInfoByIdRequest(value)
    }
    if ((value instanceof TradeHistoryInfoByIdResponse) && (value.fbeType === this.TradeHistoryInfoByIdResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryInfoByIdResponse(value)
    }
    if ((value instanceof TradeHistoryInfoUpdateNotify) && (value.fbeType === this.TradeHistoryInfoUpdateNotifySenderModel.fbeType)) {
      return this.send_TradeHistoryInfoUpdateNotify(value)
    }
    if ((value instanceof CalendarRegularRuleGetRequest) && (value.fbeType === this.CalendarRegularRuleGetRequestSenderModel.fbeType)) {
      return this.send_CalendarRegularRuleGetRequest(value)
    }
    if ((value instanceof CalendarRegularRuleGetResponse) && (value.fbeType === this.CalendarRegularRuleGetResponseSenderModel.fbeType)) {
      return this.send_CalendarRegularRuleGetResponse(value)
    }
    if ((value instanceof CalendarRegularRuleUpdateNotify) && (value.fbeType === this.CalendarRegularRuleUpdateNotifySenderModel.fbeType)) {
      return this.send_CalendarRegularRuleUpdateNotify(value)
    }
    if ((value instanceof CalendarOffTimeRuleGetRequest) && (value.fbeType === this.CalendarOffTimeRuleGetRequestSenderModel.fbeType)) {
      return this.send_CalendarOffTimeRuleGetRequest(value)
    }
    if ((value instanceof CalendarOffTimeRuleGetResponse) && (value.fbeType === this.CalendarOffTimeRuleGetResponseSenderModel.fbeType)) {
      return this.send_CalendarOffTimeRuleGetResponse(value)
    }
    if ((value instanceof CalendarOffTimeRuleUpdateNotify) && (value.fbeType === this.CalendarOffTimeRuleUpdateNotifySenderModel.fbeType)) {
      return this.send_CalendarOffTimeRuleUpdateNotify(value)
    }
    if ((value instanceof CalendarRulesGetRequest) && (value.fbeType === this.CalendarRulesGetRequestSenderModel.fbeType)) {
      return this.send_CalendarRulesGetRequest(value)
    }
    if ((value instanceof CalendarRulesGetResponse) && (value.fbeType === this.CalendarRulesGetResponseSenderModel.fbeType)) {
      return this.send_CalendarRulesGetResponse(value)
    }
    if ((value instanceof ChartBarHistoryRangeRequest) && (value.fbeType === this.ChartBarHistoryRangeRequestSenderModel.fbeType)) {
      return this.send_ChartBarHistoryRangeRequest(value)
    }
    if ((value instanceof OrderMarginSubscribeRequest) && (value.fbeType === this.OrderMarginSubscribeRequestSenderModel.fbeType)) {
      return this.send_OrderMarginSubscribeRequest(value)
    }
    if ((value instanceof OrderMarginSubscribeResponse) && (value.fbeType === this.OrderMarginSubscribeResponseSenderModel.fbeType)) {
      return this.send_OrderMarginSubscribeResponse(value)
    }
    if ((value instanceof OrderMarginNotify) && (value.fbeType === this.OrderMarginNotifySenderModel.fbeType)) {
      return this.send_OrderMarginNotify(value)
    }
    if ((value instanceof OrderMarginUpdateSubscriptionRequest) && (value.fbeType === this.OrderMarginUpdateSubscriptionRequestSenderModel.fbeType)) {
      return this.send_OrderMarginUpdateSubscriptionRequest(value)
    }
    if ((value instanceof OrderMarginUnsubscribeRequest) && (value.fbeType === this.OrderMarginUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_OrderMarginUnsubscribeRequest(value)
    }
    if ((value instanceof OrderMarginUnsubscribeResponse) && (value.fbeType === this.OrderMarginUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_OrderMarginUnsubscribeResponse(value)
    }
    if ((value instanceof OrderMarginGetRequest) && (value.fbeType === this.OrderMarginGetRequestSenderModel.fbeType)) {
      return this.send_OrderMarginGetRequest(value)
    }
    if ((value instanceof OrderMarginGetResponse) && (value.fbeType === this.OrderMarginGetResponseSenderModel.fbeType)) {
      return this.send_OrderMarginGetResponse(value)
    }
    if ((value instanceof OrderStateInfoSubscribeRequest) && (value.fbeType === this.OrderStateInfoSubscribeRequestSenderModel.fbeType)) {
      return this.send_OrderStateInfoSubscribeRequest(value)
    }
    if ((value instanceof OrderStateInfoSubscribeResponse) && (value.fbeType === this.OrderStateInfoSubscribeResponseSenderModel.fbeType)) {
      return this.send_OrderStateInfoSubscribeResponse(value)
    }
    if ((value instanceof OrderStateInfoUnsubscribeRequest) && (value.fbeType === this.OrderStateInfoUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_OrderStateInfoUnsubscribeRequest(value)
    }
    if ((value instanceof OrderStateInfoUnsubscribeResponse) && (value.fbeType === this.OrderStateInfoUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_OrderStateInfoUnsubscribeResponse(value)
    }
    if ((value instanceof OrderStateInfoNotify) && (value.fbeType === this.OrderStateInfoNotifySenderModel.fbeType)) {
      return this.send_OrderStateInfoNotify(value)
    }
    if ((value instanceof Deprecated003) && (value.fbeType === this.Deprecated003SenderModel.fbeType)) {
      return this.send_Deprecated003(value)
    }
    if ((value instanceof Deprecated004) && (value.fbeType === this.Deprecated004SenderModel.fbeType)) {
      return this.send_Deprecated004(value)
    }
    if ((value instanceof Deprecated005) && (value.fbeType === this.Deprecated005SenderModel.fbeType)) {
      return this.send_Deprecated005(value)
    }
    if ((value instanceof Deprecated006) && (value.fbeType === this.Deprecated006SenderModel.fbeType)) {
      return this.send_Deprecated006(value)
    }
    if ((value instanceof Deprecated007) && (value.fbeType === this.Deprecated007SenderModel.fbeType)) {
      return this.send_Deprecated007(value)
    }
    if ((value instanceof Deprecated008) && (value.fbeType === this.Deprecated008SenderModel.fbeType)) {
      return this.send_Deprecated008(value)
    }
    if ((value instanceof AccountUpdateRequest) && (value.fbeType === this.AccountUpdateRequestSenderModel.fbeType)) {
      return this.send_AccountUpdateRequest(value)
    }
    if ((value instanceof AccountUpdateResponse) && (value.fbeType === this.AccountUpdateResponseSenderModel.fbeType)) {
      return this.send_AccountUpdateResponse(value)
    }
    if ((value instanceof ProfitClosedInfoGetRequest) && (value.fbeType === this.ProfitClosedInfoGetRequestSenderModel.fbeType)) {
      return this.send_ProfitClosedInfoGetRequest(value)
    }
    if ((value instanceof ProfitClosedInfoGetResponse) && (value.fbeType === this.ProfitClosedInfoGetResponseSenderModel.fbeType)) {
      return this.send_ProfitClosedInfoGetResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoSubscribeRequest) && (value.fbeType === this.SymbolDayProfitLossInfoSubscribeRequestSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoSubscribeRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoSubscribeResponse) && (value.fbeType === this.SymbolDayProfitLossInfoSubscribeResponseSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoSubscribeResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoUpdateSubscriptionRequest) && (value.fbeType === this.SymbolDayProfitLossInfoUpdateSubscriptionRequestSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoUpdateSubscriptionRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoUnsubscribeRequest) && (value.fbeType === this.SymbolDayProfitLossInfoUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoUnsubscribeRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoUnsubscribeResponse) && (value.fbeType === this.SymbolDayProfitLossInfoUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoUnsubscribeResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossInfoNotify) && (value.fbeType === this.SymbolDayProfitLossInfoNotifySenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossInfoNotify(value)
    }
    if ((value instanceof LoginInfoGetRequest) && (value.fbeType === this.LoginInfoGetRequestSenderModel.fbeType)) {
      return this.send_LoginInfoGetRequest(value)
    }
    if ((value instanceof LoginInfoGetResponse) && (value.fbeType === this.LoginInfoGetResponseSenderModel.fbeType)) {
      return this.send_LoginInfoGetResponse(value)
    }
    if ((value instanceof DashboardInfoCreateRequest) && (value.fbeType === this.DashboardInfoCreateRequestSenderModel.fbeType)) {
      return this.send_DashboardInfoCreateRequest(value)
    }
    if ((value instanceof DashboardInfoCreateResponse) && (value.fbeType === this.DashboardInfoCreateResponseSenderModel.fbeType)) {
      return this.send_DashboardInfoCreateResponse(value)
    }
    if ((value instanceof DashboardInfoUpdateRequest) && (value.fbeType === this.DashboardInfoUpdateRequestSenderModel.fbeType)) {
      return this.send_DashboardInfoUpdateRequest(value)
    }
    if ((value instanceof DashboardInfoUpdateResponse) && (value.fbeType === this.DashboardInfoUpdateResponseSenderModel.fbeType)) {
      return this.send_DashboardInfoUpdateResponse(value)
    }
    if ((value instanceof DashboardInfoListRequest) && (value.fbeType === this.DashboardInfoListRequestSenderModel.fbeType)) {
      return this.send_DashboardInfoListRequest(value)
    }
    if ((value instanceof DashboardInfoListResponse) && (value.fbeType === this.DashboardInfoListResponseSenderModel.fbeType)) {
      return this.send_DashboardInfoListResponse(value)
    }
    if ((value instanceof DashboardInfoGetRequest) && (value.fbeType === this.DashboardInfoGetRequestSenderModel.fbeType)) {
      return this.send_DashboardInfoGetRequest(value)
    }
    if ((value instanceof DashboardInfoGetResponse) && (value.fbeType === this.DashboardInfoGetResponseSenderModel.fbeType)) {
      return this.send_DashboardInfoGetResponse(value)
    }
    if ((value instanceof DashboardInfoRenameRequest) && (value.fbeType === this.DashboardInfoRenameRequestSenderModel.fbeType)) {
      return this.send_DashboardInfoRenameRequest(value)
    }
    if ((value instanceof DashboardInfoRenameResponse) && (value.fbeType === this.DashboardInfoRenameResponseSenderModel.fbeType)) {
      return this.send_DashboardInfoRenameResponse(value)
    }
    if ((value instanceof DashboardInfoDeleteRequest) && (value.fbeType === this.DashboardInfoDeleteRequestSenderModel.fbeType)) {
      return this.send_DashboardInfoDeleteRequest(value)
    }
    if ((value instanceof DashboardInfoDeleteResponse) && (value.fbeType === this.DashboardInfoDeleteResponseSenderModel.fbeType)) {
      return this.send_DashboardInfoDeleteResponse(value)
    }
    if ((value instanceof ChartInfoCreateRequest) && (value.fbeType === this.ChartInfoCreateRequestSenderModel.fbeType)) {
      return this.send_ChartInfoCreateRequest(value)
    }
    if ((value instanceof ChartInfoCreateResponse) && (value.fbeType === this.ChartInfoCreateResponseSenderModel.fbeType)) {
      return this.send_ChartInfoCreateResponse(value)
    }
    if ((value instanceof ChartInfoUpdateRequest) && (value.fbeType === this.ChartInfoUpdateRequestSenderModel.fbeType)) {
      return this.send_ChartInfoUpdateRequest(value)
    }
    if ((value instanceof ChartInfoUpdateResponse) && (value.fbeType === this.ChartInfoUpdateResponseSenderModel.fbeType)) {
      return this.send_ChartInfoUpdateResponse(value)
    }
    if ((value instanceof ChartInfoListRequest) && (value.fbeType === this.ChartInfoListRequestSenderModel.fbeType)) {
      return this.send_ChartInfoListRequest(value)
    }
    if ((value instanceof ChartInfoListResponse) && (value.fbeType === this.ChartInfoListResponseSenderModel.fbeType)) {
      return this.send_ChartInfoListResponse(value)
    }
    if ((value instanceof ChartInfoDeleteRequest) && (value.fbeType === this.ChartInfoDeleteRequestSenderModel.fbeType)) {
      return this.send_ChartInfoDeleteRequest(value)
    }
    if ((value instanceof ChartInfoDeleteResponse) && (value.fbeType === this.ChartInfoDeleteResponseSenderModel.fbeType)) {
      return this.send_ChartInfoDeleteResponse(value)
    }
    if ((value instanceof ChartInfoGetRequest) && (value.fbeType === this.ChartInfoGetRequestSenderModel.fbeType)) {
      return this.send_ChartInfoGetRequest(value)
    }
    if ((value instanceof ChartInfoGetResponse) && (value.fbeType === this.ChartInfoGetResponseSenderModel.fbeType)) {
      return this.send_ChartInfoGetResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoSubscribeRequest) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoSubscribeRequestSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoSubscribeRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoSubscribeResponse) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoSubscribeResponseSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoSubscribeResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoUnsubscribeRequest) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoUnsubscribeRequest(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoUnsubscribeResponse) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoUnsubscribeResponse(value)
    }
    if ((value instanceof SymbolDayProfitLossMultipleInfoNotify) && (value.fbeType === this.SymbolDayProfitLossMultipleInfoNotifySenderModel.fbeType)) {
      return this.send_SymbolDayProfitLossMultipleInfoNotify(value)
    }
    if ((value instanceof NewsServiceGetAllRequest) && (value.fbeType === this.NewsServiceGetAllRequestSenderModel.fbeType)) {
      return this.send_NewsServiceGetAllRequest(value)
    }
    if ((value instanceof NewsServiceGetAllResponse) && (value.fbeType === this.NewsServiceGetAllResponseSenderModel.fbeType)) {
      return this.send_NewsServiceGetAllResponse(value)
    }
    if ((value instanceof NewsRecentArticlesGetRequest) && (value.fbeType === this.NewsRecentArticlesGetRequestSenderModel.fbeType)) {
      return this.send_NewsRecentArticlesGetRequest(value)
    }
    if ((value instanceof NewsRecentArticlesGetResponse) && (value.fbeType === this.NewsRecentArticlesGetResponseSenderModel.fbeType)) {
      return this.send_NewsRecentArticlesGetResponse(value)
    }
    if ((value instanceof NewsArticleGetRequest) && (value.fbeType === this.NewsArticleGetRequestSenderModel.fbeType)) {
      return this.send_NewsArticleGetRequest(value)
    }
    if ((value instanceof NewsArticleGetResponse) && (value.fbeType === this.NewsArticleGetResponseSenderModel.fbeType)) {
      return this.send_NewsArticleGetResponse(value)
    }
    if ((value instanceof OrderUpdateWithTypeRequest) && (value.fbeType === this.OrderUpdateWithTypeRequestSenderModel.fbeType)) {
      return this.send_OrderUpdateWithTypeRequest(value)
    }
    if ((value instanceof OrderUpdateWithTypeResponse) && (value.fbeType === this.OrderUpdateWithTypeResponseSenderModel.fbeType)) {
      return this.send_OrderUpdateWithTypeResponse(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoSubscribeRequest) && (value.fbeType === this.SymbolProfitLossMultipleInfoSubscribeRequestSenderModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoSubscribeRequest(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoSubscribeResponse) && (value.fbeType === this.SymbolProfitLossMultipleInfoSubscribeResponseSenderModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoSubscribeResponse(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoUpdateSubscriptionRequest) && (value.fbeType === this.SymbolProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoUnsubscribeRequest) && (value.fbeType === this.SymbolProfitLossMultipleInfoUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoUnsubscribeRequest(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoUnsubscribeResponse) && (value.fbeType === this.SymbolProfitLossMultipleInfoUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoUnsubscribeResponse(value)
    }
    if ((value instanceof SymbolProfitLossMultipleInfoNotify) && (value.fbeType === this.SymbolProfitLossMultipleInfoNotifySenderModel.fbeType)) {
      return this.send_SymbolProfitLossMultipleInfoNotify(value)
    }
    if ((value instanceof LoginWithPasswordRequest) && (value.fbeType === this.LoginWithPasswordRequestSenderModel.fbeType)) {
      return this.send_LoginWithPasswordRequest(value)
    }
    if ((value instanceof LoginWithPasswordResponse) && (value.fbeType === this.LoginWithPasswordResponseSenderModel.fbeType)) {
      return this.send_LoginWithPasswordResponse(value)
    }
    if ((value instanceof LoginWithTokenRequest) && (value.fbeType === this.LoginWithTokenRequestSenderModel.fbeType)) {
      return this.send_LoginWithTokenRequest(value)
    }
    if ((value instanceof LoginWithTokenResponse) && (value.fbeType === this.LoginWithTokenResponseSenderModel.fbeType)) {
      return this.send_LoginWithTokenResponse(value)
    }
    if ((value instanceof RefreshTokenRequest) && (value.fbeType === this.RefreshTokenRequestSenderModel.fbeType)) {
      return this.send_RefreshTokenRequest(value)
    }
    if ((value instanceof RefreshTokenResponse) && (value.fbeType === this.RefreshTokenResponseSenderModel.fbeType)) {
      return this.send_RefreshTokenResponse(value)
    }
    if ((value instanceof Logout2Request) && (value.fbeType === this.Logout2RequestSenderModel.fbeType)) {
      return this.send_Logout2Request(value)
    }
    if ((value instanceof Logout2Response) && (value.fbeType === this.Logout2ResponseSenderModel.fbeType)) {
      return this.send_Logout2Response(value)
    }
    if ((value instanceof AccountChangePasswordRequest) && (value.fbeType === this.AccountChangePasswordRequestSenderModel.fbeType)) {
      return this.send_AccountChangePasswordRequest(value)
    }
    if ((value instanceof AccountChangePasswordResponse) && (value.fbeType === this.AccountChangePasswordResponseSenderModel.fbeType)) {
      return this.send_AccountChangePasswordResponse(value)
    }
    if ((value instanceof PushNotificationTokenRegisterRequest) && (value.fbeType === this.PushNotificationTokenRegisterRequestSenderModel.fbeType)) {
      return this.send_PushNotificationTokenRegisterRequest(value)
    }
    if ((value instanceof PushNotificationTokenRegisterResponse) && (value.fbeType === this.PushNotificationTokenRegisterResponseSenderModel.fbeType)) {
      return this.send_PushNotificationTokenRegisterResponse(value)
    }
    if ((value instanceof DesiredAppSettingsGetRequest) && (value.fbeType === this.DesiredAppSettingsGetRequestSenderModel.fbeType)) {
      return this.send_DesiredAppSettingsGetRequest(value)
    }
    if ((value instanceof DesiredAppSettingsGetResponse) && (value.fbeType === this.DesiredAppSettingsGetResponseSenderModel.fbeType)) {
      return this.send_DesiredAppSettingsGetResponse(value)
    }
    if ((value instanceof AuthTokensGetAllRequest) && (value.fbeType === this.AuthTokensGetAllRequestSenderModel.fbeType)) {
      return this.send_AuthTokensGetAllRequest(value)
    }
    if ((value instanceof AuthTokensGetAllResponse) && (value.fbeType === this.AuthTokensGetAllResponseSenderModel.fbeType)) {
      return this.send_AuthTokensGetAllResponse(value)
    }
    if ((value instanceof AuthTokensDeleteRequest) && (value.fbeType === this.AuthTokensDeleteRequestSenderModel.fbeType)) {
      return this.send_AuthTokensDeleteRequest(value)
    }
    if ((value instanceof AuthTokensDeleteResponse) && (value.fbeType === this.AuthTokensDeleteResponseSenderModel.fbeType)) {
      return this.send_AuthTokensDeleteResponse(value)
    }
    if ((value instanceof LegalDocumentGroupListRequest) && (value.fbeType === this.LegalDocumentGroupListRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupListRequest(value)
    }
    if ((value instanceof LegalDocumentGroupListResponse) && (value.fbeType === this.LegalDocumentGroupListResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentGroupListResponse(value)
    }
    if ((value instanceof LegalDocumentGetRequest) && (value.fbeType === this.LegalDocumentGetRequestSenderModel.fbeType)) {
      return this.send_LegalDocumentGetRequest(value)
    }
    if ((value instanceof LegalDocumentGetResponse) && (value.fbeType === this.LegalDocumentGetResponseSenderModel.fbeType)) {
      return this.send_LegalDocumentGetResponse(value)
    }
    if ((value instanceof PushNotificationTokenUnRegisterRequest) && (value.fbeType === this.PushNotificationTokenUnRegisterRequestSenderModel.fbeType)) {
      return this.send_PushNotificationTokenUnRegisterRequest(value)
    }
    if ((value instanceof PushNotificationTokenUnRegisterResponse) && (value.fbeType === this.PushNotificationTokenUnRegisterResponseSenderModel.fbeType)) {
      return this.send_PushNotificationTokenUnRegisterResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._exposedClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send PingRequest value
   * @this {!Client}
   * @param {!PingRequest} value PingRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PingRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PingRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PingRequest serialization failed!')
    console.assert(this.PingRequestSenderModel.verify(), 'client.PingRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PongResponse value
   * @this {!Client}
   * @param {!PongResponse} value PongResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PongResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PongResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PongResponse serialization failed!')
    console.assert(this.PongResponseSenderModel.verify(), 'client.PongResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginRequest value
   * @this {!Client}
   * @param {!LoginRequest} value LoginRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginRequest serialization failed!')
    console.assert(this.LoginRequestSenderModel.verify(), 'client.LoginRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginResponse value
   * @this {!Client}
   * @param {!LoginResponse} value LoginResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginResponse serialization failed!')
    console.assert(this.LoginResponseSenderModel.verify(), 'client.LoginResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginReject value
   * @this {!Client}
   * @param {!LoginReject} value LoginReject value to send
   * @returns {!number} Sent bytes
   */
  send_LoginReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginRejectSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginReject serialization failed!')
    console.assert(this.LoginRejectSenderModel.verify(), 'client.LoginReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LogoutRequest value
   * @this {!Client}
   * @param {!LogoutRequest} value LogoutRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LogoutRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LogoutRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LogoutRequest serialization failed!')
    console.assert(this.LogoutRequestSenderModel.verify(), 'client.LogoutRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LogoutResponse value
   * @this {!Client}
   * @param {!LogoutResponse} value LogoutResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LogoutResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LogoutResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LogoutResponse serialization failed!')
    console.assert(this.LogoutResponseSenderModel.verify(), 'client.LogoutResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LogoutNotify value
   * @this {!Client}
   * @param {!LogoutNotify} value LogoutNotify value to send
   * @returns {!number} Sent bytes
   */
  send_LogoutNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LogoutNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LogoutNotify serialization failed!')
    console.assert(this.LogoutNotifySenderModel.verify(), 'client.LogoutNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SessionInfoRequest value
   * @this {!Client}
   * @param {!SessionInfoRequest} value SessionInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SessionInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SessionInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SessionInfoRequest serialization failed!')
    console.assert(this.SessionInfoRequestSenderModel.verify(), 'client.SessionInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SessionInfoResponse value
   * @this {!Client}
   * @param {!SessionInfoResponse} value SessionInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SessionInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SessionInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SessionInfoResponse serialization failed!')
    console.assert(this.SessionInfoResponseSenderModel.verify(), 'client.SessionInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimeInfoRequest value
   * @this {!Client}
   * @param {!TimeInfoRequest} value TimeInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimeInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimeInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TimeInfoRequest serialization failed!')
    console.assert(this.TimeInfoRequestSenderModel.verify(), 'client.TimeInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimeInfoResponse value
   * @this {!Client}
   * @param {!TimeInfoResponse} value TimeInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimeInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimeInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TimeInfoResponse serialization failed!')
    console.assert(this.TimeInfoResponseSenderModel.verify(), 'client.TimeInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneInfoRequest value
   * @this {!Client}
   * @param {!TimezoneInfoRequest} value TimezoneInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TimezoneInfoRequest serialization failed!')
    console.assert(this.TimezoneInfoRequestSenderModel.verify(), 'client.TimezoneInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneInfoResponse value
   * @this {!Client}
   * @param {!TimezoneInfoResponse} value TimezoneInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TimezoneInfoResponse serialization failed!')
    console.assert(this.TimezoneInfoResponseSenderModel.verify(), 'client.TimezoneInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TimezoneInfoUpdateNotify value
   * @this {!Client}
   * @param {!TimezoneInfoUpdateNotify} value TimezoneInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TimezoneInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TimezoneInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TimezoneInfoUpdateNotify serialization failed!')
    console.assert(this.TimezoneInfoUpdateNotifySenderModel.verify(), 'client.TimezoneInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyInfoRequest value
   * @this {!Client}
   * @param {!CurrencyInfoRequest} value CurrencyInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyInfoRequest serialization failed!')
    console.assert(this.CurrencyInfoRequestSenderModel.verify(), 'client.CurrencyInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyInfoResponse value
   * @this {!Client}
   * @param {!CurrencyInfoResponse} value CurrencyInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyInfoResponse serialization failed!')
    console.assert(this.CurrencyInfoResponseSenderModel.verify(), 'client.CurrencyInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyListRequest value
   * @this {!Client}
   * @param {!CurrencyListRequest} value CurrencyListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyListRequest serialization failed!')
    console.assert(this.CurrencyListRequestSenderModel.verify(), 'client.CurrencyListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyListResponse value
   * @this {!Client}
   * @param {!CurrencyListResponse} value CurrencyListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyListResponse serialization failed!')
    console.assert(this.CurrencyListResponseSenderModel.verify(), 'client.CurrencyListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyInfoUpdateNotify value
   * @this {!Client}
   * @param {!CurrencyInfoUpdateNotify} value CurrencyInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CurrencyInfoUpdateNotify serialization failed!')
    console.assert(this.CurrencyInfoUpdateNotifySenderModel.verify(), 'client.CurrencyInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolInfoRequest value
   * @this {!Client}
   * @param {!SymbolInfoRequest} value SymbolInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolInfoRequest serialization failed!')
    console.assert(this.SymbolInfoRequestSenderModel.verify(), 'client.SymbolInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolInfoResponse value
   * @this {!Client}
   * @param {!SymbolInfoResponse} value SymbolInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolInfoResponse serialization failed!')
    console.assert(this.SymbolInfoResponseSenderModel.verify(), 'client.SymbolInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolListRequest value
   * @this {!Client}
   * @param {!SymbolListRequest} value SymbolListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolListRequest serialization failed!')
    console.assert(this.SymbolListRequestSenderModel.verify(), 'client.SymbolListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolListResponse value
   * @this {!Client}
   * @param {!SymbolListResponse} value SymbolListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolListResponse serialization failed!')
    console.assert(this.SymbolListResponseSenderModel.verify(), 'client.SymbolListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolInfoUpdateNotify value
   * @this {!Client}
   * @param {!SymbolInfoUpdateNotify} value SymbolInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolInfoUpdateNotify serialization failed!')
    console.assert(this.SymbolInfoUpdateNotifySenderModel.verify(), 'client.SymbolInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickInfoRequest value
   * @this {!Client}
   * @param {!TickInfoRequest} value TickInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TickInfoRequest serialization failed!')
    console.assert(this.TickInfoRequestSenderModel.verify(), 'client.TickInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickInfoResponse value
   * @this {!Client}
   * @param {!TickInfoResponse} value TickInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TickInfoResponse serialization failed!')
    console.assert(this.TickInfoResponseSenderModel.verify(), 'client.TickInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickFilterRequest value
   * @this {!Client}
   * @param {!TickFilterRequest} value TickFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickFilterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TickFilterRequest serialization failed!')
    console.assert(this.TickFilterRequestSenderModel.verify(), 'client.TickFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickFilterResponse value
   * @this {!Client}
   * @param {!TickFilterResponse} value TickFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickFilterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TickFilterResponse serialization failed!')
    console.assert(this.TickFilterResponseSenderModel.verify(), 'client.TickFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickListRequest value
   * @this {!Client}
   * @param {!TickListRequest} value TickListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TickListRequest serialization failed!')
    console.assert(this.TickListRequestSenderModel.verify(), 'client.TickListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickListResponse value
   * @this {!Client}
   * @param {!TickListResponse} value TickListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TickListResponse serialization failed!')
    console.assert(this.TickListResponseSenderModel.verify(), 'client.TickListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2InfoRequest value
   * @this {!Client}
   * @param {!Level2InfoRequest} value Level2InfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2InfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2InfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2InfoRequest serialization failed!')
    console.assert(this.Level2InfoRequestSenderModel.verify(), 'client.Level2InfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2InfoResponse value
   * @this {!Client}
   * @param {!Level2InfoResponse} value Level2InfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2InfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2InfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2InfoResponse serialization failed!')
    console.assert(this.Level2InfoResponseSenderModel.verify(), 'client.Level2InfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2FilterRequest value
   * @this {!Client}
   * @param {!Level2FilterRequest} value Level2FilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2FilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2FilterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2FilterRequest serialization failed!')
    console.assert(this.Level2FilterRequestSenderModel.verify(), 'client.Level2FilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2FilterResponse value
   * @this {!Client}
   * @param {!Level2FilterResponse} value Level2FilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2FilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2FilterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2FilterResponse serialization failed!')
    console.assert(this.Level2FilterResponseSenderModel.verify(), 'client.Level2FilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2ListRequest value
   * @this {!Client}
   * @param {!Level2ListRequest} value Level2ListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2ListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2ListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2ListRequest serialization failed!')
    console.assert(this.Level2ListRequestSenderModel.verify(), 'client.Level2ListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2ListResponse value
   * @this {!Client}
   * @param {!Level2ListResponse} value Level2ListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2ListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2ListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2ListResponse serialization failed!')
    console.assert(this.Level2ListResponseSenderModel.verify(), 'client.Level2ListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscriptionInfoRequest value
   * @this {!Client}
   * @param {!FeedSubscriptionInfoRequest} value FeedSubscriptionInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscriptionInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscriptionInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscriptionInfoRequest serialization failed!')
    console.assert(this.FeedSubscriptionInfoRequestSenderModel.verify(), 'client.FeedSubscriptionInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscriptionInfoResponse value
   * @this {!Client}
   * @param {!FeedSubscriptionInfoResponse} value FeedSubscriptionInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscriptionInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscriptionInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscriptionInfoResponse serialization failed!')
    console.assert(this.FeedSubscriptionInfoResponseSenderModel.verify(), 'client.FeedSubscriptionInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeRequest value
   * @this {!Client}
   * @param {!FeedSubscribeRequest} value FeedSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscribeRequest serialization failed!')
    console.assert(this.FeedSubscribeRequestSenderModel.verify(), 'client.FeedSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeAllRequest value
   * @this {!Client}
   * @param {!FeedSubscribeAllRequest} value FeedSubscribeAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscribeAllRequest serialization failed!')
    console.assert(this.FeedSubscribeAllRequestSenderModel.verify(), 'client.FeedSubscribeAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeResponse value
   * @this {!Client}
   * @param {!FeedSubscribeResponse} value FeedSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedSubscribeResponse serialization failed!')
    console.assert(this.FeedSubscribeResponseSenderModel.verify(), 'client.FeedSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeRequest value
   * @this {!Client}
   * @param {!FeedUnsubscribeRequest} value FeedUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedUnsubscribeRequest serialization failed!')
    console.assert(this.FeedUnsubscribeRequestSenderModel.verify(), 'client.FeedUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeAllRequest value
   * @this {!Client}
   * @param {!FeedUnsubscribeAllRequest} value FeedUnsubscribeAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedUnsubscribeAllRequest serialization failed!')
    console.assert(this.FeedUnsubscribeAllRequestSenderModel.verify(), 'client.FeedUnsubscribeAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeResponse value
   * @this {!Client}
   * @param {!FeedUnsubscribeResponse} value FeedUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.FeedUnsubscribeResponse serialization failed!')
    console.assert(this.FeedUnsubscribeResponseSenderModel.verify(), 'client.FeedUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickSnapshotNotify value
   * @this {!Client}
   * @param {!TickSnapshotNotify} value TickSnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TickSnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickSnapshotNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TickSnapshotNotify serialization failed!')
    console.assert(this.TickSnapshotNotifySenderModel.verify(), 'client.TickSnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickUpdateNotify value
   * @this {!Client}
   * @param {!TickUpdateNotify} value TickUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TickUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TickUpdateNotify serialization failed!')
    console.assert(this.TickUpdateNotifySenderModel.verify(), 'client.TickUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2SnapshotNotify value
   * @this {!Client}
   * @param {!Level2SnapshotNotify} value Level2SnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_Level2SnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2SnapshotNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2SnapshotNotify serialization failed!')
    console.assert(this.Level2SnapshotNotifySenderModel.verify(), 'client.Level2SnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2UpdateNotify value
   * @this {!Client}
   * @param {!Level2UpdateNotify} value Level2UpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_Level2UpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2UpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Level2UpdateNotify serialization failed!')
    console.assert(this.Level2UpdateNotifySenderModel.verify(), 'client.Level2UpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryRequest value
   * @this {!Client}
   * @param {!ChartBarHistoryRequest} value ChartBarHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarHistoryRequest serialization failed!')
    console.assert(this.ChartBarHistoryRequestSenderModel.verify(), 'client.ChartBarHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryResponse value
   * @this {!Client}
   * @param {!ChartBarHistoryResponse} value ChartBarHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarHistoryResponse serialization failed!')
    console.assert(this.ChartBarHistoryResponseSenderModel.verify(), 'client.ChartBarHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarSubscribeRequest value
   * @this {!Client}
   * @param {!ChartBarSubscribeRequest} value ChartBarSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarSubscribeRequest serialization failed!')
    console.assert(this.ChartBarSubscribeRequestSenderModel.verify(), 'client.ChartBarSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarSubscribeResponse value
   * @this {!Client}
   * @param {!ChartBarSubscribeResponse} value ChartBarSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarSubscribeResponse serialization failed!')
    console.assert(this.ChartBarSubscribeResponseSenderModel.verify(), 'client.ChartBarSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarUnsubscribeRequest value
   * @this {!Client}
   * @param {!ChartBarUnsubscribeRequest} value ChartBarUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarUnsubscribeRequest serialization failed!')
    console.assert(this.ChartBarUnsubscribeRequestSenderModel.verify(), 'client.ChartBarUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarUnsubscribeResponse value
   * @this {!Client}
   * @param {!ChartBarUnsubscribeResponse} value ChartBarUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarUnsubscribeResponse serialization failed!')
    console.assert(this.ChartBarUnsubscribeResponseSenderModel.verify(), 'client.ChartBarUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarNotify value
   * @this {!Client}
   * @param {!ChartBarNotify} value ChartBarNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarNotify serialization failed!')
    console.assert(this.ChartBarNotifySenderModel.verify(), 'client.ChartBarNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarUpdateNotify value
   * @this {!Client}
   * @param {!ChartBarUpdateNotify} value ChartBarUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarUpdateNotify serialization failed!')
    console.assert(this.ChartBarUpdateNotifySenderModel.verify(), 'client.ChartBarUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformInfoRequest value
   * @this {!Client}
   * @param {!PlatformInfoRequest} value PlatformInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PlatformInfoRequest serialization failed!')
    console.assert(this.PlatformInfoRequestSenderModel.verify(), 'client.PlatformInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformInfoResponse value
   * @this {!Client}
   * @param {!PlatformInfoResponse} value PlatformInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PlatformInfoResponse serialization failed!')
    console.assert(this.PlatformInfoResponseSenderModel.verify(), 'client.PlatformInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PlatformInfoUpdateNotify value
   * @this {!Client}
   * @param {!PlatformInfoUpdateNotify} value PlatformInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PlatformInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PlatformInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PlatformInfoUpdateNotify serialization failed!')
    console.assert(this.PlatformInfoUpdateNotifySenderModel.verify(), 'client.PlatformInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInfoRequest value
   * @this {!Client}
   * @param {!AccountInfoRequest} value AccountInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountInfoRequest serialization failed!')
    console.assert(this.AccountInfoRequestSenderModel.verify(), 'client.AccountInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInfoResponse value
   * @this {!Client}
   * @param {!AccountInfoResponse} value AccountInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountInfoResponse serialization failed!')
    console.assert(this.AccountInfoResponseSenderModel.verify(), 'client.AccountInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInfoUpdateNotify value
   * @this {!Client}
   * @param {!AccountInfoUpdateNotify} value AccountInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountInfoUpdateNotify serialization failed!')
    console.assert(this.AccountInfoUpdateNotifySenderModel.verify(), 'client.AccountInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginInfoRequest value
   * @this {!Client}
   * @param {!MarginInfoRequest} value MarginInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.MarginInfoRequest serialization failed!')
    console.assert(this.MarginInfoRequestSenderModel.verify(), 'client.MarginInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginInfoResponse value
   * @this {!Client}
   * @param {!MarginInfoResponse} value MarginInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.MarginInfoResponse serialization failed!')
    console.assert(this.MarginInfoResponseSenderModel.verify(), 'client.MarginInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginInfoUpdateNotify value
   * @this {!Client}
   * @param {!MarginInfoUpdateNotify} value MarginInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.MarginInfoUpdateNotify serialization failed!')
    console.assert(this.MarginInfoUpdateNotifySenderModel.verify(), 'client.MarginInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginCallNotify value
   * @this {!Client}
   * @param {!MarginCallNotify} value MarginCallNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginCallNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginCallNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.MarginCallNotify serialization failed!')
    console.assert(this.MarginCallNotifySenderModel.verify(), 'client.MarginCallNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StopOutNotify value
   * @this {!Client}
   * @param {!StopOutNotify} value StopOutNotify value to send
   * @returns {!number} Sent bytes
   */
  send_StopOutNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StopOutNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.StopOutNotify serialization failed!')
    console.assert(this.StopOutNotifySenderModel.verify(), 'client.StopOutNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInfoRequest value
   * @this {!Client}
   * @param {!AssetInfoRequest} value AssetInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetInfoRequest serialization failed!')
    console.assert(this.AssetInfoRequestSenderModel.verify(), 'client.AssetInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInfoResponse value
   * @this {!Client}
   * @param {!AssetInfoResponse} value AssetInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetInfoResponse serialization failed!')
    console.assert(this.AssetInfoResponseSenderModel.verify(), 'client.AssetInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetListRequest value
   * @this {!Client}
   * @param {!AssetListRequest} value AssetListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetListRequest serialization failed!')
    console.assert(this.AssetListRequestSenderModel.verify(), 'client.AssetListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetListResponse value
   * @this {!Client}
   * @param {!AssetListResponse} value AssetListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetListResponse serialization failed!')
    console.assert(this.AssetListResponseSenderModel.verify(), 'client.AssetListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInfoUpdateNotify value
   * @this {!Client}
   * @param {!AssetInfoUpdateNotify} value AssetInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AssetInfoUpdateNotify serialization failed!')
    console.assert(this.AssetInfoUpdateNotifySenderModel.verify(), 'client.AssetInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInfoRequest value
   * @this {!Client}
   * @param {!OrderInfoRequest} value OrderInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderInfoRequest serialization failed!')
    console.assert(this.OrderInfoRequestSenderModel.verify(), 'client.OrderInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInfoResponse value
   * @this {!Client}
   * @param {!OrderInfoResponse} value OrderInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderInfoResponse serialization failed!')
    console.assert(this.OrderInfoResponseSenderModel.verify(), 'client.OrderInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderListRequest value
   * @this {!Client}
   * @param {!OrderListRequest} value OrderListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderListRequest serialization failed!')
    console.assert(this.OrderListRequestSenderModel.verify(), 'client.OrderListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderListResponse value
   * @this {!Client}
   * @param {!OrderListResponse} value OrderListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderListResponse serialization failed!')
    console.assert(this.OrderListResponseSenderModel.verify(), 'client.OrderListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderOpenRequest value
   * @this {!Client}
   * @param {!OrderOpenRequest} value OrderOpenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderOpenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderOpenRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderOpenRequest serialization failed!')
    console.assert(this.OrderOpenRequestSenderModel.verify(), 'client.OrderOpenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderOpenResponse value
   * @this {!Client}
   * @param {!OrderOpenResponse} value OrderOpenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderOpenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderOpenResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderOpenResponse serialization failed!')
    console.assert(this.OrderOpenResponseSenderModel.verify(), 'client.OrderOpenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateRequest value
   * @this {!Client}
   * @param {!OrderUpdateRequest} value OrderUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderUpdateRequest serialization failed!')
    console.assert(this.OrderUpdateRequestSenderModel.verify(), 'client.OrderUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateResponse value
   * @this {!Client}
   * @param {!OrderUpdateResponse} value OrderUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderUpdateResponse serialization failed!')
    console.assert(this.OrderUpdateResponseSenderModel.verify(), 'client.OrderUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelRequest value
   * @this {!Client}
   * @param {!OrderCancelRequest} value OrderCancelRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelRequest serialization failed!')
    console.assert(this.OrderCancelRequestSenderModel.verify(), 'client.OrderCancelRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelResponse value
   * @this {!Client}
   * @param {!OrderCancelResponse} value OrderCancelResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelResponse serialization failed!')
    console.assert(this.OrderCancelResponseSenderModel.verify(), 'client.OrderCancelResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllRequest value
   * @this {!Client}
   * @param {!OrderCancelAllRequest} value OrderCancelAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelAllRequest serialization failed!')
    console.assert(this.OrderCancelAllRequestSenderModel.verify(), 'client.OrderCancelAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllResponse value
   * @this {!Client}
   * @param {!OrderCancelAllResponse} value OrderCancelAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelAllResponse serialization failed!')
    console.assert(this.OrderCancelAllResponseSenderModel.verify(), 'client.OrderCancelAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelAllByIdRequest value
   * @this {!Client}
   * @param {!OrderCancelAllByIdRequest} value OrderCancelAllByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelAllByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelAllByIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelAllByIdRequest serialization failed!')
    console.assert(this.OrderCancelAllByIdRequestSenderModel.verify(), 'client.OrderCancelAllByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseRequest value
   * @this {!Client}
   * @param {!OrderCloseRequest} value OrderCloseRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseRequest serialization failed!')
    console.assert(this.OrderCloseRequestSenderModel.verify(), 'client.OrderCloseRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseResponse value
   * @this {!Client}
   * @param {!OrderCloseResponse} value OrderCloseResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseResponse serialization failed!')
    console.assert(this.OrderCloseResponseSenderModel.verify(), 'client.OrderCloseResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllRequest value
   * @this {!Client}
   * @param {!OrderCloseAllRequest} value OrderCloseAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseAllRequest serialization failed!')
    console.assert(this.OrderCloseAllRequestSenderModel.verify(), 'client.OrderCloseAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllResponse value
   * @this {!Client}
   * @param {!OrderCloseAllResponse} value OrderCloseAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseAllResponse serialization failed!')
    console.assert(this.OrderCloseAllResponseSenderModel.verify(), 'client.OrderCloseAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseAllByIdRequest value
   * @this {!Client}
   * @param {!OrderCloseAllByIdRequest} value OrderCloseAllByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseAllByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseAllByIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseAllByIdRequest serialization failed!')
    console.assert(this.OrderCloseAllByIdRequestSenderModel.verify(), 'client.OrderCloseAllByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByRequest value
   * @this {!Client}
   * @param {!OrderCloseByRequest} value OrderCloseByRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseByRequest serialization failed!')
    console.assert(this.OrderCloseByRequestSenderModel.verify(), 'client.OrderCloseByRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByResponse value
   * @this {!Client}
   * @param {!OrderCloseByResponse} value OrderCloseByResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseByResponse serialization failed!')
    console.assert(this.OrderCloseByResponseSenderModel.verify(), 'client.OrderCloseByResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByAllRequest value
   * @this {!Client}
   * @param {!OrderCloseByAllRequest} value OrderCloseByAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseByAllRequest serialization failed!')
    console.assert(this.OrderCloseByAllRequestSenderModel.verify(), 'client.OrderCloseByAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCloseByAllResponse value
   * @this {!Client}
   * @param {!OrderCloseByAllResponse} value OrderCloseByAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCloseByAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCloseByAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCloseByAllResponse serialization failed!')
    console.assert(this.OrderCloseByAllResponseSenderModel.verify(), 'client.OrderCloseByAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseRequest value
   * @this {!Client}
   * @param {!OrderCancelOrCloseRequest} value OrderCancelOrCloseRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelOrCloseRequest serialization failed!')
    console.assert(this.OrderCancelOrCloseRequestSenderModel.verify(), 'client.OrderCancelOrCloseRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseResponse value
   * @this {!Client}
   * @param {!OrderCancelOrCloseResponse} value OrderCancelOrCloseResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelOrCloseResponse serialization failed!')
    console.assert(this.OrderCancelOrCloseResponseSenderModel.verify(), 'client.OrderCancelOrCloseResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseAllRequest value
   * @this {!Client}
   * @param {!OrderCancelOrCloseAllRequest} value OrderCancelOrCloseAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelOrCloseAllRequest serialization failed!')
    console.assert(this.OrderCancelOrCloseAllRequestSenderModel.verify(), 'client.OrderCancelOrCloseAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderCancelOrCloseAllResponse value
   * @this {!Client}
   * @param {!OrderCancelOrCloseAllResponse} value OrderCancelOrCloseAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderCancelOrCloseAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderCancelOrCloseAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderCancelOrCloseAllResponse serialization failed!')
    console.assert(this.OrderCancelOrCloseAllResponseSenderModel.verify(), 'client.OrderCancelOrCloseAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInfoUpdateNotify value
   * @this {!Client}
   * @param {!OrderInfoUpdateNotify} value OrderInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderInfoUpdateNotify serialization failed!')
    console.assert(this.OrderInfoUpdateNotifySenderModel.verify(), 'client.OrderInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInfoRequest value
   * @this {!Client}
   * @param {!PositionInfoRequest} value PositionInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionInfoRequest serialization failed!')
    console.assert(this.PositionInfoRequestSenderModel.verify(), 'client.PositionInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInfoResponse value
   * @this {!Client}
   * @param {!PositionInfoResponse} value PositionInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionInfoResponse serialization failed!')
    console.assert(this.PositionInfoResponseSenderModel.verify(), 'client.PositionInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionListRequest value
   * @this {!Client}
   * @param {!PositionListRequest} value PositionListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionListRequest serialization failed!')
    console.assert(this.PositionListRequestSenderModel.verify(), 'client.PositionListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionListResponse value
   * @this {!Client}
   * @param {!PositionListResponse} value PositionListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionListResponse serialization failed!')
    console.assert(this.PositionListResponseSenderModel.verify(), 'client.PositionListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInfoUpdateNotify value
   * @this {!Client}
   * @param {!PositionInfoUpdateNotify} value PositionInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PositionInfoUpdateNotify serialization failed!')
    console.assert(this.PositionInfoUpdateNotifySenderModel.verify(), 'client.PositionInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoRequest value
   * @this {!Client}
   * @param {!TradeHistoryInfoRequest} value TradeHistoryInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoRequest serialization failed!')
    console.assert(this.TradeHistoryInfoRequestSenderModel.verify(), 'client.TradeHistoryInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoResponse value
   * @this {!Client}
   * @param {!TradeHistoryInfoResponse} value TradeHistoryInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoResponse serialization failed!')
    console.assert(this.TradeHistoryInfoResponseSenderModel.verify(), 'client.TradeHistoryInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoFilterRequest value
   * @this {!Client}
   * @param {!TradeHistoryInfoFilterRequest} value TradeHistoryInfoFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoFilterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoFilterRequest serialization failed!')
    console.assert(this.TradeHistoryInfoFilterRequestSenderModel.verify(), 'client.TradeHistoryInfoFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoByIdRequest value
   * @this {!Client}
   * @param {!TradeHistoryInfoByIdRequest} value TradeHistoryInfoByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoByIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoByIdRequest serialization failed!')
    console.assert(this.TradeHistoryInfoByIdRequestSenderModel.verify(), 'client.TradeHistoryInfoByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoByIdResponse value
   * @this {!Client}
   * @param {!TradeHistoryInfoByIdResponse} value TradeHistoryInfoByIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoByIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoByIdResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoByIdResponse serialization failed!')
    console.assert(this.TradeHistoryInfoByIdResponseSenderModel.verify(), 'client.TradeHistoryInfoByIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryInfoUpdateNotify value
   * @this {!Client}
   * @param {!TradeHistoryInfoUpdateNotify} value TradeHistoryInfoUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryInfoUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryInfoUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.TradeHistoryInfoUpdateNotify serialization failed!')
    console.assert(this.TradeHistoryInfoUpdateNotifySenderModel.verify(), 'client.TradeHistoryInfoUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRegularRuleGetRequest value
   * @this {!Client}
   * @param {!CalendarRegularRuleGetRequest} value CalendarRegularRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRegularRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRegularRuleGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRegularRuleGetRequest serialization failed!')
    console.assert(this.CalendarRegularRuleGetRequestSenderModel.verify(), 'client.CalendarRegularRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRegularRuleGetResponse value
   * @this {!Client}
   * @param {!CalendarRegularRuleGetResponse} value CalendarRegularRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRegularRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRegularRuleGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRegularRuleGetResponse serialization failed!')
    console.assert(this.CalendarRegularRuleGetResponseSenderModel.verify(), 'client.CalendarRegularRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRegularRuleUpdateNotify value
   * @this {!Client}
   * @param {!CalendarRegularRuleUpdateNotify} value CalendarRegularRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRegularRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRegularRuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRegularRuleUpdateNotify serialization failed!')
    console.assert(this.CalendarRegularRuleUpdateNotifySenderModel.verify(), 'client.CalendarRegularRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarOffTimeRuleGetRequest value
   * @this {!Client}
   * @param {!CalendarOffTimeRuleGetRequest} value CalendarOffTimeRuleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarOffTimeRuleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarOffTimeRuleGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarOffTimeRuleGetRequest serialization failed!')
    console.assert(this.CalendarOffTimeRuleGetRequestSenderModel.verify(), 'client.CalendarOffTimeRuleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarOffTimeRuleGetResponse value
   * @this {!Client}
   * @param {!CalendarOffTimeRuleGetResponse} value CalendarOffTimeRuleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarOffTimeRuleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarOffTimeRuleGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarOffTimeRuleGetResponse serialization failed!')
    console.assert(this.CalendarOffTimeRuleGetResponseSenderModel.verify(), 'client.CalendarOffTimeRuleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarOffTimeRuleUpdateNotify value
   * @this {!Client}
   * @param {!CalendarOffTimeRuleUpdateNotify} value CalendarOffTimeRuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarOffTimeRuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarOffTimeRuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarOffTimeRuleUpdateNotify serialization failed!')
    console.assert(this.CalendarOffTimeRuleUpdateNotifySenderModel.verify(), 'client.CalendarOffTimeRuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRulesGetRequest value
   * @this {!Client}
   * @param {!CalendarRulesGetRequest} value CalendarRulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRulesGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRulesGetRequest serialization failed!')
    console.assert(this.CalendarRulesGetRequestSenderModel.verify(), 'client.CalendarRulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CalendarRulesGetResponse value
   * @this {!Client}
   * @param {!CalendarRulesGetResponse} value CalendarRulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CalendarRulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CalendarRulesGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.CalendarRulesGetResponse serialization failed!')
    console.assert(this.CalendarRulesGetResponseSenderModel.verify(), 'client.CalendarRulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryRangeRequest value
   * @this {!Client}
   * @param {!ChartBarHistoryRangeRequest} value ChartBarHistoryRangeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryRangeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryRangeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartBarHistoryRangeRequest serialization failed!')
    console.assert(this.ChartBarHistoryRangeRequestSenderModel.verify(), 'client.ChartBarHistoryRangeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginSubscribeRequest value
   * @this {!Client}
   * @param {!OrderMarginSubscribeRequest} value OrderMarginSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginSubscribeRequest serialization failed!')
    console.assert(this.OrderMarginSubscribeRequestSenderModel.verify(), 'client.OrderMarginSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginSubscribeResponse value
   * @this {!Client}
   * @param {!OrderMarginSubscribeResponse} value OrderMarginSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginSubscribeResponse serialization failed!')
    console.assert(this.OrderMarginSubscribeResponseSenderModel.verify(), 'client.OrderMarginSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginNotify value
   * @this {!Client}
   * @param {!OrderMarginNotify} value OrderMarginNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginNotify serialization failed!')
    console.assert(this.OrderMarginNotifySenderModel.verify(), 'client.OrderMarginNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginUpdateSubscriptionRequest value
   * @this {!Client}
   * @param {!OrderMarginUpdateSubscriptionRequest} value OrderMarginUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginUpdateSubscriptionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginUpdateSubscriptionRequest serialization failed!')
    console.assert(this.OrderMarginUpdateSubscriptionRequestSenderModel.verify(), 'client.OrderMarginUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginUnsubscribeRequest value
   * @this {!Client}
   * @param {!OrderMarginUnsubscribeRequest} value OrderMarginUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginUnsubscribeRequest serialization failed!')
    console.assert(this.OrderMarginUnsubscribeRequestSenderModel.verify(), 'client.OrderMarginUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginUnsubscribeResponse value
   * @this {!Client}
   * @param {!OrderMarginUnsubscribeResponse} value OrderMarginUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginUnsubscribeResponse serialization failed!')
    console.assert(this.OrderMarginUnsubscribeResponseSenderModel.verify(), 'client.OrderMarginUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginGetRequest value
   * @this {!Client}
   * @param {!OrderMarginGetRequest} value OrderMarginGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginGetRequest serialization failed!')
    console.assert(this.OrderMarginGetRequestSenderModel.verify(), 'client.OrderMarginGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderMarginGetResponse value
   * @this {!Client}
   * @param {!OrderMarginGetResponse} value OrderMarginGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderMarginGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderMarginGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderMarginGetResponse serialization failed!')
    console.assert(this.OrderMarginGetResponseSenderModel.verify(), 'client.OrderMarginGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoSubscribeRequest value
   * @this {!Client}
   * @param {!OrderStateInfoSubscribeRequest} value OrderStateInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoSubscribeRequest serialization failed!')
    console.assert(this.OrderStateInfoSubscribeRequestSenderModel.verify(), 'client.OrderStateInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoSubscribeResponse value
   * @this {!Client}
   * @param {!OrderStateInfoSubscribeResponse} value OrderStateInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoSubscribeResponse serialization failed!')
    console.assert(this.OrderStateInfoSubscribeResponseSenderModel.verify(), 'client.OrderStateInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoUnsubscribeRequest value
   * @this {!Client}
   * @param {!OrderStateInfoUnsubscribeRequest} value OrderStateInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoUnsubscribeRequest serialization failed!')
    console.assert(this.OrderStateInfoUnsubscribeRequestSenderModel.verify(), 'client.OrderStateInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoUnsubscribeResponse value
   * @this {!Client}
   * @param {!OrderStateInfoUnsubscribeResponse} value OrderStateInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoUnsubscribeResponse serialization failed!')
    console.assert(this.OrderStateInfoUnsubscribeResponseSenderModel.verify(), 'client.OrderStateInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderStateInfoNotify value
   * @this {!Client}
   * @param {!OrderStateInfoNotify} value OrderStateInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_OrderStateInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderStateInfoNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderStateInfoNotify serialization failed!')
    console.assert(this.OrderStateInfoNotifySenderModel.verify(), 'client.OrderStateInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated003 value
   * @this {!Client}
   * @param {!Deprecated003} value Deprecated003 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated003 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated003SenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated003 serialization failed!')
    console.assert(this.Deprecated003SenderModel.verify(), 'client.Deprecated003 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated004 value
   * @this {!Client}
   * @param {!Deprecated004} value Deprecated004 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated004 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated004SenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated004 serialization failed!')
    console.assert(this.Deprecated004SenderModel.verify(), 'client.Deprecated004 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated005 value
   * @this {!Client}
   * @param {!Deprecated005} value Deprecated005 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated005 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated005SenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated005 serialization failed!')
    console.assert(this.Deprecated005SenderModel.verify(), 'client.Deprecated005 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated006 value
   * @this {!Client}
   * @param {!Deprecated006} value Deprecated006 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated006 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated006SenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated006 serialization failed!')
    console.assert(this.Deprecated006SenderModel.verify(), 'client.Deprecated006 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated007 value
   * @this {!Client}
   * @param {!Deprecated007} value Deprecated007 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated007 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated007SenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated007 serialization failed!')
    console.assert(this.Deprecated007SenderModel.verify(), 'client.Deprecated007 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated008 value
   * @this {!Client}
   * @param {!Deprecated008} value Deprecated008 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated008 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated008SenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Deprecated008 serialization failed!')
    console.assert(this.Deprecated008SenderModel.verify(), 'client.Deprecated008 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateRequest value
   * @this {!Client}
   * @param {!AccountUpdateRequest} value AccountUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountUpdateRequest serialization failed!')
    console.assert(this.AccountUpdateRequestSenderModel.verify(), 'client.AccountUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateResponse value
   * @this {!Client}
   * @param {!AccountUpdateResponse} value AccountUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountUpdateResponse serialization failed!')
    console.assert(this.AccountUpdateResponseSenderModel.verify(), 'client.AccountUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedInfoGetRequest value
   * @this {!Client}
   * @param {!ProfitClosedInfoGetRequest} value ProfitClosedInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedInfoGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ProfitClosedInfoGetRequest serialization failed!')
    console.assert(this.ProfitClosedInfoGetRequestSenderModel.verify(), 'client.ProfitClosedInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedInfoGetResponse value
   * @this {!Client}
   * @param {!ProfitClosedInfoGetResponse} value ProfitClosedInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedInfoGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ProfitClosedInfoGetResponse serialization failed!')
    console.assert(this.ProfitClosedInfoGetResponseSenderModel.verify(), 'client.ProfitClosedInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoSubscribeRequest value
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoSubscribeRequest} value SymbolDayProfitLossInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoSubscribeRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoSubscribeRequestSenderModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoSubscribeResponse value
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoSubscribeResponse} value SymbolDayProfitLossInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoSubscribeResponse serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoSubscribeResponseSenderModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoUpdateSubscriptionRequest value
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoUpdateSubscriptionRequest} value SymbolDayProfitLossInfoUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoUpdateSubscriptionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoUpdateSubscriptionRequestSenderModel.verify(), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoUnsubscribeRequest value
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoUnsubscribeRequest} value SymbolDayProfitLossInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoUnsubscribeRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoUnsubscribeRequestSenderModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoUnsubscribeResponse value
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoUnsubscribeResponse} value SymbolDayProfitLossInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoUnsubscribeResponse serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoUnsubscribeResponseSenderModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossInfoNotify value
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoNotify} value SymbolDayProfitLossInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossInfoNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossInfoNotify serialization failed!')
    console.assert(this.SymbolDayProfitLossInfoNotifySenderModel.verify(), 'client.SymbolDayProfitLossInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginInfoGetRequest value
   * @this {!Client}
   * @param {!LoginInfoGetRequest} value LoginInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginInfoGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginInfoGetRequest serialization failed!')
    console.assert(this.LoginInfoGetRequestSenderModel.verify(), 'client.LoginInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginInfoGetResponse value
   * @this {!Client}
   * @param {!LoginInfoGetResponse} value LoginInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginInfoGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginInfoGetResponse serialization failed!')
    console.assert(this.LoginInfoGetResponseSenderModel.verify(), 'client.LoginInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoCreateRequest value
   * @this {!Client}
   * @param {!DashboardInfoCreateRequest} value DashboardInfoCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoCreateRequest serialization failed!')
    console.assert(this.DashboardInfoCreateRequestSenderModel.verify(), 'client.DashboardInfoCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoCreateResponse value
   * @this {!Client}
   * @param {!DashboardInfoCreateResponse} value DashboardInfoCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoCreateResponse serialization failed!')
    console.assert(this.DashboardInfoCreateResponseSenderModel.verify(), 'client.DashboardInfoCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoUpdateRequest value
   * @this {!Client}
   * @param {!DashboardInfoUpdateRequest} value DashboardInfoUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoUpdateRequest serialization failed!')
    console.assert(this.DashboardInfoUpdateRequestSenderModel.verify(), 'client.DashboardInfoUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoUpdateResponse value
   * @this {!Client}
   * @param {!DashboardInfoUpdateResponse} value DashboardInfoUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoUpdateResponse serialization failed!')
    console.assert(this.DashboardInfoUpdateResponseSenderModel.verify(), 'client.DashboardInfoUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoListRequest value
   * @this {!Client}
   * @param {!DashboardInfoListRequest} value DashboardInfoListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoListRequest serialization failed!')
    console.assert(this.DashboardInfoListRequestSenderModel.verify(), 'client.DashboardInfoListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoListResponse value
   * @this {!Client}
   * @param {!DashboardInfoListResponse} value DashboardInfoListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoListResponse serialization failed!')
    console.assert(this.DashboardInfoListResponseSenderModel.verify(), 'client.DashboardInfoListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoGetRequest value
   * @this {!Client}
   * @param {!DashboardInfoGetRequest} value DashboardInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoGetRequest serialization failed!')
    console.assert(this.DashboardInfoGetRequestSenderModel.verify(), 'client.DashboardInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoGetResponse value
   * @this {!Client}
   * @param {!DashboardInfoGetResponse} value DashboardInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoGetResponse serialization failed!')
    console.assert(this.DashboardInfoGetResponseSenderModel.verify(), 'client.DashboardInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoRenameRequest value
   * @this {!Client}
   * @param {!DashboardInfoRenameRequest} value DashboardInfoRenameRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoRenameRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoRenameRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoRenameRequest serialization failed!')
    console.assert(this.DashboardInfoRenameRequestSenderModel.verify(), 'client.DashboardInfoRenameRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoRenameResponse value
   * @this {!Client}
   * @param {!DashboardInfoRenameResponse} value DashboardInfoRenameResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoRenameResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoRenameResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoRenameResponse serialization failed!')
    console.assert(this.DashboardInfoRenameResponseSenderModel.verify(), 'client.DashboardInfoRenameResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoDeleteRequest value
   * @this {!Client}
   * @param {!DashboardInfoDeleteRequest} value DashboardInfoDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoDeleteRequest serialization failed!')
    console.assert(this.DashboardInfoDeleteRequestSenderModel.verify(), 'client.DashboardInfoDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DashboardInfoDeleteResponse value
   * @this {!Client}
   * @param {!DashboardInfoDeleteResponse} value DashboardInfoDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DashboardInfoDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DashboardInfoDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DashboardInfoDeleteResponse serialization failed!')
    console.assert(this.DashboardInfoDeleteResponseSenderModel.verify(), 'client.DashboardInfoDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoCreateRequest value
   * @this {!Client}
   * @param {!ChartInfoCreateRequest} value ChartInfoCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoCreateRequest serialization failed!')
    console.assert(this.ChartInfoCreateRequestSenderModel.verify(), 'client.ChartInfoCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoCreateResponse value
   * @this {!Client}
   * @param {!ChartInfoCreateResponse} value ChartInfoCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoCreateResponse serialization failed!')
    console.assert(this.ChartInfoCreateResponseSenderModel.verify(), 'client.ChartInfoCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoUpdateRequest value
   * @this {!Client}
   * @param {!ChartInfoUpdateRequest} value ChartInfoUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoUpdateRequest serialization failed!')
    console.assert(this.ChartInfoUpdateRequestSenderModel.verify(), 'client.ChartInfoUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoUpdateResponse value
   * @this {!Client}
   * @param {!ChartInfoUpdateResponse} value ChartInfoUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoUpdateResponse serialization failed!')
    console.assert(this.ChartInfoUpdateResponseSenderModel.verify(), 'client.ChartInfoUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoListRequest value
   * @this {!Client}
   * @param {!ChartInfoListRequest} value ChartInfoListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoListRequest serialization failed!')
    console.assert(this.ChartInfoListRequestSenderModel.verify(), 'client.ChartInfoListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoListResponse value
   * @this {!Client}
   * @param {!ChartInfoListResponse} value ChartInfoListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoListResponse serialization failed!')
    console.assert(this.ChartInfoListResponseSenderModel.verify(), 'client.ChartInfoListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoDeleteRequest value
   * @this {!Client}
   * @param {!ChartInfoDeleteRequest} value ChartInfoDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoDeleteRequest serialization failed!')
    console.assert(this.ChartInfoDeleteRequestSenderModel.verify(), 'client.ChartInfoDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoDeleteResponse value
   * @this {!Client}
   * @param {!ChartInfoDeleteResponse} value ChartInfoDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoDeleteResponse serialization failed!')
    console.assert(this.ChartInfoDeleteResponseSenderModel.verify(), 'client.ChartInfoDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoGetRequest value
   * @this {!Client}
   * @param {!ChartInfoGetRequest} value ChartInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoGetRequest serialization failed!')
    console.assert(this.ChartInfoGetRequestSenderModel.verify(), 'client.ChartInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartInfoGetResponse value
   * @this {!Client}
   * @param {!ChartInfoGetResponse} value ChartInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartInfoGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.ChartInfoGetResponse serialization failed!')
    console.assert(this.ChartInfoGetResponseSenderModel.verify(), 'client.ChartInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoSubscribeRequest value
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeRequest} value SymbolDayProfitLossMultipleInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoSubscribeRequestSenderModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoSubscribeResponse value
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeResponse} value SymbolDayProfitLossMultipleInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoSubscribeResponseSenderModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest value
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest} value SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoUnsubscribeRequest value
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeRequest} value SymbolDayProfitLossMultipleInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoUnsubscribeRequestSenderModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoUnsubscribeResponse value
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeResponse} value SymbolDayProfitLossMultipleInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoUnsubscribeResponseSenderModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolDayProfitLossMultipleInfoNotify value
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoNotify} value SymbolDayProfitLossMultipleInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolDayProfitLossMultipleInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolDayProfitLossMultipleInfoNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolDayProfitLossMultipleInfoNotify serialization failed!')
    console.assert(this.SymbolDayProfitLossMultipleInfoNotifySenderModel.verify(), 'client.SymbolDayProfitLossMultipleInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsServiceGetAllRequest value
   * @this {!Client}
   * @param {!NewsServiceGetAllRequest} value NewsServiceGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsServiceGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsServiceGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsServiceGetAllRequest serialization failed!')
    console.assert(this.NewsServiceGetAllRequestSenderModel.verify(), 'client.NewsServiceGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsServiceGetAllResponse value
   * @this {!Client}
   * @param {!NewsServiceGetAllResponse} value NewsServiceGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsServiceGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsServiceGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsServiceGetAllResponse serialization failed!')
    console.assert(this.NewsServiceGetAllResponseSenderModel.verify(), 'client.NewsServiceGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsRecentArticlesGetRequest value
   * @this {!Client}
   * @param {!NewsRecentArticlesGetRequest} value NewsRecentArticlesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsRecentArticlesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsRecentArticlesGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsRecentArticlesGetRequest serialization failed!')
    console.assert(this.NewsRecentArticlesGetRequestSenderModel.verify(), 'client.NewsRecentArticlesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsRecentArticlesGetResponse value
   * @this {!Client}
   * @param {!NewsRecentArticlesGetResponse} value NewsRecentArticlesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsRecentArticlesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsRecentArticlesGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsRecentArticlesGetResponse serialization failed!')
    console.assert(this.NewsRecentArticlesGetResponseSenderModel.verify(), 'client.NewsRecentArticlesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsArticleGetRequest value
   * @this {!Client}
   * @param {!NewsArticleGetRequest} value NewsArticleGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_NewsArticleGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsArticleGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsArticleGetRequest serialization failed!')
    console.assert(this.NewsArticleGetRequestSenderModel.verify(), 'client.NewsArticleGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send NewsArticleGetResponse value
   * @this {!Client}
   * @param {!NewsArticleGetResponse} value NewsArticleGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_NewsArticleGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.NewsArticleGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.NewsArticleGetResponse serialization failed!')
    console.assert(this.NewsArticleGetResponseSenderModel.verify(), 'client.NewsArticleGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithTypeRequest value
   * @this {!Client}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithTypeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithTypeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderUpdateWithTypeRequest serialization failed!')
    console.assert(this.OrderUpdateWithTypeRequestSenderModel.verify(), 'client.OrderUpdateWithTypeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithTypeResponse value
   * @this {!Client}
   * @param {!OrderUpdateWithTypeResponse} value OrderUpdateWithTypeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithTypeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithTypeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.OrderUpdateWithTypeResponse serialization failed!')
    console.assert(this.OrderUpdateWithTypeResponseSenderModel.verify(), 'client.OrderUpdateWithTypeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoSubscribeRequest value
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoSubscribeRequest} value SymbolProfitLossMultipleInfoSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoSubscribeRequest serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoSubscribeRequestSenderModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoSubscribeResponse value
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoSubscribeResponse} value SymbolProfitLossMultipleInfoSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoSubscribeResponse serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoSubscribeResponseSenderModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoUpdateSubscriptionRequest value
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoUpdateSubscriptionRequest} value SymbolProfitLossMultipleInfoUpdateSubscriptionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoUpdateSubscriptionRequestSenderModel.verify(), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoUnsubscribeRequest value
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeRequest} value SymbolProfitLossMultipleInfoUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoUnsubscribeRequestSenderModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoUnsubscribeResponse value
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeResponse} value SymbolProfitLossMultipleInfoUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoUnsubscribeResponseSenderModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolProfitLossMultipleInfoNotify value
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoNotify} value SymbolProfitLossMultipleInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolProfitLossMultipleInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolProfitLossMultipleInfoNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'client.SymbolProfitLossMultipleInfoNotify serialization failed!')
    console.assert(this.SymbolProfitLossMultipleInfoNotifySenderModel.verify(), 'client.SymbolProfitLossMultipleInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithPasswordRequest value
   * @this {!Client}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithPasswordRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithPasswordRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginWithPasswordRequest serialization failed!')
    console.assert(this.LoginWithPasswordRequestSenderModel.verify(), 'client.LoginWithPasswordRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithPasswordResponse value
   * @this {!Client}
   * @param {!LoginWithPasswordResponse} value LoginWithPasswordResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithPasswordResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithPasswordResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginWithPasswordResponse serialization failed!')
    console.assert(this.LoginWithPasswordResponseSenderModel.verify(), 'client.LoginWithPasswordResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithTokenRequest value
   * @this {!Client}
   * @param {!LoginWithTokenRequest} value LoginWithTokenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithTokenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithTokenRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginWithTokenRequest serialization failed!')
    console.assert(this.LoginWithTokenRequestSenderModel.verify(), 'client.LoginWithTokenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LoginWithTokenResponse value
   * @this {!Client}
   * @param {!LoginWithTokenResponse} value LoginWithTokenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LoginWithTokenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LoginWithTokenResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LoginWithTokenResponse serialization failed!')
    console.assert(this.LoginWithTokenResponseSenderModel.verify(), 'client.LoginWithTokenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RefreshTokenRequest value
   * @this {!Client}
   * @param {!RefreshTokenRequest} value RefreshTokenRequest value to send
   * @returns {!number} Sent bytes
   */
  send_RefreshTokenRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RefreshTokenRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.RefreshTokenRequest serialization failed!')
    console.assert(this.RefreshTokenRequestSenderModel.verify(), 'client.RefreshTokenRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send RefreshTokenResponse value
   * @this {!Client}
   * @param {!RefreshTokenResponse} value RefreshTokenResponse value to send
   * @returns {!number} Sent bytes
   */
  send_RefreshTokenResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RefreshTokenResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.RefreshTokenResponse serialization failed!')
    console.assert(this.RefreshTokenResponseSenderModel.verify(), 'client.RefreshTokenResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Logout2Request value
   * @this {!Client}
   * @param {!Logout2Request} value Logout2Request value to send
   * @returns {!number} Sent bytes
   */
  send_Logout2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Logout2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Logout2Request serialization failed!')
    console.assert(this.Logout2RequestSenderModel.verify(), 'client.Logout2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Logout2Response value
   * @this {!Client}
   * @param {!Logout2Response} value Logout2Response value to send
   * @returns {!number} Sent bytes
   */
  send_Logout2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Logout2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.Logout2Response serialization failed!')
    console.assert(this.Logout2ResponseSenderModel.verify(), 'client.Logout2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountChangePasswordRequest value
   * @this {!Client}
   * @param {!AccountChangePasswordRequest} value AccountChangePasswordRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountChangePasswordRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountChangePasswordRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountChangePasswordRequest serialization failed!')
    console.assert(this.AccountChangePasswordRequestSenderModel.verify(), 'client.AccountChangePasswordRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountChangePasswordResponse value
   * @this {!Client}
   * @param {!AccountChangePasswordResponse} value AccountChangePasswordResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountChangePasswordResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountChangePasswordResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AccountChangePasswordResponse serialization failed!')
    console.assert(this.AccountChangePasswordResponseSenderModel.verify(), 'client.AccountChangePasswordResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PushNotificationTokenRegisterRequest value
   * @this {!Client}
   * @param {!PushNotificationTokenRegisterRequest} value PushNotificationTokenRegisterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PushNotificationTokenRegisterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PushNotificationTokenRegisterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PushNotificationTokenRegisterRequest serialization failed!')
    console.assert(this.PushNotificationTokenRegisterRequestSenderModel.verify(), 'client.PushNotificationTokenRegisterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PushNotificationTokenRegisterResponse value
   * @this {!Client}
   * @param {!PushNotificationTokenRegisterResponse} value PushNotificationTokenRegisterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PushNotificationTokenRegisterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PushNotificationTokenRegisterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PushNotificationTokenRegisterResponse serialization failed!')
    console.assert(this.PushNotificationTokenRegisterResponseSenderModel.verify(), 'client.PushNotificationTokenRegisterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DesiredAppSettingsGetRequest value
   * @this {!Client}
   * @param {!DesiredAppSettingsGetRequest} value DesiredAppSettingsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DesiredAppSettingsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DesiredAppSettingsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DesiredAppSettingsGetRequest serialization failed!')
    console.assert(this.DesiredAppSettingsGetRequestSenderModel.verify(), 'client.DesiredAppSettingsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DesiredAppSettingsGetResponse value
   * @this {!Client}
   * @param {!DesiredAppSettingsGetResponse} value DesiredAppSettingsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DesiredAppSettingsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DesiredAppSettingsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.DesiredAppSettingsGetResponse serialization failed!')
    console.assert(this.DesiredAppSettingsGetResponseSenderModel.verify(), 'client.DesiredAppSettingsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AuthTokensGetAllRequest value
   * @this {!Client}
   * @param {!AuthTokensGetAllRequest} value AuthTokensGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AuthTokensGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AuthTokensGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AuthTokensGetAllRequest serialization failed!')
    console.assert(this.AuthTokensGetAllRequestSenderModel.verify(), 'client.AuthTokensGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AuthTokensGetAllResponse value
   * @this {!Client}
   * @param {!AuthTokensGetAllResponse} value AuthTokensGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AuthTokensGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AuthTokensGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AuthTokensGetAllResponse serialization failed!')
    console.assert(this.AuthTokensGetAllResponseSenderModel.verify(), 'client.AuthTokensGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AuthTokensDeleteRequest value
   * @this {!Client}
   * @param {!AuthTokensDeleteRequest} value AuthTokensDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AuthTokensDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AuthTokensDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AuthTokensDeleteRequest serialization failed!')
    console.assert(this.AuthTokensDeleteRequestSenderModel.verify(), 'client.AuthTokensDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AuthTokensDeleteResponse value
   * @this {!Client}
   * @param {!AuthTokensDeleteResponse} value AuthTokensDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AuthTokensDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AuthTokensDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.AuthTokensDeleteResponse serialization failed!')
    console.assert(this.AuthTokensDeleteResponseSenderModel.verify(), 'client.AuthTokensDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupListRequest value
   * @this {!Client}
   * @param {!LegalDocumentGroupListRequest} value LegalDocumentGroupListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LegalDocumentGroupListRequest serialization failed!')
    console.assert(this.LegalDocumentGroupListRequestSenderModel.verify(), 'client.LegalDocumentGroupListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGroupListResponse value
   * @this {!Client}
   * @param {!LegalDocumentGroupListResponse} value LegalDocumentGroupListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGroupListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGroupListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LegalDocumentGroupListResponse serialization failed!')
    console.assert(this.LegalDocumentGroupListResponseSenderModel.verify(), 'client.LegalDocumentGroupListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetRequest value
   * @this {!Client}
   * @param {!LegalDocumentGetRequest} value LegalDocumentGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LegalDocumentGetRequest serialization failed!')
    console.assert(this.LegalDocumentGetRequestSenderModel.verify(), 'client.LegalDocumentGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LegalDocumentGetResponse value
   * @this {!Client}
   * @param {!LegalDocumentGetResponse} value LegalDocumentGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LegalDocumentGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LegalDocumentGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.LegalDocumentGetResponse serialization failed!')
    console.assert(this.LegalDocumentGetResponseSenderModel.verify(), 'client.LegalDocumentGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PushNotificationTokenUnRegisterRequest value
   * @this {!Client}
   * @param {!PushNotificationTokenUnRegisterRequest} value PushNotificationTokenUnRegisterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PushNotificationTokenUnRegisterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PushNotificationTokenUnRegisterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PushNotificationTokenUnRegisterRequest serialization failed!')
    console.assert(this.PushNotificationTokenUnRegisterRequestSenderModel.verify(), 'client.PushNotificationTokenUnRegisterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PushNotificationTokenUnRegisterResponse value
   * @this {!Client}
   * @param {!PushNotificationTokenUnRegisterResponse} value PushNotificationTokenUnRegisterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PushNotificationTokenUnRegisterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PushNotificationTokenUnRegisterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'client.PushNotificationTokenUnRegisterResponse serialization failed!')
    console.assert(this.PushNotificationTokenUnRegisterResponseSenderModel.verify(), 'client.PushNotificationTokenUnRegisterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'client.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
    this._exposedClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
    this._exposedClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * PingRequest receive handler
   * @this {!Client}
   * @param {!PingRequest} value PingRequest received value
   */
  onReceive_PingRequest (value) {}  // eslint-disable-line

  /**
   * PongResponse receive handler
   * @this {!Client}
   * @param {!PongResponse} value PongResponse received value
   */
  onReceive_PongResponse (value) {}  // eslint-disable-line

  /**
   * LoginRequest receive handler
   * @this {!Client}
   * @param {!LoginRequest} value LoginRequest received value
   */
  onReceive_LoginRequest (value) {}  // eslint-disable-line

  /**
   * LoginResponse receive handler
   * @this {!Client}
   * @param {!LoginResponse} value LoginResponse received value
   */
  onReceive_LoginResponse (value) {}  // eslint-disable-line

  /**
   * LoginReject receive handler
   * @this {!Client}
   * @param {!LoginReject} value LoginReject received value
   */
  onReceive_LoginReject (value) {}  // eslint-disable-line

  /**
   * LogoutRequest receive handler
   * @this {!Client}
   * @param {!LogoutRequest} value LogoutRequest received value
   */
  onReceive_LogoutRequest (value) {}  // eslint-disable-line

  /**
   * LogoutResponse receive handler
   * @this {!Client}
   * @param {!LogoutResponse} value LogoutResponse received value
   */
  onReceive_LogoutResponse (value) {}  // eslint-disable-line

  /**
   * LogoutNotify receive handler
   * @this {!Client}
   * @param {!LogoutNotify} value LogoutNotify received value
   */
  onReceive_LogoutNotify (value) {}  // eslint-disable-line

  /**
   * SessionInfoRequest receive handler
   * @this {!Client}
   * @param {!SessionInfoRequest} value SessionInfoRequest received value
   */
  onReceive_SessionInfoRequest (value) {}  // eslint-disable-line

  /**
   * SessionInfoResponse receive handler
   * @this {!Client}
   * @param {!SessionInfoResponse} value SessionInfoResponse received value
   */
  onReceive_SessionInfoResponse (value) {}  // eslint-disable-line

  /**
   * TimeInfoRequest receive handler
   * @this {!Client}
   * @param {!TimeInfoRequest} value TimeInfoRequest received value
   */
  onReceive_TimeInfoRequest (value) {}  // eslint-disable-line

  /**
   * TimeInfoResponse receive handler
   * @this {!Client}
   * @param {!TimeInfoResponse} value TimeInfoResponse received value
   */
  onReceive_TimeInfoResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneInfoRequest receive handler
   * @this {!Client}
   * @param {!TimezoneInfoRequest} value TimezoneInfoRequest received value
   */
  onReceive_TimezoneInfoRequest (value) {}  // eslint-disable-line

  /**
   * TimezoneInfoResponse receive handler
   * @this {!Client}
   * @param {!TimezoneInfoResponse} value TimezoneInfoResponse received value
   */
  onReceive_TimezoneInfoResponse (value) {}  // eslint-disable-line

  /**
   * TimezoneInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!TimezoneInfoUpdateNotify} value TimezoneInfoUpdateNotify received value
   */
  onReceive_TimezoneInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyInfoRequest receive handler
   * @this {!Client}
   * @param {!CurrencyInfoRequest} value CurrencyInfoRequest received value
   */
  onReceive_CurrencyInfoRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyInfoResponse receive handler
   * @this {!Client}
   * @param {!CurrencyInfoResponse} value CurrencyInfoResponse received value
   */
  onReceive_CurrencyInfoResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyListRequest receive handler
   * @this {!Client}
   * @param {!CurrencyListRequest} value CurrencyListRequest received value
   */
  onReceive_CurrencyListRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyListResponse receive handler
   * @this {!Client}
   * @param {!CurrencyListResponse} value CurrencyListResponse received value
   */
  onReceive_CurrencyListResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!CurrencyInfoUpdateNotify} value CurrencyInfoUpdateNotify received value
   */
  onReceive_CurrencyInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolInfoRequest receive handler
   * @this {!Client}
   * @param {!SymbolInfoRequest} value SymbolInfoRequest received value
   */
  onReceive_SymbolInfoRequest (value) {}  // eslint-disable-line

  /**
   * SymbolInfoResponse receive handler
   * @this {!Client}
   * @param {!SymbolInfoResponse} value SymbolInfoResponse received value
   */
  onReceive_SymbolInfoResponse (value) {}  // eslint-disable-line

  /**
   * SymbolListRequest receive handler
   * @this {!Client}
   * @param {!SymbolListRequest} value SymbolListRequest received value
   */
  onReceive_SymbolListRequest (value) {}  // eslint-disable-line

  /**
   * SymbolListResponse receive handler
   * @this {!Client}
   * @param {!SymbolListResponse} value SymbolListResponse received value
   */
  onReceive_SymbolListResponse (value) {}  // eslint-disable-line

  /**
   * SymbolInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolInfoUpdateNotify} value SymbolInfoUpdateNotify received value
   */
  onReceive_SymbolInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TickInfoRequest receive handler
   * @this {!Client}
   * @param {!TickInfoRequest} value TickInfoRequest received value
   */
  onReceive_TickInfoRequest (value) {}  // eslint-disable-line

  /**
   * TickInfoResponse receive handler
   * @this {!Client}
   * @param {!TickInfoResponse} value TickInfoResponse received value
   */
  onReceive_TickInfoResponse (value) {}  // eslint-disable-line

  /**
   * TickFilterRequest receive handler
   * @this {!Client}
   * @param {!TickFilterRequest} value TickFilterRequest received value
   */
  onReceive_TickFilterRequest (value) {}  // eslint-disable-line

  /**
   * TickFilterResponse receive handler
   * @this {!Client}
   * @param {!TickFilterResponse} value TickFilterResponse received value
   */
  onReceive_TickFilterResponse (value) {}  // eslint-disable-line

  /**
   * TickListRequest receive handler
   * @this {!Client}
   * @param {!TickListRequest} value TickListRequest received value
   */
  onReceive_TickListRequest (value) {}  // eslint-disable-line

  /**
   * TickListResponse receive handler
   * @this {!Client}
   * @param {!TickListResponse} value TickListResponse received value
   */
  onReceive_TickListResponse (value) {}  // eslint-disable-line

  /**
   * Level2InfoRequest receive handler
   * @this {!Client}
   * @param {!Level2InfoRequest} value Level2InfoRequest received value
   */
  onReceive_Level2InfoRequest (value) {}  // eslint-disable-line

  /**
   * Level2InfoResponse receive handler
   * @this {!Client}
   * @param {!Level2InfoResponse} value Level2InfoResponse received value
   */
  onReceive_Level2InfoResponse (value) {}  // eslint-disable-line

  /**
   * Level2FilterRequest receive handler
   * @this {!Client}
   * @param {!Level2FilterRequest} value Level2FilterRequest received value
   */
  onReceive_Level2FilterRequest (value) {}  // eslint-disable-line

  /**
   * Level2FilterResponse receive handler
   * @this {!Client}
   * @param {!Level2FilterResponse} value Level2FilterResponse received value
   */
  onReceive_Level2FilterResponse (value) {}  // eslint-disable-line

  /**
   * Level2ListRequest receive handler
   * @this {!Client}
   * @param {!Level2ListRequest} value Level2ListRequest received value
   */
  onReceive_Level2ListRequest (value) {}  // eslint-disable-line

  /**
   * Level2ListResponse receive handler
   * @this {!Client}
   * @param {!Level2ListResponse} value Level2ListResponse received value
   */
  onReceive_Level2ListResponse (value) {}  // eslint-disable-line

  /**
   * FeedSubscriptionInfoRequest receive handler
   * @this {!Client}
   * @param {!FeedSubscriptionInfoRequest} value FeedSubscriptionInfoRequest received value
   */
  onReceive_FeedSubscriptionInfoRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscriptionInfoResponse receive handler
   * @this {!Client}
   * @param {!FeedSubscriptionInfoResponse} value FeedSubscriptionInfoResponse received value
   */
  onReceive_FeedSubscriptionInfoResponse (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeRequest receive handler
   * @this {!Client}
   * @param {!FeedSubscribeRequest} value FeedSubscribeRequest received value
   */
  onReceive_FeedSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeAllRequest receive handler
   * @this {!Client}
   * @param {!FeedSubscribeAllRequest} value FeedSubscribeAllRequest received value
   */
  onReceive_FeedSubscribeAllRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeResponse receive handler
   * @this {!Client}
   * @param {!FeedSubscribeResponse} value FeedSubscribeResponse received value
   */
  onReceive_FeedSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!FeedUnsubscribeRequest} value FeedUnsubscribeRequest received value
   */
  onReceive_FeedUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeAllRequest receive handler
   * @this {!Client}
   * @param {!FeedUnsubscribeAllRequest} value FeedUnsubscribeAllRequest received value
   */
  onReceive_FeedUnsubscribeAllRequest (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!FeedUnsubscribeResponse} value FeedUnsubscribeResponse received value
   */
  onReceive_FeedUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * TickSnapshotNotify receive handler
   * @this {!Client}
   * @param {!TickSnapshotNotify} value TickSnapshotNotify received value
   */
  onReceive_TickSnapshotNotify (value) {}  // eslint-disable-line

  /**
   * TickUpdateNotify receive handler
   * @this {!Client}
   * @param {!TickUpdateNotify} value TickUpdateNotify received value
   */
  onReceive_TickUpdateNotify (value) {}  // eslint-disable-line

  /**
   * Level2SnapshotNotify receive handler
   * @this {!Client}
   * @param {!Level2SnapshotNotify} value Level2SnapshotNotify received value
   */
  onReceive_Level2SnapshotNotify (value) {}  // eslint-disable-line

  /**
   * Level2UpdateNotify receive handler
   * @this {!Client}
   * @param {!Level2UpdateNotify} value Level2UpdateNotify received value
   */
  onReceive_Level2UpdateNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRequest receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryRequest} value ChartBarHistoryRequest received value
   */
  onReceive_ChartBarHistoryRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryResponse receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryResponse} value ChartBarHistoryResponse received value
   */
  onReceive_ChartBarHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarSubscribeRequest receive handler
   * @this {!Client}
   * @param {!ChartBarSubscribeRequest} value ChartBarSubscribeRequest received value
   */
  onReceive_ChartBarSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarSubscribeResponse receive handler
   * @this {!Client}
   * @param {!ChartBarSubscribeResponse} value ChartBarSubscribeResponse received value
   */
  onReceive_ChartBarSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!ChartBarUnsubscribeRequest} value ChartBarUnsubscribeRequest received value
   */
  onReceive_ChartBarUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!ChartBarUnsubscribeResponse} value ChartBarUnsubscribeResponse received value
   */
  onReceive_ChartBarUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarNotify receive handler
   * @this {!Client}
   * @param {!ChartBarNotify} value ChartBarNotify received value
   */
  onReceive_ChartBarNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarUpdateNotify receive handler
   * @this {!Client}
   * @param {!ChartBarUpdateNotify} value ChartBarUpdateNotify received value
   */
  onReceive_ChartBarUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PlatformInfoRequest receive handler
   * @this {!Client}
   * @param {!PlatformInfoRequest} value PlatformInfoRequest received value
   */
  onReceive_PlatformInfoRequest (value) {}  // eslint-disable-line

  /**
   * PlatformInfoResponse receive handler
   * @this {!Client}
   * @param {!PlatformInfoResponse} value PlatformInfoResponse received value
   */
  onReceive_PlatformInfoResponse (value) {}  // eslint-disable-line

  /**
   * PlatformInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!PlatformInfoUpdateNotify} value PlatformInfoUpdateNotify received value
   */
  onReceive_PlatformInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountInfoRequest receive handler
   * @this {!Client}
   * @param {!AccountInfoRequest} value AccountInfoRequest received value
   */
  onReceive_AccountInfoRequest (value) {}  // eslint-disable-line

  /**
   * AccountInfoResponse receive handler
   * @this {!Client}
   * @param {!AccountInfoResponse} value AccountInfoResponse received value
   */
  onReceive_AccountInfoResponse (value) {}  // eslint-disable-line

  /**
   * AccountInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!AccountInfoUpdateNotify} value AccountInfoUpdateNotify received value
   */
  onReceive_AccountInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginInfoRequest receive handler
   * @this {!Client}
   * @param {!MarginInfoRequest} value MarginInfoRequest received value
   */
  onReceive_MarginInfoRequest (value) {}  // eslint-disable-line

  /**
   * MarginInfoResponse receive handler
   * @this {!Client}
   * @param {!MarginInfoResponse} value MarginInfoResponse received value
   */
  onReceive_MarginInfoResponse (value) {}  // eslint-disable-line

  /**
   * MarginInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!MarginInfoUpdateNotify} value MarginInfoUpdateNotify received value
   */
  onReceive_MarginInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * MarginCallNotify receive handler
   * @this {!Client}
   * @param {!MarginCallNotify} value MarginCallNotify received value
   */
  onReceive_MarginCallNotify (value) {}  // eslint-disable-line

  /**
   * StopOutNotify receive handler
   * @this {!Client}
   * @param {!StopOutNotify} value StopOutNotify received value
   */
  onReceive_StopOutNotify (value) {}  // eslint-disable-line

  /**
   * AssetInfoRequest receive handler
   * @this {!Client}
   * @param {!AssetInfoRequest} value AssetInfoRequest received value
   */
  onReceive_AssetInfoRequest (value) {}  // eslint-disable-line

  /**
   * AssetInfoResponse receive handler
   * @this {!Client}
   * @param {!AssetInfoResponse} value AssetInfoResponse received value
   */
  onReceive_AssetInfoResponse (value) {}  // eslint-disable-line

  /**
   * AssetListRequest receive handler
   * @this {!Client}
   * @param {!AssetListRequest} value AssetListRequest received value
   */
  onReceive_AssetListRequest (value) {}  // eslint-disable-line

  /**
   * AssetListResponse receive handler
   * @this {!Client}
   * @param {!AssetListResponse} value AssetListResponse received value
   */
  onReceive_AssetListResponse (value) {}  // eslint-disable-line

  /**
   * AssetInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!AssetInfoUpdateNotify} value AssetInfoUpdateNotify received value
   */
  onReceive_AssetInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * OrderInfoRequest receive handler
   * @this {!Client}
   * @param {!OrderInfoRequest} value OrderInfoRequest received value
   */
  onReceive_OrderInfoRequest (value) {}  // eslint-disable-line

  /**
   * OrderInfoResponse receive handler
   * @this {!Client}
   * @param {!OrderInfoResponse} value OrderInfoResponse received value
   */
  onReceive_OrderInfoResponse (value) {}  // eslint-disable-line

  /**
   * OrderListRequest receive handler
   * @this {!Client}
   * @param {!OrderListRequest} value OrderListRequest received value
   */
  onReceive_OrderListRequest (value) {}  // eslint-disable-line

  /**
   * OrderListResponse receive handler
   * @this {!Client}
   * @param {!OrderListResponse} value OrderListResponse received value
   */
  onReceive_OrderListResponse (value) {}  // eslint-disable-line

  /**
   * OrderOpenRequest receive handler
   * @this {!Client}
   * @param {!OrderOpenRequest} value OrderOpenRequest received value
   */
  onReceive_OrderOpenRequest (value) {}  // eslint-disable-line

  /**
   * OrderOpenResponse receive handler
   * @this {!Client}
   * @param {!OrderOpenResponse} value OrderOpenResponse received value
   */
  onReceive_OrderOpenResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateRequest receive handler
   * @this {!Client}
   * @param {!OrderUpdateRequest} value OrderUpdateRequest received value
   */
  onReceive_OrderUpdateRequest (value) {}  // eslint-disable-line

  /**
   * OrderUpdateResponse receive handler
   * @this {!Client}
   * @param {!OrderUpdateResponse} value OrderUpdateResponse received value
   */
  onReceive_OrderUpdateResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelRequest} value OrderCancelRequest received value
   */
  onReceive_OrderCancelRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelResponse receive handler
   * @this {!Client}
   * @param {!OrderCancelResponse} value OrderCancelResponse received value
   */
  onReceive_OrderCancelResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelAllRequest} value OrderCancelAllRequest received value
   */
  onReceive_OrderCancelAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllResponse receive handler
   * @this {!Client}
   * @param {!OrderCancelAllResponse} value OrderCancelAllResponse received value
   */
  onReceive_OrderCancelAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelAllByIdRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelAllByIdRequest} value OrderCancelAllByIdRequest received value
   */
  onReceive_OrderCancelAllByIdRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseRequest} value OrderCloseRequest received value
   */
  onReceive_OrderCloseRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseResponse receive handler
   * @this {!Client}
   * @param {!OrderCloseResponse} value OrderCloseResponse received value
   */
  onReceive_OrderCloseResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseAllRequest} value OrderCloseAllRequest received value
   */
  onReceive_OrderCloseAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllResponse receive handler
   * @this {!Client}
   * @param {!OrderCloseAllResponse} value OrderCloseAllResponse received value
   */
  onReceive_OrderCloseAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseAllByIdRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseAllByIdRequest} value OrderCloseAllByIdRequest received value
   */
  onReceive_OrderCloseAllByIdRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseByRequest} value OrderCloseByRequest received value
   */
  onReceive_OrderCloseByRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByResponse receive handler
   * @this {!Client}
   * @param {!OrderCloseByResponse} value OrderCloseByResponse received value
   */
  onReceive_OrderCloseByResponse (value) {}  // eslint-disable-line

  /**
   * OrderCloseByAllRequest receive handler
   * @this {!Client}
   * @param {!OrderCloseByAllRequest} value OrderCloseByAllRequest received value
   */
  onReceive_OrderCloseByAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCloseByAllResponse receive handler
   * @this {!Client}
   * @param {!OrderCloseByAllResponse} value OrderCloseByAllResponse received value
   */
  onReceive_OrderCloseByAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelOrCloseRequest} value OrderCancelOrCloseRequest received value
   */
  onReceive_OrderCancelOrCloseRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseResponse receive handler
   * @this {!Client}
   * @param {!OrderCancelOrCloseResponse} value OrderCancelOrCloseResponse received value
   */
  onReceive_OrderCancelOrCloseResponse (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllRequest receive handler
   * @this {!Client}
   * @param {!OrderCancelOrCloseAllRequest} value OrderCancelOrCloseAllRequest received value
   */
  onReceive_OrderCancelOrCloseAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderCancelOrCloseAllResponse receive handler
   * @this {!Client}
   * @param {!OrderCancelOrCloseAllResponse} value OrderCancelOrCloseAllResponse received value
   */
  onReceive_OrderCancelOrCloseAllResponse (value) {}  // eslint-disable-line

  /**
   * OrderInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!OrderInfoUpdateNotify} value OrderInfoUpdateNotify received value
   */
  onReceive_OrderInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PositionInfoRequest receive handler
   * @this {!Client}
   * @param {!PositionInfoRequest} value PositionInfoRequest received value
   */
  onReceive_PositionInfoRequest (value) {}  // eslint-disable-line

  /**
   * PositionInfoResponse receive handler
   * @this {!Client}
   * @param {!PositionInfoResponse} value PositionInfoResponse received value
   */
  onReceive_PositionInfoResponse (value) {}  // eslint-disable-line

  /**
   * PositionListRequest receive handler
   * @this {!Client}
   * @param {!PositionListRequest} value PositionListRequest received value
   */
  onReceive_PositionListRequest (value) {}  // eslint-disable-line

  /**
   * PositionListResponse receive handler
   * @this {!Client}
   * @param {!PositionListResponse} value PositionListResponse received value
   */
  onReceive_PositionListResponse (value) {}  // eslint-disable-line

  /**
   * PositionInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!PositionInfoUpdateNotify} value PositionInfoUpdateNotify received value
   */
  onReceive_PositionInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryInfoRequest} value TradeHistoryInfoRequest received value
   */
  onReceive_TradeHistoryInfoRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryInfoResponse} value TradeHistoryInfoResponse received value
   */
  onReceive_TradeHistoryInfoResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoFilterRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryInfoFilterRequest} value TradeHistoryInfoFilterRequest received value
   */
  onReceive_TradeHistoryInfoFilterRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoByIdRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryInfoByIdRequest} value TradeHistoryInfoByIdRequest received value
   */
  onReceive_TradeHistoryInfoByIdRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoByIdResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryInfoByIdResponse} value TradeHistoryInfoByIdResponse received value
   */
  onReceive_TradeHistoryInfoByIdResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryInfoUpdateNotify receive handler
   * @this {!Client}
   * @param {!TradeHistoryInfoUpdateNotify} value TradeHistoryInfoUpdateNotify received value
   */
  onReceive_TradeHistoryInfoUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleGetRequest receive handler
   * @this {!Client}
   * @param {!CalendarRegularRuleGetRequest} value CalendarRegularRuleGetRequest received value
   */
  onReceive_CalendarRegularRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleGetResponse receive handler
   * @this {!Client}
   * @param {!CalendarRegularRuleGetResponse} value CalendarRegularRuleGetResponse received value
   */
  onReceive_CalendarRegularRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * CalendarRegularRuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!CalendarRegularRuleUpdateNotify} value CalendarRegularRuleUpdateNotify received value
   */
  onReceive_CalendarRegularRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleGetRequest receive handler
   * @this {!Client}
   * @param {!CalendarOffTimeRuleGetRequest} value CalendarOffTimeRuleGetRequest received value
   */
  onReceive_CalendarOffTimeRuleGetRequest (value) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleGetResponse receive handler
   * @this {!Client}
   * @param {!CalendarOffTimeRuleGetResponse} value CalendarOffTimeRuleGetResponse received value
   */
  onReceive_CalendarOffTimeRuleGetResponse (value) {}  // eslint-disable-line

  /**
   * CalendarOffTimeRuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!CalendarOffTimeRuleUpdateNotify} value CalendarOffTimeRuleUpdateNotify received value
   */
  onReceive_CalendarOffTimeRuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CalendarRulesGetRequest receive handler
   * @this {!Client}
   * @param {!CalendarRulesGetRequest} value CalendarRulesGetRequest received value
   */
  onReceive_CalendarRulesGetRequest (value) {}  // eslint-disable-line

  /**
   * CalendarRulesGetResponse receive handler
   * @this {!Client}
   * @param {!CalendarRulesGetResponse} value CalendarRulesGetResponse received value
   */
  onReceive_CalendarRulesGetResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRangeRequest receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryRangeRequest} value ChartBarHistoryRangeRequest received value
   */
  onReceive_ChartBarHistoryRangeRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginSubscribeRequest receive handler
   * @this {!Client}
   * @param {!OrderMarginSubscribeRequest} value OrderMarginSubscribeRequest received value
   */
  onReceive_OrderMarginSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginSubscribeResponse receive handler
   * @this {!Client}
   * @param {!OrderMarginSubscribeResponse} value OrderMarginSubscribeResponse received value
   */
  onReceive_OrderMarginSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderMarginNotify receive handler
   * @this {!Client}
   * @param {!OrderMarginNotify} value OrderMarginNotify received value
   */
  onReceive_OrderMarginNotify (value) {}  // eslint-disable-line

  /**
   * OrderMarginUpdateSubscriptionRequest receive handler
   * @this {!Client}
   * @param {!OrderMarginUpdateSubscriptionRequest} value OrderMarginUpdateSubscriptionRequest received value
   */
  onReceive_OrderMarginUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!OrderMarginUnsubscribeRequest} value OrderMarginUnsubscribeRequest received value
   */
  onReceive_OrderMarginUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!OrderMarginUnsubscribeResponse} value OrderMarginUnsubscribeResponse received value
   */
  onReceive_OrderMarginUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderMarginGetRequest receive handler
   * @this {!Client}
   * @param {!OrderMarginGetRequest} value OrderMarginGetRequest received value
   */
  onReceive_OrderMarginGetRequest (value) {}  // eslint-disable-line

  /**
   * OrderMarginGetResponse receive handler
   * @this {!Client}
   * @param {!OrderMarginGetResponse} value OrderMarginGetResponse received value
   */
  onReceive_OrderMarginGetResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoSubscribeRequest receive handler
   * @this {!Client}
   * @param {!OrderStateInfoSubscribeRequest} value OrderStateInfoSubscribeRequest received value
   */
  onReceive_OrderStateInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoSubscribeResponse receive handler
   * @this {!Client}
   * @param {!OrderStateInfoSubscribeResponse} value OrderStateInfoSubscribeResponse received value
   */
  onReceive_OrderStateInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!OrderStateInfoUnsubscribeRequest} value OrderStateInfoUnsubscribeRequest received value
   */
  onReceive_OrderStateInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!OrderStateInfoUnsubscribeResponse} value OrderStateInfoUnsubscribeResponse received value
   */
  onReceive_OrderStateInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * OrderStateInfoNotify receive handler
   * @this {!Client}
   * @param {!OrderStateInfoNotify} value OrderStateInfoNotify received value
   */
  onReceive_OrderStateInfoNotify (value) {}  // eslint-disable-line

  /**
   * Deprecated003 receive handler
   * @this {!Client}
   * @param {!Deprecated003} value Deprecated003 received value
   */
  onReceive_Deprecated003 (value) {}  // eslint-disable-line

  /**
   * Deprecated004 receive handler
   * @this {!Client}
   * @param {!Deprecated004} value Deprecated004 received value
   */
  onReceive_Deprecated004 (value) {}  // eslint-disable-line

  /**
   * Deprecated005 receive handler
   * @this {!Client}
   * @param {!Deprecated005} value Deprecated005 received value
   */
  onReceive_Deprecated005 (value) {}  // eslint-disable-line

  /**
   * Deprecated006 receive handler
   * @this {!Client}
   * @param {!Deprecated006} value Deprecated006 received value
   */
  onReceive_Deprecated006 (value) {}  // eslint-disable-line

  /**
   * Deprecated007 receive handler
   * @this {!Client}
   * @param {!Deprecated007} value Deprecated007 received value
   */
  onReceive_Deprecated007 (value) {}  // eslint-disable-line

  /**
   * Deprecated008 receive handler
   * @this {!Client}
   * @param {!Deprecated008} value Deprecated008 received value
   */
  onReceive_Deprecated008 (value) {}  // eslint-disable-line

  /**
   * AccountUpdateRequest receive handler
   * @this {!Client}
   * @param {!AccountUpdateRequest} value AccountUpdateRequest received value
   */
  onReceive_AccountUpdateRequest (value) {}  // eslint-disable-line

  /**
   * AccountUpdateResponse receive handler
   * @this {!Client}
   * @param {!AccountUpdateResponse} value AccountUpdateResponse received value
   */
  onReceive_AccountUpdateResponse (value) {}  // eslint-disable-line

  /**
   * ProfitClosedInfoGetRequest receive handler
   * @this {!Client}
   * @param {!ProfitClosedInfoGetRequest} value ProfitClosedInfoGetRequest received value
   */
  onReceive_ProfitClosedInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * ProfitClosedInfoGetResponse receive handler
   * @this {!Client}
   * @param {!ProfitClosedInfoGetResponse} value ProfitClosedInfoGetResponse received value
   */
  onReceive_ProfitClosedInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoSubscribeRequest receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoSubscribeRequest} value SymbolDayProfitLossInfoSubscribeRequest received value
   */
  onReceive_SymbolDayProfitLossInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoSubscribeResponse receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoSubscribeResponse} value SymbolDayProfitLossInfoSubscribeResponse received value
   */
  onReceive_SymbolDayProfitLossInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUpdateSubscriptionRequest receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoUpdateSubscriptionRequest} value SymbolDayProfitLossInfoUpdateSubscriptionRequest received value
   */
  onReceive_SymbolDayProfitLossInfoUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoUnsubscribeRequest} value SymbolDayProfitLossInfoUnsubscribeRequest received value
   */
  onReceive_SymbolDayProfitLossInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoUnsubscribeResponse} value SymbolDayProfitLossInfoUnsubscribeResponse received value
   */
  onReceive_SymbolDayProfitLossInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossInfoNotify receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossInfoNotify} value SymbolDayProfitLossInfoNotify received value
   */
  onReceive_SymbolDayProfitLossInfoNotify (value) {}  // eslint-disable-line

  /**
   * LoginInfoGetRequest receive handler
   * @this {!Client}
   * @param {!LoginInfoGetRequest} value LoginInfoGetRequest received value
   */
  onReceive_LoginInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * LoginInfoGetResponse receive handler
   * @this {!Client}
   * @param {!LoginInfoGetResponse} value LoginInfoGetResponse received value
   */
  onReceive_LoginInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoCreateRequest receive handler
   * @this {!Client}
   * @param {!DashboardInfoCreateRequest} value DashboardInfoCreateRequest received value
   */
  onReceive_DashboardInfoCreateRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoCreateResponse receive handler
   * @this {!Client}
   * @param {!DashboardInfoCreateResponse} value DashboardInfoCreateResponse received value
   */
  onReceive_DashboardInfoCreateResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoUpdateRequest receive handler
   * @this {!Client}
   * @param {!DashboardInfoUpdateRequest} value DashboardInfoUpdateRequest received value
   */
  onReceive_DashboardInfoUpdateRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoUpdateResponse receive handler
   * @this {!Client}
   * @param {!DashboardInfoUpdateResponse} value DashboardInfoUpdateResponse received value
   */
  onReceive_DashboardInfoUpdateResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoListRequest receive handler
   * @this {!Client}
   * @param {!DashboardInfoListRequest} value DashboardInfoListRequest received value
   */
  onReceive_DashboardInfoListRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoListResponse receive handler
   * @this {!Client}
   * @param {!DashboardInfoListResponse} value DashboardInfoListResponse received value
   */
  onReceive_DashboardInfoListResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoGetRequest receive handler
   * @this {!Client}
   * @param {!DashboardInfoGetRequest} value DashboardInfoGetRequest received value
   */
  onReceive_DashboardInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoGetResponse receive handler
   * @this {!Client}
   * @param {!DashboardInfoGetResponse} value DashboardInfoGetResponse received value
   */
  onReceive_DashboardInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoRenameRequest receive handler
   * @this {!Client}
   * @param {!DashboardInfoRenameRequest} value DashboardInfoRenameRequest received value
   */
  onReceive_DashboardInfoRenameRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoRenameResponse receive handler
   * @this {!Client}
   * @param {!DashboardInfoRenameResponse} value DashboardInfoRenameResponse received value
   */
  onReceive_DashboardInfoRenameResponse (value) {}  // eslint-disable-line

  /**
   * DashboardInfoDeleteRequest receive handler
   * @this {!Client}
   * @param {!DashboardInfoDeleteRequest} value DashboardInfoDeleteRequest received value
   */
  onReceive_DashboardInfoDeleteRequest (value) {}  // eslint-disable-line

  /**
   * DashboardInfoDeleteResponse receive handler
   * @this {!Client}
   * @param {!DashboardInfoDeleteResponse} value DashboardInfoDeleteResponse received value
   */
  onReceive_DashboardInfoDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoCreateRequest receive handler
   * @this {!Client}
   * @param {!ChartInfoCreateRequest} value ChartInfoCreateRequest received value
   */
  onReceive_ChartInfoCreateRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoCreateResponse receive handler
   * @this {!Client}
   * @param {!ChartInfoCreateResponse} value ChartInfoCreateResponse received value
   */
  onReceive_ChartInfoCreateResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoUpdateRequest receive handler
   * @this {!Client}
   * @param {!ChartInfoUpdateRequest} value ChartInfoUpdateRequest received value
   */
  onReceive_ChartInfoUpdateRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoUpdateResponse receive handler
   * @this {!Client}
   * @param {!ChartInfoUpdateResponse} value ChartInfoUpdateResponse received value
   */
  onReceive_ChartInfoUpdateResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoListRequest receive handler
   * @this {!Client}
   * @param {!ChartInfoListRequest} value ChartInfoListRequest received value
   */
  onReceive_ChartInfoListRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoListResponse receive handler
   * @this {!Client}
   * @param {!ChartInfoListResponse} value ChartInfoListResponse received value
   */
  onReceive_ChartInfoListResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoDeleteRequest receive handler
   * @this {!Client}
   * @param {!ChartInfoDeleteRequest} value ChartInfoDeleteRequest received value
   */
  onReceive_ChartInfoDeleteRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoDeleteResponse receive handler
   * @this {!Client}
   * @param {!ChartInfoDeleteResponse} value ChartInfoDeleteResponse received value
   */
  onReceive_ChartInfoDeleteResponse (value) {}  // eslint-disable-line

  /**
   * ChartInfoGetRequest receive handler
   * @this {!Client}
   * @param {!ChartInfoGetRequest} value ChartInfoGetRequest received value
   */
  onReceive_ChartInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * ChartInfoGetResponse receive handler
   * @this {!Client}
   * @param {!ChartInfoGetResponse} value ChartInfoGetResponse received value
   */
  onReceive_ChartInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoSubscribeRequest receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeRequest} value SymbolDayProfitLossMultipleInfoSubscribeRequest received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoSubscribeResponse receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoSubscribeResponse} value SymbolDayProfitLossMultipleInfoSubscribeResponse received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest} value SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeRequest} value SymbolDayProfitLossMultipleInfoUnsubscribeRequest received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoUnsubscribeResponse} value SymbolDayProfitLossMultipleInfoUnsubscribeResponse received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolDayProfitLossMultipleInfoNotify receive handler
   * @this {!Client}
   * @param {!SymbolDayProfitLossMultipleInfoNotify} value SymbolDayProfitLossMultipleInfoNotify received value
   */
  onReceive_SymbolDayProfitLossMultipleInfoNotify (value) {}  // eslint-disable-line

  /**
   * NewsServiceGetAllRequest receive handler
   * @this {!Client}
   * @param {!NewsServiceGetAllRequest} value NewsServiceGetAllRequest received value
   */
  onReceive_NewsServiceGetAllRequest (value) {}  // eslint-disable-line

  /**
   * NewsServiceGetAllResponse receive handler
   * @this {!Client}
   * @param {!NewsServiceGetAllResponse} value NewsServiceGetAllResponse received value
   */
  onReceive_NewsServiceGetAllResponse (value) {}  // eslint-disable-line

  /**
   * NewsRecentArticlesGetRequest receive handler
   * @this {!Client}
   * @param {!NewsRecentArticlesGetRequest} value NewsRecentArticlesGetRequest received value
   */
  onReceive_NewsRecentArticlesGetRequest (value) {}  // eslint-disable-line

  /**
   * NewsRecentArticlesGetResponse receive handler
   * @this {!Client}
   * @param {!NewsRecentArticlesGetResponse} value NewsRecentArticlesGetResponse received value
   */
  onReceive_NewsRecentArticlesGetResponse (value) {}  // eslint-disable-line

  /**
   * NewsArticleGetRequest receive handler
   * @this {!Client}
   * @param {!NewsArticleGetRequest} value NewsArticleGetRequest received value
   */
  onReceive_NewsArticleGetRequest (value) {}  // eslint-disable-line

  /**
   * NewsArticleGetResponse receive handler
   * @this {!Client}
   * @param {!NewsArticleGetResponse} value NewsArticleGetResponse received value
   */
  onReceive_NewsArticleGetResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeRequest receive handler
   * @this {!Client}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest received value
   */
  onReceive_OrderUpdateWithTypeRequest (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeResponse receive handler
   * @this {!Client}
   * @param {!OrderUpdateWithTypeResponse} value OrderUpdateWithTypeResponse received value
   */
  onReceive_OrderUpdateWithTypeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoSubscribeRequest receive handler
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoSubscribeRequest} value SymbolProfitLossMultipleInfoSubscribeRequest received value
   */
  onReceive_SymbolProfitLossMultipleInfoSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoSubscribeResponse receive handler
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoSubscribeResponse} value SymbolProfitLossMultipleInfoSubscribeResponse received value
   */
  onReceive_SymbolProfitLossMultipleInfoSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUpdateSubscriptionRequest receive handler
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoUpdateSubscriptionRequest} value SymbolProfitLossMultipleInfoUpdateSubscriptionRequest received value
   */
  onReceive_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeRequest} value SymbolProfitLossMultipleInfoUnsubscribeRequest received value
   */
  onReceive_SymbolProfitLossMultipleInfoUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoUnsubscribeResponse} value SymbolProfitLossMultipleInfoUnsubscribeResponse received value
   */
  onReceive_SymbolProfitLossMultipleInfoUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * SymbolProfitLossMultipleInfoNotify receive handler
   * @this {!Client}
   * @param {!SymbolProfitLossMultipleInfoNotify} value SymbolProfitLossMultipleInfoNotify received value
   */
  onReceive_SymbolProfitLossMultipleInfoNotify (value) {}  // eslint-disable-line

  /**
   * LoginWithPasswordRequest receive handler
   * @this {!Client}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest received value
   */
  onReceive_LoginWithPasswordRequest (value) {}  // eslint-disable-line

  /**
   * LoginWithPasswordResponse receive handler
   * @this {!Client}
   * @param {!LoginWithPasswordResponse} value LoginWithPasswordResponse received value
   */
  onReceive_LoginWithPasswordResponse (value) {}  // eslint-disable-line

  /**
   * LoginWithTokenRequest receive handler
   * @this {!Client}
   * @param {!LoginWithTokenRequest} value LoginWithTokenRequest received value
   */
  onReceive_LoginWithTokenRequest (value) {}  // eslint-disable-line

  /**
   * LoginWithTokenResponse receive handler
   * @this {!Client}
   * @param {!LoginWithTokenResponse} value LoginWithTokenResponse received value
   */
  onReceive_LoginWithTokenResponse (value) {}  // eslint-disable-line

  /**
   * RefreshTokenRequest receive handler
   * @this {!Client}
   * @param {!RefreshTokenRequest} value RefreshTokenRequest received value
   */
  onReceive_RefreshTokenRequest (value) {}  // eslint-disable-line

  /**
   * RefreshTokenResponse receive handler
   * @this {!Client}
   * @param {!RefreshTokenResponse} value RefreshTokenResponse received value
   */
  onReceive_RefreshTokenResponse (value) {}  // eslint-disable-line

  /**
   * Logout2Request receive handler
   * @this {!Client}
   * @param {!Logout2Request} value Logout2Request received value
   */
  onReceive_Logout2Request (value) {}  // eslint-disable-line

  /**
   * Logout2Response receive handler
   * @this {!Client}
   * @param {!Logout2Response} value Logout2Response received value
   */
  onReceive_Logout2Response (value) {}  // eslint-disable-line

  /**
   * AccountChangePasswordRequest receive handler
   * @this {!Client}
   * @param {!AccountChangePasswordRequest} value AccountChangePasswordRequest received value
   */
  onReceive_AccountChangePasswordRequest (value) {}  // eslint-disable-line

  /**
   * AccountChangePasswordResponse receive handler
   * @this {!Client}
   * @param {!AccountChangePasswordResponse} value AccountChangePasswordResponse received value
   */
  onReceive_AccountChangePasswordResponse (value) {}  // eslint-disable-line

  /**
   * PushNotificationTokenRegisterRequest receive handler
   * @this {!Client}
   * @param {!PushNotificationTokenRegisterRequest} value PushNotificationTokenRegisterRequest received value
   */
  onReceive_PushNotificationTokenRegisterRequest (value) {}  // eslint-disable-line

  /**
   * PushNotificationTokenRegisterResponse receive handler
   * @this {!Client}
   * @param {!PushNotificationTokenRegisterResponse} value PushNotificationTokenRegisterResponse received value
   */
  onReceive_PushNotificationTokenRegisterResponse (value) {}  // eslint-disable-line

  /**
   * DesiredAppSettingsGetRequest receive handler
   * @this {!Client}
   * @param {!DesiredAppSettingsGetRequest} value DesiredAppSettingsGetRequest received value
   */
  onReceive_DesiredAppSettingsGetRequest (value) {}  // eslint-disable-line

  /**
   * DesiredAppSettingsGetResponse receive handler
   * @this {!Client}
   * @param {!DesiredAppSettingsGetResponse} value DesiredAppSettingsGetResponse received value
   */
  onReceive_DesiredAppSettingsGetResponse (value) {}  // eslint-disable-line

  /**
   * AuthTokensGetAllRequest receive handler
   * @this {!Client}
   * @param {!AuthTokensGetAllRequest} value AuthTokensGetAllRequest received value
   */
  onReceive_AuthTokensGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AuthTokensGetAllResponse receive handler
   * @this {!Client}
   * @param {!AuthTokensGetAllResponse} value AuthTokensGetAllResponse received value
   */
  onReceive_AuthTokensGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AuthTokensDeleteRequest receive handler
   * @this {!Client}
   * @param {!AuthTokensDeleteRequest} value AuthTokensDeleteRequest received value
   */
  onReceive_AuthTokensDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AuthTokensDeleteResponse receive handler
   * @this {!Client}
   * @param {!AuthTokensDeleteResponse} value AuthTokensDeleteResponse received value
   */
  onReceive_AuthTokensDeleteResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupListRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupListRequest} value LegalDocumentGroupListRequest received value
   */
  onReceive_LegalDocumentGroupListRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGroupListResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentGroupListResponse} value LegalDocumentGroupListResponse received value
   */
  onReceive_LegalDocumentGroupListResponse (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetRequest receive handler
   * @this {!Client}
   * @param {!LegalDocumentGetRequest} value LegalDocumentGetRequest received value
   */
  onReceive_LegalDocumentGetRequest (value) {}  // eslint-disable-line

  /**
   * LegalDocumentGetResponse receive handler
   * @this {!Client}
   * @param {!LegalDocumentGetResponse} value LegalDocumentGetResponse received value
   */
  onReceive_LegalDocumentGetResponse (value) {}  // eslint-disable-line

  /**
   * PushNotificationTokenUnRegisterRequest receive handler
   * @this {!Client}
   * @param {!PushNotificationTokenUnRegisterRequest} value PushNotificationTokenUnRegisterRequest received value
   */
  onReceive_PushNotificationTokenUnRegisterRequest (value) {}  // eslint-disable-line

  /**
   * PushNotificationTokenUnRegisterResponse receive handler
   * @this {!Client}
   * @param {!PushNotificationTokenUnRegisterResponse} value PushNotificationTokenUnRegisterResponse received value
   */
  onReceive_PushNotificationTokenUnRegisterResponse (value) {}  // eslint-disable-line

  /**
   * client receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case PingRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PingRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PingRequestReceiverModel.verify(), 'client.PingRequest validation failed!')
        let deserialized = this._PingRequestReceiverModel.deserialize(this._PingRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.PingRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PingRequestReceiverValue)
        this.onReceive_PingRequest(this._PingRequestReceiverValue)
        return true
      }
      case PongResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PongResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PongResponseReceiverModel.verify(), 'client.PongResponse validation failed!')
        let deserialized = this._PongResponseReceiverModel.deserialize(this._PongResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.PongResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PongResponseReceiverValue)
        this.onReceive_PongResponse(this._PongResponseReceiverValue)
        return true
      }
      case LoginRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginRequestReceiverModel.verify(), 'client.LoginRequest validation failed!')
        let deserialized = this._LoginRequestReceiverModel.deserialize(this._LoginRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginRequestReceiverValue)
        this.onReceive_LoginRequest(this._LoginRequestReceiverValue)
        return true
      }
      case LoginResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginResponseReceiverModel.verify(), 'client.LoginResponse validation failed!')
        let deserialized = this._LoginResponseReceiverModel.deserialize(this._LoginResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginResponseReceiverValue)
        this.onReceive_LoginResponse(this._LoginResponseReceiverValue)
        return true
      }
      case LoginRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginRejectReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginRejectReceiverModel.verify(), 'client.LoginReject validation failed!')
        let deserialized = this._LoginRejectReceiverModel.deserialize(this._LoginRejectReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginRejectReceiverValue)
        this.onReceive_LoginReject(this._LoginRejectReceiverValue)
        return true
      }
      case LogoutRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LogoutRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutRequestReceiverModel.verify(), 'client.LogoutRequest validation failed!')
        let deserialized = this._LogoutRequestReceiverModel.deserialize(this._LogoutRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.LogoutRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LogoutRequestReceiverValue)
        this.onReceive_LogoutRequest(this._LogoutRequestReceiverValue)
        return true
      }
      case LogoutResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LogoutResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutResponseReceiverModel.verify(), 'client.LogoutResponse validation failed!')
        let deserialized = this._LogoutResponseReceiverModel.deserialize(this._LogoutResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.LogoutResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LogoutResponseReceiverValue)
        this.onReceive_LogoutResponse(this._LogoutResponseReceiverValue)
        return true
      }
      case LogoutNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LogoutNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LogoutNotifyReceiverModel.verify(), 'client.LogoutNotify validation failed!')
        let deserialized = this._LogoutNotifyReceiverModel.deserialize(this._LogoutNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.LogoutNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LogoutNotifyReceiverValue)
        this.onReceive_LogoutNotify(this._LogoutNotifyReceiverValue)
        return true
      }
      case SessionInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SessionInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SessionInfoRequestReceiverModel.verify(), 'client.SessionInfoRequest validation failed!')
        let deserialized = this._SessionInfoRequestReceiverModel.deserialize(this._SessionInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SessionInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SessionInfoRequestReceiverValue)
        this.onReceive_SessionInfoRequest(this._SessionInfoRequestReceiverValue)
        return true
      }
      case SessionInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SessionInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SessionInfoResponseReceiverModel.verify(), 'client.SessionInfoResponse validation failed!')
        let deserialized = this._SessionInfoResponseReceiverModel.deserialize(this._SessionInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SessionInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SessionInfoResponseReceiverValue)
        this.onReceive_SessionInfoResponse(this._SessionInfoResponseReceiverValue)
        return true
      }
      case TimeInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimeInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimeInfoRequestReceiverModel.verify(), 'client.TimeInfoRequest validation failed!')
        let deserialized = this._TimeInfoRequestReceiverModel.deserialize(this._TimeInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.TimeInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimeInfoRequestReceiverValue)
        this.onReceive_TimeInfoRequest(this._TimeInfoRequestReceiverValue)
        return true
      }
      case TimeInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimeInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimeInfoResponseReceiverModel.verify(), 'client.TimeInfoResponse validation failed!')
        let deserialized = this._TimeInfoResponseReceiverModel.deserialize(this._TimeInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.TimeInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimeInfoResponseReceiverValue)
        this.onReceive_TimeInfoResponse(this._TimeInfoResponseReceiverValue)
        return true
      }
      case TimezoneInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoRequestReceiverModel.verify(), 'client.TimezoneInfoRequest validation failed!')
        let deserialized = this._TimezoneInfoRequestReceiverModel.deserialize(this._TimezoneInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.TimezoneInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneInfoRequestReceiverValue)
        this.onReceive_TimezoneInfoRequest(this._TimezoneInfoRequestReceiverValue)
        return true
      }
      case TimezoneInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoResponseReceiverModel.verify(), 'client.TimezoneInfoResponse validation failed!')
        let deserialized = this._TimezoneInfoResponseReceiverModel.deserialize(this._TimezoneInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.TimezoneInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneInfoResponseReceiverValue)
        this.onReceive_TimezoneInfoResponse(this._TimezoneInfoResponseReceiverValue)
        return true
      }
      case TimezoneInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TimezoneInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TimezoneInfoUpdateNotifyReceiverModel.verify(), 'client.TimezoneInfoUpdateNotify validation failed!')
        let deserialized = this._TimezoneInfoUpdateNotifyReceiverModel.deserialize(this._TimezoneInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.TimezoneInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TimezoneInfoUpdateNotifyReceiverValue)
        this.onReceive_TimezoneInfoUpdateNotify(this._TimezoneInfoUpdateNotifyReceiverValue)
        return true
      }
      case CurrencyInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoRequestReceiverModel.verify(), 'client.CurrencyInfoRequest validation failed!')
        let deserialized = this._CurrencyInfoRequestReceiverModel.deserialize(this._CurrencyInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.CurrencyInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyInfoRequestReceiverValue)
        this.onReceive_CurrencyInfoRequest(this._CurrencyInfoRequestReceiverValue)
        return true
      }
      case CurrencyInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoResponseReceiverModel.verify(), 'client.CurrencyInfoResponse validation failed!')
        let deserialized = this._CurrencyInfoResponseReceiverModel.deserialize(this._CurrencyInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.CurrencyInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyInfoResponseReceiverValue)
        this.onReceive_CurrencyInfoResponse(this._CurrencyInfoResponseReceiverValue)
        return true
      }
      case CurrencyListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyListRequestReceiverModel.verify(), 'client.CurrencyListRequest validation failed!')
        let deserialized = this._CurrencyListRequestReceiverModel.deserialize(this._CurrencyListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.CurrencyListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyListRequestReceiverValue)
        this.onReceive_CurrencyListRequest(this._CurrencyListRequestReceiverValue)
        return true
      }
      case CurrencyListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyListResponseReceiverModel.verify(), 'client.CurrencyListResponse validation failed!')
        let deserialized = this._CurrencyListResponseReceiverModel.deserialize(this._CurrencyListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.CurrencyListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyListResponseReceiverValue)
        this.onReceive_CurrencyListResponse(this._CurrencyListResponseReceiverValue)
        return true
      }
      case CurrencyInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyInfoUpdateNotifyReceiverModel.verify(), 'client.CurrencyInfoUpdateNotify validation failed!')
        let deserialized = this._CurrencyInfoUpdateNotifyReceiverModel.deserialize(this._CurrencyInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.CurrencyInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyInfoUpdateNotifyReceiverValue)
        this.onReceive_CurrencyInfoUpdateNotify(this._CurrencyInfoUpdateNotifyReceiverValue)
        return true
      }
      case SymbolInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoRequestReceiverModel.verify(), 'client.SymbolInfoRequest validation failed!')
        let deserialized = this._SymbolInfoRequestReceiverModel.deserialize(this._SymbolInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolInfoRequestReceiverValue)
        this.onReceive_SymbolInfoRequest(this._SymbolInfoRequestReceiverValue)
        return true
      }
      case SymbolInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoResponseReceiverModel.verify(), 'client.SymbolInfoResponse validation failed!')
        let deserialized = this._SymbolInfoResponseReceiverModel.deserialize(this._SymbolInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolInfoResponseReceiverValue)
        this.onReceive_SymbolInfoResponse(this._SymbolInfoResponseReceiverValue)
        return true
      }
      case SymbolListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolListRequestReceiverModel.verify(), 'client.SymbolListRequest validation failed!')
        let deserialized = this._SymbolListRequestReceiverModel.deserialize(this._SymbolListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolListRequestReceiverValue)
        this.onReceive_SymbolListRequest(this._SymbolListRequestReceiverValue)
        return true
      }
      case SymbolListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolListResponseReceiverModel.verify(), 'client.SymbolListResponse validation failed!')
        let deserialized = this._SymbolListResponseReceiverModel.deserialize(this._SymbolListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolListResponseReceiverValue)
        this.onReceive_SymbolListResponse(this._SymbolListResponseReceiverValue)
        return true
      }
      case SymbolInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolInfoUpdateNotifyReceiverModel.verify(), 'client.SymbolInfoUpdateNotify validation failed!')
        let deserialized = this._SymbolInfoUpdateNotifyReceiverModel.deserialize(this._SymbolInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolInfoUpdateNotifyReceiverValue)
        this.onReceive_SymbolInfoUpdateNotify(this._SymbolInfoUpdateNotifyReceiverValue)
        return true
      }
      case TickInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickInfoRequestReceiverModel.verify(), 'client.TickInfoRequest validation failed!')
        let deserialized = this._TickInfoRequestReceiverModel.deserialize(this._TickInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.TickInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickInfoRequestReceiverValue)
        this.onReceive_TickInfoRequest(this._TickInfoRequestReceiverValue)
        return true
      }
      case TickInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickInfoResponseReceiverModel.verify(), 'client.TickInfoResponse validation failed!')
        let deserialized = this._TickInfoResponseReceiverModel.deserialize(this._TickInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.TickInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickInfoResponseReceiverValue)
        this.onReceive_TickInfoResponse(this._TickInfoResponseReceiverValue)
        return true
      }
      case TickFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickFilterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickFilterRequestReceiverModel.verify(), 'client.TickFilterRequest validation failed!')
        let deserialized = this._TickFilterRequestReceiverModel.deserialize(this._TickFilterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.TickFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickFilterRequestReceiverValue)
        this.onReceive_TickFilterRequest(this._TickFilterRequestReceiverValue)
        return true
      }
      case TickFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickFilterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickFilterResponseReceiverModel.verify(), 'client.TickFilterResponse validation failed!')
        let deserialized = this._TickFilterResponseReceiverModel.deserialize(this._TickFilterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.TickFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickFilterResponseReceiverValue)
        this.onReceive_TickFilterResponse(this._TickFilterResponseReceiverValue)
        return true
      }
      case TickListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickListRequestReceiverModel.verify(), 'client.TickListRequest validation failed!')
        let deserialized = this._TickListRequestReceiverModel.deserialize(this._TickListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.TickListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickListRequestReceiverValue)
        this.onReceive_TickListRequest(this._TickListRequestReceiverValue)
        return true
      }
      case TickListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickListResponseReceiverModel.verify(), 'client.TickListResponse validation failed!')
        let deserialized = this._TickListResponseReceiverModel.deserialize(this._TickListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.TickListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickListResponseReceiverValue)
        this.onReceive_TickListResponse(this._TickListResponseReceiverValue)
        return true
      }
      case Level2InfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2InfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2InfoRequestReceiverModel.verify(), 'client.Level2InfoRequest validation failed!')
        let deserialized = this._Level2InfoRequestReceiverModel.deserialize(this._Level2InfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.Level2InfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2InfoRequestReceiverValue)
        this.onReceive_Level2InfoRequest(this._Level2InfoRequestReceiverValue)
        return true
      }
      case Level2InfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2InfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2InfoResponseReceiverModel.verify(), 'client.Level2InfoResponse validation failed!')
        let deserialized = this._Level2InfoResponseReceiverModel.deserialize(this._Level2InfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.Level2InfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2InfoResponseReceiverValue)
        this.onReceive_Level2InfoResponse(this._Level2InfoResponseReceiverValue)
        return true
      }
      case Level2FilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2FilterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2FilterRequestReceiverModel.verify(), 'client.Level2FilterRequest validation failed!')
        let deserialized = this._Level2FilterRequestReceiverModel.deserialize(this._Level2FilterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.Level2FilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2FilterRequestReceiverValue)
        this.onReceive_Level2FilterRequest(this._Level2FilterRequestReceiverValue)
        return true
      }
      case Level2FilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2FilterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2FilterResponseReceiverModel.verify(), 'client.Level2FilterResponse validation failed!')
        let deserialized = this._Level2FilterResponseReceiverModel.deserialize(this._Level2FilterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.Level2FilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2FilterResponseReceiverValue)
        this.onReceive_Level2FilterResponse(this._Level2FilterResponseReceiverValue)
        return true
      }
      case Level2ListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2ListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2ListRequestReceiverModel.verify(), 'client.Level2ListRequest validation failed!')
        let deserialized = this._Level2ListRequestReceiverModel.deserialize(this._Level2ListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.Level2ListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2ListRequestReceiverValue)
        this.onReceive_Level2ListRequest(this._Level2ListRequestReceiverValue)
        return true
      }
      case Level2ListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2ListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2ListResponseReceiverModel.verify(), 'client.Level2ListResponse validation failed!')
        let deserialized = this._Level2ListResponseReceiverModel.deserialize(this._Level2ListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.Level2ListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2ListResponseReceiverValue)
        this.onReceive_Level2ListResponse(this._Level2ListResponseReceiverValue)
        return true
      }
      case FeedSubscriptionInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscriptionInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionInfoRequestReceiverModel.verify(), 'client.FeedSubscriptionInfoRequest validation failed!')
        let deserialized = this._FeedSubscriptionInfoRequestReceiverModel.deserialize(this._FeedSubscriptionInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscriptionInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscriptionInfoRequestReceiverValue)
        this.onReceive_FeedSubscriptionInfoRequest(this._FeedSubscriptionInfoRequestReceiverValue)
        return true
      }
      case FeedSubscriptionInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscriptionInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionInfoResponseReceiverModel.verify(), 'client.FeedSubscriptionInfoResponse validation failed!')
        let deserialized = this._FeedSubscriptionInfoResponseReceiverModel.deserialize(this._FeedSubscriptionInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscriptionInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscriptionInfoResponseReceiverValue)
        this.onReceive_FeedSubscriptionInfoResponse(this._FeedSubscriptionInfoResponseReceiverValue)
        return true
      }
      case FeedSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeRequestReceiverModel.verify(), 'client.FeedSubscribeRequest validation failed!')
        let deserialized = this._FeedSubscribeRequestReceiverModel.deserialize(this._FeedSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeRequestReceiverValue)
        this.onReceive_FeedSubscribeRequest(this._FeedSubscribeRequestReceiverValue)
        return true
      }
      case FeedSubscribeAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeAllRequestReceiverModel.verify(), 'client.FeedSubscribeAllRequest validation failed!')
        let deserialized = this._FeedSubscribeAllRequestReceiverModel.deserialize(this._FeedSubscribeAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscribeAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeAllRequestReceiverValue)
        this.onReceive_FeedSubscribeAllRequest(this._FeedSubscribeAllRequestReceiverValue)
        return true
      }
      case FeedSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeResponseReceiverModel.verify(), 'client.FeedSubscribeResponse validation failed!')
        let deserialized = this._FeedSubscribeResponseReceiverModel.deserialize(this._FeedSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.FeedSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeResponseReceiverValue)
        this.onReceive_FeedSubscribeResponse(this._FeedSubscribeResponseReceiverValue)
        return true
      }
      case FeedUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeRequestReceiverModel.verify(), 'client.FeedUnsubscribeRequest validation failed!')
        let deserialized = this._FeedUnsubscribeRequestReceiverModel.deserialize(this._FeedUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.FeedUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeRequestReceiverValue)
        this.onReceive_FeedUnsubscribeRequest(this._FeedUnsubscribeRequestReceiverValue)
        return true
      }
      case FeedUnsubscribeAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeAllRequestReceiverModel.verify(), 'client.FeedUnsubscribeAllRequest validation failed!')
        let deserialized = this._FeedUnsubscribeAllRequestReceiverModel.deserialize(this._FeedUnsubscribeAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.FeedUnsubscribeAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeAllRequestReceiverValue)
        this.onReceive_FeedUnsubscribeAllRequest(this._FeedUnsubscribeAllRequestReceiverValue)
        return true
      }
      case FeedUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeResponseReceiverModel.verify(), 'client.FeedUnsubscribeResponse validation failed!')
        let deserialized = this._FeedUnsubscribeResponseReceiverModel.deserialize(this._FeedUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.FeedUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeResponseReceiverValue)
        this.onReceive_FeedUnsubscribeResponse(this._FeedUnsubscribeResponseReceiverValue)
        return true
      }
      case TickSnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickSnapshotNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickSnapshotNotifyReceiverModel.verify(), 'client.TickSnapshotNotify validation failed!')
        let deserialized = this._TickSnapshotNotifyReceiverModel.deserialize(this._TickSnapshotNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.TickSnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickSnapshotNotifyReceiverValue)
        this.onReceive_TickSnapshotNotify(this._TickSnapshotNotifyReceiverValue)
        return true
      }
      case TickUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickUpdateNotifyReceiverModel.verify(), 'client.TickUpdateNotify validation failed!')
        let deserialized = this._TickUpdateNotifyReceiverModel.deserialize(this._TickUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.TickUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickUpdateNotifyReceiverValue)
        this.onReceive_TickUpdateNotify(this._TickUpdateNotifyReceiverValue)
        return true
      }
      case Level2SnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2SnapshotNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2SnapshotNotifyReceiverModel.verify(), 'client.Level2SnapshotNotify validation failed!')
        let deserialized = this._Level2SnapshotNotifyReceiverModel.deserialize(this._Level2SnapshotNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.Level2SnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2SnapshotNotifyReceiverValue)
        this.onReceive_Level2SnapshotNotify(this._Level2SnapshotNotifyReceiverValue)
        return true
      }
      case Level2UpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2UpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2UpdateNotifyReceiverModel.verify(), 'client.Level2UpdateNotify validation failed!')
        let deserialized = this._Level2UpdateNotifyReceiverModel.deserialize(this._Level2UpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.Level2UpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2UpdateNotifyReceiverValue)
        this.onReceive_Level2UpdateNotify(this._Level2UpdateNotifyReceiverValue)
        return true
      }
      case ChartBarHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRequestReceiverModel.verify(), 'client.ChartBarHistoryRequest validation failed!')
        let deserialized = this._ChartBarHistoryRequestReceiverModel.deserialize(this._ChartBarHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryRequestReceiverValue)
        this.onReceive_ChartBarHistoryRequest(this._ChartBarHistoryRequestReceiverValue)
        return true
      }
      case ChartBarHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryResponseReceiverModel.verify(), 'client.ChartBarHistoryResponse validation failed!')
        let deserialized = this._ChartBarHistoryResponseReceiverModel.deserialize(this._ChartBarHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryResponseReceiverValue)
        this.onReceive_ChartBarHistoryResponse(this._ChartBarHistoryResponseReceiverValue)
        return true
      }
      case ChartBarSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarSubscribeRequestReceiverModel.verify(), 'client.ChartBarSubscribeRequest validation failed!')
        let deserialized = this._ChartBarSubscribeRequestReceiverModel.deserialize(this._ChartBarSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarSubscribeRequestReceiverValue)
        this.onReceive_ChartBarSubscribeRequest(this._ChartBarSubscribeRequestReceiverValue)
        return true
      }
      case ChartBarSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarSubscribeResponseReceiverModel.verify(), 'client.ChartBarSubscribeResponse validation failed!')
        let deserialized = this._ChartBarSubscribeResponseReceiverModel.deserialize(this._ChartBarSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarSubscribeResponseReceiverValue)
        this.onReceive_ChartBarSubscribeResponse(this._ChartBarSubscribeResponseReceiverValue)
        return true
      }
      case ChartBarUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUnsubscribeRequestReceiverModel.verify(), 'client.ChartBarUnsubscribeRequest validation failed!')
        let deserialized = this._ChartBarUnsubscribeRequestReceiverModel.deserialize(this._ChartBarUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarUnsubscribeRequestReceiverValue)
        this.onReceive_ChartBarUnsubscribeRequest(this._ChartBarUnsubscribeRequestReceiverValue)
        return true
      }
      case ChartBarUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUnsubscribeResponseReceiverModel.verify(), 'client.ChartBarUnsubscribeResponse validation failed!')
        let deserialized = this._ChartBarUnsubscribeResponseReceiverModel.deserialize(this._ChartBarUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarUnsubscribeResponseReceiverValue)
        this.onReceive_ChartBarUnsubscribeResponse(this._ChartBarUnsubscribeResponseReceiverValue)
        return true
      }
      case ChartBarNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarNotifyReceiverModel.verify(), 'client.ChartBarNotify validation failed!')
        let deserialized = this._ChartBarNotifyReceiverModel.deserialize(this._ChartBarNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarNotifyReceiverValue)
        this.onReceive_ChartBarNotify(this._ChartBarNotifyReceiverValue)
        return true
      }
      case ChartBarUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUpdateNotifyReceiverModel.verify(), 'client.ChartBarUpdateNotify validation failed!')
        let deserialized = this._ChartBarUpdateNotifyReceiverModel.deserialize(this._ChartBarUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarUpdateNotifyReceiverValue)
        this.onReceive_ChartBarUpdateNotify(this._ChartBarUpdateNotifyReceiverValue)
        return true
      }
      case PlatformInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoRequestReceiverModel.verify(), 'client.PlatformInfoRequest validation failed!')
        let deserialized = this._PlatformInfoRequestReceiverModel.deserialize(this._PlatformInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.PlatformInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformInfoRequestReceiverValue)
        this.onReceive_PlatformInfoRequest(this._PlatformInfoRequestReceiverValue)
        return true
      }
      case PlatformInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoResponseReceiverModel.verify(), 'client.PlatformInfoResponse validation failed!')
        let deserialized = this._PlatformInfoResponseReceiverModel.deserialize(this._PlatformInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.PlatformInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformInfoResponseReceiverValue)
        this.onReceive_PlatformInfoResponse(this._PlatformInfoResponseReceiverValue)
        return true
      }
      case PlatformInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PlatformInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PlatformInfoUpdateNotifyReceiverModel.verify(), 'client.PlatformInfoUpdateNotify validation failed!')
        let deserialized = this._PlatformInfoUpdateNotifyReceiverModel.deserialize(this._PlatformInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.PlatformInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PlatformInfoUpdateNotifyReceiverValue)
        this.onReceive_PlatformInfoUpdateNotify(this._PlatformInfoUpdateNotifyReceiverValue)
        return true
      }
      case AccountInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoRequestReceiverModel.verify(), 'client.AccountInfoRequest validation failed!')
        let deserialized = this._AccountInfoRequestReceiverModel.deserialize(this._AccountInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.AccountInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInfoRequestReceiverValue)
        this.onReceive_AccountInfoRequest(this._AccountInfoRequestReceiverValue)
        return true
      }
      case AccountInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoResponseReceiverModel.verify(), 'client.AccountInfoResponse validation failed!')
        let deserialized = this._AccountInfoResponseReceiverModel.deserialize(this._AccountInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.AccountInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInfoResponseReceiverValue)
        this.onReceive_AccountInfoResponse(this._AccountInfoResponseReceiverValue)
        return true
      }
      case AccountInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInfoUpdateNotifyReceiverModel.verify(), 'client.AccountInfoUpdateNotify validation failed!')
        let deserialized = this._AccountInfoUpdateNotifyReceiverModel.deserialize(this._AccountInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.AccountInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInfoUpdateNotifyReceiverValue)
        this.onReceive_AccountInfoUpdateNotify(this._AccountInfoUpdateNotifyReceiverValue)
        return true
      }
      case MarginInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoRequestReceiverModel.verify(), 'client.MarginInfoRequest validation failed!')
        let deserialized = this._MarginInfoRequestReceiverModel.deserialize(this._MarginInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.MarginInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginInfoRequestReceiverValue)
        this.onReceive_MarginInfoRequest(this._MarginInfoRequestReceiverValue)
        return true
      }
      case MarginInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoResponseReceiverModel.verify(), 'client.MarginInfoResponse validation failed!')
        let deserialized = this._MarginInfoResponseReceiverModel.deserialize(this._MarginInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.MarginInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginInfoResponseReceiverValue)
        this.onReceive_MarginInfoResponse(this._MarginInfoResponseReceiverValue)
        return true
      }
      case MarginInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginInfoUpdateNotifyReceiverModel.verify(), 'client.MarginInfoUpdateNotify validation failed!')
        let deserialized = this._MarginInfoUpdateNotifyReceiverModel.deserialize(this._MarginInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.MarginInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginInfoUpdateNotifyReceiverValue)
        this.onReceive_MarginInfoUpdateNotify(this._MarginInfoUpdateNotifyReceiverValue)
        return true
      }
      case MarginCallNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginCallNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginCallNotifyReceiverModel.verify(), 'client.MarginCallNotify validation failed!')
        let deserialized = this._MarginCallNotifyReceiverModel.deserialize(this._MarginCallNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.MarginCallNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginCallNotifyReceiverValue)
        this.onReceive_MarginCallNotify(this._MarginCallNotifyReceiverValue)
        return true
      }
      case StopOutNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StopOutNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StopOutNotifyReceiverModel.verify(), 'client.StopOutNotify validation failed!')
        let deserialized = this._StopOutNotifyReceiverModel.deserialize(this._StopOutNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.StopOutNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StopOutNotifyReceiverValue)
        this.onReceive_StopOutNotify(this._StopOutNotifyReceiverValue)
        return true
      }
      case AssetInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoRequestReceiverModel.verify(), 'client.AssetInfoRequest validation failed!')
        let deserialized = this._AssetInfoRequestReceiverModel.deserialize(this._AssetInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.AssetInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInfoRequestReceiverValue)
        this.onReceive_AssetInfoRequest(this._AssetInfoRequestReceiverValue)
        return true
      }
      case AssetInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoResponseReceiverModel.verify(), 'client.AssetInfoResponse validation failed!')
        let deserialized = this._AssetInfoResponseReceiverModel.deserialize(this._AssetInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.AssetInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInfoResponseReceiverValue)
        this.onReceive_AssetInfoResponse(this._AssetInfoResponseReceiverValue)
        return true
      }
      case AssetListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetListRequestReceiverModel.verify(), 'client.AssetListRequest validation failed!')
        let deserialized = this._AssetListRequestReceiverModel.deserialize(this._AssetListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.AssetListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetListRequestReceiverValue)
        this.onReceive_AssetListRequest(this._AssetListRequestReceiverValue)
        return true
      }
      case AssetListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetListResponseReceiverModel.verify(), 'client.AssetListResponse validation failed!')
        let deserialized = this._AssetListResponseReceiverModel.deserialize(this._AssetListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.AssetListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetListResponseReceiverValue)
        this.onReceive_AssetListResponse(this._AssetListResponseReceiverValue)
        return true
      }
      case AssetInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInfoUpdateNotifyReceiverModel.verify(), 'client.AssetInfoUpdateNotify validation failed!')
        let deserialized = this._AssetInfoUpdateNotifyReceiverModel.deserialize(this._AssetInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.AssetInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInfoUpdateNotifyReceiverValue)
        this.onReceive_AssetInfoUpdateNotify(this._AssetInfoUpdateNotifyReceiverValue)
        return true
      }
      case OrderInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoRequestReceiverModel.verify(), 'client.OrderInfoRequest validation failed!')
        let deserialized = this._OrderInfoRequestReceiverModel.deserialize(this._OrderInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInfoRequestReceiverValue)
        this.onReceive_OrderInfoRequest(this._OrderInfoRequestReceiverValue)
        return true
      }
      case OrderInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoResponseReceiverModel.verify(), 'client.OrderInfoResponse validation failed!')
        let deserialized = this._OrderInfoResponseReceiverModel.deserialize(this._OrderInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInfoResponseReceiverValue)
        this.onReceive_OrderInfoResponse(this._OrderInfoResponseReceiverValue)
        return true
      }
      case OrderListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderListRequestReceiverModel.verify(), 'client.OrderListRequest validation failed!')
        let deserialized = this._OrderListRequestReceiverModel.deserialize(this._OrderListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderListRequestReceiverValue)
        this.onReceive_OrderListRequest(this._OrderListRequestReceiverValue)
        return true
      }
      case OrderListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderListResponseReceiverModel.verify(), 'client.OrderListResponse validation failed!')
        let deserialized = this._OrderListResponseReceiverModel.deserialize(this._OrderListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderListResponseReceiverValue)
        this.onReceive_OrderListResponse(this._OrderListResponseReceiverValue)
        return true
      }
      case OrderOpenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderOpenRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderOpenRequestReceiverModel.verify(), 'client.OrderOpenRequest validation failed!')
        let deserialized = this._OrderOpenRequestReceiverModel.deserialize(this._OrderOpenRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderOpenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderOpenRequestReceiverValue)
        this.onReceive_OrderOpenRequest(this._OrderOpenRequestReceiverValue)
        return true
      }
      case OrderOpenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderOpenResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderOpenResponseReceiverModel.verify(), 'client.OrderOpenResponse validation failed!')
        let deserialized = this._OrderOpenResponseReceiverModel.deserialize(this._OrderOpenResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderOpenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderOpenResponseReceiverValue)
        this.onReceive_OrderOpenResponse(this._OrderOpenResponseReceiverValue)
        return true
      }
      case OrderUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateRequestReceiverModel.verify(), 'client.OrderUpdateRequest validation failed!')
        let deserialized = this._OrderUpdateRequestReceiverModel.deserialize(this._OrderUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateRequestReceiverValue)
        this.onReceive_OrderUpdateRequest(this._OrderUpdateRequestReceiverValue)
        return true
      }
      case OrderUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateResponseReceiverModel.verify(), 'client.OrderUpdateResponse validation failed!')
        let deserialized = this._OrderUpdateResponseReceiverModel.deserialize(this._OrderUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateResponseReceiverValue)
        this.onReceive_OrderUpdateResponse(this._OrderUpdateResponseReceiverValue)
        return true
      }
      case OrderCancelRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelRequestReceiverModel.verify(), 'client.OrderCancelRequest validation failed!')
        let deserialized = this._OrderCancelRequestReceiverModel.deserialize(this._OrderCancelRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelRequestReceiverValue)
        this.onReceive_OrderCancelRequest(this._OrderCancelRequestReceiverValue)
        return true
      }
      case OrderCancelResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelResponseReceiverModel.verify(), 'client.OrderCancelResponse validation failed!')
        let deserialized = this._OrderCancelResponseReceiverModel.deserialize(this._OrderCancelResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelResponseReceiverValue)
        this.onReceive_OrderCancelResponse(this._OrderCancelResponseReceiverValue)
        return true
      }
      case OrderCancelAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllRequestReceiverModel.verify(), 'client.OrderCancelAllRequest validation failed!')
        let deserialized = this._OrderCancelAllRequestReceiverModel.deserialize(this._OrderCancelAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllRequestReceiverValue)
        this.onReceive_OrderCancelAllRequest(this._OrderCancelAllRequestReceiverValue)
        return true
      }
      case OrderCancelAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllResponseReceiverModel.verify(), 'client.OrderCancelAllResponse validation failed!')
        let deserialized = this._OrderCancelAllResponseReceiverModel.deserialize(this._OrderCancelAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllResponseReceiverValue)
        this.onReceive_OrderCancelAllResponse(this._OrderCancelAllResponseReceiverValue)
        return true
      }
      case OrderCancelAllByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelAllByIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelAllByIdRequestReceiverModel.verify(), 'client.OrderCancelAllByIdRequest validation failed!')
        let deserialized = this._OrderCancelAllByIdRequestReceiverModel.deserialize(this._OrderCancelAllByIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelAllByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelAllByIdRequestReceiverValue)
        this.onReceive_OrderCancelAllByIdRequest(this._OrderCancelAllByIdRequestReceiverValue)
        return true
      }
      case OrderCloseRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseRequestReceiverModel.verify(), 'client.OrderCloseRequest validation failed!')
        let deserialized = this._OrderCloseRequestReceiverModel.deserialize(this._OrderCloseRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseRequestReceiverValue)
        this.onReceive_OrderCloseRequest(this._OrderCloseRequestReceiverValue)
        return true
      }
      case OrderCloseResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseResponseReceiverModel.verify(), 'client.OrderCloseResponse validation failed!')
        let deserialized = this._OrderCloseResponseReceiverModel.deserialize(this._OrderCloseResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseResponseReceiverValue)
        this.onReceive_OrderCloseResponse(this._OrderCloseResponseReceiverValue)
        return true
      }
      case OrderCloseAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllRequestReceiverModel.verify(), 'client.OrderCloseAllRequest validation failed!')
        let deserialized = this._OrderCloseAllRequestReceiverModel.deserialize(this._OrderCloseAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllRequestReceiverValue)
        this.onReceive_OrderCloseAllRequest(this._OrderCloseAllRequestReceiverValue)
        return true
      }
      case OrderCloseAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllResponseReceiverModel.verify(), 'client.OrderCloseAllResponse validation failed!')
        let deserialized = this._OrderCloseAllResponseReceiverModel.deserialize(this._OrderCloseAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllResponseReceiverValue)
        this.onReceive_OrderCloseAllResponse(this._OrderCloseAllResponseReceiverValue)
        return true
      }
      case OrderCloseAllByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseAllByIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseAllByIdRequestReceiverModel.verify(), 'client.OrderCloseAllByIdRequest validation failed!')
        let deserialized = this._OrderCloseAllByIdRequestReceiverModel.deserialize(this._OrderCloseAllByIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseAllByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseAllByIdRequestReceiverValue)
        this.onReceive_OrderCloseAllByIdRequest(this._OrderCloseAllByIdRequestReceiverValue)
        return true
      }
      case OrderCloseByRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByRequestReceiverModel.verify(), 'client.OrderCloseByRequest validation failed!')
        let deserialized = this._OrderCloseByRequestReceiverModel.deserialize(this._OrderCloseByRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseByRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByRequestReceiverValue)
        this.onReceive_OrderCloseByRequest(this._OrderCloseByRequestReceiverValue)
        return true
      }
      case OrderCloseByResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByResponseReceiverModel.verify(), 'client.OrderCloseByResponse validation failed!')
        let deserialized = this._OrderCloseByResponseReceiverModel.deserialize(this._OrderCloseByResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseByResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByResponseReceiverValue)
        this.onReceive_OrderCloseByResponse(this._OrderCloseByResponseReceiverValue)
        return true
      }
      case OrderCloseByAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllRequestReceiverModel.verify(), 'client.OrderCloseByAllRequest validation failed!')
        let deserialized = this._OrderCloseByAllRequestReceiverModel.deserialize(this._OrderCloseByAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseByAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByAllRequestReceiverValue)
        this.onReceive_OrderCloseByAllRequest(this._OrderCloseByAllRequestReceiverValue)
        return true
      }
      case OrderCloseByAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCloseByAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCloseByAllResponseReceiverModel.verify(), 'client.OrderCloseByAllResponse validation failed!')
        let deserialized = this._OrderCloseByAllResponseReceiverModel.deserialize(this._OrderCloseByAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCloseByAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCloseByAllResponseReceiverValue)
        this.onReceive_OrderCloseByAllResponse(this._OrderCloseByAllResponseReceiverValue)
        return true
      }
      case OrderCancelOrCloseRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseRequestReceiverModel.verify(), 'client.OrderCancelOrCloseRequest validation failed!')
        let deserialized = this._OrderCancelOrCloseRequestReceiverModel.deserialize(this._OrderCancelOrCloseRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelOrCloseRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseRequestReceiverValue)
        this.onReceive_OrderCancelOrCloseRequest(this._OrderCancelOrCloseRequestReceiverValue)
        return true
      }
      case OrderCancelOrCloseResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseResponseReceiverModel.verify(), 'client.OrderCancelOrCloseResponse validation failed!')
        let deserialized = this._OrderCancelOrCloseResponseReceiverModel.deserialize(this._OrderCancelOrCloseResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelOrCloseResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseResponseReceiverValue)
        this.onReceive_OrderCancelOrCloseResponse(this._OrderCancelOrCloseResponseReceiverValue)
        return true
      }
      case OrderCancelOrCloseAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllRequestReceiverModel.verify(), 'client.OrderCancelOrCloseAllRequest validation failed!')
        let deserialized = this._OrderCancelOrCloseAllRequestReceiverModel.deserialize(this._OrderCancelOrCloseAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelOrCloseAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseAllRequestReceiverValue)
        this.onReceive_OrderCancelOrCloseAllRequest(this._OrderCancelOrCloseAllRequestReceiverValue)
        return true
      }
      case OrderCancelOrCloseAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderCancelOrCloseAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderCancelOrCloseAllResponseReceiverModel.verify(), 'client.OrderCancelOrCloseAllResponse validation failed!')
        let deserialized = this._OrderCancelOrCloseAllResponseReceiverModel.deserialize(this._OrderCancelOrCloseAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderCancelOrCloseAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderCancelOrCloseAllResponseReceiverValue)
        this.onReceive_OrderCancelOrCloseAllResponse(this._OrderCancelOrCloseAllResponseReceiverValue)
        return true
      }
      case OrderInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInfoUpdateNotifyReceiverModel.verify(), 'client.OrderInfoUpdateNotify validation failed!')
        let deserialized = this._OrderInfoUpdateNotifyReceiverModel.deserialize(this._OrderInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInfoUpdateNotifyReceiverValue)
        this.onReceive_OrderInfoUpdateNotify(this._OrderInfoUpdateNotifyReceiverValue)
        return true
      }
      case PositionInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoRequestReceiverModel.verify(), 'client.PositionInfoRequest validation failed!')
        let deserialized = this._PositionInfoRequestReceiverModel.deserialize(this._PositionInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.PositionInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInfoRequestReceiverValue)
        this.onReceive_PositionInfoRequest(this._PositionInfoRequestReceiverValue)
        return true
      }
      case PositionInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoResponseReceiverModel.verify(), 'client.PositionInfoResponse validation failed!')
        let deserialized = this._PositionInfoResponseReceiverModel.deserialize(this._PositionInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.PositionInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInfoResponseReceiverValue)
        this.onReceive_PositionInfoResponse(this._PositionInfoResponseReceiverValue)
        return true
      }
      case PositionListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionListRequestReceiverModel.verify(), 'client.PositionListRequest validation failed!')
        let deserialized = this._PositionListRequestReceiverModel.deserialize(this._PositionListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.PositionListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionListRequestReceiverValue)
        this.onReceive_PositionListRequest(this._PositionListRequestReceiverValue)
        return true
      }
      case PositionListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionListResponseReceiverModel.verify(), 'client.PositionListResponse validation failed!')
        let deserialized = this._PositionListResponseReceiverModel.deserialize(this._PositionListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.PositionListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionListResponseReceiverValue)
        this.onReceive_PositionListResponse(this._PositionListResponseReceiverValue)
        return true
      }
      case PositionInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInfoUpdateNotifyReceiverModel.verify(), 'client.PositionInfoUpdateNotify validation failed!')
        let deserialized = this._PositionInfoUpdateNotifyReceiverModel.deserialize(this._PositionInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.PositionInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInfoUpdateNotifyReceiverValue)
        this.onReceive_PositionInfoUpdateNotify(this._PositionInfoUpdateNotifyReceiverValue)
        return true
      }
      case TradeHistoryInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoRequestReceiverModel.verify(), 'client.TradeHistoryInfoRequest validation failed!')
        let deserialized = this._TradeHistoryInfoRequestReceiverModel.deserialize(this._TradeHistoryInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoRequestReceiverValue)
        this.onReceive_TradeHistoryInfoRequest(this._TradeHistoryInfoRequestReceiverValue)
        return true
      }
      case TradeHistoryInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoResponseReceiverModel.verify(), 'client.TradeHistoryInfoResponse validation failed!')
        let deserialized = this._TradeHistoryInfoResponseReceiverModel.deserialize(this._TradeHistoryInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoResponseReceiverValue)
        this.onReceive_TradeHistoryInfoResponse(this._TradeHistoryInfoResponseReceiverValue)
        return true
      }
      case TradeHistoryInfoFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoFilterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoFilterRequestReceiverModel.verify(), 'client.TradeHistoryInfoFilterRequest validation failed!')
        let deserialized = this._TradeHistoryInfoFilterRequestReceiverModel.deserialize(this._TradeHistoryInfoFilterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoFilterRequestReceiverValue)
        this.onReceive_TradeHistoryInfoFilterRequest(this._TradeHistoryInfoFilterRequestReceiverValue)
        return true
      }
      case TradeHistoryInfoByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoByIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoByIdRequestReceiverModel.verify(), 'client.TradeHistoryInfoByIdRequest validation failed!')
        let deserialized = this._TradeHistoryInfoByIdRequestReceiverModel.deserialize(this._TradeHistoryInfoByIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoByIdRequestReceiverValue)
        this.onReceive_TradeHistoryInfoByIdRequest(this._TradeHistoryInfoByIdRequestReceiverValue)
        return true
      }
      case TradeHistoryInfoByIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoByIdResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoByIdResponseReceiverModel.verify(), 'client.TradeHistoryInfoByIdResponse validation failed!')
        let deserialized = this._TradeHistoryInfoByIdResponseReceiverModel.deserialize(this._TradeHistoryInfoByIdResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoByIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoByIdResponseReceiverValue)
        this.onReceive_TradeHistoryInfoByIdResponse(this._TradeHistoryInfoByIdResponseReceiverValue)
        return true
      }
      case TradeHistoryInfoUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryInfoUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryInfoUpdateNotifyReceiverModel.verify(), 'client.TradeHistoryInfoUpdateNotify validation failed!')
        let deserialized = this._TradeHistoryInfoUpdateNotifyReceiverModel.deserialize(this._TradeHistoryInfoUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.TradeHistoryInfoUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryInfoUpdateNotifyReceiverValue)
        this.onReceive_TradeHistoryInfoUpdateNotify(this._TradeHistoryInfoUpdateNotifyReceiverValue)
        return true
      }
      case CalendarRegularRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRegularRuleGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleGetRequestReceiverModel.verify(), 'client.CalendarRegularRuleGetRequest validation failed!')
        let deserialized = this._CalendarRegularRuleGetRequestReceiverModel.deserialize(this._CalendarRegularRuleGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.CalendarRegularRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRegularRuleGetRequestReceiverValue)
        this.onReceive_CalendarRegularRuleGetRequest(this._CalendarRegularRuleGetRequestReceiverValue)
        return true
      }
      case CalendarRegularRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRegularRuleGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleGetResponseReceiverModel.verify(), 'client.CalendarRegularRuleGetResponse validation failed!')
        let deserialized = this._CalendarRegularRuleGetResponseReceiverModel.deserialize(this._CalendarRegularRuleGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.CalendarRegularRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRegularRuleGetResponseReceiverValue)
        this.onReceive_CalendarRegularRuleGetResponse(this._CalendarRegularRuleGetResponseReceiverValue)
        return true
      }
      case CalendarRegularRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRegularRuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRegularRuleUpdateNotifyReceiverModel.verify(), 'client.CalendarRegularRuleUpdateNotify validation failed!')
        let deserialized = this._CalendarRegularRuleUpdateNotifyReceiverModel.deserialize(this._CalendarRegularRuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.CalendarRegularRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRegularRuleUpdateNotifyReceiverValue)
        this.onReceive_CalendarRegularRuleUpdateNotify(this._CalendarRegularRuleUpdateNotifyReceiverValue)
        return true
      }
      case CalendarOffTimeRuleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarOffTimeRuleGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleGetRequestReceiverModel.verify(), 'client.CalendarOffTimeRuleGetRequest validation failed!')
        let deserialized = this._CalendarOffTimeRuleGetRequestReceiverModel.deserialize(this._CalendarOffTimeRuleGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.CalendarOffTimeRuleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarOffTimeRuleGetRequestReceiverValue)
        this.onReceive_CalendarOffTimeRuleGetRequest(this._CalendarOffTimeRuleGetRequestReceiverValue)
        return true
      }
      case CalendarOffTimeRuleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarOffTimeRuleGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleGetResponseReceiverModel.verify(), 'client.CalendarOffTimeRuleGetResponse validation failed!')
        let deserialized = this._CalendarOffTimeRuleGetResponseReceiverModel.deserialize(this._CalendarOffTimeRuleGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.CalendarOffTimeRuleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarOffTimeRuleGetResponseReceiverValue)
        this.onReceive_CalendarOffTimeRuleGetResponse(this._CalendarOffTimeRuleGetResponseReceiverValue)
        return true
      }
      case CalendarOffTimeRuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarOffTimeRuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarOffTimeRuleUpdateNotifyReceiverModel.verify(), 'client.CalendarOffTimeRuleUpdateNotify validation failed!')
        let deserialized = this._CalendarOffTimeRuleUpdateNotifyReceiverModel.deserialize(this._CalendarOffTimeRuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.CalendarOffTimeRuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarOffTimeRuleUpdateNotifyReceiverValue)
        this.onReceive_CalendarOffTimeRuleUpdateNotify(this._CalendarOffTimeRuleUpdateNotifyReceiverValue)
        return true
      }
      case CalendarRulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRulesGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRulesGetRequestReceiverModel.verify(), 'client.CalendarRulesGetRequest validation failed!')
        let deserialized = this._CalendarRulesGetRequestReceiverModel.deserialize(this._CalendarRulesGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.CalendarRulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRulesGetRequestReceiverValue)
        this.onReceive_CalendarRulesGetRequest(this._CalendarRulesGetRequestReceiverValue)
        return true
      }
      case CalendarRulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CalendarRulesGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CalendarRulesGetResponseReceiverModel.verify(), 'client.CalendarRulesGetResponse validation failed!')
        let deserialized = this._CalendarRulesGetResponseReceiverModel.deserialize(this._CalendarRulesGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.CalendarRulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CalendarRulesGetResponseReceiverValue)
        this.onReceive_CalendarRulesGetResponse(this._CalendarRulesGetResponseReceiverValue)
        return true
      }
      case ChartBarHistoryRangeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryRangeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRangeRequestReceiverModel.verify(), 'client.ChartBarHistoryRangeRequest validation failed!')
        let deserialized = this._ChartBarHistoryRangeRequestReceiverModel.deserialize(this._ChartBarHistoryRangeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartBarHistoryRangeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryRangeRequestReceiverValue)
        this.onReceive_ChartBarHistoryRangeRequest(this._ChartBarHistoryRangeRequestReceiverValue)
        return true
      }
      case OrderMarginSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginSubscribeRequestReceiverModel.verify(), 'client.OrderMarginSubscribeRequest validation failed!')
        let deserialized = this._OrderMarginSubscribeRequestReceiverModel.deserialize(this._OrderMarginSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginSubscribeRequestReceiverValue)
        this.onReceive_OrderMarginSubscribeRequest(this._OrderMarginSubscribeRequestReceiverValue)
        return true
      }
      case OrderMarginSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginSubscribeResponseReceiverModel.verify(), 'client.OrderMarginSubscribeResponse validation failed!')
        let deserialized = this._OrderMarginSubscribeResponseReceiverModel.deserialize(this._OrderMarginSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginSubscribeResponseReceiverValue)
        this.onReceive_OrderMarginSubscribeResponse(this._OrderMarginSubscribeResponseReceiverValue)
        return true
      }
      case OrderMarginNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginNotifyReceiverModel.verify(), 'client.OrderMarginNotify validation failed!')
        let deserialized = this._OrderMarginNotifyReceiverModel.deserialize(this._OrderMarginNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginNotifyReceiverValue)
        this.onReceive_OrderMarginNotify(this._OrderMarginNotifyReceiverValue)
        return true
      }
      case OrderMarginUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginUpdateSubscriptionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUpdateSubscriptionRequestReceiverModel.verify(), 'client.OrderMarginUpdateSubscriptionRequest validation failed!')
        let deserialized = this._OrderMarginUpdateSubscriptionRequestReceiverModel.deserialize(this._OrderMarginUpdateSubscriptionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginUpdateSubscriptionRequestReceiverValue)
        this.onReceive_OrderMarginUpdateSubscriptionRequest(this._OrderMarginUpdateSubscriptionRequestReceiverValue)
        return true
      }
      case OrderMarginUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUnsubscribeRequestReceiverModel.verify(), 'client.OrderMarginUnsubscribeRequest validation failed!')
        let deserialized = this._OrderMarginUnsubscribeRequestReceiverModel.deserialize(this._OrderMarginUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginUnsubscribeRequestReceiverValue)
        this.onReceive_OrderMarginUnsubscribeRequest(this._OrderMarginUnsubscribeRequestReceiverValue)
        return true
      }
      case OrderMarginUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginUnsubscribeResponseReceiverModel.verify(), 'client.OrderMarginUnsubscribeResponse validation failed!')
        let deserialized = this._OrderMarginUnsubscribeResponseReceiverModel.deserialize(this._OrderMarginUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginUnsubscribeResponseReceiverValue)
        this.onReceive_OrderMarginUnsubscribeResponse(this._OrderMarginUnsubscribeResponseReceiverValue)
        return true
      }
      case OrderMarginGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginGetRequestReceiverModel.verify(), 'client.OrderMarginGetRequest validation failed!')
        let deserialized = this._OrderMarginGetRequestReceiverModel.deserialize(this._OrderMarginGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginGetRequestReceiverValue)
        this.onReceive_OrderMarginGetRequest(this._OrderMarginGetRequestReceiverValue)
        return true
      }
      case OrderMarginGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderMarginGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderMarginGetResponseReceiverModel.verify(), 'client.OrderMarginGetResponse validation failed!')
        let deserialized = this._OrderMarginGetResponseReceiverModel.deserialize(this._OrderMarginGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderMarginGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderMarginGetResponseReceiverValue)
        this.onReceive_OrderMarginGetResponse(this._OrderMarginGetResponseReceiverValue)
        return true
      }
      case OrderStateInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoSubscribeRequestReceiverModel.verify(), 'client.OrderStateInfoSubscribeRequest validation failed!')
        let deserialized = this._OrderStateInfoSubscribeRequestReceiverModel.deserialize(this._OrderStateInfoSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoSubscribeRequestReceiverValue)
        this.onReceive_OrderStateInfoSubscribeRequest(this._OrderStateInfoSubscribeRequestReceiverValue)
        return true
      }
      case OrderStateInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoSubscribeResponseReceiverModel.verify(), 'client.OrderStateInfoSubscribeResponse validation failed!')
        let deserialized = this._OrderStateInfoSubscribeResponseReceiverModel.deserialize(this._OrderStateInfoSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoSubscribeResponseReceiverValue)
        this.onReceive_OrderStateInfoSubscribeResponse(this._OrderStateInfoSubscribeResponseReceiverValue)
        return true
      }
      case OrderStateInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoUnsubscribeRequestReceiverModel.verify(), 'client.OrderStateInfoUnsubscribeRequest validation failed!')
        let deserialized = this._OrderStateInfoUnsubscribeRequestReceiverModel.deserialize(this._OrderStateInfoUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoUnsubscribeRequestReceiverValue)
        this.onReceive_OrderStateInfoUnsubscribeRequest(this._OrderStateInfoUnsubscribeRequestReceiverValue)
        return true
      }
      case OrderStateInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoUnsubscribeResponseReceiverModel.verify(), 'client.OrderStateInfoUnsubscribeResponse validation failed!')
        let deserialized = this._OrderStateInfoUnsubscribeResponseReceiverModel.deserialize(this._OrderStateInfoUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoUnsubscribeResponseReceiverValue)
        this.onReceive_OrderStateInfoUnsubscribeResponse(this._OrderStateInfoUnsubscribeResponseReceiverValue)
        return true
      }
      case OrderStateInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderStateInfoNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderStateInfoNotifyReceiverModel.verify(), 'client.OrderStateInfoNotify validation failed!')
        let deserialized = this._OrderStateInfoNotifyReceiverModel.deserialize(this._OrderStateInfoNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderStateInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderStateInfoNotifyReceiverValue)
        this.onReceive_OrderStateInfoNotify(this._OrderStateInfoNotifyReceiverValue)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated003ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003ReceiverModel.verify(), 'client.Deprecated003 validation failed!')
        let deserialized = this._Deprecated003ReceiverModel.deserialize(this._Deprecated003ReceiverValue)
        console.assert((deserialized.size > 0), 'client.Deprecated003 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated003ReceiverValue)
        this.onReceive_Deprecated003(this._Deprecated003ReceiverValue)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated004ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004ReceiverModel.verify(), 'client.Deprecated004 validation failed!')
        let deserialized = this._Deprecated004ReceiverModel.deserialize(this._Deprecated004ReceiverValue)
        console.assert((deserialized.size > 0), 'client.Deprecated004 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated004ReceiverValue)
        this.onReceive_Deprecated004(this._Deprecated004ReceiverValue)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated005ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005ReceiverModel.verify(), 'client.Deprecated005 validation failed!')
        let deserialized = this._Deprecated005ReceiverModel.deserialize(this._Deprecated005ReceiverValue)
        console.assert((deserialized.size > 0), 'client.Deprecated005 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated005ReceiverValue)
        this.onReceive_Deprecated005(this._Deprecated005ReceiverValue)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated006ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006ReceiverModel.verify(), 'client.Deprecated006 validation failed!')
        let deserialized = this._Deprecated006ReceiverModel.deserialize(this._Deprecated006ReceiverValue)
        console.assert((deserialized.size > 0), 'client.Deprecated006 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated006ReceiverValue)
        this.onReceive_Deprecated006(this._Deprecated006ReceiverValue)
        return true
      }
      case Deprecated007Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated007ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated007ReceiverModel.verify(), 'client.Deprecated007 validation failed!')
        let deserialized = this._Deprecated007ReceiverModel.deserialize(this._Deprecated007ReceiverValue)
        console.assert((deserialized.size > 0), 'client.Deprecated007 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated007ReceiverValue)
        this.onReceive_Deprecated007(this._Deprecated007ReceiverValue)
        return true
      }
      case Deprecated008Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated008ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated008ReceiverModel.verify(), 'client.Deprecated008 validation failed!')
        let deserialized = this._Deprecated008ReceiverModel.deserialize(this._Deprecated008ReceiverValue)
        console.assert((deserialized.size > 0), 'client.Deprecated008 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated008ReceiverValue)
        this.onReceive_Deprecated008(this._Deprecated008ReceiverValue)
        return true
      }
      case AccountUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateRequestReceiverModel.verify(), 'client.AccountUpdateRequest validation failed!')
        let deserialized = this._AccountUpdateRequestReceiverModel.deserialize(this._AccountUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.AccountUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateRequestReceiverValue)
        this.onReceive_AccountUpdateRequest(this._AccountUpdateRequestReceiverValue)
        return true
      }
      case AccountUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateResponseReceiverModel.verify(), 'client.AccountUpdateResponse validation failed!')
        let deserialized = this._AccountUpdateResponseReceiverModel.deserialize(this._AccountUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.AccountUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateResponseReceiverValue)
        this.onReceive_AccountUpdateResponse(this._AccountUpdateResponseReceiverValue)
        return true
      }
      case ProfitClosedInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedInfoGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedInfoGetRequestReceiverModel.verify(), 'client.ProfitClosedInfoGetRequest validation failed!')
        let deserialized = this._ProfitClosedInfoGetRequestReceiverModel.deserialize(this._ProfitClosedInfoGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ProfitClosedInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedInfoGetRequestReceiverValue)
        this.onReceive_ProfitClosedInfoGetRequest(this._ProfitClosedInfoGetRequestReceiverValue)
        return true
      }
      case ProfitClosedInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedInfoGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedInfoGetResponseReceiverModel.verify(), 'client.ProfitClosedInfoGetResponse validation failed!')
        let deserialized = this._ProfitClosedInfoGetResponseReceiverModel.deserialize(this._ProfitClosedInfoGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ProfitClosedInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedInfoGetResponseReceiverValue)
        this.onReceive_ProfitClosedInfoGetResponse(this._ProfitClosedInfoGetResponseReceiverValue)
        return true
      }
      case SymbolDayProfitLossInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoSubscribeRequestReceiverModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoSubscribeRequestReceiverModel.deserialize(this._SymbolDayProfitLossInfoSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoSubscribeRequestReceiverValue)
        this.onReceive_SymbolDayProfitLossInfoSubscribeRequest(this._SymbolDayProfitLossInfoSubscribeRequestReceiverValue)
        return true
      }
      case SymbolDayProfitLossInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoSubscribeResponseReceiverModel.verify(), 'client.SymbolDayProfitLossInfoSubscribeResponse validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoSubscribeResponseReceiverModel.deserialize(this._SymbolDayProfitLossInfoSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoSubscribeResponseReceiverValue)
        this.onReceive_SymbolDayProfitLossInfoSubscribeResponse(this._SymbolDayProfitLossInfoSubscribeResponseReceiverValue)
        return true
      }
      case SymbolDayProfitLossInfoUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoUpdateSubscriptionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestReceiverModel.verify(), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoUpdateSubscriptionRequestReceiverModel.deserialize(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestReceiverValue)
        this.onReceive_SymbolDayProfitLossInfoUpdateSubscriptionRequest(this._SymbolDayProfitLossInfoUpdateSubscriptionRequestReceiverValue)
        return true
      }
      case SymbolDayProfitLossInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUnsubscribeRequestReceiverModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoUnsubscribeRequestReceiverModel.deserialize(this._SymbolDayProfitLossInfoUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoUnsubscribeRequestReceiverValue)
        this.onReceive_SymbolDayProfitLossInfoUnsubscribeRequest(this._SymbolDayProfitLossInfoUnsubscribeRequestReceiverValue)
        return true
      }
      case SymbolDayProfitLossInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoUnsubscribeResponseReceiverModel.verify(), 'client.SymbolDayProfitLossInfoUnsubscribeResponse validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoUnsubscribeResponseReceiverModel.deserialize(this._SymbolDayProfitLossInfoUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoUnsubscribeResponseReceiverValue)
        this.onReceive_SymbolDayProfitLossInfoUnsubscribeResponse(this._SymbolDayProfitLossInfoUnsubscribeResponseReceiverValue)
        return true
      }
      case SymbolDayProfitLossInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossInfoNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossInfoNotifyReceiverModel.verify(), 'client.SymbolDayProfitLossInfoNotify validation failed!')
        let deserialized = this._SymbolDayProfitLossInfoNotifyReceiverModel.deserialize(this._SymbolDayProfitLossInfoNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossInfoNotifyReceiverValue)
        this.onReceive_SymbolDayProfitLossInfoNotify(this._SymbolDayProfitLossInfoNotifyReceiverValue)
        return true
      }
      case LoginInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginInfoGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginInfoGetRequestReceiverModel.verify(), 'client.LoginInfoGetRequest validation failed!')
        let deserialized = this._LoginInfoGetRequestReceiverModel.deserialize(this._LoginInfoGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginInfoGetRequestReceiverValue)
        this.onReceive_LoginInfoGetRequest(this._LoginInfoGetRequestReceiverValue)
        return true
      }
      case LoginInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginInfoGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginInfoGetResponseReceiverModel.verify(), 'client.LoginInfoGetResponse validation failed!')
        let deserialized = this._LoginInfoGetResponseReceiverModel.deserialize(this._LoginInfoGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginInfoGetResponseReceiverValue)
        this.onReceive_LoginInfoGetResponse(this._LoginInfoGetResponseReceiverValue)
        return true
      }
      case DashboardInfoCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoCreateRequestReceiverModel.verify(), 'client.DashboardInfoCreateRequest validation failed!')
        let deserialized = this._DashboardInfoCreateRequestReceiverModel.deserialize(this._DashboardInfoCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoCreateRequestReceiverValue)
        this.onReceive_DashboardInfoCreateRequest(this._DashboardInfoCreateRequestReceiverValue)
        return true
      }
      case DashboardInfoCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoCreateResponseReceiverModel.verify(), 'client.DashboardInfoCreateResponse validation failed!')
        let deserialized = this._DashboardInfoCreateResponseReceiverModel.deserialize(this._DashboardInfoCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoCreateResponseReceiverValue)
        this.onReceive_DashboardInfoCreateResponse(this._DashboardInfoCreateResponseReceiverValue)
        return true
      }
      case DashboardInfoUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoUpdateRequestReceiverModel.verify(), 'client.DashboardInfoUpdateRequest validation failed!')
        let deserialized = this._DashboardInfoUpdateRequestReceiverModel.deserialize(this._DashboardInfoUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoUpdateRequestReceiverValue)
        this.onReceive_DashboardInfoUpdateRequest(this._DashboardInfoUpdateRequestReceiverValue)
        return true
      }
      case DashboardInfoUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoUpdateResponseReceiverModel.verify(), 'client.DashboardInfoUpdateResponse validation failed!')
        let deserialized = this._DashboardInfoUpdateResponseReceiverModel.deserialize(this._DashboardInfoUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoUpdateResponseReceiverValue)
        this.onReceive_DashboardInfoUpdateResponse(this._DashboardInfoUpdateResponseReceiverValue)
        return true
      }
      case DashboardInfoListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoListRequestReceiverModel.verify(), 'client.DashboardInfoListRequest validation failed!')
        let deserialized = this._DashboardInfoListRequestReceiverModel.deserialize(this._DashboardInfoListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoListRequestReceiverValue)
        this.onReceive_DashboardInfoListRequest(this._DashboardInfoListRequestReceiverValue)
        return true
      }
      case DashboardInfoListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoListResponseReceiverModel.verify(), 'client.DashboardInfoListResponse validation failed!')
        let deserialized = this._DashboardInfoListResponseReceiverModel.deserialize(this._DashboardInfoListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoListResponseReceiverValue)
        this.onReceive_DashboardInfoListResponse(this._DashboardInfoListResponseReceiverValue)
        return true
      }
      case DashboardInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoGetRequestReceiverModel.verify(), 'client.DashboardInfoGetRequest validation failed!')
        let deserialized = this._DashboardInfoGetRequestReceiverModel.deserialize(this._DashboardInfoGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoGetRequestReceiverValue)
        this.onReceive_DashboardInfoGetRequest(this._DashboardInfoGetRequestReceiverValue)
        return true
      }
      case DashboardInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoGetResponseReceiverModel.verify(), 'client.DashboardInfoGetResponse validation failed!')
        let deserialized = this._DashboardInfoGetResponseReceiverModel.deserialize(this._DashboardInfoGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoGetResponseReceiverValue)
        this.onReceive_DashboardInfoGetResponse(this._DashboardInfoGetResponseReceiverValue)
        return true
      }
      case DashboardInfoRenameRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoRenameRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoRenameRequestReceiverModel.verify(), 'client.DashboardInfoRenameRequest validation failed!')
        let deserialized = this._DashboardInfoRenameRequestReceiverModel.deserialize(this._DashboardInfoRenameRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoRenameRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoRenameRequestReceiverValue)
        this.onReceive_DashboardInfoRenameRequest(this._DashboardInfoRenameRequestReceiverValue)
        return true
      }
      case DashboardInfoRenameResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoRenameResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoRenameResponseReceiverModel.verify(), 'client.DashboardInfoRenameResponse validation failed!')
        let deserialized = this._DashboardInfoRenameResponseReceiverModel.deserialize(this._DashboardInfoRenameResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoRenameResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoRenameResponseReceiverValue)
        this.onReceive_DashboardInfoRenameResponse(this._DashboardInfoRenameResponseReceiverValue)
        return true
      }
      case DashboardInfoDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoDeleteRequestReceiverModel.verify(), 'client.DashboardInfoDeleteRequest validation failed!')
        let deserialized = this._DashboardInfoDeleteRequestReceiverModel.deserialize(this._DashboardInfoDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoDeleteRequestReceiverValue)
        this.onReceive_DashboardInfoDeleteRequest(this._DashboardInfoDeleteRequestReceiverValue)
        return true
      }
      case DashboardInfoDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DashboardInfoDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DashboardInfoDeleteResponseReceiverModel.verify(), 'client.DashboardInfoDeleteResponse validation failed!')
        let deserialized = this._DashboardInfoDeleteResponseReceiverModel.deserialize(this._DashboardInfoDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.DashboardInfoDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DashboardInfoDeleteResponseReceiverValue)
        this.onReceive_DashboardInfoDeleteResponse(this._DashboardInfoDeleteResponseReceiverValue)
        return true
      }
      case ChartInfoCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoCreateRequestReceiverModel.verify(), 'client.ChartInfoCreateRequest validation failed!')
        let deserialized = this._ChartInfoCreateRequestReceiverModel.deserialize(this._ChartInfoCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoCreateRequestReceiverValue)
        this.onReceive_ChartInfoCreateRequest(this._ChartInfoCreateRequestReceiverValue)
        return true
      }
      case ChartInfoCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoCreateResponseReceiverModel.verify(), 'client.ChartInfoCreateResponse validation failed!')
        let deserialized = this._ChartInfoCreateResponseReceiverModel.deserialize(this._ChartInfoCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoCreateResponseReceiverValue)
        this.onReceive_ChartInfoCreateResponse(this._ChartInfoCreateResponseReceiverValue)
        return true
      }
      case ChartInfoUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoUpdateRequestReceiverModel.verify(), 'client.ChartInfoUpdateRequest validation failed!')
        let deserialized = this._ChartInfoUpdateRequestReceiverModel.deserialize(this._ChartInfoUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoUpdateRequestReceiverValue)
        this.onReceive_ChartInfoUpdateRequest(this._ChartInfoUpdateRequestReceiverValue)
        return true
      }
      case ChartInfoUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoUpdateResponseReceiverModel.verify(), 'client.ChartInfoUpdateResponse validation failed!')
        let deserialized = this._ChartInfoUpdateResponseReceiverModel.deserialize(this._ChartInfoUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoUpdateResponseReceiverValue)
        this.onReceive_ChartInfoUpdateResponse(this._ChartInfoUpdateResponseReceiverValue)
        return true
      }
      case ChartInfoListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoListRequestReceiverModel.verify(), 'client.ChartInfoListRequest validation failed!')
        let deserialized = this._ChartInfoListRequestReceiverModel.deserialize(this._ChartInfoListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoListRequestReceiverValue)
        this.onReceive_ChartInfoListRequest(this._ChartInfoListRequestReceiverValue)
        return true
      }
      case ChartInfoListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoListResponseReceiverModel.verify(), 'client.ChartInfoListResponse validation failed!')
        let deserialized = this._ChartInfoListResponseReceiverModel.deserialize(this._ChartInfoListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoListResponseReceiverValue)
        this.onReceive_ChartInfoListResponse(this._ChartInfoListResponseReceiverValue)
        return true
      }
      case ChartInfoDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoDeleteRequestReceiverModel.verify(), 'client.ChartInfoDeleteRequest validation failed!')
        let deserialized = this._ChartInfoDeleteRequestReceiverModel.deserialize(this._ChartInfoDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoDeleteRequestReceiverValue)
        this.onReceive_ChartInfoDeleteRequest(this._ChartInfoDeleteRequestReceiverValue)
        return true
      }
      case ChartInfoDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoDeleteResponseReceiverModel.verify(), 'client.ChartInfoDeleteResponse validation failed!')
        let deserialized = this._ChartInfoDeleteResponseReceiverModel.deserialize(this._ChartInfoDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoDeleteResponseReceiverValue)
        this.onReceive_ChartInfoDeleteResponse(this._ChartInfoDeleteResponseReceiverValue)
        return true
      }
      case ChartInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoGetRequestReceiverModel.verify(), 'client.ChartInfoGetRequest validation failed!')
        let deserialized = this._ChartInfoGetRequestReceiverModel.deserialize(this._ChartInfoGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoGetRequestReceiverValue)
        this.onReceive_ChartInfoGetRequest(this._ChartInfoGetRequestReceiverValue)
        return true
      }
      case ChartInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartInfoGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartInfoGetResponseReceiverModel.verify(), 'client.ChartInfoGetResponse validation failed!')
        let deserialized = this._ChartInfoGetResponseReceiverModel.deserialize(this._ChartInfoGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.ChartInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartInfoGetResponseReceiverValue)
        this.onReceive_ChartInfoGetResponse(this._ChartInfoGetResponseReceiverValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoSubscribeRequestReceiverModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoSubscribeRequestReceiverModel.deserialize(this._SymbolDayProfitLossMultipleInfoSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoSubscribeRequestReceiverValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoSubscribeRequest(this._SymbolDayProfitLossMultipleInfoSubscribeRequestReceiverValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoSubscribeResponseReceiverModel.verify(), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoSubscribeResponseReceiverModel.deserialize(this._SymbolDayProfitLossMultipleInfoSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoSubscribeResponseReceiverValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoSubscribeResponse(this._SymbolDayProfitLossMultipleInfoSubscribeResponseReceiverValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestReceiverModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestReceiverModel.deserialize(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestReceiverValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequest(this._SymbolDayProfitLossMultipleInfoUpdateSubscriptionRequestReceiverValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestReceiverModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestReceiverModel.deserialize(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestReceiverValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoUnsubscribeRequest(this._SymbolDayProfitLossMultipleInfoUnsubscribeRequestReceiverValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseReceiverModel.verify(), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseReceiverModel.deserialize(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseReceiverValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoUnsubscribeResponse(this._SymbolDayProfitLossMultipleInfoUnsubscribeResponseReceiverValue)
        return true
      }
      case SymbolDayProfitLossMultipleInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolDayProfitLossMultipleInfoNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolDayProfitLossMultipleInfoNotifyReceiverModel.verify(), 'client.SymbolDayProfitLossMultipleInfoNotify validation failed!')
        let deserialized = this._SymbolDayProfitLossMultipleInfoNotifyReceiverModel.deserialize(this._SymbolDayProfitLossMultipleInfoNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolDayProfitLossMultipleInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolDayProfitLossMultipleInfoNotifyReceiverValue)
        this.onReceive_SymbolDayProfitLossMultipleInfoNotify(this._SymbolDayProfitLossMultipleInfoNotifyReceiverValue)
        return true
      }
      case NewsServiceGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsServiceGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsServiceGetAllRequestReceiverModel.verify(), 'client.NewsServiceGetAllRequest validation failed!')
        let deserialized = this._NewsServiceGetAllRequestReceiverModel.deserialize(this._NewsServiceGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.NewsServiceGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsServiceGetAllRequestReceiverValue)
        this.onReceive_NewsServiceGetAllRequest(this._NewsServiceGetAllRequestReceiverValue)
        return true
      }
      case NewsServiceGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsServiceGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsServiceGetAllResponseReceiverModel.verify(), 'client.NewsServiceGetAllResponse validation failed!')
        let deserialized = this._NewsServiceGetAllResponseReceiverModel.deserialize(this._NewsServiceGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.NewsServiceGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsServiceGetAllResponseReceiverValue)
        this.onReceive_NewsServiceGetAllResponse(this._NewsServiceGetAllResponseReceiverValue)
        return true
      }
      case NewsRecentArticlesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsRecentArticlesGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsRecentArticlesGetRequestReceiverModel.verify(), 'client.NewsRecentArticlesGetRequest validation failed!')
        let deserialized = this._NewsRecentArticlesGetRequestReceiverModel.deserialize(this._NewsRecentArticlesGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.NewsRecentArticlesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsRecentArticlesGetRequestReceiverValue)
        this.onReceive_NewsRecentArticlesGetRequest(this._NewsRecentArticlesGetRequestReceiverValue)
        return true
      }
      case NewsRecentArticlesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsRecentArticlesGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsRecentArticlesGetResponseReceiverModel.verify(), 'client.NewsRecentArticlesGetResponse validation failed!')
        let deserialized = this._NewsRecentArticlesGetResponseReceiverModel.deserialize(this._NewsRecentArticlesGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.NewsRecentArticlesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsRecentArticlesGetResponseReceiverValue)
        this.onReceive_NewsRecentArticlesGetResponse(this._NewsRecentArticlesGetResponseReceiverValue)
        return true
      }
      case NewsArticleGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsArticleGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsArticleGetRequestReceiverModel.verify(), 'client.NewsArticleGetRequest validation failed!')
        let deserialized = this._NewsArticleGetRequestReceiverModel.deserialize(this._NewsArticleGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.NewsArticleGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsArticleGetRequestReceiverValue)
        this.onReceive_NewsArticleGetRequest(this._NewsArticleGetRequestReceiverValue)
        return true
      }
      case NewsArticleGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._NewsArticleGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._NewsArticleGetResponseReceiverModel.verify(), 'client.NewsArticleGetResponse validation failed!')
        let deserialized = this._NewsArticleGetResponseReceiverModel.deserialize(this._NewsArticleGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.NewsArticleGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._NewsArticleGetResponseReceiverValue)
        this.onReceive_NewsArticleGetResponse(this._NewsArticleGetResponseReceiverValue)
        return true
      }
      case OrderUpdateWithTypeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithTypeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeRequestReceiverModel.verify(), 'client.OrderUpdateWithTypeRequest validation failed!')
        let deserialized = this._OrderUpdateWithTypeRequestReceiverModel.deserialize(this._OrderUpdateWithTypeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderUpdateWithTypeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithTypeRequestReceiverValue)
        this.onReceive_OrderUpdateWithTypeRequest(this._OrderUpdateWithTypeRequestReceiverValue)
        return true
      }
      case OrderUpdateWithTypeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithTypeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeResponseReceiverModel.verify(), 'client.OrderUpdateWithTypeResponse validation failed!')
        let deserialized = this._OrderUpdateWithTypeResponseReceiverModel.deserialize(this._OrderUpdateWithTypeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.OrderUpdateWithTypeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithTypeResponseReceiverValue)
        this.onReceive_OrderUpdateWithTypeResponse(this._OrderUpdateWithTypeResponseReceiverValue)
        return true
      }
      case SymbolProfitLossMultipleInfoSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoSubscribeRequestReceiverModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeRequest validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoSubscribeRequestReceiverModel.deserialize(this._SymbolProfitLossMultipleInfoSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoSubscribeRequestReceiverValue)
        this.onReceive_SymbolProfitLossMultipleInfoSubscribeRequest(this._SymbolProfitLossMultipleInfoSubscribeRequestReceiverValue)
        return true
      }
      case SymbolProfitLossMultipleInfoSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoSubscribeResponseReceiverModel.verify(), 'client.SymbolProfitLossMultipleInfoSubscribeResponse validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoSubscribeResponseReceiverModel.deserialize(this._SymbolProfitLossMultipleInfoSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoSubscribeResponseReceiverValue)
        this.onReceive_SymbolProfitLossMultipleInfoSubscribeResponse(this._SymbolProfitLossMultipleInfoSubscribeResponseReceiverValue)
        return true
      }
      case SymbolProfitLossMultipleInfoUpdateSubscriptionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestReceiverModel.verify(), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestReceiverModel.deserialize(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoUpdateSubscriptionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestReceiverValue)
        this.onReceive_SymbolProfitLossMultipleInfoUpdateSubscriptionRequest(this._SymbolProfitLossMultipleInfoUpdateSubscriptionRequestReceiverValue)
        return true
      }
      case SymbolProfitLossMultipleInfoUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUnsubscribeRequestReceiverModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoUnsubscribeRequestReceiverModel.deserialize(this._SymbolProfitLossMultipleInfoUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoUnsubscribeRequestReceiverValue)
        this.onReceive_SymbolProfitLossMultipleInfoUnsubscribeRequest(this._SymbolProfitLossMultipleInfoUnsubscribeRequestReceiverValue)
        return true
      }
      case SymbolProfitLossMultipleInfoUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoUnsubscribeResponseReceiverModel.verify(), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoUnsubscribeResponseReceiverModel.deserialize(this._SymbolProfitLossMultipleInfoUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoUnsubscribeResponseReceiverValue)
        this.onReceive_SymbolProfitLossMultipleInfoUnsubscribeResponse(this._SymbolProfitLossMultipleInfoUnsubscribeResponseReceiverValue)
        return true
      }
      case SymbolProfitLossMultipleInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolProfitLossMultipleInfoNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolProfitLossMultipleInfoNotifyReceiverModel.verify(), 'client.SymbolProfitLossMultipleInfoNotify validation failed!')
        let deserialized = this._SymbolProfitLossMultipleInfoNotifyReceiverModel.deserialize(this._SymbolProfitLossMultipleInfoNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'client.SymbolProfitLossMultipleInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolProfitLossMultipleInfoNotifyReceiverValue)
        this.onReceive_SymbolProfitLossMultipleInfoNotify(this._SymbolProfitLossMultipleInfoNotifyReceiverValue)
        return true
      }
      case LoginWithPasswordRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithPasswordRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordRequestReceiverModel.verify(), 'client.LoginWithPasswordRequest validation failed!')
        let deserialized = this._LoginWithPasswordRequestReceiverModel.deserialize(this._LoginWithPasswordRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginWithPasswordRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithPasswordRequestReceiverValue)
        this.onReceive_LoginWithPasswordRequest(this._LoginWithPasswordRequestReceiverValue)
        return true
      }
      case LoginWithPasswordResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithPasswordResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithPasswordResponseReceiverModel.verify(), 'client.LoginWithPasswordResponse validation failed!')
        let deserialized = this._LoginWithPasswordResponseReceiverModel.deserialize(this._LoginWithPasswordResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginWithPasswordResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithPasswordResponseReceiverValue)
        this.onReceive_LoginWithPasswordResponse(this._LoginWithPasswordResponseReceiverValue)
        return true
      }
      case LoginWithTokenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithTokenRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenRequestReceiverModel.verify(), 'client.LoginWithTokenRequest validation failed!')
        let deserialized = this._LoginWithTokenRequestReceiverModel.deserialize(this._LoginWithTokenRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginWithTokenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithTokenRequestReceiverValue)
        this.onReceive_LoginWithTokenRequest(this._LoginWithTokenRequestReceiverValue)
        return true
      }
      case LoginWithTokenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LoginWithTokenResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LoginWithTokenResponseReceiverModel.verify(), 'client.LoginWithTokenResponse validation failed!')
        let deserialized = this._LoginWithTokenResponseReceiverModel.deserialize(this._LoginWithTokenResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.LoginWithTokenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LoginWithTokenResponseReceiverValue)
        this.onReceive_LoginWithTokenResponse(this._LoginWithTokenResponseReceiverValue)
        return true
      }
      case RefreshTokenRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RefreshTokenRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenRequestReceiverModel.verify(), 'client.RefreshTokenRequest validation failed!')
        let deserialized = this._RefreshTokenRequestReceiverModel.deserialize(this._RefreshTokenRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.RefreshTokenRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RefreshTokenRequestReceiverValue)
        this.onReceive_RefreshTokenRequest(this._RefreshTokenRequestReceiverValue)
        return true
      }
      case RefreshTokenResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RefreshTokenResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RefreshTokenResponseReceiverModel.verify(), 'client.RefreshTokenResponse validation failed!')
        let deserialized = this._RefreshTokenResponseReceiverModel.deserialize(this._RefreshTokenResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.RefreshTokenResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RefreshTokenResponseReceiverValue)
        this.onReceive_RefreshTokenResponse(this._RefreshTokenResponseReceiverValue)
        return true
      }
      case Logout2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Logout2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Logout2RequestReceiverModel.verify(), 'client.Logout2Request validation failed!')
        let deserialized = this._Logout2RequestReceiverModel.deserialize(this._Logout2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.Logout2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Logout2RequestReceiverValue)
        this.onReceive_Logout2Request(this._Logout2RequestReceiverValue)
        return true
      }
      case Logout2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Logout2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Logout2ResponseReceiverModel.verify(), 'client.Logout2Response validation failed!')
        let deserialized = this._Logout2ResponseReceiverModel.deserialize(this._Logout2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.Logout2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Logout2ResponseReceiverValue)
        this.onReceive_Logout2Response(this._Logout2ResponseReceiverValue)
        return true
      }
      case AccountChangePasswordRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountChangePasswordRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountChangePasswordRequestReceiverModel.verify(), 'client.AccountChangePasswordRequest validation failed!')
        let deserialized = this._AccountChangePasswordRequestReceiverModel.deserialize(this._AccountChangePasswordRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.AccountChangePasswordRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountChangePasswordRequestReceiverValue)
        this.onReceive_AccountChangePasswordRequest(this._AccountChangePasswordRequestReceiverValue)
        return true
      }
      case AccountChangePasswordResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountChangePasswordResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountChangePasswordResponseReceiverModel.verify(), 'client.AccountChangePasswordResponse validation failed!')
        let deserialized = this._AccountChangePasswordResponseReceiverModel.deserialize(this._AccountChangePasswordResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.AccountChangePasswordResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountChangePasswordResponseReceiverValue)
        this.onReceive_AccountChangePasswordResponse(this._AccountChangePasswordResponseReceiverValue)
        return true
      }
      case PushNotificationTokenRegisterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PushNotificationTokenRegisterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenRegisterRequestReceiverModel.verify(), 'client.PushNotificationTokenRegisterRequest validation failed!')
        let deserialized = this._PushNotificationTokenRegisterRequestReceiverModel.deserialize(this._PushNotificationTokenRegisterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.PushNotificationTokenRegisterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PushNotificationTokenRegisterRequestReceiverValue)
        this.onReceive_PushNotificationTokenRegisterRequest(this._PushNotificationTokenRegisterRequestReceiverValue)
        return true
      }
      case PushNotificationTokenRegisterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PushNotificationTokenRegisterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenRegisterResponseReceiverModel.verify(), 'client.PushNotificationTokenRegisterResponse validation failed!')
        let deserialized = this._PushNotificationTokenRegisterResponseReceiverModel.deserialize(this._PushNotificationTokenRegisterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.PushNotificationTokenRegisterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PushNotificationTokenRegisterResponseReceiverValue)
        this.onReceive_PushNotificationTokenRegisterResponse(this._PushNotificationTokenRegisterResponseReceiverValue)
        return true
      }
      case DesiredAppSettingsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DesiredAppSettingsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DesiredAppSettingsGetRequestReceiverModel.verify(), 'client.DesiredAppSettingsGetRequest validation failed!')
        let deserialized = this._DesiredAppSettingsGetRequestReceiverModel.deserialize(this._DesiredAppSettingsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.DesiredAppSettingsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DesiredAppSettingsGetRequestReceiverValue)
        this.onReceive_DesiredAppSettingsGetRequest(this._DesiredAppSettingsGetRequestReceiverValue)
        return true
      }
      case DesiredAppSettingsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DesiredAppSettingsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DesiredAppSettingsGetResponseReceiverModel.verify(), 'client.DesiredAppSettingsGetResponse validation failed!')
        let deserialized = this._DesiredAppSettingsGetResponseReceiverModel.deserialize(this._DesiredAppSettingsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.DesiredAppSettingsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DesiredAppSettingsGetResponseReceiverValue)
        this.onReceive_DesiredAppSettingsGetResponse(this._DesiredAppSettingsGetResponseReceiverValue)
        return true
      }
      case AuthTokensGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AuthTokensGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensGetAllRequestReceiverModel.verify(), 'client.AuthTokensGetAllRequest validation failed!')
        let deserialized = this._AuthTokensGetAllRequestReceiverModel.deserialize(this._AuthTokensGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.AuthTokensGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AuthTokensGetAllRequestReceiverValue)
        this.onReceive_AuthTokensGetAllRequest(this._AuthTokensGetAllRequestReceiverValue)
        return true
      }
      case AuthTokensGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AuthTokensGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensGetAllResponseReceiverModel.verify(), 'client.AuthTokensGetAllResponse validation failed!')
        let deserialized = this._AuthTokensGetAllResponseReceiverModel.deserialize(this._AuthTokensGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.AuthTokensGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AuthTokensGetAllResponseReceiverValue)
        this.onReceive_AuthTokensGetAllResponse(this._AuthTokensGetAllResponseReceiverValue)
        return true
      }
      case AuthTokensDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AuthTokensDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensDeleteRequestReceiverModel.verify(), 'client.AuthTokensDeleteRequest validation failed!')
        let deserialized = this._AuthTokensDeleteRequestReceiverModel.deserialize(this._AuthTokensDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.AuthTokensDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AuthTokensDeleteRequestReceiverValue)
        this.onReceive_AuthTokensDeleteRequest(this._AuthTokensDeleteRequestReceiverValue)
        return true
      }
      case AuthTokensDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AuthTokensDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AuthTokensDeleteResponseReceiverModel.verify(), 'client.AuthTokensDeleteResponse validation failed!')
        let deserialized = this._AuthTokensDeleteResponseReceiverModel.deserialize(this._AuthTokensDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.AuthTokensDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AuthTokensDeleteResponseReceiverValue)
        this.onReceive_AuthTokensDeleteResponse(this._AuthTokensDeleteResponseReceiverValue)
        return true
      }
      case LegalDocumentGroupListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupListRequestReceiverModel.verify(), 'client.LegalDocumentGroupListRequest validation failed!')
        let deserialized = this._LegalDocumentGroupListRequestReceiverModel.deserialize(this._LegalDocumentGroupListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.LegalDocumentGroupListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupListRequestReceiverValue)
        this.onReceive_LegalDocumentGroupListRequest(this._LegalDocumentGroupListRequestReceiverValue)
        return true
      }
      case LegalDocumentGroupListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGroupListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGroupListResponseReceiverModel.verify(), 'client.LegalDocumentGroupListResponse validation failed!')
        let deserialized = this._LegalDocumentGroupListResponseReceiverModel.deserialize(this._LegalDocumentGroupListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.LegalDocumentGroupListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGroupListResponseReceiverValue)
        this.onReceive_LegalDocumentGroupListResponse(this._LegalDocumentGroupListResponseReceiverValue)
        return true
      }
      case LegalDocumentGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetRequestReceiverModel.verify(), 'client.LegalDocumentGetRequest validation failed!')
        let deserialized = this._LegalDocumentGetRequestReceiverModel.deserialize(this._LegalDocumentGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.LegalDocumentGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetRequestReceiverValue)
        this.onReceive_LegalDocumentGetRequest(this._LegalDocumentGetRequestReceiverValue)
        return true
      }
      case LegalDocumentGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LegalDocumentGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LegalDocumentGetResponseReceiverModel.verify(), 'client.LegalDocumentGetResponse validation failed!')
        let deserialized = this._LegalDocumentGetResponseReceiverModel.deserialize(this._LegalDocumentGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.LegalDocumentGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LegalDocumentGetResponseReceiverValue)
        this.onReceive_LegalDocumentGetResponse(this._LegalDocumentGetResponseReceiverValue)
        return true
      }
      case PushNotificationTokenUnRegisterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PushNotificationTokenUnRegisterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenUnRegisterRequestReceiverModel.verify(), 'client.PushNotificationTokenUnRegisterRequest validation failed!')
        let deserialized = this._PushNotificationTokenUnRegisterRequestReceiverModel.deserialize(this._PushNotificationTokenUnRegisterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'client.PushNotificationTokenUnRegisterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PushNotificationTokenUnRegisterRequestReceiverValue)
        this.onReceive_PushNotificationTokenUnRegisterRequest(this._PushNotificationTokenUnRegisterRequestReceiverValue)
        return true
      }
      case PushNotificationTokenUnRegisterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PushNotificationTokenUnRegisterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PushNotificationTokenUnRegisterResponseReceiverModel.verify(), 'client.PushNotificationTokenUnRegisterResponse validation failed!')
        let deserialized = this._PushNotificationTokenUnRegisterResponseReceiverModel.deserialize(this._PushNotificationTokenUnRegisterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'client.PushNotificationTokenUnRegisterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PushNotificationTokenUnRegisterResponseReceiverValue)
        this.onReceive_PushNotificationTokenUnRegisterResponse(this._PushNotificationTokenUnRegisterResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.exposedClient != null) && this.exposedClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
    this._exposedClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._exposedClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._exposedClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()
    this._exposedClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)
    this._exposedClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
