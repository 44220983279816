
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {LegalDocumentType} from './LegalDocumentType';
import {FieldModelLegalDocumentType} from './LegalDocumentType';

/**
 * LegalDocumentGroup struct
 */
class LegalDocumentGroup {
  /**
   * Initialize struct
   * @param {!number=} groupId
   * @param {!LegalDocumentType=} legalDocumentType
   * @param {!number=} sortOrder
   * @param {!boolean=} isEnabled
   * @param {!string=} comment
   * @constructor
   */
  constructor (groupId = new UInt64(0, 0), legalDocumentType = new LegalDocumentType(), sortOrder = new UInt64(0, 0), isEnabled = false, comment = '') {
    this.GroupId = groupId
    this.LegalDocumentType = legalDocumentType
    this.SortOrder = sortOrder
    this.IsEnabled = isEnabled
    this.Comment = comment
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LegalDocumentGroup}
   * @param {!LegalDocumentGroup} other Other struct
   * @returns {!LegalDocumentGroup} This struct
   */
  copy (other) {
    if (other.GroupId != null) {
      this.GroupId = UInt64.fromNumber(other.GroupId)
    } else {
      this.GroupId = null
    }
    if (other.LegalDocumentType != null) {
      this.LegalDocumentType = LegalDocumentType.fromObject(other.LegalDocumentType)
    } else {
      this.LegalDocumentType = null
    }
    if (other.SortOrder != null) {
      this.SortOrder = UInt64.fromNumber(other.SortOrder)
    } else {
      this.SortOrder = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LegalDocumentGroup}
   * @returns {!LegalDocumentGroup} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LegalDocumentGroupModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LegalDocumentGroupModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LegalDocumentGroup}
   * @param {!LegalDocumentGroup} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LegalDocumentGroup)) {
      throw new TypeError('Instance of LegalDocumentGroup is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LegalDocumentGroup}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      GroupId: ((this.GroupId != null) ? this.GroupId.toNumber() : null),
      LegalDocumentType: ((this.LegalDocumentType != null) ? this.LegalDocumentType : null),
      SortOrder: ((this.SortOrder != null) ? this.SortOrder.toNumber() : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      Comment: ((this.Comment != null) ? this.Comment : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LegalDocumentGroup.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LegalDocumentGroup} other Object value
   * @returns {!LegalDocumentGroup} Created struct
   */
  static fromObject (other) {
    return new LegalDocumentGroup().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LegalDocumentGroup}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LegalDocumentGroup.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LegalDocumentGroup}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6595
  }
}

export { LegalDocumentGroup };

/**
 * Fast Binary Encoding LegalDocumentGroup field model
 */
class FieldModelLegalDocumentGroup extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.GroupId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.LegalDocumentType = new FieldModelLegalDocumentType(buffer, this.GroupId.fbeOffset + this.GroupId.fbeSize)
    this.SortOrder = new fbe.FieldModelUInt64(buffer, this.LegalDocumentType.fbeOffset + this.LegalDocumentType.fbeSize)
    this.IsEnabled = new fbe.FieldModelBool(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelLegalDocumentGroup}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLegalDocumentGroup}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.GroupId.fbeSize + this.LegalDocumentType.fbeSize + this.SortOrder.fbeSize + this.IsEnabled.fbeSize + this.Comment.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLegalDocumentGroup}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.GroupId.fbeExtra + this.LegalDocumentType.fbeExtra + this.SortOrder.fbeExtra + this.IsEnabled.fbeExtra + this.Comment.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLegalDocumentGroup}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLegalDocumentGroup.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLegalDocumentGroup}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6595
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLegalDocumentGroup}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLegalDocumentGroup}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.GroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GroupId.fbeSize

    if ((fbeCurrentSize + this.LegalDocumentType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LegalDocumentType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LegalDocumentType.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLegalDocumentGroup}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLegalDocumentGroup}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLegalDocumentGroup}
   * @param {!LegalDocumentGroup} fbeValue Default value, defaults is new LegalDocumentGroup()
   * @returns {!LegalDocumentGroup} LegalDocumentGroup value
   */
  get (fbeValue = new LegalDocumentGroup()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLegalDocumentGroup}
   * @param {!LegalDocumentGroup} fbeValue LegalDocumentGroup value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.GroupId.fbeSize) <= fbeStructSize) {
      fbeValue.GroupId = this.GroupId.get()
    } else {
      fbeValue.GroupId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GroupId.fbeSize

    if ((fbeCurrentSize + this.LegalDocumentType.fbeSize) <= fbeStructSize) {
      fbeValue.LegalDocumentType = this.LegalDocumentType.get()
    } else {
      fbeValue.LegalDocumentType = new LegalDocumentType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LegalDocumentType.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLegalDocumentGroup}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLegalDocumentGroup}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLegalDocumentGroup}
   * @param {!LegalDocumentGroup} fbeValue LegalDocumentGroup value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLegalDocumentGroup}
   * @param {LegalDocumentGroup} fbeValue LegalDocumentGroup value
   */
  setFields (fbeValue) {
    this.GroupId.set(fbeValue.GroupId)
    this.LegalDocumentType.set(fbeValue.LegalDocumentType)
    this.SortOrder.set(fbeValue.SortOrder)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.Comment.set(fbeValue.Comment)
  }
}

export { FieldModelLegalDocumentGroup };

/**
 * Fast Binary Encoding LegalDocumentGroup model
 */
class LegalDocumentGroupModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLegalDocumentGroup(this.buffer, 4)
  }

  /**
   * Get the LegalDocumentGroup model
   * @this {!LegalDocumentGroupModel}
   * @returns {!FieldModelLegalDocumentGroup} model LegalDocumentGroup model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LegalDocumentGroupModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LegalDocumentGroupModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LegalDocumentGroupModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LegalDocumentGroupModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLegalDocumentGroup.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LegalDocumentGroupModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LegalDocumentGroupModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LegalDocumentGroupModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LegalDocumentGroupModel}
   * @param {!LegalDocumentGroup} value LegalDocumentGroup value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LegalDocumentGroupModel}
   * @param {!LegalDocumentGroup} value LegalDocumentGroup value, defaults is new LegalDocumentGroup()
   * @return {!object} Deserialized LegalDocumentGroup value and its size
   */
  deserialize (value = new LegalDocumentGroup()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LegalDocumentGroup(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LegalDocumentGroup(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LegalDocumentGroupModel}
   * @param {!number} prev Previous LegalDocumentGroup model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LegalDocumentGroupModel };
