
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {PersonModifyResponse} from './PersonModifyResponse';

/**
 * PersonModifyRequest struct
 */
class PersonModifyRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!number=} personId
   * @param {string=} newName
   * @param {string=} newCountry
   * @param {string=} newCity
   * @param {string=} newState
   * @param {string=} newZipCode
   * @param {string=} newAddress
   * @param {string=} newPhone
   * @param {string=} newEmail
   * @param {string=} newIdentifier
   * @param {string=} newStatus
   * @param {string=} newExternalClientId
   * @param {!boolean=} newExternalClientIdReset
   * @param {string=} newLocalLanguageName
   * @constructor
   */
  constructor (parent = new Request(), personId = new UInt64(0, 0), newName = null, newCountry = null, newCity = null, newState = null, newZipCode = null, newAddress = null, newPhone = null, newEmail = null, newIdentifier = null, newStatus = null, newExternalClientId = null, newExternalClientIdReset = false, newLocalLanguageName = null) {
    super()
    super.copy(parent)
    this.PersonId = personId
    this.NewName = newName
    this.NewCountry = newCountry
    this.NewCity = newCity
    this.NewState = newState
    this.NewZipCode = newZipCode
    this.NewAddress = newAddress
    this.NewPhone = newPhone
    this.NewEmail = newEmail
    this.NewIdentifier = newIdentifier
    this.NewStatus = newStatus
    this.NewExternalClientId = newExternalClientId
    this.NewExternalClientIdReset = newExternalClientIdReset
    this.NewLocalLanguageName = newLocalLanguageName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!PersonModifyRequest}
   * @param {!PersonModifyRequest} other Other struct
   * @returns {!PersonModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.PersonId != null) {
      this.PersonId = UInt64.fromNumber(other.PersonId)
    } else {
      this.PersonId = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewCountry != null) {
      this.NewCountry = other.NewCountry
    } else {
      this.NewCountry = null
    }
    if (other.NewCity != null) {
      this.NewCity = other.NewCity
    } else {
      this.NewCity = null
    }
    if (other.NewState != null) {
      this.NewState = other.NewState
    } else {
      this.NewState = null
    }
    if (other.NewZipCode != null) {
      this.NewZipCode = other.NewZipCode
    } else {
      this.NewZipCode = null
    }
    if (other.NewAddress != null) {
      this.NewAddress = other.NewAddress
    } else {
      this.NewAddress = null
    }
    if (other.NewPhone != null) {
      this.NewPhone = other.NewPhone
    } else {
      this.NewPhone = null
    }
    if (other.NewEmail != null) {
      this.NewEmail = other.NewEmail
    } else {
      this.NewEmail = null
    }
    if (other.NewIdentifier != null) {
      this.NewIdentifier = other.NewIdentifier
    } else {
      this.NewIdentifier = null
    }
    if (other.NewStatus != null) {
      this.NewStatus = other.NewStatus
    } else {
      this.NewStatus = null
    }
    if (other.NewExternalClientId != null) {
      this.NewExternalClientId = other.NewExternalClientId
    } else {
      this.NewExternalClientId = null
    }
    if (other.NewExternalClientIdReset != null) {
      this.NewExternalClientIdReset = other.NewExternalClientIdReset
    } else {
      this.NewExternalClientIdReset = null
    }
    if (other.NewLocalLanguageName != null) {
      this.NewLocalLanguageName = other.NewLocalLanguageName
    } else {
      this.NewLocalLanguageName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!PersonModifyRequest}
   * @returns {!PersonModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PersonModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PersonModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!PersonModifyRequest}
   * @param {!PersonModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof PersonModifyRequest)) {
      throw new TypeError('Instance of PersonModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!PersonModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      PersonId: ((this.PersonId != null) ? this.PersonId.toNumber() : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewCountry: ((this.NewCountry != null) ? this.NewCountry : null),
      NewCity: ((this.NewCity != null) ? this.NewCity : null),
      NewState: ((this.NewState != null) ? this.NewState : null),
      NewZipCode: ((this.NewZipCode != null) ? this.NewZipCode : null),
      NewAddress: ((this.NewAddress != null) ? this.NewAddress : null),
      NewPhone: ((this.NewPhone != null) ? this.NewPhone : null),
      NewEmail: ((this.NewEmail != null) ? this.NewEmail : null),
      NewIdentifier: ((this.NewIdentifier != null) ? this.NewIdentifier : null),
      NewStatus: ((this.NewStatus != null) ? this.NewStatus : null),
      NewExternalClientId: ((this.NewExternalClientId != null) ? this.NewExternalClientId : null),
      NewExternalClientIdReset: ((this.NewExternalClientIdReset != null) ? this.NewExternalClientIdReset : null),
      NewLocalLanguageName: ((this.NewLocalLanguageName != null) ? this.NewLocalLanguageName : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return PersonModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!PersonModifyRequest} other Object value
   * @returns {!PersonModifyRequest} Created struct
   */
  static fromObject (other) {
    return new PersonModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!PersonModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return PersonModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!PersonModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6529
  }
}

export { PersonModifyRequest };

/**
 * Fast Binary Encoding PersonModifyRequest field model
 */
class FieldModelPersonModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.PersonId = new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.PersonId.fbeOffset + this.PersonId.fbeSize), buffer, this.PersonId.fbeOffset + this.PersonId.fbeSize)
    this.NewCountry = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewCity = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCountry.fbeOffset + this.NewCountry.fbeSize), buffer, this.NewCountry.fbeOffset + this.NewCountry.fbeSize)
    this.NewState = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCity.fbeOffset + this.NewCity.fbeSize), buffer, this.NewCity.fbeOffset + this.NewCity.fbeSize)
    this.NewZipCode = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewState.fbeOffset + this.NewState.fbeSize), buffer, this.NewState.fbeOffset + this.NewState.fbeSize)
    this.NewAddress = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewZipCode.fbeOffset + this.NewZipCode.fbeSize), buffer, this.NewZipCode.fbeOffset + this.NewZipCode.fbeSize)
    this.NewPhone = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewAddress.fbeOffset + this.NewAddress.fbeSize), buffer, this.NewAddress.fbeOffset + this.NewAddress.fbeSize)
    this.NewEmail = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewPhone.fbeOffset + this.NewPhone.fbeSize), buffer, this.NewPhone.fbeOffset + this.NewPhone.fbeSize)
    this.NewIdentifier = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewEmail.fbeOffset + this.NewEmail.fbeSize), buffer, this.NewEmail.fbeOffset + this.NewEmail.fbeSize)
    this.NewStatus = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewIdentifier.fbeOffset + this.NewIdentifier.fbeSize), buffer, this.NewIdentifier.fbeOffset + this.NewIdentifier.fbeSize)
    this.NewExternalClientId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewStatus.fbeOffset + this.NewStatus.fbeSize), buffer, this.NewStatus.fbeOffset + this.NewStatus.fbeSize)
    this.NewExternalClientIdReset = new fbe.FieldModelBool(buffer, this.NewExternalClientId.fbeOffset + this.NewExternalClientId.fbeSize)
    this.NewLocalLanguageName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewExternalClientIdReset.fbeOffset + this.NewExternalClientIdReset.fbeSize), buffer, this.NewExternalClientIdReset.fbeOffset + this.NewExternalClientIdReset.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelPersonModifyRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelPersonModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPersonModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.PersonId.fbeSize + this.NewName.fbeSize + this.NewCountry.fbeSize + this.NewCity.fbeSize + this.NewState.fbeSize + this.NewZipCode.fbeSize + this.NewAddress.fbeSize + this.NewPhone.fbeSize + this.NewEmail.fbeSize + this.NewIdentifier.fbeSize + this.NewStatus.fbeSize + this.NewExternalClientId.fbeSize + this.NewExternalClientIdReset.fbeSize + this.NewLocalLanguageName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPersonModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.PersonId.fbeExtra + this.NewName.fbeExtra + this.NewCountry.fbeExtra + this.NewCity.fbeExtra + this.NewState.fbeExtra + this.NewZipCode.fbeExtra + this.NewAddress.fbeExtra + this.NewPhone.fbeExtra + this.NewEmail.fbeExtra + this.NewIdentifier.fbeExtra + this.NewStatus.fbeExtra + this.NewExternalClientId.fbeExtra + this.NewExternalClientIdReset.fbeExtra + this.NewLocalLanguageName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPersonModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPersonModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPersonModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6529
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPersonModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPersonModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.PersonId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PersonId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PersonId.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewCountry.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCountry.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCountry.fbeSize

    if ((fbeCurrentSize + this.NewCity.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCity.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCity.fbeSize

    if ((fbeCurrentSize + this.NewState.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewState.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewState.fbeSize

    if ((fbeCurrentSize + this.NewZipCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewZipCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewZipCode.fbeSize

    if ((fbeCurrentSize + this.NewAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAddress.fbeSize

    if ((fbeCurrentSize + this.NewPhone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPhone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPhone.fbeSize

    if ((fbeCurrentSize + this.NewEmail.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewEmail.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEmail.fbeSize

    if ((fbeCurrentSize + this.NewIdentifier.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIdentifier.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIdentifier.fbeSize

    if ((fbeCurrentSize + this.NewStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStatus.fbeSize

    if ((fbeCurrentSize + this.NewExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalClientId.fbeSize

    if ((fbeCurrentSize + this.NewExternalClientIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExternalClientIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalClientIdReset.fbeSize

    if ((fbeCurrentSize + this.NewLocalLanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLocalLanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLocalLanguageName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPersonModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPersonModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPersonModifyRequest}
   * @param {!PersonModifyRequest} fbeValue Default value, defaults is new PersonModifyRequest()
   * @returns {!PersonModifyRequest} PersonModifyRequest value
   */
  get (fbeValue = new PersonModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPersonModifyRequest}
   * @param {!PersonModifyRequest} fbeValue PersonModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.PersonId.fbeSize) <= fbeStructSize) {
      fbeValue.PersonId = this.PersonId.get()
    } else {
      fbeValue.PersonId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PersonId.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewCountry.fbeSize) <= fbeStructSize) {
      fbeValue.NewCountry = this.NewCountry.get()
    } else {
      fbeValue.NewCountry = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCountry.fbeSize

    if ((fbeCurrentSize + this.NewCity.fbeSize) <= fbeStructSize) {
      fbeValue.NewCity = this.NewCity.get()
    } else {
      fbeValue.NewCity = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCity.fbeSize

    if ((fbeCurrentSize + this.NewState.fbeSize) <= fbeStructSize) {
      fbeValue.NewState = this.NewState.get()
    } else {
      fbeValue.NewState = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewState.fbeSize

    if ((fbeCurrentSize + this.NewZipCode.fbeSize) <= fbeStructSize) {
      fbeValue.NewZipCode = this.NewZipCode.get()
    } else {
      fbeValue.NewZipCode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewZipCode.fbeSize

    if ((fbeCurrentSize + this.NewAddress.fbeSize) <= fbeStructSize) {
      fbeValue.NewAddress = this.NewAddress.get()
    } else {
      fbeValue.NewAddress = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAddress.fbeSize

    if ((fbeCurrentSize + this.NewPhone.fbeSize) <= fbeStructSize) {
      fbeValue.NewPhone = this.NewPhone.get()
    } else {
      fbeValue.NewPhone = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPhone.fbeSize

    if ((fbeCurrentSize + this.NewEmail.fbeSize) <= fbeStructSize) {
      fbeValue.NewEmail = this.NewEmail.get()
    } else {
      fbeValue.NewEmail = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEmail.fbeSize

    if ((fbeCurrentSize + this.NewIdentifier.fbeSize) <= fbeStructSize) {
      fbeValue.NewIdentifier = this.NewIdentifier.get()
    } else {
      fbeValue.NewIdentifier = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIdentifier.fbeSize

    if ((fbeCurrentSize + this.NewStatus.fbeSize) <= fbeStructSize) {
      fbeValue.NewStatus = this.NewStatus.get()
    } else {
      fbeValue.NewStatus = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStatus.fbeSize

    if ((fbeCurrentSize + this.NewExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.NewExternalClientId = this.NewExternalClientId.get()
    } else {
      fbeValue.NewExternalClientId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalClientId.fbeSize

    if ((fbeCurrentSize + this.NewExternalClientIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewExternalClientIdReset = this.NewExternalClientIdReset.get()
    } else {
      fbeValue.NewExternalClientIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalClientIdReset.fbeSize

    if ((fbeCurrentSize + this.NewLocalLanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.NewLocalLanguageName = this.NewLocalLanguageName.get()
    } else {
      fbeValue.NewLocalLanguageName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLocalLanguageName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPersonModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPersonModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPersonModifyRequest}
   * @param {!PersonModifyRequest} fbeValue PersonModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPersonModifyRequest}
   * @param {PersonModifyRequest} fbeValue PersonModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.PersonId.set(fbeValue.PersonId)
    this.NewName.set(fbeValue.NewName)
    this.NewCountry.set(fbeValue.NewCountry)
    this.NewCity.set(fbeValue.NewCity)
    this.NewState.set(fbeValue.NewState)
    this.NewZipCode.set(fbeValue.NewZipCode)
    this.NewAddress.set(fbeValue.NewAddress)
    this.NewPhone.set(fbeValue.NewPhone)
    this.NewEmail.set(fbeValue.NewEmail)
    this.NewIdentifier.set(fbeValue.NewIdentifier)
    this.NewStatus.set(fbeValue.NewStatus)
    this.NewExternalClientId.set(fbeValue.NewExternalClientId)
    this.NewExternalClientIdReset.set(fbeValue.NewExternalClientIdReset)
    this.NewLocalLanguageName.set(fbeValue.NewLocalLanguageName)
  }
}

export { FieldModelPersonModifyRequest };

/**
 * Fast Binary Encoding PersonModifyRequest model
 */
class PersonModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPersonModifyRequest(this.buffer, 4)
  }

  /**
   * Get the PersonModifyRequest model
   * @this {!PersonModifyRequestModel}
   * @returns {!FieldModelPersonModifyRequest} model PersonModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PersonModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PersonModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PersonModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PersonModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPersonModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PersonModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PersonModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PersonModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PersonModifyRequestModel}
   * @param {!PersonModifyRequest} value PersonModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PersonModifyRequestModel}
   * @param {!PersonModifyRequest} value PersonModifyRequest value, defaults is new PersonModifyRequest()
   * @return {!object} Deserialized PersonModifyRequest value and its size
   */
  deserialize (value = new PersonModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new PersonModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new PersonModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PersonModifyRequestModel}
   * @param {!number} prev Previous PersonModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PersonModifyRequestModel };
PersonModifyRequest.__has_response = true;
PersonModifyRequest.__response_class = PersonModifyResponse;
