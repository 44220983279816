// Tab keys must be in camelCase

export enum MANAGERS_PAGES {
    MANAGERS = 'managers',
    MANAGER_GROUPS = 'managerGroups',
}

export enum CALENDAR_PAGES {
    REGULAR = 'regular',
    OFFTIME = 'offtime',
}

export enum CURRENCY_PAGES {
    CURRENCIES = 'currencies',
    TYPES = 'currencyTypes',
}

export enum PROFILES_PAGES {
    SYMBOL_MAPS = 'symbolMaps',
    COMMISSION = 'commission',
    MARKUP = 'markup',
}

export enum QUOTE_HISTORY_PAGES {
    CHARTS_BARS = 'chartBars',
    CHARTS_BARS_IMPORT = 'chartBarsImport',
    TICKS = 'ticks',
}

export enum SYMBOLS_PAGES {
    SYMBOLS = 'symbols',
    SYMBOL_GROUPS = 'symbolGroups',
    WATCHLIST_SETTINGS = 'watchlist',
}

export enum SYSTEM_PAGES {
    HOSTS = 'hosts',
    TIMEZONES = 'timezones',
}

export enum ACCOUNTS_PAGES {
    ACCOUNTS = 'accounts',
    ACCOUNT_GROUPS = 'accountGroups',
}

export enum RM_PAGES {
    POSITIONS = 'positions',
    PENDING_ORDERS = 'pendingOrders',
    DEALS = 'deals',
    SUMMARY = 'summary',
}

export enum HLB_PROFILES_PAGES {
    MARGIN_RATE = 'marginRate',
    NEWS = 'news',
}

export enum SECURITY_PAGES {
    SECURITY_PROFILES = 'securityProfiles',
    FIREWALL = 'firewall',
}

export enum PUSH_NOTIFICATIONS_PAGES {
    PUSH_NOTIFICATION_TEMPLATES = 'pushNotificationTemplates',
    LANGUAGES = 'languages',
}

export enum STOCKS_PAGES {
    DIVIDENDS = 'dividends',
    SPLITS = 'splits',
}
