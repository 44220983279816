import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { convertLegalDocumentGroupWithDocumentsDescriptionsToModel } from '@models/legalDocuments/LegalDocumentGroup';
import { LegalDocumentGroupGetFilterRequest } from '@api/fbe/manager/LegalDocumentGroupGetFilterRequest';
import { LegalDocumentDeleteRequest } from '@api/fbe/manager/LegalDocumentDeleteRequest';
import { UInt64 } from '@api/fbe/int64';
import { LegalDocumentGroupDeleteRequest } from '@api/fbe/manager/LegalDocumentGroupDeleteRequest';
import { LegalDocumentUploadRequest } from '@api/fbe/manager/LegalDocumentUploadRequest';
import { convertModelToLegalDocumentMetadata, UploadDocumentModel } from '@models/legalDocuments/LegalDocumentMetadata';
import { LegalDocumentGroupModifyRequest } from '@api/fbe/manager/LegalDocumentGroupModifyRequest';
import {
    getMaxSortOrder,
    LegalDocModel,
    mapLegalDocumentGroupWithDocumentsDescriptionsModelsToLegalDocModels,
} from '@models/legalDocuments/LegalDocument';
import { Message, MessageType } from '@components/Message/Message';
import { LegalDocumentGroupCreateRequest } from '@api/fbe/manager/LegalDocumentGroupCreateRequest';
import { LegalDocumentType } from '@api/fbe/manager/LegalDocumentType';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { LegalDocumentGetRequest } from '@api/fbe/manager/LegalDocumentGetRequest';
import {
    convertLegalDocumentDescriptionToModel,
    LegalDocumentGetResponseModel,
    LegalDocumentDescriptionModel,
} from '@models/legalDocuments/LegalDocumentDescription';
import { isNumber } from 'lodash';

export const isIdValid = (id: number | string | null): boolean => {
    if (id === null || id === '' || id === undefined) {
        Message(MessageType.error, 'ID is not found');
        return false;
    }
    if (typeof id === 'number' && !Number.isFinite(id)) {
        Message(MessageType.error, 'ID is not valid');
        return false;
    }
    if (typeof id === 'string' && !Number.isFinite(+id)) {
        Message(MessageType.error, 'ID is not valid');
        return false;
    }
    return true;
};

export class LegalDocumentsStore {
    errorTracker = new ErrorsTracker({ title: 'Legal Documents' });

    constructor() {
        makeAutoObservable(this);
    }

    data: LegalDocModel[] = [];

    allDocuments: LegalDocumentDescriptionModel[] = [];

    isLoading = false;

    setData(data: LegalDocModel[]) {
        this.data = data;
    }

    setAllDocuments(value: LegalDocumentDescriptionModel[]) {
        this.allDocuments = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new LegalDocumentGroupGetFilterRequest();
        request.GroupId = null;
        request.IncludeDisabled = true;
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        const dataMapped = data.Groups.map(convertLegalDocumentGroupWithDocumentsDescriptionsToModel);
        const result = mapLegalDocumentGroupWithDocumentsDescriptionsModelsToLegalDocModels(dataMapped).sort((a, b) =>
            baseSorter(a.SortOrder, b.SortOrder),
        );
        this.setData(result);
        const docs = result.reduce((acc: LegalDocumentDescriptionModel[], item) => acc.concat(item.Documents), []);
        this.setAllDocuments(docs);
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(newItem: LegalDocModel) {
        const request = new LegalDocumentGroupCreateRequest();
        if (newItem.LegalDocumentType === null) {
            Message(MessageType.error, 'Document Group Type is not selected');
            return;
        }
        request.LegalDocumentType = new LegalDocumentType(newItem.LegalDocumentType);
        request.IsEnabled = newItem.IsEnabled;
        request.Comment = newItem.Comment ?? '';
        request.SortOrder = UInt64.fromNumber(newItem.SortOrder ?? getMaxSortOrder(this.data) + 1);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(data: LegalDocModel) {
        const request = new LegalDocumentGroupModifyRequest();
        if (data.GroupId === null) {
            Message(MessageType.error, 'Document Group is not found');
            return;
        }
        request.GroupId = UInt64.fromNumber(data.GroupId);
        request.NewIsEnabled = data.IsEnabled;
        request.NewComment = data.Comment ?? '';
        request.NewSortOrder = isNumber(data.SortOrder) ? UInt64.fromNumber(data.SortOrder) : undefined;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    // async updateItemSortOrder(id: number | null, newIndex: number) {
    //     const targetItem = this.data.find((item) => item.GroupId === id);
    //     if (targetItem && targetItem.GroupId !== null) {
    //         const request = new LegalDocumentGroupModifyRequest();
    //         request.GroupId = UInt64.fromNumber(targetItem.GroupId);
    //         request.NewSortOrder = UInt64.fromNumber(newIndex);
    //         await SocketClient.instance.request(request, this.errorTracker);
    //         this.get();
    //     } else {
    //         throw new Error("Can't find Document Group to reorder");
    //     }
    // }

    @ErrorsTracker.wrapApi()
    async deleteDocument(id: number | string) {
        if (!isIdValid(id)) return;
        const request = new LegalDocumentDeleteRequest();
        request.DocumentId = UInt64.fromNumber(+id);
        await SocketClient.instance.request(request, this.errorTracker);
    }

    @ErrorsTracker.wrapApi()
    async delete(id: number) {
        const request = new LegalDocumentGroupDeleteRequest();
        request.GroupId = UInt64.fromNumber(id);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async uploadDocument(document: UploadDocumentModel) {
        const request = new LegalDocumentUploadRequest();
        if (document.GroupId === null) {
            Message(MessageType.error, 'Document Group is not found');
            return;
        }
        request.GroupId = UInt64.fromNumber(document.GroupId);
        request.Metadata = convertModelToLegalDocumentMetadata({
            Name: document.Metadata.Name,
            ContentType: document.Metadata.ContentType,
            Size: document.Metadata.Size,
            Language: document.Metadata.Language,
            Created: document.Metadata.Created,
        });
        if (document.Content === null) return;
        request.DocumentContent = new Uint8Array(document.Content);

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async downloadDocument(id: number | string) {
        if (!isIdValid(id)) return;
        const request = new LegalDocumentGetRequest();
        request.DocumentId = UInt64.fromNumber(+id);
        const result = await SocketClient.instance.request(request, this.errorTracker);
        const resultConverted: LegalDocumentGetResponseModel = {
            Description: convertLegalDocumentDescriptionToModel(result.Description),
            DocumentContent: result.DocumentContent,
        };
        const blob = new Blob([resultConverted.DocumentContent], {
            type: resultConverted.Description.Metadata.ContentType,
        });
        const url = URL.createObjectURL(blob);
        window.open(url);
    }

    @ErrorsTracker.wrapApi()
    async getGroupDocuments(groupId: number) {
        const request = new LegalDocumentGroupGetFilterRequest();
        request.GroupId = UInt64.fromNumber(groupId);
        request.IncludeDisabled = true;
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        const documents = data.Groups.map(convertLegalDocumentGroupWithDocumentsDescriptionsToModel)
            .map((item) => item.Documents)
            .reduce((acc, item) => acc.concat(item), []);
        this.setIsLoading(false);
        return documents;
    }

    reset() {
        this.data = [];
        this.isLoading = false;
        this.allDocuments = [];
    }
}

const legalDocumentsStore = new LegalDocumentsStore();
export { legalDocumentsStore };
