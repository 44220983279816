
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * LegalDocumentMetadata struct
 */
class LegalDocumentMetadata {
  /**
   * Initialize struct
   * @param {!string=} name
   * @param {!string=} contentType
   * @param {!number=} size
   * @param {!string=} language
   * @param {!Date=} created
   * @constructor
   */
  constructor (name = '', contentType = '', size = new UInt64(0, 0), language = '', created = new Date(0)) {
    this.Name = name
    this.ContentType = contentType
    this.Size = size
    this.Language = language
    this.Created = created
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LegalDocumentMetadata}
   * @param {!LegalDocumentMetadata} other Other struct
   * @returns {!LegalDocumentMetadata} This struct
   */
  copy (other) {
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.ContentType != null) {
      this.ContentType = other.ContentType
    } else {
      this.ContentType = null
    }
    if (other.Size != null) {
      this.Size = UInt64.fromNumber(other.Size)
    } else {
      this.Size = null
    }
    if (other.Language != null) {
      this.Language = other.Language
    } else {
      this.Language = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LegalDocumentMetadata}
   * @returns {!LegalDocumentMetadata} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LegalDocumentMetadataModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LegalDocumentMetadataModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LegalDocumentMetadata}
   * @param {!LegalDocumentMetadata} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LegalDocumentMetadata)) {
      throw new TypeError('Instance of LegalDocumentMetadata is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LegalDocumentMetadata}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Name: ((this.Name != null) ? this.Name : null),
      ContentType: ((this.ContentType != null) ? this.ContentType : null),
      Size: ((this.Size != null) ? this.Size.toNumber() : null),
      Language: ((this.Language != null) ? this.Language : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LegalDocumentMetadata.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LegalDocumentMetadata} other Object value
   * @returns {!LegalDocumentMetadata} Created struct
   */
  static fromObject (other) {
    return new LegalDocumentMetadata().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LegalDocumentMetadata}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LegalDocumentMetadata.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LegalDocumentMetadata}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 15001
  }
}

export { LegalDocumentMetadata };

/**
 * Fast Binary Encoding LegalDocumentMetadata field model
 */
class FieldModelLegalDocumentMetadata extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Name = new fbe.FieldModelString(buffer, 4 + 4)
    this.ContentType = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Size = new fbe.FieldModelUInt64(buffer, this.ContentType.fbeOffset + this.ContentType.fbeSize)
    this.Language = new fbe.FieldModelString(buffer, this.Size.fbeOffset + this.Size.fbeSize)
    this.Created = new fbe.FieldModelTimestamp(buffer, this.Language.fbeOffset + this.Language.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelLegalDocumentMetadata}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLegalDocumentMetadata}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Name.fbeSize + this.ContentType.fbeSize + this.Size.fbeSize + this.Language.fbeSize + this.Created.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLegalDocumentMetadata}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Name.fbeExtra + this.ContentType.fbeExtra + this.Size.fbeExtra + this.Language.fbeExtra + this.Created.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLegalDocumentMetadata}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLegalDocumentMetadata.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLegalDocumentMetadata}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 15001
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLegalDocumentMetadata}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLegalDocumentMetadata}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.ContentType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ContentType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ContentType.fbeSize

    if ((fbeCurrentSize + this.Size.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Size.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Size.fbeSize

    if ((fbeCurrentSize + this.Language.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Language.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Language.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLegalDocumentMetadata}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLegalDocumentMetadata}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLegalDocumentMetadata}
   * @param {!LegalDocumentMetadata} fbeValue Default value, defaults is new LegalDocumentMetadata()
   * @returns {!LegalDocumentMetadata} LegalDocumentMetadata value
   */
  get (fbeValue = new LegalDocumentMetadata()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLegalDocumentMetadata}
   * @param {!LegalDocumentMetadata} fbeValue LegalDocumentMetadata value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.ContentType.fbeSize) <= fbeStructSize) {
      fbeValue.ContentType = this.ContentType.get()
    } else {
      fbeValue.ContentType = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ContentType.fbeSize

    if ((fbeCurrentSize + this.Size.fbeSize) <= fbeStructSize) {
      fbeValue.Size = this.Size.get()
    } else {
      fbeValue.Size = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Size.fbeSize

    if ((fbeCurrentSize + this.Language.fbeSize) <= fbeStructSize) {
      fbeValue.Language = this.Language.get()
    } else {
      fbeValue.Language = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Language.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLegalDocumentMetadata}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLegalDocumentMetadata}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLegalDocumentMetadata}
   * @param {!LegalDocumentMetadata} fbeValue LegalDocumentMetadata value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLegalDocumentMetadata}
   * @param {LegalDocumentMetadata} fbeValue LegalDocumentMetadata value
   */
  setFields (fbeValue) {
    this.Name.set(fbeValue.Name)
    this.ContentType.set(fbeValue.ContentType)
    this.Size.set(fbeValue.Size)
    this.Language.set(fbeValue.Language)
    this.Created.set(fbeValue.Created)
  }
}

export { FieldModelLegalDocumentMetadata };

/**
 * Fast Binary Encoding LegalDocumentMetadata model
 */
class LegalDocumentMetadataModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLegalDocumentMetadata(this.buffer, 4)
  }

  /**
   * Get the LegalDocumentMetadata model
   * @this {!LegalDocumentMetadataModel}
   * @returns {!FieldModelLegalDocumentMetadata} model LegalDocumentMetadata model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LegalDocumentMetadataModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LegalDocumentMetadataModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LegalDocumentMetadataModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LegalDocumentMetadataModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLegalDocumentMetadata.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LegalDocumentMetadataModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LegalDocumentMetadataModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LegalDocumentMetadataModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LegalDocumentMetadataModel}
   * @param {!LegalDocumentMetadata} value LegalDocumentMetadata value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LegalDocumentMetadataModel}
   * @param {!LegalDocumentMetadata} value LegalDocumentMetadata value, defaults is new LegalDocumentMetadata()
   * @return {!object} Deserialized LegalDocumentMetadata value and its size
   */
  deserialize (value = new LegalDocumentMetadata()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LegalDocumentMetadata(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LegalDocumentMetadata(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LegalDocumentMetadataModel}
   * @param {!number} prev Previous LegalDocumentMetadata model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LegalDocumentMetadataModel };
