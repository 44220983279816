
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {DemoAccountsDeleteResponse} from './DemoAccountsDeleteResponse';

/**
 * DemoAccountsDeleteRequest struct
 */
class DemoAccountsDeleteRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!Array=} logins
   * @param {UUID=} accountGroupId
   * @param {string=} accountGroupName
   * @param {number=} daysSinceLastLogin
   * @param {!boolean=} force
   * @constructor
   */
  constructor (parent = new Request(), logins = [], accountGroupId = null, accountGroupName = null, daysSinceLastLogin = null, force = false) {
    super()
    super.copy(parent)
    this.Logins = logins
    this.AccountGroupId = accountGroupId
    this.AccountGroupName = accountGroupName
    this.DaysSinceLastLogin = daysSinceLastLogin
    this.Force = force
  }

  /**
   * Copy struct (shallow copy)
   * @this {!DemoAccountsDeleteRequest}
   * @param {!DemoAccountsDeleteRequest} other Other struct
   * @returns {!DemoAccountsDeleteRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Logins != null) {
      this.Logins = []
      for (let item of other.Logins) {
        if (item != null) {
          let tempItem
          tempItem = UInt64.fromNumber(item)
          this.Logins.push(tempItem)
        } else {
          this.Logins.push(null)
        }
      }
    } else {
      this.Logins = null
    }
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.AccountGroupName != null) {
      this.AccountGroupName = other.AccountGroupName
    } else {
      this.AccountGroupName = null
    }
    if (other.DaysSinceLastLogin != null) {
      this.DaysSinceLastLogin = UInt64.fromNumber(other.DaysSinceLastLogin)
    } else {
      this.DaysSinceLastLogin = null
    }
    if (other.Force != null) {
      this.Force = other.Force
    } else {
      this.Force = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!DemoAccountsDeleteRequest}
   * @returns {!DemoAccountsDeleteRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new DemoAccountsDeleteRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new DemoAccountsDeleteRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!DemoAccountsDeleteRequest}
   * @param {!DemoAccountsDeleteRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DemoAccountsDeleteRequest)) {
      throw new TypeError('Instance of DemoAccountsDeleteRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!DemoAccountsDeleteRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Logins: ((this.Logins != null) ? Array.from(this.Logins, item => ((item != null) ? item.toNumber() : null)) : null),
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      AccountGroupName: ((this.AccountGroupName != null) ? this.AccountGroupName : null),
      DaysSinceLastLogin: ((this.DaysSinceLastLogin != null) ? this.DaysSinceLastLogin.toNumber() : null),
      Force: ((this.Force != null) ? this.Force : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return DemoAccountsDeleteRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!DemoAccountsDeleteRequest} other Object value
   * @returns {!DemoAccountsDeleteRequest} Created struct
   */
  static fromObject (other) {
    return new DemoAccountsDeleteRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!DemoAccountsDeleteRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return DemoAccountsDeleteRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!DemoAccountsDeleteRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6198
  }
}

export { DemoAccountsDeleteRequest };

/**
 * Fast Binary Encoding DemoAccountsDeleteRequest field model
 */
class FieldModelDemoAccountsDeleteRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.Logins = new fbe.FieldModelVector(new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.Logins.fbeOffset + this.Logins.fbeSize), buffer, this.Logins.fbeOffset + this.Logins.fbeSize)
    this.AccountGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize), buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
    this.DaysSinceLastLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AccountGroupName.fbeOffset + this.AccountGroupName.fbeSize), buffer, this.AccountGroupName.fbeOffset + this.AccountGroupName.fbeSize)
    this.Force = new fbe.FieldModelBool(buffer, this.DaysSinceLastLogin.fbeOffset + this.DaysSinceLastLogin.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Logins.fbeSize + this.AccountGroupId.fbeSize + this.AccountGroupName.fbeSize + this.DaysSinceLastLogin.fbeSize + this.Force.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Logins.fbeExtra + this.AccountGroupId.fbeExtra + this.AccountGroupName.fbeExtra + this.DaysSinceLastLogin.fbeExtra + this.Force.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDemoAccountsDeleteRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6198
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Logins.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Logins.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Logins.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    if ((fbeCurrentSize + this.DaysSinceLastLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DaysSinceLastLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DaysSinceLastLogin.fbeSize

    if ((fbeCurrentSize + this.Force.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Force.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Force.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @param {!DemoAccountsDeleteRequest} fbeValue Default value, defaults is new DemoAccountsDeleteRequest()
   * @returns {!DemoAccountsDeleteRequest} DemoAccountsDeleteRequest value
   */
  get (fbeValue = new DemoAccountsDeleteRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @param {!DemoAccountsDeleteRequest} fbeValue DemoAccountsDeleteRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Logins.fbeSize) <= fbeStructSize) {
      this.Logins.get(fbeValue.Logins)
    } else {
      fbeValue.Logins.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Logins.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupName = this.AccountGroupName.get()
    } else {
      fbeValue.AccountGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    if ((fbeCurrentSize + this.DaysSinceLastLogin.fbeSize) <= fbeStructSize) {
      fbeValue.DaysSinceLastLogin = this.DaysSinceLastLogin.get()
    } else {
      fbeValue.DaysSinceLastLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DaysSinceLastLogin.fbeSize

    if ((fbeCurrentSize + this.Force.fbeSize) <= fbeStructSize) {
      fbeValue.Force = this.Force.get(false)
    } else {
      fbeValue.Force = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Force.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @param {!DemoAccountsDeleteRequest} fbeValue DemoAccountsDeleteRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDemoAccountsDeleteRequest}
   * @param {DemoAccountsDeleteRequest} fbeValue DemoAccountsDeleteRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Logins.set(fbeValue.Logins)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.AccountGroupName.set(fbeValue.AccountGroupName)
    this.DaysSinceLastLogin.set(fbeValue.DaysSinceLastLogin)
    this.Force.set(fbeValue.Force)
  }
}

export { FieldModelDemoAccountsDeleteRequest };

/**
 * Fast Binary Encoding DemoAccountsDeleteRequest model
 */
class DemoAccountsDeleteRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDemoAccountsDeleteRequest(this.buffer, 4)
  }

  /**
   * Get the DemoAccountsDeleteRequest model
   * @this {!DemoAccountsDeleteRequestModel}
   * @returns {!FieldModelDemoAccountsDeleteRequest} model DemoAccountsDeleteRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!DemoAccountsDeleteRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!DemoAccountsDeleteRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return DemoAccountsDeleteRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!DemoAccountsDeleteRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDemoAccountsDeleteRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!DemoAccountsDeleteRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!DemoAccountsDeleteRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!DemoAccountsDeleteRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!DemoAccountsDeleteRequestModel}
   * @param {!DemoAccountsDeleteRequest} value DemoAccountsDeleteRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!DemoAccountsDeleteRequestModel}
   * @param {!DemoAccountsDeleteRequest} value DemoAccountsDeleteRequest value, defaults is new DemoAccountsDeleteRequest()
   * @return {!object} Deserialized DemoAccountsDeleteRequest value and its size
   */
  deserialize (value = new DemoAccountsDeleteRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new DemoAccountsDeleteRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new DemoAccountsDeleteRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!DemoAccountsDeleteRequestModel}
   * @param {!number} prev Previous DemoAccountsDeleteRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { DemoAccountsDeleteRequestModel };
DemoAccountsDeleteRequest.__has_response = true;
DemoAccountsDeleteRequest.__response_class = DemoAccountsDeleteResponse;
