
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {AccountGroupCreateResponse} from './AccountGroupCreateResponse';
import {AccountingType} from '../core/AccountingType';
import {FieldModelAccountingType} from '../core/AccountingType';
import {TradingMode} from '../core/TradingMode';
import {FieldModelTradingMode} from '../core/TradingMode';
import {StopOutType} from '../core/StopOutType';
import {FieldModelStopOutType} from '../core/StopOutType';
import {SymbolGroupSettings} from '../core/SymbolGroupSettings';
import {FieldModelSymbolGroupSettings} from '../core/SymbolGroupSettings';

/**
 * AccountGroupCreateRequest struct
 */
class AccountGroupCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {number=} sortOrder
   * @param {UUID=} platformId
   * @param {string=} platformName
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!boolean=} isDemo
   * @param {!boolean=} isEnabled
   * @param {!boolean=} isOTPEnabled
   * @param {!boolean=} isTradeEnabled
   * @param {!AccountingType=} defaultAccountingType
   * @param {UUID=} defaultBalanceCurrencyId
   * @param {string=} defaultBalanceCurrencyName
   * @param {!number=} defaultBalance
   * @param {!number=} defaultCredit
   * @param {!number=} defaultLeverage
   * @param {!number=} marginCallLevel
   * @param {!TradingMode=} marginCallAccessType
   * @param {!number=} stopOutLevel
   * @param {!StopOutType=} stopOutType
   * @param {!TradingMode=} stopOutAccessType
   * @param {!number=} defaultMaxPendingOrders
   * @param {!Map=} symbolGroupSettings
   * @param {!boolean=} isSwapEnabled
   * @param {!number=} minPasswordLength
   * @param {!boolean=} deprecated1
   * @param {!boolean=} deprecated2
   * @param {!boolean=} isSSLAccessEnabled
   * @param {!boolean=} isHTTPSAccessEnabled
   * @param {!boolean=} isWSSAccessEnabled
   * @param {!boolean=} isFIXAccessEnabled
   * @param {number=} maxLeverage
   * @param {UUID=} leverageProfileId
   * @param {!number=} defaultMaxOrders
   * @param {number=} maxDepositOperationAmount
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), sortOrder = null, platformId = null, platformName = null, name = '', comment = '', description = '', isDemo = false, isEnabled = true, isOTPEnabled = false, isTradeEnabled = true, defaultAccountingType = new AccountingType(AccountingType.GROSS), defaultBalanceCurrencyId = null, defaultBalanceCurrencyName = null, defaultBalance = 0.0, defaultCredit = 0.0, defaultLeverage = 100, marginCallLevel = 0.6, marginCallAccessType = new TradingMode(TradingMode.NORMAL), stopOutLevel = 0.3, stopOutType = new StopOutType(StopOutType.CLOSE_MOST_LOSS), stopOutAccessType = new TradingMode(TradingMode.NORMAL), defaultMaxPendingOrders = new UInt64(1000, 0), symbolGroupSettings = new Map(), isSwapEnabled = false, minPasswordLength = 8, deprecated1 = false, deprecated2 = false, isSSLAccessEnabled = true, isHTTPSAccessEnabled = true, isWSSAccessEnabled = true, isFIXAccessEnabled = true, maxLeverage = null, leverageProfileId = null, defaultMaxOrders = new UInt64(1200, 0), maxDepositOperationAmount = null) {
    super()
    super.copy(parent)
    this.SortOrder = sortOrder
    this.PlatformId = platformId
    this.PlatformName = platformName
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.IsDemo = isDemo
    this.IsEnabled = isEnabled
    this.IsOTPEnabled = isOTPEnabled
    this.IsTradeEnabled = isTradeEnabled
    this.DefaultAccountingType = defaultAccountingType
    this.DefaultBalanceCurrencyId = defaultBalanceCurrencyId
    this.DefaultBalanceCurrencyName = defaultBalanceCurrencyName
    this.DefaultBalance = defaultBalance
    this.DefaultCredit = defaultCredit
    this.DefaultLeverage = defaultLeverage
    this.MarginCallLevel = marginCallLevel
    this.MarginCallAccessType = marginCallAccessType
    this.StopOutLevel = stopOutLevel
    this.StopOutType = stopOutType
    this.StopOutAccessType = stopOutAccessType
    this.DefaultMaxPendingOrders = defaultMaxPendingOrders
    this.SymbolGroupSettings = symbolGroupSettings
    this.IsSwapEnabled = isSwapEnabled
    this.MinPasswordLength = minPasswordLength
    this.Deprecated1 = deprecated1
    this.Deprecated2 = deprecated2
    this.IsSSLAccessEnabled = isSSLAccessEnabled
    this.IsHTTPSAccessEnabled = isHTTPSAccessEnabled
    this.IsWSSAccessEnabled = isWSSAccessEnabled
    this.IsFIXAccessEnabled = isFIXAccessEnabled
    this.MaxLeverage = maxLeverage
    this.LeverageProfileId = leverageProfileId
    this.DefaultMaxOrders = defaultMaxOrders
    this.MaxDepositOperationAmount = maxDepositOperationAmount
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountGroupCreateRequest}
   * @param {!AccountGroupCreateRequest} other Other struct
   * @returns {!AccountGroupCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.PlatformId != null) {
      this.PlatformId = new UUID(other.PlatformId)
    } else {
      this.PlatformId = null
    }
    if (other.PlatformName != null) {
      this.PlatformName = other.PlatformName
    } else {
      this.PlatformName = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.IsDemo != null) {
      this.IsDemo = other.IsDemo
    } else {
      this.IsDemo = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.IsOTPEnabled != null) {
      this.IsOTPEnabled = other.IsOTPEnabled
    } else {
      this.IsOTPEnabled = null
    }
    if (other.IsTradeEnabled != null) {
      this.IsTradeEnabled = other.IsTradeEnabled
    } else {
      this.IsTradeEnabled = null
    }
    if (other.DefaultAccountingType != null) {
      this.DefaultAccountingType = AccountingType.fromObject(other.DefaultAccountingType)
    } else {
      this.DefaultAccountingType = null
    }
    if (other.DefaultBalanceCurrencyId != null) {
      this.DefaultBalanceCurrencyId = new UUID(other.DefaultBalanceCurrencyId)
    } else {
      this.DefaultBalanceCurrencyId = null
    }
    if (other.DefaultBalanceCurrencyName != null) {
      this.DefaultBalanceCurrencyName = other.DefaultBalanceCurrencyName
    } else {
      this.DefaultBalanceCurrencyName = null
    }
    if (other.DefaultBalance != null) {
      this.DefaultBalance = other.DefaultBalance
    } else {
      this.DefaultBalance = null
    }
    if (other.DefaultCredit != null) {
      this.DefaultCredit = other.DefaultCredit
    } else {
      this.DefaultCredit = null
    }
    if (other.DefaultLeverage != null) {
      this.DefaultLeverage = other.DefaultLeverage
    } else {
      this.DefaultLeverage = null
    }
    if (other.MarginCallLevel != null) {
      this.MarginCallLevel = other.MarginCallLevel
    } else {
      this.MarginCallLevel = null
    }
    if (other.MarginCallAccessType != null) {
      this.MarginCallAccessType = TradingMode.fromObject(other.MarginCallAccessType)
    } else {
      this.MarginCallAccessType = null
    }
    if (other.StopOutLevel != null) {
      this.StopOutLevel = other.StopOutLevel
    } else {
      this.StopOutLevel = null
    }
    if (other.StopOutType != null) {
      this.StopOutType = StopOutType.fromObject(other.StopOutType)
    } else {
      this.StopOutType = null
    }
    if (other.StopOutAccessType != null) {
      this.StopOutAccessType = TradingMode.fromObject(other.StopOutAccessType)
    } else {
      this.StopOutAccessType = null
    }
    if (other.DefaultMaxPendingOrders != null) {
      this.DefaultMaxPendingOrders = UInt64.fromNumber(other.DefaultMaxPendingOrders)
    } else {
      this.DefaultMaxPendingOrders = null
    }
    if (other.SymbolGroupSettings != null) {
      this.SymbolGroupSettings = new Map()
      Object.keys(other.SymbolGroupSettings).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = new UUID(key)
          if (other.SymbolGroupSettings[key] != null) {
            let tempValue
            tempValue = SymbolGroupSettings.fromObject(other.SymbolGroupSettings[key])
            this.SymbolGroupSettings.set(tempKey, tempValue)
          } else {
            this.SymbolGroupSettings.set(tempKey, null)
          }
        }
      })
    } else {
      this.SymbolGroupSettings = null
    }
    if (other.IsSwapEnabled != null) {
      this.IsSwapEnabled = other.IsSwapEnabled
    } else {
      this.IsSwapEnabled = null
    }
    if (other.MinPasswordLength != null) {
      this.MinPasswordLength = other.MinPasswordLength
    } else {
      this.MinPasswordLength = null
    }
    if (other.Deprecated1 != null) {
      this.Deprecated1 = other.Deprecated1
    } else {
      this.Deprecated1 = null
    }
    if (other.Deprecated2 != null) {
      this.Deprecated2 = other.Deprecated2
    } else {
      this.Deprecated2 = null
    }
    if (other.IsSSLAccessEnabled != null) {
      this.IsSSLAccessEnabled = other.IsSSLAccessEnabled
    } else {
      this.IsSSLAccessEnabled = null
    }
    if (other.IsHTTPSAccessEnabled != null) {
      this.IsHTTPSAccessEnabled = other.IsHTTPSAccessEnabled
    } else {
      this.IsHTTPSAccessEnabled = null
    }
    if (other.IsWSSAccessEnabled != null) {
      this.IsWSSAccessEnabled = other.IsWSSAccessEnabled
    } else {
      this.IsWSSAccessEnabled = null
    }
    if (other.IsFIXAccessEnabled != null) {
      this.IsFIXAccessEnabled = other.IsFIXAccessEnabled
    } else {
      this.IsFIXAccessEnabled = null
    }
    if (other.MaxLeverage != null) {
      this.MaxLeverage = other.MaxLeverage
    } else {
      this.MaxLeverage = null
    }
    if (other.LeverageProfileId != null) {
      this.LeverageProfileId = new UUID(other.LeverageProfileId)
    } else {
      this.LeverageProfileId = null
    }
    if (other.DefaultMaxOrders != null) {
      this.DefaultMaxOrders = UInt64.fromNumber(other.DefaultMaxOrders)
    } else {
      this.DefaultMaxOrders = null
    }
    if (other.MaxDepositOperationAmount != null) {
      this.MaxDepositOperationAmount = other.MaxDepositOperationAmount
    } else {
      this.MaxDepositOperationAmount = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountGroupCreateRequest}
   * @returns {!AccountGroupCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountGroupCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountGroupCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountGroupCreateRequest}
   * @param {!AccountGroupCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountGroupCreateRequest)) {
      throw new TypeError('Instance of AccountGroupCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountGroupCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      PlatformId: ((this.PlatformId != null) ? this.PlatformId.toString() : null),
      PlatformName: ((this.PlatformName != null) ? this.PlatformName : null),
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      IsDemo: ((this.IsDemo != null) ? this.IsDemo : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      IsOTPEnabled: ((this.IsOTPEnabled != null) ? this.IsOTPEnabled : null),
      IsTradeEnabled: ((this.IsTradeEnabled != null) ? this.IsTradeEnabled : null),
      DefaultAccountingType: ((this.DefaultAccountingType != null) ? this.DefaultAccountingType : null),
      DefaultBalanceCurrencyId: ((this.DefaultBalanceCurrencyId != null) ? this.DefaultBalanceCurrencyId.toString() : null),
      DefaultBalanceCurrencyName: ((this.DefaultBalanceCurrencyName != null) ? this.DefaultBalanceCurrencyName : null),
      DefaultBalance: ((this.DefaultBalance != null) ? this.DefaultBalance : null),
      DefaultCredit: ((this.DefaultCredit != null) ? this.DefaultCredit : null),
      DefaultLeverage: ((this.DefaultLeverage != null) ? this.DefaultLeverage : null),
      MarginCallLevel: ((this.MarginCallLevel != null) ? this.MarginCallLevel : null),
      MarginCallAccessType: ((this.MarginCallAccessType != null) ? this.MarginCallAccessType : null),
      StopOutLevel: ((this.StopOutLevel != null) ? this.StopOutLevel : null),
      StopOutType: ((this.StopOutType != null) ? this.StopOutType : null),
      StopOutAccessType: ((this.StopOutAccessType != null) ? this.StopOutAccessType : null),
      DefaultMaxPendingOrders: ((this.DefaultMaxPendingOrders != null) ? this.DefaultMaxPendingOrders.toNumber() : null),
      SymbolGroupSettings: ((this.SymbolGroupSettings != null) ? fbe.MapToObject(new Map(Array.from(this.SymbolGroupSettings, item => [((item[0] != null) ? item[0].toString() : null), ((item[1] != null) ? item[1] : null)]))) : null),
      IsSwapEnabled: ((this.IsSwapEnabled != null) ? this.IsSwapEnabled : null),
      MinPasswordLength: ((this.MinPasswordLength != null) ? this.MinPasswordLength : null),
      Deprecated1: ((this.Deprecated1 != null) ? this.Deprecated1 : null),
      Deprecated2: ((this.Deprecated2 != null) ? this.Deprecated2 : null),
      IsSSLAccessEnabled: ((this.IsSSLAccessEnabled != null) ? this.IsSSLAccessEnabled : null),
      IsHTTPSAccessEnabled: ((this.IsHTTPSAccessEnabled != null) ? this.IsHTTPSAccessEnabled : null),
      IsWSSAccessEnabled: ((this.IsWSSAccessEnabled != null) ? this.IsWSSAccessEnabled : null),
      IsFIXAccessEnabled: ((this.IsFIXAccessEnabled != null) ? this.IsFIXAccessEnabled : null),
      MaxLeverage: ((this.MaxLeverage != null) ? this.MaxLeverage : null),
      LeverageProfileId: ((this.LeverageProfileId != null) ? this.LeverageProfileId.toString() : null),
      DefaultMaxOrders: ((this.DefaultMaxOrders != null) ? this.DefaultMaxOrders.toNumber() : null),
      MaxDepositOperationAmount: ((this.MaxDepositOperationAmount != null) ? this.MaxDepositOperationAmount : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountGroupCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountGroupCreateRequest} other Object value
   * @returns {!AccountGroupCreateRequest} Created struct
   */
  static fromObject (other) {
    return new AccountGroupCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountGroupCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountGroupCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountGroupCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6181
  }
}

export { AccountGroupCreateRequest };

/**
 * Fast Binary Encoding AccountGroupCreateRequest field model
 */
class FieldModelAccountGroupCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.SortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.PlatformId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize), buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.PlatformName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize), buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.PlatformName.fbeOffset + this.PlatformName.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.IsDemo = new fbe.FieldModelBool(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.IsEnabled = new fbe.FieldModelBool(buffer, this.IsDemo.fbeOffset + this.IsDemo.fbeSize)
    this.IsOTPEnabled = new fbe.FieldModelBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.IsTradeEnabled = new fbe.FieldModelBool(buffer, this.IsOTPEnabled.fbeOffset + this.IsOTPEnabled.fbeSize)
    this.DefaultAccountingType = new FieldModelAccountingType(buffer, this.IsTradeEnabled.fbeOffset + this.IsTradeEnabled.fbeSize)
    this.DefaultBalanceCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.DefaultAccountingType.fbeOffset + this.DefaultAccountingType.fbeSize), buffer, this.DefaultAccountingType.fbeOffset + this.DefaultAccountingType.fbeSize)
    this.DefaultBalanceCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.DefaultBalanceCurrencyId.fbeOffset + this.DefaultBalanceCurrencyId.fbeSize), buffer, this.DefaultBalanceCurrencyId.fbeOffset + this.DefaultBalanceCurrencyId.fbeSize)
    this.DefaultBalance = new fbe.FieldModelDouble(buffer, this.DefaultBalanceCurrencyName.fbeOffset + this.DefaultBalanceCurrencyName.fbeSize)
    this.DefaultCredit = new fbe.FieldModelDouble(buffer, this.DefaultBalance.fbeOffset + this.DefaultBalance.fbeSize)
    this.DefaultLeverage = new fbe.FieldModelUInt32(buffer, this.DefaultCredit.fbeOffset + this.DefaultCredit.fbeSize)
    this.MarginCallLevel = new fbe.FieldModelDouble(buffer, this.DefaultLeverage.fbeOffset + this.DefaultLeverage.fbeSize)
    this.MarginCallAccessType = new FieldModelTradingMode(buffer, this.MarginCallLevel.fbeOffset + this.MarginCallLevel.fbeSize)
    this.StopOutLevel = new fbe.FieldModelDouble(buffer, this.MarginCallAccessType.fbeOffset + this.MarginCallAccessType.fbeSize)
    this.StopOutType = new FieldModelStopOutType(buffer, this.StopOutLevel.fbeOffset + this.StopOutLevel.fbeSize)
    this.StopOutAccessType = new FieldModelTradingMode(buffer, this.StopOutType.fbeOffset + this.StopOutType.fbeSize)
    this.DefaultMaxPendingOrders = new fbe.FieldModelUInt64(buffer, this.StopOutAccessType.fbeOffset + this.StopOutAccessType.fbeSize)
    this.SymbolGroupSettings = new fbe.FieldModelMap(new fbe.FieldModelUUID(buffer, this.DefaultMaxPendingOrders.fbeOffset + this.DefaultMaxPendingOrders.fbeSize), new FieldModelSymbolGroupSettings(buffer, this.DefaultMaxPendingOrders.fbeOffset + this.DefaultMaxPendingOrders.fbeSize), buffer, this.DefaultMaxPendingOrders.fbeOffset + this.DefaultMaxPendingOrders.fbeSize)
    this.IsSwapEnabled = new fbe.FieldModelBool(buffer, this.SymbolGroupSettings.fbeOffset + this.SymbolGroupSettings.fbeSize)
    this.MinPasswordLength = new fbe.FieldModelUInt8(buffer, this.IsSwapEnabled.fbeOffset + this.IsSwapEnabled.fbeSize)
    this.Deprecated1 = new fbe.FieldModelBool(buffer, this.MinPasswordLength.fbeOffset + this.MinPasswordLength.fbeSize)
    this.Deprecated2 = new fbe.FieldModelBool(buffer, this.Deprecated1.fbeOffset + this.Deprecated1.fbeSize)
    this.IsSSLAccessEnabled = new fbe.FieldModelBool(buffer, this.Deprecated2.fbeOffset + this.Deprecated2.fbeSize)
    this.IsHTTPSAccessEnabled = new fbe.FieldModelBool(buffer, this.IsSSLAccessEnabled.fbeOffset + this.IsSSLAccessEnabled.fbeSize)
    this.IsWSSAccessEnabled = new fbe.FieldModelBool(buffer, this.IsHTTPSAccessEnabled.fbeOffset + this.IsHTTPSAccessEnabled.fbeSize)
    this.IsFIXAccessEnabled = new fbe.FieldModelBool(buffer, this.IsWSSAccessEnabled.fbeOffset + this.IsWSSAccessEnabled.fbeSize)
    this.MaxLeverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.IsFIXAccessEnabled.fbeOffset + this.IsFIXAccessEnabled.fbeSize), buffer, this.IsFIXAccessEnabled.fbeOffset + this.IsFIXAccessEnabled.fbeSize)
    this.LeverageProfileId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.MaxLeverage.fbeOffset + this.MaxLeverage.fbeSize), buffer, this.MaxLeverage.fbeOffset + this.MaxLeverage.fbeSize)
    this.DefaultMaxOrders = new fbe.FieldModelUInt64(buffer, this.LeverageProfileId.fbeOffset + this.LeverageProfileId.fbeSize)
    this.MaxDepositOperationAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.DefaultMaxOrders.fbeOffset + this.DefaultMaxOrders.fbeSize), buffer, this.DefaultMaxOrders.fbeOffset + this.DefaultMaxOrders.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelAccountGroupCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountGroupCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountGroupCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SortOrder.fbeSize + this.PlatformId.fbeSize + this.PlatformName.fbeSize + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.IsDemo.fbeSize + this.IsEnabled.fbeSize + this.IsOTPEnabled.fbeSize + this.IsTradeEnabled.fbeSize + this.DefaultAccountingType.fbeSize + this.DefaultBalanceCurrencyId.fbeSize + this.DefaultBalanceCurrencyName.fbeSize + this.DefaultBalance.fbeSize + this.DefaultCredit.fbeSize + this.DefaultLeverage.fbeSize + this.MarginCallLevel.fbeSize + this.MarginCallAccessType.fbeSize + this.StopOutLevel.fbeSize + this.StopOutType.fbeSize + this.StopOutAccessType.fbeSize + this.DefaultMaxPendingOrders.fbeSize + this.SymbolGroupSettings.fbeSize + this.IsSwapEnabled.fbeSize + this.MinPasswordLength.fbeSize + this.Deprecated1.fbeSize + this.Deprecated2.fbeSize + this.IsSSLAccessEnabled.fbeSize + this.IsHTTPSAccessEnabled.fbeSize + this.IsWSSAccessEnabled.fbeSize + this.IsFIXAccessEnabled.fbeSize + this.MaxLeverage.fbeSize + this.LeverageProfileId.fbeSize + this.DefaultMaxOrders.fbeSize + this.MaxDepositOperationAmount.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountGroupCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SortOrder.fbeExtra + this.PlatformId.fbeExtra + this.PlatformName.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.IsDemo.fbeExtra + this.IsEnabled.fbeExtra + this.IsOTPEnabled.fbeExtra + this.IsTradeEnabled.fbeExtra + this.DefaultAccountingType.fbeExtra + this.DefaultBalanceCurrencyId.fbeExtra + this.DefaultBalanceCurrencyName.fbeExtra + this.DefaultBalance.fbeExtra + this.DefaultCredit.fbeExtra + this.DefaultLeverage.fbeExtra + this.MarginCallLevel.fbeExtra + this.MarginCallAccessType.fbeExtra + this.StopOutLevel.fbeExtra + this.StopOutType.fbeExtra + this.StopOutAccessType.fbeExtra + this.DefaultMaxPendingOrders.fbeExtra + this.SymbolGroupSettings.fbeExtra + this.IsSwapEnabled.fbeExtra + this.MinPasswordLength.fbeExtra + this.Deprecated1.fbeExtra + this.Deprecated2.fbeExtra + this.IsSSLAccessEnabled.fbeExtra + this.IsHTTPSAccessEnabled.fbeExtra + this.IsWSSAccessEnabled.fbeExtra + this.IsFIXAccessEnabled.fbeExtra + this.MaxLeverage.fbeExtra + this.LeverageProfileId.fbeExtra + this.DefaultMaxOrders.fbeExtra + this.MaxDepositOperationAmount.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountGroupCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountGroupCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountGroupCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6181
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountGroupCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountGroupCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.PlatformName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsDemo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsDemo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDemo.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.DefaultAccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultAccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultAccountingType.fbeSize

    if ((fbeCurrentSize + this.DefaultBalanceCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultBalanceCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultBalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.DefaultBalanceCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultBalanceCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultBalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.DefaultBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultBalance.fbeSize

    if ((fbeCurrentSize + this.DefaultCredit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultCredit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultCredit.fbeSize

    if ((fbeCurrentSize + this.DefaultLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultLeverage.fbeSize

    if ((fbeCurrentSize + this.MarginCallLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCallLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCallLevel.fbeSize

    if ((fbeCurrentSize + this.MarginCallAccessType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCallAccessType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCallAccessType.fbeSize

    if ((fbeCurrentSize + this.StopOutLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopOutLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutLevel.fbeSize

    if ((fbeCurrentSize + this.StopOutType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopOutType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutType.fbeSize

    if ((fbeCurrentSize + this.StopOutAccessType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopOutAccessType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutAccessType.fbeSize

    if ((fbeCurrentSize + this.DefaultMaxPendingOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultMaxPendingOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultMaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupSettings.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsSwapEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.MinPasswordLength.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MinPasswordLength.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinPasswordLength.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated1.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated2.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated2.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated2.fbeSize

    if ((fbeCurrentSize + this.IsSSLAccessEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsSSLAccessEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSSLAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.IsHTTPSAccessEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsHTTPSAccessEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsHTTPSAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.IsWSSAccessEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsWSSAccessEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsWSSAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.IsFIXAccessEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsFIXAccessEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFIXAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.LeverageProfileId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LeverageProfileId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageProfileId.fbeSize

    if ((fbeCurrentSize + this.DefaultMaxOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultMaxOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultMaxOrders.fbeSize

    if ((fbeCurrentSize + this.MaxDepositOperationAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxDepositOperationAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxDepositOperationAmount.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountGroupCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountGroupCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountGroupCreateRequest}
   * @param {!AccountGroupCreateRequest} fbeValue Default value, defaults is new AccountGroupCreateRequest()
   * @returns {!AccountGroupCreateRequest} AccountGroupCreateRequest value
   */
  get (fbeValue = new AccountGroupCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountGroupCreateRequest}
   * @param {!AccountGroupCreateRequest} fbeValue AccountGroupCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformId = this.PlatformId.get()
    } else {
      fbeValue.PlatformId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.PlatformName.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformName = this.PlatformName.get()
    } else {
      fbeValue.PlatformName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsDemo.fbeSize) <= fbeStructSize) {
      fbeValue.IsDemo = this.IsDemo.get(false)
    } else {
      fbeValue.IsDemo = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDemo.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get(true)
    } else {
      fbeValue.IsEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsOTPEnabled = this.IsOTPEnabled.get(false)
    } else {
      fbeValue.IsOTPEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeEnabled = this.IsTradeEnabled.get(true)
    } else {
      fbeValue.IsTradeEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.DefaultAccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultAccountingType = this.DefaultAccountingType.get(new AccountingType(AccountingType.GROSS))
    } else {
      fbeValue.DefaultAccountingType = new AccountingType(AccountingType.GROSS)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultAccountingType.fbeSize

    if ((fbeCurrentSize + this.DefaultBalanceCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultBalanceCurrencyId = this.DefaultBalanceCurrencyId.get()
    } else {
      fbeValue.DefaultBalanceCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultBalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.DefaultBalanceCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultBalanceCurrencyName = this.DefaultBalanceCurrencyName.get()
    } else {
      fbeValue.DefaultBalanceCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultBalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.DefaultBalance.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultBalance = this.DefaultBalance.get(0.0)
    } else {
      fbeValue.DefaultBalance = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultBalance.fbeSize

    if ((fbeCurrentSize + this.DefaultCredit.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultCredit = this.DefaultCredit.get(0.0)
    } else {
      fbeValue.DefaultCredit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultCredit.fbeSize

    if ((fbeCurrentSize + this.DefaultLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultLeverage = this.DefaultLeverage.get(100)
    } else {
      fbeValue.DefaultLeverage = 100
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultLeverage.fbeSize

    if ((fbeCurrentSize + this.MarginCallLevel.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCallLevel = this.MarginCallLevel.get(0.6)
    } else {
      fbeValue.MarginCallLevel = 0.6
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCallLevel.fbeSize

    if ((fbeCurrentSize + this.MarginCallAccessType.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCallAccessType = this.MarginCallAccessType.get(new TradingMode(TradingMode.NORMAL))
    } else {
      fbeValue.MarginCallAccessType = new TradingMode(TradingMode.NORMAL)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCallAccessType.fbeSize

    if ((fbeCurrentSize + this.StopOutLevel.fbeSize) <= fbeStructSize) {
      fbeValue.StopOutLevel = this.StopOutLevel.get(0.3)
    } else {
      fbeValue.StopOutLevel = 0.3
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutLevel.fbeSize

    if ((fbeCurrentSize + this.StopOutType.fbeSize) <= fbeStructSize) {
      fbeValue.StopOutType = this.StopOutType.get(new StopOutType(StopOutType.CLOSE_MOST_LOSS))
    } else {
      fbeValue.StopOutType = new StopOutType(StopOutType.CLOSE_MOST_LOSS)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutType.fbeSize

    if ((fbeCurrentSize + this.StopOutAccessType.fbeSize) <= fbeStructSize) {
      fbeValue.StopOutAccessType = this.StopOutAccessType.get(new TradingMode(TradingMode.NORMAL))
    } else {
      fbeValue.StopOutAccessType = new TradingMode(TradingMode.NORMAL)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutAccessType.fbeSize

    if ((fbeCurrentSize + this.DefaultMaxPendingOrders.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultMaxPendingOrders = this.DefaultMaxPendingOrders.get(new UInt64(1000, 0))
    } else {
      fbeValue.DefaultMaxPendingOrders = new UInt64(1000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultMaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupSettings.fbeSize) <= fbeStructSize) {
      this.SymbolGroupSettings.get(fbeValue.SymbolGroupSettings)
    } else {
      fbeValue.SymbolGroupSettings.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupSettings.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsSwapEnabled = this.IsSwapEnabled.get(false)
    } else {
      fbeValue.IsSwapEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.MinPasswordLength.fbeSize) <= fbeStructSize) {
      fbeValue.MinPasswordLength = this.MinPasswordLength.get(8)
    } else {
      fbeValue.MinPasswordLength = 8
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinPasswordLength.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated1 = this.Deprecated1.get()
    } else {
      fbeValue.Deprecated1 = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated2.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated2 = this.Deprecated2.get()
    } else {
      fbeValue.Deprecated2 = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated2.fbeSize

    if ((fbeCurrentSize + this.IsSSLAccessEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsSSLAccessEnabled = this.IsSSLAccessEnabled.get(true)
    } else {
      fbeValue.IsSSLAccessEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSSLAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.IsHTTPSAccessEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsHTTPSAccessEnabled = this.IsHTTPSAccessEnabled.get(true)
    } else {
      fbeValue.IsHTTPSAccessEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsHTTPSAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.IsWSSAccessEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsWSSAccessEnabled = this.IsWSSAccessEnabled.get(true)
    } else {
      fbeValue.IsWSSAccessEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsWSSAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.IsFIXAccessEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsFIXAccessEnabled = this.IsFIXAccessEnabled.get(true)
    } else {
      fbeValue.IsFIXAccessEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFIXAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.MaxLeverage = this.MaxLeverage.get()
    } else {
      fbeValue.MaxLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.LeverageProfileId.fbeSize) <= fbeStructSize) {
      fbeValue.LeverageProfileId = this.LeverageProfileId.get()
    } else {
      fbeValue.LeverageProfileId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageProfileId.fbeSize

    if ((fbeCurrentSize + this.DefaultMaxOrders.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultMaxOrders = this.DefaultMaxOrders.get(new UInt64(1200, 0))
    } else {
      fbeValue.DefaultMaxOrders = new UInt64(1200, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultMaxOrders.fbeSize

    if ((fbeCurrentSize + this.MaxDepositOperationAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MaxDepositOperationAmount = this.MaxDepositOperationAmount.get()
    } else {
      fbeValue.MaxDepositOperationAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxDepositOperationAmount.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountGroupCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountGroupCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountGroupCreateRequest}
   * @param {!AccountGroupCreateRequest} fbeValue AccountGroupCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountGroupCreateRequest}
   * @param {AccountGroupCreateRequest} fbeValue AccountGroupCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SortOrder.set(fbeValue.SortOrder)
    this.PlatformId.set(fbeValue.PlatformId)
    this.PlatformName.set(fbeValue.PlatformName)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.IsDemo.set(fbeValue.IsDemo)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.IsOTPEnabled.set(fbeValue.IsOTPEnabled)
    this.IsTradeEnabled.set(fbeValue.IsTradeEnabled)
    this.DefaultAccountingType.set(fbeValue.DefaultAccountingType)
    this.DefaultBalanceCurrencyId.set(fbeValue.DefaultBalanceCurrencyId)
    this.DefaultBalanceCurrencyName.set(fbeValue.DefaultBalanceCurrencyName)
    this.DefaultBalance.set(fbeValue.DefaultBalance)
    this.DefaultCredit.set(fbeValue.DefaultCredit)
    this.DefaultLeverage.set(fbeValue.DefaultLeverage)
    this.MarginCallLevel.set(fbeValue.MarginCallLevel)
    this.MarginCallAccessType.set(fbeValue.MarginCallAccessType)
    this.StopOutLevel.set(fbeValue.StopOutLevel)
    this.StopOutType.set(fbeValue.StopOutType)
    this.StopOutAccessType.set(fbeValue.StopOutAccessType)
    this.DefaultMaxPendingOrders.set(fbeValue.DefaultMaxPendingOrders)
    this.SymbolGroupSettings.set(fbeValue.SymbolGroupSettings)
    this.IsSwapEnabled.set(fbeValue.IsSwapEnabled)
    this.MinPasswordLength.set(fbeValue.MinPasswordLength)
    this.Deprecated1.set(fbeValue.Deprecated1)
    this.Deprecated2.set(fbeValue.Deprecated2)
    this.IsSSLAccessEnabled.set(fbeValue.IsSSLAccessEnabled)
    this.IsHTTPSAccessEnabled.set(fbeValue.IsHTTPSAccessEnabled)
    this.IsWSSAccessEnabled.set(fbeValue.IsWSSAccessEnabled)
    this.IsFIXAccessEnabled.set(fbeValue.IsFIXAccessEnabled)
    this.MaxLeverage.set(fbeValue.MaxLeverage)
    this.LeverageProfileId.set(fbeValue.LeverageProfileId)
    this.DefaultMaxOrders.set(fbeValue.DefaultMaxOrders)
    this.MaxDepositOperationAmount.set(fbeValue.MaxDepositOperationAmount)
  }
}

export { FieldModelAccountGroupCreateRequest };

/**
 * Fast Binary Encoding AccountGroupCreateRequest model
 */
class AccountGroupCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountGroupCreateRequest(this.buffer, 4)
  }

  /**
   * Get the AccountGroupCreateRequest model
   * @this {!AccountGroupCreateRequestModel}
   * @returns {!FieldModelAccountGroupCreateRequest} model AccountGroupCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountGroupCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountGroupCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountGroupCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountGroupCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountGroupCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountGroupCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountGroupCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountGroupCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountGroupCreateRequestModel}
   * @param {!AccountGroupCreateRequest} value AccountGroupCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountGroupCreateRequestModel}
   * @param {!AccountGroupCreateRequest} value AccountGroupCreateRequest value, defaults is new AccountGroupCreateRequest()
   * @return {!object} Deserialized AccountGroupCreateRequest value and its size
   */
  deserialize (value = new AccountGroupCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountGroupCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountGroupCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountGroupCreateRequestModel}
   * @param {!number} prev Previous AccountGroupCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountGroupCreateRequestModel };
AccountGroupCreateRequest.__has_response = true;
AccountGroupCreateRequest.__response_class = AccountGroupCreateResponse;
