import { AuthenticationType } from '@api/fbe/core/AuthenticationType';
import { ManagerRight } from '@api/fbe/core/ManagerRight';
import { RightsMode } from '@api/fbe/core/RightsMode';
import { UInt64 } from '@api/fbe/int64';
import { UUID } from '@api/fbe/uuid';
import { getClassStaticFields } from '../utils';

export type Manager = {
    Login: UInt64;
    SortOrder: number;
    ManagerGroupId: UUID;
    Name: string;
    Comment: string;
    Description: string;
    Email: string;
    IsEnabled?: boolean | null;
    IsOTPEnabled?: boolean | null;
    IsManagerRightsSet: boolean;
    ManagerRights?: ManagerRights | null;
    AuthenticationType: AuthenticationType;
    PasswordDigest: string;
    OTPDigest?: string | null;
};

export type ParsedManager = {
    Login: string;
    SortOrder: number;
    ManagerGroupId: string | null;
    Name: string;
    Comment: string;
    Description: string;
    Email: string;
    IsEnabled: boolean | null | undefined;
    IsOTPEnabled: boolean | null | undefined;
    IsManagerRightsSet: boolean;
    ManagerRights: ParsedManagerRights | null | undefined;
    AuthenticationType: number;
    PasswordDigest: string | null | undefined;
    OTPDigest: string | null | undefined;
    Password: string;
};

export type ManagerRights = {
    AccountGroups: Array<string>;
    SymbolGroups: Array<string>;
    Accounts: RightsMode;
    Symbols: RightsMode;
    System: RightsMode;
    Modules: RightsMode;
    Platforms: RightsMode;
    Managers: RightsMode;
    Currencies: RightsMode;
    Calendars: RightsMode;
    Profiles: RightsMode;
    DataFeeds: RightsMode;
    Gateways: RightsMode;
    TradingRoutes: RightsMode;
    QuotesHistory: RightsMode;
    Reports: RightsMode;
    Monitoring: RightsMode;
    SecuritySettings: RightsMode;
    FirewallRules: RightsMode;
    Documents: RightsMode;
    Rights: Set<ManagerRight>;
};

export type ParsedManagerRights = {
    AccountGroups: string[];
    SymbolGroups: string[];
    Accounts: number;
    Symbols: number;
    System: number;
    Modules: number;
    Platforms: number;
    Managers: number;
    Currencies: number;
    Calendars: number;
    Profiles: number;
    DataFeeds: number;
    Gateways: number;
    TradingRoutes: number;
    QuotesHistory: number;
    Reports: number;
    Monitoring: number;
    SecuritySettings: number;
    FirewallRules: number;
    Documents: number;
    Rights: number[];
};

export const ParsedRights = getClassStaticFields(ManagerRight);

/* export enum ParsedRights {
    HOSTS_VIEW,
    HOSTS_EDIT,
    HOSTS_CREATE,
    HOSTS_DELETE,
    MODULES_VIEW,
    MODULES_EDIT,
    MODULES_CREATE,
    MODULES_DELETE,
    MODULES_SYSTEM_INFO_VIEW,
    TIMEZONES_VIEW,
    TIMEZONES_EDIT,
    TIMEZONES_CREATE,
    TIMEZONES_DELETE,
    CURRENCY_GROUPS_VIEW,
    CURRENCY_GROUPS_EDIT,
    CURRENCY_GROUPS_CREATE,
    CURRENCY_GROUPS_DELETE,
    CURRENCIES_VIEW,
    CURRENCIES_EDIT,
    CURRENCIES_CREATE,
    CURRENCIES_DELETE,
    SYMBOL_GROUPS_VIEW,
    SYMBOL_GROUPS_EDIT,
    SYMBOL_GROUPS_CREATE,
    SYMBOL_GROUPS_DELETE,
    SYMBOLS_VIEW,
    SYMBOLS_EDIT,
    SYMBOLS_CREATE,
    SYMBOLS_DELETE,
    SYMBOLS_SEND_TICKS,
    SWAPS_IMPORT,
    SWAPS_EXPORT,
    COMMISSION_PROFILES_VIEW,
    COMMISSION_PROFILES_EDIT,
    COMMISSION_PROFILES_CREATE,
    COMMISSION_PROFILES_DELETE,
    MARKUP_PROFILES_VIEW,
    MARKUP_PROFILES_EDIT,
    MARKUP_PROFILES_CREATE,
    MARKUP_PROFILES_DELETE,
    MANAGER_GROUPS_VIEW,
    MANAGER_GROUPS_EDIT,
    MANAGER_GROUPS_CREATE,
    MANAGER_GROUPS_DELETE,
    MANAGERS_VIEW,
    MANAGERS_EDIT,
    MANAGERS_CREATE,
    MANAGERS_DELETE,
    PLATFORMS_VIEW,
    PLATFORMS_EDIT,
    PLATFORMS_CREATE,
    ACCOUNT_GROUPS_VIEW,
    ACCOUNT_GROUPS_EDIT,
    ACCOUNT_GROUPS_CREATE,
    ACCOUNT_GROUPS_DELETE,
    ACCOUNTS_VIEW,
    ACCOUNTS_EDIT,
    ACCOUNTS_CREATE,
    ACCOUNTS_DELETE,
    ACCOUNTS_DEPOSIT,
    ACCOUNTS_CREDIT,
    ACCOUNTS_TRANSFER_MONEY,
    ACCOUNTS_PERFORM_ROLLOVER,
    ACCOUNTS_PERSONAL_DETAILS_VIEW,
    ORDERS_VIEW,
    ORDERS_EDIT,
    ORDERS_CREATE,
    ORDERS_DELETE,
    POSITIONS_VIEW,
    POSITIONS_EDIT,
    POSITIONS_CREATE,
    POSITIONS_DELETE,
    TRADE_HISTORY_VIEW,
    TRADE_HISTORY_EDIT,
    TRADE_HISTORY_DELETE,
    DAILY_SNAPSHOTS_VIEW,
    DAILY_SNAPSHOTS_EDIT,
    DAILY_SNAPSHOTS_DELETE,
    DAILY_SNAPSHOTS_DUMP,
    CALENDAR_RULES_VIEW,
    CALENDAR_RULES_EDIT,
    CALENDAR_RULES_CREATE,
    CALENDAR_RULES_DELETE,
    OFF_TIME_RULES_VIEW,
    OFF_TIME_RULES_EDIT,
    OFF_TIME_RULES_CREATE,
    OFF_TIME_RULES_DELETE,
    TRADING_ROUTE_RULES_VIEW,
    TRADING_ROUTE_RULES_EDIT,
    TRADING_ROUTE_RULES_CREATE,
    TRADING_ROUTE_RULES_DELETE,
    SYMBOL_MAPS_VIEW,
    SYMBOL_MAPS_EDIT,
    SYMBOL_MAPS_CREATE,
    SYMBOL_MAPS_DELETE,
    GATEWAYS_VIEW,
    GATEWAYS_EDIT,
    GATEWAYS_CREATE,
    GATEWAYS_DELETE,
    DATA_FEEDS_VIEW,
    DATA_FEEDS_EDIT,
    DATA_FEEDS_CREATE,
    DATA_FEEDS_DELETE,
    MAIL_SERVERS_VIEW,
    MAIL_SERVERS_EDIT,
    MAIL_SERVERS_CREATE,
    MAIL_SERVERS_DELETE,
    TICK_HISTORY_VIEW,
    CHART_BARS_VIEW,
    CHART_BARS_EDIT,
    SESSIONS_VIEW,
    SESSIONS_DROP,
    LEVERAGE_PROFILE_VIEW,
    LEVERAGE_PROFILE_EDIT,
    LEVERAGE_PROFILE_CREATE,
    LEVERAGE_PROFILE_DELETE,
    MARGIN_RATE_PROFILE_VIEW,
    MARGIN_RATE_PROFILE_EDIT,
    MARGIN_RATE_PROFILE_CREATE,
    MARGIN_RATE_PROFILE_DELETE,
    NEWS_PROFILE_VIEW,
    NEWS_PROFILE_EDIT,
    NEWS_PROFILE_CREATE,
    NEWS_PROFILE_DELETE,
} */

export type ParsedManagerGroup = {
    id: string;
    SortOrder: number;
    Name: string;
    Comment: string;
    Description: string;
    IsEnabled: boolean;
    IsOTPEnabled: boolean;
    ManagerRights: ParsedManagerRights;
    PlatformId: string;
};

/*

type RightPattern = Record<string, Right>;

export type Manager = {
    id: number | string | UInt64;
    idParsed: string;
    Login: number | string | UInt64;
    SortOrder: number;
    ManagerGroupId: UUID;
    ManagerGroupIdParsed: string;
    Name: string;
    Comment: string;
    Description: string;
    Email: string;
    IsEnabled?: boolean | null;
    IsOTPEnabled?: boolean | null;
    Rights: Right;
    AccountRights: Map<any, any>;
    SymbolRights: Map<any, any>;
    AuthenticationType: any;
    PasswordDigest: string;
    OTPDigest?: string | null;
};

export type ConvertedManager = {
    id: number | string | UInt64;
    Login: number | string | UInt64;
    SortOrder: number | string;
    ManagerGroupId: string | UUID;
    Name: string;
    Comment: string;
    Description: string;
    Email: string;
    IsEnabled: boolean;
    IsOTPEnabled: boolean;
    Rights: Right;
    AccountRights: string[];
    SymbolRights: string[];
    AuthenticationType: any;
    PasswordDigest: string;
    OTPDigest: string;
};

export type NewManager = {
    Login?: number | null | string;
    SortOrder: number | string;
    ManagerGroupId: string;
    ManagerGroupName?: string | null;
    Name: string;
    Comment: string;
    Description: string;
    Email: string;
    IsEnabled: boolean;
    IsOTPEnabled: boolean;
    Rights: Record<string, boolean>;
    AccountRights: NewRightPattern;
    SymbolRights: NewRightPattern;
    Password: string;
};

type NewRightPattern = Record<string, { name: string; list: Record<string, boolean> }>;

export type NewRawManager = {
    Login?: number | null | string;
    SortOrder: number | string;
    ManagerGroupId: string;
    ManagerGroupName?: string | null;
    Name: string;
    Comment: string;
    Description: string;
    Email: string;
    IsEnabled: boolean;
    IsOTPEnabled: boolean;
    Password: string;
    Rights: Record<string, boolean>;
};

export type UpdatedManager = {
    Login: number | string;
    NewSortOrder: number | string;
    NewManagerGroupId: string;
    NewManagerGroupName: string;
    NewLogin: number | string;
    NewName: string;
    NewComment: string;
    NewDescription: string;
    NewEmail: string;
    NewIsEnabled: boolean;
    ResetIsEnabled: boolean;
    NewIsOTPEnabled: boolean;
    ResetIsOTPEnabled: boolean;
    NewRights: Right;
    AccountRightsToModify: RightPattern;
    AccountRightsToDelete: string[];
    SymbolRightsToModify: RightPattern;
    SymbolRightsToDelete: string[];
    NewPassword: string;
}; */

export type ManagersGroup = {
    id: string;
    SortOrder: number | string;
    Name: string;
    Comment: string;
    Description: string;
    IsEnabled: true;
    IsOTPEnabled: true;
    Rights: number | string;
    AccountRights: Map<any, any>;
    SymbolRights: Map<any, any>;
    LoginRangeOffset: number | string;
    LoginRangeSize: number | string;
};

export type Right = {
    Allow: number | string | any;
    Deny: number | string | any;
};

export type ConvertedManagersGroup = {
    id: string;
    SortOrder: number | string;
    Name: string;
    Comment: string;
    Description: string;
    IsEnabled: true;
    IsOTPEnabled: true;
    Rights: Right;
    AccountRights: string[];
    SymbolRights: string[];
    LoginRangeOffset: number | string;
    LoginRangeSize: number | string;
};

export type NewManagersGroup = {
    SortOrder: number | string;
    Name: string;
    Comment: string;
    Description: string;
    IsEnabled: boolean;
    IsOTPEnabled: boolean;
    Rights: number | string;
    AccountRights: Map<any, any>;
    SymbolRights: Map<any, any>;
    LoginRangeOffset: number | string;
    LoginRangeSize: number | string;
};

export type NewRawManagersGroup = {
    SortOrder: number | string;
    Name: string;
    Comment: string;
    Description: string;
    IsEnabled: boolean;
    IsOTPEnabled: boolean;
    Rights: Record<string, boolean>;
    AccountRights: Record<string, boolean>;
    SymbolRights: Record<string, boolean>;
    LoginRangeOffset: number | string;
    LoginRangeSize: number | string;
};

export type UpdatedManagersGroup = {
    ManagerGroupId: string;
    ManagerGroupName: string;
    NewSortOrder: number | string;
    NewName: string;
    NewComment: string;
    NewDescription: string;
    NewIsEnabled: boolean;
    NewIsOTPEnabled: boolean;
    NewRights: number | string;
    AccountRightsToModify: Map<any, any>;
    AccountRightsToDelete: string[];
    SymbolRightsToModify: Map<any, any>;
    SymbolRightsToDelete: string[];
    NewLoginRangeOffset: number | string;
    NewLoginRangeSize: number | string;
};
