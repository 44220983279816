import { accountsGroupsStore } from './accountGroups/accountsGroupsStore';
import { offTimeCalendarStore } from './calendars/offTimeCalendarStore';
import { regularCalendarStore } from './calendars/regularCalendarStore';
import { chartsBarsStore } from './chartBars/chartBarsStore';
import { chartBarsImportStore } from './chartBars/chartBarsImportStore';
import { currenciesStore } from './currencies/currenciesStore';
import { currenciesTypesStore } from './currencies/currenciesTypesStore';
import { dataFeedsStore } from './dataFeeds/dataFeedsStore';
import { gatewaysStore } from './gateways/gatewaysStore';
import { journalStore } from './journal/JournalStore';
import { businessJournalStore } from './businessJournal/BusinessJournalStore';
import { mailStore } from './mail/mailStore';
import { managerGroupsStore } from './managerGroups/managersGroupsStore';
import { managersStore } from './managers/managerStore';
import { modulesStore } from './modules/modulesStore';
import { platformsStore } from './platforms/platformsStore';
import { commissionStore } from './profiles/commissionApi';
import { leverageProfileStore } from './profiles/leverageProfileStore';
import { marginRateProfileStore } from './profiles/marginRateProfileStore';
import { markupProfileStore } from './profiles/markupProfileStore';
import { newsProfileStore } from './profiles/newsProfileStore';
import { symbolMapsStore } from './profiles/symbolMapsStore';
import { balanceTransactionsStore } from './reports/balanceTransactionsStore';
import { statementReportsStore } from './reports/statementReportsStore';
import { dealsStore } from './riskManagement/dealsStore';
import { orderSummaryStore } from './riskManagement/orderSummaryStore';
import { sessionsStore } from './sessions/sessionsStore';
import { symbolsGroupsStore } from './symbols/symbolsGroupsStore';
import { symbolsStore } from './symbols/symbolsStore';
import { hostsStore } from './system/hostsStore';
import { timezonesStore } from './system/timezonesStore';
import { ticksStore } from './ticks/ticks';
import { tradingRouteStore } from './tradingRoutes/tradingRouteStore';
import { orderStore } from './accountsPage/orderStore';
import { securityProfilesStore } from './security/securityProfilesStore';
import { firewallStore } from './security/firewallStore';
import { emailTemplatesStore } from './emailTemplates/emailTemplatesStore';
import { pushNotificationTemplatesStore } from './pushNotifications/pushNotificationTemplatesStore';
import { languagesStore } from './pushNotifications/languagesStore';
import { tradeVolumeReportStore } from './reports/tradeVolumeReportStore';
import { dividendsStore } from './stocks/dividendsStore';
import { splitsStore } from './stocks/splitsStore';
import { legalDocumentsStore } from './legalDocuments/legalDocumentsStore';

export const resetStore = (): void => {
    accountsGroupsStore.reset();
    offTimeCalendarStore.reset();
    regularCalendarStore.reset();
    chartsBarsStore.reset();
    currenciesStore.reset();
    currenciesTypesStore.reset();
    dataFeedsStore.reset();
    gatewaysStore.reset();
    journalStore.reset();
    businessJournalStore.reset();
    mailStore.reset();
    managerGroupsStore.reset();
    managersStore.reset();
    modulesStore.reset();
    platformsStore.reset();
    commissionStore.reset();
    leverageProfileStore.reset();
    marginRateProfileStore.reset();
    markupProfileStore.reset();
    newsProfileStore.reset();
    symbolMapsStore.reset();
    balanceTransactionsStore.reset();
    statementReportsStore.reset();
    dealsStore.reset();
    orderSummaryStore.reset();
    sessionsStore.reset();
    symbolsGroupsStore.reset();
    symbolsStore.reset();
    hostsStore.reset();
    timezonesStore.reset();
    ticksStore.reset();
    tradingRouteStore.reset();
    chartBarsImportStore.reset();
    orderStore.reset();
    securityProfilesStore.reset();
    firewallStore.reset();
    emailTemplatesStore.reset();
    pushNotificationTemplatesStore.reset();
    languagesStore.reset();
    tradeVolumeReportStore.reset();
    dividendsStore.reset();
    splitsStore.reset();
    legalDocumentsStore.reset();
};
