
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {TradingRouteRuleCreateResponse} from './TradingRouteRuleCreateResponse';
import {TradingRouteRequestType} from '../core/TradingRouteRequestType';
import {FieldModelTradingRouteRequestType} from '../core/TradingRouteRequestType';
import {TradingRouteCondition} from '../core/TradingRouteCondition';
import {FieldModelTradingRouteCondition} from '../core/TradingRouteCondition';
import {TradingRouteActionDelay} from '../core/TradingRouteActionDelay';
import {FieldModelTradingRouteActionDelay} from '../core/TradingRouteActionDelay';
import {TradingRouteActionType} from '../core/TradingRouteActionType';
import {FieldModelTradingRouteActionType} from '../core/TradingRouteActionType';
import {TradingRouteActionForwardToGateway} from '../core/TradingRouteActionForwardToGateway';
import {FieldModelTradingRouteActionForwardToGateway} from '../core/TradingRouteActionForwardToGateway';

/**
 * TradingRouteRuleCreateRequest struct
 */
class TradingRouteRuleCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {!boolean=} isEnabled
   * @param {!string=} name
   * @param {!string=} description
   * @param {!Set=} requestType
   * @param {!Array=} condition
   * @param {TradingRouteActionDelay=} actionDelay
   * @param {!TradingRouteActionType=} actionType
   * @param {TradingRouteActionForwardToGateway=} actionForwardToGateway
   * @param {number=} sortOrder
   * @param {string=} gatewayAccountId
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), isEnabled = false, name = '', description = '', requestType = new Set(), condition = [], actionDelay = null, actionType = new TradingRouteActionType(), actionForwardToGateway = null, sortOrder = null, gatewayAccountId = null) {
    super()
    super.copy(parent)
    this.IsEnabled = isEnabled
    this.Name = name
    this.Description = description
    this.RequestType = requestType
    this.Condition = condition
    this.ActionDelay = actionDelay
    this.ActionType = actionType
    this.ActionForwardToGateway = actionForwardToGateway
    this.SortOrder = sortOrder
    this.GatewayAccountId = gatewayAccountId
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradingRouteRuleCreateRequest}
   * @param {!TradingRouteRuleCreateRequest} other Other struct
   * @returns {!TradingRouteRuleCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.RequestType != null) {
      this.RequestType = new Set()
      for (let item of other.RequestType) {
        if (item != null) {
          let tempItem
          tempItem = TradingRouteRequestType.fromObject(item)
          this.RequestType.add(tempItem)
        } else {
          this.RequestType.add(null)
        }
      }
    } else {
      this.RequestType = null
    }
    if (other.Condition != null) {
      this.Condition = []
      for (let item of other.Condition) {
        if (item != null) {
          let tempItem
          tempItem = TradingRouteCondition.fromObject(item)
          this.Condition.push(tempItem)
        } else {
          this.Condition.push(null)
        }
      }
    } else {
      this.Condition = null
    }
    if (other.ActionDelay != null) {
      this.ActionDelay = TradingRouteActionDelay.fromObject(other.ActionDelay)
    } else {
      this.ActionDelay = null
    }
    if (other.ActionType != null) {
      this.ActionType = TradingRouteActionType.fromObject(other.ActionType)
    } else {
      this.ActionType = null
    }
    if (other.ActionForwardToGateway != null) {
      this.ActionForwardToGateway = TradingRouteActionForwardToGateway.fromObject(other.ActionForwardToGateway)
    } else {
      this.ActionForwardToGateway = null
    }
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.GatewayAccountId != null) {
      this.GatewayAccountId = other.GatewayAccountId
    } else {
      this.GatewayAccountId = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradingRouteRuleCreateRequest}
   * @returns {!TradingRouteRuleCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradingRouteRuleCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradingRouteRuleCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradingRouteRuleCreateRequest}
   * @param {!TradingRouteRuleCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingRouteRuleCreateRequest)) {
      throw new TypeError('Instance of TradingRouteRuleCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradingRouteRuleCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      Name: ((this.Name != null) ? this.Name : null),
      Description: ((this.Description != null) ? this.Description : null),
      RequestType: ((this.RequestType != null) ? Array.from(this.RequestType, item => ((item != null) ? item : null)) : null),
      Condition: ((this.Condition != null) ? Array.from(this.Condition, item => ((item != null) ? item : null)) : null),
      ActionDelay: ((this.ActionDelay != null) ? this.ActionDelay : null),
      ActionType: ((this.ActionType != null) ? this.ActionType : null),
      ActionForwardToGateway: ((this.ActionForwardToGateway != null) ? this.ActionForwardToGateway : null),
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      GatewayAccountId: ((this.GatewayAccountId != null) ? this.GatewayAccountId : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradingRouteRuleCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradingRouteRuleCreateRequest} other Object value
   * @returns {!TradingRouteRuleCreateRequest} Created struct
   */
  static fromObject (other) {
    return new TradingRouteRuleCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradingRouteRuleCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradingRouteRuleCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradingRouteRuleCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6308
  }
}

export { TradingRouteRuleCreateRequest };

/**
 * Fast Binary Encoding TradingRouteRuleCreateRequest field model
 */
class FieldModelTradingRouteRuleCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.IsEnabled = new fbe.FieldModelBool(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Name = new fbe.FieldModelString(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.RequestType = new fbe.FieldModelSet(new FieldModelTradingRouteRequestType(buffer, this.Description.fbeOffset + this.Description.fbeSize), buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.Condition = new fbe.FieldModelVector(new FieldModelTradingRouteCondition(buffer, this.RequestType.fbeOffset + this.RequestType.fbeSize), buffer, this.RequestType.fbeOffset + this.RequestType.fbeSize)
    this.ActionDelay = new fbe.FieldModelOptional(new FieldModelTradingRouteActionDelay(buffer, this.Condition.fbeOffset + this.Condition.fbeSize), buffer, this.Condition.fbeOffset + this.Condition.fbeSize)
    this.ActionType = new FieldModelTradingRouteActionType(buffer, this.ActionDelay.fbeOffset + this.ActionDelay.fbeSize)
    this.ActionForwardToGateway = new fbe.FieldModelOptional(new FieldModelTradingRouteActionForwardToGateway(buffer, this.ActionType.fbeOffset + this.ActionType.fbeSize), buffer, this.ActionType.fbeOffset + this.ActionType.fbeSize)
    this.SortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.ActionForwardToGateway.fbeOffset + this.ActionForwardToGateway.fbeSize), buffer, this.ActionForwardToGateway.fbeOffset + this.ActionForwardToGateway.fbeSize)
    this.GatewayAccountId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize), buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.IsEnabled.fbeSize + this.Name.fbeSize + this.Description.fbeSize + this.RequestType.fbeSize + this.Condition.fbeSize + this.ActionDelay.fbeSize + this.ActionType.fbeSize + this.ActionForwardToGateway.fbeSize + this.SortOrder.fbeSize + this.GatewayAccountId.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.IsEnabled.fbeExtra + this.Name.fbeExtra + this.Description.fbeExtra + this.RequestType.fbeExtra + this.Condition.fbeExtra + this.ActionDelay.fbeExtra + this.ActionType.fbeExtra + this.ActionForwardToGateway.fbeExtra + this.SortOrder.fbeExtra + this.GatewayAccountId.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradingRouteRuleCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6308
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.RequestType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestType.fbeSize

    if ((fbeCurrentSize + this.Condition.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Condition.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Condition.fbeSize

    if ((fbeCurrentSize + this.ActionDelay.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActionDelay.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActionDelay.fbeSize

    if ((fbeCurrentSize + this.ActionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActionType.fbeSize

    if ((fbeCurrentSize + this.ActionForwardToGateway.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActionForwardToGateway.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActionForwardToGateway.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.GatewayAccountId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayAccountId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayAccountId.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @param {!TradingRouteRuleCreateRequest} fbeValue Default value, defaults is new TradingRouteRuleCreateRequest()
   * @returns {!TradingRouteRuleCreateRequest} TradingRouteRuleCreateRequest value
   */
  get (fbeValue = new TradingRouteRuleCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @param {!TradingRouteRuleCreateRequest} fbeValue TradingRouteRuleCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.RequestType.fbeSize) <= fbeStructSize) {
      this.RequestType.get(fbeValue.RequestType)
    } else {
      fbeValue.RequestType.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestType.fbeSize

    if ((fbeCurrentSize + this.Condition.fbeSize) <= fbeStructSize) {
      this.Condition.get(fbeValue.Condition)
    } else {
      fbeValue.Condition.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Condition.fbeSize

    if ((fbeCurrentSize + this.ActionDelay.fbeSize) <= fbeStructSize) {
      fbeValue.ActionDelay = this.ActionDelay.get()
    } else {
      fbeValue.ActionDelay = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActionDelay.fbeSize

    if ((fbeCurrentSize + this.ActionType.fbeSize) <= fbeStructSize) {
      fbeValue.ActionType = this.ActionType.get()
    } else {
      fbeValue.ActionType = new TradingRouteActionType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActionType.fbeSize

    if ((fbeCurrentSize + this.ActionForwardToGateway.fbeSize) <= fbeStructSize) {
      fbeValue.ActionForwardToGateway = this.ActionForwardToGateway.get()
    } else {
      fbeValue.ActionForwardToGateway = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActionForwardToGateway.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.GatewayAccountId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayAccountId = this.GatewayAccountId.get()
    } else {
      fbeValue.GatewayAccountId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayAccountId.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @param {!TradingRouteRuleCreateRequest} fbeValue TradingRouteRuleCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradingRouteRuleCreateRequest}
   * @param {TradingRouteRuleCreateRequest} fbeValue TradingRouteRuleCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.Name.set(fbeValue.Name)
    this.Description.set(fbeValue.Description)
    this.RequestType.set(fbeValue.RequestType)
    this.Condition.set(fbeValue.Condition)
    this.ActionDelay.set(fbeValue.ActionDelay)
    this.ActionType.set(fbeValue.ActionType)
    this.ActionForwardToGateway.set(fbeValue.ActionForwardToGateway)
    this.SortOrder.set(fbeValue.SortOrder)
    this.GatewayAccountId.set(fbeValue.GatewayAccountId)
  }
}

export { FieldModelTradingRouteRuleCreateRequest };

/**
 * Fast Binary Encoding TradingRouteRuleCreateRequest model
 */
class TradingRouteRuleCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradingRouteRuleCreateRequest(this.buffer, 4)
  }

  /**
   * Get the TradingRouteRuleCreateRequest model
   * @this {!TradingRouteRuleCreateRequestModel}
   * @returns {!FieldModelTradingRouteRuleCreateRequest} model TradingRouteRuleCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradingRouteRuleCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradingRouteRuleCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradingRouteRuleCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradingRouteRuleCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradingRouteRuleCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradingRouteRuleCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradingRouteRuleCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradingRouteRuleCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradingRouteRuleCreateRequestModel}
   * @param {!TradingRouteRuleCreateRequest} value TradingRouteRuleCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradingRouteRuleCreateRequestModel}
   * @param {!TradingRouteRuleCreateRequest} value TradingRouteRuleCreateRequest value, defaults is new TradingRouteRuleCreateRequest()
   * @return {!object} Deserialized TradingRouteRuleCreateRequest value and its size
   */
  deserialize (value = new TradingRouteRuleCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradingRouteRuleCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradingRouteRuleCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradingRouteRuleCreateRequestModel}
   * @param {!number} prev Previous TradingRouteRuleCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradingRouteRuleCreateRequestModel };
TradingRouteRuleCreateRequest.__has_response = true;
TradingRouteRuleCreateRequest.__response_class = TradingRouteRuleCreateResponse;
