
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {CurrencyGroupModifyResponse} from './CurrencyGroupModifyResponse';

/**
 * CurrencyGroupModifyRequest struct
 */
class CurrencyGroupModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} currencyGroupId
   * @param {string=} currencyGroupName
   * @param {number=} newSortOrder
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), currencyGroupId = null, currencyGroupName = null, newSortOrder = null, newName = null, newComment = null, newDescription = null) {
    super()
    super.copy(parent)
    this.CurrencyGroupId = currencyGroupId
    this.CurrencyGroupName = currencyGroupName
    this.NewSortOrder = newSortOrder
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
  }

  /**
   * Copy struct (shallow copy)
   * @this {!CurrencyGroupModifyRequest}
   * @param {!CurrencyGroupModifyRequest} other Other struct
   * @returns {!CurrencyGroupModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.CurrencyGroupId != null) {
      this.CurrencyGroupId = new UUID(other.CurrencyGroupId)
    } else {
      this.CurrencyGroupId = null
    }
    if (other.CurrencyGroupName != null) {
      this.CurrencyGroupName = other.CurrencyGroupName
    } else {
      this.CurrencyGroupName = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!CurrencyGroupModifyRequest}
   * @returns {!CurrencyGroupModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new CurrencyGroupModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new CurrencyGroupModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!CurrencyGroupModifyRequest}
   * @param {!CurrencyGroupModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof CurrencyGroupModifyRequest)) {
      throw new TypeError('Instance of CurrencyGroupModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!CurrencyGroupModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      CurrencyGroupId: ((this.CurrencyGroupId != null) ? this.CurrencyGroupId.toString() : null),
      CurrencyGroupName: ((this.CurrencyGroupName != null) ? this.CurrencyGroupName : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return CurrencyGroupModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!CurrencyGroupModifyRequest} other Object value
   * @returns {!CurrencyGroupModifyRequest} Created struct
   */
  static fromObject (other) {
    return new CurrencyGroupModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!CurrencyGroupModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return CurrencyGroupModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!CurrencyGroupModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6080
  }
}

export { CurrencyGroupModifyRequest };

/**
 * Fast Binary Encoding CurrencyGroupModifyRequest field model
 */
class FieldModelCurrencyGroupModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.CurrencyGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.CurrencyGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CurrencyGroupId.fbeOffset + this.CurrencyGroupId.fbeSize), buffer, this.CurrencyGroupId.fbeOffset + this.CurrencyGroupId.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.CurrencyGroupName.fbeOffset + this.CurrencyGroupName.fbeSize), buffer, this.CurrencyGroupName.fbeOffset + this.CurrencyGroupName.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.CurrencyGroupId.fbeSize + this.CurrencyGroupName.fbeSize + this.NewSortOrder.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.CurrencyGroupId.fbeExtra + this.CurrencyGroupName.fbeExtra + this.NewSortOrder.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelCurrencyGroupModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6080
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.CurrencyGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CurrencyGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyGroupId.fbeSize

    if ((fbeCurrentSize + this.CurrencyGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CurrencyGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyGroupName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @param {!CurrencyGroupModifyRequest} fbeValue Default value, defaults is new CurrencyGroupModifyRequest()
   * @returns {!CurrencyGroupModifyRequest} CurrencyGroupModifyRequest value
   */
  get (fbeValue = new CurrencyGroupModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @param {!CurrencyGroupModifyRequest} fbeValue CurrencyGroupModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.CurrencyGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.CurrencyGroupId = this.CurrencyGroupId.get()
    } else {
      fbeValue.CurrencyGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyGroupId.fbeSize

    if ((fbeCurrentSize + this.CurrencyGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.CurrencyGroupName = this.CurrencyGroupName.get()
    } else {
      fbeValue.CurrencyGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyGroupName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @param {!CurrencyGroupModifyRequest} fbeValue CurrencyGroupModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelCurrencyGroupModifyRequest}
   * @param {CurrencyGroupModifyRequest} fbeValue CurrencyGroupModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.CurrencyGroupId.set(fbeValue.CurrencyGroupId)
    this.CurrencyGroupName.set(fbeValue.CurrencyGroupName)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
  }
}

export { FieldModelCurrencyGroupModifyRequest };

/**
 * Fast Binary Encoding CurrencyGroupModifyRequest model
 */
class CurrencyGroupModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelCurrencyGroupModifyRequest(this.buffer, 4)
  }

  /**
   * Get the CurrencyGroupModifyRequest model
   * @this {!CurrencyGroupModifyRequestModel}
   * @returns {!FieldModelCurrencyGroupModifyRequest} model CurrencyGroupModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!CurrencyGroupModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!CurrencyGroupModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return CurrencyGroupModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!CurrencyGroupModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelCurrencyGroupModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!CurrencyGroupModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!CurrencyGroupModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!CurrencyGroupModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!CurrencyGroupModifyRequestModel}
   * @param {!CurrencyGroupModifyRequest} value CurrencyGroupModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!CurrencyGroupModifyRequestModel}
   * @param {!CurrencyGroupModifyRequest} value CurrencyGroupModifyRequest value, defaults is new CurrencyGroupModifyRequest()
   * @return {!object} Deserialized CurrencyGroupModifyRequest value and its size
   */
  deserialize (value = new CurrencyGroupModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new CurrencyGroupModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new CurrencyGroupModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!CurrencyGroupModifyRequestModel}
   * @param {!number} prev Previous CurrencyGroupModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { CurrencyGroupModifyRequestModel };
CurrencyGroupModifyRequest.__has_response = true;
CurrencyGroupModifyRequest.__response_class = CurrencyGroupModifyResponse;
