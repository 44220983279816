import { LegalDocumentDescription } from "@api/fbe/manager/LegalDocumentDescription";
import { convertLegalDocumentMetadataToModel, convertModelToLegalDocumentMetadata, LegalDocumentMetadataModel } from "./LegalDocumentMetadata";
import { UInt64 } from "@api/fbe/int64";

export type LegalDocumentDescriptionModel = {
    DocumentId: number | null;
    GroupId: number | null;
    Metadata: LegalDocumentMetadataModel;
};

export const convertLegalDocumentDescriptionToModel = (
    record: LegalDocumentDescription,
): LegalDocumentDescriptionModel => ({
    DocumentId: +record.DocumentId.toNumber(),
    GroupId: +record.GroupId.toNumber(),
    Metadata: convertLegalDocumentMetadataToModel(record.Metadata),
});

export const convertModelToLegalDocumentDescription = (
    record: LegalDocumentDescriptionModel,
): LegalDocumentDescription => {
    const result = new LegalDocumentDescription();
    if (record.DocumentId !== null && Number.isFinite(record.DocumentId)) {
        result.DocumentId = UInt64.fromNumber(record.DocumentId);
    }
    if (record.GroupId !== null && Number.isFinite(record.GroupId)) {
        result.GroupId = UInt64.fromNumber(record.GroupId);
    }
    result.Metadata = convertModelToLegalDocumentMetadata(record.Metadata);
    return result;
};

export type LegalDocumentGetResponseModel = {
    Description: LegalDocumentDescriptionModel;
    DocumentContent: Uint8Array;
};