
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * AccountMarginStable struct
 */
class AccountMarginStable {
  /**
   * Initialize struct
   * @param {!number=} login
   * @param {!number=} actualVersion
   * @param {!UUID=} balanceCurrencyId
   * @param {!number=} balance
   * @param {!number=} credit
   * @param {!number=} leverage
   * @param {!number=} prevDayBalance
   * @param {!number=} prevMonthBalance
   * @param {!number=} dayCloseProfit
   * @param {!number=} dayDeposit
   * @param {number=} maxLeverage
   * @param {!number=} prevDayOpenProfit
   * @param {!number=} totalCloseProfit
   * @param {!number=} totalDeposit
   * @param {!number=} totalWithdrawal
   * @constructor
   */
  constructor (login = new UInt64(0, 0), actualVersion = new UInt64(0, 0), balanceCurrencyId = new UUID(), balance = 0.0, credit = 0.0, leverage = 0, prevDayBalance = 0.0, prevMonthBalance = 0.0, dayCloseProfit = 0.0, dayDeposit = 0.0, maxLeverage = null, prevDayOpenProfit = 0.0, totalCloseProfit = 0.0, totalDeposit = 0.0, totalWithdrawal = 0.0) {
    this.Login = login
    this.ActualVersion = actualVersion
    this.BalanceCurrencyId = balanceCurrencyId
    this.Balance = balance
    this.Credit = credit
    this.Leverage = leverage
    this.PrevDayBalance = prevDayBalance
    this.PrevMonthBalance = prevMonthBalance
    this.DayCloseProfit = dayCloseProfit
    this.DayDeposit = dayDeposit
    this.MaxLeverage = maxLeverage
    this.PrevDayOpenProfit = prevDayOpenProfit
    this.TotalCloseProfit = totalCloseProfit
    this.TotalDeposit = totalDeposit
    this.TotalWithdrawal = totalWithdrawal
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountMarginStable}
   * @param {!AccountMarginStable} other Other struct
   * @returns {!AccountMarginStable} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.BalanceCurrencyId != null) {
      this.BalanceCurrencyId = new UUID(other.BalanceCurrencyId)
    } else {
      this.BalanceCurrencyId = null
    }
    if (other.Balance != null) {
      this.Balance = other.Balance
    } else {
      this.Balance = null
    }
    if (other.Credit != null) {
      this.Credit = other.Credit
    } else {
      this.Credit = null
    }
    if (other.Leverage != null) {
      this.Leverage = other.Leverage
    } else {
      this.Leverage = null
    }
    if (other.PrevDayBalance != null) {
      this.PrevDayBalance = other.PrevDayBalance
    } else {
      this.PrevDayBalance = null
    }
    if (other.PrevMonthBalance != null) {
      this.PrevMonthBalance = other.PrevMonthBalance
    } else {
      this.PrevMonthBalance = null
    }
    if (other.DayCloseProfit != null) {
      this.DayCloseProfit = other.DayCloseProfit
    } else {
      this.DayCloseProfit = null
    }
    if (other.DayDeposit != null) {
      this.DayDeposit = other.DayDeposit
    } else {
      this.DayDeposit = null
    }
    if (other.MaxLeverage != null) {
      this.MaxLeverage = other.MaxLeverage
    } else {
      this.MaxLeverage = null
    }
    if (other.PrevDayOpenProfit != null) {
      this.PrevDayOpenProfit = other.PrevDayOpenProfit
    } else {
      this.PrevDayOpenProfit = null
    }
    if (other.TotalCloseProfit != null) {
      this.TotalCloseProfit = other.TotalCloseProfit
    } else {
      this.TotalCloseProfit = null
    }
    if (other.TotalDeposit != null) {
      this.TotalDeposit = other.TotalDeposit
    } else {
      this.TotalDeposit = null
    }
    if (other.TotalWithdrawal != null) {
      this.TotalWithdrawal = other.TotalWithdrawal
    } else {
      this.TotalWithdrawal = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountMarginStable}
   * @returns {!AccountMarginStable} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountMarginStableModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountMarginStableModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountMarginStable}
   * @param {!AccountMarginStable} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountMarginStable)) {
      throw new TypeError('Instance of AccountMarginStable is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.Login !== other.Login) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountMarginStable}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      BalanceCurrencyId: ((this.BalanceCurrencyId != null) ? this.BalanceCurrencyId.toString() : null),
      Balance: ((this.Balance != null) ? this.Balance : null),
      Credit: ((this.Credit != null) ? this.Credit : null),
      Leverage: ((this.Leverage != null) ? this.Leverage : null),
      PrevDayBalance: ((this.PrevDayBalance != null) ? this.PrevDayBalance : null),
      PrevMonthBalance: ((this.PrevMonthBalance != null) ? this.PrevMonthBalance : null),
      DayCloseProfit: ((this.DayCloseProfit != null) ? this.DayCloseProfit : null),
      DayDeposit: ((this.DayDeposit != null) ? this.DayDeposit : null),
      MaxLeverage: ((this.MaxLeverage != null) ? this.MaxLeverage : null),
      PrevDayOpenProfit: ((this.PrevDayOpenProfit != null) ? this.PrevDayOpenProfit : null),
      TotalCloseProfit: ((this.TotalCloseProfit != null) ? this.TotalCloseProfit : null),
      TotalDeposit: ((this.TotalDeposit != null) ? this.TotalDeposit : null),
      TotalWithdrawal: ((this.TotalWithdrawal != null) ? this.TotalWithdrawal : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountMarginStable.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountMarginStable} other Object value
   * @returns {!AccountMarginStable} Created struct
   */
  static fromObject (other) {
    return new AccountMarginStable().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountMarginStable}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountMarginStable.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountMarginStable}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6509
  }
}

export { AccountMarginStable };

/**
 * Fast Binary Encoding AccountMarginStable field model
 */
class FieldModelAccountMarginStable extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.ActualVersion = new fbe.FieldModelUInt64(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.BalanceCurrencyId = new fbe.FieldModelUUID(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.Balance = new fbe.FieldModelDouble(buffer, this.BalanceCurrencyId.fbeOffset + this.BalanceCurrencyId.fbeSize)
    this.Credit = new fbe.FieldModelDouble(buffer, this.Balance.fbeOffset + this.Balance.fbeSize)
    this.Leverage = new fbe.FieldModelUInt32(buffer, this.Credit.fbeOffset + this.Credit.fbeSize)
    this.PrevDayBalance = new fbe.FieldModelDouble(buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize)
    this.PrevMonthBalance = new fbe.FieldModelDouble(buffer, this.PrevDayBalance.fbeOffset + this.PrevDayBalance.fbeSize)
    this.DayCloseProfit = new fbe.FieldModelDouble(buffer, this.PrevMonthBalance.fbeOffset + this.PrevMonthBalance.fbeSize)
    this.DayDeposit = new fbe.FieldModelDouble(buffer, this.DayCloseProfit.fbeOffset + this.DayCloseProfit.fbeSize)
    this.MaxLeverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.DayDeposit.fbeOffset + this.DayDeposit.fbeSize), buffer, this.DayDeposit.fbeOffset + this.DayDeposit.fbeSize)
    this.PrevDayOpenProfit = new fbe.FieldModelDouble(buffer, this.MaxLeverage.fbeOffset + this.MaxLeverage.fbeSize)
    this.TotalCloseProfit = new fbe.FieldModelDouble(buffer, this.PrevDayOpenProfit.fbeOffset + this.PrevDayOpenProfit.fbeSize)
    this.TotalDeposit = new fbe.FieldModelDouble(buffer, this.TotalCloseProfit.fbeOffset + this.TotalCloseProfit.fbeSize)
    this.TotalWithdrawal = new fbe.FieldModelDouble(buffer, this.TotalDeposit.fbeOffset + this.TotalDeposit.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountMarginStable}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountMarginStable}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.ActualVersion.fbeSize + this.BalanceCurrencyId.fbeSize + this.Balance.fbeSize + this.Credit.fbeSize + this.Leverage.fbeSize + this.PrevDayBalance.fbeSize + this.PrevMonthBalance.fbeSize + this.DayCloseProfit.fbeSize + this.DayDeposit.fbeSize + this.MaxLeverage.fbeSize + this.PrevDayOpenProfit.fbeSize + this.TotalCloseProfit.fbeSize + this.TotalDeposit.fbeSize + this.TotalWithdrawal.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountMarginStable}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.ActualVersion.fbeExtra + this.BalanceCurrencyId.fbeExtra + this.Balance.fbeExtra + this.Credit.fbeExtra + this.Leverage.fbeExtra + this.PrevDayBalance.fbeExtra + this.PrevMonthBalance.fbeExtra + this.DayCloseProfit.fbeExtra + this.DayDeposit.fbeExtra + this.MaxLeverage.fbeExtra + this.PrevDayOpenProfit.fbeExtra + this.TotalCloseProfit.fbeExtra + this.TotalDeposit.fbeExtra + this.TotalWithdrawal.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountMarginStable}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountMarginStable.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountMarginStable}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6509
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountMarginStable}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountMarginStable}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Balance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Credit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Leverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.PrevDayBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevDayBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayBalance.fbeSize

    if ((fbeCurrentSize + this.PrevMonthBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevMonthBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevMonthBalance.fbeSize

    if ((fbeCurrentSize + this.DayCloseProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayCloseProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayCloseProfit.fbeSize

    if ((fbeCurrentSize + this.DayDeposit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayDeposit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayDeposit.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.PrevDayOpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevDayOpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalCloseProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfit.fbeSize

    if ((fbeCurrentSize + this.TotalDeposit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalDeposit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDeposit.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawal.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalWithdrawal.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawal.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountMarginStable}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountMarginStable}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountMarginStable}
   * @param {!AccountMarginStable} fbeValue Default value, defaults is new AccountMarginStable()
   * @returns {!AccountMarginStable} AccountMarginStable value
   */
  get (fbeValue = new AccountMarginStable()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountMarginStable}
   * @param {!AccountMarginStable} fbeValue AccountMarginStable value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceCurrencyId = this.BalanceCurrencyId.get()
    } else {
      fbeValue.BalanceCurrencyId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) <= fbeStructSize) {
      fbeValue.Balance = this.Balance.get()
    } else {
      fbeValue.Balance = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) <= fbeStructSize) {
      fbeValue.Credit = this.Credit.get()
    } else {
      fbeValue.Credit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) <= fbeStructSize) {
      fbeValue.Leverage = this.Leverage.get()
    } else {
      fbeValue.Leverage = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.PrevDayBalance.fbeSize) <= fbeStructSize) {
      fbeValue.PrevDayBalance = this.PrevDayBalance.get()
    } else {
      fbeValue.PrevDayBalance = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayBalance.fbeSize

    if ((fbeCurrentSize + this.PrevMonthBalance.fbeSize) <= fbeStructSize) {
      fbeValue.PrevMonthBalance = this.PrevMonthBalance.get()
    } else {
      fbeValue.PrevMonthBalance = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevMonthBalance.fbeSize

    if ((fbeCurrentSize + this.DayCloseProfit.fbeSize) <= fbeStructSize) {
      fbeValue.DayCloseProfit = this.DayCloseProfit.get()
    } else {
      fbeValue.DayCloseProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayCloseProfit.fbeSize

    if ((fbeCurrentSize + this.DayDeposit.fbeSize) <= fbeStructSize) {
      fbeValue.DayDeposit = this.DayDeposit.get()
    } else {
      fbeValue.DayDeposit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayDeposit.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.MaxLeverage = this.MaxLeverage.get()
    } else {
      fbeValue.MaxLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.PrevDayOpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.PrevDayOpenProfit = this.PrevDayOpenProfit.get()
    } else {
      fbeValue.PrevDayOpenProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfit.fbeSize) <= fbeStructSize) {
      fbeValue.TotalCloseProfit = this.TotalCloseProfit.get()
    } else {
      fbeValue.TotalCloseProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfit.fbeSize

    if ((fbeCurrentSize + this.TotalDeposit.fbeSize) <= fbeStructSize) {
      fbeValue.TotalDeposit = this.TotalDeposit.get()
    } else {
      fbeValue.TotalDeposit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDeposit.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawal.fbeSize) <= fbeStructSize) {
      fbeValue.TotalWithdrawal = this.TotalWithdrawal.get()
    } else {
      fbeValue.TotalWithdrawal = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawal.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountMarginStable}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountMarginStable}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountMarginStable}
   * @param {!AccountMarginStable} fbeValue AccountMarginStable value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountMarginStable}
   * @param {AccountMarginStable} fbeValue AccountMarginStable value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.BalanceCurrencyId.set(fbeValue.BalanceCurrencyId)
    this.Balance.set(fbeValue.Balance)
    this.Credit.set(fbeValue.Credit)
    this.Leverage.set(fbeValue.Leverage)
    this.PrevDayBalance.set(fbeValue.PrevDayBalance)
    this.PrevMonthBalance.set(fbeValue.PrevMonthBalance)
    this.DayCloseProfit.set(fbeValue.DayCloseProfit)
    this.DayDeposit.set(fbeValue.DayDeposit)
    this.MaxLeverage.set(fbeValue.MaxLeverage)
    this.PrevDayOpenProfit.set(fbeValue.PrevDayOpenProfit)
    this.TotalCloseProfit.set(fbeValue.TotalCloseProfit)
    this.TotalDeposit.set(fbeValue.TotalDeposit)
    this.TotalWithdrawal.set(fbeValue.TotalWithdrawal)
  }
}

export { FieldModelAccountMarginStable };

/**
 * Fast Binary Encoding AccountMarginStable model
 */
class AccountMarginStableModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountMarginStable(this.buffer, 4)
  }

  /**
   * Get the AccountMarginStable model
   * @this {!AccountMarginStableModel}
   * @returns {!FieldModelAccountMarginStable} model AccountMarginStable model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountMarginStableModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountMarginStableModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountMarginStableModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountMarginStableModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountMarginStable.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountMarginStableModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountMarginStableModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountMarginStableModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountMarginStableModel}
   * @param {!AccountMarginStable} value AccountMarginStable value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountMarginStableModel}
   * @param {!AccountMarginStable} value AccountMarginStable value, defaults is new AccountMarginStable()
   * @return {!object} Deserialized AccountMarginStable value and its size
   */
  deserialize (value = new AccountMarginStable()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountMarginStable(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountMarginStable(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountMarginStableModel}
   * @param {!number} prev Previous AccountMarginStable model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountMarginStableModel };
