
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {OrderModifyResponse} from './OrderModifyResponse';
import {OrderSide} from '../core/OrderSide';
import {FieldModelOrderSide} from '../core/OrderSide';
import {OrderType} from '../core/OrderType';
import {FieldModelOrderType} from '../core/OrderType';
import {OrderTimeInForce} from '../core/OrderTimeInForce';
import {FieldModelOrderTimeInForce} from '../core/OrderTimeInForce';
import {OrderStatus} from '../core/OrderStatus';
import {FieldModelOrderStatus} from '../core/OrderStatus';

/**
 * OrderModifyRequest struct
 */
class OrderModifyRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {number=} orderId
   * @param {number=} login
   * @param {string=} clientOrderId
   * @param {number=} newParentOrderId
   * @param {!boolean=} newParentOrderIdReset
   * @param {UUID=} newSymbolId
   * @param {string=} newSymbolName
   * @param {OrderSide=} newOrderSide
   * @param {OrderType=} newOrderType
   * @param {OrderTimeInForce=} newOrderTimeInForce
   * @param {!boolean=} newOrderTimeInForceReset
   * @param {number=} newPrice
   * @param {!boolean=} newPriceReset
   * @param {number=} newStopPrice
   * @param {!boolean=} newStopPriceReset
   * @param {number=} newTakeProfit
   * @param {!boolean=} newTakeProfitReset
   * @param {number=} newStopLoss
   * @param {!boolean=} newStopLossReset
   * @param {number=} newAmount
   * @param {number=} newExecutedAmount
   * @param {number=} newRemainingAmount
   * @param {Date=} newExpiration
   * @param {!boolean=} newExpirationReset
   * @param {string=} newAccountComment
   * @param {string=} newManagerComment
   * @param {number=} newMagic
   * @param {!boolean=} newMagicReset
   * @param {number=} newOpenPrice
   * @param {!boolean=} newOpenPriceReset
   * @param {number=} newClosePrice
   * @param {!boolean=} newClosePriceReset
   * @param {number=} newCloseAmount
   * @param {!boolean=} newCloseAmountReset
   * @param {number=} newOpenMarginRate
   * @param {!boolean=} newOpenMarginRateReset
   * @param {OrderStatus=} newOrderStatus
   * @param {number=} newOpenAccountMargin
   * @param {!boolean=} newOpenAccountMarginReset
   * @param {number=} newOpenAccountEquity
   * @param {!boolean=} newOpenAccountEquityReset
   * @param {number=} newOpenAccountCredit
   * @param {!boolean=} newOpenAccountCreditReset
   * @param {number=} newOpenAmount
   * @param {!boolean=} newOpenAmountReset
   * @constructor
   */
  constructor (parent = new Request(), orderId = null, login = null, clientOrderId = null, newParentOrderId = null, newParentOrderIdReset = false, newSymbolId = null, newSymbolName = null, newOrderSide = null, newOrderType = null, newOrderTimeInForce = null, newOrderTimeInForceReset = false, newPrice = null, newPriceReset = false, newStopPrice = null, newStopPriceReset = false, newTakeProfit = null, newTakeProfitReset = false, newStopLoss = null, newStopLossReset = false, newAmount = null, newExecutedAmount = null, newRemainingAmount = null, newExpiration = null, newExpirationReset = false, newAccountComment = null, newManagerComment = null, newMagic = null, newMagicReset = false, newOpenPrice = null, newOpenPriceReset = false, newClosePrice = null, newClosePriceReset = false, newCloseAmount = null, newCloseAmountReset = false, newOpenMarginRate = null, newOpenMarginRateReset = false, newOrderStatus = null, newOpenAccountMargin = null, newOpenAccountMarginReset = false, newOpenAccountEquity = null, newOpenAccountEquityReset = false, newOpenAccountCredit = null, newOpenAccountCreditReset = false, newOpenAmount = null, newOpenAmountReset = false) {
    super()
    super.copy(parent)
    this.OrderId = orderId
    this.Login = login
    this.ClientOrderId = clientOrderId
    this.NewParentOrderId = newParentOrderId
    this.NewParentOrderIdReset = newParentOrderIdReset
    this.NewSymbolId = newSymbolId
    this.NewSymbolName = newSymbolName
    this.NewOrderSide = newOrderSide
    this.NewOrderType = newOrderType
    this.NewOrderTimeInForce = newOrderTimeInForce
    this.NewOrderTimeInForceReset = newOrderTimeInForceReset
    this.NewPrice = newPrice
    this.NewPriceReset = newPriceReset
    this.NewStopPrice = newStopPrice
    this.NewStopPriceReset = newStopPriceReset
    this.NewTakeProfit = newTakeProfit
    this.NewTakeProfitReset = newTakeProfitReset
    this.NewStopLoss = newStopLoss
    this.NewStopLossReset = newStopLossReset
    this.NewAmount = newAmount
    this.NewExecutedAmount = newExecutedAmount
    this.NewRemainingAmount = newRemainingAmount
    this.NewExpiration = newExpiration
    this.NewExpirationReset = newExpirationReset
    this.NewAccountComment = newAccountComment
    this.NewManagerComment = newManagerComment
    this.NewMagic = newMagic
    this.NewMagicReset = newMagicReset
    this.NewOpenPrice = newOpenPrice
    this.NewOpenPriceReset = newOpenPriceReset
    this.NewClosePrice = newClosePrice
    this.NewClosePriceReset = newClosePriceReset
    this.NewCloseAmount = newCloseAmount
    this.NewCloseAmountReset = newCloseAmountReset
    this.NewOpenMarginRate = newOpenMarginRate
    this.NewOpenMarginRateReset = newOpenMarginRateReset
    this.NewOrderStatus = newOrderStatus
    this.NewOpenAccountMargin = newOpenAccountMargin
    this.NewOpenAccountMarginReset = newOpenAccountMarginReset
    this.NewOpenAccountEquity = newOpenAccountEquity
    this.NewOpenAccountEquityReset = newOpenAccountEquityReset
    this.NewOpenAccountCredit = newOpenAccountCredit
    this.NewOpenAccountCreditReset = newOpenAccountCreditReset
    this.NewOpenAmount = newOpenAmount
    this.NewOpenAmountReset = newOpenAmountReset
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderModifyRequest}
   * @param {!OrderModifyRequest} other Other struct
   * @returns {!OrderModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.OrderId != null) {
      this.OrderId = UInt64.fromNumber(other.OrderId)
    } else {
      this.OrderId = null
    }
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.ClientOrderId != null) {
      this.ClientOrderId = other.ClientOrderId
    } else {
      this.ClientOrderId = null
    }
    if (other.NewParentOrderId != null) {
      this.NewParentOrderId = UInt64.fromNumber(other.NewParentOrderId)
    } else {
      this.NewParentOrderId = null
    }
    if (other.NewParentOrderIdReset != null) {
      this.NewParentOrderIdReset = other.NewParentOrderIdReset
    } else {
      this.NewParentOrderIdReset = null
    }
    if (other.NewSymbolId != null) {
      this.NewSymbolId = new UUID(other.NewSymbolId)
    } else {
      this.NewSymbolId = null
    }
    if (other.NewSymbolName != null) {
      this.NewSymbolName = other.NewSymbolName
    } else {
      this.NewSymbolName = null
    }
    if (other.NewOrderSide != null) {
      this.NewOrderSide = OrderSide.fromObject(other.NewOrderSide)
    } else {
      this.NewOrderSide = null
    }
    if (other.NewOrderType != null) {
      this.NewOrderType = OrderType.fromObject(other.NewOrderType)
    } else {
      this.NewOrderType = null
    }
    if (other.NewOrderTimeInForce != null) {
      this.NewOrderTimeInForce = OrderTimeInForce.fromObject(other.NewOrderTimeInForce)
    } else {
      this.NewOrderTimeInForce = null
    }
    if (other.NewOrderTimeInForceReset != null) {
      this.NewOrderTimeInForceReset = other.NewOrderTimeInForceReset
    } else {
      this.NewOrderTimeInForceReset = null
    }
    if (other.NewPrice != null) {
      this.NewPrice = other.NewPrice
    } else {
      this.NewPrice = null
    }
    if (other.NewPriceReset != null) {
      this.NewPriceReset = other.NewPriceReset
    } else {
      this.NewPriceReset = null
    }
    if (other.NewStopPrice != null) {
      this.NewStopPrice = other.NewStopPrice
    } else {
      this.NewStopPrice = null
    }
    if (other.NewStopPriceReset != null) {
      this.NewStopPriceReset = other.NewStopPriceReset
    } else {
      this.NewStopPriceReset = null
    }
    if (other.NewTakeProfit != null) {
      this.NewTakeProfit = other.NewTakeProfit
    } else {
      this.NewTakeProfit = null
    }
    if (other.NewTakeProfitReset != null) {
      this.NewTakeProfitReset = other.NewTakeProfitReset
    } else {
      this.NewTakeProfitReset = null
    }
    if (other.NewStopLoss != null) {
      this.NewStopLoss = other.NewStopLoss
    } else {
      this.NewStopLoss = null
    }
    if (other.NewStopLossReset != null) {
      this.NewStopLossReset = other.NewStopLossReset
    } else {
      this.NewStopLossReset = null
    }
    if (other.NewAmount != null) {
      this.NewAmount = other.NewAmount
    } else {
      this.NewAmount = null
    }
    if (other.NewExecutedAmount != null) {
      this.NewExecutedAmount = other.NewExecutedAmount
    } else {
      this.NewExecutedAmount = null
    }
    if (other.NewRemainingAmount != null) {
      this.NewRemainingAmount = other.NewRemainingAmount
    } else {
      this.NewRemainingAmount = null
    }
    if (other.NewExpiration != null) {
      if (other.NewExpiration instanceof Date) {
        this.NewExpiration = new Date(other.NewExpiration.getTime())
      } else {
        this.NewExpiration = new Date(Math.round(other.NewExpiration / 1000000))
      }
    } else {
      this.NewExpiration = null
    }
    if (other.NewExpirationReset != null) {
      this.NewExpirationReset = other.NewExpirationReset
    } else {
      this.NewExpirationReset = null
    }
    if (other.NewAccountComment != null) {
      this.NewAccountComment = other.NewAccountComment
    } else {
      this.NewAccountComment = null
    }
    if (other.NewManagerComment != null) {
      this.NewManagerComment = other.NewManagerComment
    } else {
      this.NewManagerComment = null
    }
    if (other.NewMagic != null) {
      this.NewMagic = other.NewMagic
    } else {
      this.NewMagic = null
    }
    if (other.NewMagicReset != null) {
      this.NewMagicReset = other.NewMagicReset
    } else {
      this.NewMagicReset = null
    }
    if (other.NewOpenPrice != null) {
      this.NewOpenPrice = other.NewOpenPrice
    } else {
      this.NewOpenPrice = null
    }
    if (other.NewOpenPriceReset != null) {
      this.NewOpenPriceReset = other.NewOpenPriceReset
    } else {
      this.NewOpenPriceReset = null
    }
    if (other.NewClosePrice != null) {
      this.NewClosePrice = other.NewClosePrice
    } else {
      this.NewClosePrice = null
    }
    if (other.NewClosePriceReset != null) {
      this.NewClosePriceReset = other.NewClosePriceReset
    } else {
      this.NewClosePriceReset = null
    }
    if (other.NewCloseAmount != null) {
      this.NewCloseAmount = other.NewCloseAmount
    } else {
      this.NewCloseAmount = null
    }
    if (other.NewCloseAmountReset != null) {
      this.NewCloseAmountReset = other.NewCloseAmountReset
    } else {
      this.NewCloseAmountReset = null
    }
    if (other.NewOpenMarginRate != null) {
      this.NewOpenMarginRate = other.NewOpenMarginRate
    } else {
      this.NewOpenMarginRate = null
    }
    if (other.NewOpenMarginRateReset != null) {
      this.NewOpenMarginRateReset = other.NewOpenMarginRateReset
    } else {
      this.NewOpenMarginRateReset = null
    }
    if (other.NewOrderStatus != null) {
      this.NewOrderStatus = OrderStatus.fromObject(other.NewOrderStatus)
    } else {
      this.NewOrderStatus = null
    }
    if (other.NewOpenAccountMargin != null) {
      this.NewOpenAccountMargin = other.NewOpenAccountMargin
    } else {
      this.NewOpenAccountMargin = null
    }
    if (other.NewOpenAccountMarginReset != null) {
      this.NewOpenAccountMarginReset = other.NewOpenAccountMarginReset
    } else {
      this.NewOpenAccountMarginReset = null
    }
    if (other.NewOpenAccountEquity != null) {
      this.NewOpenAccountEquity = other.NewOpenAccountEquity
    } else {
      this.NewOpenAccountEquity = null
    }
    if (other.NewOpenAccountEquityReset != null) {
      this.NewOpenAccountEquityReset = other.NewOpenAccountEquityReset
    } else {
      this.NewOpenAccountEquityReset = null
    }
    if (other.NewOpenAccountCredit != null) {
      this.NewOpenAccountCredit = other.NewOpenAccountCredit
    } else {
      this.NewOpenAccountCredit = null
    }
    if (other.NewOpenAccountCreditReset != null) {
      this.NewOpenAccountCreditReset = other.NewOpenAccountCreditReset
    } else {
      this.NewOpenAccountCreditReset = null
    }
    if (other.NewOpenAmount != null) {
      this.NewOpenAmount = other.NewOpenAmount
    } else {
      this.NewOpenAmount = null
    }
    if (other.NewOpenAmountReset != null) {
      this.NewOpenAmountReset = other.NewOpenAmountReset
    } else {
      this.NewOpenAmountReset = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderModifyRequest}
   * @returns {!OrderModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderModifyRequest}
   * @param {!OrderModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderModifyRequest)) {
      throw new TypeError('Instance of OrderModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      OrderId: ((this.OrderId != null) ? this.OrderId.toNumber() : null),
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      ClientOrderId: ((this.ClientOrderId != null) ? this.ClientOrderId : null),
      NewParentOrderId: ((this.NewParentOrderId != null) ? this.NewParentOrderId.toNumber() : null),
      NewParentOrderIdReset: ((this.NewParentOrderIdReset != null) ? this.NewParentOrderIdReset : null),
      NewSymbolId: ((this.NewSymbolId != null) ? this.NewSymbolId.toString() : null),
      NewSymbolName: ((this.NewSymbolName != null) ? this.NewSymbolName : null),
      NewOrderSide: ((this.NewOrderSide != null) ? this.NewOrderSide : null),
      NewOrderType: ((this.NewOrderType != null) ? this.NewOrderType : null),
      NewOrderTimeInForce: ((this.NewOrderTimeInForce != null) ? this.NewOrderTimeInForce : null),
      NewOrderTimeInForceReset: ((this.NewOrderTimeInForceReset != null) ? this.NewOrderTimeInForceReset : null),
      NewPrice: ((this.NewPrice != null) ? this.NewPrice : null),
      NewPriceReset: ((this.NewPriceReset != null) ? this.NewPriceReset : null),
      NewStopPrice: ((this.NewStopPrice != null) ? this.NewStopPrice : null),
      NewStopPriceReset: ((this.NewStopPriceReset != null) ? this.NewStopPriceReset : null),
      NewTakeProfit: ((this.NewTakeProfit != null) ? this.NewTakeProfit : null),
      NewTakeProfitReset: ((this.NewTakeProfitReset != null) ? this.NewTakeProfitReset : null),
      NewStopLoss: ((this.NewStopLoss != null) ? this.NewStopLoss : null),
      NewStopLossReset: ((this.NewStopLossReset != null) ? this.NewStopLossReset : null),
      NewAmount: ((this.NewAmount != null) ? this.NewAmount : null),
      NewExecutedAmount: ((this.NewExecutedAmount != null) ? this.NewExecutedAmount : null),
      NewRemainingAmount: ((this.NewRemainingAmount != null) ? this.NewRemainingAmount : null),
      NewExpiration: ((this.NewExpiration != null) ? (this.NewExpiration.getTime() * 1000000) : null),
      NewExpirationReset: ((this.NewExpirationReset != null) ? this.NewExpirationReset : null),
      NewAccountComment: ((this.NewAccountComment != null) ? this.NewAccountComment : null),
      NewManagerComment: ((this.NewManagerComment != null) ? this.NewManagerComment : null),
      NewMagic: ((this.NewMagic != null) ? this.NewMagic : null),
      NewMagicReset: ((this.NewMagicReset != null) ? this.NewMagicReset : null),
      NewOpenPrice: ((this.NewOpenPrice != null) ? this.NewOpenPrice : null),
      NewOpenPriceReset: ((this.NewOpenPriceReset != null) ? this.NewOpenPriceReset : null),
      NewClosePrice: ((this.NewClosePrice != null) ? this.NewClosePrice : null),
      NewClosePriceReset: ((this.NewClosePriceReset != null) ? this.NewClosePriceReset : null),
      NewCloseAmount: ((this.NewCloseAmount != null) ? this.NewCloseAmount : null),
      NewCloseAmountReset: ((this.NewCloseAmountReset != null) ? this.NewCloseAmountReset : null),
      NewOpenMarginRate: ((this.NewOpenMarginRate != null) ? this.NewOpenMarginRate : null),
      NewOpenMarginRateReset: ((this.NewOpenMarginRateReset != null) ? this.NewOpenMarginRateReset : null),
      NewOrderStatus: ((this.NewOrderStatus != null) ? this.NewOrderStatus : null),
      NewOpenAccountMargin: ((this.NewOpenAccountMargin != null) ? this.NewOpenAccountMargin : null),
      NewOpenAccountMarginReset: ((this.NewOpenAccountMarginReset != null) ? this.NewOpenAccountMarginReset : null),
      NewOpenAccountEquity: ((this.NewOpenAccountEquity != null) ? this.NewOpenAccountEquity : null),
      NewOpenAccountEquityReset: ((this.NewOpenAccountEquityReset != null) ? this.NewOpenAccountEquityReset : null),
      NewOpenAccountCredit: ((this.NewOpenAccountCredit != null) ? this.NewOpenAccountCredit : null),
      NewOpenAccountCreditReset: ((this.NewOpenAccountCreditReset != null) ? this.NewOpenAccountCreditReset : null),
      NewOpenAmount: ((this.NewOpenAmount != null) ? this.NewOpenAmount : null),
      NewOpenAmountReset: ((this.NewOpenAmountReset != null) ? this.NewOpenAmountReset : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderModifyRequest} other Object value
   * @returns {!OrderModifyRequest} Created struct
   */
  static fromObject (other) {
    return new OrderModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6223
  }
}

export { OrderModifyRequest };

/**
 * Fast Binary Encoding OrderModifyRequest field model
 */
class FieldModelOrderModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.OrderId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Login = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize), buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize)
    this.ClientOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.NewParentOrderId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize), buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize)
    this.NewParentOrderIdReset = new fbe.FieldModelBool(buffer, this.NewParentOrderId.fbeOffset + this.NewParentOrderId.fbeSize)
    this.NewSymbolId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewParentOrderIdReset.fbeOffset + this.NewParentOrderIdReset.fbeSize), buffer, this.NewParentOrderIdReset.fbeOffset + this.NewParentOrderIdReset.fbeSize)
    this.NewSymbolName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSymbolId.fbeOffset + this.NewSymbolId.fbeSize), buffer, this.NewSymbolId.fbeOffset + this.NewSymbolId.fbeSize)
    this.NewOrderSide = new fbe.FieldModelOptional(new FieldModelOrderSide(buffer, this.NewSymbolName.fbeOffset + this.NewSymbolName.fbeSize), buffer, this.NewSymbolName.fbeOffset + this.NewSymbolName.fbeSize)
    this.NewOrderType = new fbe.FieldModelOptional(new FieldModelOrderType(buffer, this.NewOrderSide.fbeOffset + this.NewOrderSide.fbeSize), buffer, this.NewOrderSide.fbeOffset + this.NewOrderSide.fbeSize)
    this.NewOrderTimeInForce = new fbe.FieldModelOptional(new FieldModelOrderTimeInForce(buffer, this.NewOrderType.fbeOffset + this.NewOrderType.fbeSize), buffer, this.NewOrderType.fbeOffset + this.NewOrderType.fbeSize)
    this.NewOrderTimeInForceReset = new fbe.FieldModelBool(buffer, this.NewOrderTimeInForce.fbeOffset + this.NewOrderTimeInForce.fbeSize)
    this.NewPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewOrderTimeInForceReset.fbeOffset + this.NewOrderTimeInForceReset.fbeSize), buffer, this.NewOrderTimeInForceReset.fbeOffset + this.NewOrderTimeInForceReset.fbeSize)
    this.NewPriceReset = new fbe.FieldModelBool(buffer, this.NewPrice.fbeOffset + this.NewPrice.fbeSize)
    this.NewStopPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewPriceReset.fbeOffset + this.NewPriceReset.fbeSize), buffer, this.NewPriceReset.fbeOffset + this.NewPriceReset.fbeSize)
    this.NewStopPriceReset = new fbe.FieldModelBool(buffer, this.NewStopPrice.fbeOffset + this.NewStopPrice.fbeSize)
    this.NewTakeProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewStopPriceReset.fbeOffset + this.NewStopPriceReset.fbeSize), buffer, this.NewStopPriceReset.fbeOffset + this.NewStopPriceReset.fbeSize)
    this.NewTakeProfitReset = new fbe.FieldModelBool(buffer, this.NewTakeProfit.fbeOffset + this.NewTakeProfit.fbeSize)
    this.NewStopLoss = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewTakeProfitReset.fbeOffset + this.NewTakeProfitReset.fbeSize), buffer, this.NewTakeProfitReset.fbeOffset + this.NewTakeProfitReset.fbeSize)
    this.NewStopLossReset = new fbe.FieldModelBool(buffer, this.NewStopLoss.fbeOffset + this.NewStopLoss.fbeSize)
    this.NewAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewStopLossReset.fbeOffset + this.NewStopLossReset.fbeSize), buffer, this.NewStopLossReset.fbeOffset + this.NewStopLossReset.fbeSize)
    this.NewExecutedAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewAmount.fbeOffset + this.NewAmount.fbeSize), buffer, this.NewAmount.fbeOffset + this.NewAmount.fbeSize)
    this.NewRemainingAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewExecutedAmount.fbeOffset + this.NewExecutedAmount.fbeSize), buffer, this.NewExecutedAmount.fbeOffset + this.NewExecutedAmount.fbeSize)
    this.NewExpiration = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.NewRemainingAmount.fbeOffset + this.NewRemainingAmount.fbeSize), buffer, this.NewRemainingAmount.fbeOffset + this.NewRemainingAmount.fbeSize)
    this.NewExpirationReset = new fbe.FieldModelBool(buffer, this.NewExpiration.fbeOffset + this.NewExpiration.fbeSize)
    this.NewAccountComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewExpirationReset.fbeOffset + this.NewExpirationReset.fbeSize), buffer, this.NewExpirationReset.fbeOffset + this.NewExpirationReset.fbeSize)
    this.NewManagerComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewAccountComment.fbeOffset + this.NewAccountComment.fbeSize), buffer, this.NewAccountComment.fbeOffset + this.NewAccountComment.fbeSize)
    this.NewMagic = new fbe.FieldModelOptional(new fbe.FieldModelInt32(buffer, this.NewManagerComment.fbeOffset + this.NewManagerComment.fbeSize), buffer, this.NewManagerComment.fbeOffset + this.NewManagerComment.fbeSize)
    this.NewMagicReset = new fbe.FieldModelBool(buffer, this.NewMagic.fbeOffset + this.NewMagic.fbeSize)
    this.NewOpenPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewMagicReset.fbeOffset + this.NewMagicReset.fbeSize), buffer, this.NewMagicReset.fbeOffset + this.NewMagicReset.fbeSize)
    this.NewOpenPriceReset = new fbe.FieldModelBool(buffer, this.NewOpenPrice.fbeOffset + this.NewOpenPrice.fbeSize)
    this.NewClosePrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewOpenPriceReset.fbeOffset + this.NewOpenPriceReset.fbeSize), buffer, this.NewOpenPriceReset.fbeOffset + this.NewOpenPriceReset.fbeSize)
    this.NewClosePriceReset = new fbe.FieldModelBool(buffer, this.NewClosePrice.fbeOffset + this.NewClosePrice.fbeSize)
    this.NewCloseAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewClosePriceReset.fbeOffset + this.NewClosePriceReset.fbeSize), buffer, this.NewClosePriceReset.fbeOffset + this.NewClosePriceReset.fbeSize)
    this.NewCloseAmountReset = new fbe.FieldModelBool(buffer, this.NewCloseAmount.fbeOffset + this.NewCloseAmount.fbeSize)
    this.NewOpenMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewCloseAmountReset.fbeOffset + this.NewCloseAmountReset.fbeSize), buffer, this.NewCloseAmountReset.fbeOffset + this.NewCloseAmountReset.fbeSize)
    this.NewOpenMarginRateReset = new fbe.FieldModelBool(buffer, this.NewOpenMarginRate.fbeOffset + this.NewOpenMarginRate.fbeSize)
    this.NewOrderStatus = new fbe.FieldModelOptional(new FieldModelOrderStatus(buffer, this.NewOpenMarginRateReset.fbeOffset + this.NewOpenMarginRateReset.fbeSize), buffer, this.NewOpenMarginRateReset.fbeOffset + this.NewOpenMarginRateReset.fbeSize)
    this.NewOpenAccountMargin = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewOrderStatus.fbeOffset + this.NewOrderStatus.fbeSize), buffer, this.NewOrderStatus.fbeOffset + this.NewOrderStatus.fbeSize)
    this.NewOpenAccountMarginReset = new fbe.FieldModelBool(buffer, this.NewOpenAccountMargin.fbeOffset + this.NewOpenAccountMargin.fbeSize)
    this.NewOpenAccountEquity = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewOpenAccountMarginReset.fbeOffset + this.NewOpenAccountMarginReset.fbeSize), buffer, this.NewOpenAccountMarginReset.fbeOffset + this.NewOpenAccountMarginReset.fbeSize)
    this.NewOpenAccountEquityReset = new fbe.FieldModelBool(buffer, this.NewOpenAccountEquity.fbeOffset + this.NewOpenAccountEquity.fbeSize)
    this.NewOpenAccountCredit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewOpenAccountEquityReset.fbeOffset + this.NewOpenAccountEquityReset.fbeSize), buffer, this.NewOpenAccountEquityReset.fbeOffset + this.NewOpenAccountEquityReset.fbeSize)
    this.NewOpenAccountCreditReset = new fbe.FieldModelBool(buffer, this.NewOpenAccountCredit.fbeOffset + this.NewOpenAccountCredit.fbeSize)
    this.NewOpenAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewOpenAccountCreditReset.fbeOffset + this.NewOpenAccountCreditReset.fbeSize), buffer, this.NewOpenAccountCreditReset.fbeOffset + this.NewOpenAccountCreditReset.fbeSize)
    this.NewOpenAmountReset = new fbe.FieldModelBool(buffer, this.NewOpenAmount.fbeOffset + this.NewOpenAmount.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelOrderModifyRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.OrderId.fbeSize + this.Login.fbeSize + this.ClientOrderId.fbeSize + this.NewParentOrderId.fbeSize + this.NewParentOrderIdReset.fbeSize + this.NewSymbolId.fbeSize + this.NewSymbolName.fbeSize + this.NewOrderSide.fbeSize + this.NewOrderType.fbeSize + this.NewOrderTimeInForce.fbeSize + this.NewOrderTimeInForceReset.fbeSize + this.NewPrice.fbeSize + this.NewPriceReset.fbeSize + this.NewStopPrice.fbeSize + this.NewStopPriceReset.fbeSize + this.NewTakeProfit.fbeSize + this.NewTakeProfitReset.fbeSize + this.NewStopLoss.fbeSize + this.NewStopLossReset.fbeSize + this.NewAmount.fbeSize + this.NewExecutedAmount.fbeSize + this.NewRemainingAmount.fbeSize + this.NewExpiration.fbeSize + this.NewExpirationReset.fbeSize + this.NewAccountComment.fbeSize + this.NewManagerComment.fbeSize + this.NewMagic.fbeSize + this.NewMagicReset.fbeSize + this.NewOpenPrice.fbeSize + this.NewOpenPriceReset.fbeSize + this.NewClosePrice.fbeSize + this.NewClosePriceReset.fbeSize + this.NewCloseAmount.fbeSize + this.NewCloseAmountReset.fbeSize + this.NewOpenMarginRate.fbeSize + this.NewOpenMarginRateReset.fbeSize + this.NewOrderStatus.fbeSize + this.NewOpenAccountMargin.fbeSize + this.NewOpenAccountMarginReset.fbeSize + this.NewOpenAccountEquity.fbeSize + this.NewOpenAccountEquityReset.fbeSize + this.NewOpenAccountCredit.fbeSize + this.NewOpenAccountCreditReset.fbeSize + this.NewOpenAmount.fbeSize + this.NewOpenAmountReset.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.OrderId.fbeExtra + this.Login.fbeExtra + this.ClientOrderId.fbeExtra + this.NewParentOrderId.fbeExtra + this.NewParentOrderIdReset.fbeExtra + this.NewSymbolId.fbeExtra + this.NewSymbolName.fbeExtra + this.NewOrderSide.fbeExtra + this.NewOrderType.fbeExtra + this.NewOrderTimeInForce.fbeExtra + this.NewOrderTimeInForceReset.fbeExtra + this.NewPrice.fbeExtra + this.NewPriceReset.fbeExtra + this.NewStopPrice.fbeExtra + this.NewStopPriceReset.fbeExtra + this.NewTakeProfit.fbeExtra + this.NewTakeProfitReset.fbeExtra + this.NewStopLoss.fbeExtra + this.NewStopLossReset.fbeExtra + this.NewAmount.fbeExtra + this.NewExecutedAmount.fbeExtra + this.NewRemainingAmount.fbeExtra + this.NewExpiration.fbeExtra + this.NewExpirationReset.fbeExtra + this.NewAccountComment.fbeExtra + this.NewManagerComment.fbeExtra + this.NewMagic.fbeExtra + this.NewMagicReset.fbeExtra + this.NewOpenPrice.fbeExtra + this.NewOpenPriceReset.fbeExtra + this.NewClosePrice.fbeExtra + this.NewClosePriceReset.fbeExtra + this.NewCloseAmount.fbeExtra + this.NewCloseAmountReset.fbeExtra + this.NewOpenMarginRate.fbeExtra + this.NewOpenMarginRateReset.fbeExtra + this.NewOrderStatus.fbeExtra + this.NewOpenAccountMargin.fbeExtra + this.NewOpenAccountMarginReset.fbeExtra + this.NewOpenAccountEquity.fbeExtra + this.NewOpenAccountEquityReset.fbeExtra + this.NewOpenAccountCredit.fbeExtra + this.NewOpenAccountCreditReset.fbeExtra + this.NewOpenAmount.fbeExtra + this.NewOpenAmountReset.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6223
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.OrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.NewParentOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewParentOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewParentOrderId.fbeSize

    if ((fbeCurrentSize + this.NewParentOrderIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewParentOrderIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewParentOrderIdReset.fbeSize

    if ((fbeCurrentSize + this.NewSymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolId.fbeSize

    if ((fbeCurrentSize + this.NewSymbolName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolName.fbeSize

    if ((fbeCurrentSize + this.NewOrderSide.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOrderSide.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderSide.fbeSize

    if ((fbeCurrentSize + this.NewOrderType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOrderType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderType.fbeSize

    if ((fbeCurrentSize + this.NewOrderTimeInForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOrderTimeInForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.NewOrderTimeInForceReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOrderTimeInForceReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderTimeInForceReset.fbeSize

    if ((fbeCurrentSize + this.NewPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrice.fbeSize

    if ((fbeCurrentSize + this.NewPriceReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPriceReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewStopPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPrice.fbeSize

    if ((fbeCurrentSize + this.NewStopPriceReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopPriceReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewTakeProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTakeProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTakeProfit.fbeSize

    if ((fbeCurrentSize + this.NewTakeProfitReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTakeProfitReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTakeProfitReset.fbeSize

    if ((fbeCurrentSize + this.NewStopLoss.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopLoss.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopLoss.fbeSize

    if ((fbeCurrentSize + this.NewStopLossReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopLossReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopLossReset.fbeSize

    if ((fbeCurrentSize + this.NewAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAmount.fbeSize

    if ((fbeCurrentSize + this.NewExecutedAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExecutedAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExecutedAmount.fbeSize

    if ((fbeCurrentSize + this.NewRemainingAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewRemainingAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRemainingAmount.fbeSize

    if ((fbeCurrentSize + this.NewExpiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExpiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExpiration.fbeSize

    if ((fbeCurrentSize + this.NewExpirationReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExpirationReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExpirationReset.fbeSize

    if ((fbeCurrentSize + this.NewAccountComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccountComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountComment.fbeSize

    if ((fbeCurrentSize + this.NewManagerComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewManagerComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerComment.fbeSize

    if ((fbeCurrentSize + this.NewMagic.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMagic.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMagic.fbeSize

    if ((fbeCurrentSize + this.NewMagicReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMagicReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMagicReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenPrice.fbeSize

    if ((fbeCurrentSize + this.NewOpenPriceReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenPriceReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewClosePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewClosePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewClosePrice.fbeSize

    if ((fbeCurrentSize + this.NewClosePriceReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewClosePriceReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewClosePriceReset.fbeSize

    if ((fbeCurrentSize + this.NewCloseAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCloseAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCloseAmount.fbeSize

    if ((fbeCurrentSize + this.NewCloseAmountReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCloseAmountReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCloseAmountReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenMarginRate.fbeSize

    if ((fbeCurrentSize + this.NewOpenMarginRateReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenMarginRateReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenMarginRateReset.fbeSize

    if ((fbeCurrentSize + this.NewOrderStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOrderStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderStatus.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenAccountMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountMargin.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountMarginReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenAccountMarginReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountMarginReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountEquity.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenAccountEquity.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountEquity.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountEquityReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenAccountEquityReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountEquityReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountCredit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenAccountCredit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountCredit.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountCreditReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenAccountCreditReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountCreditReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAmount.fbeSize

    if ((fbeCurrentSize + this.NewOpenAmountReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOpenAmountReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAmountReset.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderModifyRequest}
   * @param {!OrderModifyRequest} fbeValue Default value, defaults is new OrderModifyRequest()
   * @returns {!OrderModifyRequest} OrderModifyRequest value
   */
  get (fbeValue = new OrderModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderModifyRequest}
   * @param {!OrderModifyRequest} fbeValue OrderModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.OrderId.fbeSize) <= fbeStructSize) {
      fbeValue.OrderId = this.OrderId.get()
    } else {
      fbeValue.OrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.ClientOrderId = this.ClientOrderId.get()
    } else {
      fbeValue.ClientOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.NewParentOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.NewParentOrderId = this.NewParentOrderId.get()
    } else {
      fbeValue.NewParentOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewParentOrderId.fbeSize

    if ((fbeCurrentSize + this.NewParentOrderIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewParentOrderIdReset = this.NewParentOrderIdReset.get()
    } else {
      fbeValue.NewParentOrderIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewParentOrderIdReset.fbeSize

    if ((fbeCurrentSize + this.NewSymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.NewSymbolId = this.NewSymbolId.get()
    } else {
      fbeValue.NewSymbolId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolId.fbeSize

    if ((fbeCurrentSize + this.NewSymbolName.fbeSize) <= fbeStructSize) {
      fbeValue.NewSymbolName = this.NewSymbolName.get()
    } else {
      fbeValue.NewSymbolName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolName.fbeSize

    if ((fbeCurrentSize + this.NewOrderSide.fbeSize) <= fbeStructSize) {
      fbeValue.NewOrderSide = this.NewOrderSide.get()
    } else {
      fbeValue.NewOrderSide = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderSide.fbeSize

    if ((fbeCurrentSize + this.NewOrderType.fbeSize) <= fbeStructSize) {
      fbeValue.NewOrderType = this.NewOrderType.get()
    } else {
      fbeValue.NewOrderType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderType.fbeSize

    if ((fbeCurrentSize + this.NewOrderTimeInForce.fbeSize) <= fbeStructSize) {
      fbeValue.NewOrderTimeInForce = this.NewOrderTimeInForce.get()
    } else {
      fbeValue.NewOrderTimeInForce = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.NewOrderTimeInForceReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewOrderTimeInForceReset = this.NewOrderTimeInForceReset.get()
    } else {
      fbeValue.NewOrderTimeInForceReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderTimeInForceReset.fbeSize

    if ((fbeCurrentSize + this.NewPrice.fbeSize) <= fbeStructSize) {
      fbeValue.NewPrice = this.NewPrice.get()
    } else {
      fbeValue.NewPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrice.fbeSize

    if ((fbeCurrentSize + this.NewPriceReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewPriceReset = this.NewPriceReset.get()
    } else {
      fbeValue.NewPriceReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewStopPrice.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopPrice = this.NewStopPrice.get()
    } else {
      fbeValue.NewStopPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPrice.fbeSize

    if ((fbeCurrentSize + this.NewStopPriceReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopPriceReset = this.NewStopPriceReset.get()
    } else {
      fbeValue.NewStopPriceReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewTakeProfit.fbeSize) <= fbeStructSize) {
      fbeValue.NewTakeProfit = this.NewTakeProfit.get()
    } else {
      fbeValue.NewTakeProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTakeProfit.fbeSize

    if ((fbeCurrentSize + this.NewTakeProfitReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewTakeProfitReset = this.NewTakeProfitReset.get()
    } else {
      fbeValue.NewTakeProfitReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTakeProfitReset.fbeSize

    if ((fbeCurrentSize + this.NewStopLoss.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopLoss = this.NewStopLoss.get()
    } else {
      fbeValue.NewStopLoss = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopLoss.fbeSize

    if ((fbeCurrentSize + this.NewStopLossReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopLossReset = this.NewStopLossReset.get()
    } else {
      fbeValue.NewStopLossReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopLossReset.fbeSize

    if ((fbeCurrentSize + this.NewAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewAmount = this.NewAmount.get()
    } else {
      fbeValue.NewAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAmount.fbeSize

    if ((fbeCurrentSize + this.NewExecutedAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewExecutedAmount = this.NewExecutedAmount.get()
    } else {
      fbeValue.NewExecutedAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExecutedAmount.fbeSize

    if ((fbeCurrentSize + this.NewRemainingAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewRemainingAmount = this.NewRemainingAmount.get()
    } else {
      fbeValue.NewRemainingAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRemainingAmount.fbeSize

    if ((fbeCurrentSize + this.NewExpiration.fbeSize) <= fbeStructSize) {
      fbeValue.NewExpiration = this.NewExpiration.get()
    } else {
      fbeValue.NewExpiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExpiration.fbeSize

    if ((fbeCurrentSize + this.NewExpirationReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewExpirationReset = this.NewExpirationReset.get()
    } else {
      fbeValue.NewExpirationReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExpirationReset.fbeSize

    if ((fbeCurrentSize + this.NewAccountComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewAccountComment = this.NewAccountComment.get()
    } else {
      fbeValue.NewAccountComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountComment.fbeSize

    if ((fbeCurrentSize + this.NewManagerComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewManagerComment = this.NewManagerComment.get()
    } else {
      fbeValue.NewManagerComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerComment.fbeSize

    if ((fbeCurrentSize + this.NewMagic.fbeSize) <= fbeStructSize) {
      fbeValue.NewMagic = this.NewMagic.get()
    } else {
      fbeValue.NewMagic = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMagic.fbeSize

    if ((fbeCurrentSize + this.NewMagicReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewMagicReset = this.NewMagicReset.get()
    } else {
      fbeValue.NewMagicReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMagicReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenPrice.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenPrice = this.NewOpenPrice.get()
    } else {
      fbeValue.NewOpenPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenPrice.fbeSize

    if ((fbeCurrentSize + this.NewOpenPriceReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenPriceReset = this.NewOpenPriceReset.get()
    } else {
      fbeValue.NewOpenPriceReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewClosePrice.fbeSize) <= fbeStructSize) {
      fbeValue.NewClosePrice = this.NewClosePrice.get()
    } else {
      fbeValue.NewClosePrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewClosePrice.fbeSize

    if ((fbeCurrentSize + this.NewClosePriceReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewClosePriceReset = this.NewClosePriceReset.get()
    } else {
      fbeValue.NewClosePriceReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewClosePriceReset.fbeSize

    if ((fbeCurrentSize + this.NewCloseAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewCloseAmount = this.NewCloseAmount.get()
    } else {
      fbeValue.NewCloseAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCloseAmount.fbeSize

    if ((fbeCurrentSize + this.NewCloseAmountReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewCloseAmountReset = this.NewCloseAmountReset.get()
    } else {
      fbeValue.NewCloseAmountReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCloseAmountReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenMarginRate = this.NewOpenMarginRate.get()
    } else {
      fbeValue.NewOpenMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenMarginRate.fbeSize

    if ((fbeCurrentSize + this.NewOpenMarginRateReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenMarginRateReset = this.NewOpenMarginRateReset.get()
    } else {
      fbeValue.NewOpenMarginRateReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenMarginRateReset.fbeSize

    if ((fbeCurrentSize + this.NewOrderStatus.fbeSize) <= fbeStructSize) {
      fbeValue.NewOrderStatus = this.NewOrderStatus.get()
    } else {
      fbeValue.NewOrderStatus = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderStatus.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountMargin.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenAccountMargin = this.NewOpenAccountMargin.get()
    } else {
      fbeValue.NewOpenAccountMargin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountMargin.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountMarginReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenAccountMarginReset = this.NewOpenAccountMarginReset.get()
    } else {
      fbeValue.NewOpenAccountMarginReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountMarginReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountEquity.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenAccountEquity = this.NewOpenAccountEquity.get()
    } else {
      fbeValue.NewOpenAccountEquity = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountEquity.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountEquityReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenAccountEquityReset = this.NewOpenAccountEquityReset.get()
    } else {
      fbeValue.NewOpenAccountEquityReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountEquityReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountCredit.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenAccountCredit = this.NewOpenAccountCredit.get()
    } else {
      fbeValue.NewOpenAccountCredit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountCredit.fbeSize

    if ((fbeCurrentSize + this.NewOpenAccountCreditReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenAccountCreditReset = this.NewOpenAccountCreditReset.get()
    } else {
      fbeValue.NewOpenAccountCreditReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAccountCreditReset.fbeSize

    if ((fbeCurrentSize + this.NewOpenAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenAmount = this.NewOpenAmount.get()
    } else {
      fbeValue.NewOpenAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAmount.fbeSize

    if ((fbeCurrentSize + this.NewOpenAmountReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewOpenAmountReset = this.NewOpenAmountReset.get()
    } else {
      fbeValue.NewOpenAmountReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOpenAmountReset.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderModifyRequest}
   * @param {!OrderModifyRequest} fbeValue OrderModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderModifyRequest}
   * @param {OrderModifyRequest} fbeValue OrderModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.OrderId.set(fbeValue.OrderId)
    this.Login.set(fbeValue.Login)
    this.ClientOrderId.set(fbeValue.ClientOrderId)
    this.NewParentOrderId.set(fbeValue.NewParentOrderId)
    this.NewParentOrderIdReset.set(fbeValue.NewParentOrderIdReset)
    this.NewSymbolId.set(fbeValue.NewSymbolId)
    this.NewSymbolName.set(fbeValue.NewSymbolName)
    this.NewOrderSide.set(fbeValue.NewOrderSide)
    this.NewOrderType.set(fbeValue.NewOrderType)
    this.NewOrderTimeInForce.set(fbeValue.NewOrderTimeInForce)
    this.NewOrderTimeInForceReset.set(fbeValue.NewOrderTimeInForceReset)
    this.NewPrice.set(fbeValue.NewPrice)
    this.NewPriceReset.set(fbeValue.NewPriceReset)
    this.NewStopPrice.set(fbeValue.NewStopPrice)
    this.NewStopPriceReset.set(fbeValue.NewStopPriceReset)
    this.NewTakeProfit.set(fbeValue.NewTakeProfit)
    this.NewTakeProfitReset.set(fbeValue.NewTakeProfitReset)
    this.NewStopLoss.set(fbeValue.NewStopLoss)
    this.NewStopLossReset.set(fbeValue.NewStopLossReset)
    this.NewAmount.set(fbeValue.NewAmount)
    this.NewExecutedAmount.set(fbeValue.NewExecutedAmount)
    this.NewRemainingAmount.set(fbeValue.NewRemainingAmount)
    this.NewExpiration.set(fbeValue.NewExpiration)
    this.NewExpirationReset.set(fbeValue.NewExpirationReset)
    this.NewAccountComment.set(fbeValue.NewAccountComment)
    this.NewManagerComment.set(fbeValue.NewManagerComment)
    this.NewMagic.set(fbeValue.NewMagic)
    this.NewMagicReset.set(fbeValue.NewMagicReset)
    this.NewOpenPrice.set(fbeValue.NewOpenPrice)
    this.NewOpenPriceReset.set(fbeValue.NewOpenPriceReset)
    this.NewClosePrice.set(fbeValue.NewClosePrice)
    this.NewClosePriceReset.set(fbeValue.NewClosePriceReset)
    this.NewCloseAmount.set(fbeValue.NewCloseAmount)
    this.NewCloseAmountReset.set(fbeValue.NewCloseAmountReset)
    this.NewOpenMarginRate.set(fbeValue.NewOpenMarginRate)
    this.NewOpenMarginRateReset.set(fbeValue.NewOpenMarginRateReset)
    this.NewOrderStatus.set(fbeValue.NewOrderStatus)
    this.NewOpenAccountMargin.set(fbeValue.NewOpenAccountMargin)
    this.NewOpenAccountMarginReset.set(fbeValue.NewOpenAccountMarginReset)
    this.NewOpenAccountEquity.set(fbeValue.NewOpenAccountEquity)
    this.NewOpenAccountEquityReset.set(fbeValue.NewOpenAccountEquityReset)
    this.NewOpenAccountCredit.set(fbeValue.NewOpenAccountCredit)
    this.NewOpenAccountCreditReset.set(fbeValue.NewOpenAccountCreditReset)
    this.NewOpenAmount.set(fbeValue.NewOpenAmount)
    this.NewOpenAmountReset.set(fbeValue.NewOpenAmountReset)
  }
}

export { FieldModelOrderModifyRequest };

/**
 * Fast Binary Encoding OrderModifyRequest model
 */
class OrderModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderModifyRequest(this.buffer, 4)
  }

  /**
   * Get the OrderModifyRequest model
   * @this {!OrderModifyRequestModel}
   * @returns {!FieldModelOrderModifyRequest} model OrderModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderModifyRequestModel}
   * @param {!OrderModifyRequest} value OrderModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderModifyRequestModel}
   * @param {!OrderModifyRequest} value OrderModifyRequest value, defaults is new OrderModifyRequest()
   * @return {!object} Deserialized OrderModifyRequest value and its size
   */
  deserialize (value = new OrderModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderModifyRequestModel}
   * @param {!number} prev Previous OrderModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderModifyRequestModel };
OrderModifyRequest.__has_response = true;
OrderModifyRequest.__response_class = OrderModifyResponse;
