
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * RejectCode enum
 */
class RejectCode {
  /**
   * Initialize enum with a given value
   * @param {RejectCode|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof RejectCode) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!RejectCode}
   * @param {!RejectCode} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof RejectCode)) {
      throw new TypeError('Instance of RejectCode is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!RejectCode}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!RejectCode}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === RejectCode.UNKNOWN.value) {
      return 'UNKNOWN'
    }
    if (this.value === RejectCode.SUCCESS.value) {
      return 'SUCCESS'
    }
    if (this.value === RejectCode.TIMEOUT.value) {
      return 'TIMEOUT'
    }
    if (this.value === RejectCode.THROTTLING.value) {
      return 'THROTTLING'
    }
    if (this.value === RejectCode.EXCEPTION.value) {
      return 'EXCEPTION'
    }
    if (this.value === RejectCode.CONFLICT.value) {
      return 'CONFLICT'
    }
    if (this.value === RejectCode.NOT_FOUND.value) {
      return 'NOT_FOUND'
    }
    if (this.value === RejectCode.INVALID_KEY.value) {
      return 'INVALID_KEY'
    }
    if (this.value === RejectCode.INVALID_VALUE.value) {
      return 'INVALID_VALUE'
    }
    if (this.value === RejectCode.INVALID_LOGIN.value) {
      return 'INVALID_LOGIN'
    }
    if (this.value === RejectCode.INVALID_PASSWORD.value) {
      return 'INVALID_PASSWORD'
    }
    if (this.value === RejectCode.INVALID_OTP_TOKEN.value) {
      return 'INVALID_OTP_TOKEN'
    }
    if (this.value === RejectCode.INVALID_AUTH_TOKEN.value) {
      return 'INVALID_AUTH_TOKEN'
    }
    if (this.value === RejectCode.INVALID_OPERATION.value) {
      return 'INVALID_OPERATION'
    }
    if (this.value === RejectCode.INVALID_PROTOCOL.value) {
      return 'INVALID_PROTOCOL'
    }
    if (this.value === RejectCode.INVALID_REQUEST.value) {
      return 'INVALID_REQUEST'
    }
    if (this.value === RejectCode.INVALID_RESPONSE.value) {
      return 'INVALID_RESPONSE'
    }
    if (this.value === RejectCode.INVALID_SERIALIZATION.value) {
      return 'INVALID_SERIALIZATION'
    }
    if (this.value === RejectCode.INVALID_DESERIALIZATION.value) {
      return 'INVALID_DESERIALIZATION'
    }
    if (this.value === RejectCode.INVALID_SESSION.value) {
      return 'INVALID_SESSION'
    }
    if (this.value === RejectCode.INVALID_CACHE.value) {
      return 'INVALID_CACHE'
    }
    if (this.value === RejectCode.INVALID_CLIENT_VERSION.value) {
      return 'INVALID_CLIENT_VERSION'
    }
    if (this.value === RejectCode.OPERATION_NOT_SUPPORTED.value) {
      return 'OPERATION_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.OPERATION_TEMPORARILY_NOT_AVAILABLE.value) {
      return 'OPERATION_TEMPORARILY_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.EXTERNAL_LOGIN_NOT_AVAILABLE.value) {
      return 'EXTERNAL_LOGIN_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.PROTOCOL_REQUIRED.value) {
      return 'PROTOCOL_REQUIRED'
    }
    if (this.value === RejectCode.LOGIN_REQUIRED.value) {
      return 'LOGIN_REQUIRED'
    }
    if (this.value === RejectCode.PASSWORD_REQUIRED.value) {
      return 'PASSWORD_REQUIRED'
    }
    if (this.value === RejectCode.OTP_TOKEN_REQUIRED.value) {
      return 'OTP_TOKEN_REQUIRED'
    }
    if (this.value === RejectCode.EXTERNAL_LOGIN_NOT_CONFIGURED.value) {
      return 'EXTERNAL_LOGIN_NOT_CONFIGURED'
    }
    if (this.value === RejectCode.EXTERNAL_LOGIN_NOT_CONNECTED.value) {
      return 'EXTERNAL_LOGIN_NOT_CONNECTED'
    }
    if (this.value === RejectCode.EXTERNAL_LOGIN_TIMEOUT.value) {
      return 'EXTERNAL_LOGIN_TIMEOUT'
    }
    if (this.value === RejectCode.AUTH_TOKEN_EXPIRED.value) {
      return 'AUTH_TOKEN_EXPIRED'
    }
    if (this.value === RejectCode.CLIENT_VERSION_REQUIRED.value) {
      return 'CLIENT_VERSION_REQUIRED'
    }
    if (this.value === RejectCode.SESSION_NOT_INITIALIZED.value) {
      return 'SESSION_NOT_INITIALIZED'
    }
    if (this.value === RejectCode.SSL_CONNECTION_FORBIDDEN.value) {
      return 'SSL_CONNECTION_FORBIDDEN'
    }
    if (this.value === RejectCode.HTTPS_CONNECTION_FORBIDDEN.value) {
      return 'HTTPS_CONNECTION_FORBIDDEN'
    }
    if (this.value === RejectCode.WSS_CONNECTION_FORBIDDEN.value) {
      return 'WSS_CONNECTION_FORBIDDEN'
    }
    if (this.value === RejectCode.FIX_CONNECTION_FORBIDDEN.value) {
      return 'FIX_CONNECTION_FORBIDDEN'
    }
    if (this.value === RejectCode.SECURITY_NOT_ALLOWED.value) {
      return 'SECURITY_NOT_ALLOWED'
    }
    if (this.value === RejectCode.SECURITY_NOT_AUTHENICATED.value) {
      return 'SECURITY_NOT_AUTHENICATED'
    }
    if (this.value === RejectCode.SECURITY_NOT_AUTHORIZED.value) {
      return 'SECURITY_NOT_AUTHORIZED'
    }
    if (this.value === RejectCode.SECURITY_NOT_REGISTERED.value) {
      return 'SECURITY_NOT_REGISTERED'
    }
    if (this.value === RejectCode.SECURITY_WEAK_PASSWORD.value) {
      return 'SECURITY_WEAK_PASSWORD'
    }
    if (this.value === RejectCode.CONFIG_NOT_AVAILABLE.value) {
      return 'CONFIG_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.CONFIG_INVALID_KEY.value) {
      return 'CONFIG_INVALID_KEY'
    }
    if (this.value === RejectCode.CONFIG_INVALID_VALUE.value) {
      return 'CONFIG_INVALID_VALUE'
    }
    if (this.value === RejectCode.CONFIG_REFRESH_FAILED.value) {
      return 'CONFIG_REFRESH_FAILED'
    }
    if (this.value === RejectCode.CONFIG_HISTORY_FAILED.value) {
      return 'CONFIG_HISTORY_FAILED'
    }
    if (this.value === RejectCode.CONFIG_ROLLBACK_FAILED.value) {
      return 'CONFIG_ROLLBACK_FAILED'
    }
    if (this.value === RejectCode.CONFIG_STORAGE_NOT_AVAILABLE.value) {
      return 'CONFIG_STORAGE_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.CONFIG_STORAGE_NOT_STARTED.value) {
      return 'CONFIG_STORAGE_NOT_STARTED'
    }
    if (this.value === RejectCode.CONFIG_STORAGE_NOT_SUPPORTED.value) {
      return 'CONFIG_STORAGE_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.CONFIG_UPDATE_DUPLICATE.value) {
      return 'CONFIG_UPDATE_DUPLICATE'
    }
    if (this.value === RejectCode.CONFIG_UPDATE_NOT_FOUND.value) {
      return 'CONFIG_UPDATE_NOT_FOUND'
    }
    if (this.value === RejectCode.CONFIG_VERSION_CONFLICT.value) {
      return 'CONFIG_VERSION_CONFLICT'
    }
    if (this.value === RejectCode.FEED_NOT_AVAILABLE.value) {
      return 'FEED_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.FEED_NOT_STARTED.value) {
      return 'FEED_NOT_STARTED'
    }
    if (this.value === RejectCode.FEED_NOT_SUPPORTED.value) {
      return 'FEED_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.FEED_NOT_SUBSCRIBED.value) {
      return 'FEED_NOT_SUBSCRIBED'
    }
    if (this.value === RejectCode.FEED_NOT_UNSUBSCRIBED.value) {
      return 'FEED_NOT_UNSUBSCRIBED'
    }
    if (this.value === RejectCode.FEED_VERSION_CONFLICT.value) {
      return 'FEED_VERSION_CONFLICT'
    }
    if (this.value === RejectCode.HISTORY_NOT_AVAILABLE.value) {
      return 'HISTORY_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.HISTORY_NOT_STARTED.value) {
      return 'HISTORY_NOT_STARTED'
    }
    if (this.value === RejectCode.HISTORY_NOT_SUPPORTED.value) {
      return 'HISTORY_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.HISTORY_VERSION_CONFLICT.value) {
      return 'HISTORY_VERSION_CONFLICT'
    }
    if (this.value === RejectCode.STORAGE_NOT_AVAILABLE.value) {
      return 'STORAGE_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.STORAGE_NOT_STARTED.value) {
      return 'STORAGE_NOT_STARTED'
    }
    if (this.value === RejectCode.STORAGE_NOT_SUPPORTED.value) {
      return 'STORAGE_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.STORAGE_VERSION_CONFLICT.value) {
      return 'STORAGE_VERSION_CONFLICT'
    }
    if (this.value === RejectCode.STORAGE_INSERT_FAILED.value) {
      return 'STORAGE_INSERT_FAILED'
    }
    if (this.value === RejectCode.STORAGE_UPDATE_FAILED.value) {
      return 'STORAGE_UPDATE_FAILED'
    }
    if (this.value === RejectCode.STORAGE_DELETE_FAILED.value) {
      return 'STORAGE_DELETE_FAILED'
    }
    if (this.value === RejectCode.STORAGE_OPERATION_FAILED.value) {
      return 'STORAGE_OPERATION_FAILED'
    }
    if (this.value === RejectCode.TRADE_NOT_AVAILABLE.value) {
      return 'TRADE_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.TRADE_NOT_ALLOWED.value) {
      return 'TRADE_NOT_ALLOWED'
    }
    if (this.value === RejectCode.NOT_ENOUGH_BALANCE.value) {
      return 'NOT_ENOUGH_BALANCE'
    }
    if (this.value === RejectCode.NOT_ENOUGH_CREDIT.value) {
      return 'NOT_ENOUGH_CREDIT'
    }
    if (this.value === RejectCode.NOT_ENOUGH_MARGIN.value) {
      return 'NOT_ENOUGH_MARGIN'
    }
    if (this.value === RejectCode.TRADE_IS_REJECTED.value) {
      return 'TRADE_IS_REJECTED'
    }
    if (this.value === RejectCode.TRADE_REJECT_BY_MARGIN_CALL.value) {
      return 'TRADE_REJECT_BY_MARGIN_CALL'
    }
    if (this.value === RejectCode.TRADE_REJECT_BY_STOP_OUT.value) {
      return 'TRADE_REJECT_BY_STOP_OUT'
    }
    if (this.value === RejectCode.TRADE_REJECT_BY_CALENDAR.value) {
      return 'TRADE_REJECT_BY_CALENDAR'
    }
    if (this.value === RejectCode.TRADE_OPEN_ORDER_NOT_ALLOWED.value) {
      return 'TRADE_OPEN_ORDER_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_OPEN_BUY_ORDER_NOT_ALLOWED.value) {
      return 'TRADE_OPEN_BUY_ORDER_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_OPEN_SELL_ORDER_NOT_ALLOWED.value) {
      return 'TRADE_OPEN_SELL_ORDER_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_MODIFY_ORDER_NOT_ALLOWED.value) {
      return 'TRADE_MODIFY_ORDER_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_ACTIVATE_ORDER_NOT_ALLOWED.value) {
      return 'TRADE_ACTIVATE_ORDER_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_CANCEL_ORDER_NOT_ALLOWED.value) {
      return 'TRADE_CANCEL_ORDER_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_CLOSE_ORDER_NOT_ALLOWED.value) {
      return 'TRADE_CLOSE_ORDER_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_ACCOUNT_NOT_ALLOWED.value) {
      return 'TRADE_ACCOUNT_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_SYMBOL_NOT_ALLOWED.value) {
      return 'TRADE_SYMBOL_NOT_ALLOWED'
    }
    if (this.value === RejectCode.TRADE_ORDER_ID_GENERATION_FAILED.value) {
      return 'TRADE_ORDER_ID_GENERATION_FAILED'
    }
    if (this.value === RejectCode.TRADE_EVENT_TRANSACTION_ID_GENERATION_FAILED.value) {
      return 'TRADE_EVENT_TRANSACTION_ID_GENERATION_FAILED'
    }
    if (this.value === RejectCode.SECURITY_NOT_AVAILABLE.value) {
      return 'SECURITY_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.EXECUTION_NOT_AVAILABLE.value) {
      return 'EXECUTION_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.EXECUTION_NOT_STARTED.value) {
      return 'EXECUTION_NOT_STARTED'
    }
    if (this.value === RejectCode.EXECUTION_NOT_SUPPORTED.value) {
      return 'EXECUTION_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.EXECUTION_VERSION_CONFLICT.value) {
      return 'EXECUTION_VERSION_CONFLICT'
    }
    if (this.value === RejectCode.DRAWING_STORAGE_TECHNICAL_ERROR.value) {
      return 'DRAWING_STORAGE_TECHNICAL_ERROR'
    }
    if (this.value === RejectCode.DRAWING_STORAGE_DEPRECATED_1.value) {
      return 'DRAWING_STORAGE_DEPRECATED_1'
    }
    if (this.value === RejectCode.DRAWING_STORAGE_DASHBOARD_VERSION_CONFLICT.value) {
      return 'DRAWING_STORAGE_DASHBOARD_VERSION_CONFLICT'
    }
    if (this.value === RejectCode.DRAWING_STORAGE_DASHBOARD_NAME_CONFLICT.value) {
      return 'DRAWING_STORAGE_DASHBOARD_NAME_CONFLICT'
    }
    if (this.value === RejectCode.DRAWING_STORAGE_DASHBOARD_NOT_FOUND.value) {
      return 'DRAWING_STORAGE_DASHBOARD_NOT_FOUND'
    }
    if (this.value === RejectCode.HOST_DUPLICATE.value) {
      return 'HOST_DUPLICATE'
    }
    if (this.value === RejectCode.HOST_NOT_FOUND.value) {
      return 'HOST_NOT_FOUND'
    }
    if (this.value === RejectCode.HOST_IS_DISABLED.value) {
      return 'HOST_IS_DISABLED'
    }
    if (this.value === RejectCode.HOST_IS_USED.value) {
      return 'HOST_IS_USED'
    }
    if (this.value === RejectCode.MODULE_DUPLICATE.value) {
      return 'MODULE_DUPLICATE'
    }
    if (this.value === RejectCode.MODULE_NOT_FOUND.value) {
      return 'MODULE_NOT_FOUND'
    }
    if (this.value === RejectCode.MODULE_IS_DISABLED.value) {
      return 'MODULE_IS_DISABLED'
    }
    if (this.value === RejectCode.MODULE_IS_USED.value) {
      return 'MODULE_IS_USED'
    }
    if (this.value === RejectCode.TIMEZONE_DUPLICATE.value) {
      return 'TIMEZONE_DUPLICATE'
    }
    if (this.value === RejectCode.TIMEZONE_NOT_FOUND.value) {
      return 'TIMEZONE_NOT_FOUND'
    }
    if (this.value === RejectCode.TIMEZONE_IS_DISABLED.value) {
      return 'TIMEZONE_IS_DISABLED'
    }
    if (this.value === RejectCode.TIMEZONE_IS_USED.value) {
      return 'TIMEZONE_IS_USED'
    }
    if (this.value === RejectCode.CURRENCY_GROUP_DUPLICATE.value) {
      return 'CURRENCY_GROUP_DUPLICATE'
    }
    if (this.value === RejectCode.CURRENCY_GROUP_NOT_FOUND.value) {
      return 'CURRENCY_GROUP_NOT_FOUND'
    }
    if (this.value === RejectCode.CURRENCY_GROUP_IS_DISABLED.value) {
      return 'CURRENCY_GROUP_IS_DISABLED'
    }
    if (this.value === RejectCode.CURRENCY_GROUP_IS_USED.value) {
      return 'CURRENCY_GROUP_IS_USED'
    }
    if (this.value === RejectCode.CURRENCY_DUPLICATE.value) {
      return 'CURRENCY_DUPLICATE'
    }
    if (this.value === RejectCode.CURRENCY_NOT_FOUND.value) {
      return 'CURRENCY_NOT_FOUND'
    }
    if (this.value === RejectCode.CURRENCY_IS_DISABLED.value) {
      return 'CURRENCY_IS_DISABLED'
    }
    if (this.value === RejectCode.CURRENCY_IS_USED.value) {
      return 'CURRENCY_IS_USED'
    }
    if (this.value === RejectCode.BALANCE_CURRENCY_NOT_FOUND.value) {
      return 'BALANCE_CURRENCY_NOT_FOUND'
    }
    if (this.value === RejectCode.SYMBOL_GROUP_DUPLICATE.value) {
      return 'SYMBOL_GROUP_DUPLICATE'
    }
    if (this.value === RejectCode.SYMBOL_GROUP_NOT_FOUND.value) {
      return 'SYMBOL_GROUP_NOT_FOUND'
    }
    if (this.value === RejectCode.SYMBOL_GROUP_IS_DISABLED.value) {
      return 'SYMBOL_GROUP_IS_DISABLED'
    }
    if (this.value === RejectCode.SYMBOL_GROUP_IS_USED.value) {
      return 'SYMBOL_GROUP_IS_USED'
    }
    if (this.value === RejectCode.SYMBOL_GROUP_TRADING_NOT_DISABLED.value) {
      return 'SYMBOL_GROUP_TRADING_NOT_DISABLED'
    }
    if (this.value === RejectCode.SYMBOL_GROUP_HAS_ACTIVE_ORDERS.value) {
      return 'SYMBOL_GROUP_HAS_ACTIVE_ORDERS'
    }
    if (this.value === RejectCode.SYMBOL_DUPLICATE.value) {
      return 'SYMBOL_DUPLICATE'
    }
    if (this.value === RejectCode.SYMBOL_NOT_FOUND.value) {
      return 'SYMBOL_NOT_FOUND'
    }
    if (this.value === RejectCode.SYMBOL_IS_DISABLED.value) {
      return 'SYMBOL_IS_DISABLED'
    }
    if (this.value === RejectCode.SYMBOL_IS_USED.value) {
      return 'SYMBOL_IS_USED'
    }
    if (this.value === RejectCode.TICK_DUPLICATE.value) {
      return 'TICK_DUPLICATE'
    }
    if (this.value === RejectCode.TICK_NOT_FOUND.value) {
      return 'TICK_NOT_FOUND'
    }
    if (this.value === RejectCode.LEVEL2_DUPLICATE.value) {
      return 'LEVEL2_DUPLICATE'
    }
    if (this.value === RejectCode.LEVEL2_NOT_FOUND.value) {
      return 'LEVEL2_NOT_FOUND'
    }
    if (this.value === RejectCode.MANAGER_GROUP_DUPLICATE.value) {
      return 'MANAGER_GROUP_DUPLICATE'
    }
    if (this.value === RejectCode.MANAGER_GROUP_NOT_FOUND.value) {
      return 'MANAGER_GROUP_NOT_FOUND'
    }
    if (this.value === RejectCode.MANAGER_GROUP_IS_DISABLED.value) {
      return 'MANAGER_GROUP_IS_DISABLED'
    }
    if (this.value === RejectCode.MANAGER_GROUP_IS_USED.value) {
      return 'MANAGER_GROUP_IS_USED'
    }
    if (this.value === RejectCode.MANAGER_DUPLICATE.value) {
      return 'MANAGER_DUPLICATE'
    }
    if (this.value === RejectCode.MANAGER_NOT_FOUND.value) {
      return 'MANAGER_NOT_FOUND'
    }
    if (this.value === RejectCode.MANAGER_IS_DISABLED.value) {
      return 'MANAGER_IS_DISABLED'
    }
    if (this.value === RejectCode.MANAGER_IS_USED.value) {
      return 'MANAGER_IS_USED'
    }
    if (this.value === RejectCode.MANAGER_LOGIN_OUT_OF_RANGE.value) {
      return 'MANAGER_LOGIN_OUT_OF_RANGE'
    }
    if (this.value === RejectCode.MANAGER_LOGIN_RANGE_EXHAUSTED.value) {
      return 'MANAGER_LOGIN_RANGE_EXHAUSTED'
    }
    if (this.value === RejectCode.MANAGER_LOGIN_RANGE_OVERLAPPED.value) {
      return 'MANAGER_LOGIN_RANGE_OVERLAPPED'
    }
    if (this.value === RejectCode.PLATFORM_DUPLICATE.value) {
      return 'PLATFORM_DUPLICATE'
    }
    if (this.value === RejectCode.PLATFORM_NOT_FOUND.value) {
      return 'PLATFORM_NOT_FOUND'
    }
    if (this.value === RejectCode.PLATFORM_IS_DISABLED.value) {
      return 'PLATFORM_IS_DISABLED'
    }
    if (this.value === RejectCode.PLATFORM_IS_USED.value) {
      return 'PLATFORM_IS_USED'
    }
    if (this.value === RejectCode.ACCOUNT_GROUP_DUPLICATE.value) {
      return 'ACCOUNT_GROUP_DUPLICATE'
    }
    if (this.value === RejectCode.ACCOUNT_GROUP_NOT_FOUND.value) {
      return 'ACCOUNT_GROUP_NOT_FOUND'
    }
    if (this.value === RejectCode.ACCOUNT_GROUP_IS_DISABLED.value) {
      return 'ACCOUNT_GROUP_IS_DISABLED'
    }
    if (this.value === RejectCode.ACCOUNT_GROUP_IS_USED.value) {
      return 'ACCOUNT_GROUP_IS_USED'
    }
    if (this.value === RejectCode.ACCOUNT_DUPLICATE.value) {
      return 'ACCOUNT_DUPLICATE'
    }
    if (this.value === RejectCode.ACCOUNT_NOT_FOUND.value) {
      return 'ACCOUNT_NOT_FOUND'
    }
    if (this.value === RejectCode.ACCOUNT_NOT_SUPPORTED.value) {
      return 'ACCOUNT_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.ACCOUNT_IS_DISABLED.value) {
      return 'ACCOUNT_IS_DISABLED'
    }
    if (this.value === RejectCode.ACCOUNT_IS_USED.value) {
      return 'ACCOUNT_IS_USED'
    }
    if (this.value === RejectCode.ACCOUNT_LOGIN_INVALID.value) {
      return 'ACCOUNT_LOGIN_INVALID'
    }
    if (this.value === RejectCode.ACCOUNT_LOGIN_OUT_OF_RANGE.value) {
      return 'ACCOUNT_LOGIN_OUT_OF_RANGE'
    }
    if (this.value === RejectCode.ACCOUNT_LOGIN_RANGE_EXHAUSTED.value) {
      return 'ACCOUNT_LOGIN_RANGE_EXHAUSTED'
    }
    if (this.value === RejectCode.ACCOUNT_LOGIN_RANGE_OVERLAPPED.value) {
      return 'ACCOUNT_LOGIN_RANGE_OVERLAPPED'
    }
    if (this.value === RejectCode.ACCOUNT_MAX_DEPOSIT_EXCEEDED.value) {
      return 'ACCOUNT_MAX_DEPOSIT_EXCEEDED'
    }
    if (this.value === RejectCode.ACCOUNT_MARGIN_NOT_FOUND.value) {
      return 'ACCOUNT_MARGIN_NOT_FOUND'
    }
    if (this.value === RejectCode.ACCOUNT_MARGIN_NOT_SUPPORTED.value) {
      return 'ACCOUNT_MARGIN_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.ASSET_DUPLICATE.value) {
      return 'ASSET_DUPLICATE'
    }
    if (this.value === RejectCode.ASSET_NOT_FOUND.value) {
      return 'ASSET_NOT_FOUND'
    }
    if (this.value === RejectCode.ASSET_IS_USED.value) {
      return 'ASSET_IS_USED'
    }
    if (this.value === RejectCode.ASSET_NOT_SUPPORTED.value) {
      return 'ASSET_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.ASSET_CURRENCY_IS_EMPTY.value) {
      return 'ASSET_CURRENCY_IS_EMPTY'
    }
    if (this.value === RejectCode.ORDER_DUPLICATE.value) {
      return 'ORDER_DUPLICATE'
    }
    if (this.value === RejectCode.ORDER_NOT_FOUND.value) {
      return 'ORDER_NOT_FOUND'
    }
    if (this.value === RejectCode.ORDER_NOT_SUPPORTED.value) {
      return 'ORDER_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.ORDER_IS_USED.value) {
      return 'ORDER_IS_USED'
    }
    if (this.value === RejectCode.ORDER_SYMBOL_IS_EMPTY.value) {
      return 'ORDER_SYMBOL_IS_EMPTY'
    }
    if (this.value === RejectCode.ORDER_CLIENT_ID_LENGTH_INVALID.value) {
      return 'ORDER_CLIENT_ID_LENGTH_INVALID'
    }
    if (this.value === RejectCode.ORDER_PARENT_ORDER_ID_INVALID.value) {
      return 'ORDER_PARENT_ORDER_ID_INVALID'
    }
    if (this.value === RejectCode.ORDER_GTC_TIF_NOT_SUPPORTED_FOR_MARKET_ORDER.value) {
      return 'ORDER_GTC_TIF_NOT_SUPPORTED_FOR_MARKET_ORDER'
    }
    if (this.value === RejectCode.ORDER_DAY_TIF_NOT_SUPPORTED_FOR_MARKET_ORDER.value) {
      return 'ORDER_DAY_TIF_NOT_SUPPORTED_FOR_MARKET_ORDER'
    }
    if (this.value === RejectCode.ORDER_EXPIRATION_INVALID.value) {
      return 'ORDER_EXPIRATION_INVALID'
    }
    if (this.value === RejectCode.ORDER_EXPIRATION_NOT_SUPPORTED.value) {
      return 'ORDER_EXPIRATION_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.ORDER_PRICE_IS_MISSING.value) {
      return 'ORDER_PRICE_IS_MISSING'
    }
    if (this.value === RejectCode.ORDER_PRICE_INVALID.value) {
      return 'ORDER_PRICE_INVALID'
    }
    if (this.value === RejectCode.ORDER_STOP_PRICE_IS_MISSING.value) {
      return 'ORDER_STOP_PRICE_IS_MISSING'
    }
    if (this.value === RejectCode.ORDER_STOP_PRICE_INVALID.value) {
      return 'ORDER_STOP_PRICE_INVALID'
    }
    if (this.value === RejectCode.ORDER_TAKE_PROFIT_PRICE_INVALID.value) {
      return 'ORDER_TAKE_PROFIT_PRICE_INVALID'
    }
    if (this.value === RejectCode.ORDER_STOP_LOSS_PRICE_INVALID.value) {
      return 'ORDER_STOP_LOSS_PRICE_INVALID'
    }
    if (this.value === RejectCode.ORDER_AMOUNT_INVALID.value) {
      return 'ORDER_AMOUNT_INVALID'
    }
    if (this.value === RejectCode.ORDER_AMOUNT_TOO_SMALL.value) {
      return 'ORDER_AMOUNT_TOO_SMALL'
    }
    if (this.value === RejectCode.ORDER_AMOUNT_TOO_LARGE.value) {
      return 'ORDER_AMOUNT_TOO_LARGE'
    }
    if (this.value === RejectCode.ORDER_AMOUNT_INVALID_STEP.value) {
      return 'ORDER_AMOUNT_INVALID_STEP'
    }
    if (this.value === RejectCode.ORDER_TOO_MANY_PENDING_ORDERS.value) {
      return 'ORDER_TOO_MANY_PENDING_ORDERS'
    }
    if (this.value === RejectCode.ORDER_ACCOUNT_COMMENT_LENGTH_INVALID.value) {
      return 'ORDER_ACCOUNT_COMMENT_LENGTH_INVALID'
    }
    if (this.value === RejectCode.ORDER_MANAGER_COMMENT_LENGTH_INVALID.value) {
      return 'ORDER_MANAGER_COMMENT_LENGTH_INVALID'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_LESS_THAN_LIMIT_PRICE.value) {
      return 'ORDER_TP_PRICE_LESS_THAN_LIMIT_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_LESS_THAN_STOP_PRICE.value) {
      return 'ORDER_TP_PRICE_LESS_THAN_STOP_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_GREATER_THAN_LIMIT_PRICE.value) {
      return 'ORDER_TP_PRICE_GREATER_THAN_LIMIT_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_GREATER_THAN_STOP_PRICE.value) {
      return 'ORDER_TP_PRICE_GREATER_THAN_STOP_PRICE'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_LESS_THAN_LIMIT_PRICE.value) {
      return 'ORDER_SL_PRICE_LESS_THAN_LIMIT_PRICE'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_LESS_THAN_STOP_PRICE.value) {
      return 'ORDER_SL_PRICE_LESS_THAN_STOP_PRICE'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_GREATER_THAN_LIMIT_PRICE.value) {
      return 'ORDER_SL_PRICE_GREATER_THAN_LIMIT_PRICE'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_GREATER_THAN_STOP_PRICE.value) {
      return 'ORDER_SL_PRICE_GREATER_THAN_STOP_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_LESS_THAN_ASK_PRICE.value) {
      return 'ORDER_TP_PRICE_LESS_THAN_ASK_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_GREATER_THAN_BID_PRICE.value) {
      return 'ORDER_TP_PRICE_GREATER_THAN_BID_PRICE'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_LESS_THAN_ASK_PRICE.value) {
      return 'ORDER_SL_PRICE_LESS_THAN_ASK_PRICE'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_GREATER_THAN_BID_PRICE.value) {
      return 'ORDER_SL_PRICE_GREATER_THAN_BID_PRICE'
    }
    if (this.value === RejectCode.ORDER_LIMIT_PRICE_IS_CLOSE_TO_MARKET.value) {
      return 'ORDER_LIMIT_PRICE_IS_CLOSE_TO_MARKET'
    }
    if (this.value === RejectCode.ORDER_STOP_PRICE_IS_CLOSE_TO_MARKET.value) {
      return 'ORDER_STOP_PRICE_IS_CLOSE_TO_MARKET'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_IS_CLOSE_TO_MARKET.value) {
      return 'ORDER_TP_PRICE_IS_CLOSE_TO_MARKET'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_IS_CLOSE_TO_MARKET.value) {
      return 'ORDER_SL_PRICE_IS_CLOSE_TO_MARKET'
    }
    if (this.value === RejectCode.ORDER_CURRENT_LIMIT_PRICE_IS_CLOSE_TO_MARKET.value) {
      return 'ORDER_CURRENT_LIMIT_PRICE_IS_CLOSE_TO_MARKET'
    }
    if (this.value === RejectCode.ORDER_CURRENT_STOP_PRICE_IS_CLOSE_TO_MARKET.value) {
      return 'ORDER_CURRENT_STOP_PRICE_IS_CLOSE_TO_MARKET'
    }
    if (this.value === RejectCode.ORDER_CURRENT_TP_PRICE_IS_CLOSE_TO_MARKET.value) {
      return 'ORDER_CURRENT_TP_PRICE_IS_CLOSE_TO_MARKET'
    }
    if (this.value === RejectCode.ORDER_CURRENT_SL_PRICE_IS_CLOSE_TO_MARKET.value) {
      return 'ORDER_CURRENT_SL_PRICE_IS_CLOSE_TO_MARKET'
    }
    if (this.value === RejectCode.ORDER_LIMIT_PRICE_INVALID_PRECISION.value) {
      return 'ORDER_LIMIT_PRICE_INVALID_PRECISION'
    }
    if (this.value === RejectCode.ORDER_STOP_PRICE_INVALID_PRECISION.value) {
      return 'ORDER_STOP_PRICE_INVALID_PRECISION'
    }
    if (this.value === RejectCode.ORDER_TAKE_PROFIT_PRICE_INVALID_PRECISION.value) {
      return 'ORDER_TAKE_PROFIT_PRICE_INVALID_PRECISION'
    }
    if (this.value === RejectCode.ORDER_STOP_LOSS_PRICE_INVALID_PRECISION.value) {
      return 'ORDER_STOP_LOSS_PRICE_INVALID_PRECISION'
    }
    if (this.value === RejectCode.ORDER_NOT_PENDING_NOR_FILLED.value) {
      return 'ORDER_NOT_PENDING_NOR_FILLED'
    }
    if (this.value === RejectCode.ORDER_IS_NOT_MARKET.value) {
      return 'ORDER_IS_NOT_MARKET'
    }
    if (this.value === RejectCode.ORDER_IS_NOT_PENDING.value) {
      return 'ORDER_IS_NOT_PENDING'
    }
    if (this.value === RejectCode.ORDER_IS_NOT_FILLED.value) {
      return 'ORDER_IS_NOT_FILLED'
    }
    if (this.value === RejectCode.ORDER_IS_BEING_EXECUTED.value) {
      return 'ORDER_IS_BEING_EXECUTED'
    }
    if (this.value === RejectCode.ORDER_MAGIC_NUMBER_MODIFY_NOT_ALLOWED.value) {
      return 'ORDER_MAGIC_NUMBER_MODIFY_NOT_ALLOWED'
    }
    if (this.value === RejectCode.ORDER_AMOUNT_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER.value) {
      return 'ORDER_AMOUNT_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER'
    }
    if (this.value === RejectCode.ORDER_EXPIRATION_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER.value) {
      return 'ORDER_EXPIRATION_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER'
    }
    if (this.value === RejectCode.ORDER_LIMIT_PRICE_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER.value) {
      return 'ORDER_LIMIT_PRICE_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER'
    }
    if (this.value === RejectCode.ORDER_STOP_PRICE_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER.value) {
      return 'ORDER_STOP_PRICE_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER'
    }
    if (this.value === RejectCode.ORDER_PARENT_ID_MODIFY_NOT_ALLOWED.value) {
      return 'ORDER_PARENT_ID_MODIFY_NOT_ALLOWED'
    }
    if (this.value === RejectCode.ORDER_TYPE_MODIFY_NOT_ALLOWED.value) {
      return 'ORDER_TYPE_MODIFY_NOT_ALLOWED'
    }
    if (this.value === RejectCode.ORDER_SIDE_MODIFY_NOT_ALLOWED.value) {
      return 'ORDER_SIDE_MODIFY_NOT_ALLOWED'
    }
    if (this.value === RejectCode.ORDER_SYMBOL_MODIFY_NOT_ALLOWED.value) {
      return 'ORDER_SYMBOL_MODIFY_NOT_ALLOWED'
    }
    if (this.value === RejectCode.ORDER_PROFIT_NOT_CALCULATED.value) {
      return 'ORDER_PROFIT_NOT_CALCULATED'
    }
    if (this.value === RejectCode.ORDER_COMMISSION_NOT_CALCULATED.value) {
      return 'ORDER_COMMISSION_NOT_CALCULATED'
    }
    if (this.value === RejectCode.ORDER_LIMIT_PRICE_GREATER_THAN_ASK_PRICE.value) {
      return 'ORDER_LIMIT_PRICE_GREATER_THAN_ASK_PRICE'
    }
    if (this.value === RejectCode.ORDER_LIMIT_PRICE_LESS_THAN_BID_PRICE.value) {
      return 'ORDER_LIMIT_PRICE_LESS_THAN_BID_PRICE'
    }
    if (this.value === RejectCode.ORDER_STOP_PRICE_GREATER_THAN_BID_PRICE.value) {
      return 'ORDER_STOP_PRICE_GREATER_THAN_BID_PRICE'
    }
    if (this.value === RejectCode.ORDER_STOP_PRICE_LESS_THAN_ASK_PRICE.value) {
      return 'ORDER_STOP_PRICE_LESS_THAN_ASK_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_IS_CLOSE_TO_LIMIT_PRICE.value) {
      return 'ORDER_TP_PRICE_IS_CLOSE_TO_LIMIT_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_IS_CLOSE_TO_STOP_PRICE.value) {
      return 'ORDER_TP_PRICE_IS_CLOSE_TO_STOP_PRICE'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_IS_CLOSE_TO_LIMIT_PRICE.value) {
      return 'ORDER_SL_PRICE_IS_CLOSE_TO_LIMIT_PRICE'
    }
    if (this.value === RejectCode.ORDER_SL_PRICE_IS_CLOSE_TO_STOP_PRICE.value) {
      return 'ORDER_SL_PRICE_IS_CLOSE_TO_STOP_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_LESS_THAN_BID_PRICE.value) {
      return 'ORDER_TP_PRICE_LESS_THAN_BID_PRICE'
    }
    if (this.value === RejectCode.ORDER_TP_PRICE_GREATER_THAN_ASK_PRICE.value) {
      return 'ORDER_TP_PRICE_GREATER_THAN_ASK_PRICE'
    }
    if (this.value === RejectCode.ORDER_TOO_MANY_ORDERS.value) {
      return 'ORDER_TOO_MANY_ORDERS'
    }
    if (this.value === RejectCode.ORDER_CONTEXT_NOT_CREATED.value) {
      return 'ORDER_CONTEXT_NOT_CREATED'
    }
    if (this.value === RejectCode.ORDER_CLOSE_PRICE_NOT_CALCULATED.value) {
      return 'ORDER_CLOSE_PRICE_NOT_CALCULATED'
    }
    if (this.value === RejectCode.ORDER_TYPE_NOT_PROVIDED.value) {
      return 'ORDER_TYPE_NOT_PROVIDED'
    }
    if (this.value === RejectCode.ORDER_OPEN_PRICE_NOT_CALCULATED.value) {
      return 'ORDER_OPEN_PRICE_NOT_CALCULATED'
    }
    if (this.value === RejectCode.POSITION_DUPLICATE.value) {
      return 'POSITION_DUPLICATE'
    }
    if (this.value === RejectCode.POSITION_NOT_FOUND.value) {
      return 'POSITION_NOT_FOUND'
    }
    if (this.value === RejectCode.POSITION_IS_USED.value) {
      return 'POSITION_IS_USED'
    }
    if (this.value === RejectCode.POSITION_NOT_SUPPORTED.value) {
      return 'POSITION_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.POSITION_SYMBOL_IS_EMPTY.value) {
      return 'POSITION_SYMBOL_IS_EMPTY'
    }
    if (this.value === RejectCode.TRADE_HISTORY_DUPLICATE.value) {
      return 'TRADE_HISTORY_DUPLICATE'
    }
    if (this.value === RejectCode.TRADE_HISTORY_NOT_FOUND.value) {
      return 'TRADE_HISTORY_NOT_FOUND'
    }
    if (this.value === RejectCode.TRADE_HISTORY_PAGE_SIZE_LIMIT_IS_EXCEEDED.value) {
      return 'TRADE_HISTORY_PAGE_SIZE_LIMIT_IS_EXCEEDED'
    }
    if (this.value === RejectCode.TRADE_HISTORY_PROFIT_CLOSED_GET_FAILED.value) {
      return 'TRADE_HISTORY_PROFIT_CLOSED_GET_FAILED'
    }
    if (this.value === RejectCode.TRADE_HISTORY_ORDERS_CLOSED_SUMMARIES_GET_FAILED.value) {
      return 'TRADE_HISTORY_ORDERS_CLOSED_SUMMARIES_GET_FAILED'
    }
    if (this.value === RejectCode.CALENDAR_REGULAR_RULE_DUPLICATE.value) {
      return 'CALENDAR_REGULAR_RULE_DUPLICATE'
    }
    if (this.value === RejectCode.CALENDAR_REGULAR_RULE_NOT_FOUND.value) {
      return 'CALENDAR_REGULAR_RULE_NOT_FOUND'
    }
    if (this.value === RejectCode.CALENDAR_REGULAR_RULE_IS_USED.value) {
      return 'CALENDAR_REGULAR_RULE_IS_USED'
    }
    if (this.value === RejectCode.CALENDAR_OFFTIME_RULE_DUPLICATE.value) {
      return 'CALENDAR_OFFTIME_RULE_DUPLICATE'
    }
    if (this.value === RejectCode.CALENDAR_OFFTIME_RULE_NOT_FOUND.value) {
      return 'CALENDAR_OFFTIME_RULE_NOT_FOUND'
    }
    if (this.value === RejectCode.CALENDAR_OFFTIME_RULE_IS_USED.value) {
      return 'CALENDAR_OFFTIME_RULE_IS_USED'
    }
    if (this.value === RejectCode.CALENDAR_RULES_NOT_FOUND.value) {
      return 'CALENDAR_RULES_NOT_FOUND'
    }
    if (this.value === RejectCode.COMMISSION_PROFILE_DUPLICATE.value) {
      return 'COMMISSION_PROFILE_DUPLICATE'
    }
    if (this.value === RejectCode.COMMISSION_PROFILE_NOT_FOUND.value) {
      return 'COMMISSION_PROFILE_NOT_FOUND'
    }
    if (this.value === RejectCode.COMMISSION_PROFILE_IS_DISABLED.value) {
      return 'COMMISSION_PROFILE_IS_DISABLED'
    }
    if (this.value === RejectCode.COMMISSION_PROFILE_IS_USED.value) {
      return 'COMMISSION_PROFILE_IS_USED'
    }
    if (this.value === RejectCode.COMMISSION_VALUES_RANGE_OVERLAPPED.value) {
      return 'COMMISSION_VALUES_RANGE_OVERLAPPED'
    }
    if (this.value === RejectCode.TRADING_ROUTE_RULE_DUPLICATE.value) {
      return 'TRADING_ROUTE_RULE_DUPLICATE'
    }
    if (this.value === RejectCode.TRADING_ROUTE_RULE_NOT_FOUND.value) {
      return 'TRADING_ROUTE_RULE_NOT_FOUND'
    }
    if (this.value === RejectCode.TRADING_ROUTE_RULE_PRIORITY_LIMIT.value) {
      return 'TRADING_ROUTE_RULE_PRIORITY_LIMIT'
    }
    if (this.value === RejectCode.TRADING_ROUTE_RULE_IS_USED.value) {
      return 'TRADING_ROUTE_RULE_IS_USED'
    }
    if (this.value === RejectCode.SYMBOL_MAP_DUPLICATE.value) {
      return 'SYMBOL_MAP_DUPLICATE'
    }
    if (this.value === RejectCode.SYMBOL_MAP_NOT_FOUND.value) {
      return 'SYMBOL_MAP_NOT_FOUND'
    }
    if (this.value === RejectCode.SYMBOL_MAP_IS_USED.value) {
      return 'SYMBOL_MAP_IS_USED'
    }
    if (this.value === RejectCode.GATEWAY_DUPLICATE.value) {
      return 'GATEWAY_DUPLICATE'
    }
    if (this.value === RejectCode.GATEWAY_NOT_FOUND.value) {
      return 'GATEWAY_NOT_FOUND'
    }
    if (this.value === RejectCode.GATEWAY_MODULE_NOT_FOUND.value) {
      return 'GATEWAY_MODULE_NOT_FOUND'
    }
    if (this.value === RejectCode.GATEWAY_IS_USED.value) {
      return 'GATEWAY_IS_USED'
    }
    if (this.value === RejectCode.GATEWAY_NOT_STARTED.value) {
      return 'GATEWAY_NOT_STARTED'
    }
    if (this.value === RejectCode.GATEWAY_ERROR_SENDING_TO_LP.value) {
      return 'GATEWAY_ERROR_SENDING_TO_LP'
    }
    if (this.value === RejectCode.DATA_FEED_DUPLICATE.value) {
      return 'DATA_FEED_DUPLICATE'
    }
    if (this.value === RejectCode.DATA_FEED_NOT_FOUND.value) {
      return 'DATA_FEED_NOT_FOUND'
    }
    if (this.value === RejectCode.DATA_FEED_MODULE_NOT_FOUND.value) {
      return 'DATA_FEED_MODULE_NOT_FOUND'
    }
    if (this.value === RejectCode.DATA_FEED_IS_USED.value) {
      return 'DATA_FEED_IS_USED'
    }
    if (this.value === RejectCode.DATA_FEED_NOT_STARTED.value) {
      return 'DATA_FEED_NOT_STARTED'
    }
    if (this.value === RejectCode.DATA_FEED_ERROR_SENDING_TO_LP.value) {
      return 'DATA_FEED_ERROR_SENDING_TO_LP'
    }
    if (this.value === RejectCode.CHART_BAR_NOT_FOUND.value) {
      return 'CHART_BAR_NOT_FOUND'
    }
    if (this.value === RejectCode.CHART_BAR_REQUEST_LIMIT_IS_EXCEEDED.value) {
      return 'CHART_BAR_REQUEST_LIMIT_IS_EXCEEDED'
    }
    if (this.value === RejectCode.CHART_BAR_IMPORT_FAILED.value) {
      return 'CHART_BAR_IMPORT_FAILED'
    }
    if (this.value === RejectCode.CHART_BAR_OPERATION_FAILED.value) {
      return 'CHART_BAR_OPERATION_FAILED'
    }
    if (this.value === RejectCode.MARKUP_PROFILE_DUPLICATE.value) {
      return 'MARKUP_PROFILE_DUPLICATE'
    }
    if (this.value === RejectCode.MARKUP_PROFILE_NOT_FOUND.value) {
      return 'MARKUP_PROFILE_NOT_FOUND'
    }
    if (this.value === RejectCode.MARKUP_PROFILE_IS_USED.value) {
      return 'MARKUP_PROFILE_IS_USED'
    }
    if (this.value === RejectCode.MAIL_SERVER_DUPLICATE.value) {
      return 'MAIL_SERVER_DUPLICATE'
    }
    if (this.value === RejectCode.MAIL_SERVER_NOT_FOUND.value) {
      return 'MAIL_SERVER_NOT_FOUND'
    }
    if (this.value === RejectCode.MAIL_SERVER_IS_USED.value) {
      return 'MAIL_SERVER_IS_USED'
    }
    if (this.value === RejectCode.LEVERAGE_PROFILE_DUPLICATE.value) {
      return 'LEVERAGE_PROFILE_DUPLICATE'
    }
    if (this.value === RejectCode.LEVERAGE_PROFILE_NOT_FOUND.value) {
      return 'LEVERAGE_PROFILE_NOT_FOUND'
    }
    if (this.value === RejectCode.LEVERAGE_PROFILE_IS_USED.value) {
      return 'LEVERAGE_PROFILE_IS_USED'
    }
    if (this.value === RejectCode.MARGIN_RATE_PROFILE_DUPLICATE.value) {
      return 'MARGIN_RATE_PROFILE_DUPLICATE'
    }
    if (this.value === RejectCode.MARGIN_RATE_PROFILE_NOT_FOUND.value) {
      return 'MARGIN_RATE_PROFILE_NOT_FOUND'
    }
    if (this.value === RejectCode.MARGIN_RATE_PROFILE_IS_USED.value) {
      return 'MARGIN_RATE_PROFILE_IS_USED'
    }
    if (this.value === RejectCode.NEWS_PROFILE_DUPLICATE.value) {
      return 'NEWS_PROFILE_DUPLICATE'
    }
    if (this.value === RejectCode.NEWS_PROFILE_NOT_FOUND.value) {
      return 'NEWS_PROFILE_NOT_FOUND'
    }
    if (this.value === RejectCode.NEWS_PROFILE_IS_USED.value) {
      return 'NEWS_PROFILE_IS_USED'
    }
    if (this.value === RejectCode.EMAIL_TEMPLATE_DUPLICATE.value) {
      return 'EMAIL_TEMPLATE_DUPLICATE'
    }
    if (this.value === RejectCode.EMAIL_TEMPLATE_NOT_FOUND.value) {
      return 'EMAIL_TEMPLATE_NOT_FOUND'
    }
    if (this.value === RejectCode.EMAIL_TEMPLATE_IS_USED.value) {
      return 'EMAIL_TEMPLATE_IS_USED'
    }
    if (this.value === RejectCode.EMAIL_TEMPLATE_WRONG_EVENT_TYPE.value) {
      return 'EMAIL_TEMPLATE_WRONG_EVENT_TYPE'
    }
    if (this.value === RejectCode.ORDER_STATE_SUBSCRIPTION_DUPLICATE.value) {
      return 'ORDER_STATE_SUBSCRIPTION_DUPLICATE'
    }
    if (this.value === RejectCode.ORDER_STATE_SUBSCRIPTION_NOT_FOUND.value) {
      return 'ORDER_STATE_SUBSCRIPTION_NOT_FOUND'
    }
    if (this.value === RejectCode.ORDER_STATE_SUBSCRIPTION_NO_ORDERS.value) {
      return 'ORDER_STATE_SUBSCRIPTION_NO_ORDERS'
    }
    if (this.value === RejectCode.TRADE_EVENT_DUPLICATE.value) {
      return 'TRADE_EVENT_DUPLICATE'
    }
    if (this.value === RejectCode.TRADE_EVENT_UNEXPECTED_RECORD_ID.value) {
      return 'TRADE_EVENT_UNEXPECTED_RECORD_ID'
    }
    if (this.value === RejectCode.TRADE_EVENT_REQUIRED_FIELD_MISSING.value) {
      return 'TRADE_EVENT_REQUIRED_FIELD_MISSING'
    }
    if (this.value === RejectCode.TRADE_EVENT_LIMIT_IS_EXCEEDED.value) {
      return 'TRADE_EVENT_LIMIT_IS_EXCEEDED'
    }
    if (this.value === RejectCode.ORDER_MARGIN_NOT_SUBSCRIBED.value) {
      return 'ORDER_MARGIN_NOT_SUBSCRIBED'
    }
    if (this.value === RejectCode.ORDER_MARGIN_SUBSCRIPTION_NOT_UPDATED.value) {
      return 'ORDER_MARGIN_SUBSCRIPTION_NOT_UPDATED'
    }
    if (this.value === RejectCode.ORDER_MARGIN_NOT_UNSUBSCRIBED.value) {
      return 'ORDER_MARGIN_NOT_UNSUBSCRIBED'
    }
    if (this.value === RejectCode.ORDER_MARGIN_NOT_CALCULATED.value) {
      return 'ORDER_MARGIN_NOT_CALCULATED'
    }
    if (this.value === RejectCode.ORDER_STATE_INFO_ALREADY_SUBSCRIBED.value) {
      return 'ORDER_STATE_INFO_ALREADY_SUBSCRIBED'
    }
    if (this.value === RejectCode.ORDER_STATE_INFO_NOT_SUBSCRIBED.value) {
      return 'ORDER_STATE_INFO_NOT_SUBSCRIBED'
    }
    if (this.value === RejectCode.ORDERS_BY_PRICE_NOT_SUBSCRIBED.value) {
      return 'ORDERS_BY_PRICE_NOT_SUBSCRIBED'
    }
    if (this.value === RejectCode.ORDERS_BY_PRICE_SUBSCRIPTION_NOT_UPDATED.value) {
      return 'ORDERS_BY_PRICE_SUBSCRIPTION_NOT_UPDATED'
    }
    if (this.value === RejectCode.ORDERS_BY_PRICE_NOT_UNSUBSCRIBED.value) {
      return 'ORDERS_BY_PRICE_NOT_UNSUBSCRIBED'
    }
    if (this.value === RejectCode.ORDERS_SUMMARY_INFO_NOT_SUBSCRIBED.value) {
      return 'ORDERS_SUMMARY_INFO_NOT_SUBSCRIBED'
    }
    if (this.value === RejectCode.ORDERS_SUMMARY_INFO_SUBSCRIPTION_NOT_UPDATED.value) {
      return 'ORDERS_SUMMARY_INFO_SUBSCRIPTION_NOT_UPDATED'
    }
    if (this.value === RejectCode.ORDERS_SUMMARY_INFO_NOT_UNSUBSCRIBED.value) {
      return 'ORDERS_SUMMARY_INFO_NOT_UNSUBSCRIBED'
    }
    if (this.value === RejectCode.CLIENT_REPORTS_SUBSCRIPTION_NOT_CHANGED.value) {
      return 'CLIENT_REPORTS_SUBSCRIPTION_NOT_CHANGED'
    }
    if (this.value === RejectCode.STORAGE_CACHE_NOT_AVAILABLE.value) {
      return 'STORAGE_CACHE_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.SYMBOL_DAY_PL_NOT_SUBSCRIBED.value) {
      return 'SYMBOL_DAY_PL_NOT_SUBSCRIBED'
    }
    if (this.value === RejectCode.SYMBOL_DAY_PL_SUBSCRIPTION_NOT_UPDATED.value) {
      return 'SYMBOL_DAY_PL_SUBSCRIPTION_NOT_UPDATED'
    }
    if (this.value === RejectCode.SYMBOL_DAY_PL_NOT_UNSUBSCRIBED.value) {
      return 'SYMBOL_DAY_PL_NOT_UNSUBSCRIBED'
    }
    if (this.value === RejectCode.SYMBOL_PL_NOT_SUBSCRIBED.value) {
      return 'SYMBOL_PL_NOT_SUBSCRIBED'
    }
    if (this.value === RejectCode.SYMBOL_PL_SUBSCRIPTION_NOT_UPDATED.value) {
      return 'SYMBOL_PL_SUBSCRIPTION_NOT_UPDATED'
    }
    if (this.value === RejectCode.SYMBOL_PL_NOT_UNSUBSCRIBED.value) {
      return 'SYMBOL_PL_NOT_UNSUBSCRIBED'
    }
    if (this.value === RejectCode.CLIENT_LOGIN_INFO_NOT_FOUND.value) {
      return 'CLIENT_LOGIN_INFO_NOT_FOUND'
    }
    if (this.value === RejectCode.UNDERLYING_REST_SERVICE_FAILED.value) {
      return 'UNDERLYING_REST_SERVICE_FAILED'
    }
    if (this.value === RejectCode.UNDERLYING_REST_SERVICE_RESPONSE_PARSE_ERROR.value) {
      return 'UNDERLYING_REST_SERVICE_RESPONSE_PARSE_ERROR'
    }
    if (this.value === RejectCode.UNDERLYING_REST_SERVICE_INCORRECT_RESPONSE.value) {
      return 'UNDERLYING_REST_SERVICE_INCORRECT_RESPONSE'
    }
    if (this.value === RejectCode.UNDERLYING_REST_SERVICE_CONFLICT.value) {
      return 'UNDERLYING_REST_SERVICE_CONFLICT'
    }
    if (this.value === RejectCode.UNDERLYING_REST_SERVICE_UNEXPECTED_CODE.value) {
      return 'UNDERLYING_REST_SERVICE_UNEXPECTED_CODE'
    }
    if (this.value === RejectCode.UNDERLYING_REST_SERVICE_NOT_FOUND.value) {
      return 'UNDERLYING_REST_SERVICE_NOT_FOUND'
    }
    if (this.value === RejectCode.UNDERLYING_REST_SERVICE_BAD_REQUEST.value) {
      return 'UNDERLYING_REST_SERVICE_BAD_REQUEST'
    }
    if (this.value === RejectCode.UNDERLYING_REST_SERVICE_UNAUTHORIZED.value) {
      return 'UNDERLYING_REST_SERVICE_UNAUTHORIZED'
    }
    if (this.value === RejectCode.DASHBOARD_NOT_FOUND.value) {
      return 'DASHBOARD_NOT_FOUND'
    }
    if (this.value === RejectCode.CHART_NOT_FOUND.value) {
      return 'CHART_NOT_FOUND'
    }
    if (this.value === RejectCode.SOCIAL_TRADING_ACCOUNT_MODE_NOT_SUPPORTED.value) {
      return 'SOCIAL_TRADING_ACCOUNT_MODE_NOT_SUPPORTED'
    }
    if (this.value === RejectCode.SOCIAL_TRADING_ALLOCATION_METHOD_INVALID.value) {
      return 'SOCIAL_TRADING_ALLOCATION_METHOD_INVALID'
    }
    if (this.value === RejectCode.SOCIAL_TRADING_ACCOUNT_TYPE_INVALID.value) {
      return 'SOCIAL_TRADING_ACCOUNT_TYPE_INVALID'
    }
    if (this.value === RejectCode.SOCIAL_TRADING_ACCOUNT_COMMISSION_ACCOUNT_INVALID.value) {
      return 'SOCIAL_TRADING_ACCOUNT_COMMISSION_ACCOUNT_INVALID'
    }
    if (this.value === RejectCode.SOCIAL_TRADING_DISABLED.value) {
      return 'SOCIAL_TRADING_DISABLED'
    }
    if (this.value === RejectCode.SOCIAL_TRADING_ACCOUNT_HAS_ACTIVE_ORDERS.value) {
      return 'SOCIAL_TRADING_ACCOUNT_HAS_ACTIVE_ORDERS'
    }
    if (this.value === RejectCode.SOCIAL_TRADING_ALLOCATION_FAILED.value) {
      return 'SOCIAL_TRADING_ALLOCATION_FAILED'
    }
    if (this.value === RejectCode.SOCIAL_TRADING_ACCOUNT_COMMISSION_NOT_CALCULATED.value) {
      return 'SOCIAL_TRADING_ACCOUNT_COMMISSION_NOT_CALCULATED'
    }
    if (this.value === RejectCode.PERSON_DUPLICATE.value) {
      return 'PERSON_DUPLICATE'
    }
    if (this.value === RejectCode.PERSON_NOT_FOUND.value) {
      return 'PERSON_NOT_FOUND'
    }
    if (this.value === RejectCode.SECURITY_SETTINGS_DUPLICATE.value) {
      return 'SECURITY_SETTINGS_DUPLICATE'
    }
    if (this.value === RejectCode.SECURITY_SETTINGS_NOT_FOUND.value) {
      return 'SECURITY_SETTINGS_NOT_FOUND'
    }
    if (this.value === RejectCode.SECURITY_SETTINGS_IS_USED.value) {
      return 'SECURITY_SETTINGS_IS_USED'
    }
    if (this.value === RejectCode.FIREWALL_RULE_DUPLICATE.value) {
      return 'FIREWALL_RULE_DUPLICATE'
    }
    if (this.value === RejectCode.FIREWALL_RULE_NOT_FOUND.value) {
      return 'FIREWALL_RULE_NOT_FOUND'
    }
    if (this.value === RejectCode.FIREWALL_RULE_IS_USED.value) {
      return 'FIREWALL_RULE_IS_USED'
    }
    if (this.value === RejectCode.BLOCKED_IP_EVENT_LOG_OPERATION_FAILED.value) {
      return 'BLOCKED_IP_EVENT_LOG_OPERATION_FAILED'
    }
    if (this.value === RejectCode.LANGUAGE_DUPLICATE.value) {
      return 'LANGUAGE_DUPLICATE'
    }
    if (this.value === RejectCode.LANGUAGE_NOT_FOUND.value) {
      return 'LANGUAGE_NOT_FOUND'
    }
    if (this.value === RejectCode.LANGUAGE_IS_USED.value) {
      return 'LANGUAGE_IS_USED'
    }
    if (this.value === RejectCode.PUSH_NOTIFICATION_TEMPLATE_DUPLICATE.value) {
      return 'PUSH_NOTIFICATION_TEMPLATE_DUPLICATE'
    }
    if (this.value === RejectCode.PUSH_NOTIFICATION_TEMPLATE_NOT_FOUND.value) {
      return 'PUSH_NOTIFICATION_TEMPLATE_NOT_FOUND'
    }
    if (this.value === RejectCode.PUSH_NOTIFICATION_TEMPLATE_IS_USED.value) {
      return 'PUSH_NOTIFICATION_TEMPLATE_IS_USED'
    }
    if (this.value === RejectCode.PUSH_NOTIFICATION_TEMPLATE_IS_DISABLED.value) {
      return 'PUSH_NOTIFICATION_TEMPLATE_IS_DISABLED'
    }
    if (this.value === RejectCode.PUSH_NOTIFICATION_TEMPLATE_IS_MALFORMED.value) {
      return 'PUSH_NOTIFICATION_TEMPLATE_IS_MALFORMED'
    }
    if (this.value === RejectCode.DOCUMENT_SERVER_NOT_AVAILABLE.value) {
      return 'DOCUMENT_SERVER_NOT_AVAILABLE'
    }
    if (this.value === RejectCode.LEGAL_DOCUMENT_GROUP_NOT_FOUND.value) {
      return 'LEGAL_DOCUMENT_GROUP_NOT_FOUND'
    }
    if (this.value === RejectCode.LEGAL_DOCUMENT_GROUP_DISABLED.value) {
      return 'LEGAL_DOCUMENT_GROUP_DISABLED'
    }
    if (this.value === RejectCode.LEGAL_DOCUMENT_GROUP_DUPLICATE.value) {
      return 'LEGAL_DOCUMENT_GROUP_DUPLICATE'
    }
    if (this.value === RejectCode.LEGAL_DOCUMENT_NOT_FOUND.value) {
      return 'LEGAL_DOCUMENT_NOT_FOUND'
    }
    if (this.value === RejectCode.LEGAL_DOCUMENT_DUPLICATE.value) {
      return 'LEGAL_DOCUMENT_DUPLICATE'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!RejectCode}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!RejectCode}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!RejectCode} Created enum
   */
  static fromObject (other) {
    return new RejectCode(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
RejectCode.UNKNOWN = new RejectCode(0 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SUCCESS = new RejectCode(0 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TIMEOUT = new RejectCode(0 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.THROTTLING = new RejectCode(0 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXCEPTION = new RejectCode(0 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFLICT = new RejectCode(0 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.NOT_FOUND = new RejectCode(0 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_KEY = new RejectCode(1000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_VALUE = new RejectCode(1000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_LOGIN = new RejectCode(1000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_PASSWORD = new RejectCode(1000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_OTP_TOKEN = new RejectCode(1000 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_AUTH_TOKEN = new RejectCode(1000 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_OPERATION = new RejectCode(1000 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_PROTOCOL = new RejectCode(1000 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_REQUEST = new RejectCode(1000 + 8)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_RESPONSE = new RejectCode(1000 + 9)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_SERIALIZATION = new RejectCode(1000 + 10)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_DESERIALIZATION = new RejectCode(1000 + 11)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_SESSION = new RejectCode(1000 + 12)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_CACHE = new RejectCode(1000 + 13)
// noinspection PointlessArithmeticExpressionJS
RejectCode.INVALID_CLIENT_VERSION = new RejectCode(1000 + 14)
// noinspection PointlessArithmeticExpressionJS
RejectCode.OPERATION_NOT_SUPPORTED = new RejectCode(1100 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.OPERATION_TEMPORARILY_NOT_AVAILABLE = new RejectCode(1100 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXTERNAL_LOGIN_NOT_AVAILABLE = new RejectCode(1200 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PROTOCOL_REQUIRED = new RejectCode(1200 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LOGIN_REQUIRED = new RejectCode(1200 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PASSWORD_REQUIRED = new RejectCode(1200 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.OTP_TOKEN_REQUIRED = new RejectCode(1200 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXTERNAL_LOGIN_NOT_CONFIGURED = new RejectCode(1200 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXTERNAL_LOGIN_NOT_CONNECTED = new RejectCode(1200 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXTERNAL_LOGIN_TIMEOUT = new RejectCode(1200 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.AUTH_TOKEN_EXPIRED = new RejectCode(1200 + 8)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CLIENT_VERSION_REQUIRED = new RejectCode(1200 + 9)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SESSION_NOT_INITIALIZED = new RejectCode(1300 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SSL_CONNECTION_FORBIDDEN = new RejectCode(1300 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HTTPS_CONNECTION_FORBIDDEN = new RejectCode(1300 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.WSS_CONNECTION_FORBIDDEN = new RejectCode(1300 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FIX_CONNECTION_FORBIDDEN = new RejectCode(1300 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_NOT_ALLOWED = new RejectCode(2000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_NOT_AUTHENICATED = new RejectCode(2000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_NOT_AUTHORIZED = new RejectCode(2000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_NOT_REGISTERED = new RejectCode(2000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_WEAK_PASSWORD = new RejectCode(2000 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_NOT_AVAILABLE = new RejectCode(3000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_INVALID_KEY = new RejectCode(3000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_INVALID_VALUE = new RejectCode(3000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_REFRESH_FAILED = new RejectCode(3000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_HISTORY_FAILED = new RejectCode(3000 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_ROLLBACK_FAILED = new RejectCode(3000 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_STORAGE_NOT_AVAILABLE = new RejectCode(3000 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_STORAGE_NOT_STARTED = new RejectCode(3000 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_STORAGE_NOT_SUPPORTED = new RejectCode(3000 + 8)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_UPDATE_DUPLICATE = new RejectCode(3000 + 9)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_UPDATE_NOT_FOUND = new RejectCode(3000 + 10)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CONFIG_VERSION_CONFLICT = new RejectCode(3000 + 11)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FEED_NOT_AVAILABLE = new RejectCode(4000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FEED_NOT_STARTED = new RejectCode(4000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FEED_NOT_SUPPORTED = new RejectCode(4000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FEED_NOT_SUBSCRIBED = new RejectCode(4000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FEED_NOT_UNSUBSCRIBED = new RejectCode(4000 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FEED_VERSION_CONFLICT = new RejectCode(4000 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HISTORY_NOT_AVAILABLE = new RejectCode(5000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HISTORY_NOT_STARTED = new RejectCode(5000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HISTORY_NOT_SUPPORTED = new RejectCode(5000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HISTORY_VERSION_CONFLICT = new RejectCode(5000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_NOT_AVAILABLE = new RejectCode(6000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_NOT_STARTED = new RejectCode(6000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_NOT_SUPPORTED = new RejectCode(6000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_VERSION_CONFLICT = new RejectCode(6000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_INSERT_FAILED = new RejectCode(6000 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_UPDATE_FAILED = new RejectCode(6000 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_DELETE_FAILED = new RejectCode(6000 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_OPERATION_FAILED = new RejectCode(6000 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_NOT_AVAILABLE = new RejectCode(7000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_NOT_ALLOWED = new RejectCode(7000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.NOT_ENOUGH_BALANCE = new RejectCode(7000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.NOT_ENOUGH_CREDIT = new RejectCode(7000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.NOT_ENOUGH_MARGIN = new RejectCode(7000 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_IS_REJECTED = new RejectCode(7000 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_REJECT_BY_MARGIN_CALL = new RejectCode(7000 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_REJECT_BY_STOP_OUT = new RejectCode(7000 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_REJECT_BY_CALENDAR = new RejectCode(7000 + 8)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_OPEN_ORDER_NOT_ALLOWED = new RejectCode(7000 + 9)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_OPEN_BUY_ORDER_NOT_ALLOWED = new RejectCode(7000 + 10)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_OPEN_SELL_ORDER_NOT_ALLOWED = new RejectCode(7000 + 11)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_MODIFY_ORDER_NOT_ALLOWED = new RejectCode(7000 + 12)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_ACTIVATE_ORDER_NOT_ALLOWED = new RejectCode(7000 + 13)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_CANCEL_ORDER_NOT_ALLOWED = new RejectCode(7000 + 14)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_CLOSE_ORDER_NOT_ALLOWED = new RejectCode(7000 + 15)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_ACCOUNT_NOT_ALLOWED = new RejectCode(7000 + 16)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_SYMBOL_NOT_ALLOWED = new RejectCode(7000 + 17)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_ORDER_ID_GENERATION_FAILED = new RejectCode(7000 + 18)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_EVENT_TRANSACTION_ID_GENERATION_FAILED = new RejectCode(7000 + 19)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_NOT_AVAILABLE = new RejectCode(7500 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXECUTION_NOT_AVAILABLE = new RejectCode(8000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXECUTION_NOT_STARTED = new RejectCode(8000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXECUTION_NOT_SUPPORTED = new RejectCode(8000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EXECUTION_VERSION_CONFLICT = new RejectCode(8000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DRAWING_STORAGE_TECHNICAL_ERROR = new RejectCode(9000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DRAWING_STORAGE_DEPRECATED_1 = new RejectCode(9000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DRAWING_STORAGE_DASHBOARD_VERSION_CONFLICT = new RejectCode(9000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DRAWING_STORAGE_DASHBOARD_NAME_CONFLICT = new RejectCode(9000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DRAWING_STORAGE_DASHBOARD_NOT_FOUND = new RejectCode(9000 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HOST_DUPLICATE = new RejectCode(10000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HOST_NOT_FOUND = new RejectCode(10000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HOST_IS_DISABLED = new RejectCode(10000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.HOST_IS_USED = new RejectCode(10000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MODULE_DUPLICATE = new RejectCode(10100 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MODULE_NOT_FOUND = new RejectCode(10100 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MODULE_IS_DISABLED = new RejectCode(10100 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MODULE_IS_USED = new RejectCode(10100 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TIMEZONE_DUPLICATE = new RejectCode(10200 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TIMEZONE_NOT_FOUND = new RejectCode(10200 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TIMEZONE_IS_DISABLED = new RejectCode(10200 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TIMEZONE_IS_USED = new RejectCode(10200 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CURRENCY_GROUP_DUPLICATE = new RejectCode(10300 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CURRENCY_GROUP_NOT_FOUND = new RejectCode(10300 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CURRENCY_GROUP_IS_DISABLED = new RejectCode(10300 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CURRENCY_GROUP_IS_USED = new RejectCode(10300 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CURRENCY_DUPLICATE = new RejectCode(10400 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CURRENCY_NOT_FOUND = new RejectCode(10400 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CURRENCY_IS_DISABLED = new RejectCode(10400 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CURRENCY_IS_USED = new RejectCode(10400 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.BALANCE_CURRENCY_NOT_FOUND = new RejectCode(10400 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_GROUP_DUPLICATE = new RejectCode(10500 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_GROUP_NOT_FOUND = new RejectCode(10500 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_GROUP_IS_DISABLED = new RejectCode(10500 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_GROUP_IS_USED = new RejectCode(10500 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_GROUP_TRADING_NOT_DISABLED = new RejectCode(10500 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_GROUP_HAS_ACTIVE_ORDERS = new RejectCode(10500 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_DUPLICATE = new RejectCode(10600 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_NOT_FOUND = new RejectCode(10600 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_IS_DISABLED = new RejectCode(10600 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_IS_USED = new RejectCode(10600 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TICK_DUPLICATE = new RejectCode(10700 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TICK_NOT_FOUND = new RejectCode(10700 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEVEL2_DUPLICATE = new RejectCode(10800 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEVEL2_NOT_FOUND = new RejectCode(10800 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_GROUP_DUPLICATE = new RejectCode(10900 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_GROUP_NOT_FOUND = new RejectCode(10900 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_GROUP_IS_DISABLED = new RejectCode(10900 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_GROUP_IS_USED = new RejectCode(10900 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_DUPLICATE = new RejectCode(11000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_NOT_FOUND = new RejectCode(11000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_IS_DISABLED = new RejectCode(11000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_IS_USED = new RejectCode(11000 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_LOGIN_OUT_OF_RANGE = new RejectCode(11000 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_LOGIN_RANGE_EXHAUSTED = new RejectCode(11000 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MANAGER_LOGIN_RANGE_OVERLAPPED = new RejectCode(11000 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PLATFORM_DUPLICATE = new RejectCode(11100 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PLATFORM_NOT_FOUND = new RejectCode(11100 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PLATFORM_IS_DISABLED = new RejectCode(11100 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PLATFORM_IS_USED = new RejectCode(11100 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_GROUP_DUPLICATE = new RejectCode(11200 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_GROUP_NOT_FOUND = new RejectCode(11200 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_GROUP_IS_DISABLED = new RejectCode(11200 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_GROUP_IS_USED = new RejectCode(11200 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_DUPLICATE = new RejectCode(11300 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_NOT_FOUND = new RejectCode(11300 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_NOT_SUPPORTED = new RejectCode(11300 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_IS_DISABLED = new RejectCode(11300 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_IS_USED = new RejectCode(11300 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_LOGIN_INVALID = new RejectCode(11300 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_LOGIN_OUT_OF_RANGE = new RejectCode(11300 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_LOGIN_RANGE_EXHAUSTED = new RejectCode(11300 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_LOGIN_RANGE_OVERLAPPED = new RejectCode(11300 + 8)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_MAX_DEPOSIT_EXCEEDED = new RejectCode(11300 + 9)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_MARGIN_NOT_FOUND = new RejectCode(11350 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ACCOUNT_MARGIN_NOT_SUPPORTED = new RejectCode(11350 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ASSET_DUPLICATE = new RejectCode(11400 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ASSET_NOT_FOUND = new RejectCode(11400 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ASSET_IS_USED = new RejectCode(11400 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ASSET_NOT_SUPPORTED = new RejectCode(11400 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ASSET_CURRENCY_IS_EMPTY = new RejectCode(11400 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_DUPLICATE = new RejectCode(11500 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_NOT_FOUND = new RejectCode(11500 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_NOT_SUPPORTED = new RejectCode(11500 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_IS_USED = new RejectCode(11500 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SYMBOL_IS_EMPTY = new RejectCode(11500 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_CLIENT_ID_LENGTH_INVALID = new RejectCode(11500 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_PARENT_ORDER_ID_INVALID = new RejectCode(11500 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_GTC_TIF_NOT_SUPPORTED_FOR_MARKET_ORDER = new RejectCode(11500 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_DAY_TIF_NOT_SUPPORTED_FOR_MARKET_ORDER = new RejectCode(11500 + 8)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_EXPIRATION_INVALID = new RejectCode(11500 + 9)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_EXPIRATION_NOT_SUPPORTED = new RejectCode(11500 + 10)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_PRICE_IS_MISSING = new RejectCode(11500 + 11)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_PRICE_INVALID = new RejectCode(11500 + 12)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_PRICE_IS_MISSING = new RejectCode(11500 + 13)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_PRICE_INVALID = new RejectCode(11500 + 14)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TAKE_PROFIT_PRICE_INVALID = new RejectCode(11500 + 15)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_LOSS_PRICE_INVALID = new RejectCode(11500 + 16)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_AMOUNT_INVALID = new RejectCode(11500 + 17)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_AMOUNT_TOO_SMALL = new RejectCode(11500 + 18)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_AMOUNT_TOO_LARGE = new RejectCode(11500 + 19)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_AMOUNT_INVALID_STEP = new RejectCode(11500 + 20)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TOO_MANY_PENDING_ORDERS = new RejectCode(11500 + 21)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_ACCOUNT_COMMENT_LENGTH_INVALID = new RejectCode(11500 + 22)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_MANAGER_COMMENT_LENGTH_INVALID = new RejectCode(11500 + 23)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_LESS_THAN_LIMIT_PRICE = new RejectCode(11500 + 24)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_LESS_THAN_STOP_PRICE = new RejectCode(11500 + 25)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_GREATER_THAN_LIMIT_PRICE = new RejectCode(11500 + 26)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_GREATER_THAN_STOP_PRICE = new RejectCode(11500 + 27)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_LESS_THAN_LIMIT_PRICE = new RejectCode(11500 + 28)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_LESS_THAN_STOP_PRICE = new RejectCode(11500 + 29)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_GREATER_THAN_LIMIT_PRICE = new RejectCode(11500 + 30)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_GREATER_THAN_STOP_PRICE = new RejectCode(11500 + 31)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_LESS_THAN_ASK_PRICE = new RejectCode(11500 + 32)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_GREATER_THAN_BID_PRICE = new RejectCode(11500 + 33)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_LESS_THAN_ASK_PRICE = new RejectCode(11500 + 34)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_GREATER_THAN_BID_PRICE = new RejectCode(11500 + 35)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_LIMIT_PRICE_IS_CLOSE_TO_MARKET = new RejectCode(11500 + 36)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_PRICE_IS_CLOSE_TO_MARKET = new RejectCode(11500 + 37)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_IS_CLOSE_TO_MARKET = new RejectCode(11500 + 38)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_IS_CLOSE_TO_MARKET = new RejectCode(11500 + 39)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_CURRENT_LIMIT_PRICE_IS_CLOSE_TO_MARKET = new RejectCode(11500 + 40)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_CURRENT_STOP_PRICE_IS_CLOSE_TO_MARKET = new RejectCode(11500 + 41)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_CURRENT_TP_PRICE_IS_CLOSE_TO_MARKET = new RejectCode(11500 + 42)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_CURRENT_SL_PRICE_IS_CLOSE_TO_MARKET = new RejectCode(11500 + 43)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_LIMIT_PRICE_INVALID_PRECISION = new RejectCode(11500 + 44)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_PRICE_INVALID_PRECISION = new RejectCode(11500 + 45)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TAKE_PROFIT_PRICE_INVALID_PRECISION = new RejectCode(11500 + 46)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_LOSS_PRICE_INVALID_PRECISION = new RejectCode(11500 + 47)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_NOT_PENDING_NOR_FILLED = new RejectCode(11500 + 48)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_IS_NOT_MARKET = new RejectCode(11500 + 49)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_IS_NOT_PENDING = new RejectCode(11500 + 50)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_IS_NOT_FILLED = new RejectCode(11500 + 51)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_IS_BEING_EXECUTED = new RejectCode(11500 + 52)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_MAGIC_NUMBER_MODIFY_NOT_ALLOWED = new RejectCode(11500 + 53)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_AMOUNT_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER = new RejectCode(11500 + 54)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_EXPIRATION_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER = new RejectCode(11500 + 55)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_LIMIT_PRICE_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER = new RejectCode(11500 + 56)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_PRICE_MODIFY_NOT_ALLOWED_FOR_MARKET_ORDER = new RejectCode(11500 + 57)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_PARENT_ID_MODIFY_NOT_ALLOWED = new RejectCode(11500 + 58)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TYPE_MODIFY_NOT_ALLOWED = new RejectCode(11500 + 59)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SIDE_MODIFY_NOT_ALLOWED = new RejectCode(11500 + 60)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SYMBOL_MODIFY_NOT_ALLOWED = new RejectCode(11500 + 61)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_PROFIT_NOT_CALCULATED = new RejectCode(11500 + 62)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_COMMISSION_NOT_CALCULATED = new RejectCode(11500 + 63)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_LIMIT_PRICE_GREATER_THAN_ASK_PRICE = new RejectCode(11500 + 64)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_LIMIT_PRICE_LESS_THAN_BID_PRICE = new RejectCode(11500 + 65)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_PRICE_GREATER_THAN_BID_PRICE = new RejectCode(11500 + 66)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STOP_PRICE_LESS_THAN_ASK_PRICE = new RejectCode(11500 + 67)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_IS_CLOSE_TO_LIMIT_PRICE = new RejectCode(11500 + 68)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_IS_CLOSE_TO_STOP_PRICE = new RejectCode(11500 + 69)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_IS_CLOSE_TO_LIMIT_PRICE = new RejectCode(11500 + 70)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_SL_PRICE_IS_CLOSE_TO_STOP_PRICE = new RejectCode(11500 + 71)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_LESS_THAN_BID_PRICE = new RejectCode(11500 + 72)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TP_PRICE_GREATER_THAN_ASK_PRICE = new RejectCode(11500 + 73)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TOO_MANY_ORDERS = new RejectCode(11500 + 74)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_CONTEXT_NOT_CREATED = new RejectCode(11500 + 75)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_CLOSE_PRICE_NOT_CALCULATED = new RejectCode(11500 + 76)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_TYPE_NOT_PROVIDED = new RejectCode(11500 + 77)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_OPEN_PRICE_NOT_CALCULATED = new RejectCode(11500 + 78)
// noinspection PointlessArithmeticExpressionJS
RejectCode.POSITION_DUPLICATE = new RejectCode(11600 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.POSITION_NOT_FOUND = new RejectCode(11600 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.POSITION_IS_USED = new RejectCode(11600 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.POSITION_NOT_SUPPORTED = new RejectCode(11600 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.POSITION_SYMBOL_IS_EMPTY = new RejectCode(11600 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_HISTORY_DUPLICATE = new RejectCode(11700 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_HISTORY_NOT_FOUND = new RejectCode(11700 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_HISTORY_PAGE_SIZE_LIMIT_IS_EXCEEDED = new RejectCode(11700 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_HISTORY_PROFIT_CLOSED_GET_FAILED = new RejectCode(11700 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_HISTORY_ORDERS_CLOSED_SUMMARIES_GET_FAILED = new RejectCode(11700 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CALENDAR_REGULAR_RULE_DUPLICATE = new RejectCode(11900 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CALENDAR_REGULAR_RULE_NOT_FOUND = new RejectCode(11900 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CALENDAR_REGULAR_RULE_IS_USED = new RejectCode(11900 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CALENDAR_OFFTIME_RULE_DUPLICATE = new RejectCode(12000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CALENDAR_OFFTIME_RULE_NOT_FOUND = new RejectCode(12000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CALENDAR_OFFTIME_RULE_IS_USED = new RejectCode(12000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CALENDAR_RULES_NOT_FOUND = new RejectCode(12050 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.COMMISSION_PROFILE_DUPLICATE = new RejectCode(12100 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.COMMISSION_PROFILE_NOT_FOUND = new RejectCode(12100 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.COMMISSION_PROFILE_IS_DISABLED = new RejectCode(12100 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.COMMISSION_PROFILE_IS_USED = new RejectCode(12100 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.COMMISSION_VALUES_RANGE_OVERLAPPED = new RejectCode(12100 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADING_ROUTE_RULE_DUPLICATE = new RejectCode(12200 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADING_ROUTE_RULE_NOT_FOUND = new RejectCode(12200 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADING_ROUTE_RULE_PRIORITY_LIMIT = new RejectCode(12200 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADING_ROUTE_RULE_IS_USED = new RejectCode(12200 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_MAP_DUPLICATE = new RejectCode(12300 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_MAP_NOT_FOUND = new RejectCode(12300 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_MAP_IS_USED = new RejectCode(12300 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.GATEWAY_DUPLICATE = new RejectCode(12400 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.GATEWAY_NOT_FOUND = new RejectCode(12400 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.GATEWAY_MODULE_NOT_FOUND = new RejectCode(12400 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.GATEWAY_IS_USED = new RejectCode(12400 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.GATEWAY_NOT_STARTED = new RejectCode(12400 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.GATEWAY_ERROR_SENDING_TO_LP = new RejectCode(12400 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DATA_FEED_DUPLICATE = new RejectCode(12500 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DATA_FEED_NOT_FOUND = new RejectCode(12500 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DATA_FEED_MODULE_NOT_FOUND = new RejectCode(12500 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DATA_FEED_IS_USED = new RejectCode(12500 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DATA_FEED_NOT_STARTED = new RejectCode(12500 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DATA_FEED_ERROR_SENDING_TO_LP = new RejectCode(12500 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CHART_BAR_NOT_FOUND = new RejectCode(12600 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CHART_BAR_REQUEST_LIMIT_IS_EXCEEDED = new RejectCode(12600 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CHART_BAR_IMPORT_FAILED = new RejectCode(12600 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CHART_BAR_OPERATION_FAILED = new RejectCode(12600 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MARKUP_PROFILE_DUPLICATE = new RejectCode(12700 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MARKUP_PROFILE_NOT_FOUND = new RejectCode(12700 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MARKUP_PROFILE_IS_USED = new RejectCode(12700 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MAIL_SERVER_DUPLICATE = new RejectCode(12800 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MAIL_SERVER_NOT_FOUND = new RejectCode(12800 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MAIL_SERVER_IS_USED = new RejectCode(12800 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEVERAGE_PROFILE_DUPLICATE = new RejectCode(12900 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEVERAGE_PROFILE_NOT_FOUND = new RejectCode(12900 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEVERAGE_PROFILE_IS_USED = new RejectCode(12900 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MARGIN_RATE_PROFILE_DUPLICATE = new RejectCode(13000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MARGIN_RATE_PROFILE_NOT_FOUND = new RejectCode(13000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.MARGIN_RATE_PROFILE_IS_USED = new RejectCode(13000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.NEWS_PROFILE_DUPLICATE = new RejectCode(13100 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.NEWS_PROFILE_NOT_FOUND = new RejectCode(13100 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.NEWS_PROFILE_IS_USED = new RejectCode(13100 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EMAIL_TEMPLATE_DUPLICATE = new RejectCode(13200 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EMAIL_TEMPLATE_NOT_FOUND = new RejectCode(13200 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EMAIL_TEMPLATE_IS_USED = new RejectCode(13200 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.EMAIL_TEMPLATE_WRONG_EVENT_TYPE = new RejectCode(13200 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STATE_SUBSCRIPTION_DUPLICATE = new RejectCode(13300 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STATE_SUBSCRIPTION_NOT_FOUND = new RejectCode(13300 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STATE_SUBSCRIPTION_NO_ORDERS = new RejectCode(13300 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_EVENT_DUPLICATE = new RejectCode(13400 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_EVENT_UNEXPECTED_RECORD_ID = new RejectCode(13400 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_EVENT_REQUIRED_FIELD_MISSING = new RejectCode(13400 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.TRADE_EVENT_LIMIT_IS_EXCEEDED = new RejectCode(13400 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_MARGIN_NOT_SUBSCRIBED = new RejectCode(13500 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_MARGIN_SUBSCRIPTION_NOT_UPDATED = new RejectCode(13500 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_MARGIN_NOT_UNSUBSCRIBED = new RejectCode(13500 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_MARGIN_NOT_CALCULATED = new RejectCode(13500 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STATE_INFO_ALREADY_SUBSCRIBED = new RejectCode(13600 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDER_STATE_INFO_NOT_SUBSCRIBED = new RejectCode(13600 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDERS_BY_PRICE_NOT_SUBSCRIBED = new RejectCode(13700 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDERS_BY_PRICE_SUBSCRIPTION_NOT_UPDATED = new RejectCode(13700 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDERS_BY_PRICE_NOT_UNSUBSCRIBED = new RejectCode(13700 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDERS_SUMMARY_INFO_NOT_SUBSCRIBED = new RejectCode(13800 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDERS_SUMMARY_INFO_SUBSCRIPTION_NOT_UPDATED = new RejectCode(13800 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.ORDERS_SUMMARY_INFO_NOT_UNSUBSCRIBED = new RejectCode(13800 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CLIENT_REPORTS_SUBSCRIPTION_NOT_CHANGED = new RejectCode(13900 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.STORAGE_CACHE_NOT_AVAILABLE = new RejectCode(14000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_DAY_PL_NOT_SUBSCRIBED = new RejectCode(14100 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_DAY_PL_SUBSCRIPTION_NOT_UPDATED = new RejectCode(14100 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_DAY_PL_NOT_UNSUBSCRIBED = new RejectCode(14100 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_PL_NOT_SUBSCRIBED = new RejectCode(14100 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_PL_SUBSCRIPTION_NOT_UPDATED = new RejectCode(14100 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SYMBOL_PL_NOT_UNSUBSCRIBED = new RejectCode(14100 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CLIENT_LOGIN_INFO_NOT_FOUND = new RejectCode(14200 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.UNDERLYING_REST_SERVICE_FAILED = new RejectCode(14300 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.UNDERLYING_REST_SERVICE_RESPONSE_PARSE_ERROR = new RejectCode(14300 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.UNDERLYING_REST_SERVICE_INCORRECT_RESPONSE = new RejectCode(14300 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.UNDERLYING_REST_SERVICE_CONFLICT = new RejectCode(14300 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.UNDERLYING_REST_SERVICE_UNEXPECTED_CODE = new RejectCode(14300 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.UNDERLYING_REST_SERVICE_NOT_FOUND = new RejectCode(14300 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.UNDERLYING_REST_SERVICE_BAD_REQUEST = new RejectCode(14300 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.UNDERLYING_REST_SERVICE_UNAUTHORIZED = new RejectCode(14300 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DASHBOARD_NOT_FOUND = new RejectCode(14400 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.CHART_NOT_FOUND = new RejectCode(14400 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SOCIAL_TRADING_ACCOUNT_MODE_NOT_SUPPORTED = new RejectCode(14500 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SOCIAL_TRADING_ALLOCATION_METHOD_INVALID = new RejectCode(14500 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SOCIAL_TRADING_ACCOUNT_TYPE_INVALID = new RejectCode(14500 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SOCIAL_TRADING_ACCOUNT_COMMISSION_ACCOUNT_INVALID = new RejectCode(14500 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SOCIAL_TRADING_DISABLED = new RejectCode(14500 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SOCIAL_TRADING_ACCOUNT_HAS_ACTIVE_ORDERS = new RejectCode(14500 + 5)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SOCIAL_TRADING_ALLOCATION_FAILED = new RejectCode(14500 + 6)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SOCIAL_TRADING_ACCOUNT_COMMISSION_NOT_CALCULATED = new RejectCode(14500 + 7)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PERSON_DUPLICATE = new RejectCode(14600 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PERSON_NOT_FOUND = new RejectCode(14600 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_SETTINGS_DUPLICATE = new RejectCode(14700 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_SETTINGS_NOT_FOUND = new RejectCode(14700 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.SECURITY_SETTINGS_IS_USED = new RejectCode(14700 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FIREWALL_RULE_DUPLICATE = new RejectCode(14800 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FIREWALL_RULE_NOT_FOUND = new RejectCode(14800 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.FIREWALL_RULE_IS_USED = new RejectCode(14800 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.BLOCKED_IP_EVENT_LOG_OPERATION_FAILED = new RejectCode(14900 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LANGUAGE_DUPLICATE = new RejectCode(15000 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LANGUAGE_NOT_FOUND = new RejectCode(15000 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LANGUAGE_IS_USED = new RejectCode(15000 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PUSH_NOTIFICATION_TEMPLATE_DUPLICATE = new RejectCode(15100 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PUSH_NOTIFICATION_TEMPLATE_NOT_FOUND = new RejectCode(15100 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PUSH_NOTIFICATION_TEMPLATE_IS_USED = new RejectCode(15100 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PUSH_NOTIFICATION_TEMPLATE_IS_DISABLED = new RejectCode(15100 + 3)
// noinspection PointlessArithmeticExpressionJS
RejectCode.PUSH_NOTIFICATION_TEMPLATE_IS_MALFORMED = new RejectCode(15100 + 4)
// noinspection PointlessArithmeticExpressionJS
RejectCode.DOCUMENT_SERVER_NOT_AVAILABLE = new RejectCode(15200 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEGAL_DOCUMENT_GROUP_NOT_FOUND = new RejectCode(15300 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEGAL_DOCUMENT_GROUP_DISABLED = new RejectCode(15300 + 1)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEGAL_DOCUMENT_GROUP_DUPLICATE = new RejectCode(15300 + 2)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEGAL_DOCUMENT_NOT_FOUND = new RejectCode(15400 + 0)
// noinspection PointlessArithmeticExpressionJS
RejectCode.LEGAL_DOCUMENT_DUPLICATE = new RejectCode(15400 + 1)

export { RejectCode };

/**
 * Fast Binary Encoding RejectCode field model
 */
class FieldModelRejectCode extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelRejectCode}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelRejectCode}
   * @param {RejectCode=} defaults Default value, defaults is new RejectCode()
   * @returns {!RejectCode} Result value
   */
  get (defaults = new RejectCode()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new RejectCode(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelRejectCode}
   * @param {!RejectCode} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelRejectCode };
