
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {HostModifyResponse} from './HostModifyResponse';

/**
 * HostModifyRequest struct
 */
class HostModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} hostId
   * @param {string=} hostName
   * @param {number=} newSortOrder
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {string=} newAddress
   * @param {boolean=} newIsEnabled
   * @param {!Map=} settingsToModify
   * @param {!Set=} settingsToDelete
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), hostId = null, hostName = null, newSortOrder = null, newName = null, newComment = null, newDescription = null, newAddress = null, newIsEnabled = null, settingsToModify = new Map(), settingsToDelete = new Set()) {
    super()
    super.copy(parent)
    this.HostId = hostId
    this.HostName = hostName
    this.NewSortOrder = newSortOrder
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewAddress = newAddress
    this.NewIsEnabled = newIsEnabled
    this.SettingsToModify = settingsToModify
    this.SettingsToDelete = settingsToDelete
  }

  /**
   * Copy struct (shallow copy)
   * @this {!HostModifyRequest}
   * @param {!HostModifyRequest} other Other struct
   * @returns {!HostModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.HostId != null) {
      this.HostId = new UUID(other.HostId)
    } else {
      this.HostId = null
    }
    if (other.HostName != null) {
      this.HostName = other.HostName
    } else {
      this.HostName = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewAddress != null) {
      this.NewAddress = other.NewAddress
    } else {
      this.NewAddress = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    if (other.SettingsToModify != null) {
      this.SettingsToModify = new Map()
      Object.keys(other.SettingsToModify).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = key
          if (other.SettingsToModify[key] != null) {
            let tempValue
            tempValue = other.SettingsToModify[key]
            this.SettingsToModify.set(tempKey, tempValue)
          } else {
            this.SettingsToModify.set(tempKey, null)
          }
        }
      })
    } else {
      this.SettingsToModify = null
    }
    if (other.SettingsToDelete != null) {
      this.SettingsToDelete = new Set()
      for (let item of other.SettingsToDelete) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.SettingsToDelete.add(tempItem)
        } else {
          this.SettingsToDelete.add(null)
        }
      }
    } else {
      this.SettingsToDelete = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!HostModifyRequest}
   * @returns {!HostModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new HostModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new HostModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!HostModifyRequest}
   * @param {!HostModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof HostModifyRequest)) {
      throw new TypeError('Instance of HostModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!HostModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      HostId: ((this.HostId != null) ? this.HostId.toString() : null),
      HostName: ((this.HostName != null) ? this.HostName : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewAddress: ((this.NewAddress != null) ? this.NewAddress : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null),
      SettingsToModify: ((this.SettingsToModify != null) ? fbe.MapToObject(new Map(Array.from(this.SettingsToModify, item => [((item[0] != null) ? item[0] : null), ((item[1] != null) ? item[1] : null)]))) : null),
      SettingsToDelete: ((this.SettingsToDelete != null) ? Array.from(this.SettingsToDelete, item => ((item != null) ? item : null)) : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return HostModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!HostModifyRequest} other Object value
   * @returns {!HostModifyRequest} Created struct
   */
  static fromObject (other) {
    return new HostModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!HostModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return HostModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!HostModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6045
  }
}

export { HostModifyRequest };

/**
 * Fast Binary Encoding HostModifyRequest field model
 */
class FieldModelHostModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.HostId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.HostName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.HostId.fbeOffset + this.HostId.fbeSize), buffer, this.HostId.fbeOffset + this.HostId.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.HostName.fbeOffset + this.HostName.fbeSize), buffer, this.HostName.fbeOffset + this.HostName.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewAddress = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewAddress.fbeOffset + this.NewAddress.fbeSize), buffer, this.NewAddress.fbeOffset + this.NewAddress.fbeSize)
    this.SettingsToModify = new fbe.FieldModelMap(new fbe.FieldModelString(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize), new fbe.FieldModelString(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize), buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize)
    this.SettingsToDelete = new fbe.FieldModelSet(new fbe.FieldModelString(buffer, this.SettingsToModify.fbeOffset + this.SettingsToModify.fbeSize), buffer, this.SettingsToModify.fbeOffset + this.SettingsToModify.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelHostModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelHostModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelHostModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.HostId.fbeSize + this.HostName.fbeSize + this.NewSortOrder.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewAddress.fbeSize + this.NewIsEnabled.fbeSize + this.SettingsToModify.fbeSize + this.SettingsToDelete.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelHostModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.HostId.fbeExtra + this.HostName.fbeExtra + this.NewSortOrder.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewAddress.fbeExtra + this.NewIsEnabled.fbeExtra + this.SettingsToModify.fbeExtra + this.SettingsToDelete.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelHostModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelHostModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelHostModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6045
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelHostModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelHostModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.HostId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HostId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HostId.fbeSize

    if ((fbeCurrentSize + this.HostName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HostName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HostName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAddress.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.SettingsToModify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SettingsToModify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SettingsToModify.fbeSize

    if ((fbeCurrentSize + this.SettingsToDelete.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SettingsToDelete.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SettingsToDelete.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelHostModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelHostModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelHostModifyRequest}
   * @param {!HostModifyRequest} fbeValue Default value, defaults is new HostModifyRequest()
   * @returns {!HostModifyRequest} HostModifyRequest value
   */
  get (fbeValue = new HostModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelHostModifyRequest}
   * @param {!HostModifyRequest} fbeValue HostModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.HostId.fbeSize) <= fbeStructSize) {
      fbeValue.HostId = this.HostId.get()
    } else {
      fbeValue.HostId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HostId.fbeSize

    if ((fbeCurrentSize + this.HostName.fbeSize) <= fbeStructSize) {
      fbeValue.HostName = this.HostName.get()
    } else {
      fbeValue.HostName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HostName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewAddress.fbeSize) <= fbeStructSize) {
      fbeValue.NewAddress = this.NewAddress.get()
    } else {
      fbeValue.NewAddress = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAddress.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.SettingsToModify.fbeSize) <= fbeStructSize) {
      this.SettingsToModify.get(fbeValue.SettingsToModify)
    } else {
      fbeValue.SettingsToModify.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SettingsToModify.fbeSize

    if ((fbeCurrentSize + this.SettingsToDelete.fbeSize) <= fbeStructSize) {
      this.SettingsToDelete.get(fbeValue.SettingsToDelete)
    } else {
      fbeValue.SettingsToDelete.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SettingsToDelete.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelHostModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelHostModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelHostModifyRequest}
   * @param {!HostModifyRequest} fbeValue HostModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelHostModifyRequest}
   * @param {HostModifyRequest} fbeValue HostModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.HostId.set(fbeValue.HostId)
    this.HostName.set(fbeValue.HostName)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewAddress.set(fbeValue.NewAddress)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
    this.SettingsToModify.set(fbeValue.SettingsToModify)
    this.SettingsToDelete.set(fbeValue.SettingsToDelete)
  }
}

export { FieldModelHostModifyRequest };

/**
 * Fast Binary Encoding HostModifyRequest model
 */
class HostModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelHostModifyRequest(this.buffer, 4)
  }

  /**
   * Get the HostModifyRequest model
   * @this {!HostModifyRequestModel}
   * @returns {!FieldModelHostModifyRequest} model HostModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!HostModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!HostModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return HostModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!HostModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelHostModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!HostModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!HostModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!HostModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!HostModifyRequestModel}
   * @param {!HostModifyRequest} value HostModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!HostModifyRequestModel}
   * @param {!HostModifyRequest} value HostModifyRequest value, defaults is new HostModifyRequest()
   * @return {!object} Deserialized HostModifyRequest value and its size
   */
  deserialize (value = new HostModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new HostModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new HostModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!HostModifyRequestModel}
   * @param {!number} prev Previous HostModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { HostModifyRequestModel };
HostModifyRequest.__has_response = true;
HostModifyRequest.__response_class = HostModifyResponse;
