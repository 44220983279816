import { forEach } from 'lodash';
import { LegalDocumentDescriptionModel } from './LegalDocumentDescription';
import { LegalDocumentGroupModel, LegalDocumentGroupWithDocumentsDescriptionsModel } from './LegalDocumentGroup';

export type LegalDocModel = LegalDocumentGroupModel & {
    Documents: LegalDocumentDescriptionModel[];
};

export const mapLegalDocumentGroupWithDocumentsDescriptionsModelsToLegalDocModels = (
    records: LegalDocumentGroupWithDocumentsDescriptionsModel[],
): LegalDocModel[] => {
    const result: LegalDocModel[] = [];
    forEach(records, (item) => {
        result.push({
            ...item.Group,
            Documents: item.Documents,
        });
    });
    return result;
};

export const getMaxSortOrder = (data: LegalDocModel[]) =>
    data.reduce((acc, item) => ((item.SortOrder ?? 0) > acc ? item.SortOrder ?? 0 : acc), 0);
