
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * TradeTransactionType enum
 */
class TradeTransactionType {
  /**
   * Initialize enum with a given value
   * @param {TradeTransactionType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof TradeTransactionType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!TradeTransactionType}
   * @param {!TradeTransactionType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradeTransactionType)) {
      throw new TypeError('Instance of TradeTransactionType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!TradeTransactionType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!TradeTransactionType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === TradeTransactionType.UNKNOWN.value) {
      return 'UNKNOWN'
    }
    if (this.value === TradeTransactionType.DEPOSIT.value) {
      return 'DEPOSIT'
    }
    if (this.value === TradeTransactionType.CREDIT.value) {
      return 'CREDIT'
    }
    if (this.value === TradeTransactionType.TRANSFER.value) {
      return 'TRANSFER'
    }
    if (this.value === TradeTransactionType.ORDER_OPENED.value) {
      return 'ORDER_OPENED'
    }
    if (this.value === TradeTransactionType.ORDER_FILLED.value) {
      return 'ORDER_FILLED'
    }
    if (this.value === TradeTransactionType.ORDER_FILLED_PARTIALLY.value) {
      return 'ORDER_FILLED_PARTIALLY'
    }
    if (this.value === TradeTransactionType.ORDER_ACTIVATED.value) {
      return 'ORDER_ACTIVATED'
    }
    if (this.value === TradeTransactionType.ORDER_CANCELED.value) {
      return 'ORDER_CANCELED'
    }
    if (this.value === TradeTransactionType.ORDER_EXPIRED.value) {
      return 'ORDER_EXPIRED'
    }
    if (this.value === TradeTransactionType.ORDER_CLOSED.value) {
      return 'ORDER_CLOSED'
    }
    if (this.value === TradeTransactionType.ORDER_CLOSED_PARTIALLY.value) {
      return 'ORDER_CLOSED_PARTIALLY'
    }
    if (this.value === TradeTransactionType.ORDER_REJECTED.value) {
      return 'ORDER_REJECTED'
    }
    if (this.value === TradeTransactionType.ORDER_MODIFIED.value) {
      return 'ORDER_MODIFIED'
    }
    if (this.value === TradeTransactionType.ACCOUNT_CREATED.value) {
      return 'ACCOUNT_CREATED'
    }
    if (this.value === TradeTransactionType.ACCOUNT_MODIFIED.value) {
      return 'ACCOUNT_MODIFIED'
    }
    if (this.value === TradeTransactionType.ACCOUNT_DELETED.value) {
      return 'ACCOUNT_DELETED'
    }
    if (this.value === TradeTransactionType.DIVIDENDS_ADJUSTMENT.value) {
      return 'DIVIDENDS_ADJUSTMENT'
    }
    if (this.value === TradeTransactionType.SPLIT_ADJUSTMENT.value) {
      return 'SPLIT_ADJUSTMENT'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!TradeTransactionType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!TradeTransactionType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!TradeTransactionType} Created enum
   */
  static fromObject (other) {
    return new TradeTransactionType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.UNKNOWN = new TradeTransactionType(0 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.DEPOSIT = new TradeTransactionType(1 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.CREDIT = new TradeTransactionType(2 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.TRANSFER = new TradeTransactionType(3 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_OPENED = new TradeTransactionType(4 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_FILLED = new TradeTransactionType(5 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_FILLED_PARTIALLY = new TradeTransactionType(6 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_ACTIVATED = new TradeTransactionType(7 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_CANCELED = new TradeTransactionType(8 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_EXPIRED = new TradeTransactionType(9 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_CLOSED = new TradeTransactionType(10 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_CLOSED_PARTIALLY = new TradeTransactionType(11 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_REJECTED = new TradeTransactionType(12 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ORDER_MODIFIED = new TradeTransactionType(13 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ACCOUNT_CREATED = new TradeTransactionType(14 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ACCOUNT_MODIFIED = new TradeTransactionType(15 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.ACCOUNT_DELETED = new TradeTransactionType(16 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.DIVIDENDS_ADJUSTMENT = new TradeTransactionType(17 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeTransactionType.SPLIT_ADJUSTMENT = new TradeTransactionType(18 + 0)

export { TradeTransactionType };

/**
 * Fast Binary Encoding TradeTransactionType field model
 */
class FieldModelTradeTransactionType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelTradeTransactionType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelTradeTransactionType}
   * @param {TradeTransactionType=} defaults Default value, defaults is new TradeTransactionType()
   * @returns {!TradeTransactionType} Result value
   */
  get (defaults = new TradeTransactionType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new TradeTransactionType(this.readByte(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelTradeTransactionType}
   * @param {!TradeTransactionType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeByte(this.fbeOffset, value.value)
  }
}

export { FieldModelTradeTransactionType };
