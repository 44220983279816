import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { TradingRoute } from '@models/TradingRoute';
import { UUID } from '@api/fbe/uuid';
import { UInt64 } from '@api/fbe/int64';
import { BinaryOperatorType } from '@api/fbe/core/BinaryOperatorType';
import { OrderSide } from '@api/fbe/core/OrderSide';
import { TradingRouteActionDelay } from '@api/fbe/core/TradingRouteActionDelay';
import { TradingRouteActionForwardToGateway } from '@api/fbe/core/TradingRouteActionForwardToGateway';
import { TradingRouteActionType } from '@api/fbe/core/TradingRouteActionType';
import { TradingRouteCondition } from '@api/fbe/core/TradingRouteCondition';
import { TradingRouteConditionType } from '@api/fbe/core/TradingRouteConditionType';
import { TradingRouteRequestType } from '@api/fbe/core/TradingRouteRequestType';
import { keyMap, t } from '@localization';
import { TradingRouteRulesGetRequest } from '@api/fbe/manager/TradingRouteRulesGetRequest';
import { TradingRouteRuleCreateRequest } from '@api/fbe/manager/TradingRouteRuleCreateRequest';
import { TradingRouteRuleModifyRequest } from '@api/fbe/manager/TradingRouteRuleModifyRequest';
import { TradingRouteRuleDeleteRequest } from '@api/fbe/manager/TradingRouteRuleDeleteRequest';

class TradingRouteStore {
    errorTracker = new ErrorsTracker({ title: 'TradingRoutes' });

    constructor() {
        makeAutoObservable(this);
    }

    data: TradingRoute[] = [];

    isLoading: boolean = false;

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setData(value: TradingRoute[]) {
        this.data = value;
    }

    updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            this.update({
                ...targetItem,
                SortOrder: newIndex,
            });
        } else {
            throw new Error(t(keyMap.messages.tradingRoutes.reorderError));
        }
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new TradingRouteRulesGetRequest();
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.Rules.map(
                (rule): TradingRoute => ({
                    RequestType: Array.from(rule.RequestType).map((item) => item.valueOf()),
                    id: rule.id.toString(),
                    IsEnabled: rule.IsEnabled,
                    Description: rule.Description,
                    Name: rule.Name,
                    SortOrder: rule.SortOrder,
                    Condition: rule.Condition.map((item) => ({
                        ConditionType: item.ConditionType.valueOf(),
                        OperatorType: item.OperatorType.valueOf(),
                        OrderSide: Array.from(item.OrderSide).map((val) => val.valueOf()),
                        OrderVolume: item.OrderVolume?.valueOf(),
                        SymbolId: Array.from(item.SymbolId).map((val) => val.toString()),
                        SymbolGroupId: Array.from(item.SymbolGroupId).map((val) => val.toString()),
                        AccountGroupId: Array.from(item.AccountGroupId).map((val) => val.toString()),
                        AccountLogin: Array.from(item.AccountLogin).map((val) => val.toString()),
                    })),
                    ActionDelay: {
                        IsDelayed: !!rule.ActionDelay,
                        DelayMax: rule.ActionDelay?.DelayMax.toInt32() ?? null,
                        DelayMin: rule.ActionDelay?.DelayMin.toInt32() ?? null,
                    },

                    ActionType: rule.ActionType.valueOf(),
                    GatewayId: rule.ActionForwardToGateway?.GatewayId.toString(),
                    GatewayAccountId: rule.GatewayAccountId ?? null,
                    IsEnabledGatewayAccountId: !!rule.GatewayAccountId,
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(route: TradingRoute) {
        const request = new TradingRouteRuleCreateRequest();
        request.IsEnabled = route.IsEnabled;
        request.Name = route.Name;
        request.RequestType = new Set<TradingRouteRequestType>(
            route.RequestType.map((item) => new TradingRouteRequestType(item)),
        );
        request.Description = route.Description;
        request.SortOrder = route.SortOrder;
        request.GatewayAccountId = route.IsEnabledGatewayAccountId ? route.GatewayAccountId || null : null;
        request.Condition = route.Condition.map(
            (item) =>
                new TradingRouteCondition(
                    new TradingRouteConditionType(item.ConditionType),
                    new BinaryOperatorType(item.OperatorType),
                    item.OrderSide ? new Set<OrderSide>(item.OrderSide.map((side) => new OrderSide(side))) : undefined,
                    item.OrderVolume,
                    item.SymbolId ? new Set<UUID>(item.SymbolId.map((val) => UUID.fromString(val))) : undefined,
                    item.SymbolGroupId
                        ? new Set<UUID>(item.SymbolGroupId.map((val) => UUID.fromString(val)))
                        : undefined,
                    item.AccountGroupId
                        ? new Set<UUID>(item.AccountGroupId.map((val) => UUID.fromString(val)))
                        : undefined,
                    item.AccountLogin
                        ? new Set<UInt64>(item.AccountLogin.map((val) => UInt64.fromString(val)))
                        : undefined,
                ),
        );
        if (!route.ActionDelay.IsDelayed) {
            request.ActionDelay = null;
        } else if (typeof route.ActionDelay.DelayMin === 'number' && typeof route.ActionDelay.DelayMax === 'number') {
            request.ActionDelay = new TradingRouteActionDelay(
                UInt64.fromNumber(route.ActionDelay.DelayMin),
                UInt64.fromNumber(route.ActionDelay.DelayMax),
            );
        }
        request.ActionType = new TradingRouteActionType(route.ActionType!);
        if (route.GatewayId)
            request.ActionForwardToGateway = new TradingRouteActionForwardToGateway(UUID.fromString(route.GatewayId));
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(route: TradingRoute) {
        const request = new TradingRouteRuleModifyRequest();
        request.RuleId = UUID.fromString(route.id);
        request.NewIsEnabled = route.IsEnabled;
        request.NewName = route.Name;
        request.NewRequestType = new Set<TradingRouteRequestType>(
            route.RequestType.map((item) => new TradingRouteRequestType(item)),
        );
        request.NewDescription = route.Description;
        request.NewSortOrder = route.SortOrder;
        request.NewGatewayAccountId = route.IsEnabledGatewayAccountId ? route.GatewayAccountId || null : null;
        if (request.NewGatewayAccountId === null) {
            request.NewGatewayAccountIdReset = true;
        }
        request.NewCondition = route.Condition.map(
            (item) =>
                new TradingRouteCondition(
                    new TradingRouteConditionType(item.ConditionType),
                    new BinaryOperatorType(item.OperatorType),
                    item.OrderSide ? new Set<OrderSide>(item.OrderSide.map((side) => new OrderSide(side))) : undefined,
                    item.OrderVolume,
                    item.SymbolId ? new Set<UUID>(item.SymbolId.map((val) => UUID.fromString(val))) : undefined,
                    item.SymbolGroupId
                        ? new Set<UUID>(item.SymbolGroupId.map((val) => UUID.fromString(val)))
                        : undefined,
                    item.AccountGroupId
                        ? new Set<UUID>(item.AccountGroupId.map((val) => UUID.fromString(val)))
                        : undefined,
                    item.AccountLogin
                        ? new Set<UInt64>(item.AccountLogin.map((val) => UInt64.fromString(val)))
                        : undefined,
                ),
        );
        request.ConditionReset = !request.NewCondition.length;
        if (!route.ActionDelay.IsDelayed) {
            request.NewActionDelay = null;
            request.NewActionDelayReset = true;
        } else if (typeof route.ActionDelay.DelayMin === 'number' && typeof route.ActionDelay.DelayMax === 'number') {
            request.NewActionDelay = new TradingRouteActionDelay(
                UInt64.fromNumber(route.ActionDelay.DelayMin),
                UInt64.fromNumber(route.ActionDelay.DelayMax),
            );
        }
        request.NewActionType = new TradingRouteActionType(route.ActionType!);
        if (route.GatewayId)
            request.NewActionForwardToGateway = new TradingRouteActionForwardToGateway(
                UUID.fromString(route.GatewayId),
            );
        request.NewSortOrder = route.SortOrder;
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new TradingRouteRuleDeleteRequest();
        request.RuleId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const tradingRouteStore = new TradingRouteStore();
