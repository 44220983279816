
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateNotify} from './ConfigUpdateNotify';
import {FieldModelConfigUpdateNotify} from './ConfigUpdateNotify';
import {UpdateType} from '../core/UpdateType';
import {FieldModelUpdateType} from '../core/UpdateType';
import {FirewallRule} from '../core/FirewallRule';
import {FieldModelFirewallRule} from '../core/FirewallRule';

/**
 * FirewallRuleUpdateNotify struct
 */
class FirewallRuleUpdateNotify extends ConfigUpdateNotify {
  /**
   * Initialize struct
   * @param {!ConfigUpdateNotify=} parent
   * @param {!UpdateType=} updateType
   * @param {FirewallRule=} previous
   * @param {FirewallRule=} current
   * @constructor
   */
  constructor (parent = new ConfigUpdateNotify(), updateType = new UpdateType(), previous = null, current = null) {
    super()
    super.copy(parent)
    this.UpdateType = updateType
    this.Previous = previous
    this.Current = current
  }

  /**
   * Copy struct (shallow copy)
   * @this {!FirewallRuleUpdateNotify}
   * @param {!FirewallRuleUpdateNotify} other Other struct
   * @returns {!FirewallRuleUpdateNotify} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.UpdateType != null) {
      this.UpdateType = UpdateType.fromObject(other.UpdateType)
    } else {
      this.UpdateType = null
    }
    if (other.Previous != null) {
      this.Previous = FirewallRule.fromObject(other.Previous)
    } else {
      this.Previous = null
    }
    if (other.Current != null) {
      this.Current = FirewallRule.fromObject(other.Current)
    } else {
      this.Current = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!FirewallRuleUpdateNotify}
   * @returns {!FirewallRuleUpdateNotify} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new FirewallRuleUpdateNotifyModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new FirewallRuleUpdateNotifyModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!FirewallRuleUpdateNotify}
   * @param {!FirewallRuleUpdateNotify} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof FirewallRuleUpdateNotify)) {
      throw new TypeError('Instance of FirewallRuleUpdateNotify is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!FirewallRuleUpdateNotify}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      UpdateType: ((this.UpdateType != null) ? this.UpdateType : null),
      Previous: ((this.Previous != null) ? this.Previous : null),
      Current: ((this.Current != null) ? this.Current : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return FirewallRuleUpdateNotify.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!FirewallRuleUpdateNotify} other Object value
   * @returns {!FirewallRuleUpdateNotify} Created struct
   */
  static fromObject (other) {
    return new FirewallRuleUpdateNotify().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!FirewallRuleUpdateNotify}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return FirewallRuleUpdateNotify.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!FirewallRuleUpdateNotify}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6553
  }
}

export { FirewallRuleUpdateNotify };

/**
 * Fast Binary Encoding FirewallRuleUpdateNotify field model
 */
class FieldModelFirewallRuleUpdateNotify extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateNotify(buffer, 4 + 4)
    this.UpdateType = new FieldModelUpdateType(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Previous = new fbe.FieldModelOptional(new FieldModelFirewallRule(buffer, this.UpdateType.fbeOffset + this.UpdateType.fbeSize), buffer, this.UpdateType.fbeOffset + this.UpdateType.fbeSize)
    this.Current = new fbe.FieldModelOptional(new FieldModelFirewallRule(buffer, this.Previous.fbeOffset + this.Previous.fbeSize), buffer, this.Previous.fbeOffset + this.Previous.fbeSize)
  }

  /**
   * Get the ConfigUpdateNotify field model
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @returns {!FieldModelConfigUpdateNotify} ConfigUpdateNotify field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.UpdateType.fbeSize + this.Previous.fbeSize + this.Current.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.UpdateType.fbeExtra + this.Previous.fbeExtra + this.Current.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelFirewallRuleUpdateNotify.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6553
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.UpdateType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UpdateType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UpdateType.fbeSize

    if ((fbeCurrentSize + this.Previous.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Previous.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Previous.fbeSize

    if ((fbeCurrentSize + this.Current.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Current.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Current.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @param {!FirewallRuleUpdateNotify} fbeValue Default value, defaults is new FirewallRuleUpdateNotify()
   * @returns {!FirewallRuleUpdateNotify} FirewallRuleUpdateNotify value
   */
  get (fbeValue = new FirewallRuleUpdateNotify()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @param {!FirewallRuleUpdateNotify} fbeValue FirewallRuleUpdateNotify value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.UpdateType.fbeSize) <= fbeStructSize) {
      fbeValue.UpdateType = this.UpdateType.get()
    } else {
      fbeValue.UpdateType = new UpdateType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UpdateType.fbeSize

    if ((fbeCurrentSize + this.Previous.fbeSize) <= fbeStructSize) {
      fbeValue.Previous = this.Previous.get()
    } else {
      fbeValue.Previous = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Previous.fbeSize

    if ((fbeCurrentSize + this.Current.fbeSize) <= fbeStructSize) {
      fbeValue.Current = this.Current.get()
    } else {
      fbeValue.Current = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Current.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @param {!FirewallRuleUpdateNotify} fbeValue FirewallRuleUpdateNotify value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelFirewallRuleUpdateNotify}
   * @param {FirewallRuleUpdateNotify} fbeValue FirewallRuleUpdateNotify value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.UpdateType.set(fbeValue.UpdateType)
    this.Previous.set(fbeValue.Previous)
    this.Current.set(fbeValue.Current)
  }
}

export { FieldModelFirewallRuleUpdateNotify };

/**
 * Fast Binary Encoding FirewallRuleUpdateNotify model
 */
class FirewallRuleUpdateNotifyModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelFirewallRuleUpdateNotify(this.buffer, 4)
  }

  /**
   * Get the FirewallRuleUpdateNotify model
   * @this {!FirewallRuleUpdateNotifyModel}
   * @returns {!FieldModelFirewallRuleUpdateNotify} model FirewallRuleUpdateNotify model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!FirewallRuleUpdateNotifyModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!FirewallRuleUpdateNotifyModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return FirewallRuleUpdateNotifyModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!FirewallRuleUpdateNotifyModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelFirewallRuleUpdateNotify.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!FirewallRuleUpdateNotifyModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!FirewallRuleUpdateNotifyModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!FirewallRuleUpdateNotifyModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!FirewallRuleUpdateNotifyModel}
   * @param {!FirewallRuleUpdateNotify} value FirewallRuleUpdateNotify value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!FirewallRuleUpdateNotifyModel}
   * @param {!FirewallRuleUpdateNotify} value FirewallRuleUpdateNotify value, defaults is new FirewallRuleUpdateNotify()
   * @return {!object} Deserialized FirewallRuleUpdateNotify value and its size
   */
  deserialize (value = new FirewallRuleUpdateNotify()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new FirewallRuleUpdateNotify(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new FirewallRuleUpdateNotify(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!FirewallRuleUpdateNotifyModel}
   * @param {!number} prev Previous FirewallRuleUpdateNotify model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { FirewallRuleUpdateNotifyModel };
