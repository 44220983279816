
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {RightsMode} from './RightsMode';
import {FieldModelRightsMode} from './RightsMode';
import {ManagerRight} from './ManagerRight';
import {FieldModelManagerRight} from './ManagerRight';

/**
 * ManagerRights struct
 */
class ManagerRights {
  /**
   * Initialize struct
   * @param {!Array=} accountGroups
   * @param {!Array=} symbolGroups
   * @param {!RightsMode=} accounts
   * @param {!RightsMode=} symbols
   * @param {!RightsMode=} system
   * @param {!RightsMode=} modules
   * @param {!RightsMode=} platforms
   * @param {!RightsMode=} managers
   * @param {!RightsMode=} currencies
   * @param {!RightsMode=} calendars
   * @param {!RightsMode=} profiles
   * @param {!RightsMode=} dataFeeds
   * @param {!RightsMode=} gateways
   * @param {!RightsMode=} tradingRoutes
   * @param {!RightsMode=} quotesHistory
   * @param {!RightsMode=} reports
   * @param {!RightsMode=} monitoring
   * @param {!Set=} rights
   * @param {!RightsMode=} persons
   * @param {!RightsMode=} securitySettings
   * @param {!RightsMode=} firewallRules
   * @param {!RightsMode=} documents
   * @constructor
   */
  constructor (accountGroups = [], symbolGroups = [], accounts = new RightsMode(), symbols = new RightsMode(), system = new RightsMode(), modules = new RightsMode(), platforms = new RightsMode(), managers = new RightsMode(), currencies = new RightsMode(), calendars = new RightsMode(), profiles = new RightsMode(), dataFeeds = new RightsMode(), gateways = new RightsMode(), tradingRoutes = new RightsMode(), quotesHistory = new RightsMode(), reports = new RightsMode(), monitoring = new RightsMode(), rights = new Set(), persons = new RightsMode(), securitySettings = new RightsMode(), firewallRules = new RightsMode(), documents = new RightsMode()) {
    this.AccountGroups = accountGroups
    this.SymbolGroups = symbolGroups
    this.Accounts = accounts
    this.Symbols = symbols
    this.System = system
    this.Modules = modules
    this.Platforms = platforms
    this.Managers = managers
    this.Currencies = currencies
    this.Calendars = calendars
    this.Profiles = profiles
    this.DataFeeds = dataFeeds
    this.Gateways = gateways
    this.TradingRoutes = tradingRoutes
    this.QuotesHistory = quotesHistory
    this.Reports = reports
    this.Monitoring = monitoring
    this.Rights = rights
    this.Persons = persons
    this.SecuritySettings = securitySettings
    this.FirewallRules = firewallRules
    this.Documents = documents
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ManagerRights}
   * @param {!ManagerRights} other Other struct
   * @returns {!ManagerRights} This struct
   */
  copy (other) {
    if (other.AccountGroups != null) {
      this.AccountGroups = []
      for (let item of other.AccountGroups) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.AccountGroups.push(tempItem)
        } else {
          this.AccountGroups.push(null)
        }
      }
    } else {
      this.AccountGroups = null
    }
    if (other.SymbolGroups != null) {
      this.SymbolGroups = []
      for (let item of other.SymbolGroups) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.SymbolGroups.push(tempItem)
        } else {
          this.SymbolGroups.push(null)
        }
      }
    } else {
      this.SymbolGroups = null
    }
    if (other.Accounts != null) {
      this.Accounts = RightsMode.fromObject(other.Accounts)
    } else {
      this.Accounts = null
    }
    if (other.Symbols != null) {
      this.Symbols = RightsMode.fromObject(other.Symbols)
    } else {
      this.Symbols = null
    }
    if (other.System != null) {
      this.System = RightsMode.fromObject(other.System)
    } else {
      this.System = null
    }
    if (other.Modules != null) {
      this.Modules = RightsMode.fromObject(other.Modules)
    } else {
      this.Modules = null
    }
    if (other.Platforms != null) {
      this.Platforms = RightsMode.fromObject(other.Platforms)
    } else {
      this.Platforms = null
    }
    if (other.Managers != null) {
      this.Managers = RightsMode.fromObject(other.Managers)
    } else {
      this.Managers = null
    }
    if (other.Currencies != null) {
      this.Currencies = RightsMode.fromObject(other.Currencies)
    } else {
      this.Currencies = null
    }
    if (other.Calendars != null) {
      this.Calendars = RightsMode.fromObject(other.Calendars)
    } else {
      this.Calendars = null
    }
    if (other.Profiles != null) {
      this.Profiles = RightsMode.fromObject(other.Profiles)
    } else {
      this.Profiles = null
    }
    if (other.DataFeeds != null) {
      this.DataFeeds = RightsMode.fromObject(other.DataFeeds)
    } else {
      this.DataFeeds = null
    }
    if (other.Gateways != null) {
      this.Gateways = RightsMode.fromObject(other.Gateways)
    } else {
      this.Gateways = null
    }
    if (other.TradingRoutes != null) {
      this.TradingRoutes = RightsMode.fromObject(other.TradingRoutes)
    } else {
      this.TradingRoutes = null
    }
    if (other.QuotesHistory != null) {
      this.QuotesHistory = RightsMode.fromObject(other.QuotesHistory)
    } else {
      this.QuotesHistory = null
    }
    if (other.Reports != null) {
      this.Reports = RightsMode.fromObject(other.Reports)
    } else {
      this.Reports = null
    }
    if (other.Monitoring != null) {
      this.Monitoring = RightsMode.fromObject(other.Monitoring)
    } else {
      this.Monitoring = null
    }
    if (other.Rights != null) {
      this.Rights = new Set()
      for (let item of other.Rights) {
        if (item != null) {
          let tempItem
          tempItem = ManagerRight.fromObject(item)
          this.Rights.add(tempItem)
        } else {
          this.Rights.add(null)
        }
      }
    } else {
      this.Rights = null
    }
    if (other.Persons != null) {
      this.Persons = RightsMode.fromObject(other.Persons)
    } else {
      this.Persons = null
    }
    if (other.SecuritySettings != null) {
      this.SecuritySettings = RightsMode.fromObject(other.SecuritySettings)
    } else {
      this.SecuritySettings = null
    }
    if (other.FirewallRules != null) {
      this.FirewallRules = RightsMode.fromObject(other.FirewallRules)
    } else {
      this.FirewallRules = null
    }
    if (other.Documents != null) {
      this.Documents = RightsMode.fromObject(other.Documents)
    } else {
      this.Documents = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ManagerRights}
   * @returns {!ManagerRights} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ManagerRightsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ManagerRightsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ManagerRights}
   * @param {!ManagerRights} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ManagerRights)) {
      throw new TypeError('Instance of ManagerRights is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ManagerRights}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      AccountGroups: ((this.AccountGroups != null) ? Array.from(this.AccountGroups, item => ((item != null) ? item : null)) : null),
      SymbolGroups: ((this.SymbolGroups != null) ? Array.from(this.SymbolGroups, item => ((item != null) ? item : null)) : null),
      Accounts: ((this.Accounts != null) ? this.Accounts : null),
      Symbols: ((this.Symbols != null) ? this.Symbols : null),
      System: ((this.System != null) ? this.System : null),
      Modules: ((this.Modules != null) ? this.Modules : null),
      Platforms: ((this.Platforms != null) ? this.Platforms : null),
      Managers: ((this.Managers != null) ? this.Managers : null),
      Currencies: ((this.Currencies != null) ? this.Currencies : null),
      Calendars: ((this.Calendars != null) ? this.Calendars : null),
      Profiles: ((this.Profiles != null) ? this.Profiles : null),
      DataFeeds: ((this.DataFeeds != null) ? this.DataFeeds : null),
      Gateways: ((this.Gateways != null) ? this.Gateways : null),
      TradingRoutes: ((this.TradingRoutes != null) ? this.TradingRoutes : null),
      QuotesHistory: ((this.QuotesHistory != null) ? this.QuotesHistory : null),
      Reports: ((this.Reports != null) ? this.Reports : null),
      Monitoring: ((this.Monitoring != null) ? this.Monitoring : null),
      Rights: ((this.Rights != null) ? Array.from(this.Rights, item => ((item != null) ? item : null)) : null),
      Persons: ((this.Persons != null) ? this.Persons : null),
      SecuritySettings: ((this.SecuritySettings != null) ? this.SecuritySettings : null),
      FirewallRules: ((this.FirewallRules != null) ? this.FirewallRules : null),
      Documents: ((this.Documents != null) ? this.Documents : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ManagerRights.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ManagerRights} other Object value
   * @returns {!ManagerRights} Created struct
   */
  static fromObject (other) {
    return new ManagerRights().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ManagerRights}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ManagerRights.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ManagerRights}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 40
  }
}

export { ManagerRights };

/**
 * Fast Binary Encoding ManagerRights field model
 */
class FieldModelManagerRights extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.AccountGroups = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, 4 + 4), buffer, 4 + 4)
    this.SymbolGroups = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.AccountGroups.fbeOffset + this.AccountGroups.fbeSize), buffer, this.AccountGroups.fbeOffset + this.AccountGroups.fbeSize)
    this.Accounts = new FieldModelRightsMode(buffer, this.SymbolGroups.fbeOffset + this.SymbolGroups.fbeSize)
    this.Symbols = new FieldModelRightsMode(buffer, this.Accounts.fbeOffset + this.Accounts.fbeSize)
    this.System = new FieldModelRightsMode(buffer, this.Symbols.fbeOffset + this.Symbols.fbeSize)
    this.Modules = new FieldModelRightsMode(buffer, this.System.fbeOffset + this.System.fbeSize)
    this.Platforms = new FieldModelRightsMode(buffer, this.Modules.fbeOffset + this.Modules.fbeSize)
    this.Managers = new FieldModelRightsMode(buffer, this.Platforms.fbeOffset + this.Platforms.fbeSize)
    this.Currencies = new FieldModelRightsMode(buffer, this.Managers.fbeOffset + this.Managers.fbeSize)
    this.Calendars = new FieldModelRightsMode(buffer, this.Currencies.fbeOffset + this.Currencies.fbeSize)
    this.Profiles = new FieldModelRightsMode(buffer, this.Calendars.fbeOffset + this.Calendars.fbeSize)
    this.DataFeeds = new FieldModelRightsMode(buffer, this.Profiles.fbeOffset + this.Profiles.fbeSize)
    this.Gateways = new FieldModelRightsMode(buffer, this.DataFeeds.fbeOffset + this.DataFeeds.fbeSize)
    this.TradingRoutes = new FieldModelRightsMode(buffer, this.Gateways.fbeOffset + this.Gateways.fbeSize)
    this.QuotesHistory = new FieldModelRightsMode(buffer, this.TradingRoutes.fbeOffset + this.TradingRoutes.fbeSize)
    this.Reports = new FieldModelRightsMode(buffer, this.QuotesHistory.fbeOffset + this.QuotesHistory.fbeSize)
    this.Monitoring = new FieldModelRightsMode(buffer, this.Reports.fbeOffset + this.Reports.fbeSize)
    this.Rights = new fbe.FieldModelSet(new FieldModelManagerRight(buffer, this.Monitoring.fbeOffset + this.Monitoring.fbeSize), buffer, this.Monitoring.fbeOffset + this.Monitoring.fbeSize)
    this.Persons = new FieldModelRightsMode(buffer, this.Rights.fbeOffset + this.Rights.fbeSize)
    this.SecuritySettings = new FieldModelRightsMode(buffer, this.Persons.fbeOffset + this.Persons.fbeSize)
    this.FirewallRules = new FieldModelRightsMode(buffer, this.SecuritySettings.fbeOffset + this.SecuritySettings.fbeSize)
    this.Documents = new FieldModelRightsMode(buffer, this.FirewallRules.fbeOffset + this.FirewallRules.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelManagerRights}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelManagerRights}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.AccountGroups.fbeSize + this.SymbolGroups.fbeSize + this.Accounts.fbeSize + this.Symbols.fbeSize + this.System.fbeSize + this.Modules.fbeSize + this.Platforms.fbeSize + this.Managers.fbeSize + this.Currencies.fbeSize + this.Calendars.fbeSize + this.Profiles.fbeSize + this.DataFeeds.fbeSize + this.Gateways.fbeSize + this.TradingRoutes.fbeSize + this.QuotesHistory.fbeSize + this.Reports.fbeSize + this.Monitoring.fbeSize + this.Rights.fbeSize + this.Persons.fbeSize + this.SecuritySettings.fbeSize + this.FirewallRules.fbeSize + this.Documents.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelManagerRights}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.AccountGroups.fbeExtra + this.SymbolGroups.fbeExtra + this.Accounts.fbeExtra + this.Symbols.fbeExtra + this.System.fbeExtra + this.Modules.fbeExtra + this.Platforms.fbeExtra + this.Managers.fbeExtra + this.Currencies.fbeExtra + this.Calendars.fbeExtra + this.Profiles.fbeExtra + this.DataFeeds.fbeExtra + this.Gateways.fbeExtra + this.TradingRoutes.fbeExtra + this.QuotesHistory.fbeExtra + this.Reports.fbeExtra + this.Monitoring.fbeExtra + this.Rights.fbeExtra + this.Persons.fbeExtra + this.SecuritySettings.fbeExtra + this.FirewallRules.fbeExtra + this.Documents.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelManagerRights}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelManagerRights.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelManagerRights}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 40
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelManagerRights}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelManagerRights}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountGroups.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroups.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroups.fbeSize

    if ((fbeCurrentSize + this.SymbolGroups.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroups.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroups.fbeSize

    if ((fbeCurrentSize + this.Accounts.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Accounts.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Accounts.fbeSize

    if ((fbeCurrentSize + this.Symbols.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Symbols.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbols.fbeSize

    if ((fbeCurrentSize + this.System.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.System.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.System.fbeSize

    if ((fbeCurrentSize + this.Modules.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modules.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modules.fbeSize

    if ((fbeCurrentSize + this.Platforms.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Platforms.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Platforms.fbeSize

    if ((fbeCurrentSize + this.Managers.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Managers.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Managers.fbeSize

    if ((fbeCurrentSize + this.Currencies.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Currencies.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Currencies.fbeSize

    if ((fbeCurrentSize + this.Calendars.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Calendars.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Calendars.fbeSize

    if ((fbeCurrentSize + this.Profiles.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Profiles.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profiles.fbeSize

    if ((fbeCurrentSize + this.DataFeeds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DataFeeds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DataFeeds.fbeSize

    if ((fbeCurrentSize + this.Gateways.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Gateways.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Gateways.fbeSize

    if ((fbeCurrentSize + this.TradingRoutes.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradingRoutes.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingRoutes.fbeSize

    if ((fbeCurrentSize + this.QuotesHistory.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.QuotesHistory.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.QuotesHistory.fbeSize

    if ((fbeCurrentSize + this.Reports.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Reports.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Reports.fbeSize

    if ((fbeCurrentSize + this.Monitoring.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Monitoring.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Monitoring.fbeSize

    if ((fbeCurrentSize + this.Rights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Rights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Rights.fbeSize

    if ((fbeCurrentSize + this.Persons.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Persons.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Persons.fbeSize

    if ((fbeCurrentSize + this.SecuritySettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SecuritySettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SecuritySettings.fbeSize

    if ((fbeCurrentSize + this.FirewallRules.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FirewallRules.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FirewallRules.fbeSize

    if ((fbeCurrentSize + this.Documents.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Documents.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Documents.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelManagerRights}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelManagerRights}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelManagerRights}
   * @param {!ManagerRights} fbeValue Default value, defaults is new ManagerRights()
   * @returns {!ManagerRights} ManagerRights value
   */
  get (fbeValue = new ManagerRights()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelManagerRights}
   * @param {!ManagerRights} fbeValue ManagerRights value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountGroups.fbeSize) <= fbeStructSize) {
      this.AccountGroups.get(fbeValue.AccountGroups)
    } else {
      fbeValue.AccountGroups.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroups.fbeSize

    if ((fbeCurrentSize + this.SymbolGroups.fbeSize) <= fbeStructSize) {
      this.SymbolGroups.get(fbeValue.SymbolGroups)
    } else {
      fbeValue.SymbolGroups.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroups.fbeSize

    if ((fbeCurrentSize + this.Accounts.fbeSize) <= fbeStructSize) {
      fbeValue.Accounts = this.Accounts.get()
    } else {
      fbeValue.Accounts = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Accounts.fbeSize

    if ((fbeCurrentSize + this.Symbols.fbeSize) <= fbeStructSize) {
      fbeValue.Symbols = this.Symbols.get()
    } else {
      fbeValue.Symbols = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbols.fbeSize

    if ((fbeCurrentSize + this.System.fbeSize) <= fbeStructSize) {
      fbeValue.System = this.System.get()
    } else {
      fbeValue.System = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.System.fbeSize

    if ((fbeCurrentSize + this.Modules.fbeSize) <= fbeStructSize) {
      fbeValue.Modules = this.Modules.get()
    } else {
      fbeValue.Modules = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modules.fbeSize

    if ((fbeCurrentSize + this.Platforms.fbeSize) <= fbeStructSize) {
      fbeValue.Platforms = this.Platforms.get()
    } else {
      fbeValue.Platforms = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Platforms.fbeSize

    if ((fbeCurrentSize + this.Managers.fbeSize) <= fbeStructSize) {
      fbeValue.Managers = this.Managers.get()
    } else {
      fbeValue.Managers = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Managers.fbeSize

    if ((fbeCurrentSize + this.Currencies.fbeSize) <= fbeStructSize) {
      fbeValue.Currencies = this.Currencies.get()
    } else {
      fbeValue.Currencies = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Currencies.fbeSize

    if ((fbeCurrentSize + this.Calendars.fbeSize) <= fbeStructSize) {
      fbeValue.Calendars = this.Calendars.get()
    } else {
      fbeValue.Calendars = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Calendars.fbeSize

    if ((fbeCurrentSize + this.Profiles.fbeSize) <= fbeStructSize) {
      fbeValue.Profiles = this.Profiles.get()
    } else {
      fbeValue.Profiles = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profiles.fbeSize

    if ((fbeCurrentSize + this.DataFeeds.fbeSize) <= fbeStructSize) {
      fbeValue.DataFeeds = this.DataFeeds.get()
    } else {
      fbeValue.DataFeeds = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DataFeeds.fbeSize

    if ((fbeCurrentSize + this.Gateways.fbeSize) <= fbeStructSize) {
      fbeValue.Gateways = this.Gateways.get()
    } else {
      fbeValue.Gateways = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Gateways.fbeSize

    if ((fbeCurrentSize + this.TradingRoutes.fbeSize) <= fbeStructSize) {
      fbeValue.TradingRoutes = this.TradingRoutes.get()
    } else {
      fbeValue.TradingRoutes = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingRoutes.fbeSize

    if ((fbeCurrentSize + this.QuotesHistory.fbeSize) <= fbeStructSize) {
      fbeValue.QuotesHistory = this.QuotesHistory.get()
    } else {
      fbeValue.QuotesHistory = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.QuotesHistory.fbeSize

    if ((fbeCurrentSize + this.Reports.fbeSize) <= fbeStructSize) {
      fbeValue.Reports = this.Reports.get()
    } else {
      fbeValue.Reports = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Reports.fbeSize

    if ((fbeCurrentSize + this.Monitoring.fbeSize) <= fbeStructSize) {
      fbeValue.Monitoring = this.Monitoring.get()
    } else {
      fbeValue.Monitoring = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Monitoring.fbeSize

    if ((fbeCurrentSize + this.Rights.fbeSize) <= fbeStructSize) {
      this.Rights.get(fbeValue.Rights)
    } else {
      fbeValue.Rights.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Rights.fbeSize

    if ((fbeCurrentSize + this.Persons.fbeSize) <= fbeStructSize) {
      fbeValue.Persons = this.Persons.get()
    } else {
      fbeValue.Persons = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Persons.fbeSize

    if ((fbeCurrentSize + this.SecuritySettings.fbeSize) <= fbeStructSize) {
      fbeValue.SecuritySettings = this.SecuritySettings.get()
    } else {
      fbeValue.SecuritySettings = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SecuritySettings.fbeSize

    if ((fbeCurrentSize + this.FirewallRules.fbeSize) <= fbeStructSize) {
      fbeValue.FirewallRules = this.FirewallRules.get()
    } else {
      fbeValue.FirewallRules = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FirewallRules.fbeSize

    if ((fbeCurrentSize + this.Documents.fbeSize) <= fbeStructSize) {
      fbeValue.Documents = this.Documents.get()
    } else {
      fbeValue.Documents = new RightsMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Documents.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelManagerRights}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelManagerRights}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelManagerRights}
   * @param {!ManagerRights} fbeValue ManagerRights value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelManagerRights}
   * @param {ManagerRights} fbeValue ManagerRights value
   */
  setFields (fbeValue) {
    this.AccountGroups.set(fbeValue.AccountGroups)
    this.SymbolGroups.set(fbeValue.SymbolGroups)
    this.Accounts.set(fbeValue.Accounts)
    this.Symbols.set(fbeValue.Symbols)
    this.System.set(fbeValue.System)
    this.Modules.set(fbeValue.Modules)
    this.Platforms.set(fbeValue.Platforms)
    this.Managers.set(fbeValue.Managers)
    this.Currencies.set(fbeValue.Currencies)
    this.Calendars.set(fbeValue.Calendars)
    this.Profiles.set(fbeValue.Profiles)
    this.DataFeeds.set(fbeValue.DataFeeds)
    this.Gateways.set(fbeValue.Gateways)
    this.TradingRoutes.set(fbeValue.TradingRoutes)
    this.QuotesHistory.set(fbeValue.QuotesHistory)
    this.Reports.set(fbeValue.Reports)
    this.Monitoring.set(fbeValue.Monitoring)
    this.Rights.set(fbeValue.Rights)
    this.Persons.set(fbeValue.Persons)
    this.SecuritySettings.set(fbeValue.SecuritySettings)
    this.FirewallRules.set(fbeValue.FirewallRules)
    this.Documents.set(fbeValue.Documents)
  }
}

export { FieldModelManagerRights };

/**
 * Fast Binary Encoding ManagerRights model
 */
class ManagerRightsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelManagerRights(this.buffer, 4)
  }

  /**
   * Get the ManagerRights model
   * @this {!ManagerRightsModel}
   * @returns {!FieldModelManagerRights} model ManagerRights model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ManagerRightsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ManagerRightsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ManagerRightsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ManagerRightsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelManagerRights.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ManagerRightsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ManagerRightsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ManagerRightsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ManagerRightsModel}
   * @param {!ManagerRights} value ManagerRights value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ManagerRightsModel}
   * @param {!ManagerRights} value ManagerRights value, defaults is new ManagerRights()
   * @return {!object} Deserialized ManagerRights value and its size
   */
  deserialize (value = new ManagerRights()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ManagerRights(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ManagerRights(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ManagerRightsModel}
   * @param {!number} prev Previous ManagerRights model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ManagerRightsModel };
