
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as entity from '../entity'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {AgentInfo} from '../entity/AgentInfo';
import {FieldModelAgentInfo} from '../entity/AgentInfo';

/**
 * TokenInfo struct
 */
class TokenInfo {
  /**
   * Initialize struct
   * @param {!number=} pairId
   * @param {!number=} login
   * @param {!boolean=} isManager
   * @param {!AgentInfo=} agentInfo
   * @param {!Date=} issued
   * @param {!Date=} accessed
   * @param {!Date=} accessExpire
   * @param {!Date=} refreshExpire
   * @constructor
   */
  constructor (pairId = new UInt64(0, 0), login = new UInt64(0, 0), isManager = false, agentInfo = new AgentInfo(), issued = new Date(0), accessed = new Date(0), accessExpire = new Date(0), refreshExpire = new Date(0)) {
    this.PairId = pairId
    this.Login = login
    this.IsManager = isManager
    this.AgentInfo = agentInfo
    this.Issued = issued
    this.Accessed = accessed
    this.AccessExpire = accessExpire
    this.RefreshExpire = refreshExpire
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TokenInfo}
   * @param {!TokenInfo} other Other struct
   * @returns {!TokenInfo} This struct
   */
  copy (other) {
    if (other.PairId != null) {
      this.PairId = UInt64.fromNumber(other.PairId)
    } else {
      this.PairId = null
    }
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.IsManager != null) {
      this.IsManager = other.IsManager
    } else {
      this.IsManager = null
    }
    if (other.AgentInfo != null) {
      this.AgentInfo = AgentInfo.fromObject(other.AgentInfo)
    } else {
      this.AgentInfo = null
    }
    if (other.Issued != null) {
      if (other.Issued instanceof Date) {
        this.Issued = new Date(other.Issued.getTime())
      } else {
        this.Issued = new Date(Math.round(other.Issued / 1000000))
      }
    } else {
      this.Issued = null
    }
    if (other.Accessed != null) {
      if (other.Accessed instanceof Date) {
        this.Accessed = new Date(other.Accessed.getTime())
      } else {
        this.Accessed = new Date(Math.round(other.Accessed / 1000000))
      }
    } else {
      this.Accessed = null
    }
    if (other.AccessExpire != null) {
      if (other.AccessExpire instanceof Date) {
        this.AccessExpire = new Date(other.AccessExpire.getTime())
      } else {
        this.AccessExpire = new Date(Math.round(other.AccessExpire / 1000000))
      }
    } else {
      this.AccessExpire = null
    }
    if (other.RefreshExpire != null) {
      if (other.RefreshExpire instanceof Date) {
        this.RefreshExpire = new Date(other.RefreshExpire.getTime())
      } else {
        this.RefreshExpire = new Date(Math.round(other.RefreshExpire / 1000000))
      }
    } else {
      this.RefreshExpire = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TokenInfo}
   * @returns {!TokenInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TokenInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TokenInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TokenInfo}
   * @param {!TokenInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TokenInfo)) {
      throw new TypeError('Instance of TokenInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TokenInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      PairId: ((this.PairId != null) ? this.PairId.toNumber() : null),
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      IsManager: ((this.IsManager != null) ? this.IsManager : null),
      AgentInfo: ((this.AgentInfo != null) ? this.AgentInfo : null),
      Issued: ((this.Issued != null) ? (this.Issued.getTime() * 1000000) : null),
      Accessed: ((this.Accessed != null) ? (this.Accessed.getTime() * 1000000) : null),
      AccessExpire: ((this.AccessExpire != null) ? (this.AccessExpire.getTime() * 1000000) : null),
      RefreshExpire: ((this.RefreshExpire != null) ? (this.RefreshExpire.getTime() * 1000000) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TokenInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TokenInfo} other Object value
   * @returns {!TokenInfo} Created struct
   */
  static fromObject (other) {
    return new TokenInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TokenInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TokenInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TokenInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 14113
  }
}

export { TokenInfo };

/**
 * Fast Binary Encoding TokenInfo field model
 */
class FieldModelTokenInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.PairId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.Login = new fbe.FieldModelUInt64(buffer, this.PairId.fbeOffset + this.PairId.fbeSize)
    this.IsManager = new fbe.FieldModelBool(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.AgentInfo = new FieldModelAgentInfo(buffer, this.IsManager.fbeOffset + this.IsManager.fbeSize)
    this.Issued = new fbe.FieldModelTimestamp(buffer, this.AgentInfo.fbeOffset + this.AgentInfo.fbeSize)
    this.Accessed = new fbe.FieldModelTimestamp(buffer, this.Issued.fbeOffset + this.Issued.fbeSize)
    this.AccessExpire = new fbe.FieldModelTimestamp(buffer, this.Accessed.fbeOffset + this.Accessed.fbeSize)
    this.RefreshExpire = new fbe.FieldModelTimestamp(buffer, this.AccessExpire.fbeOffset + this.AccessExpire.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelTokenInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTokenInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.PairId.fbeSize + this.Login.fbeSize + this.IsManager.fbeSize + this.AgentInfo.fbeSize + this.Issued.fbeSize + this.Accessed.fbeSize + this.AccessExpire.fbeSize + this.RefreshExpire.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTokenInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.PairId.fbeExtra + this.Login.fbeExtra + this.IsManager.fbeExtra + this.AgentInfo.fbeExtra + this.Issued.fbeExtra + this.Accessed.fbeExtra + this.AccessExpire.fbeExtra + this.RefreshExpire.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTokenInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTokenInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTokenInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 14113
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTokenInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTokenInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.PairId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PairId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PairId.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.IsManager.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsManager.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsManager.fbeSize

    if ((fbeCurrentSize + this.AgentInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AgentInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentInfo.fbeSize

    if ((fbeCurrentSize + this.Issued.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Issued.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Issued.fbeSize

    if ((fbeCurrentSize + this.Accessed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Accessed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Accessed.fbeSize

    if ((fbeCurrentSize + this.AccessExpire.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccessExpire.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessExpire.fbeSize

    if ((fbeCurrentSize + this.RefreshExpire.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RefreshExpire.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RefreshExpire.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTokenInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTokenInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTokenInfo}
   * @param {!TokenInfo} fbeValue Default value, defaults is new TokenInfo()
   * @returns {!TokenInfo} TokenInfo value
   */
  get (fbeValue = new TokenInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTokenInfo}
   * @param {!TokenInfo} fbeValue TokenInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.PairId.fbeSize) <= fbeStructSize) {
      fbeValue.PairId = this.PairId.get()
    } else {
      fbeValue.PairId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PairId.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.IsManager.fbeSize) <= fbeStructSize) {
      fbeValue.IsManager = this.IsManager.get()
    } else {
      fbeValue.IsManager = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsManager.fbeSize

    if ((fbeCurrentSize + this.AgentInfo.fbeSize) <= fbeStructSize) {
      fbeValue.AgentInfo = this.AgentInfo.get()
    } else {
      fbeValue.AgentInfo = new AgentInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentInfo.fbeSize

    if ((fbeCurrentSize + this.Issued.fbeSize) <= fbeStructSize) {
      fbeValue.Issued = this.Issued.get()
    } else {
      fbeValue.Issued = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Issued.fbeSize

    if ((fbeCurrentSize + this.Accessed.fbeSize) <= fbeStructSize) {
      fbeValue.Accessed = this.Accessed.get()
    } else {
      fbeValue.Accessed = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Accessed.fbeSize

    if ((fbeCurrentSize + this.AccessExpire.fbeSize) <= fbeStructSize) {
      fbeValue.AccessExpire = this.AccessExpire.get()
    } else {
      fbeValue.AccessExpire = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessExpire.fbeSize

    if ((fbeCurrentSize + this.RefreshExpire.fbeSize) <= fbeStructSize) {
      fbeValue.RefreshExpire = this.RefreshExpire.get()
    } else {
      fbeValue.RefreshExpire = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RefreshExpire.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTokenInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTokenInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTokenInfo}
   * @param {!TokenInfo} fbeValue TokenInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTokenInfo}
   * @param {TokenInfo} fbeValue TokenInfo value
   */
  setFields (fbeValue) {
    this.PairId.set(fbeValue.PairId)
    this.Login.set(fbeValue.Login)
    this.IsManager.set(fbeValue.IsManager)
    this.AgentInfo.set(fbeValue.AgentInfo)
    this.Issued.set(fbeValue.Issued)
    this.Accessed.set(fbeValue.Accessed)
    this.AccessExpire.set(fbeValue.AccessExpire)
    this.RefreshExpire.set(fbeValue.RefreshExpire)
  }
}

export { FieldModelTokenInfo };

/**
 * Fast Binary Encoding TokenInfo model
 */
class TokenInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTokenInfo(this.buffer, 4)
  }

  /**
   * Get the TokenInfo model
   * @this {!TokenInfoModel}
   * @returns {!FieldModelTokenInfo} model TokenInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TokenInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TokenInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TokenInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TokenInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTokenInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TokenInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TokenInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TokenInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TokenInfoModel}
   * @param {!TokenInfo} value TokenInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TokenInfoModel}
   * @param {!TokenInfo} value TokenInfo value, defaults is new TokenInfo()
   * @return {!object} Deserialized TokenInfo value and its size
   */
  deserialize (value = new TokenInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TokenInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TokenInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TokenInfoModel}
   * @param {!number} prev Previous TokenInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TokenInfoModel };
