import { NewsServicesSettings } from '@api/fbe/core/NewsServicesSettings';
import { UUID } from '@api/fbe/uuid';

export type LoggingSettingsType = {
    LogLevel: number;
};

export type HistoryServerSettingsType = {
    HistoryPort: number | null;
    HistoryPath: string;
    UseSsl: boolean;
    ClientReportsPath: string;
    Deprecated1: string;
    Deprecated2: string;
    OutgoingBufferSizeLimit: number | null;
    ChartBarsSlowQueryLogThreshold: number | null;
    TicksSlowQueryLogThreshold: number | null;
    RequestsThreadPoolSize: number | null;
    ChartBarsUpdatePeriod: number | null;
    ClientReportsSlowQueryLogThreshold: number | null;
    UserAuditSlowQueryLogThreshold: number | null;
};

export type ConfigServerSettingsType = {
    ConfigPort: number | null;
    ConfigPath: string;
    ConfigGit: boolean;
    UseSsl: boolean;
};

export type FeedServerSettingsType = {
    FeedPort: number | null;
    LatestTickDatabaseFile: string;
    UseSsl: boolean;
};

export type StorageServerSettingsType = {
    StoragePort: number | null;
    StorageDatabase: number | null;
    StorageDatabaseFile: string;
    UseSsl: boolean;
    // DisableDailySnapshots: boolean;
    SlowQueryLogThreshold: number | null;
};

export type NotificationServiceSettingsType = {
    IsEnabled: boolean;
    Host: string;
    Port: number | null;
    UseSsl: boolean;
    Environment: string;
    NotificationTTLInMinutes: number | null;
    ResponseTimeoutInSeconds: number | null;
    MaxBatchSize: number | null;
    TokenTTLInMinutes: number | null;
};

export type TradeServerSettingsType = {
    TradePort: number | null;
    UseSsl: boolean;
    OrderTimeout: number | null;
    NotificationServiceSettings: NotificationServiceSettingsType;
};

export type ExecutionServerSettingsType = {
    ExecutionPort: number | null;
    UseSsl: boolean;
};

export type BackupServerSettingsType = {
    // MainConfigServerHost: string;
    // MainConfigServerPort: number | null;
    // BackupTradingPlatformDirectory: string;
    IsConfigSyncEnabled: boolean;
    IsStorageSyncEnabled: boolean;
    IsLastPricesSyncEnabled: boolean;
    IsChartBarHistorySyncEnabled: boolean;
    DrawingSlowQueryLogThreshold: number | null;
    IsDrawingSyncEnabled: boolean;
    DrawingSyncBatchSize: number;
    DrawingSyncPeriodInSeconds: number;
    ChartBarSyncBatchSize: number | null;
    ChartBarSyncPeriodInSeconds: number | null;
    BackupPort: number | null;
    UseSsl: boolean;
    StorageSlowQueryLogThreshold: number | null;
    ChartBarsSlowQueryLogThreshold: number | null;
    IsClientReportsSyncEnabled: boolean;
    ClientReportsSyncBatchSize: number | null;
    ClientReportsSyncPeriodInSeconds: number | null;
    ClientReportsSlowQueryLogThreshold: number | null;
};

export type ExternalAuthParamsType = {
    MAEndpointHost: string | null;
    MAEndpointPort: number | null;
};

export type TradingCentralSettingsType = {
    Enabled: boolean;
    ClientId: string;
    Password: string;
};

export type NewsServicesSettingsType = {
    TradingCentralSettings: TradingCentralSettingsType;
};

export type AccessServerSettingsType = {
    SslClientPort: number | null;
    SslManagerPort: number | null;
    WssClientPort: number | null;
    WssManagerPort: number | null;
    HttpsClientPort: number | null;
    HttpsManagerPort: number | null;
    HttpsPublicPort: number | null;
    WwwPath: string;
    ExternalAuthParams: ExternalAuthParamsType | null;
    ClientNotifySendingInterval: number | null;

    SslClientOutgoingBufferSizeLimit: number | null;
    SslManagerOutgoingBufferSizeLimit: number | null;
    WssClientOutgoingBufferSizeLimit: number | null;
    WssManagerOutgoingBufferSizeLimit: number | null;
    HttpsClientOutgoingBufferSizeLimit: number | null;
    HttpsManagerOutgoingBufferSizeLimit: number | null;
    HttpsPublicOutgoingBufferSizeLimit: number | null;

    Deprecated1: string;
    Deprecated2: string;

    SslClientEndpointEnabled: boolean;
    SslManagerEndpointEnabled: boolean;
    WssClientEndpointEnabled: boolean;
    WssManagerEndpointEnabled: boolean;
    HttpsClientEndpointEnabled: boolean;
    HttpsManagerEndpointEnabled: boolean;
    HttpsPublicEndpointEnabled: boolean;
    SecuritySettingsId: string;
    NewsServicesSettings: NewsServicesSettingsType;
    MinRequiredClientAppVersion: string | null;
    DesiredAppSettings: DesiredAppSettingsModel | null;
};

export type DatabaseParams = {
    DatabaseType: number | null;
    DatabaseAddress: string;
    DatabasePort: number | null;
    DatabaseLogin: string;
    DatabasePassword: string;
    DatabaseName: string;
    ConnectionTimeout: number | null;
    CommandTimeout: number | null;
};

export type ExportServerSettingsType = {
    DatabaseParams: DatabaseParams | null;
    SyncPrices: boolean;
    SyncPricesInterval: number | null;
    SyncTrades: boolean;
    SyncTradesInterval: number | null;
    SyncMarginInterval: number | null;
    SyncDaily: boolean;
    SyncDailyInterval: number | null;
    SyncUsers: boolean;
    SyncUsersInterval: number | null;
    PlatformId: string | null;
    ExportPort: number | null;
    UseSsl: boolean;
};

export type DrawingStorageSqliteParamsType = {
    DatabaseFile: string;
    DatabasePassword: string | null;
};

export type DrawingStorageServerSettingsType = {
    ApiPort: number | null;
    SlowQueryLogThreshold: number | null;
    UseSsl: boolean;
    SqliteParams: DrawingStorageSqliteParamsType | null;
    SslCertificatePath: string;
    SslCertificatePassword: string | null;
};

export type SecurityServerSettingsType = {
    SecurityPort: number | null;
    UseSsl: boolean;
    SlowQueryLogThreshold: number | null;
};

export type DocumentServerSettingsType = {
    DocumentPort: number | null;
    UseSsl: boolean;
    LegalDocumentsSlowQueryLogThreshold: number | null;
};

export type Module = {
    id: string;
    SortOrder?: number;
    HostId: string;
    Name: string;
    Comment: string;
    Description: string;
    ModuleType: number | null;
    IsEnabled: boolean;
    LoggingSettings: LoggingSettingsType;
    HistoryServerSettings?: HistoryServerSettingsType | null;
    ConfigServerSettings?: ConfigServerSettingsType | null;
    FeedServerSettings?: FeedServerSettingsType | null;
    StorageServerSettings?: StorageServerSettingsType | null;
    TradeServerSettings?: TradeServerSettingsType | null;
    ExecutionServerSettings?: ExecutionServerSettingsType | null;
    BackupServerSettings?: BackupServerSettingsType | null;
    AccessServerSettings?: AccessServerSettingsType | null;
    ExportServerSettings?: ExportServerSettingsType | null;
    DrawingStorageServerSettings?: DrawingStorageServerSettingsType | null;
    SecurityServerSettings?: SecurityServerSettingsType | null;
    DocumentServerSettings?: DocumentServerSettingsType | null;
};

export type DesiredAppSettingsModel = {
    LatestApkFileUrl: string | null;
    LatestAppVersion: string | null;
    NonForceUpdateTimeInterval: number | null; // minutes
};

export const convertNewsServicesSettings = (newsServicesSettings: NewsServicesSettings): NewsServicesSettingsType => ({
    TradingCentralSettings: newsServicesSettings.TradingCentralSettings
        ? {
              ClientId: newsServicesSettings.TradingCentralSettings.ClientId,
              Enabled: newsServicesSettings.TradingCentralSettings.Enabled,
              Password: newsServicesSettings.TradingCentralSettings.Password,
          }
        : {
              ClientId: '',
              Enabled: false,
              Password: '',
          },
});

export const convertUUIDToString = (id: UUID): string => {
    const idStr = id.toString();
    return idStr === '00000000-0000-0000-0000-000000000000' ? '' : idStr;
};
