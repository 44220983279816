
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Message} from '../core/Message';
import {FieldModelMessage} from '../core/Message';
import {Reject} from '../core/Reject';
import {FieldModelReject} from '../core/Reject';

/**
 * RequestMonitoringRequest struct
 */
class RequestMonitoringRequest extends Message {
  /**
   * Initialize struct
   * @param {!Message=} parent
   * @param {!number=} login
   * @param {!Date=} requestTimestamp
   * @param {!string=} request
   * @param {Date=} responseTimestamp
   * @param {string=} response
   * @param {Reject=} reject
   * @constructor
   */
  constructor (parent = new Message(), login = new UInt64(0, 0), requestTimestamp = new Date(0), request = '', responseTimestamp = null, response = null, reject = null) {
    super()
    super.copy(parent)
    this.Login = login
    this.RequestTimestamp = requestTimestamp
    this.Request = request
    this.ResponseTimestamp = responseTimestamp
    this.Response = response
    this.Reject = reject
  }

  /**
   * Copy struct (shallow copy)
   * @this {!RequestMonitoringRequest}
   * @param {!RequestMonitoringRequest} other Other struct
   * @returns {!RequestMonitoringRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.RequestTimestamp != null) {
      if (other.RequestTimestamp instanceof Date) {
        this.RequestTimestamp = new Date(other.RequestTimestamp.getTime())
      } else {
        this.RequestTimestamp = new Date(Math.round(other.RequestTimestamp / 1000000))
      }
    } else {
      this.RequestTimestamp = null
    }
    if (other.Request != null) {
      this.Request = other.Request
    } else {
      this.Request = null
    }
    if (other.ResponseTimestamp != null) {
      if (other.ResponseTimestamp instanceof Date) {
        this.ResponseTimestamp = new Date(other.ResponseTimestamp.getTime())
      } else {
        this.ResponseTimestamp = new Date(Math.round(other.ResponseTimestamp / 1000000))
      }
    } else {
      this.ResponseTimestamp = null
    }
    if (other.Response != null) {
      this.Response = other.Response
    } else {
      this.Response = null
    }
    if (other.Reject != null) {
      this.Reject = Reject.fromObject(other.Reject)
    } else {
      this.Reject = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!RequestMonitoringRequest}
   * @returns {!RequestMonitoringRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new RequestMonitoringRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new RequestMonitoringRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!RequestMonitoringRequest}
   * @param {!RequestMonitoringRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof RequestMonitoringRequest)) {
      throw new TypeError('Instance of RequestMonitoringRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!RequestMonitoringRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      RequestTimestamp: ((this.RequestTimestamp != null) ? (this.RequestTimestamp.getTime() * 1000000) : null),
      Request: ((this.Request != null) ? this.Request : null),
      ResponseTimestamp: ((this.ResponseTimestamp != null) ? (this.ResponseTimestamp.getTime() * 1000000) : null),
      Response: ((this.Response != null) ? this.Response : null),
      Reject: ((this.Reject != null) ? this.Reject : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return RequestMonitoringRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!RequestMonitoringRequest} other Object value
   * @returns {!RequestMonitoringRequest} Created struct
   */
  static fromObject (other) {
    return new RequestMonitoringRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!RequestMonitoringRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return RequestMonitoringRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!RequestMonitoringRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6431
  }
}

export { RequestMonitoringRequest };

/**
 * Fast Binary Encoding RequestMonitoringRequest field model
 */
class FieldModelRequestMonitoringRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelMessage(buffer, 4 + 4)
    this.Login = new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.RequestTimestamp = new fbe.FieldModelTimestamp(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.Request = new fbe.FieldModelString(buffer, this.RequestTimestamp.fbeOffset + this.RequestTimestamp.fbeSize)
    this.ResponseTimestamp = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Request.fbeOffset + this.Request.fbeSize), buffer, this.Request.fbeOffset + this.Request.fbeSize)
    this.Response = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ResponseTimestamp.fbeOffset + this.ResponseTimestamp.fbeSize), buffer, this.ResponseTimestamp.fbeOffset + this.ResponseTimestamp.fbeSize)
    this.Reject = new fbe.FieldModelOptional(new FieldModelReject(buffer, this.Response.fbeOffset + this.Response.fbeSize), buffer, this.Response.fbeOffset + this.Response.fbeSize)
  }

  /**
   * Get the core.Message field model
   * @this {!FieldModelRequestMonitoringRequest}
   * @returns {!FieldModelMessage} core.Message field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelRequestMonitoringRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelRequestMonitoringRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Login.fbeSize + this.RequestTimestamp.fbeSize + this.Request.fbeSize + this.ResponseTimestamp.fbeSize + this.Response.fbeSize + this.Reject.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelRequestMonitoringRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Login.fbeExtra + this.RequestTimestamp.fbeExtra + this.Request.fbeExtra + this.ResponseTimestamp.fbeExtra + this.Response.fbeExtra + this.Reject.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelRequestMonitoringRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelRequestMonitoringRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelRequestMonitoringRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6431
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelRequestMonitoringRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelRequestMonitoringRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.RequestTimestamp.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestTimestamp.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestTimestamp.fbeSize

    if ((fbeCurrentSize + this.Request.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Request.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Request.fbeSize

    if ((fbeCurrentSize + this.ResponseTimestamp.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ResponseTimestamp.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ResponseTimestamp.fbeSize

    if ((fbeCurrentSize + this.Response.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Response.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Response.fbeSize

    if ((fbeCurrentSize + this.Reject.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Reject.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Reject.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelRequestMonitoringRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelRequestMonitoringRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelRequestMonitoringRequest}
   * @param {!RequestMonitoringRequest} fbeValue Default value, defaults is new RequestMonitoringRequest()
   * @returns {!RequestMonitoringRequest} RequestMonitoringRequest value
   */
  get (fbeValue = new RequestMonitoringRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelRequestMonitoringRequest}
   * @param {!RequestMonitoringRequest} fbeValue RequestMonitoringRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.RequestTimestamp.fbeSize) <= fbeStructSize) {
      fbeValue.RequestTimestamp = this.RequestTimestamp.get()
    } else {
      fbeValue.RequestTimestamp = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestTimestamp.fbeSize

    if ((fbeCurrentSize + this.Request.fbeSize) <= fbeStructSize) {
      fbeValue.Request = this.Request.get()
    } else {
      fbeValue.Request = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Request.fbeSize

    if ((fbeCurrentSize + this.ResponseTimestamp.fbeSize) <= fbeStructSize) {
      fbeValue.ResponseTimestamp = this.ResponseTimestamp.get()
    } else {
      fbeValue.ResponseTimestamp = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ResponseTimestamp.fbeSize

    if ((fbeCurrentSize + this.Response.fbeSize) <= fbeStructSize) {
      fbeValue.Response = this.Response.get()
    } else {
      fbeValue.Response = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Response.fbeSize

    if ((fbeCurrentSize + this.Reject.fbeSize) <= fbeStructSize) {
      fbeValue.Reject = this.Reject.get()
    } else {
      fbeValue.Reject = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Reject.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelRequestMonitoringRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelRequestMonitoringRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelRequestMonitoringRequest}
   * @param {!RequestMonitoringRequest} fbeValue RequestMonitoringRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelRequestMonitoringRequest}
   * @param {RequestMonitoringRequest} fbeValue RequestMonitoringRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Login.set(fbeValue.Login)
    this.RequestTimestamp.set(fbeValue.RequestTimestamp)
    this.Request.set(fbeValue.Request)
    this.ResponseTimestamp.set(fbeValue.ResponseTimestamp)
    this.Response.set(fbeValue.Response)
    this.Reject.set(fbeValue.Reject)
  }
}

export { FieldModelRequestMonitoringRequest };

/**
 * Fast Binary Encoding RequestMonitoringRequest model
 */
class RequestMonitoringRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelRequestMonitoringRequest(this.buffer, 4)
  }

  /**
   * Get the RequestMonitoringRequest model
   * @this {!RequestMonitoringRequestModel}
   * @returns {!FieldModelRequestMonitoringRequest} model RequestMonitoringRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!RequestMonitoringRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!RequestMonitoringRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return RequestMonitoringRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!RequestMonitoringRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelRequestMonitoringRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!RequestMonitoringRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!RequestMonitoringRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!RequestMonitoringRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!RequestMonitoringRequestModel}
   * @param {!RequestMonitoringRequest} value RequestMonitoringRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!RequestMonitoringRequestModel}
   * @param {!RequestMonitoringRequest} value RequestMonitoringRequest value, defaults is new RequestMonitoringRequest()
   * @return {!object} Deserialized RequestMonitoringRequest value and its size
   */
  deserialize (value = new RequestMonitoringRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new RequestMonitoringRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new RequestMonitoringRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!RequestMonitoringRequestModel}
   * @param {!number} prev Previous RequestMonitoringRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { RequestMonitoringRequestModel };
