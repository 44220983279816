import { ManagerRight } from '@api/fbe/core/ManagerRight';
import { createContext } from 'react';
import {
    HLB_PROFILES_PAGES,
    PUSH_NOTIFICATIONS_PAGES,
    SECURITY_PAGES,
    STOCKS_PAGES,
} from '../App/pages/types/pagesTypes';
import {
    ACCOUNTS_PAGES,
    CALENDAR_PAGES,
    CURRENCY_PAGES,
    MANAGERS_PAGES,
    PROFILES_PAGES,
    QUOTE_HISTORY_PAGES,
    RM_PAGES,
    SYMBOLS_PAGES,
    SYSTEM_PAGES,
} from '@pages/types/pagesTypes';

export type TabActionsAccess = {
    accessible: boolean;
    missingRights: ManagerRight[] | ManagerRight[][];
};

export type TabActions = {
    view?: TabActionsAccess;
    edit: TabActionsAccess;
    create: TabActionsAccess;
    delete: TabActionsAccess;
};

export type AccessCRUD = {
    view: boolean;
    edit: boolean;
    create: boolean;
    delete: boolean;
};

export type TabAccessLevel = {
    accessible: boolean;
    actions: TabActions;
};

export type PageAccessLevel<
    PageTabs extends Record<string, string>,
    CustomRights extends Record<string, boolean> | null,
> = {
    accessible: boolean;
    tabs: Record<keyof PageTabs, TabAccessLevel>;
    custom: CustomRights;
};

export type AdminPanelAccessMap = {
    system: PageAccessLevel<{ hosts: 'hosts'; timezones: 'timezones' }, null>;
    modules: PageAccessLevel<
        { modules: 'modules' },
        {
            platforms: boolean;
            hosts: boolean;
        }
    >;
    platforms: PageAccessLevel<{ platforms: 'platforms' }, { timezones: boolean }>;
    currencies: PageAccessLevel<
        { [CURRENCY_PAGES.CURRENCIES]: 'currencies'; [CURRENCY_PAGES.TYPES]: 'currency-types' },
        {
            currencyGroups: boolean;
        }
    >;
    symbols: PageAccessLevel<
        {
            [SYMBOLS_PAGES.SYMBOLS]: 'symbols';
            [SYMBOLS_PAGES.SYMBOL_GROUPS]: 'symbol-groups';
            [SYMBOLS_PAGES.WATCHLIST_SETTINGS]: 'watchlist';
        },
        {
            currencies: boolean;
            symbolGroups: boolean;
            symbolsView: boolean;
            regularCalendar: boolean;
        }
    >;
    accounts: PageAccessLevel<
        {
            [ACCOUNTS_PAGES.ACCOUNTS]: 'accounts';
            [ACCOUNTS_PAGES.ACCOUNT_GROUPS]: 'accountGroups';
        },
        {
            currencies: boolean;
            positions: boolean;
            orders: boolean;
            tradeHistory: boolean;
            deposit: boolean;
            credit: boolean;
            platforms: boolean;
            symbolGroups: boolean;
            symbols: boolean;
            markups: boolean;
            commissions: boolean;
            personalInfo: boolean;
            accountGroups: boolean;
        }
    >;
    managers: PageAccessLevel<
        { [MANAGERS_PAGES.MANAGERS]: 'managers'; [MANAGERS_PAGES.MANAGER_GROUPS]: 'manager-groups' },
        {
            accountGroups: boolean;
            symbolGroups: boolean;
            platforms: boolean;
            managerGroups: boolean;
        }
    >;
    calendars: PageAccessLevel<
        { [CALENDAR_PAGES.REGULAR]: 'regular'; [CALENDAR_PAGES.OFFTIME]: 'irregular' },
        {
            symbols: boolean;
            symbolGroups: boolean;
            irregularCalendars: boolean;
            timezones: boolean;
        }
    >;
    profiles: PageAccessLevel<
        {
            [PROFILES_PAGES.SYMBOL_MAPS]: 'symbol-maps';
            [PROFILES_PAGES.COMMISSION]: 'commission';
            [PROFILES_PAGES.MARKUP]: 'markup';
        },
        {
            symbols: boolean;
        }
    >;
    dataFeeds: PageAccessLevel<
        { dataFeeds: 'data-feeds' },
        {
            symbols: boolean;
            symbolGroups: boolean;
            symbolMaps: boolean;
        }
    >;
    tradingRoutes: PageAccessLevel<
        { tradingRoutes: 'tradingRoutes' },
        {
            showConditions: boolean;
            gateways: boolean;
        }
    >;
    gateways: PageAccessLevel<
        { gateways: 'gateways' },
        {
            symbolMaps: boolean;
        }
    >;
    quoteHistory: PageAccessLevel<
        {
            [QUOTE_HISTORY_PAGES.CHARTS_BARS]: 'chart-bars';
            [QUOTE_HISTORY_PAGES.CHARTS_BARS_IMPORT]: 'chart-bars-import';
            [QUOTE_HISTORY_PAGES.TICKS]: 'ticks';
        },
        {
            symbols: boolean;
        }
    >;
    sessions: PageAccessLevel<
        { sessions: 'sessions' },
        {
            accounts: boolean;
            personalInfo: boolean;
            accountGroups: boolean;
            currencies: boolean;
            // platforms: boolean;
        }
    >;
    market: PageAccessLevel<
        { market: 'market' },
        {
            currencies: boolean;
            calendars: boolean;
            symbols: boolean;
            symbolGroups: boolean;
        }
    >;
    journal: PageAccessLevel<{ journal: 'journal' }, null>;
    businessJournal: PageAccessLevel<{ businessJournal: 'business-journal' }, null>;
    modulesWatch: PageAccessLevel<{ modulesWatch: 'modules-watch' }, { modules: boolean }>;
    riskManagement: PageAccessLevel<
        {
            [RM_PAGES.POSITIONS]: 'positions';
            [RM_PAGES.PENDING_ORDERS]: 'pendingOrders';
            [RM_PAGES.DEALS]: 'deals';
            [RM_PAGES.SUMMARY]: 'summary';
        },
        {
            currencies: boolean;
            accounts: boolean;
            symbols: boolean;
            orders: boolean;
            positions: boolean;
            accountsGroups: boolean;
        }
    >;
    stocks: PageAccessLevel<
        {
            [STOCKS_PAGES.DIVIDENDS]: 'dividends';
            [STOCKS_PAGES.SPLITS]: 'splits';
        },
        {
            symbols: boolean;
            accountsGroups: boolean;
        }
    >;
    mail: PageAccessLevel<{ mail: 'mail' }, null>;
    emailTemplates: PageAccessLevel<{ emailTemplates: 'email-templates' }, null>;
    languages: PageAccessLevel<{ languages: 'languages' }, null>;
    pushNotifications: PageAccessLevel<
        {
            pushNotifications: 'push-notifications';
        },
        null
    >;
    leverageProfiles: PageAccessLevel<{ leverageProfiles: 'leverage-profiles' }, null>;
    // marginRateProfiles: PageAccessLevel<{ marginRateProfiles: 'margin-rate' }, null>;
    // newsProfiles: PageAccessLevel<{ newsProfiles: 'news' }, null>;
    balanceTransactions: PageAccessLevel<
        { balanceTransactions: 'balance-transactions' },
        { accounts: boolean; symbols: boolean }
    >;
    dailyTradeReport: PageAccessLevel<{ dailyTradeReport: 'daily-trade-report' }, null>;
    statements: PageAccessLevel<{ statements: 'client-reports' }, { resendReport: boolean }>;
    hlbProfiles: PageAccessLevel<
        { [HLB_PROFILES_PAGES.MARGIN_RATE]: 'margin-rate'; [HLB_PROFILES_PAGES.NEWS]: 'news' },
        null
    >;
    security: PageAccessLevel<
        {
            [SECURITY_PAGES.SECURITY_PROFILES]: 'security-profiles';
            [SECURITY_PAGES.FIREWALL]: 'firewall';
        },
        null
    >;
    legalDocuments: PageAccessLevel<
        { legalDocuments: 'legal-documents' },
        { documentsDelete: boolean; documentsUpload: boolean }
    >;
};

type RightPreset = {
    view?: ManagerRight[] | ManagerRight[][];
    create: ManagerRight[] | ManagerRight[][];
    edit: ManagerRight[];
    delete: ManagerRight[];
};

type RightsPreset = {
    modules: RightPreset;
    platforms: RightPreset;
    currencies: RightPreset;
    symbols: RightPreset;
    accounts: RightPreset;
    accountGroups: RightPreset;
    managers: RightPreset;
    managerGroups: RightPreset;
    regularCalendars: RightPreset;
    symbolMaps: RightPreset;
    markups: RightPreset;
    hosts: RightPreset;
    timezones: RightPreset;
    currencyGroups: RightPreset;
    symbolGroups: RightPreset;
    irregularCalendars: RightPreset;
    commission: RightPreset;
    dataFeeds: RightPreset;
    tradingRoutes: RightPreset;
    gateways: RightPreset;
    positions: RightPreset;
    orders: RightPreset;
    deals: RightPreset;
    summary: RightPreset;
    mail: RightPreset;
    emailTemplates: RightPreset;
    pushNotificationTemplates: RightPreset;
    languages: RightPreset;
    chartBars: RightPreset;
    chartBarsImport: RightPreset;
    ticks: RightPreset;
    sessions: RightPreset;
    market: RightPreset;
    journal: RightPreset;
    businessJournal: RightPreset;
    leverageProfiles: RightPreset;
    marginRateProfiles: RightPreset;
    newsProfiles: RightPreset;
    balanceTransactions: RightPreset;
    dailyTradeReport: RightPreset;
    firewall: RightPreset;
    securityProfiles: RightPreset;
    dividends: RightPreset;
    splits: RightPreset;
    legalDocuments: RightPreset;
};

export const AccessContext = createContext<AdminPanelAccessMap | null>(null);

export const rightsPresets: RightsPreset = {
    market: {
        view: [ManagerRight.SYMBOLS_VIEW, ManagerRight.TICK_HISTORY_VIEW],
        create: [],
        edit: [],
        delete: [],
    },
    journal: {
        create: [],
        edit: [],
        delete: [],
    },
    businessJournal: {
        create: [],
        edit: [],
        delete: [],
    },
    balanceTransactions: {
        view: [ManagerRight.TRADE_HISTORY_VIEW, ManagerRight.ACCOUNTS_VIEW, ManagerRight.SYMBOLS_VIEW],
        create: [],
        edit: [],
        delete: [],
    },
    dailyTradeReport: {
        view: [ManagerRight.BILLING_STATISTICS_VIEW],
        create: [],
        edit: [],
        delete: [],
    },
    modules: {
        view: [ManagerRight.MODULES_VIEW],
        create: [ManagerRight.MODULES_CREATE, ManagerRight.HOSTS_VIEW],
        edit: [ManagerRight.MODULES_EDIT],
        delete: [ManagerRight.MODULES_DELETE],
    },
    platforms: {
        create: [ManagerRight.PLATFORMS_CREATE, ManagerRight.TIMEZONES_VIEW],
        edit: [ManagerRight.PLATFORMS_EDIT],
        delete: [ManagerRight.PLATFORMS_DELETE],
    },
    currencies: {
        create: [ManagerRight.CURRENCIES_CREATE, ManagerRight.CURRENCY_GROUPS_VIEW],
        edit: [ManagerRight.CURRENCIES_EDIT],
        delete: [ManagerRight.CURRENCIES_DELETE],
    },
    symbols: {
        create: [ManagerRight.SYMBOLS_CREATE, ManagerRight.CURRENCIES_VIEW, ManagerRight.SYMBOL_GROUPS_VIEW],
        edit: [ManagerRight.SYMBOLS_EDIT],
        delete: [ManagerRight.SYMBOLS_DELETE],
    },
    accounts: {
        create: [ManagerRight.ACCOUNTS_CREATE, ManagerRight.ACCOUNT_GROUPS_VIEW],
        edit: [ManagerRight.ACCOUNTS_EDIT],
        delete: [ManagerRight.ACCOUNTS_DELETE],
    },
    accountGroups: {
        create: [ManagerRight.ACCOUNT_GROUPS_CREATE, ManagerRight.CURRENCIES_VIEW, ManagerRight.PLATFORMS_VIEW],
        edit: [ManagerRight.ACCOUNT_GROUPS_EDIT],
        delete: [ManagerRight.ACCOUNT_GROUPS_DELETE],
    },
    managers: {
        create: [ManagerRight.MANAGERS_CREATE, ManagerRight.MANAGER_GROUPS_VIEW],
        edit: [ManagerRight.MANAGERS_EDIT],
        delete: [ManagerRight.MANAGERS_DELETE],
    },
    managerGroups: {
        create: [ManagerRight.MANAGER_GROUPS_CREATE, ManagerRight.PLATFORMS_VIEW],
        edit: [ManagerRight.MANAGER_GROUPS_EDIT],
        delete: [ManagerRight.MANAGER_GROUPS_DELETE],
    },
    regularCalendars: {
        create: [
            [ManagerRight.CALENDAR_RULES_CREATE, ManagerRight.TIMEZONES_VIEW, ManagerRight.SYMBOLS_VIEW],
            [ManagerRight.CALENDAR_RULES_CREATE, ManagerRight.TIMEZONES_VIEW, ManagerRight.SYMBOL_GROUPS_VIEW],
        ],
        edit: [ManagerRight.CALENDAR_RULES_EDIT],
        delete: [ManagerRight.CALENDAR_RULES_DELETE],
    },
    symbolMaps: {
        create: [ManagerRight.SYMBOL_MAPS_CREATE, ManagerRight.SYMBOLS_VIEW],
        edit: [ManagerRight.SYMBOL_MAPS_EDIT],
        delete: [ManagerRight.MODULES_DELETE],
    },
    markups: {
        create: [ManagerRight.MARKUP_PROFILES_CREATE, ManagerRight.SYMBOLS_VIEW],
        edit: [ManagerRight.MARKUP_PROFILES_EDIT],
        delete: [ManagerRight.MARKUP_PROFILES_DELETE],
    },
    hosts: {
        create: [ManagerRight.HOSTS_CREATE],
        edit: [ManagerRight.HOSTS_EDIT],
        delete: [ManagerRight.HOSTS_DELETE],
    },
    timezones: {
        create: [ManagerRight.TIMEZONES_CREATE],
        edit: [ManagerRight.TIMEZONES_EDIT],
        delete: [ManagerRight.TIMEZONES_DELETE],
    },
    currencyGroups: {
        create: [ManagerRight.CURRENCY_GROUPS_CREATE],
        edit: [ManagerRight.CURRENCY_GROUPS_EDIT],
        delete: [ManagerRight.CURRENCY_GROUPS_DELETE],
    },
    symbolGroups: {
        create: [ManagerRight.SYMBOL_GROUPS_CREATE],
        edit: [ManagerRight.SYMBOL_GROUPS_EDIT],
        delete: [ManagerRight.SYMBOL_GROUPS_DELETE],
    },
    irregularCalendars: {
        create: [ManagerRight.OFF_TIME_RULES_CREATE],
        edit: [ManagerRight.OFF_TIME_RULES_EDIT],
        delete: [ManagerRight.OFF_TIME_RULES_DELETE],
    },
    commission: {
        create: [ManagerRight.COMMISSION_PROFILES_CREATE],
        edit: [ManagerRight.COMMISSION_PROFILES_EDIT],
        delete: [ManagerRight.COMMISSION_PROFILES_DELETE],
    },
    dataFeeds: {
        create: [ManagerRight.DATA_FEEDS_CREATE],
        edit: [ManagerRight.DATA_FEEDS_EDIT],
        delete: [ManagerRight.DATA_FEEDS_DELETE],
    },
    tradingRoutes: {
        create: [ManagerRight.TRADING_ROUTE_RULES_CREATE],
        edit: [ManagerRight.TRADING_ROUTE_RULES_EDIT],
        delete: [ManagerRight.TRADING_ROUTE_RULES_DELETE],
    },
    gateways: {
        create: [ManagerRight.GATEWAYS_CREATE],
        edit: [ManagerRight.GATEWAYS_EDIT],
        delete: [ManagerRight.GATEWAYS_DELETE],
    },
    positions: {
        create: [ManagerRight.ORDERS_CREATE],
        edit: [ManagerRight.ORDERS_EDIT],
        delete: [ManagerRight.ORDERS_DELETE],
        // create: [ManagerRight.POSITIONS_CREATE],
        // edit: [ManagerRight.POSITIONS_EDIT],
        // delete: [ManagerRight.POSITIONS_DELETE],
    },
    orders: {
        create: [ManagerRight.ORDERS_CREATE],
        edit: [ManagerRight.ORDERS_EDIT],
        delete: [ManagerRight.ORDERS_DELETE],
    },
    deals: {
        create: [ManagerRight.ORDERS_CREATE],
        edit: [ManagerRight.ORDERS_EDIT],
        delete: [ManagerRight.ORDERS_DELETE],
    },
    summary: {
        create: [ManagerRight.ORDERS_CREATE],
        edit: [ManagerRight.ORDERS_EDIT],
        delete: [ManagerRight.ORDERS_DELETE],
    },
    mail: {
        create: [ManagerRight.MAIL_SERVERS_CREATE],
        edit: [ManagerRight.MAIL_SERVERS_EDIT],
        delete: [ManagerRight.MAIL_SERVERS_DELETE],
    },
    emailTemplates: {
        create: [ManagerRight.EMAIL_TEMPLATE_CREATE],
        edit: [ManagerRight.EMAIL_TEMPLATE_EDIT],
        delete: [ManagerRight.EMAIL_TEMPLATE_DELETE],
    },
    pushNotificationTemplates: {
        create: [],
        edit: [],
        delete: [],
    },
    languages: {
        create: [],
        edit: [],
        delete: [],
    },
    chartBars: { create: [], edit: [ManagerRight.CHART_BARS_EDIT], delete: [] },
    chartBarsImport: { create: [], edit: [ManagerRight.CHART_BARS_IMPORT], delete: [] },
    ticks: { create: [], edit: [], delete: [] },
    sessions: { delete: [ManagerRight.SESSIONS_DROP], create: [], edit: [] },
    leverageProfiles: {
        create: [ManagerRight.LEVERAGE_PROFILE_CREATE],
        edit: [ManagerRight.LEVERAGE_PROFILE_EDIT],
        delete: [ManagerRight.LEVERAGE_PROFILE_DELETE],
    },
    marginRateProfiles: {
        create: [ManagerRight.MARGIN_RATE_PROFILE_CREATE],
        edit: [ManagerRight.MARGIN_RATE_PROFILE_EDIT],
        delete: [ManagerRight.MARGIN_RATE_PROFILE_DELETE],
    },
    newsProfiles: {
        create: [ManagerRight.NEWS_PROFILE_CREATE],
        edit: [ManagerRight.NEWS_PROFILE_EDIT],
        delete: [ManagerRight.NEWS_PROFILE_DELETE],
    },
    firewall: {
        create: [ManagerRight.FIREWALL_RULES_CREATE],
        edit: [ManagerRight.FIREWALL_RULES_EDIT],
        delete: [ManagerRight.FIREWALL_RULES_DELETE],
    },
    securityProfiles: {
        create: [ManagerRight.SECURITY_SETTINGS_CREATE],
        edit: [ManagerRight.SECURITY_SETTINGS_EDIT],
        delete: [ManagerRight.SECURITY_SETTINGS_DELETE],
    },
    dividends: { create: [], edit: [], delete: [] },
    splits: { create: [], edit: [], delete: [] },
    legalDocuments: {
        create: [ManagerRight.LEGAL_DOCUMENT_GROUP_CREATE],
        edit: [ManagerRight.LEGAL_DOCUMENT_GROUP_EDIT],
        delete: [ManagerRight.LEGAL_DOCUMENT_GROUP_DELETE],
    },
};

export const getMissingRights = (
    requiredRights: ManagerRight[] | ManagerRight[][],
    rights: number[],
): ManagerRight[] | ManagerRight[][] => {
    let missingRights: ManagerRight[] | ManagerRight[][] = [];

    if (!requiredRights || !requiredRights.length) {
        return [];
    }
    if (Array.isArray(requiredRights[0])) {
        requiredRights.forEach((right) => {
            const missingSetRights: ManagerRight[] = [];
            (right as ManagerRight[]).forEach((innerRight) => {
                if (!rights.includes(innerRight.toJSON())) {
                    missingSetRights.push(innerRight);
                }
            });
            if (missingSetRights.length) {
                (missingRights as ManagerRight[][]).push(missingSetRights);
            } else missingRights = [];
        });
        const sortedMissingRights = ([...missingRights] as ManagerRight[][]).map((rightSet) =>
            rightSet.sort((a, b) => (a.toJSON() > b.toJSON() ? 1 : -1)),
        );
        const stringMissingRights = sortedMissingRights.map((rightSet) => JSON.stringify(rightSet));
        missingRights = sortedMissingRights.filter(
            (rightSet, index) => stringMissingRights.indexOf(JSON.stringify(rightSet)) === index,
        );
    } else
        requiredRights.forEach((right) => {
            if (!rights.includes((right as ManagerRight).toJSON())) {
                (missingRights as ManagerRight[]).push(right);
            }
        });

    return missingRights;
};

export const getAccessMap = (rights: number[]): AdminPanelAccessMap => {
    const checkAccess = (requiredRights: ManagerRight[] | ManagerRight[][]): boolean => {
        if (!requiredRights || !requiredRights.length) return true;
        return Array.isArray(requiredRights[0])
            ? (requiredRights as ManagerRight[][]).some((rightSet) =>
                  rightSet.every((right) => rights.includes(right.toJSON())),
              )
            : (requiredRights as ManagerRight[]).every((right) => rights.includes(right.toJSON()));
    };

    const getActionAccess = (requiredRights: ManagerRight[] | ManagerRight[][]): TabActionsAccess => {
        const missingRights = getMissingRights(requiredRights, rights);
        return {
            accessible: !missingRights.length && !Array.isArray(missingRights[0]),
            missingRights,
        };
    };

    return {
        system: {
            accessible: checkAccess([[ManagerRight.HOSTS_VIEW], [ManagerRight.TIMEZONES_VIEW]]),
            tabs: {
                [SYSTEM_PAGES.HOSTS]: {
                    accessible: checkAccess([ManagerRight.HOSTS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.hosts.edit),
                        create: getActionAccess(rightsPresets.hosts.create),
                        delete: getActionAccess(rightsPresets.hosts.delete),
                    },
                },
                [SYSTEM_PAGES.TIMEZONES]: {
                    accessible: checkAccess([ManagerRight.TIMEZONES_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.timezones.edit),
                        create: getActionAccess(rightsPresets.timezones.create),
                        delete: getActionAccess(rightsPresets.timezones.delete),
                    },
                },
            },
            custom: null,
        },
        modules: {
            accessible: checkAccess([ManagerRight.MODULES_VIEW]),
            tabs: {
                modules: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.modules.edit),
                        create: getActionAccess(rightsPresets.modules.create),
                        delete: getActionAccess(rightsPresets.modules.delete),
                    },
                },
            },
            custom: {
                platforms: checkAccess([ManagerRight.PLATFORMS_VIEW]),
                hosts: checkAccess([ManagerRight.HOSTS_VIEW]),
            },
        },
        platforms: {
            accessible: checkAccess([ManagerRight.PLATFORMS_VIEW]),
            tabs: {
                platforms: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.platforms.edit),
                        create: getActionAccess(rightsPresets.platforms.create),
                        delete: getActionAccess(rightsPresets.platforms.delete),
                    },
                },
            },
            custom: {
                timezones: checkAccess([ManagerRight.TIMEZONES_VIEW]),
            },
        },
        currencies: {
            accessible: checkAccess([[ManagerRight.CURRENCIES_VIEW], [ManagerRight.CURRENCY_GROUPS_VIEW]]),
            tabs: {
                [CURRENCY_PAGES.CURRENCIES]: {
                    accessible: checkAccess([ManagerRight.CURRENCIES_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.currencies.edit),
                        create: getActionAccess(rightsPresets.currencies.create),
                        delete: getActionAccess(rightsPresets.currencies.delete),
                    },
                },
                [CURRENCY_PAGES.TYPES]: {
                    accessible: checkAccess([ManagerRight.CURRENCY_GROUPS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.currencyGroups.edit),
                        create: getActionAccess(rightsPresets.currencyGroups.create),
                        delete: getActionAccess(rightsPresets.currencyGroups.delete),
                    },
                },
            },
            custom: {
                currencyGroups: checkAccess([ManagerRight.CURRENCY_GROUPS_VIEW]),
            },
        },
        symbols: {
            accessible: checkAccess([[ManagerRight.SYMBOLS_VIEW], [ManagerRight.SYMBOL_GROUPS_VIEW]]),
            tabs: {
                [SYMBOLS_PAGES.SYMBOLS]: {
                    accessible: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.symbols.edit),
                        create: getActionAccess(rightsPresets.symbols.create),
                        delete: getActionAccess(rightsPresets.symbols.delete),
                    },
                },
                [SYMBOLS_PAGES.SYMBOL_GROUPS]: {
                    accessible: checkAccess([ManagerRight.SYMBOL_GROUPS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.symbolGroups.edit),
                        create: getActionAccess(rightsPresets.symbolGroups.create),
                        delete: getActionAccess(rightsPresets.symbolGroups.delete),
                    },
                },
                [SYMBOLS_PAGES.WATCHLIST_SETTINGS]: {
                    accessible: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.symbols.edit),
                        create: getActionAccess(rightsPresets.symbols.create),
                        delete: getActionAccess(rightsPresets.symbols.delete),
                    },
                },
            },
            custom: {
                currencies: checkAccess([ManagerRight.CURRENCIES_VIEW]),
                symbolGroups: checkAccess([ManagerRight.SYMBOL_GROUPS_VIEW]),
                symbolsView: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                regularCalendar: checkAccess([ManagerRight.CALENDAR_RULES_VIEW]),
            },
        },
        accounts: {
            accessible: checkAccess([[ManagerRight.ACCOUNTS_VIEW], [ManagerRight.ACCOUNT_GROUPS_VIEW]]),
            tabs: {
                [ACCOUNTS_PAGES.ACCOUNTS]: {
                    accessible: checkAccess([ManagerRight.ACCOUNTS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.accounts.edit),
                        create: getActionAccess(rightsPresets.accounts.create),
                        delete: getActionAccess(rightsPresets.accounts.delete),
                    },
                },
                [ACCOUNTS_PAGES.ACCOUNT_GROUPS]: {
                    accessible: checkAccess([ManagerRight.ACCOUNT_GROUPS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.accountGroups.edit),
                        create: getActionAccess(rightsPresets.accountGroups.create),
                        delete: getActionAccess(rightsPresets.accountGroups.delete),
                    },
                },
            },
            custom: {
                currencies: checkAccess([ManagerRight.CURRENCIES_VIEW]),
                orders: checkAccess([ManagerRight.ORDERS_VIEW]),
                tradeHistory: checkAccess([ManagerRight.TRADE_HISTORY_VIEW]),
                deposit: checkAccess([ManagerRight.ACCOUNTS_DEPOSIT]),
                credit: checkAccess([ManagerRight.ACCOUNTS_CREDIT]),
                // positions: checkAccess([ManagerRight.POSITIONS_VIEW]),
                positions: checkAccess([ManagerRight.ORDERS_VIEW]),
                platforms: checkAccess([ManagerRight.PLATFORMS_VIEW]),
                symbolGroups: checkAccess([ManagerRight.SYMBOL_GROUPS_VIEW]),
                markups: checkAccess([ManagerRight.MARKUP_PROFILES_VIEW]),
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                commissions: checkAccess([ManagerRight.COMMISSION_PROFILES_VIEW]),
                accountGroups: checkAccess([ManagerRight.ACCOUNT_GROUPS_VIEW]),
                personalInfo: checkAccess([ManagerRight.ACCOUNTS_PERSONAL_DETAILS_VIEW]),
            },
        },
        managers: {
            accessible: checkAccess([[ManagerRight.MANAGERS_VIEW], [ManagerRight.MANAGER_GROUPS_VIEW]]),
            tabs: {
                [MANAGERS_PAGES.MANAGERS]: {
                    accessible: checkAccess([ManagerRight.MANAGERS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.managers.edit),
                        create: getActionAccess(rightsPresets.managers.create),
                        delete: getActionAccess(rightsPresets.managers.delete),
                    },
                },
                [MANAGERS_PAGES.MANAGER_GROUPS]: {
                    accessible: checkAccess([ManagerRight.MANAGER_GROUPS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.managerGroups.edit),
                        create: getActionAccess(rightsPresets.managerGroups.create),
                        delete: getActionAccess(rightsPresets.managerGroups.delete),
                    },
                },
            },
            custom: {
                managerGroups: checkAccess([ManagerRight.MANAGER_GROUPS_VIEW]),
                accountGroups: checkAccess([ManagerRight.ACCOUNT_GROUPS_VIEW]),
                symbolGroups: checkAccess([ManagerRight.SYMBOL_GROUPS_EDIT]),
                platforms: checkAccess([ManagerRight.PLATFORMS_VIEW]),
            },
        },
        calendars: {
            accessible: checkAccess([[ManagerRight.CALENDAR_RULES_VIEW], [ManagerRight.OFF_TIME_RULES_VIEW]]),
            tabs: {
                [CALENDAR_PAGES.REGULAR]: {
                    accessible: checkAccess([ManagerRight.CALENDAR_RULES_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.regularCalendars.edit),
                        create: getActionAccess(rightsPresets.regularCalendars.create),
                        delete: getActionAccess(rightsPresets.regularCalendars.delete),
                    },
                },
                [CALENDAR_PAGES.OFFTIME]: {
                    accessible: checkAccess([ManagerRight.OFF_TIME_RULES_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.irregularCalendars.edit),
                        create: getActionAccess(rightsPresets.irregularCalendars.create),
                        delete: getActionAccess(rightsPresets.irregularCalendars.delete),
                    },
                },
            },
            custom: {
                irregularCalendars: checkAccess([ManagerRight.OFF_TIME_RULES_VIEW]),
                timezones: checkAccess([ManagerRight.TIMEZONES_VIEW]),
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                symbolGroups: checkAccess([ManagerRight.SYMBOL_GROUPS_VIEW]),
            },
        },
        profiles: {
            accessible: checkAccess([
                [ManagerRight.SYMBOL_MAPS_VIEW],
                [ManagerRight.COMMISSION_PROFILES_VIEW],
                [ManagerRight.MARKUP_PROFILES_VIEW],
            ]),
            tabs: {
                [PROFILES_PAGES.SYMBOL_MAPS]: {
                    accessible: checkAccess([ManagerRight.SYMBOL_MAPS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.symbolMaps.edit),
                        create: getActionAccess(rightsPresets.symbolMaps.create),
                        delete: getActionAccess(rightsPresets.symbolMaps.delete),
                    },
                },
                [PROFILES_PAGES.COMMISSION]: {
                    accessible: checkAccess([ManagerRight.COMMISSION_PROFILES_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.commission.edit),
                        create: getActionAccess(rightsPresets.commission.create),
                        delete: getActionAccess(rightsPresets.commission.delete),
                    },
                },
                [PROFILES_PAGES.MARKUP]: {
                    accessible: checkAccess([ManagerRight.MARKUP_PROFILES_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.markups.edit),
                        create: getActionAccess(rightsPresets.markups.create),
                        delete: getActionAccess(rightsPresets.markups.delete),
                    },
                },
            },
            custom: {
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
            },
        },
        dataFeeds: {
            accessible: checkAccess([ManagerRight.DATA_FEEDS_VIEW]),
            tabs: {
                dataFeeds: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.dataFeeds.edit),
                        create: getActionAccess(rightsPresets.dataFeeds.create),
                        delete: getActionAccess(rightsPresets.dataFeeds.delete),
                    },
                },
            },
            custom: {
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                symbolGroups: checkAccess([ManagerRight.SYMBOL_GROUPS_EDIT]),
                symbolMaps: checkAccess([ManagerRight.SYMBOL_MAPS_VIEW]),
            },
        },
        tradingRoutes: {
            accessible: checkAccess([ManagerRight.TRADING_ROUTE_RULES_VIEW]),
            tabs: {
                tradingRoutes: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.tradingRoutes.edit),
                        create: getActionAccess(rightsPresets.tradingRoutes.create),
                        delete: getActionAccess(rightsPresets.tradingRoutes.delete),
                    },
                },
            },
            custom: {
                showConditions: checkAccess([
                    ManagerRight.ACCOUNTS_VIEW,
                    ManagerRight.ACCOUNT_GROUPS_VIEW,
                    ManagerRight.SYMBOLS_VIEW,
                    ManagerRight.SYMBOL_GROUPS_VIEW,
                ]),
                gateways: checkAccess([ManagerRight.SYMBOL_GROUPS_VIEW]),
            },
        },
        gateways: {
            accessible: checkAccess([ManagerRight.GATEWAYS_VIEW]),
            tabs: {
                gateways: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.gateways.edit),
                        create: getActionAccess(rightsPresets.gateways.create),
                        delete: getActionAccess(rightsPresets.gateways.delete),
                    },
                },
            },
            custom: {
                symbolMaps: checkAccess([ManagerRight.SYMBOL_MAPS_VIEW]),
            },
        },
        quoteHistory: {
            accessible: checkAccess([[ManagerRight.CHART_BARS_VIEW], [ManagerRight.TICK_HISTORY_VIEW]]),
            tabs: {
                [QUOTE_HISTORY_PAGES.CHARTS_BARS]: {
                    accessible: checkAccess([ManagerRight.CHART_BARS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.chartBars.edit),
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
                [QUOTE_HISTORY_PAGES.CHARTS_BARS_IMPORT]: {
                    accessible: checkAccess([ManagerRight.CHART_BARS_IMPORT]),
                    actions: {
                        edit: getActionAccess(rightsPresets.chartBarsImport.edit),
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
                [QUOTE_HISTORY_PAGES.TICKS]: {
                    accessible: checkAccess([ManagerRight.TICK_HISTORY_VIEW]),
                    actions: {
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
            },
            custom: {
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
            },
        },
        sessions: {
            accessible: checkAccess([ManagerRight.SESSIONS_VIEW]),
            tabs: {
                sessions: {
                    accessible: true,
                    actions: {
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: getActionAccess(rightsPresets.sessions.delete),
                    },
                },
            },
            custom: {
                accounts: checkAccess([ManagerRight.ACCOUNTS_VIEW]),
                accountGroups: checkAccess([ManagerRight.ACCOUNT_GROUPS_VIEW]),
                currencies: checkAccess([ManagerRight.CURRENCIES_VIEW]),
                // platforms: checkAccess([ManagerRight.PLATFORMS_VIEW]),
                personalInfo: checkAccess([ManagerRight.ACCOUNTS_PERSONAL_DETAILS_VIEW]),
            },
        },
        stocks: {
            accessible: checkAccess([ManagerRight.ORDERS_ADJUST_DIVIDENDS]) || checkAccess([ManagerRight.ORDERS_SPLIT]),
            tabs: {
                [STOCKS_PAGES.DIVIDENDS]: {
                    accessible: checkAccess([ManagerRight.ORDERS_ADJUST_DIVIDENDS]),
                    actions: {
                        edit: getActionAccess(rightsPresets.dividends.edit!),
                        create: getActionAccess(rightsPresets.dividends.create!),
                        delete: getActionAccess(rightsPresets.dividends.delete!),
                    },
                },
                [STOCKS_PAGES.SPLITS]: {
                    accessible: checkAccess([ManagerRight.ORDERS_SPLIT]),
                    actions: {
                        edit: getActionAccess(rightsPresets.splits.edit!),
                        create: getActionAccess(rightsPresets.splits.create!),
                        delete: getActionAccess(rightsPresets.splits.delete!),
                    },
                },
            },
            custom: {
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                accountsGroups: checkAccess([ManagerRight.ACCOUNT_GROUPS_VIEW]),
            },
        },
        riskManagement: {
            accessible: checkAccess([[ManagerRight.ORDERS_VIEW], [ManagerRight.TRADE_HISTORY_VIEW]]),
            tabs: {
                [RM_PAGES.POSITIONS]: {
                    accessible: checkAccess([ManagerRight.ORDERS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.orders.edit),
                        create: getActionAccess(rightsPresets.orders.create),
                        delete: getActionAccess(rightsPresets.orders.delete),
                    },
                },
                [RM_PAGES.PENDING_ORDERS]: {
                    accessible: checkAccess([ManagerRight.ORDERS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.orders.edit),
                        create: getActionAccess(rightsPresets.orders.create),
                        delete: getActionAccess(rightsPresets.orders.delete),
                    },
                },
                [RM_PAGES.DEALS]: {
                    accessible: checkAccess([ManagerRight.TRADE_HISTORY_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.deals.edit),
                        create: getActionAccess(rightsPresets.deals.create),
                        delete: getActionAccess(rightsPresets.deals.delete),
                    },
                },
                [RM_PAGES.SUMMARY]: {
                    accessible: checkAccess([ManagerRight.ORDERS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.orders.edit),
                        create: getActionAccess(rightsPresets.orders.create),
                        delete: getActionAccess(rightsPresets.orders.delete),
                    },
                },
            },
            custom: {
                currencies: checkAccess([ManagerRight.CURRENCIES_VIEW]),
                accounts: checkAccess([ManagerRight.ACCOUNTS_VIEW]),
                orders: checkAccess([ManagerRight.ORDERS_VIEW]),
                positions: checkAccess([ManagerRight.ORDERS_VIEW]),
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                accountsGroups: checkAccess([ManagerRight.ACCOUNT_GROUPS_VIEW]),
            },
        },
        market: {
            accessible: checkAccess([ManagerRight.TICK_HISTORY_VIEW]),
            tabs: {
                market: {
                    accessible: true,
                    actions: {
                        view: getActionAccess(rightsPresets.market.view!),
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
            },
            custom: {
                calendars: checkAccess([ManagerRight.CALENDAR_RULES_VIEW]),
                currencies: checkAccess([ManagerRight.CURRENCIES_VIEW]),
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
                symbolGroups: checkAccess([ManagerRight.SYMBOL_GROUPS_VIEW]),
            },
        },
        balanceTransactions: {
            accessible: checkAccess([ManagerRight.TRADE_HISTORY_VIEW]),
            tabs: {
                balanceTransactions: {
                    accessible: true,
                    actions: {
                        view: getActionAccess(rightsPresets.balanceTransactions.view!),
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
            },
            custom: {
                accounts: checkAccess([ManagerRight.ACCOUNTS_VIEW]),
                symbols: checkAccess([ManagerRight.SYMBOLS_VIEW]),
            },
        },
        dailyTradeReport: {
            accessible: checkAccess([ManagerRight.BILLING_STATISTICS_VIEW]),
            tabs: {
                dailyTradeReport: {
                    accessible: true,
                    actions: {
                        view: getActionAccess(rightsPresets.dailyTradeReport.view!),
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
            },
            custom: null,
        },
        statements: {
            accessible: checkAccess([ManagerRight.CLIENT_REPORTS_VIEW]),
            tabs: {
                statements: {
                    accessible: true,
                    actions: {
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
            },
            custom: {
                resendReport: checkAccess([ManagerRight.CLIENT_REPORTS_SEND]),
            },
        },
        modulesWatch: {
            accessible: checkAccess([ManagerRight.MODULES_SYSTEM_INFO_VIEW]),
            tabs: {
                modulesWatch: {
                    accessible: true,
                    actions: {
                        view: getActionAccess(rightsPresets.modules.view!),
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
            },
            custom: {
                modules: checkAccess([ManagerRight.MODULES_VIEW]),
            },
        },
        journal: {
            accessible: checkAccess([ManagerRight.JOURNAL_VIEW]),
            tabs: {
                journal: {
                    accessible: true,
                    actions: {
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
            },
            custom: null,
        },
        businessJournal: {
            accessible: checkAccess([ManagerRight.JOURNAL_VIEW]),
            tabs: {
                businessJournal: {
                    accessible: true,
                    actions: {
                        edit: { accessible: false, missingRights: [] },
                        create: { accessible: false, missingRights: [] },
                        delete: { accessible: false, missingRights: [] },
                    },
                },
            },
            custom: null,
        },
        mail: {
            accessible: checkAccess([ManagerRight.MAIL_SERVERS_VIEW]),
            tabs: {
                mail: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.mail.edit),
                        create: getActionAccess(rightsPresets.mail.create),
                        delete: getActionAccess(rightsPresets.mail.delete),
                    },
                },
            },
            custom: null,
        },
        emailTemplates: {
            accessible: checkAccess([ManagerRight.EMAIL_TEMPLATE_VIEW]),
            tabs: {
                emailTemplates: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.emailTemplates.edit),
                        create: getActionAccess(rightsPresets.emailTemplates.create),
                        delete: getActionAccess(rightsPresets.emailTemplates.delete),
                    },
                },
            },
            custom: null,
        },
        languages: {
            accessible: true,
            tabs: {
                languages: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.languages.edit),
                        create: getActionAccess(rightsPresets.languages.create),
                        delete: getActionAccess(rightsPresets.languages.delete),
                    },
                },
            },
            custom: null,
        },
        pushNotifications: {
            accessible: true,
            tabs: {
                pushNotifications: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.pushNotificationTemplates.edit),
                        create: getActionAccess(rightsPresets.pushNotificationTemplates.create),
                        delete: getActionAccess(rightsPresets.pushNotificationTemplates.delete),
                    },
                },
            },
            custom: null,
        },
        leverageProfiles: {
            accessible: checkAccess([ManagerRight.LEVERAGE_PROFILE_VIEW]),
            tabs: {
                leverageProfiles: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.leverageProfiles.edit),
                        create: getActionAccess(rightsPresets.leverageProfiles.create),
                        delete: getActionAccess(rightsPresets.leverageProfiles.delete),
                    },
                },
            },
            custom: null,
        },
        hlbProfiles: {
            accessible: checkAccess([[ManagerRight.MARGIN_RATE_PROFILE_VIEW], [ManagerRight.NEWS_PROFILE_VIEW]]),
            tabs: {
                [HLB_PROFILES_PAGES.MARGIN_RATE]: {
                    accessible: checkAccess([ManagerRight.MARGIN_RATE_PROFILE_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.marginRateProfiles.edit),
                        create: getActionAccess(rightsPresets.marginRateProfiles.create),
                        delete: getActionAccess(rightsPresets.marginRateProfiles.delete),
                    },
                },
                [HLB_PROFILES_PAGES.NEWS]: {
                    accessible: checkAccess([ManagerRight.NEWS_PROFILE_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.newsProfiles.edit),
                        create: getActionAccess(rightsPresets.newsProfiles.create),
                        delete: getActionAccess(rightsPresets.newsProfiles.delete),
                    },
                },
            },
            custom: null,
        },
        security: {
            accessible: checkAccess([[ManagerRight.SECURITY_SETTINGS_VIEW], [ManagerRight.FIREWALL_RULES_VIEW]]),
            tabs: {
                [SECURITY_PAGES.SECURITY_PROFILES]: {
                    accessible: checkAccess([ManagerRight.SECURITY_SETTINGS_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.securityProfiles.edit),
                        create: getActionAccess(rightsPresets.securityProfiles.create),
                        delete: getActionAccess(rightsPresets.securityProfiles.delete),
                    },
                },
                [SECURITY_PAGES.FIREWALL]: {
                    accessible: checkAccess([ManagerRight.FIREWALL_RULES_VIEW]),
                    actions: {
                        edit: getActionAccess(rightsPresets.firewall.edit),
                        create: getActionAccess(rightsPresets.firewall.create),
                        delete: getActionAccess(rightsPresets.firewall.delete),
                    },
                },
            },
            custom: null,
        },
        legalDocuments: {
            accessible: true,
            tabs: {
                legalDocuments: {
                    accessible: true,
                    actions: {
                        edit: getActionAccess(rightsPresets.legalDocuments.edit),
                        create: getActionAccess(rightsPresets.legalDocuments.create),
                        delete: getActionAccess(rightsPresets.legalDocuments.delete),
                    },
                },
            },
            custom: {
                documentsDelete: checkAccess([ManagerRight.LEGAL_DOCUMENT_DELETE]),
                documentsUpload: checkAccess([ManagerRight.LEGAL_DOCUMENT_UPLOAD]),
            },
        },
    };
};
