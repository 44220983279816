import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { ErrorsTracker } from '@api/errorsTracker';
import { Message, MessageType } from '@components/Message/Message';
import { OrdersSplitExecuteRequest } from '@api/fbe/manager/OrdersSplitExecuteRequest';
import { OrdersSplitCommonParamsModel } from '@models/stocks/Splits';
import { OrdersSplitExecuteResponse } from '@api/fbe/manager/OrdersSplitExecuteResponse';
import { OrdersSplitCommonParams } from '@api/fbe/manager/OrdersSplitCommonParams';
import { UInt64 } from '@api/fbe/int64';
import { PriceRoundingPolicy } from '@api/fbe/manager/PriceRoundingPolicy';
import { AmountRoundingPolicy } from '@api/fbe/manager/AmountRoundingPolicy';

export class SplitsStore {
    errorTracker = new ErrorsTracker({ title: 'Splits' });

    constructor() {
        makeAutoObservable(this);
    }

    isLoading: boolean = false;

    requestParams: OrdersSplitCommonParamsModel | null = null;

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setRequestParams(value: OrdersSplitCommonParamsModel | null) {
        this.requestParams = value;
    }

    @ErrorsTracker.wrapApi()
    async execute(): Promise<OrdersSplitExecuteResponse> {
        const request = new OrdersSplitExecuteRequest();
        if (this.requestParams === null) {
            Message(MessageType.error, 'Request params are required');
            return Promise.reject();
        }

        request.Params = new OrdersSplitCommonParams();
        request.Params.SymbolName = this.requestParams.SymbolName;
        request.Params.GroupNamePatterns = this.requestParams.GroupNamePatterns;
        request.Params.AmountRoundingPolicy = new AmountRoundingPolicy(this.requestParams.AmountRoundingPolicy);
        request.Params.PriceRoundingPolicy = new PriceRoundingPolicy(this.requestParams.PriceRoundingPolicy);
        request.Params.OldShares = UInt64.fromNumber(this.requestParams.OldShares);
        request.Params.NewShares = UInt64.fromNumber(this.requestParams.NewShares);

        this.setIsLoading(true);

        try {
            const result = await SocketClient.instance.request(request, this.errorTracker);
            this.setIsLoading(false);
            return result;
        } catch (e) {
            this.setIsLoading(false);
            return Promise.reject(e);
        }
    }

    reset() {
        this.isLoading = false;
        this.requestParams = null;
    }
}

const splitsStore = new SplitsStore();

export { splitsStore };
