
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {TradingCalendarOffTimeRuleModifyResponse} from './TradingCalendarOffTimeRuleModifyResponse';
import {DaysOfWeek} from '../core/DaysOfWeek';
import {FieldModelDaysOfWeek} from '../core/DaysOfWeek';
import {OffTimeType} from '../core/OffTimeType';
import {FieldModelOffTimeType} from '../core/OffTimeType';

/**
 * TradingCalendarOffTimeRuleModifyRequest struct
 */
class TradingCalendarOffTimeRuleModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} ruleId
   * @param {string=} ruleName
   * @param {string=} newName
   * @param {Date=} newDay
   * @param {DaysOfWeek=} newWeekDays
   * @param {number=} newMonthDay
   * @param {number=} newYearMonth
   * @param {OffTimeType=} newOffTimeType
   * @param {boolean=} newIsMarketEnabled
   * @param {boolean=} newIsTradingEnabled
   * @param {number=} newStartTime
   * @param {number=} newEndTime
   * @param {string=} newComment
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), ruleId = null, ruleName = null, newName = null, newDay = null, newWeekDays = null, newMonthDay = null, newYearMonth = null, newOffTimeType = null, newIsMarketEnabled = null, newIsTradingEnabled = null, newStartTime = null, newEndTime = null, newComment = null) {
    super()
    super.copy(parent)
    this.RuleId = ruleId
    this.RuleName = ruleName
    this.NewName = newName
    this.NewDay = newDay
    this.NewWeekDays = newWeekDays
    this.NewMonthDay = newMonthDay
    this.NewYearMonth = newYearMonth
    this.NewOffTimeType = newOffTimeType
    this.NewIsMarketEnabled = newIsMarketEnabled
    this.NewIsTradingEnabled = newIsTradingEnabled
    this.NewStartTime = newStartTime
    this.NewEndTime = newEndTime
    this.NewComment = newComment
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradingCalendarOffTimeRuleModifyRequest}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} other Other struct
   * @returns {!TradingCalendarOffTimeRuleModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.RuleId != null) {
      this.RuleId = new UUID(other.RuleId)
    } else {
      this.RuleId = null
    }
    if (other.RuleName != null) {
      this.RuleName = other.RuleName
    } else {
      this.RuleName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewDay != null) {
      if (other.NewDay instanceof Date) {
        this.NewDay = new Date(other.NewDay.getTime())
      } else {
        this.NewDay = new Date(Math.round(other.NewDay / 1000000))
      }
    } else {
      this.NewDay = null
    }
    if (other.NewWeekDays != null) {
      this.NewWeekDays = DaysOfWeek.fromObject(other.NewWeekDays)
    } else {
      this.NewWeekDays = null
    }
    if (other.NewMonthDay != null) {
      this.NewMonthDay = other.NewMonthDay
    } else {
      this.NewMonthDay = null
    }
    if (other.NewYearMonth != null) {
      this.NewYearMonth = other.NewYearMonth
    } else {
      this.NewYearMonth = null
    }
    if (other.NewOffTimeType != null) {
      this.NewOffTimeType = OffTimeType.fromObject(other.NewOffTimeType)
    } else {
      this.NewOffTimeType = null
    }
    if (other.NewIsMarketEnabled != null) {
      this.NewIsMarketEnabled = other.NewIsMarketEnabled
    } else {
      this.NewIsMarketEnabled = null
    }
    if (other.NewIsTradingEnabled != null) {
      this.NewIsTradingEnabled = other.NewIsTradingEnabled
    } else {
      this.NewIsTradingEnabled = null
    }
    if (other.NewStartTime != null) {
      this.NewStartTime = UInt64.fromNumber(other.NewStartTime)
    } else {
      this.NewStartTime = null
    }
    if (other.NewEndTime != null) {
      this.NewEndTime = UInt64.fromNumber(other.NewEndTime)
    } else {
      this.NewEndTime = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradingCalendarOffTimeRuleModifyRequest}
   * @returns {!TradingCalendarOffTimeRuleModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradingCalendarOffTimeRuleModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradingCalendarOffTimeRuleModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradingCalendarOffTimeRuleModifyRequest}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingCalendarOffTimeRuleModifyRequest)) {
      throw new TypeError('Instance of TradingCalendarOffTimeRuleModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradingCalendarOffTimeRuleModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      RuleId: ((this.RuleId != null) ? this.RuleId.toString() : null),
      RuleName: ((this.RuleName != null) ? this.RuleName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewDay: ((this.NewDay != null) ? (this.NewDay.getTime() * 1000000) : null),
      NewWeekDays: ((this.NewWeekDays != null) ? this.NewWeekDays : null),
      NewMonthDay: ((this.NewMonthDay != null) ? this.NewMonthDay : null),
      NewYearMonth: ((this.NewYearMonth != null) ? this.NewYearMonth : null),
      NewOffTimeType: ((this.NewOffTimeType != null) ? this.NewOffTimeType : null),
      NewIsMarketEnabled: ((this.NewIsMarketEnabled != null) ? this.NewIsMarketEnabled : null),
      NewIsTradingEnabled: ((this.NewIsTradingEnabled != null) ? this.NewIsTradingEnabled : null),
      NewStartTime: ((this.NewStartTime != null) ? this.NewStartTime.toNumber() : null),
      NewEndTime: ((this.NewEndTime != null) ? this.NewEndTime.toNumber() : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradingCalendarOffTimeRuleModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradingCalendarOffTimeRuleModifyRequest} other Object value
   * @returns {!TradingCalendarOffTimeRuleModifyRequest} Created struct
   */
  static fromObject (other) {
    return new TradingCalendarOffTimeRuleModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradingCalendarOffTimeRuleModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6299
  }
}

export { TradingCalendarOffTimeRuleModifyRequest };

/**
 * Fast Binary Encoding TradingCalendarOffTimeRuleModifyRequest field model
 */
class FieldModelTradingCalendarOffTimeRuleModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.RuleId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.RuleName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.RuleId.fbeOffset + this.RuleId.fbeSize), buffer, this.RuleId.fbeOffset + this.RuleId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.RuleName.fbeOffset + this.RuleName.fbeSize), buffer, this.RuleName.fbeOffset + this.RuleName.fbeSize)
    this.NewDay = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewWeekDays = new fbe.FieldModelOptional(new FieldModelDaysOfWeek(buffer, this.NewDay.fbeOffset + this.NewDay.fbeSize), buffer, this.NewDay.fbeOffset + this.NewDay.fbeSize)
    this.NewMonthDay = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.NewWeekDays.fbeOffset + this.NewWeekDays.fbeSize), buffer, this.NewWeekDays.fbeOffset + this.NewWeekDays.fbeSize)
    this.NewYearMonth = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.NewMonthDay.fbeOffset + this.NewMonthDay.fbeSize), buffer, this.NewMonthDay.fbeOffset + this.NewMonthDay.fbeSize)
    this.NewOffTimeType = new fbe.FieldModelOptional(new FieldModelOffTimeType(buffer, this.NewYearMonth.fbeOffset + this.NewYearMonth.fbeSize), buffer, this.NewYearMonth.fbeOffset + this.NewYearMonth.fbeSize)
    this.NewIsMarketEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewOffTimeType.fbeOffset + this.NewOffTimeType.fbeSize), buffer, this.NewOffTimeType.fbeOffset + this.NewOffTimeType.fbeSize)
    this.NewIsTradingEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsMarketEnabled.fbeOffset + this.NewIsMarketEnabled.fbeSize), buffer, this.NewIsMarketEnabled.fbeOffset + this.NewIsMarketEnabled.fbeSize)
    this.NewStartTime = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewIsTradingEnabled.fbeOffset + this.NewIsTradingEnabled.fbeSize), buffer, this.NewIsTradingEnabled.fbeOffset + this.NewIsTradingEnabled.fbeSize)
    this.NewEndTime = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewStartTime.fbeOffset + this.NewStartTime.fbeSize), buffer, this.NewStartTime.fbeOffset + this.NewStartTime.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewEndTime.fbeOffset + this.NewEndTime.fbeSize), buffer, this.NewEndTime.fbeOffset + this.NewEndTime.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.RuleId.fbeSize + this.RuleName.fbeSize + this.NewName.fbeSize + this.NewDay.fbeSize + this.NewWeekDays.fbeSize + this.NewMonthDay.fbeSize + this.NewYearMonth.fbeSize + this.NewOffTimeType.fbeSize + this.NewIsMarketEnabled.fbeSize + this.NewIsTradingEnabled.fbeSize + this.NewStartTime.fbeSize + this.NewEndTime.fbeSize + this.NewComment.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.RuleId.fbeExtra + this.RuleName.fbeExtra + this.NewName.fbeExtra + this.NewDay.fbeExtra + this.NewWeekDays.fbeExtra + this.NewMonthDay.fbeExtra + this.NewYearMonth.fbeExtra + this.NewOffTimeType.fbeExtra + this.NewIsMarketEnabled.fbeExtra + this.NewIsTradingEnabled.fbeExtra + this.NewStartTime.fbeExtra + this.NewEndTime.fbeExtra + this.NewComment.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradingCalendarOffTimeRuleModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6299
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RuleId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RuleId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RuleId.fbeSize

    if ((fbeCurrentSize + this.RuleName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RuleName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RuleName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewDay.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDay.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDay.fbeSize

    if ((fbeCurrentSize + this.NewWeekDays.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewWeekDays.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewWeekDays.fbeSize

    if ((fbeCurrentSize + this.NewMonthDay.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMonthDay.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonthDay.fbeSize

    if ((fbeCurrentSize + this.NewYearMonth.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewYearMonth.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewYearMonth.fbeSize

    if ((fbeCurrentSize + this.NewOffTimeType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOffTimeType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOffTimeType.fbeSize

    if ((fbeCurrentSize + this.NewIsMarketEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsMarketEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsMarketEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsTradingEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsTradingEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradingEnabled.fbeSize

    if ((fbeCurrentSize + this.NewStartTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStartTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStartTime.fbeSize

    if ((fbeCurrentSize + this.NewEndTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewEndTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEndTime.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} fbeValue Default value, defaults is new TradingCalendarOffTimeRuleModifyRequest()
   * @returns {!TradingCalendarOffTimeRuleModifyRequest} TradingCalendarOffTimeRuleModifyRequest value
   */
  get (fbeValue = new TradingCalendarOffTimeRuleModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} fbeValue TradingCalendarOffTimeRuleModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RuleId.fbeSize) <= fbeStructSize) {
      fbeValue.RuleId = this.RuleId.get()
    } else {
      fbeValue.RuleId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RuleId.fbeSize

    if ((fbeCurrentSize + this.RuleName.fbeSize) <= fbeStructSize) {
      fbeValue.RuleName = this.RuleName.get()
    } else {
      fbeValue.RuleName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RuleName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewDay.fbeSize) <= fbeStructSize) {
      fbeValue.NewDay = this.NewDay.get()
    } else {
      fbeValue.NewDay = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDay.fbeSize

    if ((fbeCurrentSize + this.NewWeekDays.fbeSize) <= fbeStructSize) {
      fbeValue.NewWeekDays = this.NewWeekDays.get()
    } else {
      fbeValue.NewWeekDays = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewWeekDays.fbeSize

    if ((fbeCurrentSize + this.NewMonthDay.fbeSize) <= fbeStructSize) {
      fbeValue.NewMonthDay = this.NewMonthDay.get()
    } else {
      fbeValue.NewMonthDay = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonthDay.fbeSize

    if ((fbeCurrentSize + this.NewYearMonth.fbeSize) <= fbeStructSize) {
      fbeValue.NewYearMonth = this.NewYearMonth.get()
    } else {
      fbeValue.NewYearMonth = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewYearMonth.fbeSize

    if ((fbeCurrentSize + this.NewOffTimeType.fbeSize) <= fbeStructSize) {
      fbeValue.NewOffTimeType = this.NewOffTimeType.get()
    } else {
      fbeValue.NewOffTimeType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOffTimeType.fbeSize

    if ((fbeCurrentSize + this.NewIsMarketEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsMarketEnabled = this.NewIsMarketEnabled.get()
    } else {
      fbeValue.NewIsMarketEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsMarketEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsTradingEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsTradingEnabled = this.NewIsTradingEnabled.get()
    } else {
      fbeValue.NewIsTradingEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradingEnabled.fbeSize

    if ((fbeCurrentSize + this.NewStartTime.fbeSize) <= fbeStructSize) {
      fbeValue.NewStartTime = this.NewStartTime.get()
    } else {
      fbeValue.NewStartTime = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStartTime.fbeSize

    if ((fbeCurrentSize + this.NewEndTime.fbeSize) <= fbeStructSize) {
      fbeValue.NewEndTime = this.NewEndTime.get()
    } else {
      fbeValue.NewEndTime = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEndTime.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} fbeValue TradingCalendarOffTimeRuleModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradingCalendarOffTimeRuleModifyRequest}
   * @param {TradingCalendarOffTimeRuleModifyRequest} fbeValue TradingCalendarOffTimeRuleModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.RuleId.set(fbeValue.RuleId)
    this.RuleName.set(fbeValue.RuleName)
    this.NewName.set(fbeValue.NewName)
    this.NewDay.set(fbeValue.NewDay)
    this.NewWeekDays.set(fbeValue.NewWeekDays)
    this.NewMonthDay.set(fbeValue.NewMonthDay)
    this.NewYearMonth.set(fbeValue.NewYearMonth)
    this.NewOffTimeType.set(fbeValue.NewOffTimeType)
    this.NewIsMarketEnabled.set(fbeValue.NewIsMarketEnabled)
    this.NewIsTradingEnabled.set(fbeValue.NewIsTradingEnabled)
    this.NewStartTime.set(fbeValue.NewStartTime)
    this.NewEndTime.set(fbeValue.NewEndTime)
    this.NewComment.set(fbeValue.NewComment)
  }
}

export { FieldModelTradingCalendarOffTimeRuleModifyRequest };

/**
 * Fast Binary Encoding TradingCalendarOffTimeRuleModifyRequest model
 */
class TradingCalendarOffTimeRuleModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradingCalendarOffTimeRuleModifyRequest(this.buffer, 4)
  }

  /**
   * Get the TradingCalendarOffTimeRuleModifyRequest model
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @returns {!FieldModelTradingCalendarOffTimeRuleModifyRequest} model TradingCalendarOffTimeRuleModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradingCalendarOffTimeRuleModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradingCalendarOffTimeRuleModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} value TradingCalendarOffTimeRuleModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @param {!TradingCalendarOffTimeRuleModifyRequest} value TradingCalendarOffTimeRuleModifyRequest value, defaults is new TradingCalendarOffTimeRuleModifyRequest()
   * @return {!object} Deserialized TradingCalendarOffTimeRuleModifyRequest value and its size
   */
  deserialize (value = new TradingCalendarOffTimeRuleModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradingCalendarOffTimeRuleModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradingCalendarOffTimeRuleModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradingCalendarOffTimeRuleModifyRequestModel}
   * @param {!number} prev Previous TradingCalendarOffTimeRuleModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradingCalendarOffTimeRuleModifyRequestModel };
TradingCalendarOffTimeRuleModifyRequest.__has_response = true;
TradingCalendarOffTimeRuleModifyRequest.__response_class = TradingCalendarOffTimeRuleModifyResponse;
