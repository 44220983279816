
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {MarginRateProfileModifyResponse} from './MarginRateProfileModifyResponse';
import {MarginRateProfileStatus} from '../core/MarginRateProfileStatus';
import {FieldModelMarginRateProfileStatus} from '../core/MarginRateProfileStatus';
import {MarginRateProfilePositionMode} from '../core/MarginRateProfilePositionMode';
import {FieldModelMarginRateProfilePositionMode} from '../core/MarginRateProfilePositionMode';

/**
 * MarginRateProfileModifyRequest struct
 */
class MarginRateProfileModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} marginRateProfileId
   * @param {string=} marginRateProfileName
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {MarginRateProfileStatus=} newStatus
   * @param {MarginRateProfilePositionMode=} newPositionMode
   * @param {!Map=} newPercentage
   * @param {!boolean=} percentageReset
   * @param {!Set=} newAccountGroupId
   * @param {!boolean=} accountGroupIdReset
   * @param {number=} newSortOrder
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), marginRateProfileId = null, marginRateProfileName = null, newName = null, newComment = null, newDescription = null, newStatus = null, newPositionMode = null, newPercentage = new Map(), percentageReset = false, newAccountGroupId = new Set(), accountGroupIdReset = false, newSortOrder = null) {
    super()
    super.copy(parent)
    this.MarginRateProfileId = marginRateProfileId
    this.MarginRateProfileName = marginRateProfileName
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewStatus = newStatus
    this.NewPositionMode = newPositionMode
    this.NewPercentage = newPercentage
    this.PercentageReset = percentageReset
    this.NewAccountGroupId = newAccountGroupId
    this.AccountGroupIdReset = accountGroupIdReset
    this.NewSortOrder = newSortOrder
  }

  /**
   * Copy struct (shallow copy)
   * @this {!MarginRateProfileModifyRequest}
   * @param {!MarginRateProfileModifyRequest} other Other struct
   * @returns {!MarginRateProfileModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.MarginRateProfileId != null) {
      this.MarginRateProfileId = new UUID(other.MarginRateProfileId)
    } else {
      this.MarginRateProfileId = null
    }
    if (other.MarginRateProfileName != null) {
      this.MarginRateProfileName = other.MarginRateProfileName
    } else {
      this.MarginRateProfileName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewStatus != null) {
      this.NewStatus = MarginRateProfileStatus.fromObject(other.NewStatus)
    } else {
      this.NewStatus = null
    }
    if (other.NewPositionMode != null) {
      this.NewPositionMode = MarginRateProfilePositionMode.fromObject(other.NewPositionMode)
    } else {
      this.NewPositionMode = null
    }
    if (other.NewPercentage != null) {
      this.NewPercentage = new Map()
      Object.keys(other.NewPercentage).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = new UUID(key)
          if (other.NewPercentage[key] != null) {
            let tempValue
            tempValue = other.NewPercentage[key]
            this.NewPercentage.set(tempKey, tempValue)
          } else {
            this.NewPercentage.set(tempKey, null)
          }
        }
      })
    } else {
      this.NewPercentage = null
    }
    if (other.PercentageReset != null) {
      this.PercentageReset = other.PercentageReset
    } else {
      this.PercentageReset = null
    }
    if (other.NewAccountGroupId != null) {
      this.NewAccountGroupId = new Set()
      for (let item of other.NewAccountGroupId) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.NewAccountGroupId.add(tempItem)
        } else {
          this.NewAccountGroupId.add(null)
        }
      }
    } else {
      this.NewAccountGroupId = null
    }
    if (other.AccountGroupIdReset != null) {
      this.AccountGroupIdReset = other.AccountGroupIdReset
    } else {
      this.AccountGroupIdReset = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!MarginRateProfileModifyRequest}
   * @returns {!MarginRateProfileModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new MarginRateProfileModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new MarginRateProfileModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!MarginRateProfileModifyRequest}
   * @param {!MarginRateProfileModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof MarginRateProfileModifyRequest)) {
      throw new TypeError('Instance of MarginRateProfileModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!MarginRateProfileModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      MarginRateProfileId: ((this.MarginRateProfileId != null) ? this.MarginRateProfileId.toString() : null),
      MarginRateProfileName: ((this.MarginRateProfileName != null) ? this.MarginRateProfileName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewStatus: ((this.NewStatus != null) ? this.NewStatus : null),
      NewPositionMode: ((this.NewPositionMode != null) ? this.NewPositionMode : null),
      NewPercentage: ((this.NewPercentage != null) ? fbe.MapToObject(new Map(Array.from(this.NewPercentage, item => [((item[0] != null) ? item[0].toString() : null), ((item[1] != null) ? item[1] : null)]))) : null),
      PercentageReset: ((this.PercentageReset != null) ? this.PercentageReset : null),
      NewAccountGroupId: ((this.NewAccountGroupId != null) ? Array.from(this.NewAccountGroupId, item => ((item != null) ? item.toString() : null)) : null),
      AccountGroupIdReset: ((this.AccountGroupIdReset != null) ? this.AccountGroupIdReset : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return MarginRateProfileModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!MarginRateProfileModifyRequest} other Object value
   * @returns {!MarginRateProfileModifyRequest} Created struct
   */
  static fromObject (other) {
    return new MarginRateProfileModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!MarginRateProfileModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return MarginRateProfileModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!MarginRateProfileModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6390
  }
}

export { MarginRateProfileModifyRequest };

/**
 * Fast Binary Encoding MarginRateProfileModifyRequest field model
 */
class FieldModelMarginRateProfileModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.MarginRateProfileId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.MarginRateProfileName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MarginRateProfileId.fbeOffset + this.MarginRateProfileId.fbeSize), buffer, this.MarginRateProfileId.fbeOffset + this.MarginRateProfileId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MarginRateProfileName.fbeOffset + this.MarginRateProfileName.fbeSize), buffer, this.MarginRateProfileName.fbeOffset + this.MarginRateProfileName.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewStatus = new fbe.FieldModelOptional(new FieldModelMarginRateProfileStatus(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewPositionMode = new fbe.FieldModelOptional(new FieldModelMarginRateProfilePositionMode(buffer, this.NewStatus.fbeOffset + this.NewStatus.fbeSize), buffer, this.NewStatus.fbeOffset + this.NewStatus.fbeSize)
    this.NewPercentage = new fbe.FieldModelMap(new fbe.FieldModelUUID(buffer, this.NewPositionMode.fbeOffset + this.NewPositionMode.fbeSize), new fbe.FieldModelDouble(buffer, this.NewPositionMode.fbeOffset + this.NewPositionMode.fbeSize), buffer, this.NewPositionMode.fbeOffset + this.NewPositionMode.fbeSize)
    this.PercentageReset = new fbe.FieldModelBool(buffer, this.NewPercentage.fbeOffset + this.NewPercentage.fbeSize)
    this.NewAccountGroupId = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.PercentageReset.fbeOffset + this.PercentageReset.fbeSize), buffer, this.PercentageReset.fbeOffset + this.PercentageReset.fbeSize)
    this.AccountGroupIdReset = new fbe.FieldModelBool(buffer, this.NewAccountGroupId.fbeOffset + this.NewAccountGroupId.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.AccountGroupIdReset.fbeOffset + this.AccountGroupIdReset.fbeSize), buffer, this.AccountGroupIdReset.fbeOffset + this.AccountGroupIdReset.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.MarginRateProfileId.fbeSize + this.MarginRateProfileName.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewStatus.fbeSize + this.NewPositionMode.fbeSize + this.NewPercentage.fbeSize + this.PercentageReset.fbeSize + this.NewAccountGroupId.fbeSize + this.AccountGroupIdReset.fbeSize + this.NewSortOrder.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.MarginRateProfileId.fbeExtra + this.MarginRateProfileName.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewStatus.fbeExtra + this.NewPositionMode.fbeExtra + this.NewPercentage.fbeExtra + this.PercentageReset.fbeExtra + this.NewAccountGroupId.fbeExtra + this.AccountGroupIdReset.fbeExtra + this.NewSortOrder.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelMarginRateProfileModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6390
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.MarginRateProfileId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginRateProfileId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRateProfileId.fbeSize

    if ((fbeCurrentSize + this.MarginRateProfileName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginRateProfileName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRateProfileName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStatus.fbeSize

    if ((fbeCurrentSize + this.NewPositionMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPositionMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPositionMode.fbeSize

    if ((fbeCurrentSize + this.NewPercentage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPercentage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPercentage.fbeSize

    if ((fbeCurrentSize + this.PercentageReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PercentageReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PercentageReset.fbeSize

    if ((fbeCurrentSize + this.NewAccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupIdReset.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @param {!MarginRateProfileModifyRequest} fbeValue Default value, defaults is new MarginRateProfileModifyRequest()
   * @returns {!MarginRateProfileModifyRequest} MarginRateProfileModifyRequest value
   */
  get (fbeValue = new MarginRateProfileModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @param {!MarginRateProfileModifyRequest} fbeValue MarginRateProfileModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.MarginRateProfileId.fbeSize) <= fbeStructSize) {
      fbeValue.MarginRateProfileId = this.MarginRateProfileId.get()
    } else {
      fbeValue.MarginRateProfileId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRateProfileId.fbeSize

    if ((fbeCurrentSize + this.MarginRateProfileName.fbeSize) <= fbeStructSize) {
      fbeValue.MarginRateProfileName = this.MarginRateProfileName.get()
    } else {
      fbeValue.MarginRateProfileName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRateProfileName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewStatus.fbeSize) <= fbeStructSize) {
      fbeValue.NewStatus = this.NewStatus.get()
    } else {
      fbeValue.NewStatus = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStatus.fbeSize

    if ((fbeCurrentSize + this.NewPositionMode.fbeSize) <= fbeStructSize) {
      fbeValue.NewPositionMode = this.NewPositionMode.get()
    } else {
      fbeValue.NewPositionMode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPositionMode.fbeSize

    if ((fbeCurrentSize + this.NewPercentage.fbeSize) <= fbeStructSize) {
      this.NewPercentage.get(fbeValue.NewPercentage)
    } else {
      fbeValue.NewPercentage.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPercentage.fbeSize

    if ((fbeCurrentSize + this.PercentageReset.fbeSize) <= fbeStructSize) {
      fbeValue.PercentageReset = this.PercentageReset.get()
    } else {
      fbeValue.PercentageReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PercentageReset.fbeSize

    if ((fbeCurrentSize + this.NewAccountGroupId.fbeSize) <= fbeStructSize) {
      this.NewAccountGroupId.get(fbeValue.NewAccountGroupId)
    } else {
      fbeValue.NewAccountGroupId.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupIdReset = this.AccountGroupIdReset.get()
    } else {
      fbeValue.AccountGroupIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupIdReset.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @param {!MarginRateProfileModifyRequest} fbeValue MarginRateProfileModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelMarginRateProfileModifyRequest}
   * @param {MarginRateProfileModifyRequest} fbeValue MarginRateProfileModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.MarginRateProfileId.set(fbeValue.MarginRateProfileId)
    this.MarginRateProfileName.set(fbeValue.MarginRateProfileName)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewStatus.set(fbeValue.NewStatus)
    this.NewPositionMode.set(fbeValue.NewPositionMode)
    this.NewPercentage.set(fbeValue.NewPercentage)
    this.PercentageReset.set(fbeValue.PercentageReset)
    this.NewAccountGroupId.set(fbeValue.NewAccountGroupId)
    this.AccountGroupIdReset.set(fbeValue.AccountGroupIdReset)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
  }
}

export { FieldModelMarginRateProfileModifyRequest };

/**
 * Fast Binary Encoding MarginRateProfileModifyRequest model
 */
class MarginRateProfileModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelMarginRateProfileModifyRequest(this.buffer, 4)
  }

  /**
   * Get the MarginRateProfileModifyRequest model
   * @this {!MarginRateProfileModifyRequestModel}
   * @returns {!FieldModelMarginRateProfileModifyRequest} model MarginRateProfileModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!MarginRateProfileModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!MarginRateProfileModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return MarginRateProfileModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!MarginRateProfileModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelMarginRateProfileModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!MarginRateProfileModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!MarginRateProfileModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!MarginRateProfileModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!MarginRateProfileModifyRequestModel}
   * @param {!MarginRateProfileModifyRequest} value MarginRateProfileModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!MarginRateProfileModifyRequestModel}
   * @param {!MarginRateProfileModifyRequest} value MarginRateProfileModifyRequest value, defaults is new MarginRateProfileModifyRequest()
   * @return {!object} Deserialized MarginRateProfileModifyRequest value and its size
   */
  deserialize (value = new MarginRateProfileModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new MarginRateProfileModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new MarginRateProfileModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!MarginRateProfileModifyRequestModel}
   * @param {!number} prev Previous MarginRateProfileModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { MarginRateProfileModifyRequestModel };
MarginRateProfileModifyRequest.__has_response = true;
MarginRateProfileModifyRequest.__response_class = MarginRateProfileModifyResponse;
