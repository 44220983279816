
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {PushNotificationTemplateModifyResponse} from './PushNotificationTemplateModifyResponse';
import {NewNotificationTemplates} from './NewNotificationTemplates';
import {FieldModelNewNotificationTemplates} from './NewNotificationTemplates';

/**
 * PushNotificationTemplateModifyRequest struct
 */
class PushNotificationTemplateModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {!UUID=} pushNotificationTemplateId
   * @param {number=} newSortOrder
   * @param {NewNotificationTemplates=} newTemplates
   * @param {boolean=} newIsDisabled
   * @param {number=} newThrottleTimeInSeconds
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), pushNotificationTemplateId = new UUID(), newSortOrder = null, newTemplates = null, newIsDisabled = null, newThrottleTimeInSeconds = null) {
    super()
    super.copy(parent)
    this.PushNotificationTemplateId = pushNotificationTemplateId
    this.NewSortOrder = newSortOrder
    this.NewTemplates = newTemplates
    this.NewIsDisabled = newIsDisabled
    this.NewThrottleTimeInSeconds = newThrottleTimeInSeconds
  }

  /**
   * Copy struct (shallow copy)
   * @this {!PushNotificationTemplateModifyRequest}
   * @param {!PushNotificationTemplateModifyRequest} other Other struct
   * @returns {!PushNotificationTemplateModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.PushNotificationTemplateId != null) {
      this.PushNotificationTemplateId = new UUID(other.PushNotificationTemplateId)
    } else {
      this.PushNotificationTemplateId = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewTemplates != null) {
      this.NewTemplates = NewNotificationTemplates.fromObject(other.NewTemplates)
    } else {
      this.NewTemplates = null
    }
    if (other.NewIsDisabled != null) {
      this.NewIsDisabled = other.NewIsDisabled
    } else {
      this.NewIsDisabled = null
    }
    if (other.NewThrottleTimeInSeconds != null) {
      this.NewThrottleTimeInSeconds = other.NewThrottleTimeInSeconds
    } else {
      this.NewThrottleTimeInSeconds = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!PushNotificationTemplateModifyRequest}
   * @returns {!PushNotificationTemplateModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PushNotificationTemplateModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PushNotificationTemplateModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!PushNotificationTemplateModifyRequest}
   * @param {!PushNotificationTemplateModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof PushNotificationTemplateModifyRequest)) {
      throw new TypeError('Instance of PushNotificationTemplateModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!PushNotificationTemplateModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      PushNotificationTemplateId: ((this.PushNotificationTemplateId != null) ? this.PushNotificationTemplateId.toString() : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewTemplates: ((this.NewTemplates != null) ? this.NewTemplates : null),
      NewIsDisabled: ((this.NewIsDisabled != null) ? this.NewIsDisabled : null),
      NewThrottleTimeInSeconds: ((this.NewThrottleTimeInSeconds != null) ? this.NewThrottleTimeInSeconds : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return PushNotificationTemplateModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!PushNotificationTemplateModifyRequest} other Object value
   * @returns {!PushNotificationTemplateModifyRequest} Created struct
   */
  static fromObject (other) {
    return new PushNotificationTemplateModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!PushNotificationTemplateModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return PushNotificationTemplateModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!PushNotificationTemplateModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6588
  }
}

export { PushNotificationTemplateModifyRequest };

/**
 * Fast Binary Encoding PushNotificationTemplateModifyRequest field model
 */
class FieldModelPushNotificationTemplateModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.PushNotificationTemplateId = new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.PushNotificationTemplateId.fbeOffset + this.PushNotificationTemplateId.fbeSize), buffer, this.PushNotificationTemplateId.fbeOffset + this.PushNotificationTemplateId.fbeSize)
    this.NewTemplates = new fbe.FieldModelOptional(new FieldModelNewNotificationTemplates(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewIsDisabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewTemplates.fbeOffset + this.NewTemplates.fbeSize), buffer, this.NewTemplates.fbeOffset + this.NewTemplates.fbeSize)
    this.NewThrottleTimeInSeconds = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewIsDisabled.fbeOffset + this.NewIsDisabled.fbeSize), buffer, this.NewIsDisabled.fbeOffset + this.NewIsDisabled.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.PushNotificationTemplateId.fbeSize + this.NewSortOrder.fbeSize + this.NewTemplates.fbeSize + this.NewIsDisabled.fbeSize + this.NewThrottleTimeInSeconds.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.PushNotificationTemplateId.fbeExtra + this.NewSortOrder.fbeExtra + this.NewTemplates.fbeExtra + this.NewIsDisabled.fbeExtra + this.NewThrottleTimeInSeconds.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPushNotificationTemplateModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6588
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.PushNotificationTemplateId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PushNotificationTemplateId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PushNotificationTemplateId.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewTemplates.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTemplates.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTemplates.fbeSize

    if ((fbeCurrentSize + this.NewIsDisabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsDisabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsDisabled.fbeSize

    if ((fbeCurrentSize + this.NewThrottleTimeInSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewThrottleTimeInSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewThrottleTimeInSeconds.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @param {!PushNotificationTemplateModifyRequest} fbeValue Default value, defaults is new PushNotificationTemplateModifyRequest()
   * @returns {!PushNotificationTemplateModifyRequest} PushNotificationTemplateModifyRequest value
   */
  get (fbeValue = new PushNotificationTemplateModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @param {!PushNotificationTemplateModifyRequest} fbeValue PushNotificationTemplateModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.PushNotificationTemplateId.fbeSize) <= fbeStructSize) {
      fbeValue.PushNotificationTemplateId = this.PushNotificationTemplateId.get()
    } else {
      fbeValue.PushNotificationTemplateId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PushNotificationTemplateId.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewTemplates.fbeSize) <= fbeStructSize) {
      fbeValue.NewTemplates = this.NewTemplates.get()
    } else {
      fbeValue.NewTemplates = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTemplates.fbeSize

    if ((fbeCurrentSize + this.NewIsDisabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsDisabled = this.NewIsDisabled.get()
    } else {
      fbeValue.NewIsDisabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsDisabled.fbeSize

    if ((fbeCurrentSize + this.NewThrottleTimeInSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.NewThrottleTimeInSeconds = this.NewThrottleTimeInSeconds.get()
    } else {
      fbeValue.NewThrottleTimeInSeconds = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewThrottleTimeInSeconds.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @param {!PushNotificationTemplateModifyRequest} fbeValue PushNotificationTemplateModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPushNotificationTemplateModifyRequest}
   * @param {PushNotificationTemplateModifyRequest} fbeValue PushNotificationTemplateModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.PushNotificationTemplateId.set(fbeValue.PushNotificationTemplateId)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewTemplates.set(fbeValue.NewTemplates)
    this.NewIsDisabled.set(fbeValue.NewIsDisabled)
    this.NewThrottleTimeInSeconds.set(fbeValue.NewThrottleTimeInSeconds)
  }
}

export { FieldModelPushNotificationTemplateModifyRequest };

/**
 * Fast Binary Encoding PushNotificationTemplateModifyRequest model
 */
class PushNotificationTemplateModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPushNotificationTemplateModifyRequest(this.buffer, 4)
  }

  /**
   * Get the PushNotificationTemplateModifyRequest model
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @returns {!FieldModelPushNotificationTemplateModifyRequest} model PushNotificationTemplateModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PushNotificationTemplateModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPushNotificationTemplateModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @param {!PushNotificationTemplateModifyRequest} value PushNotificationTemplateModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @param {!PushNotificationTemplateModifyRequest} value PushNotificationTemplateModifyRequest value, defaults is new PushNotificationTemplateModifyRequest()
   * @return {!object} Deserialized PushNotificationTemplateModifyRequest value and its size
   */
  deserialize (value = new PushNotificationTemplateModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new PushNotificationTemplateModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new PushNotificationTemplateModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PushNotificationTemplateModifyRequestModel}
   * @param {!number} prev Previous PushNotificationTemplateModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PushNotificationTemplateModifyRequestModel };
PushNotificationTemplateModifyRequest.__has_response = true;
PushNotificationTemplateModifyRequest.__response_class = PushNotificationTemplateModifyResponse;
