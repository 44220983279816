
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {LanguageModifyResponse} from './LanguageModifyResponse';

/**
 * LanguageModifyRequest struct
 */
class LanguageModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} languageId
   * @param {string=} languageName
   * @param {number=} newSortOrder
   * @param {string=} newDescription
   * @param {boolean=} newIsEnabled
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), languageId = null, languageName = null, newSortOrder = null, newDescription = null, newIsEnabled = null) {
    super()
    super.copy(parent)
    this.LanguageId = languageId
    this.LanguageName = languageName
    this.NewSortOrder = newSortOrder
    this.NewDescription = newDescription
    this.NewIsEnabled = newIsEnabled
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LanguageModifyRequest}
   * @param {!LanguageModifyRequest} other Other struct
   * @returns {!LanguageModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.LanguageId != null) {
      this.LanguageId = new UUID(other.LanguageId)
    } else {
      this.LanguageId = null
    }
    if (other.LanguageName != null) {
      this.LanguageName = other.LanguageName
    } else {
      this.LanguageName = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LanguageModifyRequest}
   * @returns {!LanguageModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LanguageModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LanguageModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LanguageModifyRequest}
   * @param {!LanguageModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LanguageModifyRequest)) {
      throw new TypeError('Instance of LanguageModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LanguageModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      LanguageId: ((this.LanguageId != null) ? this.LanguageId.toString() : null),
      LanguageName: ((this.LanguageName != null) ? this.LanguageName : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LanguageModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LanguageModifyRequest} other Object value
   * @returns {!LanguageModifyRequest} Created struct
   */
  static fromObject (other) {
    return new LanguageModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LanguageModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LanguageModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LanguageModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6573
  }
}

export { LanguageModifyRequest };

/**
 * Fast Binary Encoding LanguageModifyRequest field model
 */
class FieldModelLanguageModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.LanguageId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.LanguageName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.LanguageId.fbeOffset + this.LanguageId.fbeSize), buffer, this.LanguageId.fbeOffset + this.LanguageId.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.LanguageName.fbeOffset + this.LanguageName.fbeSize), buffer, this.LanguageName.fbeOffset + this.LanguageName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelLanguageModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelLanguageModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLanguageModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.LanguageId.fbeSize + this.LanguageName.fbeSize + this.NewSortOrder.fbeSize + this.NewDescription.fbeSize + this.NewIsEnabled.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLanguageModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.LanguageId.fbeExtra + this.LanguageName.fbeExtra + this.NewSortOrder.fbeExtra + this.NewDescription.fbeExtra + this.NewIsEnabled.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLanguageModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLanguageModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLanguageModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6573
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLanguageModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLanguageModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.LanguageId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LanguageId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LanguageId.fbeSize

    if ((fbeCurrentSize + this.LanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LanguageName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLanguageModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLanguageModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLanguageModifyRequest}
   * @param {!LanguageModifyRequest} fbeValue Default value, defaults is new LanguageModifyRequest()
   * @returns {!LanguageModifyRequest} LanguageModifyRequest value
   */
  get (fbeValue = new LanguageModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLanguageModifyRequest}
   * @param {!LanguageModifyRequest} fbeValue LanguageModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.LanguageId.fbeSize) <= fbeStructSize) {
      fbeValue.LanguageId = this.LanguageId.get()
    } else {
      fbeValue.LanguageId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LanguageId.fbeSize

    if ((fbeCurrentSize + this.LanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.LanguageName = this.LanguageName.get()
    } else {
      fbeValue.LanguageName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LanguageName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLanguageModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLanguageModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLanguageModifyRequest}
   * @param {!LanguageModifyRequest} fbeValue LanguageModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLanguageModifyRequest}
   * @param {LanguageModifyRequest} fbeValue LanguageModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.LanguageId.set(fbeValue.LanguageId)
    this.LanguageName.set(fbeValue.LanguageName)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
  }
}

export { FieldModelLanguageModifyRequest };

/**
 * Fast Binary Encoding LanguageModifyRequest model
 */
class LanguageModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLanguageModifyRequest(this.buffer, 4)
  }

  /**
   * Get the LanguageModifyRequest model
   * @this {!LanguageModifyRequestModel}
   * @returns {!FieldModelLanguageModifyRequest} model LanguageModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LanguageModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LanguageModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LanguageModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LanguageModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLanguageModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LanguageModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LanguageModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LanguageModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LanguageModifyRequestModel}
   * @param {!LanguageModifyRequest} value LanguageModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LanguageModifyRequestModel}
   * @param {!LanguageModifyRequest} value LanguageModifyRequest value, defaults is new LanguageModifyRequest()
   * @return {!object} Deserialized LanguageModifyRequest value and its size
   */
  deserialize (value = new LanguageModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LanguageModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LanguageModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LanguageModifyRequestModel}
   * @param {!number} prev Previous LanguageModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LanguageModifyRequestModel };
LanguageModifyRequest.__has_response = true;
LanguageModifyRequest.__response_class = LanguageModifyResponse;
