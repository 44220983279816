import React, { FC } from 'react';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import icoUsers from '@assets/icons/ico-users.svg';
import icoTools from '@assets/icons/ico-tools.svg';
import icoBrain from '@assets/icons/ico-brain.svg';
import icoLaptop from '@assets/icons/ico-laptop-code.svg';
import icoNetworkWired from '@assets/icons/ico-network-wired.svg';
import icoEuroSign from '@assets/icons/ico-euro-sign.svg';
import icoCoins from '@assets/icons/ico-coins.svg';
import icoUserTie from '@assets/icons/ico-user-tie.svg';
import icoCalendarAlt from '@assets/icons/ico-calendar-alt.svg';
import icoIdCard from '@assets/icons/ico-id-card.svg';
import icoArchive from '@assets/icons/ico-archive.svg';
import icoCodeMerge from '@assets/icons/ico-code-merge.svg';
import icoDungeon from '@assets/icons/ico-dungeon.svg';
import icoHistory from '@assets/icons/ico-history.svg';
import icoChessClock from '@assets/icons/ico-chess-clock.svg';
import icoShield from '@assets/icons/ico-shield.svg';
import icoFileChart from '@assets/icons/ico-file-chart.svg';
import icoBinoculars from '@assets/icons/ico-binoculars.svg';
import icoPuzzle from '@assets/icons/ico-puzzle.svg';
import icoGears from '@assets/icons/ico-gears.svg';
import icoStocks from '@assets/icons/ico-stocks.svg';

const MenuIcon: FC<{ src: string; title?: string; className?: string }> = ({ src, title, className }) => (
    <ReactSVG title={title ?? 'Menu Icon'} src={src} className={cn('menuIcon', className)} />
);

export const IconUsers: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Users' src={icoUsers} className={className} />;
export const IconTools: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Tools' src={icoTools} className={className} />;
export const IconBrain: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Brain' src={icoBrain} className={className} />;
export const IconLaptop: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Laptop' src={icoLaptop} className={className} />;
export const IconNetworkWired: FC<{ className?: string }> = ({ className }) => <MenuIcon title='NetworkWired' src={icoNetworkWired} className={className} />;
export const IconEuroSign: FC<{ className?: string }> = ({ className }) => <MenuIcon title='EuroSign' src={icoEuroSign} className={className} />;
export const IconCoins: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Coins' src={icoCoins} className={className} />;
export const IconUserTie: FC<{ className?: string }> = ({ className }) => <MenuIcon title='UserTie' src={icoUserTie} className={className} />;
export const IconCalendarAlt: FC<{ className?: string }> = ({ className }) => <MenuIcon title='CalendarAlt' src={icoCalendarAlt} className={className} />;
export const IconIdCard: FC<{ className?: string }> = ({ className }) => <MenuIcon title='IdCard' src={icoIdCard} className={className} />;
export const IconArchive: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Archive' src={icoArchive} className={className} />;
export const IconCodeMerge: FC<{ className?: string }> = ({ className }) => <MenuIcon title='CodeMerge' src={icoCodeMerge} className={className} />;
export const IconDungeon: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Dungeon' src={icoDungeon} className={className} />;
export const IconHistory: FC<{ className?: string }> = ({ className }) => <MenuIcon title='History' src={icoHistory} className={className} />;
export const IconChessClock: FC<{ className?: string }> = ({ className }) => <MenuIcon title='ChessClock' src={icoChessClock} className={className} />;
export const IconShield: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Shield' src={icoShield} className={className} />;
export const IconFileChart: FC<{ className?: string }> = ({ className }) => <MenuIcon title='FileChart' src={icoFileChart} className={className} />;
export const IconBinoculars: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Binoculars' src={icoBinoculars} className={className} />;
export const IconPuzzle: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Puzzle' src={icoPuzzle} className={className} />;
export const IconGears: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Gears' src={icoGears} className={className} />;
export const IconStocks: FC<{ className?: string }> = ({ className }) => <MenuIcon title='Stocks' src={icoStocks} className={className} />;
