
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {CommissionProfileModifyResponse} from './CommissionProfileModifyResponse';
import {CommissionMode} from '../core/CommissionMode';
import {FieldModelCommissionMode} from '../core/CommissionMode';
import {CommissionChargingType} from '../core/CommissionChargingType';
import {FieldModelCommissionChargingType} from '../core/CommissionChargingType';
import {CommissionValue} from '../core/CommissionValue';
import {FieldModelCommissionValue} from '../core/CommissionValue';
import {CommissionCalculationType} from '../core/CommissionCalculationType';
import {FieldModelCommissionCalculationType} from '../core/CommissionCalculationType';
import {CommissionType} from '../core/CommissionType';
import {FieldModelCommissionType} from '../core/CommissionType';

/**
 * CommissionProfileModifyRequest struct
 */
class CommissionProfileModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} commissionProfileId
   * @param {string=} commissionProfileName
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {CommissionMode=} newMode
   * @param {CommissionChargingType=} newChargingType
   * @param {!Array=} newValues
   * @param {CommissionCalculationType=} newCalculationType
   * @param {CommissionType=} newCommissionType
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), commissionProfileId = null, commissionProfileName = null, newName = null, newComment = null, newDescription = null, newMode = null, newChargingType = null, newValues = [], newCalculationType = null, newCommissionType = null) {
    super()
    super.copy(parent)
    this.CommissionProfileId = commissionProfileId
    this.CommissionProfileName = commissionProfileName
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewMode = newMode
    this.NewChargingType = newChargingType
    this.NewValues = newValues
    this.NewCalculationType = newCalculationType
    this.NewCommissionType = newCommissionType
  }

  /**
   * Copy struct (shallow copy)
   * @this {!CommissionProfileModifyRequest}
   * @param {!CommissionProfileModifyRequest} other Other struct
   * @returns {!CommissionProfileModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.CommissionProfileId != null) {
      this.CommissionProfileId = new UUID(other.CommissionProfileId)
    } else {
      this.CommissionProfileId = null
    }
    if (other.CommissionProfileName != null) {
      this.CommissionProfileName = other.CommissionProfileName
    } else {
      this.CommissionProfileName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewMode != null) {
      this.NewMode = CommissionMode.fromObject(other.NewMode)
    } else {
      this.NewMode = null
    }
    if (other.NewChargingType != null) {
      this.NewChargingType = CommissionChargingType.fromObject(other.NewChargingType)
    } else {
      this.NewChargingType = null
    }
    if (other.NewValues != null) {
      this.NewValues = []
      for (let item of other.NewValues) {
        if (item != null) {
          let tempItem
          tempItem = CommissionValue.fromObject(item)
          this.NewValues.push(tempItem)
        } else {
          this.NewValues.push(null)
        }
      }
    } else {
      this.NewValues = null
    }
    if (other.NewCalculationType != null) {
      this.NewCalculationType = CommissionCalculationType.fromObject(other.NewCalculationType)
    } else {
      this.NewCalculationType = null
    }
    if (other.NewCommissionType != null) {
      this.NewCommissionType = CommissionType.fromObject(other.NewCommissionType)
    } else {
      this.NewCommissionType = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!CommissionProfileModifyRequest}
   * @returns {!CommissionProfileModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new CommissionProfileModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new CommissionProfileModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!CommissionProfileModifyRequest}
   * @param {!CommissionProfileModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof CommissionProfileModifyRequest)) {
      throw new TypeError('Instance of CommissionProfileModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!CommissionProfileModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      CommissionProfileId: ((this.CommissionProfileId != null) ? this.CommissionProfileId.toString() : null),
      CommissionProfileName: ((this.CommissionProfileName != null) ? this.CommissionProfileName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewMode: ((this.NewMode != null) ? this.NewMode : null),
      NewChargingType: ((this.NewChargingType != null) ? this.NewChargingType : null),
      NewValues: ((this.NewValues != null) ? Array.from(this.NewValues, item => ((item != null) ? item : null)) : null),
      NewCalculationType: ((this.NewCalculationType != null) ? this.NewCalculationType : null),
      NewCommissionType: ((this.NewCommissionType != null) ? this.NewCommissionType : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return CommissionProfileModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!CommissionProfileModifyRequest} other Object value
   * @returns {!CommissionProfileModifyRequest} Created struct
   */
  static fromObject (other) {
    return new CommissionProfileModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!CommissionProfileModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return CommissionProfileModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!CommissionProfileModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6128
  }
}

export { CommissionProfileModifyRequest };

/**
 * Fast Binary Encoding CommissionProfileModifyRequest field model
 */
class FieldModelCommissionProfileModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.CommissionProfileId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.CommissionProfileName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CommissionProfileId.fbeOffset + this.CommissionProfileId.fbeSize), buffer, this.CommissionProfileId.fbeOffset + this.CommissionProfileId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CommissionProfileName.fbeOffset + this.CommissionProfileName.fbeSize), buffer, this.CommissionProfileName.fbeOffset + this.CommissionProfileName.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewMode = new fbe.FieldModelOptional(new FieldModelCommissionMode(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewChargingType = new fbe.FieldModelOptional(new FieldModelCommissionChargingType(buffer, this.NewMode.fbeOffset + this.NewMode.fbeSize), buffer, this.NewMode.fbeOffset + this.NewMode.fbeSize)
    this.NewValues = new fbe.FieldModelVector(new FieldModelCommissionValue(buffer, this.NewChargingType.fbeOffset + this.NewChargingType.fbeSize), buffer, this.NewChargingType.fbeOffset + this.NewChargingType.fbeSize)
    this.NewCalculationType = new fbe.FieldModelOptional(new FieldModelCommissionCalculationType(buffer, this.NewValues.fbeOffset + this.NewValues.fbeSize), buffer, this.NewValues.fbeOffset + this.NewValues.fbeSize)
    this.NewCommissionType = new fbe.FieldModelOptional(new FieldModelCommissionType(buffer, this.NewCalculationType.fbeOffset + this.NewCalculationType.fbeSize), buffer, this.NewCalculationType.fbeOffset + this.NewCalculationType.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.CommissionProfileId.fbeSize + this.CommissionProfileName.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewMode.fbeSize + this.NewChargingType.fbeSize + this.NewValues.fbeSize + this.NewCalculationType.fbeSize + this.NewCommissionType.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.CommissionProfileId.fbeExtra + this.CommissionProfileName.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewMode.fbeExtra + this.NewChargingType.fbeExtra + this.NewValues.fbeExtra + this.NewCalculationType.fbeExtra + this.NewCommissionType.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelCommissionProfileModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6128
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.CommissionProfileId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionProfileId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionProfileId.fbeSize

    if ((fbeCurrentSize + this.CommissionProfileName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionProfileName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionProfileName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMode.fbeSize

    if ((fbeCurrentSize + this.NewChargingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewChargingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewChargingType.fbeSize

    if ((fbeCurrentSize + this.NewValues.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewValues.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewValues.fbeSize

    if ((fbeCurrentSize + this.NewCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCalculationType.fbeSize

    if ((fbeCurrentSize + this.NewCommissionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCommissionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCommissionType.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @param {!CommissionProfileModifyRequest} fbeValue Default value, defaults is new CommissionProfileModifyRequest()
   * @returns {!CommissionProfileModifyRequest} CommissionProfileModifyRequest value
   */
  get (fbeValue = new CommissionProfileModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @param {!CommissionProfileModifyRequest} fbeValue CommissionProfileModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.CommissionProfileId.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionProfileId = this.CommissionProfileId.get()
    } else {
      fbeValue.CommissionProfileId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionProfileId.fbeSize

    if ((fbeCurrentSize + this.CommissionProfileName.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionProfileName = this.CommissionProfileName.get()
    } else {
      fbeValue.CommissionProfileName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionProfileName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewMode.fbeSize) <= fbeStructSize) {
      fbeValue.NewMode = this.NewMode.get()
    } else {
      fbeValue.NewMode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMode.fbeSize

    if ((fbeCurrentSize + this.NewChargingType.fbeSize) <= fbeStructSize) {
      fbeValue.NewChargingType = this.NewChargingType.get()
    } else {
      fbeValue.NewChargingType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewChargingType.fbeSize

    if ((fbeCurrentSize + this.NewValues.fbeSize) <= fbeStructSize) {
      this.NewValues.get(fbeValue.NewValues)
    } else {
      fbeValue.NewValues.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewValues.fbeSize

    if ((fbeCurrentSize + this.NewCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.NewCalculationType = this.NewCalculationType.get()
    } else {
      fbeValue.NewCalculationType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCalculationType.fbeSize

    if ((fbeCurrentSize + this.NewCommissionType.fbeSize) <= fbeStructSize) {
      fbeValue.NewCommissionType = this.NewCommissionType.get()
    } else {
      fbeValue.NewCommissionType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCommissionType.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @param {!CommissionProfileModifyRequest} fbeValue CommissionProfileModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelCommissionProfileModifyRequest}
   * @param {CommissionProfileModifyRequest} fbeValue CommissionProfileModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.CommissionProfileId.set(fbeValue.CommissionProfileId)
    this.CommissionProfileName.set(fbeValue.CommissionProfileName)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewMode.set(fbeValue.NewMode)
    this.NewChargingType.set(fbeValue.NewChargingType)
    this.NewValues.set(fbeValue.NewValues)
    this.NewCalculationType.set(fbeValue.NewCalculationType)
    this.NewCommissionType.set(fbeValue.NewCommissionType)
  }
}

export { FieldModelCommissionProfileModifyRequest };

/**
 * Fast Binary Encoding CommissionProfileModifyRequest model
 */
class CommissionProfileModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelCommissionProfileModifyRequest(this.buffer, 4)
  }

  /**
   * Get the CommissionProfileModifyRequest model
   * @this {!CommissionProfileModifyRequestModel}
   * @returns {!FieldModelCommissionProfileModifyRequest} model CommissionProfileModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!CommissionProfileModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!CommissionProfileModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return CommissionProfileModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!CommissionProfileModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelCommissionProfileModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!CommissionProfileModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!CommissionProfileModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!CommissionProfileModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!CommissionProfileModifyRequestModel}
   * @param {!CommissionProfileModifyRequest} value CommissionProfileModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!CommissionProfileModifyRequestModel}
   * @param {!CommissionProfileModifyRequest} value CommissionProfileModifyRequest value, defaults is new CommissionProfileModifyRequest()
   * @return {!object} Deserialized CommissionProfileModifyRequest value and its size
   */
  deserialize (value = new CommissionProfileModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new CommissionProfileModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new CommissionProfileModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!CommissionProfileModifyRequestModel}
   * @param {!number} prev Previous CommissionProfileModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { CommissionProfileModifyRequestModel };
CommissionProfileModifyRequest.__has_response = true;
CommissionProfileModifyRequest.__response_class = CommissionProfileModifyResponse;
