import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import arrayMove from 'array-move';
import { Button, Modal, Table, TablePaginationConfig } from 'antd';
import { CustomColumnType, FilterableTableProps, FilterValuesContextT, FilterValuesT } from '../types';
import { IFilterContext } from '../RowFilters/types';
import { SizeType } from '@models';
import { renderColumns, stringifyDataIndex } from '../utils';
import { TableHeader } from '../TableHeader';
import { FilterValuesContext } from '../TableExt';
import { filterAll } from '../RowFilters/utils';
import { useSortableTableRows } from '../SortableTableRow';
import styles from '../TableExt.module.scss';
import cn from 'classnames';
import { Message, MessageType } from '../../Message/Message';
import { keyMap, t } from '@localization';
import { SorterResult } from 'antd/lib/table/interface';
import { AlignType } from 'rc-table/lib/interface';
import { useLocalStorage } from '@utils/hooks';
import { LS_COLS_ORDER_PREFIX } from '@utils';
import { ExclamationCircleOutlined, CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ExportCSV from '../ExportCSV';
import { getSummaryRow } from '../SummaryRow/SummaryRow';

function createFilterContext<ContextT>() {
    return React.createContext<IFilterContext<ContextT>>(null!);
}

export function TableExtInner<ContextDataT extends {}>(props: FilterableTableProps<ContextDataT>) {
    const [tableSizeLS, setTableSizeLS] = useLocalStorage('TableSize', 'small');
    const Context = useMemo<React.Context<IFilterContext<ContextDataT>>>(() => createFilterContext<ContextDataT>(), []);
    const filterValues = useContext<FilterValuesContextT>(FilterValuesContext);
    const {
        columns,
        dragIcon,
        title,
        dataSource,
        draggable,
        focusedRowEntityId,
        onRefreshClick,
        dragConfirmationText,
        onRowMove,
        components,
        headerToolbar,
        exportCSV,
        rowKey = 'key',
        colsSettings,
        header,
        pagination,
        scroll,
        actionColumn,
        style,
        summaryItems,
        ...restProps
    } = props;
    const [localData, setLocalData] = useState<ContextDataT[]>(dataSource as ContextDataT[]);
    const [internalDataSource, setInternalDataSource] = useState<ContextDataT[] | null>(null);

    const isFiltered = useMemo<boolean>(
        () => !!Object.values(filterValues.values).filter((el) => !!el).length && !!localData.length,
        [localData, filterValues.values],
    );
    const [isSorted, setIsSorted] = useState<boolean>(false);
    const isModified = useMemo(() => isSorted || isFiltered, [isFiltered, isSorted]);
    const renderedColumns = useMemo(
        () => renderColumns<ContextDataT>(columns, !!draggable, isModified, Context, dragIcon),
        [Context, columns, dragIcon, draggable, isModified],
    );
    const [LSColsOrder, setLSColsOrder] = useLocalStorage(
        `${colsSettings?.key || title}${LS_COLS_ORDER_PREFIX}`,
        renderedColumns.map((col) => stringifyDataIndex(col.dataIndex)),
    );
    const sortColumns = useCallback(
        (cols) => {
            const mappedCols = LSColsOrder.map((lsColKey) =>
                cols.find((col) => stringifyDataIndex(col.dataIndex) === lsColKey),
            ).filter((el) => !!el);
            if (LSColsOrder.length !== cols.length || LSColsOrder.length !== mappedCols.length) {
                setLSColsOrder(cols.map((col) => stringifyDataIndex(col.dataIndex)));
                return renderedColumns;
            }
            return mappedCols;
        },
        [LSColsOrder, renderedColumns, setLSColsOrder],
    );
    const [filteredValues, setFilteredValues] = useState<FilterValuesT>(null!);

    const [tableColumns, setTableColumns] = useState<CustomColumnType<ContextDataT>[]>(() =>
        sortColumns(renderedColumns),
    );
    const [visibleColKeys, setVisibleColKeys] = useState<string[]>(
        columns.map((col) => stringifyDataIndex(col.dataIndex)),
    );

    const sortDrag = useSortableTableRows<ContextDataT>({
        onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            const doRowMove = () => {
                if (oldIndex !== newIndex) {
                    setLocalData(arrayMove([...localData], oldIndex, newIndex).filter((el) => !!el));
                    if (onRowMove) onRowMove(oldIndex, newIndex, isFiltered);
                }
            };
            if (dragConfirmationText) {
                Modal.confirm({
                    title: <span>{dragConfirmationText}</span>,
                    icon: <ExclamationCircleOutlined />,
                    okText: t(keyMap.common.confirm),
                    cancelText: t(keyMap.common.cancel),
                    onOk: doRowMove,
                });
            } else {
                doRowMove();
            }
        },
        findItemIndex: (dataRowKey) => localData.findIndex((x: ContextDataT) => x[rowKey] === dataRowKey),
        sortableContainer: {
            disableAutoscroll: true,
        },
        sortableItemClass: (row) => {
            if (!rowKey && !row[rowKey]) {
                return '';
            }
            return row[rowKey] === focusedRowEntityId ? styles.rowFocused : '';
        },
    });
    const renderRowBody = useCallback(
        ({ className, trStyle, ...restRowProps }) => (
            <tr
                className={`${className} ${
                    restRowProps['data-row-key'] === focusedRowEntityId ? styles.rowFocused : ''
                }`}
                style={trStyle}
                {...restRowProps}
            />
        ),
        [focusedRowEntityId],
    );

    useEffect(() => {
        setLocalData(filterAll(dataSource, filterValues.values, columns));
    }, [filterValues.values, dataSource, columns]);

    useEffect(() => {
        setTableColumns(sortColumns(renderColumns<ContextDataT>(columns, !!draggable, isModified, Context, dragIcon)));
    }, [Context, columns, dragIcon, draggable, isModified, sortColumns]);

    const getVisibleCols = useCallback(
        (cols: CustomColumnType<ContextDataT>[]): CustomColumnType<ContextDataT>[] => {
            const filteredCols = cols.filter((col) => visibleColKeys.includes(stringifyDataIndex(col.dataIndex)));
            const actionColumns =
                actionColumn && filteredCols.length
                    ? [
                          {
                              align: 'center' as AlignType,
                              title: actionColumn.title,
                              dataIndex: actionColumn.dataIndex,
                              width: actionColumn.width,
                              fixed: !actionColumn.custom?.length ? actionColumn.fixed : undefined,
                              render: (value, parent) => (
                                  <div className={styles.iconRow}>
                                      {actionColumn!.onEdit && (
                                          <Button type='link' onClick={() => actionColumn!.onEdit!(value, parent)}>
                                              <span className={cn(styles.icon, styles.blueIcon)}>
                                                  <EditOutlined />
                                              </span>
                                          </Button>
                                      )}
                                      {actionColumn!.copyColIndex && (
                                          <Button
                                              type='link'
                                              onClick={() => {
                                                  navigator.clipboard.writeText(
                                                      parent[actionColumn!.copyColIndex! as string] ?? '',
                                                  );
                                                  Message(MessageType.success, t(keyMap.messages.copiedToClipboard));
                                              }}
                                          >
                                              <span className={cn(styles.icon, styles.blueIcon)}>
                                                  <CopyOutlined />
                                              </span>
                                          </Button>
                                      )}
                                      {actionColumn!.onDelete && (
                                          <Button type='link' onClick={() => actionColumn!.onDelete!(value, parent)}>
                                              <span className={cn(styles.icon, styles.redIcon)}>
                                                  <DeleteOutlined />
                                              </span>
                                          </Button>
                                      )}
                                      {actionColumn!.custom &&
                                          actionColumn!.custom.map((action) => (
                                              <Button
                                                  type='link'
                                                  onClick={(e) => {
                                                      action.onClick(value, parent);
                                                      e.stopPropagation();
                                                  }}
                                              >
                                                  {action.icon}
                                              </Button>
                                          ))}
                                  </div>
                              ),
                          },
                      ]
                    : [];
            return [...filteredCols, ...actionColumns];
        },
        [actionColumn, visibleColKeys],
    );

    const tableHeader = () => {
        let toolbarElements: JSX.Element[] = [];

        if (headerToolbar) {
            toolbarElements = headerToolbar();
        }
        if (exportCSV) {
            const getTableDataToExport = () => {
                const filteredInternalDataSource = (internalDataSource ?? localData).filter(
                    (record) => !!localData.find((item) => item[rowKey] === record[rowKey]),
                );
                const orderedVisibleColKeys = tableColumns
                    .filter((item) => visibleColKeys.includes(stringifyDataIndex(item.dataIndex)))
                    .map((item) => stringifyDataIndex(item.dataIndex));

                const colsToRender = getVisibleCols(tableColumns);
                const getTableDataRowToExport = (
                    colsKeyTitleToExport: CustomColumnType<ContextDataT>[],
                    record: ContextDataT,
                ) =>
                    colsKeyTitleToExport.reduce((acc, { dataIndex, title: colTitle }) => {
                        const key = stringifyDataIndex(dataIndex);
                        const cellRender =
                            colsToRender.find((col) => col.dataIndex === key)?.render ||
                            // @ts-ignore
                            colsToRender.find((col) => col.dataIndex === key)?.children?.[0]?.render;

                        const cellValue = cellRender ? cellRender(record[key], record) : record[key];

                        return {
                            ...acc,
                            [colTitle as string]: cellValue,
                        };
                    }, {});

                const colsKeyTitleToExport = orderedVisibleColKeys
                    .map((key) => colsToRender.find((item) => stringifyDataIndex(item.dataIndex) === key) || null)
                    .filter((item): item is CustomColumnType<ContextDataT> => !!item);

                const tableDataToExport: Record<string, string>[] = filteredInternalDataSource.map(
                    (record: ContextDataT) => getTableDataRowToExport(colsKeyTitleToExport, record),
                );

                if (summaryItems) {
                    const totalRow: Record<string, string> = colsKeyTitleToExport.reduce(
                        (acc, key) => ({
                            ...acc,
                            [stringifyDataIndex(key.dataIndex)]:
                                summaryItems.find((item) => item.key === key.dataIndex)?.value ?? '',
                        }),
                        {},
                    );

                    tableDataToExport.push(getTableDataRowToExport(colsKeyTitleToExport, totalRow as ContextDataT));
                }
                return tableDataToExport;
            };

            let fileName = title;
            if (typeof exportCSV !== 'boolean' && exportCSV.fileName) {
                fileName = exportCSV.fileName;
            }

            toolbarElements = [
                ...toolbarElements,
                <ExportCSV getDataToExport={getTableDataToExport} isDisabled={!localData.length} fileName={fileName} />,
            ];
        }
        return (
            <TableHeader<ContextDataT>
                onSizeChange={(tableSize: SizeType) => {
                    setTableSizeLS(tableSize);
                }}
                defaultSize={tableSizeLS}
                toolbar={toolbarElements}
                activeColumnsSetter={setVisibleColKeys}
                reorderColumns={setTableColumns}
                columns={tableColumns}
                updateData={onRefreshClick}
                title={title || ''}
                colsSettings={colsSettings}
                setLSColsOrder={setLSColsOrder}
            />
        );
    };

    const paginationSettings: false | TablePaginationConfig | undefined = pagination
        ? {
              ...pagination,
              size: 'default',
              defaultPageSize: pagination.defaultPageSize ?? 20,
              showSizeChanger: pagination.showSizeChanger ?? true,
          }
        : pagination ?? { size: 'default', showSizeChanger: true, defaultPageSize: 20 };

    const tableDataSource = visibleColKeys.length ? localData : undefined;
    const visibleColumns = getVisibleCols(tableColumns);

    return (
        <Context.Provider
            value={{
                data: localData,
                staticData: dataSource,
                setData: setLocalData,
                filteredPanel: filteredValues,
                setFilteredPanel: setFilteredValues,
            }}
        >
            <Table
                {...restProps}
                style={{ ...style, margin: style?.margin ?? '1.5rem' }}
                rowKey={rowKey}
                components={{
                    ...components,
                    body: draggable
                        ? {
                              wrapper: sortDrag.TableWrapper,
                              row: sortDrag.TableRow,
                          }
                        : components?.body || {
                              row: renderRowBody,
                          },
                }}
                dataSource={tableDataSource}
                columns={visibleColumns}
                size={tableSizeLS}
                onChange={(_, __, sorter, extra) => {
                    setInternalDataSource(extra.currentDataSource);
                    return (sorter as SorterResult<ContextDataT>).column ? setIsSorted(true) : setIsSorted(false);
                }}
                scroll={scroll || { x: 800 }}
                title={header === false ? undefined : tableHeader}
                pagination={paginationSettings}
                summary={restProps.summary || (() => getSummaryRow(summaryItems, visibleColumns))}
            />
        </Context.Provider>
    );
}
