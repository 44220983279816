
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * LegalDocumentGroupModifyParams struct
 */
class LegalDocumentGroupModifyParams {
  /**
   * Initialize struct
   * @param {!number=} groupId
   * @param {number=} newSortOrder
   * @param {boolean=} newIsEnabled
   * @param {string=} newComment
   * @constructor
   */
  constructor (groupId = new UInt64(0, 0), newSortOrder = null, newIsEnabled = null, newComment = null) {
    this.GroupId = groupId
    this.NewSortOrder = newSortOrder
    this.NewIsEnabled = newIsEnabled
    this.NewComment = newComment
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LegalDocumentGroupModifyParams}
   * @param {!LegalDocumentGroupModifyParams} other Other struct
   * @returns {!LegalDocumentGroupModifyParams} This struct
   */
  copy (other) {
    if (other.GroupId != null) {
      this.GroupId = UInt64.fromNumber(other.GroupId)
    } else {
      this.GroupId = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = UInt64.fromNumber(other.NewSortOrder)
    } else {
      this.NewSortOrder = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LegalDocumentGroupModifyParams}
   * @returns {!LegalDocumentGroupModifyParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LegalDocumentGroupModifyParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LegalDocumentGroupModifyParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LegalDocumentGroupModifyParams}
   * @param {!LegalDocumentGroupModifyParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LegalDocumentGroupModifyParams)) {
      throw new TypeError('Instance of LegalDocumentGroupModifyParams is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LegalDocumentGroupModifyParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      GroupId: ((this.GroupId != null) ? this.GroupId.toNumber() : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder.toNumber() : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LegalDocumentGroupModifyParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LegalDocumentGroupModifyParams} other Object value
   * @returns {!LegalDocumentGroupModifyParams} Created struct
   */
  static fromObject (other) {
    return new LegalDocumentGroupModifyParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LegalDocumentGroupModifyParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LegalDocumentGroupModifyParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LegalDocumentGroupModifyParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 15013
  }
}

export { LegalDocumentGroupModifyParams };

/**
 * Fast Binary Encoding LegalDocumentGroupModifyParams field model
 */
class FieldModelLegalDocumentGroupModifyParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.GroupId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.GroupId.fbeOffset + this.GroupId.fbeSize), buffer, this.GroupId.fbeOffset + this.GroupId.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize), buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.GroupId.fbeSize + this.NewSortOrder.fbeSize + this.NewIsEnabled.fbeSize + this.NewComment.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.GroupId.fbeExtra + this.NewSortOrder.fbeExtra + this.NewIsEnabled.fbeExtra + this.NewComment.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLegalDocumentGroupModifyParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 15013
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.GroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GroupId.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @param {!LegalDocumentGroupModifyParams} fbeValue Default value, defaults is new LegalDocumentGroupModifyParams()
   * @returns {!LegalDocumentGroupModifyParams} LegalDocumentGroupModifyParams value
   */
  get (fbeValue = new LegalDocumentGroupModifyParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @param {!LegalDocumentGroupModifyParams} fbeValue LegalDocumentGroupModifyParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.GroupId.fbeSize) <= fbeStructSize) {
      fbeValue.GroupId = this.GroupId.get()
    } else {
      fbeValue.GroupId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GroupId.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @param {!LegalDocumentGroupModifyParams} fbeValue LegalDocumentGroupModifyParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLegalDocumentGroupModifyParams}
   * @param {LegalDocumentGroupModifyParams} fbeValue LegalDocumentGroupModifyParams value
   */
  setFields (fbeValue) {
    this.GroupId.set(fbeValue.GroupId)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
    this.NewComment.set(fbeValue.NewComment)
  }
}

export { FieldModelLegalDocumentGroupModifyParams };

/**
 * Fast Binary Encoding LegalDocumentGroupModifyParams model
 */
class LegalDocumentGroupModifyParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLegalDocumentGroupModifyParams(this.buffer, 4)
  }

  /**
   * Get the LegalDocumentGroupModifyParams model
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @returns {!FieldModelLegalDocumentGroupModifyParams} model LegalDocumentGroupModifyParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LegalDocumentGroupModifyParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLegalDocumentGroupModifyParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @param {!LegalDocumentGroupModifyParams} value LegalDocumentGroupModifyParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @param {!LegalDocumentGroupModifyParams} value LegalDocumentGroupModifyParams value, defaults is new LegalDocumentGroupModifyParams()
   * @return {!object} Deserialized LegalDocumentGroupModifyParams value and its size
   */
  deserialize (value = new LegalDocumentGroupModifyParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LegalDocumentGroupModifyParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LegalDocumentGroupModifyParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LegalDocumentGroupModifyParamsModel}
   * @param {!number} prev Previous LegalDocumentGroupModifyParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LegalDocumentGroupModifyParamsModel };
