// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: security.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'
import * as entity from './entity'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ErrorCode} from './security/ErrorCode'
import {Reject} from './security/Reject'
import {RejectModel} from './security/Reject'
import {TokenCreateParams} from './security/TokenCreateParams'
import {TokenCreateParamsModel} from './security/TokenCreateParams'
import {TokenPair} from './security/TokenPair'
import {TokenPairModel} from './security/TokenPair'
import {TokenCreateRequest} from './security/TokenCreateRequest'
import {TokenCreateRequestModel} from './security/TokenCreateRequest'
import {TokenCreateResponse} from './security/TokenCreateResponse'
import {TokenCreateResponseModel} from './security/TokenCreateResponse'
import {TokenAccessParams} from './security/TokenAccessParams'
import {TokenAccessParamsModel} from './security/TokenAccessParams'
import {TokenAccessResult} from './security/TokenAccessResult'
import {TokenAccessResultModel} from './security/TokenAccessResult'
import {TokenAccessRequest} from './security/TokenAccessRequest'
import {TokenAccessRequestModel} from './security/TokenAccessRequest'
import {TokenAccessResponse} from './security/TokenAccessResponse'
import {TokenAccessResponseModel} from './security/TokenAccessResponse'
import {TokenRefreshParams} from './security/TokenRefreshParams'
import {TokenRefreshParamsModel} from './security/TokenRefreshParams'
import {TokenRefreshResult} from './security/TokenRefreshResult'
import {TokenRefreshResultModel} from './security/TokenRefreshResult'
import {TokenRefreshRequest} from './security/TokenRefreshRequest'
import {TokenRefreshRequestModel} from './security/TokenRefreshRequest'
import {TokenRefreshResponse} from './security/TokenRefreshResponse'
import {TokenRefreshResponseModel} from './security/TokenRefreshResponse'
import {TokenInfo} from './security/TokenInfo'
import {TokenInfoModel} from './security/TokenInfo'
import {TokensGetParams} from './security/TokensGetParams'
import {TokensGetParamsModel} from './security/TokensGetParams'
import {TokensGetResult} from './security/TokensGetResult'
import {TokensGetResultModel} from './security/TokensGetResult'
import {TokensGetRequest} from './security/TokensGetRequest'
import {TokensGetRequestModel} from './security/TokensGetRequest'
import {TokensGetResponse} from './security/TokensGetResponse'
import {TokensGetResponseModel} from './security/TokensGetResponse'
import {TokensDeleteParams} from './security/TokensDeleteParams'
import {TokensDeleteParamsModel} from './security/TokensDeleteParams'
import {TokensDeleteResult} from './security/TokensDeleteResult'
import {TokensDeleteResultModel} from './security/TokensDeleteResult'
import {TokensDeleteRequest} from './security/TokensDeleteRequest'
import {TokensDeleteRequestModel} from './security/TokensDeleteRequest'
import {TokensDeleteResponse} from './security/TokensDeleteResponse'
import {TokensDeleteResponseModel} from './security/TokensDeleteResponse'
import {TokenUpdateNotify} from './security/TokenUpdateNotify'
import {TokenUpdateNotifyModel} from './security/TokenUpdateNotify'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 7

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding security sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize security sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._entitySender = new entity.Sender(this.buffer)
    this._RejectModel = new RejectModel(this.buffer)
    this._TokenCreateRequestModel = new TokenCreateRequestModel(this.buffer)
    this._TokenCreateResponseModel = new TokenCreateResponseModel(this.buffer)
    this._TokenAccessRequestModel = new TokenAccessRequestModel(this.buffer)
    this._TokenAccessResponseModel = new TokenAccessResponseModel(this.buffer)
    this._TokenRefreshRequestModel = new TokenRefreshRequestModel(this.buffer)
    this._TokenRefreshResponseModel = new TokenRefreshResponseModel(this.buffer)
    this._TokensGetRequestModel = new TokensGetRequestModel(this.buffer)
    this._TokensGetResponseModel = new TokensGetResponseModel(this.buffer)
    this._TokensDeleteRequestModel = new TokensDeleteRequestModel(this.buffer)
    this._TokensDeleteResponseModel = new TokensDeleteResponseModel(this.buffer)
    this._TokenUpdateNotifyModel = new TokenUpdateNotifyModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  /**
   * Get imported entity sender
   * @this {!Sender}
   * @returns {!entity.Sender} entity sender
   */
  get entitySender () {
    return this._entitySender
  }

  // Sender models accessors

  /**
   * Get Reject model
   * @this {!Sender}
   * @returns {!RejectModel} Reject model
   */
  get RejectModel () {
    return this._RejectModel
  }

  /**
   * Get TokenCreateRequest model
   * @this {!Sender}
   * @returns {!TokenCreateRequestModel} TokenCreateRequest model
   */
  get TokenCreateRequestModel () {
    return this._TokenCreateRequestModel
  }

  /**
   * Get TokenCreateResponse model
   * @this {!Sender}
   * @returns {!TokenCreateResponseModel} TokenCreateResponse model
   */
  get TokenCreateResponseModel () {
    return this._TokenCreateResponseModel
  }

  /**
   * Get TokenAccessRequest model
   * @this {!Sender}
   * @returns {!TokenAccessRequestModel} TokenAccessRequest model
   */
  get TokenAccessRequestModel () {
    return this._TokenAccessRequestModel
  }

  /**
   * Get TokenAccessResponse model
   * @this {!Sender}
   * @returns {!TokenAccessResponseModel} TokenAccessResponse model
   */
  get TokenAccessResponseModel () {
    return this._TokenAccessResponseModel
  }

  /**
   * Get TokenRefreshRequest model
   * @this {!Sender}
   * @returns {!TokenRefreshRequestModel} TokenRefreshRequest model
   */
  get TokenRefreshRequestModel () {
    return this._TokenRefreshRequestModel
  }

  /**
   * Get TokenRefreshResponse model
   * @this {!Sender}
   * @returns {!TokenRefreshResponseModel} TokenRefreshResponse model
   */
  get TokenRefreshResponseModel () {
    return this._TokenRefreshResponseModel
  }

  /**
   * Get TokensGetRequest model
   * @this {!Sender}
   * @returns {!TokensGetRequestModel} TokensGetRequest model
   */
  get TokensGetRequestModel () {
    return this._TokensGetRequestModel
  }

  /**
   * Get TokensGetResponse model
   * @this {!Sender}
   * @returns {!TokensGetResponseModel} TokensGetResponse model
   */
  get TokensGetResponseModel () {
    return this._TokensGetResponseModel
  }

  /**
   * Get TokensDeleteRequest model
   * @this {!Sender}
   * @returns {!TokensDeleteRequestModel} TokensDeleteRequest model
   */
  get TokensDeleteRequestModel () {
    return this._TokensDeleteRequestModel
  }

  /**
   * Get TokensDeleteResponse model
   * @this {!Sender}
   * @returns {!TokensDeleteResponseModel} TokensDeleteResponse model
   */
  get TokensDeleteResponseModel () {
    return this._TokensDeleteResponseModel
  }

  /**
   * Get TokenUpdateNotify model
   * @this {!Sender}
   * @returns {!TokenUpdateNotifyModel} TokenUpdateNotify model
   */
  get TokenUpdateNotifyModel () {
    return this._TokenUpdateNotifyModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof Reject) && (value.fbeType === this.RejectModel.fbeType)) {
      return this.send_Reject(value)
    }
    if ((value instanceof TokenCreateRequest) && (value.fbeType === this.TokenCreateRequestModel.fbeType)) {
      return this.send_TokenCreateRequest(value)
    }
    if ((value instanceof TokenCreateResponse) && (value.fbeType === this.TokenCreateResponseModel.fbeType)) {
      return this.send_TokenCreateResponse(value)
    }
    if ((value instanceof TokenAccessRequest) && (value.fbeType === this.TokenAccessRequestModel.fbeType)) {
      return this.send_TokenAccessRequest(value)
    }
    if ((value instanceof TokenAccessResponse) && (value.fbeType === this.TokenAccessResponseModel.fbeType)) {
      return this.send_TokenAccessResponse(value)
    }
    if ((value instanceof TokenRefreshRequest) && (value.fbeType === this.TokenRefreshRequestModel.fbeType)) {
      return this.send_TokenRefreshRequest(value)
    }
    if ((value instanceof TokenRefreshResponse) && (value.fbeType === this.TokenRefreshResponseModel.fbeType)) {
      return this.send_TokenRefreshResponse(value)
    }
    if ((value instanceof TokensGetRequest) && (value.fbeType === this.TokensGetRequestModel.fbeType)) {
      return this.send_TokensGetRequest(value)
    }
    if ((value instanceof TokensGetResponse) && (value.fbeType === this.TokensGetResponseModel.fbeType)) {
      return this.send_TokensGetResponse(value)
    }
    if ((value instanceof TokensDeleteRequest) && (value.fbeType === this.TokensDeleteRequestModel.fbeType)) {
      return this.send_TokensDeleteRequest(value)
    }
    if ((value instanceof TokensDeleteResponse) && (value.fbeType === this.TokensDeleteResponseModel.fbeType)) {
      return this.send_TokensDeleteResponse(value)
    }
    if ((value instanceof TokenUpdateNotify) && (value.fbeType === this.TokenUpdateNotifyModel.fbeType)) {
      return this.send_TokenUpdateNotify(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._entitySender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send Reject value
   * @this {!Sender}
   * @param {!Reject} value Reject value to send
   * @returns {!number} Sent bytes
   */
  send_Reject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RejectModel.serialize(value)
    console.assert((serialized > 0), 'security.Reject serialization failed!')
    console.assert(this.RejectModel.verify(), 'security.Reject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenCreateRequest value
   * @this {!Sender}
   * @param {!TokenCreateRequest} value TokenCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokenCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenCreateRequest serialization failed!')
    console.assert(this.TokenCreateRequestModel.verify(), 'security.TokenCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenCreateResponse value
   * @this {!Sender}
   * @param {!TokenCreateResponse} value TokenCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokenCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenCreateResponse serialization failed!')
    console.assert(this.TokenCreateResponseModel.verify(), 'security.TokenCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenAccessRequest value
   * @this {!Sender}
   * @param {!TokenAccessRequest} value TokenAccessRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokenAccessRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenAccessRequestModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenAccessRequest serialization failed!')
    console.assert(this.TokenAccessRequestModel.verify(), 'security.TokenAccessRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenAccessResponse value
   * @this {!Sender}
   * @param {!TokenAccessResponse} value TokenAccessResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokenAccessResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenAccessResponseModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenAccessResponse serialization failed!')
    console.assert(this.TokenAccessResponseModel.verify(), 'security.TokenAccessResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenRefreshRequest value
   * @this {!Sender}
   * @param {!TokenRefreshRequest} value TokenRefreshRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokenRefreshRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenRefreshRequestModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenRefreshRequest serialization failed!')
    console.assert(this.TokenRefreshRequestModel.verify(), 'security.TokenRefreshRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenRefreshResponse value
   * @this {!Sender}
   * @param {!TokenRefreshResponse} value TokenRefreshResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokenRefreshResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenRefreshResponseModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenRefreshResponse serialization failed!')
    console.assert(this.TokenRefreshResponseModel.verify(), 'security.TokenRefreshResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokensGetRequest value
   * @this {!Sender}
   * @param {!TokensGetRequest} value TokensGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokensGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokensGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'security.TokensGetRequest serialization failed!')
    console.assert(this.TokensGetRequestModel.verify(), 'security.TokensGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokensGetResponse value
   * @this {!Sender}
   * @param {!TokensGetResponse} value TokensGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokensGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokensGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'security.TokensGetResponse serialization failed!')
    console.assert(this.TokensGetResponseModel.verify(), 'security.TokensGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokensDeleteRequest value
   * @this {!Sender}
   * @param {!TokensDeleteRequest} value TokensDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokensDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokensDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'security.TokensDeleteRequest serialization failed!')
    console.assert(this.TokensDeleteRequestModel.verify(), 'security.TokensDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokensDeleteResponse value
   * @this {!Sender}
   * @param {!TokensDeleteResponse} value TokensDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokensDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokensDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'security.TokensDeleteResponse serialization failed!')
    console.assert(this.TokensDeleteResponseModel.verify(), 'security.TokensDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenUpdateNotify value
   * @this {!Sender}
   * @param {!TokenUpdateNotify} value TokenUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TokenUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenUpdateNotify serialization failed!')
    console.assert(this.TokenUpdateNotifyModel.verify(), 'security.TokenUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'security.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
    this._entitySender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
    this._entitySender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding security receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize security receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._entityReceiver = new entity.Receiver(this.buffer)
    this._RejectValue = new Reject()
    this._RejectModel = new RejectModel()
    this._TokenCreateRequestValue = new TokenCreateRequest()
    this._TokenCreateRequestModel = new TokenCreateRequestModel()
    this._TokenCreateResponseValue = new TokenCreateResponse()
    this._TokenCreateResponseModel = new TokenCreateResponseModel()
    this._TokenAccessRequestValue = new TokenAccessRequest()
    this._TokenAccessRequestModel = new TokenAccessRequestModel()
    this._TokenAccessResponseValue = new TokenAccessResponse()
    this._TokenAccessResponseModel = new TokenAccessResponseModel()
    this._TokenRefreshRequestValue = new TokenRefreshRequest()
    this._TokenRefreshRequestModel = new TokenRefreshRequestModel()
    this._TokenRefreshResponseValue = new TokenRefreshResponse()
    this._TokenRefreshResponseModel = new TokenRefreshResponseModel()
    this._TokensGetRequestValue = new TokensGetRequest()
    this._TokensGetRequestModel = new TokensGetRequestModel()
    this._TokensGetResponseValue = new TokensGetResponse()
    this._TokensGetResponseModel = new TokensGetResponseModel()
    this._TokensDeleteRequestValue = new TokensDeleteRequest()
    this._TokensDeleteRequestModel = new TokensDeleteRequestModel()
    this._TokensDeleteResponseValue = new TokensDeleteResponse()
    this._TokensDeleteResponseModel = new TokensDeleteResponseModel()
    this._TokenUpdateNotifyValue = new TokenUpdateNotify()
    this._TokenUpdateNotifyModel = new TokenUpdateNotifyModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  /**
   * Get imported entity receiver
   * @this {!Receiver}
   * @returns {Receiver} entity receiver
   */
  get entityReceiver () {
    return this._entityReceiver
  }

  /**
   * Set imported entity receiver
   * @this {!Receiver}
   * @param {Receiver} receiver entity receiver
   */
  set entityReceiver (receiver) {
    this._entityReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * Reject receive handler
   * @this {!Receiver}
   * @param {!Reject} value Reject received value
   */
  onReceive_Reject (value) {}  // eslint-disable-line

  /**
   * TokenCreateRequest receive handler
   * @this {!Receiver}
   * @param {!TokenCreateRequest} value TokenCreateRequest received value
   */
  onReceive_TokenCreateRequest (value) {}  // eslint-disable-line

  /**
   * TokenCreateResponse receive handler
   * @this {!Receiver}
   * @param {!TokenCreateResponse} value TokenCreateResponse received value
   */
  onReceive_TokenCreateResponse (value) {}  // eslint-disable-line

  /**
   * TokenAccessRequest receive handler
   * @this {!Receiver}
   * @param {!TokenAccessRequest} value TokenAccessRequest received value
   */
  onReceive_TokenAccessRequest (value) {}  // eslint-disable-line

  /**
   * TokenAccessResponse receive handler
   * @this {!Receiver}
   * @param {!TokenAccessResponse} value TokenAccessResponse received value
   */
  onReceive_TokenAccessResponse (value) {}  // eslint-disable-line

  /**
   * TokenRefreshRequest receive handler
   * @this {!Receiver}
   * @param {!TokenRefreshRequest} value TokenRefreshRequest received value
   */
  onReceive_TokenRefreshRequest (value) {}  // eslint-disable-line

  /**
   * TokenRefreshResponse receive handler
   * @this {!Receiver}
   * @param {!TokenRefreshResponse} value TokenRefreshResponse received value
   */
  onReceive_TokenRefreshResponse (value) {}  // eslint-disable-line

  /**
   * TokensGetRequest receive handler
   * @this {!Receiver}
   * @param {!TokensGetRequest} value TokensGetRequest received value
   */
  onReceive_TokensGetRequest (value) {}  // eslint-disable-line

  /**
   * TokensGetResponse receive handler
   * @this {!Receiver}
   * @param {!TokensGetResponse} value TokensGetResponse received value
   */
  onReceive_TokensGetResponse (value) {}  // eslint-disable-line

  /**
   * TokensDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!TokensDeleteRequest} value TokensDeleteRequest received value
   */
  onReceive_TokensDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TokensDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!TokensDeleteResponse} value TokensDeleteResponse received value
   */
  onReceive_TokensDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TokenUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TokenUpdateNotify} value TokenUpdateNotify received value
   */
  onReceive_TokenUpdateNotify (value) {}  // eslint-disable-line

  /**
   * security receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case RejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RejectModel.attachBuffer(buffer, offset)
        console.assert(this._RejectModel.verify(), 'security.Reject validation failed!')
        let deserialized = this._RejectModel.deserialize(this._RejectValue)
        console.assert((deserialized.size > 0), 'security.Reject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RejectValue)
        this.onReceive_Reject(this._RejectValue)
        return true
      }
      case TokenCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokenCreateRequestModel.verify(), 'security.TokenCreateRequest validation failed!')
        let deserialized = this._TokenCreateRequestModel.deserialize(this._TokenCreateRequestValue)
        console.assert((deserialized.size > 0), 'security.TokenCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenCreateRequestValue)
        this.onReceive_TokenCreateRequest(this._TokenCreateRequestValue)
        return true
      }
      case TokenCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokenCreateResponseModel.verify(), 'security.TokenCreateResponse validation failed!')
        let deserialized = this._TokenCreateResponseModel.deserialize(this._TokenCreateResponseValue)
        console.assert((deserialized.size > 0), 'security.TokenCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenCreateResponseValue)
        this.onReceive_TokenCreateResponse(this._TokenCreateResponseValue)
        return true
      }
      case TokenAccessRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenAccessRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokenAccessRequestModel.verify(), 'security.TokenAccessRequest validation failed!')
        let deserialized = this._TokenAccessRequestModel.deserialize(this._TokenAccessRequestValue)
        console.assert((deserialized.size > 0), 'security.TokenAccessRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenAccessRequestValue)
        this.onReceive_TokenAccessRequest(this._TokenAccessRequestValue)
        return true
      }
      case TokenAccessResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenAccessResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokenAccessResponseModel.verify(), 'security.TokenAccessResponse validation failed!')
        let deserialized = this._TokenAccessResponseModel.deserialize(this._TokenAccessResponseValue)
        console.assert((deserialized.size > 0), 'security.TokenAccessResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenAccessResponseValue)
        this.onReceive_TokenAccessResponse(this._TokenAccessResponseValue)
        return true
      }
      case TokenRefreshRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenRefreshRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokenRefreshRequestModel.verify(), 'security.TokenRefreshRequest validation failed!')
        let deserialized = this._TokenRefreshRequestModel.deserialize(this._TokenRefreshRequestValue)
        console.assert((deserialized.size > 0), 'security.TokenRefreshRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenRefreshRequestValue)
        this.onReceive_TokenRefreshRequest(this._TokenRefreshRequestValue)
        return true
      }
      case TokenRefreshResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenRefreshResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokenRefreshResponseModel.verify(), 'security.TokenRefreshResponse validation failed!')
        let deserialized = this._TokenRefreshResponseModel.deserialize(this._TokenRefreshResponseValue)
        console.assert((deserialized.size > 0), 'security.TokenRefreshResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenRefreshResponseValue)
        this.onReceive_TokenRefreshResponse(this._TokenRefreshResponseValue)
        return true
      }
      case TokensGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokensGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokensGetRequestModel.verify(), 'security.TokensGetRequest validation failed!')
        let deserialized = this._TokensGetRequestModel.deserialize(this._TokensGetRequestValue)
        console.assert((deserialized.size > 0), 'security.TokensGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokensGetRequestValue)
        this.onReceive_TokensGetRequest(this._TokensGetRequestValue)
        return true
      }
      case TokensGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokensGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokensGetResponseModel.verify(), 'security.TokensGetResponse validation failed!')
        let deserialized = this._TokensGetResponseModel.deserialize(this._TokensGetResponseValue)
        console.assert((deserialized.size > 0), 'security.TokensGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokensGetResponseValue)
        this.onReceive_TokensGetResponse(this._TokensGetResponseValue)
        return true
      }
      case TokensDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokensDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokensDeleteRequestModel.verify(), 'security.TokensDeleteRequest validation failed!')
        let deserialized = this._TokensDeleteRequestModel.deserialize(this._TokensDeleteRequestValue)
        console.assert((deserialized.size > 0), 'security.TokensDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokensDeleteRequestValue)
        this.onReceive_TokensDeleteRequest(this._TokensDeleteRequestValue)
        return true
      }
      case TokensDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokensDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokensDeleteResponseModel.verify(), 'security.TokensDeleteResponse validation failed!')
        let deserialized = this._TokensDeleteResponseModel.deserialize(this._TokensDeleteResponseValue)
        console.assert((deserialized.size > 0), 'security.TokensDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokensDeleteResponseValue)
        this.onReceive_TokensDeleteResponse(this._TokensDeleteResponseValue)
        return true
      }
      case TokenUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TokenUpdateNotifyModel.verify(), 'security.TokenUpdateNotify validation failed!')
        let deserialized = this._TokenUpdateNotifyModel.deserialize(this._TokenUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'security.TokenUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenUpdateNotifyValue)
        this.onReceive_TokenUpdateNotify(this._TokenUpdateNotifyValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.entityReceiver != null) && this.entityReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
    this._entityReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding security proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize security proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._entityProxy = new entity.Proxy(this.buffer)
    this._RejectModel = new RejectModel()
    this._TokenCreateRequestModel = new TokenCreateRequestModel()
    this._TokenCreateResponseModel = new TokenCreateResponseModel()
    this._TokenAccessRequestModel = new TokenAccessRequestModel()
    this._TokenAccessResponseModel = new TokenAccessResponseModel()
    this._TokenRefreshRequestModel = new TokenRefreshRequestModel()
    this._TokenRefreshResponseModel = new TokenRefreshResponseModel()
    this._TokensGetRequestModel = new TokensGetRequestModel()
    this._TokensGetResponseModel = new TokensGetResponseModel()
    this._TokensDeleteRequestModel = new TokensDeleteRequestModel()
    this._TokensDeleteResponseModel = new TokensDeleteResponseModel()
    this._TokenUpdateNotifyModel = new TokenUpdateNotifyModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  /**
   * Get imported entity proxy
   * @this {!Proxy}
   * @returns {Proxy} entity proxy
   */
  get entityProxy () {
    return this._entityProxy
  }

  /**
   * Set imported entity proxy
   * @this {!Proxy}
   * @param {Proxy} proxy entity proxy
   */
  set entityProxy (proxy) {
    this._entityProxy = proxy
  }

  // Proxy handlers

  /**
   * Reject proxy handler
   * @this {!Proxy}
   * @param {!Reject} model Reject model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Reject (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokenCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!TokenCreateRequest} model TokenCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokenCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokenCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!TokenCreateResponse} model TokenCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokenCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokenAccessRequest proxy handler
   * @this {!Proxy}
   * @param {!TokenAccessRequest} model TokenAccessRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokenAccessRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokenAccessResponse proxy handler
   * @this {!Proxy}
   * @param {!TokenAccessResponse} model TokenAccessResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokenAccessResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokenRefreshRequest proxy handler
   * @this {!Proxy}
   * @param {!TokenRefreshRequest} model TokenRefreshRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokenRefreshRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokenRefreshResponse proxy handler
   * @this {!Proxy}
   * @param {!TokenRefreshResponse} model TokenRefreshResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokenRefreshResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokensGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TokensGetRequest} model TokensGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokensGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokensGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TokensGetResponse} model TokensGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokensGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokensDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!TokensDeleteRequest} model TokensDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokensDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokensDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!TokensDeleteResponse} model TokensDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokensDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TokenUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TokenUpdateNotify} model TokenUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TokenUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * security receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case RejectModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RejectModel.attachBuffer(buffer, offset)
        console.assert(this._RejectModel.verify(), 'security.Reject validation failed!')

        let fbeBegin = this._RejectModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Reject(this._RejectModel, type, buffer, offset, size)
        this._RejectModel.model.getEnd(fbeBegin)
        return true
      }
      case TokenCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokenCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokenCreateRequestModel.verify(), 'security.TokenCreateRequest validation failed!')

        let fbeBegin = this._TokenCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokenCreateRequest(this._TokenCreateRequestModel, type, buffer, offset, size)
        this._TokenCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TokenCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokenCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokenCreateResponseModel.verify(), 'security.TokenCreateResponse validation failed!')

        let fbeBegin = this._TokenCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokenCreateResponse(this._TokenCreateResponseModel, type, buffer, offset, size)
        this._TokenCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TokenAccessRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokenAccessRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokenAccessRequestModel.verify(), 'security.TokenAccessRequest validation failed!')

        let fbeBegin = this._TokenAccessRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokenAccessRequest(this._TokenAccessRequestModel, type, buffer, offset, size)
        this._TokenAccessRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TokenAccessResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokenAccessResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokenAccessResponseModel.verify(), 'security.TokenAccessResponse validation failed!')

        let fbeBegin = this._TokenAccessResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokenAccessResponse(this._TokenAccessResponseModel, type, buffer, offset, size)
        this._TokenAccessResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TokenRefreshRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokenRefreshRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokenRefreshRequestModel.verify(), 'security.TokenRefreshRequest validation failed!')

        let fbeBegin = this._TokenRefreshRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokenRefreshRequest(this._TokenRefreshRequestModel, type, buffer, offset, size)
        this._TokenRefreshRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TokenRefreshResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokenRefreshResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokenRefreshResponseModel.verify(), 'security.TokenRefreshResponse validation failed!')

        let fbeBegin = this._TokenRefreshResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokenRefreshResponse(this._TokenRefreshResponseModel, type, buffer, offset, size)
        this._TokenRefreshResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TokensGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokensGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokensGetRequestModel.verify(), 'security.TokensGetRequest validation failed!')

        let fbeBegin = this._TokensGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokensGetRequest(this._TokensGetRequestModel, type, buffer, offset, size)
        this._TokensGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TokensGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokensGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokensGetResponseModel.verify(), 'security.TokensGetResponse validation failed!')

        let fbeBegin = this._TokensGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokensGetResponse(this._TokensGetResponseModel, type, buffer, offset, size)
        this._TokensGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TokensDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokensDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TokensDeleteRequestModel.verify(), 'security.TokensDeleteRequest validation failed!')

        let fbeBegin = this._TokensDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokensDeleteRequest(this._TokensDeleteRequestModel, type, buffer, offset, size)
        this._TokensDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TokensDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokensDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TokensDeleteResponseModel.verify(), 'security.TokensDeleteResponse validation failed!')

        let fbeBegin = this._TokensDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokensDeleteResponse(this._TokensDeleteResponseModel, type, buffer, offset, size)
        this._TokensDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TokenUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TokenUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TokenUpdateNotifyModel.verify(), 'security.TokenUpdateNotify validation failed!')

        let fbeBegin = this._TokenUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TokenUpdateNotify(this._TokenUpdateNotifyModel, type, buffer, offset, size)
        this._TokenUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.entityProxy != null) && this.entityProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding security client
 */
class Client extends fbe.Client {
  /**
   * Initialize security client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._entityClient = new entity.Client(this.sendBuffer, this.receiveBuffer)
    this._RejectSenderModel = new RejectModel(this.sendBuffer)
    this._RejectReceiverValue = new Reject()
    this._RejectReceiverModel = new RejectModel()
    this._TokenCreateRequestSenderModel = new TokenCreateRequestModel(this.sendBuffer)
    this._TokenCreateRequestReceiverValue = new TokenCreateRequest()
    this._TokenCreateRequestReceiverModel = new TokenCreateRequestModel()
    this._TokenCreateResponseSenderModel = new TokenCreateResponseModel(this.sendBuffer)
    this._TokenCreateResponseReceiverValue = new TokenCreateResponse()
    this._TokenCreateResponseReceiverModel = new TokenCreateResponseModel()
    this._TokenAccessRequestSenderModel = new TokenAccessRequestModel(this.sendBuffer)
    this._TokenAccessRequestReceiverValue = new TokenAccessRequest()
    this._TokenAccessRequestReceiverModel = new TokenAccessRequestModel()
    this._TokenAccessResponseSenderModel = new TokenAccessResponseModel(this.sendBuffer)
    this._TokenAccessResponseReceiverValue = new TokenAccessResponse()
    this._TokenAccessResponseReceiverModel = new TokenAccessResponseModel()
    this._TokenRefreshRequestSenderModel = new TokenRefreshRequestModel(this.sendBuffer)
    this._TokenRefreshRequestReceiverValue = new TokenRefreshRequest()
    this._TokenRefreshRequestReceiverModel = new TokenRefreshRequestModel()
    this._TokenRefreshResponseSenderModel = new TokenRefreshResponseModel(this.sendBuffer)
    this._TokenRefreshResponseReceiverValue = new TokenRefreshResponse()
    this._TokenRefreshResponseReceiverModel = new TokenRefreshResponseModel()
    this._TokensGetRequestSenderModel = new TokensGetRequestModel(this.sendBuffer)
    this._TokensGetRequestReceiverValue = new TokensGetRequest()
    this._TokensGetRequestReceiverModel = new TokensGetRequestModel()
    this._TokensGetResponseSenderModel = new TokensGetResponseModel(this.sendBuffer)
    this._TokensGetResponseReceiverValue = new TokensGetResponse()
    this._TokensGetResponseReceiverModel = new TokensGetResponseModel()
    this._TokensDeleteRequestSenderModel = new TokensDeleteRequestModel(this.sendBuffer)
    this._TokensDeleteRequestReceiverValue = new TokensDeleteRequest()
    this._TokensDeleteRequestReceiverModel = new TokensDeleteRequestModel()
    this._TokensDeleteResponseSenderModel = new TokensDeleteResponseModel(this.sendBuffer)
    this._TokensDeleteResponseReceiverValue = new TokensDeleteResponse()
    this._TokensDeleteResponseReceiverModel = new TokensDeleteResponseModel()
    this._TokenUpdateNotifySenderModel = new TokenUpdateNotifyModel(this.sendBuffer)
    this._TokenUpdateNotifyReceiverValue = new TokenUpdateNotify()
    this._TokenUpdateNotifyReceiverModel = new TokenUpdateNotifyModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  /**
   * Get imported entity client
   * @this {!Client}
   * @returns {!entity.Client} entity client
   */
  get entityClient () {
    return this._entityClient
  }

  // Sender models accessors

  /**
   * Get Reject model
   * @this {!Client}
   * @returns {!RejectModel} Reject sender model
   */
  get RejectSenderModel () {
    return this._RejectSenderModel
  }

  /**
   * Get TokenCreateRequest model
   * @this {!Client}
   * @returns {!TokenCreateRequestModel} TokenCreateRequest sender model
   */
  get TokenCreateRequestSenderModel () {
    return this._TokenCreateRequestSenderModel
  }

  /**
   * Get TokenCreateResponse model
   * @this {!Client}
   * @returns {!TokenCreateResponseModel} TokenCreateResponse sender model
   */
  get TokenCreateResponseSenderModel () {
    return this._TokenCreateResponseSenderModel
  }

  /**
   * Get TokenAccessRequest model
   * @this {!Client}
   * @returns {!TokenAccessRequestModel} TokenAccessRequest sender model
   */
  get TokenAccessRequestSenderModel () {
    return this._TokenAccessRequestSenderModel
  }

  /**
   * Get TokenAccessResponse model
   * @this {!Client}
   * @returns {!TokenAccessResponseModel} TokenAccessResponse sender model
   */
  get TokenAccessResponseSenderModel () {
    return this._TokenAccessResponseSenderModel
  }

  /**
   * Get TokenRefreshRequest model
   * @this {!Client}
   * @returns {!TokenRefreshRequestModel} TokenRefreshRequest sender model
   */
  get TokenRefreshRequestSenderModel () {
    return this._TokenRefreshRequestSenderModel
  }

  /**
   * Get TokenRefreshResponse model
   * @this {!Client}
   * @returns {!TokenRefreshResponseModel} TokenRefreshResponse sender model
   */
  get TokenRefreshResponseSenderModel () {
    return this._TokenRefreshResponseSenderModel
  }

  /**
   * Get TokensGetRequest model
   * @this {!Client}
   * @returns {!TokensGetRequestModel} TokensGetRequest sender model
   */
  get TokensGetRequestSenderModel () {
    return this._TokensGetRequestSenderModel
  }

  /**
   * Get TokensGetResponse model
   * @this {!Client}
   * @returns {!TokensGetResponseModel} TokensGetResponse sender model
   */
  get TokensGetResponseSenderModel () {
    return this._TokensGetResponseSenderModel
  }

  /**
   * Get TokensDeleteRequest model
   * @this {!Client}
   * @returns {!TokensDeleteRequestModel} TokensDeleteRequest sender model
   */
  get TokensDeleteRequestSenderModel () {
    return this._TokensDeleteRequestSenderModel
  }

  /**
   * Get TokensDeleteResponse model
   * @this {!Client}
   * @returns {!TokensDeleteResponseModel} TokensDeleteResponse sender model
   */
  get TokensDeleteResponseSenderModel () {
    return this._TokensDeleteResponseSenderModel
  }

  /**
   * Get TokenUpdateNotify model
   * @this {!Client}
   * @returns {!TokenUpdateNotifyModel} TokenUpdateNotify sender model
   */
  get TokenUpdateNotifySenderModel () {
    return this._TokenUpdateNotifySenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof Reject) && (value.fbeType === this.RejectSenderModel.fbeType)) {
      return this.send_Reject(value)
    }
    if ((value instanceof TokenCreateRequest) && (value.fbeType === this.TokenCreateRequestSenderModel.fbeType)) {
      return this.send_TokenCreateRequest(value)
    }
    if ((value instanceof TokenCreateResponse) && (value.fbeType === this.TokenCreateResponseSenderModel.fbeType)) {
      return this.send_TokenCreateResponse(value)
    }
    if ((value instanceof TokenAccessRequest) && (value.fbeType === this.TokenAccessRequestSenderModel.fbeType)) {
      return this.send_TokenAccessRequest(value)
    }
    if ((value instanceof TokenAccessResponse) && (value.fbeType === this.TokenAccessResponseSenderModel.fbeType)) {
      return this.send_TokenAccessResponse(value)
    }
    if ((value instanceof TokenRefreshRequest) && (value.fbeType === this.TokenRefreshRequestSenderModel.fbeType)) {
      return this.send_TokenRefreshRequest(value)
    }
    if ((value instanceof TokenRefreshResponse) && (value.fbeType === this.TokenRefreshResponseSenderModel.fbeType)) {
      return this.send_TokenRefreshResponse(value)
    }
    if ((value instanceof TokensGetRequest) && (value.fbeType === this.TokensGetRequestSenderModel.fbeType)) {
      return this.send_TokensGetRequest(value)
    }
    if ((value instanceof TokensGetResponse) && (value.fbeType === this.TokensGetResponseSenderModel.fbeType)) {
      return this.send_TokensGetResponse(value)
    }
    if ((value instanceof TokensDeleteRequest) && (value.fbeType === this.TokensDeleteRequestSenderModel.fbeType)) {
      return this.send_TokensDeleteRequest(value)
    }
    if ((value instanceof TokensDeleteResponse) && (value.fbeType === this.TokensDeleteResponseSenderModel.fbeType)) {
      return this.send_TokensDeleteResponse(value)
    }
    if ((value instanceof TokenUpdateNotify) && (value.fbeType === this.TokenUpdateNotifySenderModel.fbeType)) {
      return this.send_TokenUpdateNotify(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._entityClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send Reject value
   * @this {!Client}
   * @param {!Reject} value Reject value to send
   * @returns {!number} Sent bytes
   */
  send_Reject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RejectSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.Reject serialization failed!')
    console.assert(this.RejectSenderModel.verify(), 'security.Reject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenCreateRequest value
   * @this {!Client}
   * @param {!TokenCreateRequest} value TokenCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokenCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenCreateRequest serialization failed!')
    console.assert(this.TokenCreateRequestSenderModel.verify(), 'security.TokenCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenCreateResponse value
   * @this {!Client}
   * @param {!TokenCreateResponse} value TokenCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokenCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenCreateResponse serialization failed!')
    console.assert(this.TokenCreateResponseSenderModel.verify(), 'security.TokenCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenAccessRequest value
   * @this {!Client}
   * @param {!TokenAccessRequest} value TokenAccessRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokenAccessRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenAccessRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenAccessRequest serialization failed!')
    console.assert(this.TokenAccessRequestSenderModel.verify(), 'security.TokenAccessRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenAccessResponse value
   * @this {!Client}
   * @param {!TokenAccessResponse} value TokenAccessResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokenAccessResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenAccessResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenAccessResponse serialization failed!')
    console.assert(this.TokenAccessResponseSenderModel.verify(), 'security.TokenAccessResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenRefreshRequest value
   * @this {!Client}
   * @param {!TokenRefreshRequest} value TokenRefreshRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokenRefreshRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenRefreshRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenRefreshRequest serialization failed!')
    console.assert(this.TokenRefreshRequestSenderModel.verify(), 'security.TokenRefreshRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenRefreshResponse value
   * @this {!Client}
   * @param {!TokenRefreshResponse} value TokenRefreshResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokenRefreshResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenRefreshResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenRefreshResponse serialization failed!')
    console.assert(this.TokenRefreshResponseSenderModel.verify(), 'security.TokenRefreshResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokensGetRequest value
   * @this {!Client}
   * @param {!TokensGetRequest} value TokensGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokensGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokensGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokensGetRequest serialization failed!')
    console.assert(this.TokensGetRequestSenderModel.verify(), 'security.TokensGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokensGetResponse value
   * @this {!Client}
   * @param {!TokensGetResponse} value TokensGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokensGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokensGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokensGetResponse serialization failed!')
    console.assert(this.TokensGetResponseSenderModel.verify(), 'security.TokensGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokensDeleteRequest value
   * @this {!Client}
   * @param {!TokensDeleteRequest} value TokensDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TokensDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokensDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokensDeleteRequest serialization failed!')
    console.assert(this.TokensDeleteRequestSenderModel.verify(), 'security.TokensDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokensDeleteResponse value
   * @this {!Client}
   * @param {!TokensDeleteResponse} value TokensDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TokensDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokensDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokensDeleteResponse serialization failed!')
    console.assert(this.TokensDeleteResponseSenderModel.verify(), 'security.TokensDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TokenUpdateNotify value
   * @this {!Client}
   * @param {!TokenUpdateNotify} value TokenUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TokenUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TokenUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'security.TokenUpdateNotify serialization failed!')
    console.assert(this.TokenUpdateNotifySenderModel.verify(), 'security.TokenUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'security.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
    this._entityClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
    this._entityClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * Reject receive handler
   * @this {!Client}
   * @param {!Reject} value Reject received value
   */
  onReceive_Reject (value) {}  // eslint-disable-line

  /**
   * TokenCreateRequest receive handler
   * @this {!Client}
   * @param {!TokenCreateRequest} value TokenCreateRequest received value
   */
  onReceive_TokenCreateRequest (value) {}  // eslint-disable-line

  /**
   * TokenCreateResponse receive handler
   * @this {!Client}
   * @param {!TokenCreateResponse} value TokenCreateResponse received value
   */
  onReceive_TokenCreateResponse (value) {}  // eslint-disable-line

  /**
   * TokenAccessRequest receive handler
   * @this {!Client}
   * @param {!TokenAccessRequest} value TokenAccessRequest received value
   */
  onReceive_TokenAccessRequest (value) {}  // eslint-disable-line

  /**
   * TokenAccessResponse receive handler
   * @this {!Client}
   * @param {!TokenAccessResponse} value TokenAccessResponse received value
   */
  onReceive_TokenAccessResponse (value) {}  // eslint-disable-line

  /**
   * TokenRefreshRequest receive handler
   * @this {!Client}
   * @param {!TokenRefreshRequest} value TokenRefreshRequest received value
   */
  onReceive_TokenRefreshRequest (value) {}  // eslint-disable-line

  /**
   * TokenRefreshResponse receive handler
   * @this {!Client}
   * @param {!TokenRefreshResponse} value TokenRefreshResponse received value
   */
  onReceive_TokenRefreshResponse (value) {}  // eslint-disable-line

  /**
   * TokensGetRequest receive handler
   * @this {!Client}
   * @param {!TokensGetRequest} value TokensGetRequest received value
   */
  onReceive_TokensGetRequest (value) {}  // eslint-disable-line

  /**
   * TokensGetResponse receive handler
   * @this {!Client}
   * @param {!TokensGetResponse} value TokensGetResponse received value
   */
  onReceive_TokensGetResponse (value) {}  // eslint-disable-line

  /**
   * TokensDeleteRequest receive handler
   * @this {!Client}
   * @param {!TokensDeleteRequest} value TokensDeleteRequest received value
   */
  onReceive_TokensDeleteRequest (value) {}  // eslint-disable-line

  /**
   * TokensDeleteResponse receive handler
   * @this {!Client}
   * @param {!TokensDeleteResponse} value TokensDeleteResponse received value
   */
  onReceive_TokensDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TokenUpdateNotify receive handler
   * @this {!Client}
   * @param {!TokenUpdateNotify} value TokenUpdateNotify received value
   */
  onReceive_TokenUpdateNotify (value) {}  // eslint-disable-line

  /**
   * security receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case RejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RejectReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RejectReceiverModel.verify(), 'security.Reject validation failed!')
        let deserialized = this._RejectReceiverModel.deserialize(this._RejectReceiverValue)
        console.assert((deserialized.size > 0), 'security.Reject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RejectReceiverValue)
        this.onReceive_Reject(this._RejectReceiverValue)
        return true
      }
      case TokenCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokenCreateRequestReceiverModel.verify(), 'security.TokenCreateRequest validation failed!')
        let deserialized = this._TokenCreateRequestReceiverModel.deserialize(this._TokenCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokenCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenCreateRequestReceiverValue)
        this.onReceive_TokenCreateRequest(this._TokenCreateRequestReceiverValue)
        return true
      }
      case TokenCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokenCreateResponseReceiverModel.verify(), 'security.TokenCreateResponse validation failed!')
        let deserialized = this._TokenCreateResponseReceiverModel.deserialize(this._TokenCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokenCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenCreateResponseReceiverValue)
        this.onReceive_TokenCreateResponse(this._TokenCreateResponseReceiverValue)
        return true
      }
      case TokenAccessRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenAccessRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokenAccessRequestReceiverModel.verify(), 'security.TokenAccessRequest validation failed!')
        let deserialized = this._TokenAccessRequestReceiverModel.deserialize(this._TokenAccessRequestReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokenAccessRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenAccessRequestReceiverValue)
        this.onReceive_TokenAccessRequest(this._TokenAccessRequestReceiverValue)
        return true
      }
      case TokenAccessResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenAccessResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokenAccessResponseReceiverModel.verify(), 'security.TokenAccessResponse validation failed!')
        let deserialized = this._TokenAccessResponseReceiverModel.deserialize(this._TokenAccessResponseReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokenAccessResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenAccessResponseReceiverValue)
        this.onReceive_TokenAccessResponse(this._TokenAccessResponseReceiverValue)
        return true
      }
      case TokenRefreshRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenRefreshRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokenRefreshRequestReceiverModel.verify(), 'security.TokenRefreshRequest validation failed!')
        let deserialized = this._TokenRefreshRequestReceiverModel.deserialize(this._TokenRefreshRequestReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokenRefreshRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenRefreshRequestReceiverValue)
        this.onReceive_TokenRefreshRequest(this._TokenRefreshRequestReceiverValue)
        return true
      }
      case TokenRefreshResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenRefreshResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokenRefreshResponseReceiverModel.verify(), 'security.TokenRefreshResponse validation failed!')
        let deserialized = this._TokenRefreshResponseReceiverModel.deserialize(this._TokenRefreshResponseReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokenRefreshResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenRefreshResponseReceiverValue)
        this.onReceive_TokenRefreshResponse(this._TokenRefreshResponseReceiverValue)
        return true
      }
      case TokensGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokensGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokensGetRequestReceiverModel.verify(), 'security.TokensGetRequest validation failed!')
        let deserialized = this._TokensGetRequestReceiverModel.deserialize(this._TokensGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokensGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokensGetRequestReceiverValue)
        this.onReceive_TokensGetRequest(this._TokensGetRequestReceiverValue)
        return true
      }
      case TokensGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokensGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokensGetResponseReceiverModel.verify(), 'security.TokensGetResponse validation failed!')
        let deserialized = this._TokensGetResponseReceiverModel.deserialize(this._TokensGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokensGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokensGetResponseReceiverValue)
        this.onReceive_TokensGetResponse(this._TokensGetResponseReceiverValue)
        return true
      }
      case TokensDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokensDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokensDeleteRequestReceiverModel.verify(), 'security.TokensDeleteRequest validation failed!')
        let deserialized = this._TokensDeleteRequestReceiverModel.deserialize(this._TokensDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokensDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokensDeleteRequestReceiverValue)
        this.onReceive_TokensDeleteRequest(this._TokensDeleteRequestReceiverValue)
        return true
      }
      case TokensDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokensDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokensDeleteResponseReceiverModel.verify(), 'security.TokensDeleteResponse validation failed!')
        let deserialized = this._TokensDeleteResponseReceiverModel.deserialize(this._TokensDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokensDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokensDeleteResponseReceiverValue)
        this.onReceive_TokensDeleteResponse(this._TokensDeleteResponseReceiverValue)
        return true
      }
      case TokenUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TokenUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TokenUpdateNotifyReceiverModel.verify(), 'security.TokenUpdateNotify validation failed!')
        let deserialized = this._TokenUpdateNotifyReceiverModel.deserialize(this._TokenUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'security.TokenUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TokenUpdateNotifyReceiverValue)
        this.onReceive_TokenUpdateNotify(this._TokenUpdateNotifyReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.entityClient != null) && this.entityClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
    this._entityClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._entityClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._entityClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()
    this._entityClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)
    this._entityClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
