
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * LegalDocumentType enum
 */
class LegalDocumentType {
  /**
   * Initialize enum with a given value
   * @param {LegalDocumentType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof LegalDocumentType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!LegalDocumentType}
   * @param {!LegalDocumentType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LegalDocumentType)) {
      throw new TypeError('Instance of LegalDocumentType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!LegalDocumentType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!LegalDocumentType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === LegalDocumentType.RISK_DISCLOSURE.value) {
      return 'RISK_DISCLOSURE'
    }
    if (this.value === LegalDocumentType.TERMS_AND_CONDITIONS.value) {
      return 'TERMS_AND_CONDITIONS'
    }
    if (this.value === LegalDocumentType.LIQUIDITY_GUIDELINES.value) {
      return 'LIQUIDITY_GUIDELINES'
    }
    if (this.value === LegalDocumentType.INFORMATION_DISCLOSURE.value) {
      return 'INFORMATION_DISCLOSURE'
    }
    if (this.value === LegalDocumentType.INTRODUCTION_DISCLOSURE.value) {
      return 'INTRODUCTION_DISCLOSURE'
    }
    if (this.value === LegalDocumentType.CLIENT_AGREEMENT.value) {
      return 'CLIENT_AGREEMENT'
    }
    if (this.value === LegalDocumentType.COMMISSION_AUTHORIZATION_FORM.value) {
      return 'COMMISSION_AUTHORIZATION_FORM'
    }
    if (this.value === LegalDocumentType.PRIVACY_POLICY.value) {
      return 'PRIVACY_POLICY'
    }
    if (this.value === LegalDocumentType.INDEMNIFICATION_ACKNOWLEDGEMENT.value) {
      return 'INDEMNIFICATION_ACKNOWLEDGEMENT'
    }
    if (this.value === LegalDocumentType.DEPOSIT_AND_WITHDRAWAL_POLICY.value) {
      return 'DEPOSIT_AND_WITHDRAWAL_POLICY'
    }
    if (this.value === LegalDocumentType.BIRTHDAY_BONUS_TERMS_AND_CONDITIONS.value) {
      return 'BIRTHDAY_BONUS_TERMS_AND_CONDITIONS'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!LegalDocumentType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!LegalDocumentType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!LegalDocumentType} Created enum
   */
  static fromObject (other) {
    return new LegalDocumentType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.RISK_DISCLOSURE = new LegalDocumentType(0 + 0)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.TERMS_AND_CONDITIONS = new LegalDocumentType(0 + 1)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.LIQUIDITY_GUIDELINES = new LegalDocumentType(0 + 2)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.INFORMATION_DISCLOSURE = new LegalDocumentType(0 + 3)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.INTRODUCTION_DISCLOSURE = new LegalDocumentType(0 + 4)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.CLIENT_AGREEMENT = new LegalDocumentType(0 + 5)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.COMMISSION_AUTHORIZATION_FORM = new LegalDocumentType(0 + 6)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.PRIVACY_POLICY = new LegalDocumentType(0 + 7)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.INDEMNIFICATION_ACKNOWLEDGEMENT = new LegalDocumentType(0 + 8)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.DEPOSIT_AND_WITHDRAWAL_POLICY = new LegalDocumentType(0 + 9)
// noinspection PointlessArithmeticExpressionJS
LegalDocumentType.BIRTHDAY_BONUS_TERMS_AND_CONDITIONS = new LegalDocumentType(0 + 10)

export { LegalDocumentType };

/**
 * Fast Binary Encoding LegalDocumentType field model
 */
class FieldModelLegalDocumentType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelLegalDocumentType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelLegalDocumentType}
   * @param {LegalDocumentType=} defaults Default value, defaults is new LegalDocumentType()
   * @returns {!LegalDocumentType} Result value
   */
  get (defaults = new LegalDocumentType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new LegalDocumentType(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelLegalDocumentType}
   * @param {!LegalDocumentType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelLegalDocumentType };
