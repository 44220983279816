
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {AmountRoundingPolicy} from '../entity/AmountRoundingPolicy';
import {FieldModelAmountRoundingPolicy} from '../entity/AmountRoundingPolicy';
import {PriceRoundingPolicy} from '../entity/PriceRoundingPolicy';
import {FieldModelPriceRoundingPolicy} from '../entity/PriceRoundingPolicy';

/**
 * OrdersSplitCommonParams struct
 */
class OrdersSplitCommonParams {
  /**
   * Initialize struct
   * @param {!UUID=} symbolId
   * @param {!Set=} groupIds
   * @param {!AmountRoundingPolicy=} amountRoundingPolicy
   * @param {!PriceRoundingPolicy=} priceRoundingPolicy
   * @param {!number=} oldShares
   * @param {!number=} newShares
   * @constructor
   */
  constructor (symbolId = new UUID(), groupIds = new Set(), amountRoundingPolicy = new AmountRoundingPolicy(), priceRoundingPolicy = new PriceRoundingPolicy(), oldShares = new UInt64(0, 0), newShares = new UInt64(0, 0)) {
    this.SymbolId = symbolId
    this.GroupIds = groupIds
    this.AmountRoundingPolicy = amountRoundingPolicy
    this.PriceRoundingPolicy = priceRoundingPolicy
    this.OldShares = oldShares
    this.NewShares = newShares
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrdersSplitCommonParams}
   * @param {!OrdersSplitCommonParams} other Other struct
   * @returns {!OrdersSplitCommonParams} This struct
   */
  copy (other) {
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.GroupIds != null) {
      this.GroupIds = new Set()
      for (let item of other.GroupIds) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.GroupIds.add(tempItem)
        } else {
          this.GroupIds.add(null)
        }
      }
    } else {
      this.GroupIds = null
    }
    if (other.AmountRoundingPolicy != null) {
      this.AmountRoundingPolicy = AmountRoundingPolicy.fromObject(other.AmountRoundingPolicy)
    } else {
      this.AmountRoundingPolicy = null
    }
    if (other.PriceRoundingPolicy != null) {
      this.PriceRoundingPolicy = PriceRoundingPolicy.fromObject(other.PriceRoundingPolicy)
    } else {
      this.PriceRoundingPolicy = null
    }
    if (other.OldShares != null) {
      this.OldShares = UInt64.fromNumber(other.OldShares)
    } else {
      this.OldShares = null
    }
    if (other.NewShares != null) {
      this.NewShares = UInt64.fromNumber(other.NewShares)
    } else {
      this.NewShares = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrdersSplitCommonParams}
   * @returns {!OrdersSplitCommonParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrdersSplitCommonParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrdersSplitCommonParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrdersSplitCommonParams}
   * @param {!OrdersSplitCommonParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrdersSplitCommonParams)) {
      throw new TypeError('Instance of OrdersSplitCommonParams is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrdersSplitCommonParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      GroupIds: ((this.GroupIds != null) ? Array.from(this.GroupIds, item => ((item != null) ? item.toString() : null)) : null),
      AmountRoundingPolicy: ((this.AmountRoundingPolicy != null) ? this.AmountRoundingPolicy : null),
      PriceRoundingPolicy: ((this.PriceRoundingPolicy != null) ? this.PriceRoundingPolicy : null),
      OldShares: ((this.OldShares != null) ? this.OldShares.toNumber() : null),
      NewShares: ((this.NewShares != null) ? this.NewShares.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrdersSplitCommonParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrdersSplitCommonParams} other Object value
   * @returns {!OrdersSplitCommonParams} Created struct
   */
  static fromObject (other) {
    return new OrdersSplitCommonParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrdersSplitCommonParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrdersSplitCommonParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrdersSplitCommonParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 12038
  }
}

export { OrdersSplitCommonParams };

/**
 * Fast Binary Encoding OrdersSplitCommonParams field model
 */
class FieldModelOrdersSplitCommonParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.SymbolId = new fbe.FieldModelUUID(buffer, 4 + 4)
    this.GroupIds = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize), buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.AmountRoundingPolicy = new FieldModelAmountRoundingPolicy(buffer, this.GroupIds.fbeOffset + this.GroupIds.fbeSize)
    this.PriceRoundingPolicy = new FieldModelPriceRoundingPolicy(buffer, this.AmountRoundingPolicy.fbeOffset + this.AmountRoundingPolicy.fbeSize)
    this.OldShares = new fbe.FieldModelUInt64(buffer, this.PriceRoundingPolicy.fbeOffset + this.PriceRoundingPolicy.fbeSize)
    this.NewShares = new fbe.FieldModelUInt64(buffer, this.OldShares.fbeOffset + this.OldShares.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrdersSplitCommonParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrdersSplitCommonParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.SymbolId.fbeSize + this.GroupIds.fbeSize + this.AmountRoundingPolicy.fbeSize + this.PriceRoundingPolicy.fbeSize + this.OldShares.fbeSize + this.NewShares.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrdersSplitCommonParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.SymbolId.fbeExtra + this.GroupIds.fbeExtra + this.AmountRoundingPolicy.fbeExtra + this.PriceRoundingPolicy.fbeExtra + this.OldShares.fbeExtra + this.NewShares.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrdersSplitCommonParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrdersSplitCommonParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrdersSplitCommonParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 12038
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrdersSplitCommonParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrdersSplitCommonParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.GroupIds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GroupIds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GroupIds.fbeSize

    if ((fbeCurrentSize + this.AmountRoundingPolicy.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AmountRoundingPolicy.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AmountRoundingPolicy.fbeSize

    if ((fbeCurrentSize + this.PriceRoundingPolicy.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PriceRoundingPolicy.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PriceRoundingPolicy.fbeSize

    if ((fbeCurrentSize + this.OldShares.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OldShares.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OldShares.fbeSize

    if ((fbeCurrentSize + this.NewShares.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewShares.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewShares.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrdersSplitCommonParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrdersSplitCommonParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrdersSplitCommonParams}
   * @param {!OrdersSplitCommonParams} fbeValue Default value, defaults is new OrdersSplitCommonParams()
   * @returns {!OrdersSplitCommonParams} OrdersSplitCommonParams value
   */
  get (fbeValue = new OrdersSplitCommonParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrdersSplitCommonParams}
   * @param {!OrdersSplitCommonParams} fbeValue OrdersSplitCommonParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.GroupIds.fbeSize) <= fbeStructSize) {
      this.GroupIds.get(fbeValue.GroupIds)
    } else {
      fbeValue.GroupIds.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GroupIds.fbeSize

    if ((fbeCurrentSize + this.AmountRoundingPolicy.fbeSize) <= fbeStructSize) {
      fbeValue.AmountRoundingPolicy = this.AmountRoundingPolicy.get()
    } else {
      fbeValue.AmountRoundingPolicy = new AmountRoundingPolicy()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AmountRoundingPolicy.fbeSize

    if ((fbeCurrentSize + this.PriceRoundingPolicy.fbeSize) <= fbeStructSize) {
      fbeValue.PriceRoundingPolicy = this.PriceRoundingPolicy.get()
    } else {
      fbeValue.PriceRoundingPolicy = new PriceRoundingPolicy()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PriceRoundingPolicy.fbeSize

    if ((fbeCurrentSize + this.OldShares.fbeSize) <= fbeStructSize) {
      fbeValue.OldShares = this.OldShares.get()
    } else {
      fbeValue.OldShares = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OldShares.fbeSize

    if ((fbeCurrentSize + this.NewShares.fbeSize) <= fbeStructSize) {
      fbeValue.NewShares = this.NewShares.get()
    } else {
      fbeValue.NewShares = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewShares.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrdersSplitCommonParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrdersSplitCommonParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrdersSplitCommonParams}
   * @param {!OrdersSplitCommonParams} fbeValue OrdersSplitCommonParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrdersSplitCommonParams}
   * @param {OrdersSplitCommonParams} fbeValue OrdersSplitCommonParams value
   */
  setFields (fbeValue) {
    this.SymbolId.set(fbeValue.SymbolId)
    this.GroupIds.set(fbeValue.GroupIds)
    this.AmountRoundingPolicy.set(fbeValue.AmountRoundingPolicy)
    this.PriceRoundingPolicy.set(fbeValue.PriceRoundingPolicy)
    this.OldShares.set(fbeValue.OldShares)
    this.NewShares.set(fbeValue.NewShares)
  }
}

export { FieldModelOrdersSplitCommonParams };

/**
 * Fast Binary Encoding OrdersSplitCommonParams model
 */
class OrdersSplitCommonParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrdersSplitCommonParams(this.buffer, 4)
  }

  /**
   * Get the OrdersSplitCommonParams model
   * @this {!OrdersSplitCommonParamsModel}
   * @returns {!FieldModelOrdersSplitCommonParams} model OrdersSplitCommonParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrdersSplitCommonParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrdersSplitCommonParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrdersSplitCommonParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrdersSplitCommonParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrdersSplitCommonParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrdersSplitCommonParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrdersSplitCommonParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrdersSplitCommonParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrdersSplitCommonParamsModel}
   * @param {!OrdersSplitCommonParams} value OrdersSplitCommonParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrdersSplitCommonParamsModel}
   * @param {!OrdersSplitCommonParams} value OrdersSplitCommonParams value, defaults is new OrdersSplitCommonParams()
   * @return {!object} Deserialized OrdersSplitCommonParams value and its size
   */
  deserialize (value = new OrdersSplitCommonParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrdersSplitCommonParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrdersSplitCommonParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrdersSplitCommonParamsModel}
   * @param {!number} prev Previous OrdersSplitCommonParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrdersSplitCommonParamsModel };
