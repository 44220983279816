
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {LegalDocumentMetadata} from './LegalDocumentMetadata';
import {FieldModelLegalDocumentMetadata} from './LegalDocumentMetadata';

/**
 * LegalDocumentUploadParams struct
 */
class LegalDocumentUploadParams {
  /**
   * Initialize struct
   * @param {!number=} groupId
   * @param {!LegalDocumentMetadata=} metadata
   * @param {!Uint8Array=} documentContent
   * @constructor
   */
  constructor (groupId = new UInt64(0, 0), metadata = new LegalDocumentMetadata(), documentContent = new Uint8Array(0)) {
    this.GroupId = groupId
    this.Metadata = metadata
    this.DocumentContent = documentContent
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LegalDocumentUploadParams}
   * @param {!LegalDocumentUploadParams} other Other struct
   * @returns {!LegalDocumentUploadParams} This struct
   */
  copy (other) {
    if (other.GroupId != null) {
      this.GroupId = UInt64.fromNumber(other.GroupId)
    } else {
      this.GroupId = null
    }
    if (other.Metadata != null) {
      this.Metadata = LegalDocumentMetadata.fromObject(other.Metadata)
    } else {
      this.Metadata = null
    }
    if (other.DocumentContent != null) {
      if (typeof other.DocumentContent === 'string') {
        // noinspection JSUnresolvedFunction
        this.DocumentContent = Uint8Array.from(Buffer.from(other.DocumentContent, 'base64'))
      } else {
        this.DocumentContent = Uint8Array.from(other.DocumentContent)
      }
    } else {
      this.DocumentContent = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LegalDocumentUploadParams}
   * @returns {!LegalDocumentUploadParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LegalDocumentUploadParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LegalDocumentUploadParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LegalDocumentUploadParams}
   * @param {!LegalDocumentUploadParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LegalDocumentUploadParams)) {
      throw new TypeError('Instance of LegalDocumentUploadParams is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LegalDocumentUploadParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      GroupId: ((this.GroupId != null) ? this.GroupId.toNumber() : null),
      Metadata: ((this.Metadata != null) ? this.Metadata : null),
      DocumentContent: ((this.DocumentContent != null) ? Buffer.from(this.DocumentContent).toString('base64') : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LegalDocumentUploadParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LegalDocumentUploadParams} other Object value
   * @returns {!LegalDocumentUploadParams} Created struct
   */
  static fromObject (other) {
    return new LegalDocumentUploadParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LegalDocumentUploadParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LegalDocumentUploadParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LegalDocumentUploadParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 15021
  }
}

export { LegalDocumentUploadParams };

/**
 * Fast Binary Encoding LegalDocumentUploadParams field model
 */
class FieldModelLegalDocumentUploadParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.GroupId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.Metadata = new FieldModelLegalDocumentMetadata(buffer, this.GroupId.fbeOffset + this.GroupId.fbeSize)
    this.DocumentContent = new fbe.FieldModelBytes(buffer, this.Metadata.fbeOffset + this.Metadata.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelLegalDocumentUploadParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLegalDocumentUploadParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.GroupId.fbeSize + this.Metadata.fbeSize + this.DocumentContent.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLegalDocumentUploadParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.GroupId.fbeExtra + this.Metadata.fbeExtra + this.DocumentContent.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLegalDocumentUploadParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLegalDocumentUploadParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLegalDocumentUploadParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 15021
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLegalDocumentUploadParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLegalDocumentUploadParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.GroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GroupId.fbeSize

    if ((fbeCurrentSize + this.Metadata.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Metadata.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Metadata.fbeSize

    if ((fbeCurrentSize + this.DocumentContent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DocumentContent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DocumentContent.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLegalDocumentUploadParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLegalDocumentUploadParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLegalDocumentUploadParams}
   * @param {!LegalDocumentUploadParams} fbeValue Default value, defaults is new LegalDocumentUploadParams()
   * @returns {!LegalDocumentUploadParams} LegalDocumentUploadParams value
   */
  get (fbeValue = new LegalDocumentUploadParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLegalDocumentUploadParams}
   * @param {!LegalDocumentUploadParams} fbeValue LegalDocumentUploadParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.GroupId.fbeSize) <= fbeStructSize) {
      fbeValue.GroupId = this.GroupId.get()
    } else {
      fbeValue.GroupId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GroupId.fbeSize

    if ((fbeCurrentSize + this.Metadata.fbeSize) <= fbeStructSize) {
      fbeValue.Metadata = this.Metadata.get()
    } else {
      fbeValue.Metadata = new LegalDocumentMetadata()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Metadata.fbeSize

    if ((fbeCurrentSize + this.DocumentContent.fbeSize) <= fbeStructSize) {
      fbeValue.DocumentContent = this.DocumentContent.get()
    } else {
      fbeValue.DocumentContent = new Uint8Array(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DocumentContent.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLegalDocumentUploadParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLegalDocumentUploadParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLegalDocumentUploadParams}
   * @param {!LegalDocumentUploadParams} fbeValue LegalDocumentUploadParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLegalDocumentUploadParams}
   * @param {LegalDocumentUploadParams} fbeValue LegalDocumentUploadParams value
   */
  setFields (fbeValue) {
    this.GroupId.set(fbeValue.GroupId)
    this.Metadata.set(fbeValue.Metadata)
    this.DocumentContent.set(fbeValue.DocumentContent)
  }
}

export { FieldModelLegalDocumentUploadParams };

/**
 * Fast Binary Encoding LegalDocumentUploadParams model
 */
class LegalDocumentUploadParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLegalDocumentUploadParams(this.buffer, 4)
  }

  /**
   * Get the LegalDocumentUploadParams model
   * @this {!LegalDocumentUploadParamsModel}
   * @returns {!FieldModelLegalDocumentUploadParams} model LegalDocumentUploadParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LegalDocumentUploadParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LegalDocumentUploadParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LegalDocumentUploadParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LegalDocumentUploadParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLegalDocumentUploadParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LegalDocumentUploadParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LegalDocumentUploadParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LegalDocumentUploadParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LegalDocumentUploadParamsModel}
   * @param {!LegalDocumentUploadParams} value LegalDocumentUploadParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LegalDocumentUploadParamsModel}
   * @param {!LegalDocumentUploadParams} value LegalDocumentUploadParams value, defaults is new LegalDocumentUploadParams()
   * @return {!object} Deserialized LegalDocumentUploadParams value and its size
   */
  deserialize (value = new LegalDocumentUploadParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LegalDocumentUploadParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LegalDocumentUploadParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LegalDocumentUploadParamsModel}
   * @param {!number} prev Previous LegalDocumentUploadParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LegalDocumentUploadParamsModel };
