
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {AccountUpdateNotify} from '../manager/AccountUpdateNotify';
import {FieldModelAccountUpdateNotify} from '../manager/AccountUpdateNotify';
import {MarginUpdateNotify} from '../manager/MarginUpdateNotify';
import {FieldModelMarginUpdateNotify} from '../manager/MarginUpdateNotify';
import {AssetUpdateNotify} from '../manager/AssetUpdateNotify';
import {FieldModelAssetUpdateNotify} from '../manager/AssetUpdateNotify';
import {OrderUpdateNotify} from '../manager/OrderUpdateNotify';
import {FieldModelOrderUpdateNotify} from '../manager/OrderUpdateNotify';
import {PositionUpdateNotify} from '../manager/PositionUpdateNotify';
import {FieldModelPositionUpdateNotify} from '../manager/PositionUpdateNotify';
import {TradeHistoryUpdateNotify} from '../manager/TradeHistoryUpdateNotify';
import {FieldModelTradeHistoryUpdateNotify} from '../manager/TradeHistoryUpdateNotify';
import {TradeEventsNotify} from '../manager/TradeEventsNotify';
import {FieldModelTradeEventsNotify} from '../manager/TradeEventsNotify';

/**
 * StorageUniversalNotify struct
 */
class StorageUniversalNotify {
  /**
   * Initialize struct
   * @param {AccountUpdateNotify=} accountUpdateNotify
   * @param {MarginUpdateNotify=} marginUpdateNotify
   * @param {AssetUpdateNotify=} assetUpdateNotify
   * @param {OrderUpdateNotify=} orderUpdateNotify
   * @param {PositionUpdateNotify=} positionUpdateNotify
   * @param {TradeHistoryUpdateNotify=} tradeHistoryUpdateNotify
   * @param {TradeEventsNotify=} tradeEventsNotify
   * @constructor
   */
  constructor (accountUpdateNotify = null, marginUpdateNotify = null, assetUpdateNotify = null, orderUpdateNotify = null, positionUpdateNotify = null, tradeHistoryUpdateNotify = null, tradeEventsNotify = null) {
    this.AccountUpdateNotify = accountUpdateNotify
    this.MarginUpdateNotify = marginUpdateNotify
    this.AssetUpdateNotify = assetUpdateNotify
    this.OrderUpdateNotify = orderUpdateNotify
    this.PositionUpdateNotify = positionUpdateNotify
    this.TradeHistoryUpdateNotify = tradeHistoryUpdateNotify
    this.TradeEventsNotify = tradeEventsNotify
  }

  /**
   * Copy struct (shallow copy)
   * @this {!StorageUniversalNotify}
   * @param {!StorageUniversalNotify} other Other struct
   * @returns {!StorageUniversalNotify} This struct
   */
  copy (other) {
    if (other.AccountUpdateNotify != null) {
      this.AccountUpdateNotify = AccountUpdateNotify.fromObject(other.AccountUpdateNotify)
    } else {
      this.AccountUpdateNotify = null
    }
    if (other.MarginUpdateNotify != null) {
      this.MarginUpdateNotify = MarginUpdateNotify.fromObject(other.MarginUpdateNotify)
    } else {
      this.MarginUpdateNotify = null
    }
    if (other.AssetUpdateNotify != null) {
      this.AssetUpdateNotify = AssetUpdateNotify.fromObject(other.AssetUpdateNotify)
    } else {
      this.AssetUpdateNotify = null
    }
    if (other.OrderUpdateNotify != null) {
      this.OrderUpdateNotify = OrderUpdateNotify.fromObject(other.OrderUpdateNotify)
    } else {
      this.OrderUpdateNotify = null
    }
    if (other.PositionUpdateNotify != null) {
      this.PositionUpdateNotify = PositionUpdateNotify.fromObject(other.PositionUpdateNotify)
    } else {
      this.PositionUpdateNotify = null
    }
    if (other.TradeHistoryUpdateNotify != null) {
      this.TradeHistoryUpdateNotify = TradeHistoryUpdateNotify.fromObject(other.TradeHistoryUpdateNotify)
    } else {
      this.TradeHistoryUpdateNotify = null
    }
    if (other.TradeEventsNotify != null) {
      this.TradeEventsNotify = TradeEventsNotify.fromObject(other.TradeEventsNotify)
    } else {
      this.TradeEventsNotify = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!StorageUniversalNotify}
   * @returns {!StorageUniversalNotify} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new StorageUniversalNotifyModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new StorageUniversalNotifyModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!StorageUniversalNotify}
   * @param {!StorageUniversalNotify} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof StorageUniversalNotify)) {
      throw new TypeError('Instance of StorageUniversalNotify is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!StorageUniversalNotify}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      AccountUpdateNotify: ((this.AccountUpdateNotify != null) ? this.AccountUpdateNotify : null),
      MarginUpdateNotify: ((this.MarginUpdateNotify != null) ? this.MarginUpdateNotify : null),
      AssetUpdateNotify: ((this.AssetUpdateNotify != null) ? this.AssetUpdateNotify : null),
      OrderUpdateNotify: ((this.OrderUpdateNotify != null) ? this.OrderUpdateNotify : null),
      PositionUpdateNotify: ((this.PositionUpdateNotify != null) ? this.PositionUpdateNotify : null),
      TradeHistoryUpdateNotify: ((this.TradeHistoryUpdateNotify != null) ? this.TradeHistoryUpdateNotify : null),
      TradeEventsNotify: ((this.TradeEventsNotify != null) ? this.TradeEventsNotify : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return StorageUniversalNotify.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!StorageUniversalNotify} other Object value
   * @returns {!StorageUniversalNotify} Created struct
   */
  static fromObject (other) {
    return new StorageUniversalNotify().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!StorageUniversalNotify}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return StorageUniversalNotify.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!StorageUniversalNotify}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6472
  }
}

export { StorageUniversalNotify };

/**
 * Fast Binary Encoding StorageUniversalNotify field model
 */
class FieldModelStorageUniversalNotify extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.AccountUpdateNotify = new fbe.FieldModelOptional(new FieldModelAccountUpdateNotify(buffer, 4 + 4), buffer, 4 + 4)
    this.MarginUpdateNotify = new fbe.FieldModelOptional(new FieldModelMarginUpdateNotify(buffer, this.AccountUpdateNotify.fbeOffset + this.AccountUpdateNotify.fbeSize), buffer, this.AccountUpdateNotify.fbeOffset + this.AccountUpdateNotify.fbeSize)
    this.AssetUpdateNotify = new fbe.FieldModelOptional(new FieldModelAssetUpdateNotify(buffer, this.MarginUpdateNotify.fbeOffset + this.MarginUpdateNotify.fbeSize), buffer, this.MarginUpdateNotify.fbeOffset + this.MarginUpdateNotify.fbeSize)
    this.OrderUpdateNotify = new fbe.FieldModelOptional(new FieldModelOrderUpdateNotify(buffer, this.AssetUpdateNotify.fbeOffset + this.AssetUpdateNotify.fbeSize), buffer, this.AssetUpdateNotify.fbeOffset + this.AssetUpdateNotify.fbeSize)
    this.PositionUpdateNotify = new fbe.FieldModelOptional(new FieldModelPositionUpdateNotify(buffer, this.OrderUpdateNotify.fbeOffset + this.OrderUpdateNotify.fbeSize), buffer, this.OrderUpdateNotify.fbeOffset + this.OrderUpdateNotify.fbeSize)
    this.TradeHistoryUpdateNotify = new fbe.FieldModelOptional(new FieldModelTradeHistoryUpdateNotify(buffer, this.PositionUpdateNotify.fbeOffset + this.PositionUpdateNotify.fbeSize), buffer, this.PositionUpdateNotify.fbeOffset + this.PositionUpdateNotify.fbeSize)
    this.TradeEventsNotify = new fbe.FieldModelOptional(new FieldModelTradeEventsNotify(buffer, this.TradeHistoryUpdateNotify.fbeOffset + this.TradeHistoryUpdateNotify.fbeSize), buffer, this.TradeHistoryUpdateNotify.fbeOffset + this.TradeHistoryUpdateNotify.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelStorageUniversalNotify}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelStorageUniversalNotify}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.AccountUpdateNotify.fbeSize + this.MarginUpdateNotify.fbeSize + this.AssetUpdateNotify.fbeSize + this.OrderUpdateNotify.fbeSize + this.PositionUpdateNotify.fbeSize + this.TradeHistoryUpdateNotify.fbeSize + this.TradeEventsNotify.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelStorageUniversalNotify}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.AccountUpdateNotify.fbeExtra + this.MarginUpdateNotify.fbeExtra + this.AssetUpdateNotify.fbeExtra + this.OrderUpdateNotify.fbeExtra + this.PositionUpdateNotify.fbeExtra + this.TradeHistoryUpdateNotify.fbeExtra + this.TradeEventsNotify.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelStorageUniversalNotify}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelStorageUniversalNotify.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelStorageUniversalNotify}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6472
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelStorageUniversalNotify}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelStorageUniversalNotify}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountUpdateNotify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountUpdateNotify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.MarginUpdateNotify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginUpdateNotify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.AssetUpdateNotify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AssetUpdateNotify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AssetUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.OrderUpdateNotify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderUpdateNotify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.PositionUpdateNotify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PositionUpdateNotify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PositionUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.TradeHistoryUpdateNotify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeHistoryUpdateNotify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeHistoryUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.TradeEventsNotify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeEventsNotify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEventsNotify.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelStorageUniversalNotify}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelStorageUniversalNotify}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelStorageUniversalNotify}
   * @param {!StorageUniversalNotify} fbeValue Default value, defaults is new StorageUniversalNotify()
   * @returns {!StorageUniversalNotify} StorageUniversalNotify value
   */
  get (fbeValue = new StorageUniversalNotify()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelStorageUniversalNotify}
   * @param {!StorageUniversalNotify} fbeValue StorageUniversalNotify value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountUpdateNotify.fbeSize) <= fbeStructSize) {
      fbeValue.AccountUpdateNotify = this.AccountUpdateNotify.get()
    } else {
      fbeValue.AccountUpdateNotify = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.MarginUpdateNotify.fbeSize) <= fbeStructSize) {
      fbeValue.MarginUpdateNotify = this.MarginUpdateNotify.get()
    } else {
      fbeValue.MarginUpdateNotify = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.AssetUpdateNotify.fbeSize) <= fbeStructSize) {
      fbeValue.AssetUpdateNotify = this.AssetUpdateNotify.get()
    } else {
      fbeValue.AssetUpdateNotify = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AssetUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.OrderUpdateNotify.fbeSize) <= fbeStructSize) {
      fbeValue.OrderUpdateNotify = this.OrderUpdateNotify.get()
    } else {
      fbeValue.OrderUpdateNotify = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.PositionUpdateNotify.fbeSize) <= fbeStructSize) {
      fbeValue.PositionUpdateNotify = this.PositionUpdateNotify.get()
    } else {
      fbeValue.PositionUpdateNotify = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PositionUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.TradeHistoryUpdateNotify.fbeSize) <= fbeStructSize) {
      fbeValue.TradeHistoryUpdateNotify = this.TradeHistoryUpdateNotify.get()
    } else {
      fbeValue.TradeHistoryUpdateNotify = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeHistoryUpdateNotify.fbeSize

    if ((fbeCurrentSize + this.TradeEventsNotify.fbeSize) <= fbeStructSize) {
      fbeValue.TradeEventsNotify = this.TradeEventsNotify.get()
    } else {
      fbeValue.TradeEventsNotify = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEventsNotify.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelStorageUniversalNotify}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelStorageUniversalNotify}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelStorageUniversalNotify}
   * @param {!StorageUniversalNotify} fbeValue StorageUniversalNotify value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelStorageUniversalNotify}
   * @param {StorageUniversalNotify} fbeValue StorageUniversalNotify value
   */
  setFields (fbeValue) {
    this.AccountUpdateNotify.set(fbeValue.AccountUpdateNotify)
    this.MarginUpdateNotify.set(fbeValue.MarginUpdateNotify)
    this.AssetUpdateNotify.set(fbeValue.AssetUpdateNotify)
    this.OrderUpdateNotify.set(fbeValue.OrderUpdateNotify)
    this.PositionUpdateNotify.set(fbeValue.PositionUpdateNotify)
    this.TradeHistoryUpdateNotify.set(fbeValue.TradeHistoryUpdateNotify)
    this.TradeEventsNotify.set(fbeValue.TradeEventsNotify)
  }
}

export { FieldModelStorageUniversalNotify };

/**
 * Fast Binary Encoding StorageUniversalNotify model
 */
class StorageUniversalNotifyModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelStorageUniversalNotify(this.buffer, 4)
  }

  /**
   * Get the StorageUniversalNotify model
   * @this {!StorageUniversalNotifyModel}
   * @returns {!FieldModelStorageUniversalNotify} model StorageUniversalNotify model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!StorageUniversalNotifyModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!StorageUniversalNotifyModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return StorageUniversalNotifyModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!StorageUniversalNotifyModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelStorageUniversalNotify.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!StorageUniversalNotifyModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!StorageUniversalNotifyModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!StorageUniversalNotifyModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!StorageUniversalNotifyModel}
   * @param {!StorageUniversalNotify} value StorageUniversalNotify value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!StorageUniversalNotifyModel}
   * @param {!StorageUniversalNotify} value StorageUniversalNotify value, defaults is new StorageUniversalNotify()
   * @return {!object} Deserialized StorageUniversalNotify value and its size
   */
  deserialize (value = new StorageUniversalNotify()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new StorageUniversalNotify(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new StorageUniversalNotify(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!StorageUniversalNotifyModel}
   * @param {!number} prev Previous StorageUniversalNotify model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { StorageUniversalNotifyModel };
