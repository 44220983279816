
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'
import * as security from '../security'
import * as document from '../document'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {Person} from './Person';
import {FieldModelPerson} from './Person';

/**
 * PersonCreateResponse struct
 */
class PersonCreateResponse extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {!Person=} person
   * @constructor
   */
  constructor (parent = new Response(), person = new Person()) {
    super()
    super.copy(parent)
    this.Person = person
  }

  /**
   * Copy struct (shallow copy)
   * @this {!PersonCreateResponse}
   * @param {!PersonCreateResponse} other Other struct
   * @returns {!PersonCreateResponse} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Person != null) {
      this.Person = Person.fromObject(other.Person)
    } else {
      this.Person = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!PersonCreateResponse}
   * @returns {!PersonCreateResponse} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PersonCreateResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PersonCreateResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!PersonCreateResponse}
   * @param {!PersonCreateResponse} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof PersonCreateResponse)) {
      throw new TypeError('Instance of PersonCreateResponse is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!PersonCreateResponse}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Person: ((this.Person != null) ? this.Person : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return PersonCreateResponse.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!PersonCreateResponse} other Object value
   * @returns {!PersonCreateResponse} Created struct
   */
  static fromObject (other) {
    return new PersonCreateResponse().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!PersonCreateResponse}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return PersonCreateResponse.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!PersonCreateResponse}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6528
  }
}

export { PersonCreateResponse };

/**
 * Fast Binary Encoding PersonCreateResponse field model
 */
class FieldModelPersonCreateResponse extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.Person = new FieldModelPerson(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelPersonCreateResponse}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelPersonCreateResponse}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPersonCreateResponse}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Person.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPersonCreateResponse}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Person.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPersonCreateResponse}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPersonCreateResponse.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPersonCreateResponse}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6528
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPersonCreateResponse}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPersonCreateResponse}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Person.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Person.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Person.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPersonCreateResponse}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPersonCreateResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPersonCreateResponse}
   * @param {!PersonCreateResponse} fbeValue Default value, defaults is new PersonCreateResponse()
   * @returns {!PersonCreateResponse} PersonCreateResponse value
   */
  get (fbeValue = new PersonCreateResponse()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPersonCreateResponse}
   * @param {!PersonCreateResponse} fbeValue PersonCreateResponse value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Person.fbeSize) <= fbeStructSize) {
      fbeValue.Person = this.Person.get()
    } else {
      fbeValue.Person = new Person()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Person.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPersonCreateResponse}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPersonCreateResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPersonCreateResponse}
   * @param {!PersonCreateResponse} fbeValue PersonCreateResponse value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPersonCreateResponse}
   * @param {PersonCreateResponse} fbeValue PersonCreateResponse value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Person.set(fbeValue.Person)
  }
}

export { FieldModelPersonCreateResponse };

/**
 * Fast Binary Encoding PersonCreateResponse model
 */
class PersonCreateResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPersonCreateResponse(this.buffer, 4)
  }

  /**
   * Get the PersonCreateResponse model
   * @this {!PersonCreateResponseModel}
   * @returns {!FieldModelPersonCreateResponse} model PersonCreateResponse model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PersonCreateResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PersonCreateResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PersonCreateResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PersonCreateResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPersonCreateResponse.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PersonCreateResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PersonCreateResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PersonCreateResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PersonCreateResponseModel}
   * @param {!PersonCreateResponse} value PersonCreateResponse value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PersonCreateResponseModel}
   * @param {!PersonCreateResponse} value PersonCreateResponse value, defaults is new PersonCreateResponse()
   * @return {!object} Deserialized PersonCreateResponse value and its size
   */
  deserialize (value = new PersonCreateResponse()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new PersonCreateResponse(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new PersonCreateResponse(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PersonCreateResponseModel}
   * @param {!number} prev Previous PersonCreateResponse model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PersonCreateResponseModel };
