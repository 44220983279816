
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * AuthTokenInfo struct
 */
class AuthTokenInfo {
  /**
   * Initialize struct
   * @param {!number=} tokenId
   * @param {!Date=} issued
   * @param {!Date=} accessed
   * @param {!Date=} expire
   * @param {!string=} appId
   * @param {!string=} application
   * @param {!string=} appVersion
   * @param {!string=} device
   * @param {!string=} browser
   * @param {!string=} oS
   * @param {!string=} oSVersion
   * @constructor
   */
  constructor (tokenId = new UInt64(0, 0), issued = new Date(0), accessed = new Date(0), expire = new Date(0), appId = '', application = '', appVersion = '', device = '', browser = '', oS = '', oSVersion = '') {
    this.TokenId = tokenId
    this.Issued = issued
    this.Accessed = accessed
    this.Expire = expire
    this.AppId = appId
    this.Application = application
    this.AppVersion = appVersion
    this.Device = device
    this.Browser = browser
    this.OS = oS
    this.OSVersion = oSVersion
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AuthTokenInfo}
   * @param {!AuthTokenInfo} other Other struct
   * @returns {!AuthTokenInfo} This struct
   */
  copy (other) {
    if (other.TokenId != null) {
      this.TokenId = UInt64.fromNumber(other.TokenId)
    } else {
      this.TokenId = null
    }
    if (other.Issued != null) {
      if (other.Issued instanceof Date) {
        this.Issued = new Date(other.Issued.getTime())
      } else {
        this.Issued = new Date(Math.round(other.Issued / 1000000))
      }
    } else {
      this.Issued = null
    }
    if (other.Accessed != null) {
      if (other.Accessed instanceof Date) {
        this.Accessed = new Date(other.Accessed.getTime())
      } else {
        this.Accessed = new Date(Math.round(other.Accessed / 1000000))
      }
    } else {
      this.Accessed = null
    }
    if (other.Expire != null) {
      if (other.Expire instanceof Date) {
        this.Expire = new Date(other.Expire.getTime())
      } else {
        this.Expire = new Date(Math.round(other.Expire / 1000000))
      }
    } else {
      this.Expire = null
    }
    if (other.AppId != null) {
      this.AppId = other.AppId
    } else {
      this.AppId = null
    }
    if (other.Application != null) {
      this.Application = other.Application
    } else {
      this.Application = null
    }
    if (other.AppVersion != null) {
      this.AppVersion = other.AppVersion
    } else {
      this.AppVersion = null
    }
    if (other.Device != null) {
      this.Device = other.Device
    } else {
      this.Device = null
    }
    if (other.Browser != null) {
      this.Browser = other.Browser
    } else {
      this.Browser = null
    }
    if (other.OS != null) {
      this.OS = other.OS
    } else {
      this.OS = null
    }
    if (other.OSVersion != null) {
      this.OSVersion = other.OSVersion
    } else {
      this.OSVersion = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AuthTokenInfo}
   * @returns {!AuthTokenInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AuthTokenInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AuthTokenInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AuthTokenInfo}
   * @param {!AuthTokenInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AuthTokenInfo)) {
      throw new TypeError('Instance of AuthTokenInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AuthTokenInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      TokenId: ((this.TokenId != null) ? this.TokenId.toNumber() : null),
      Issued: ((this.Issued != null) ? (this.Issued.getTime() * 1000000) : null),
      Accessed: ((this.Accessed != null) ? (this.Accessed.getTime() * 1000000) : null),
      Expire: ((this.Expire != null) ? (this.Expire.getTime() * 1000000) : null),
      AppId: ((this.AppId != null) ? this.AppId : null),
      Application: ((this.Application != null) ? this.Application : null),
      AppVersion: ((this.AppVersion != null) ? this.AppVersion : null),
      Device: ((this.Device != null) ? this.Device : null),
      Browser: ((this.Browser != null) ? this.Browser : null),
      OS: ((this.OS != null) ? this.OS : null),
      OSVersion: ((this.OSVersion != null) ? this.OSVersion : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AuthTokenInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AuthTokenInfo} other Object value
   * @returns {!AuthTokenInfo} Created struct
   */
  static fromObject (other) {
    return new AuthTokenInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AuthTokenInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AuthTokenInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AuthTokenInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5544
  }
}

export { AuthTokenInfo };

/**
 * Fast Binary Encoding AuthTokenInfo field model
 */
class FieldModelAuthTokenInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.TokenId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.Issued = new fbe.FieldModelTimestamp(buffer, this.TokenId.fbeOffset + this.TokenId.fbeSize)
    this.Accessed = new fbe.FieldModelTimestamp(buffer, this.Issued.fbeOffset + this.Issued.fbeSize)
    this.Expire = new fbe.FieldModelTimestamp(buffer, this.Accessed.fbeOffset + this.Accessed.fbeSize)
    this.AppId = new fbe.FieldModelString(buffer, this.Expire.fbeOffset + this.Expire.fbeSize)
    this.Application = new fbe.FieldModelString(buffer, this.AppId.fbeOffset + this.AppId.fbeSize)
    this.AppVersion = new fbe.FieldModelString(buffer, this.Application.fbeOffset + this.Application.fbeSize)
    this.Device = new fbe.FieldModelString(buffer, this.AppVersion.fbeOffset + this.AppVersion.fbeSize)
    this.Browser = new fbe.FieldModelString(buffer, this.Device.fbeOffset + this.Device.fbeSize)
    this.OS = new fbe.FieldModelString(buffer, this.Browser.fbeOffset + this.Browser.fbeSize)
    this.OSVersion = new fbe.FieldModelString(buffer, this.OS.fbeOffset + this.OS.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAuthTokenInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAuthTokenInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.TokenId.fbeSize + this.Issued.fbeSize + this.Accessed.fbeSize + this.Expire.fbeSize + this.AppId.fbeSize + this.Application.fbeSize + this.AppVersion.fbeSize + this.Device.fbeSize + this.Browser.fbeSize + this.OS.fbeSize + this.OSVersion.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAuthTokenInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.TokenId.fbeExtra + this.Issued.fbeExtra + this.Accessed.fbeExtra + this.Expire.fbeExtra + this.AppId.fbeExtra + this.Application.fbeExtra + this.AppVersion.fbeExtra + this.Device.fbeExtra + this.Browser.fbeExtra + this.OS.fbeExtra + this.OSVersion.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAuthTokenInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAuthTokenInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAuthTokenInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5544
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAuthTokenInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAuthTokenInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.TokenId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TokenId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TokenId.fbeSize

    if ((fbeCurrentSize + this.Issued.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Issued.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Issued.fbeSize

    if ((fbeCurrentSize + this.Accessed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Accessed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Accessed.fbeSize

    if ((fbeCurrentSize + this.Expire.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Expire.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expire.fbeSize

    if ((fbeCurrentSize + this.AppId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AppId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AppId.fbeSize

    if ((fbeCurrentSize + this.Application.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Application.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Application.fbeSize

    if ((fbeCurrentSize + this.AppVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AppVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AppVersion.fbeSize

    if ((fbeCurrentSize + this.Device.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Device.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Device.fbeSize

    if ((fbeCurrentSize + this.Browser.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Browser.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Browser.fbeSize

    if ((fbeCurrentSize + this.OS.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OS.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OS.fbeSize

    if ((fbeCurrentSize + this.OSVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersion.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAuthTokenInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAuthTokenInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAuthTokenInfo}
   * @param {!AuthTokenInfo} fbeValue Default value, defaults is new AuthTokenInfo()
   * @returns {!AuthTokenInfo} AuthTokenInfo value
   */
  get (fbeValue = new AuthTokenInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAuthTokenInfo}
   * @param {!AuthTokenInfo} fbeValue AuthTokenInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.TokenId.fbeSize) <= fbeStructSize) {
      fbeValue.TokenId = this.TokenId.get()
    } else {
      fbeValue.TokenId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TokenId.fbeSize

    if ((fbeCurrentSize + this.Issued.fbeSize) <= fbeStructSize) {
      fbeValue.Issued = this.Issued.get()
    } else {
      fbeValue.Issued = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Issued.fbeSize

    if ((fbeCurrentSize + this.Accessed.fbeSize) <= fbeStructSize) {
      fbeValue.Accessed = this.Accessed.get()
    } else {
      fbeValue.Accessed = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Accessed.fbeSize

    if ((fbeCurrentSize + this.Expire.fbeSize) <= fbeStructSize) {
      fbeValue.Expire = this.Expire.get()
    } else {
      fbeValue.Expire = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expire.fbeSize

    if ((fbeCurrentSize + this.AppId.fbeSize) <= fbeStructSize) {
      fbeValue.AppId = this.AppId.get()
    } else {
      fbeValue.AppId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AppId.fbeSize

    if ((fbeCurrentSize + this.Application.fbeSize) <= fbeStructSize) {
      fbeValue.Application = this.Application.get()
    } else {
      fbeValue.Application = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Application.fbeSize

    if ((fbeCurrentSize + this.AppVersion.fbeSize) <= fbeStructSize) {
      fbeValue.AppVersion = this.AppVersion.get()
    } else {
      fbeValue.AppVersion = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AppVersion.fbeSize

    if ((fbeCurrentSize + this.Device.fbeSize) <= fbeStructSize) {
      fbeValue.Device = this.Device.get()
    } else {
      fbeValue.Device = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Device.fbeSize

    if ((fbeCurrentSize + this.Browser.fbeSize) <= fbeStructSize) {
      fbeValue.Browser = this.Browser.get()
    } else {
      fbeValue.Browser = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Browser.fbeSize

    if ((fbeCurrentSize + this.OS.fbeSize) <= fbeStructSize) {
      fbeValue.OS = this.OS.get()
    } else {
      fbeValue.OS = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OS.fbeSize

    if ((fbeCurrentSize + this.OSVersion.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersion = this.OSVersion.get()
    } else {
      fbeValue.OSVersion = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersion.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAuthTokenInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAuthTokenInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAuthTokenInfo}
   * @param {!AuthTokenInfo} fbeValue AuthTokenInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAuthTokenInfo}
   * @param {AuthTokenInfo} fbeValue AuthTokenInfo value
   */
  setFields (fbeValue) {
    this.TokenId.set(fbeValue.TokenId)
    this.Issued.set(fbeValue.Issued)
    this.Accessed.set(fbeValue.Accessed)
    this.Expire.set(fbeValue.Expire)
    this.AppId.set(fbeValue.AppId)
    this.Application.set(fbeValue.Application)
    this.AppVersion.set(fbeValue.AppVersion)
    this.Device.set(fbeValue.Device)
    this.Browser.set(fbeValue.Browser)
    this.OS.set(fbeValue.OS)
    this.OSVersion.set(fbeValue.OSVersion)
  }
}

export { FieldModelAuthTokenInfo };

/**
 * Fast Binary Encoding AuthTokenInfo model
 */
class AuthTokenInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAuthTokenInfo(this.buffer, 4)
  }

  /**
   * Get the AuthTokenInfo model
   * @this {!AuthTokenInfoModel}
   * @returns {!FieldModelAuthTokenInfo} model AuthTokenInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AuthTokenInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AuthTokenInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AuthTokenInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AuthTokenInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAuthTokenInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AuthTokenInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AuthTokenInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AuthTokenInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AuthTokenInfoModel}
   * @param {!AuthTokenInfo} value AuthTokenInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AuthTokenInfoModel}
   * @param {!AuthTokenInfo} value AuthTokenInfo value, defaults is new AuthTokenInfo()
   * @return {!object} Deserialized AuthTokenInfo value and its size
   */
  deserialize (value = new AuthTokenInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AuthTokenInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AuthTokenInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AuthTokenInfoModel}
   * @param {!number} prev Previous AuthTokenInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AuthTokenInfoModel };
